import { Link, useLocation } from "react-router-dom"
import React, { useState, useEffect } from 'react'

import Service from '../../../Services/Service';

import { Form, FormGroup, Col, Row, Button, InputGroup, Collapse, Modal } from 'react-bootstrap';
import 'react-multiple-select-dropdown-lite/dist/index.css'
import CustomErrorPopup from '../../../UI/CustomErrorPopup';
import Multiselect from 'multiselect-react-dropdown';
import $ from 'jquery';
import { useTranslation } from "react-i18next";


function EditRoleComponent(props) {
    const [t, i18n] = useTranslation('translation');
    const [editPerms, setEditPerms] = useState([]);
    const [selecteperm, setselecteperm] = useState([]);
    const location = useLocation();
    const [userPerms, setuserPermission] = useState([]);
    const [Permissions, setPermissions] = useState([]);
    const [roleStatus, setRoleStatus] = useState(false);
    const [closeModal, setCloseModal] = useState(false);
    const [handleChangeValue, setHandleChangeValue] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [errorStatus, setErrorStatus] = useState("Error");
    const [isValid, setIsValid] = useState(true);
    let loggedInUser = sessionStorage.getItem("id");
    const [isLocked, setIsLocked] = useState(false);
    const [lockedBy, setLockedBy] = useState('');
    let disable = false;
    useEffect(() => {
        getUserPermission(location.pathname);
        getData("Permission/GetAll");
        getUserDatabyId("Roles/GetById/", props.id);
    }, [0])
    const getUserPermission = (pathname) => {
        Service.GetUserPermission(pathname).then((res) => {
       
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null' && res.data !== '') {
                    if (typeof res.data.DataList !== 'undefined') {
                        var permission = res.data.DataList;
                        if (typeof permission !== 'undefined') {
                            if (permission.filter((item) => item.ActionName === 'Edit' || item.ActionName === 'Submit').length == 0) {
                                setEditPerms(false);
                            } else {
                                setEditPerms(true);
                            }
                        }
                        else {
                            setEditPerms(false);
                        }
                    }
                }
            }
        });
    }
    const getData = (method) => {
        Service.getdata(method).then((res) => {
            if (typeof res !== 'undefined') {
                if (res.data !== 'null' && typeof res.data !== 'undefined') {
                    var d = res.data;
                    setPermissions(res.data);
                }
            }
        });
    }

    const getUserDatabyId = (method, id) => {
        Service.LockUnLockEntity('Configurationprop/LockEntity', "Role", props.id, loggedInUser).then((res1) => {
            if (typeof res1 !== 'undefined') {
                Service.getdatabyId(method, id).then((res) => {
               
                    if (typeof res !== 'undefined') {
                        if (typeof res.data !== 'undefined' && res.data !== 'null') {
                            setInputField(res.data);
                           
                            setselecteperm(res.data.lstPermission);
                       
                            setRoleStatus(true)
                            setLockedBy(res.data.LockedBy);
                            setIsLocked(res.data.Locked);
                            if ((res.data.Locked === true && res.data.LockedBy.toString() !== loggedInUser)) {
                                $("#libread").html("Role User " + `Locked By ${res.data.LockedByUserName}`);
                            }
                        }
                    }
                });
            }
        });
    }

    const [inputField, setInputField] = useState({
        Id: props.id,
        label: '',
        Name: '',
        Description: '',
        CreatedUserId: loggedInUser,
        LastModifiedUserId: loggedInUser,
        lstPermission: []
    })

    const inputsHandler = (e) => {
        setHandleChangeValue(true)
        
        if (roleStatus === false) {
            const { name, value } = e.target;
            setInputField((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        }
        else {
            const { name, value } = e.target;
            setInputField((prevState) => ({
                ...prevState, [name]: value,
            }));
        }
    }

    const handleChange = (selectedList, selectedItem) => {
        setHandleChangeValue(true)
        const selected = [];
        const selectedId = [];
        inputField.lstPermission = [];
        let selectedOption = (selectedList);
   
        for (let i = 0; i < selectedOption.length; i++) {
            selected.push(selectedOption[i].Name);
            selectedId.push(selectedOption[i].Id);
            const getallData = {
                id: selectedOption[i].Id,
          
            }
            inputField.lstPermission.push(getallData);
        }

 
    }
    const checkValidation = () => {
        var hasError = false;
       
        if (inputField.label == '' || inputField.label == null) {
           
            setErrorMessage(`${t('RoleList.Validation')} ${t('RoleList.RoleLabel')}`)
            setIsValid(false)
            setShowErrorModal(true)
            hasError = true;
        }
        else if (inputField.lstPermission.length == 0) {
            setErrorMessage(`${t('RoleList.Validation')} ${t('RoleList.Permissions')}`)
            setIsValid(false)
            setShowErrorModal(true)
            hasError = true;
        }
        return hasError;
    }

    const submitButton = (e) => {
        e.preventDefault();
        let roles = inputField;
        roles.LastModifiedUserId = loggedInUser;
        var hasError = checkValidation()
        if (hasError == false) {
            Service.createorupdate("Roles/CreateOrUpdate", roles).then(res => {
                if (typeof res !== 'undefined') {
                    if (typeof res.data !== 'undefined' && res.data !== 'null') {
                        Service.LockUnLockEntity('Configurationprop/UnLockEntity', "Role", props.id, loggedInUser).then((res) => {
                        });
                        props.navigate('/Roles');
                    }
                }

            });
        }
    }

    const closeHandler = (flag) => {
        if (flag == "Open" && handleChangeValue == true) {
            setCloseModal(true)
        }
        if (flag == "Open" && handleChangeValue == false) {
            Service.LockUnLockEntity('Configurationprop/UnLockEntity', "Role", props.id, loggedInUser).then((res) => {
            });
            props.navigate("/roles")
        }
        if (flag == "Close") {
            Service.LockUnLockEntity('Configurationprop/UnLockEntity', "Role", props.id, loggedInUser).then((res) => {
            });
            props.navigate("/roles")
        }
        if (flag == "Cancel") {
            setCloseModal(false)
        }

    }
    if (isLocked === false || (isLocked === true && lockedBy.toString() === loggedInUser)) {
        disable = false;
    }
    else {
        disable = true;
    }
    window.onbeforeunload = function () {
        unlockEntity();
   
    };

    const unlockEntity = () => {
        Service.LockUnLockEntity('Configurationprop/UnLockEntity', "Role", props.id, loggedInUser).then((res) => {
        });
    }
    const class_textbox = `col-md-2 textbox-padding  ${editPerms == false ? "disabledClass" : ""}`;
    const class_multiselect = ` textbox-padding  ${editPerms == false ? "disabledClass" : ""}`;

    const regionStyles = {
        chips: {
            fontSize: "12px",
            lineHeight: "13px"
        },

        multiSelectContainer: {
            "&:hover": { background: "red" }
        }

    }

    return (<>
        <div class="container-fluid content-top-gap">

            <nav aria-label="breadcrumb">
                <ol class="breadcrumb my-breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">{t('Home.title')}</Link></li>
                    <li className="breadcrumb-item"><Link to="/admin">{t('Menu.Admin')}</Link></li>
                    <li class="breadcrumb-item active" aria-current="page"><Link to="/Roles">{t('RoleList.RoleList')}</Link></li>
                    <li class="breadcrumb-item active" id="libread" aria-current="page">{t('RoleList.RoleUser')}</li>
                </ol>
            </nav>

            <div class="row mt-3" id="rebateProgramdiv">
                <div class="col-lg-12 pr-lg-2">
                    <div class="card text-center card_border">
                        <div class="card-header chart-grid__header">
                            <div class="row">
                                <div class="col-md-2">
                                {t('RoleList.CreateRole')}
                                </div>
                                <div class="col-md-10">
                                    {editPerms == false ? "" : (isLocked === true && lockedBy.toString() === loggedInUser) ? <button class="btn btn-sm btn-update ml-2" style={{ float: "right",width: "50px" }} onClick={submitButton} type="submit">{t('Submit')}</button> : ''}
                                    <button className="btn btn-sm btn-update ml-2" id="close" style={{ width: "45px", backgroundColor: "buttonface", float: "right" }} onClick={(event) => closeHandler("Open")} >{t('Close')}</button>
                                </div>
                            </div>
                        </div>
                        <div class="card-body" style={{ minHeight: "60vh" }}>
                            <div class="container">
                                <div class="row">
                                    <label class="col-md-2 text-left">{t('RoleList.RoleName')}</label>
                                    <input id="aid" readOnly="true" onChange={inputsHandler} value={inputField.Name} name="Name" autoComplete='off' placeholder="Role Name" type="text" class="col-md-2 textbox-padding disabledClass" disabled={roleStatus} />
                                </div>
                                <div class="row mt-3">
                                    <label class="col-md-2 text-left">{t('RoleList.RoleLabel')}</label>
                                    <input disabled={disable} id="aid" onChange={inputsHandler} type="text" value={inputField.label} autoComplete='off' placeholder="Role Label" name="label" className={class_textbox} />
                                </div>
                               
                                <div class="row mt-3">
                                    <label class="col-md-2 mt-2 text-left">{t('RoleList.Permissions')}</label>
                                    <Multiselect className={class_multiselect}
                                        onSelect={handleChange}
                                        onRemove={handleChange}
                                        options={Permissions}
                                     
                                        displayValue="Name"
                                        showCheckbox={true}

                                        selectedValues={selecteperm}
                                        disable={disable}
                                        style={regionStyles}
                                    />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Modal show={closeModal}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Body style={{ "textAlign": 'center', fontFamily: 'Segoe UI' }}>Your changes will be lost! Are you sure you want to close?</Modal.Body>
                <Modal.Footer>
                    <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black',width: "45px" }} size='sm' onClick={() => closeHandler("Close")}>
                        Close
                    </Button>
                    <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black',width: "50px" }} size='sm' onClick={() => closeHandler("Cancel")}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
            <CustomErrorPopup ErrorMessage={errorMessage} show={showErrorModal} ErrorStatus={errorStatus}
                onHide={() => setShowErrorModal(false)}></CustomErrorPopup>

        </div>
    </>)
}
export default EditRoleComponent