
import React, { useEffect, useState, useRef, forwardRef } from 'react';
import { CSVLink } from "react-csv";
import { Link } from 'react-router-dom';
import Service from '../../../Services/Service';
import moment from 'moment';
import { useNavigate, useLocation } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import SessionManage from '../../../SessionManagement/SessionManage';
import CustomErrorPopup from '../../../UI/CustomErrorPopup';
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



function AccuralsPostingComponent(props) {
    const csvInstance = useRef();
    const [t, i18n] = useTranslation('translation');
    const [csvList, setCsvList] = useState([]);
    const [headers, setHeaders] = useState([
        { label: "Rebate Agreement ID", key: "RebateHeaderId" },
        { label: "Customer", key: "CustomerName" },
        { label: "Rebate Program ID", key: "RebateProgramLineId" },
        { label: "Accrual ID", key: "AccrualId" },
        { label: "Rebate Period", key: "RebatePeriod" },
        { label: "Accrual Amount", key: "AccrualsAggregate" },
        { label: "Auto Post Accrual", key: "AutoPostAccruals" },
        { label: "Accrual Posted", key: "AccrualsPosted" },
        { label: "Posted By", key: "PostedByUserName" },
        { label: "Posted On", key: "PostedDate" },
    ]);
    const [sortColName, setSortColName] = useState("");
    const [sticky, setSticky] = useState("");
    const [userPerms, setuserPermission] = useState([]);
    const location = useLocation();
    const [columnAscending, setColumnAscending] = useState(true);
    const navigate = useNavigate();
    const [showbutton, setshowbutton] = useState('');
    const [showspinner, setshowspinner] = useState('');
    const [filterValue, setFilterValue] = useState('');
    const [filterCol, setFilterCol] = useState('');
    const [showfilterpopup, setshowfilterpopup] = useState(false);
    const [dropdownData, setDropdownData] = useState([]);
    let rowData = [];
    const [listViewData, setListViewData] = useState(rowData)
    const [filteredArray, setFilteredArray] = useState(rowData)
    const [state, setState] = useState({ status: '2' });
    const [checkedValueids, setCheckedValueids] = useState([]);
    const [searchValue, setSearchValue] = useState('')
    const [totalselectedshow, settotalselectedshow] = useState('');
    const [totalselectedcount, settotalselectedcount] = useState(0);
    const [totalCountlst, settotalCountlst] = useState('');
    const [filterdropdownData, setFilterDropdownData] = useState([]);
    const [filterSearch, setFilterSearch] = useState("");
    const [filtertext, setfiltertext] = useState('');
    const [paging, setpaging] = useState({
        pageSize: 20,
        pageNumber: 1
    })
    const [showLoadMore, setshowLoadMore] = useState('');
    const [filterValueDict, setFilterValueDict] = useState({
        RebateHeaderId: '',
        RebateProgramLineId: '',
        amount: '',
        AccrualId: '',
        version: '',
        AccrualsAggregate: '',
        FiscalYearPeriod: '',
    });
    const [multiFilterValue, setMultiFilterValue] = useState({
        CustomerName: '',
        PostedByUserName: '',
        AccrualsPosted: '',
        AutoPostAccruals: ''
    });

    let loggedInUser = SessionManage.getuserid();
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [errorStatus, setErrorStatus] = useState("Error");
    const [operatorVal, setOperatorValue] = useState({ value: "=", label: "equals" })
    const [operatorValOption, setOperatorValueOption] = useState([
        { value: "=", label: "equals" },
        { value: "<", label: "less then" },
        { value: ">", label: "greater then" },
        { value: "<=", label: "less or equal" },
        { value: ">=", label: "greater or equal" }
    ])
    const OnChangeOperatorValue = (data) => {
        setOperatorValue({ value: data.value, label: data.label });
    }
    const customStyles = {
        control: base => ({
            ...base,
            minHeight: 28
        }),
        dropdownIndicator: base => ({
            ...base,
            padding: 2
        }),
        clearIndicator: base => ({
            ...base,
            padding: 2
        }),
        multiValue: base => ({
            ...base,

        }),
        valueContainer: base => ({
            ...base,
            padding: '0px 2px'
        }),
        input: base => ({
            ...base,
            margin: 0,
            padding: 0
        })
    };

    const checkAll = async (mycheckbox) => {
        var checkboxes = document.querySelectorAll("input[name = 'chkBox']");
        if (mycheckbox.target.checked == true) {
            let check_lst = checkedValueids
            checkboxes.forEach(function (checkbox) {
                checkbox.checked = true;
                let obj = checkbox.id.split(',')
                if (obj[1] == 'false' && obj[2] == 'false') {
                    setCheckedValueids([...checkedValueids, parseInt(obj[0])])
                    check_lst.push(parseInt(obj[0]))
                }
            });
            if (check_lst.length > 0) {
                setshowbutton(true);
            }
            settotalselectedshow(true);
            settotalselectedcount(checkboxes.length)
        }
        else {
            checkboxes.forEach(function (checkbox) {
                checkbox.checked = false;
            });
            setshowbutton(false);
            settotalselectedshow(false);
            settotalselectedcount(0)
            setCheckedValueids([])
        }

    }
    {
        useEffect(() => {
            getUserPermission(location.pathname);
            setshowspinner(true);
            setColumnAscending(false);
            setSortColName("Id");
            getAccrualListViewData("Accurals/GetAllAccrualsPosting", paging.pageSize, paging.pageNumber, "Descending", "Id", [], null);
            getColumnVisibilityList()
            getColVisibilityListData()
            window.addEventListener("scroll", isSticky);
            return () => {
                window.removeEventListener("scroll", isSticky);
            };
        }, [])
    }
    const isSticky = () => {
        const scrollTop = window.scrollY;
        const stickyClass = scrollTop >= 60 ? "is-sticky-thead" : "";
        setSticky(stickyClass);
        const scrollTopActual = scrollTop >= 60 ? scrollTop - 85 : 0;
        var divtop1 = document.getElementById("tableA");
        setdivtop(divtop1.offsetTop + scrollTopActual)
    };
    const getUserPermission = (pathname) => {
        Service.GetUserPermission(pathname).then((res) => {

            if (res !== undefined) {
                if (res.data !== 'null' && res.data !== 'undefined' && res.data !== '') {
                    if (res.data.DataList !== undefined) {
                        setuserPermission(res.data.DataList)
                    }
                }
            }
        });
    }


    const ExporttoCSV = () => {
        let checkedBoxes = document.querySelectorAll('input[name=chkBox]:checked');
        let values = "";
        if (checkedBoxes.length >= 1) {
            for (let checkbox of checkedBoxes) {
                values = values + ';' + checkbox.id.split(',')[0];
            }
        }
        let objFilter = [];
        let objFilterValue = [{
            filterKey: "id",
            FilterValue: values,
        }]

        objFilter = objFilterValue;

        Service.getdataWithPagingByGroupSearch("Accurals/GetAllAccrualsPosting", 20000, 1, "Ascending", "Id", objFilter, null).then((res) => {

            if (typeof res !== 'undefined') {
                if (res.data !== 'null' && res.data !== 'undefined') {

                    let csvLst = res.data.DataList;

                    let filteredCsvList = csvLst.map(item => {
                        let newItem = {};
                        Object.keys(item).forEach(key => {
                            if (!hideColumn.includes(key)) {
                                newItem[key] = item[key];
                            }
                        });
                        return newItem;
                    });

                    let filteredHeaders = headers.filter(header => !hideColumn.includes(header.key));

                    setCsvList(filteredCsvList);
                    setHeaders(filteredHeaders);

                    setTimeout(() => {
                        toast.success('Exported Successfully !', {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                        csvInstance.current.link.click();
                    });

                }
            }
        });
    }
    const activeOption = [
        { Name: "Yes", Id: "1" },
        { Name: "No", Id: "0" },

    ]
    const getAccrualListViewData = (method, pageSize, pageNumber, sortType, SortProperty, filterValue, search) => {
        Service.getdataWithPagingByGroupSearch(method, pageSize, pageNumber, null, null, filterValue, search).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                            rowData = res.data.DataList
                            LoadMore(res.data.DataList.length, res.data.Totalcount)
                            settotalCountlst(res.data.Totalcount)
                            setListViewData(rowData)
                            setFilteredArray(rowData)
                            setshowspinner(false);
                        }
                    }
                }
            }

        });
    }

    const searchHandler = (event) => {
        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
        let pagenumber = 1;
        paging.pageNumber = pagenumber;
        setpaging(paging);
        if (event.target.value != '') {
            //	filterHandler('Reset');
            setSearchValue(String(event.target.value).toLowerCase())
            //   getAccrualListViewData("Accurals/GetAllAccrualsPosting", paging.pageSize, paging.pageNumber, null, null, [],null);
            getAccrualListViewData("Accurals/GetAllAccrualsPosting", paging.pageSize, pagenumber, sortType, sortColName, filterLst(), event.target.value);
        }
        else {
            setSearchValue(null);
            getAccrualListViewData("Accurals/GetAllAccrualsPosting", paging.pageSize, pagenumber, sortType, sortColName, filterLst(), null);
        }
    }
    const filterLst = () => {
        let list = [];
        for (var key in multiFilterValue) {
            if (multiFilterValue[key].length > 0) {
                let values = ""
                multiFilterValue[key].map((i) => {
                    values += i.Name + ";";
                })
                if (values !== null && values !== '') {
                    list.push({
                        filterKey: key,
                        FilterValue: values
                    })
                }
            }
        }

        for (var key in filterValueDict) {
            if (filterValueDict[key] !== null && filterValueDict[key] !== '') {
                list.push({
                    filterKey: key,
                    FilterValue: filterValueDict[key]
                })
            }
        }
        return list;
    }

    const handleLoadMore = () => {

        let pagenumber = paging.pageNumber + 1;
        var sortType = columnAscending === true ? 'Ascending' : 'Descending';

        Service.getdataWithPagingByGroupSearch("Accurals/GetAllAccrualsPosting", paging.pageSize, pagenumber, sortType, sortColName, filterLst(), searchValue).then((res) => {
            if (res && res.status === 200) {
                if (res.data !== 'null' && res.data !== 'undefined') {
                    rowData = res.data.DataList;
                    if (res.data.DataList !== 'null' && res.data.DataList !== 'undefined') {
                        const currentlength = listViewData.length;
                        setListViewData(listViewData => [...listViewData, ...res.data.DataList])
                        setFilteredArray(filteredArray => [...filteredArray, ...res.data.DataList])
                        LoadMore(currentlength + rowData.length, res.data.Totalcount)
                    }

                }
            }
        });

        paging.pageNumber = pagenumber
        setpaging(paging);
    };


    const handleChange = (e, listVal) => {
        let isChecked = e.target.checked;   // true if it is checked false if unchecked
        let check_lst = checkedValueids
        if (isChecked === true && listVal.AutoPostAccruals === false && listVal.AccrualsPosted === false) {
            setCheckedValueids([...checkedValueids, listVal.Id])
            check_lst.push(listVal.Id)
        }
        else {
            setCheckedValueids(checkedValueids.filter((e) => e !== listVal.Id))
            check_lst = check_lst.filter((e) => e !== listVal.Id)
        }
        if (check_lst.length > 0) {
            setshowbutton(true);
        }
        else {
            setshowbutton(false);
        }

        let checkedBoxess = document.querySelectorAll('input[name=chkBox]:checked');
        if (checkedBoxess.length > 0) {
            settotalselectedshow(true);
            settotalselectedcount(checkedBoxess.length);
        } else {
            settotalselectedcount(0);
            settotalselectedshow(false);
        }
    }
    const PostHandler = (e) => {
        Service.PostWithIdAndUser("Accurals/PostAccruals", checkedValueids, loggedInUser).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data.Status === 'Failure') {
                            setErrorStatus("Error")
                            setErrorMessage(res.data.ErrorMessage)
                            setShowErrorModal(true)
                        }
                        window.location.reload();

                    }
                }
            }

        });
    }

    const filterInput = (e) => {
        let inputValue = e.target.value
        setFilterValue(inputValue)
        setFilterValueDict(() => ({
            ...filterValueDict, [filterCol]: e.target.value,
        }));
    }


    const OndropdownChange = (e, id, name) => {
        let isChecked = e.target.checked;
        var lastfilter = [...dropdownData]
        if (isChecked === true) {
            let currentdata = lastfilter.map((i) => {
                if (i.Id == id) {
                    i["ischecked"] = true;
                }
                return i;
            });
            setDropdownData(currentdata);
            let data1 = []
            const getallData = {
                Name: name,
            }
            data1.push(getallData)
            setFilterValue(filterValue => [...filterValue, ...data1]);
            let updateddata = ([...filterValue, ...data1]);
            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: updateddata,
            }));
        }
        else {
            let currentdata = lastfilter.map((i) => {
                if (i.Id === id) {
                    i["ischecked"] = false;
                }
                return i;
            });
            setDropdownData(currentdata);
            setFilterValue(filterValue => filterValue.filter(data => data.Name !== name))
            let data1 = filterValue.filter(data => data.Name !== name);
            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: data1,
            }));
        }
    }



    const [divleft, setdivleft] = useState('');
    const [divtop, setdivtop] = useState('');

    const SortColumn = (colName) => {
        var sortType = '';
        if (columnAscending === true) {
            setColumnAscending(false);
            sortType = "Descending";
        } else {
            setColumnAscending(true);
            sortType = "Ascending";
        }
        setSortColName(colName);

        let pagenumber = 1;

        paging.pageNumber = pagenumber;
        setpaging(paging);
        getAccrualListViewData("Accurals/GetAlllst", paging.pageSize, paging.pageNumber, sortType, colName, filterLst(), searchValue);
    }


    const FilterColumn = (name) => {
        const scrollTop = window.scrollY;
        const scrollTopActual = scrollTop >= 60 ? scrollTop - 85 : 0;
        var divtop1 = document.getElementById("tableA");
        setdivtop((divtop1.offsetTop + 30) + scrollTopActual)
        setshowfilterpopup(true)
        setFilterCol(name)
        var testDiv = document.getElementById(name);
        setdivleft((testDiv.offsetLeft + 30) + "px");
        if (name === 'CustomerName') {
            setfiltertext(t('Accruals.Customer'))

        }
        if (name === 'FiscalYearPeriod') {
            setfiltertext(t('Accruals.RebatePeriod'))
        }
        if (name === 'createdby') {
            setfiltertext(t('Accruals.RebateAgreementId'))
        }

        if (name === 'RebateHeaderId') {
            setfiltertext(t('LastModifiedDate'))
        }
        if (name === 'RebateProgramLineId') {
            setfiltertext(t('Accruals.RebateProgramId'))
        }
        if (name === 'AccrualId') {
            setfiltertext(t('Accruals.AccrualId'))
        }
        if (name === 'version') {
            setfiltertext("Version")
        }
        if (name === 'PostedByUserName') {
            setfiltertext(t('Accruals.PostedBy'))
        }
        if (name === 'PostedDate') {
            setfiltertext(t('Accruals.PostedOn'))
        }
        if (name === 'AccrualsAggregate') {
            setfiltertext(t('Accruals.AccrualAmount'))
        }
        if (name === 'AutoPostAccruals') {
            addnewfield(activeOption, name)
            setfiltertext(t('Accruals.AutoPostAccrual'))
        }
        if (name === 'AccrualsPosted') {
            addnewfield(activeOption, name)
            setfiltertext(t('Accruals.AccrualPosted'))
        }
        if (name === 'CustomerName' || name === 'PostedByUserName')
            DropdownHandler(name)
        setshowfilterpopup(true)
    }


    const DropdownHandler = (key) => {
        Service.getfilterDropdownByGroup('Accurals/GetAccrualsFilterValuelst', key).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                            addnewfield(res.data, key)
                        }
                    }
                }
            }

        });
    }

    const UserfilterHandler = (e) => {
   
        setFilterSearch(e.target.value)
        if (e.target.value !== "") {
            let searchResults = dropdownData.filter((each) => {
                return each.Name.toLowerCase().includes(String(e.target.value).toLowerCase())
            });
            setDropdownData(searchResults);
        }
        else {
            setFilterSearch("")
            setDropdownData(filterdropdownData)
        }
    }

    const addnewfield = (data, key) => {
        let currentdata = data.map((i) => {
            i["ischecked"] = false;
            return i;
        });
        let selectedKeys = multiFilterValue[key];
        if (key != 'AutoPostAccruals' || key != 'AccrualsPosted') {
            if (selectedKeys != '') {
                currentdata.forEach((each) => {
                    selectedKeys.forEach((eachSelected) => {
                        if (each.Name == eachSelected.Name) {
                            each.ischecked = true
                        }
                    })
                })
            }
            setDropdownData(currentdata);
            setFilterDropdownData(currentdata)
        }
        else {
            if (selectedKeys != '') {
                currentdata.forEach((each) => {
                    selectedKeys.forEach((eachSelected) => {
                        if (each.Name == eachSelected.Name) {
                            each.ischecked = true
                        }
                    })
                })
                setDropdownData(currentdata)
                setFilterDropdownData(currentdata)
            }
            else {
                setDropdownData(currentdata)
                setFilterDropdownData(currentdata)
            }
        }
    }
    const checkAllFilter = (mycheckbox) => {
        var lastfilter = [...dropdownData]
        let data1 = []
        var checkboxes = document.querySelectorAll("input[name = 'filter-option-all']");
        if (mycheckbox.target.checked == true) {

            let currentdata = lastfilter.map((i) => {
                i["ischecked"] = true;
                return i;
            });
            setDropdownData(currentdata)

            lastfilter.map((each) => {

                const getallData = {
                    Name: each.Name,
                }
                data1.push(getallData)
            })
            setFilterValue(filterValue => [...filterValue, ...data1]);
            let updateddata = ([...filterValue, ...data1]);
            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: updateddata,
            }));
        }
        else {

            let currentdata = lastfilter.map((i) => {
                i["ischecked"] = false;
                return i;
            });
            setFilterValue([])
            setMultiFilterValue([])
        }
    }

    const filterHandler = (flag) => {

        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
        let pagenumber = 1;
        paging.pageNumber = pagenumber;

        if (flag === 'Apply') {
            setshowfilterpopup(false)
            let list = [];

            for (var key in multiFilterValue) {
                if (multiFilterValue[key].length > 0) {
                    let values = ""
                    multiFilterValue[key].map((i) => {
                        values += i.Name + ";";
                    })
                    if (values !== null && values !== '') {
                        list.push({
                            filterKey: key,
                            FilterValue: values
                        })
                    }
                }
            }

            for (var key in filterValueDict) {
                if (filterValueDict[key] !== null && filterValueDict[key] !== '') {
                    if (key === 'AccrualsAggregate') {
                        list.push({
                            filterKey: key,
                            FilterValue: filterValueDict[key],
                            operatorValue: operatorVal.value
                        })
                    }
                    else {
                        list.push({
                            filterKey: key,
                            FilterValue: filterValueDict[key]
                        })
                    }
                }
            }
            if (list.length > 0) {
                setpaging(paging);
                getAccrualListViewData("Accurals/GetAllAccrualsPosting", paging.pageSize, pagenumber, sortType, sortColName, list, searchValue);
            }
           
        }
        else {
            setshowfilterpopup(false)
            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: '',
            }));
            setFilterValueDict(() => ({
                ...filterValueDict, [filterCol]: '',
            }));

            let list = [];
            for (var key in multiFilterValue) {
                if (key !== filterCol && multiFilterValue[key].length > 0) {
                    let values = ""
                    multiFilterValue[key].map((i) => {
                        values += i.Name + ";";
                    })
                    if (values !== null && values !== '') {
                        list.push({
                            filterKey: key,
                            FilterValue: values
                        })
                    }
                }
            }

            for (var key in filterValueDict) {
                if (filterValueDict[key] !== null && filterValueDict[key] !== '' && key !== filterCol) {
                    list.push({
                        filterKey: key,
                        FilterValue: filterValueDict[key]
                    })
                }
            }

            setpaging(paging);
            getAccrualListViewData("Accurals/GetAllAccrualsPosting", paging.pageSize, pagenumber, sortType, sortColName, list, searchValue);

            
            setFilterCol('')
            setFilterValue('')
            setDropdownData([])
            setFilterDropdownData([])
            setFilterSearch("")
        }
    }

   
    const LoadMore = (lst, totalcount) => {
        if (lst < totalcount) {
            setshowLoadMore(true);
        }
        else if (lst >= totalcount) {
            setshowLoadMore(false);
        }
        else {
            setshowLoadMore(false);
        }
    }

    const customMultiStyles = {
        multiselectContainer: {
            maxWidth: '170px',
            marginLeft: '3px'
        },
        chips: {
            borderRadius: '5px'
        }
    };


    // -----------------------------------------------------------------
    const columnList = [
        { "label": "Accruals.RebateAgreementId", "key": "RebateHeaderId", "value": true },
        { "label": "Accruals.Customer", "key": "CustomerName", "value": true },
        { "label": "Accruals.RebateProgramId", "key": "RebateProgramLineId", "value": true },
        { "label": "Accruals.AccrualId", "key": "AccrualId", "value": true },
        { "label": "Accruals.RebatePeriod", "key": "FiscalYearPeriod", "value": true },
        { "label": "Accruals.AccrualAmount", "key": "AccrualsAggregate", "value": true },
        { "label": "Accruals.AutoPostAccrual", "key": "AutoPostAccruals", "value": true },
        { "label": "Accruals.AccrualPosted", "key": "AccrualsPosted", "value": true },
        { "label": "Accruals.PostedBy", "key": "PostedByUserName", "value": true },
        { "label": "Accruals.PostedOn", "key": "PostedDate", "value": true }
    ]
    const [addRemoveCol, setAddRemoveCol] = useState(columnList);
    const [createColumnListOption, setCreateColumnListOption] = useState(columnList)
    const [showAddColumnPopup, setShowAddColumnPopup] = useState(false)
    const [showCreateColumnListPopup, setShowCreateColumnListPopup] = useState(false)
    const [hideColumn, setHideColumn] = useState([]);
    const [resetColumnKey, setResetColumnKey] = useState([]);
    const [colVisibilityOption, setColVisibilityOption] = useState([]);
    const [colvisiOptionVal, setColvisiOptionVal] = useState({ value: null, label: 'Default' })
    const [divleft1, setdivleft1] = useState('');
    const [divtop1, setdivtop1] = useState('');
    const [existingViewVal, setExistingViewVal] = useState(false);
    const [defaultView, setDefaultView] = useState(false);
    const [colViewName, setColViewName] = useState('');
    const [recorddId, setRecordId] = useState(0);


    const OnChangedefaultView = (event,) => {
        setDefaultView(event.target.checked)
    }
    const OnChangeexistingViewVal = (event,) => {
        setExistingViewVal(event.target.checked)
    }

    const OnChangeSetViewName = (event,) => {
        setColViewName(event.target.value)
    }

    const OnChangeColVisibilityList = (data) => {
        setColvisiOptionVal({ value: data.value, label: data.label });
        getColVisibilityListData(data.value)
    }
    const CreateColumnListPopup = () => {
        setShowCreateColumnListPopup(true)
        var divtop1 = document.getElementById("addRemoveCol");
        setdivtop1(divtop1.offsetTop + 30)
        setdivleft1((divtop1.offsetLeft) + 100 + "px");
    }

    const CloseCreateColumnListPopup = () => {
        setShowCreateColumnListPopup(false)
        setColViewName('')
        setDefaultView(false)
    }

    const AddRemoveColumn = () => {
        setShowAddColumnPopup(true)
        var divtop1 = document.getElementById("addRemoveCol");
        setdivtop(divtop1.offsetTop + 45)
        setdivleft((divtop1.offsetLeft) + "px");
        getColumnVisibilityList()
    }
    const OnchangeAddRemoveCol = (event, column) => {
        let isChecked = event.target.checked;
        let lst = [...addRemoveCol];
        for (let d in lst) {
            if (lst[d].key == column.key && isChecked) {
                lst[d]['value'] = true
            }
            else if (lst[d].key == column.key && !isChecked) {
                lst[d]['value'] = false
            }
        }
        setResetColumnKey([...resetColumnKey, column.key])
        setAddRemoveCol(lst)
    }
    const OnchangeCreateColumnListOption = (event, column) => {
        let isChecked = event.target.checked;
        let lst = [...createColumnListOption];
        for (let d in lst) {
            if (lst[d].key == column.key && isChecked) {
                lst[d]['value'] = true
            }
            else if (lst[d].key == column.key && !isChecked) {
                lst[d]['value'] = false
            }
        }
        setCreateColumnListOption(lst)
    }
    const updateColumnVisibilityList = (tag) => {
        let lst = [...addRemoveCol];
        if (tag == 'reset') {
            for (let d in lst) {
                lst[d]['value'] = true
            }
            setHideColumn([])
            setAddRemoveCol(lst)
        }
        else {
            let data = [];
            for (let d in lst) {
                if (!lst[d].value) {
                    data.push(lst[d].key)
                }
            }
            setHideColumn(data)
        }
        let viewName = colvisiOptionVal.value;
        Service.setColumnVisibilityListData('ColumnVisibility/CreateOrUpdateColumnList', recorddId, loggedInUser, 'accrualPosting', viewName, existingViewVal, lst).then((res) => { })
        setShowAddColumnPopup(false)
        setResetColumnKey([])
    }


    const CreateColumnVisibilityList = (tag) => {
        let veiwName = colViewName;
        let idDefault = defaultView;
        if (veiwName.trim() == 'undefined' || veiwName.trim() == '' || veiwName.trim() == null) {
            setErrorStatus("Error")
            setErrorMessage("Please set View Name")
            setShowErrorModal(true)
        }
        else {
            let lst = [...createColumnListOption];
            if (tag == 'reset') {
                for (let d in lst) {
                    lst[d]['value'] = true
                }
                setHideColumn([])
                setAddRemoveCol(lst)
            }
            else {
                let data = [];
                for (let d in lst) {
                    if (!lst[d].value) {
                        data.push(lst[d].key)
                    }
                }
                setHideColumn(data)
            }
            Service.setColumnVisibilityListData('ColumnVisibility/CreateOrUpdateColumnList', 0, loggedInUser, 'accrualPosting', colViewName, defaultView, lst).then((res) => {
                getColumnVisibilityList()
                setColvisiOptionVal({ value: colViewName, label: colViewName });
                getColVisibilityListData(colViewName)
                CloseCreateColumnListPopup()
                setShowAddColumnPopup(false)
            })
        }
    }

    const closeAddRemoveColumnPopup = () => {
        let lst = [...addRemoveCol];
        let resetKey = [...resetColumnKey];
        for (let rk in resetKey) {
            for (let d in lst) {
                if (lst[d].key == resetKey[rk]) {
                    lst[d]['value'] = !lst[d].value
                }
            }
        }
        setAddRemoveCol(lst)
        setShowAddColumnPopup(false)
        setResetColumnKey([])

    }

    const getColumnVisibilityList = () => {
        Service.getColumnVisibilityListData('ColumnVisibility/GetColumnList', loggedInUser, 'accrualPosting', null).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {

                        if (typeof res.data !== 'undefined' && res.data !== 'null') {
                            let data = []
                            for (let d in res.data) {
                                data.push({ value: res.data[d].label, label: res.data[d].label })

                            }

                            setColVisibilityOption(data)
                        }
                    }
                }
            }
        })
    }


    const getColVisibilityListData = (viewName = null) => {
        if (!viewName) {
            viewName = colvisiOptionVal.value
        }
        if (viewName == 'Deleted') {
            viewName = null
        }
        Service.getColumnVisibilityListData('ColumnVisibility/GetColumnListJsonData', loggedInUser, 'accrualPosting', viewName).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (typeof res.data.jsonData !== 'undefined' && res.data.jsonData !== 'null') {

                            let jsonData = JSON.parse(res.data.jsonData)
                            let data = [];
                            for (let d in jsonData) {
                                if (!jsonData[d].value) {
                                    data.push(jsonData[d].key)
                                }
                            }
                            setColvisiOptionVal({ value: res.data.label, label: res.data.label });
                            setHideColumn(data)
                            setAddRemoveCol(jsonData)
                            setExistingViewVal(res.data.defaultView)
                            setResetColumnKey([])
                            setRecordId(res.data.id)
                        }
                    }
                }
            }
        })
    }

    const deleteColumnVisibilityView = () => {
        Service.deleteDataByPolicyID("ColumnVisibility/Delete", recorddId, loggedInUser).then(res => {
            if (res.status === 200) {
                getColumnVisibilityList()
                getColVisibilityListData('Deleted')
                setShowAddColumnPopup(false)
                toast.error('Deleted Successfully !', {
                    position: toast.POSITION.TOP_RIGHT,
                });
              
            }
        })
    }
    // ------------------------------------------------------------

    return (
        <div className="container-fluid content-top-gap">
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb my-breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">{t('Home.title')}</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">{t('Accruals.AccrualPosting')}</li>
                </ol>
            </nav>

            <div style={{ display: "none" }}>
                <CSVLink data={csvList} headers={headers} ref={csvInstance} filename="accrualsposting.csv" > </CSVLink>
            </div>

            <div className="">
                <div className="row">
                    <div className="col-lg-12 pr-lg-2">
                        <div className="card text-center card_border">

                            <div className="card-body" style={{ minHeight: "70vh" }}>
                                <div className="row ml-1" style={{ position: "sticky" }}>

                                    <div className="col-md-7">
                                        <div className="row">
                                            <input type="text" className="form-control form-control-user col-md-10" onChange={(event) => searchHandler(event)} id="firstName" autoComplete='off' name="firstName" placeholder={`${t('SearchFor')} ${t('Accruals.AgreementId')} , ${t('Accruals.Customer')} ,${t('Accruals.RebateProgramId')} ,${t('Accruals.AccrualId')} ,${t('Accruals.PostedBy')} `} />
                                            <button id="addRemoveCol" icon className="btn btn-update ml-2 mt-1 pa-0" type="submit"
                                                style={{ float: "left", cursor: "pointer", padding: '0px', height: '29px', width: '26px' }}
                                                onClick={AddRemoveColumn} title='Manage View'><i class="fa fa-columns" aria-hidden="true"></i></button>
                                        </div>
                                    </div>
                                    <div className="col-md-5">

                                        {totalselectedcount <= 0 ? "" : Service.exportPerms(userPerms) == false ? "" : <button className="btn btn-update  btn-sm ml-3" type="submit" style={{ float: "right", width: "50px" }} onClick={(event) => ExporttoCSV()}>{t('Export')}</button>}
                                        {showbutton === true ?
                                            <>
                                                {Service.editPerms(userPerms) == false ? "" : <button className="btn btn-update btn-sm ml-2" style={{ width: '100px', float: "right" }} onClick={PostHandler}>{t('Accruals.AccrualPosting')}</button>}
                                            </>
                                            : ''
                                        }

                                    </div>

                                </div>
                                <div className="scrollbar-class">
                                    <table className="table table-bordered text-left mt-2 table-responsive-sm" id="tableA">
                                        <thead className="text-white is-sticky-thead-product" style={{ backgroundColor: "var(--primary)" }}>
                                            <tr style={{ position: "sticky", top: "0", backgroundColor: "var(--primary)" }}>
                                                <th className="tableheader">
                                                    <input type="checkbox" id="option-all" onChange={checkAll} />
                                                </th>
                                                <th id="RebateHeaderId" style={{ backgroundColor: (filterValueDict.RebateHeaderId ? '#6cae55' : ''), minWidth: "140px" }} hidden={hideColumn.includes('RebateHeaderId') ? true : false}>
                                                    <div className="d-flex text-nowrap">
                                                        <span> {t('Accruals.RebateAgreementId')}</span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('RebateHeaderId')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('RebateHeaderId')}></span>
                                                    </div>
                                                </th>
                                                <th id="CustomerName" style={{ backgroundColor: (multiFilterValue.CustomerName ? '#6cae55' : ''), minWidth: "100px" }} hidden={hideColumn.includes('CustomerName') ? true : false}>
                                                    <div className="d-flex text-nowrap">
                                                        <span> {t('Accruals.Customer')}</span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('CustomerName')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('CustomerName')}></span>
                                                    </div>
                                                </th>
                                                <th id="RebateProgramLineId" style={{ backgroundColor: (filterValueDict.RebateProgramLineId ? '#6cae55' : ''), minWidth: "130px" }} hidden={hideColumn.includes('RebateProgramLineId') ? true : false}>
                                                    <div className="d-flex text-nowrap">
                                                        <span> {t('Accruals.RebateProgramId')}</span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('RebateProgramLineId')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('RebateProgramLineId')}></span>
                                                    </div>
                                                </th>
                                                <th id="AccrualId" style={{ backgroundColor: (filterValueDict.AccrualId ? '#6cae55' : ''), minWidth: "110px" }} hidden={hideColumn.includes('AccrualId') ? true : false}>
                                                    <div className="d-flex text-nowrap">
                                                        <span>{t('Accruals.AccrualId')}</span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('AccrualId')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('AccrualId')}></span>
                                                    </div>
                                                </th>
                                               
                                                <th id='FiscalYearPeriod' style={{ backgroundColor: (filterValueDict.FiscalYearPeriod ? '#6cae55' : ''), minWidth: "110px" }} hidden={hideColumn.includes('FiscalYearPeriod') ? true : false}>
                                                    <div className="d-flex text-nowrap">
                                                        <span> {t('Accruals.RebatePeriod')}</span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('FiscalYearPeriod')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('FiscalYearPeriod')}></span>
                                                    </div>
                                                </th>
                                                <th id="AccrualsAggregate" style={{ backgroundColor: (filterValueDict.AccrualsAggregate ? '#6cae55' : '') }} hidden={hideColumn.includes('AccrualsAggregate') ? true : false}>
                                                    <div className="d-flex text-nowrap">
                                                        <span> {t('Accruals.AccrualAmount')}</span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('AccrualsAggregate')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('AccrualsAggregate')}></span>
                                                    </div>
                                                </th>
                                                <th id="AutoPostAccruals" style={{ backgroundColor: (multiFilterValue.AutoPostAccruals ? '#6cae55' : ''), minWidth: "130px" }} hidden={hideColumn.includes('AutoPostAccruals') ? true : false}>
                                                    <div className="d-flex text-nowrap">
                                                        <span> {t('Accruals.AutoPostAccrual')}</span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('AutoPostAccruals')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('AutoPostAccruals')}></span>
                                                    </div>
                                                </th>
                                                <th id="AccrualsPosted" style={{ backgroundColor: (multiFilterValue.AccrualsPosted ? '#6cae55' : ''), minWidth: "110px" }} hidden={hideColumn.includes('AccrualsPosted') ? true : false}>
                                                    <div className="d-flex text-nowrap">
                                                        <span>{t('Accruals.AccrualPosted')}</span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('AccrualsPosted')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('AccrualsPosted')}></span>
                                                    </div>
                                                </th>
                                                <th id='PostedByUserName' style={{ backgroundColor: (multiFilterValue.PostedByUserName ? '#6cae55' : ''), minWidth: "100px" }} hidden={hideColumn.includes('PostedByUserName') ? true : false}>
                                                    <div className="d-flex text-nowrap">
                                                        <span>{t('Accruals.PostedBy')}</span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('PostedByUserName')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('PostedByUserName')}></span>
                                                    </div>
                                                </th>
                                                <th id='PostedDate' style={{ backgroundColor: (filterValueDict.PostedDate ? '#6cae55' : ''), minWidth: "100" }} hidden={hideColumn.includes('PostedDate') ? true : false}>
                                                    <div className="d-flex text-nowrap">
                                                        <span>{t('Accruals.PostedOn')}</span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('PostedDate')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('PostedDate')}></span>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                showspinner === true ?
                                                    <>
                                                        <Spinner animation="grow" size="sm" variant="success" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                        <Spinner animation="grow" size="sm" variant="danger" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                        <Spinner animation="grow" size="sm" variant="warning" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                    </>
                                                    :
                                                    filteredArray.length === 0 ?
                                                        <tr>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td>
                                                                {t('NoDataAvailable')}
                                                            </td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                        :
                                                        filteredArray.map((listVal, index) =>
                                                            <tr key={listVal.Rebate_id}>
                                                                <td><input id={[listVal.Id, listVal.AutoPostAccruals, listVal.AccrualsPosted]} type="checkbox" name="chkBox" value={listVal.Rebate_id} onChange={(event) => handleChange(event, listVal)}
                                                                
                                                                /></td>
                                                                <td style={{ maxWidth: "140px" }} hidden={hideColumn.includes('RebateHeaderId') ? true : false}>{listVal.RebateHeaderId}</td>
                                                              
                                                                <td style={{ width: "160px" }} hidden={hideColumn.includes('CustomerName') ? true : false}>
                                                                    <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }}
                                                                        data-toggle="tooltip" data-placement="right" title={listVal.CustomerName}>{listVal.CustomerName}
                                                                    </div>
                                                                </td>
                                                                <td style={{ maxWidth: "130px" }} hidden={hideColumn.includes('RebateProgramLineId') ? true : false} >{listVal.RebateProgramLineId}</td>
                                                                <td style={{ maxWidth: "145px" }} hidden={hideColumn.includes('AccrualId') ? true : false}><Link to={`/accruals/new/${listVal.Id}/accrualsposting`}>
                                                                    {listVal.AccrualId}&nbsp;</Link>
                                                                </td>
                                                               
                                                                <td style={{ maxWidth: "110px" }} hidden={hideColumn.includes('FiscalYearPeriod') ? true : false}>{listVal.FiscalYearPeriod}</td>

                                                                <td style={{ maxWidth: "110px" }} hidden={hideColumn.includes('AccrualsAggregate') ? true : false}>
                                                                    {listVal.Currency} {Service.currencyFormat(parseFloat(listVal.AccrualsAggregate).toFixed(SessionManage.getroundingPrecision()))}
                                                                </td>
                                                                <td style={{ maxWidth: "130px" }} hidden={hideColumn.includes('AutoPostAccruals') ? true : false}><input type="checkbox" checked={listVal.AutoPostAccruals} disabled /></td>
                                                                <td style={{ maxWidth: "110px" }} hidden={hideColumn.includes('AccrualsPosted') ? true : false}><input type="checkbox" checked={listVal.AccrualsPosted} disabled /></td>

                                                                <td style={{ maxWidth: "110px" }} hidden={hideColumn.includes('PostedByUserName') ? true : false}>{listVal.PostedByUserName}</td>
                                                                {listVal.PostedDate === null || listVal.PostedDate === '' ?
                                                                    <td hidden={hideColumn.includes('PostedDate') ? true : false}></td>
                                                                    :
                                                                    <td hidden={hideColumn.includes('PostedDate') ? true : false}>{moment(listVal.PostedDate).format(SessionManage.getcurrentdateformat())}</td>
                                                                }
                                                            </tr>
                                                        )

                                            }

                                        </tbody>
                                    </table>
                                </div>

                                {
                                    showLoadMore === true ?
                                        <div className='text-center container row'>
                                            <div className='col-md-5'></div>
                                            <div className='col-md-2'>
                                                <button className='btn btn-sm btn-update ml-1' onClick={handleLoadMore} style={{ width: "4.375rem" }}>{t('LoadMore')}</button>
                                            </div>
                                            <div className='col-md-5'></div>

                                        </div>
                                        :
                                        ""
                                }
                                <div className='row'>
                                    {
                                        totalselectedshow === true ?

                                            <div className='col-lg-2 forgettext col-sm-12 border form-control' style={{ textAlign: "center", marginLeft: "0.938rem" }}>
                                                {t('TotalRecordsSelected')} - <span id="totalselected">{totalselectedcount}</span>
                                            </div>

                                            :
                                            <div className='col-lg-2  col-sm-12' style={{ textAlign: "center" }}>

                                            </div>
                                    }



                                    <div className='col-lg-7 col-sm-12'></div>
                                    <div className='col-lg-2 forgettext  col-sm-12 border form-control' style={{ textAlign: "center" }}>
                                        {t('TotalRecords')} - {totalCountlst}
                                    </div>
                                </div>
                            </div>

                            {showfilterpopup === true ?
                                <div className='pop-up' style={{ left: divleft, top: divtop, width: "200px" }}>
                                    {filterCol === 'RebateHeaderId' || filterCol === 'RebateProgramLineId' || filterCol === 'AccrualId' || filterCol === 'status' || filterCol === 'version' || filterCol === 'FiscalYearPeriod' ?
                                        <>
                                            <div className="container">
                                                <div className="row">
                                                    <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>
                                                </div>
                                            </div>
                                            <div className="container mt-2">
                                                <div className="row">
                                                    <input type='textbox' placeholder='Search...' className='filtertextboxbackground col-md-11 textbox-padding ml-2' value={filterValueDict[filterCol]} name={filterCol} onChange={(event) => filterInput(event)}></input>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <></>

                                    }

                                    {filterCol === 'PostedDate' ?
                                        <>
                                            <div className="container">
                                                <div className="row">
                                                    <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>
                                                </div>
                                            </div>
                                            <div className="container mt-2">
                                                <div className="row">
                                                    <input type='date' placeholder='Search...' className='filtertextboxbackground col-md-11 textbox-padding ml-2' value={filterValueDict[filterCol]} onChange={(event) => filterInput(event)}></input>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <></>
                                    }
                                    {filterCol === 'AccrualsAggregate' ?
                                        <div>
                                            <div className="row">
                                                <div className="row" style={{ marginLeft: "40px", paddingBottom: "5px" }}>
                                                    <b>{filtertext}</b>
                                                </div>
                                                <Select
                                                    className='col-md-9 textbox-padding ml-4 fontsizedropdown'
                                                    options={operatorValOption}
                                                    placeholder="Select"
                                                    value={operatorVal}
                                                    onChange={OnChangeOperatorValue}
                                                    styles={customStyles}
                                                />
                                            </div>
                                            <div className="mt-2">
                                                <input type='text' className='col-md-10 ml-3' onKeyPress={(e) => !/^(\d)*(\.)?([0-9]{1})?$/.test(e.key) && e.preventDefault()}
                                                    value={filterValueDict[filterCol]} onChange={(event) => filterInput(event)} />
                                            </div>
                                        </div>
                                        :
                                        <></>
                                    }
                                   
                                    {filterCol === 'CustomerName' || filterCol === 'PostedByUserName' || filterCol === 'AutoPostAccruals' || filterCol === 'AccrualsPosted' ?
                                       
                                        <>
                                            <div className="container">
                                                <div className="row">
                                                    <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>
                                                </div>

                                            </div>

                                            <div className="container mt-2">
                                                <div className="row">
                                                    <input type="text" placeholder="Search" value={filterSearch} onChange={(event) => UserfilterHandler(event)} className='filtertextboxbackground col-md-11 textbox-padding ml-2' />
                                                </div>

                                                <div className="row contentoverflow mt-2">
                                                    <table className="filtertable">
                                                        <tbody>
                                                            <tr>
                                                                <td><input type="checkbox" name="filter-option-all" id="option-all" onChange={checkAllFilter} /></td>
                                                                <td>Select All</td>
                                                            </tr>
                                                            {
                                                                dropdownData.map((item, index) => (

                                                                    <tr key={index}>

                                                                        <td><input id={item.Id} data-index="1" name="filter-option-all" checked={item.ischecked} type="checkbox" onChange={(event) => OndropdownChange(event, item.Id, item.Name)} /></td>

                                                                        <td className="hovr" style={{ overflow: "hidden", display: "block", maxWidth: "163px" }}>{item.Name}</td>

                                                                    </tr>


                                                                ))
                                                            }

                                                        </tbody>
                                                    </table>

                                                </div>
                                            </div>
                                        </>
                                        :
                                        <></>
                                    }
                                    <div style={{ paddingTop: '10px', paddingLeft: '35px', paddingBottom: '7px' }}>
                                        <button className="btn btn-sm btn-update" style={{ width: "30px" }} onClick={() => filterHandler('Apply')}>{t('Ok')}</button>
                                        <button className="btn btn-sm btn-update" style={{ marginLeft: '10px', width: "3.438rem" }} onClick={() => filterHandler('Reset')}>{t('Remove')}</button>
                                    </div>
                                    <div>
                                    </div>
                                </div>
                                :
                                <></>
                            }

                            {showAddColumnPopup === true ?
                                <div className='pop-up' style={{ left: divleft, top: divtop, width: "250px" }}>
                                    <div className="container">
                                        <div className="row">
                                            <Select
                                                className='col-md-10 textbox-padding fontsizedropdown'
                                                options={colVisibilityOption}
                                                placeholder="Select"
                                                value={colvisiOptionVal}
                                                onChange={OnChangeColVisibilityList}
                                                styles={customStyles}
                                            />
                                            <button id="createColumnListPopup" icon className="btn btn-update ml-1 pa-0" type="submit"
                                                style={{ float: "left", cursor: "pointer", padding: '0px', height: '28px', width: '26px' }}
                                                onClick={CreateColumnListPopup} title='Add New View'><i class="fa fa-plus" aria-hidden="true"></i></button>
                                        </div>
                                        <div className="row mb-2" style={{ textAlign: 'right' }}>
                                            <div className="col-md-10 mt-1 mr-1" style={{ padding: '0px', fontSize: '12px' }}>
                                                <input id="existingViewVal" data-index="1" name="filter-option-all" checked={existingViewVal} type="checkbox"
                                                    onChange={(event) => OnChangeexistingViewVal(event)} /> <b>Is Default</b>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <table className='ml-2' style={{ fontSize: '0.80rem' }}>
                                                {
                                                    addRemoveCol.map((item, index) => (
                                                        <tr>
                                                            <td key={index}><input id={item.label} data-index="1" name="filter-option-all" checked={item.value} type="checkbox"
                                                                onChange={(event) => OnchangeAddRemoveCol(event, item)}
                                                            /> {t(item.label)}</td>
                                                        </tr>
                                                    ))
                                                }
                                            </table>
                                        </div>
                                        <div className="row mr-2" style={{ float: "right" }}>
                                            <div style={{ paddingTop: '10px', paddingLeft: '15px', paddingBottom: '7px' }}>
                                                <button className="btn btn-sm btn-update" style={{ width: "45px" }} onClick={() => updateColumnVisibilityList('reset')}>{t('Reset')}</button>
                                                <button className="btn btn-sm btn-update ml-1" style={{ width: "45px" }} onClick={closeAddRemoveColumnPopup}>{t('Close')}</button>
                                                <button className="btn btn-sm btn-update ml-1" style={{ width: "50px", background: '#4755ab', color: 'white' }} onClick={() => updateColumnVisibilityList('apply')} title='Update View'>{t('Update')}</button>
                                                <button className="btn btn-sm btn-update ml-1" style={{ width: "50px", background: 'rgb(233 43 0)', color: 'white' }} onClick={deleteColumnVisibilityView} title='Delete View'>{t('Delete')}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : <></>
                            }


                            {showCreateColumnListPopup === true ?
                                <div className='pop-up' style={{ left: divleft1, top: divtop1, width: "190px" }}>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-12 ml-2" style={{ padding: '0px' }}>
                                                <input type='textbox' placeholder='View Name' style={{ height: '30px' }}
                                                    value={colViewName} name={colViewName} onChange={(event) => OnChangeSetViewName(event)}></input>
                                            </div>
                                        </div>
                                        <div className="row mb-2" style={{ textAlign: 'right' }}>
                                            <div className="col-10 mt-2" style={{ padding: '0px', fontSize: '12px' }}>
                                                <input id="defaultView" data-index="1" name="filter-option-all" checked={defaultView} type="checkbox"
                                                    onChange={(event) => OnChangedefaultView(event)} /> <b>Set Default</b>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <table className='ml-2' style={{ fontSize: '0.80rem' }}>
                                                {
                                                    createColumnListOption.map((item, index) => (
                                                        <tr>
                                                            <td key={index}><input id={item.label} data-index="1" name="filter-option-all" checked={item.value} type="checkbox"
                                                                onChange={(event) => OnchangeCreateColumnListOption(event, item)}
                                                            /> {t(item.label)}</td>
                                                        </tr>
                                                    ))
                                                }
                                            </table>
                                        </div>
                                        <div className="row mr-2" style={{ float: "right" }}>
                                            <div style={{ paddingTop: '10px', paddingLeft: '35px', paddingBottom: '7px' }}>
                                                <button className="btn btn-sm btn-update ml-1" style={{ width: "50px" }} onClick={CloseCreateColumnListPopup}>{t('Cancel')}</button>
                                                <button className="btn btn-sm btn-update ml-1" style={{ width: "40px", background: '#4755ab', color: 'white' }} onClick={() => CreateColumnVisibilityList('apply')}>{t('Save')}</button>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                                : <></>
                            }

                        </div>
                        <CustomErrorPopup ErrorMessage={errorMessage} show={showErrorModal} ErrorStatus={errorStatus}
                            onHide={() => setShowErrorModal(false)}></CustomErrorPopup>
                        <ToastContainer autoClose={1000} />
                    </div>

                </div>
            </div>

        </div>


    )
}
export default AccuralsPostingComponent;
