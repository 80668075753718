import { Link, useLocation } from "react-router-dom"
import React, { useState, useEffect, useRef } from 'react'
import Service from "../../Services/Service";
import { useNavigate } from 'react-router-dom';
import { Form, FormGroup, Col, Row, Button, InputGroup, Collapse, Modal } from 'react-bootstrap';
import moment from 'moment';
import Spinner from 'react-bootstrap/Spinner';
import Multiselect from 'multiselect-react-dropdown';
import SessionManage from "../../SessionManagement/SessionManage";
import { useTranslation } from "react-i18next";

import { CSVLink } from "react-csv";

function SchedulerListComponent(props) {
    const [t, i18n] = useTranslation('translation');
    const [searchValue, setSearchValue] = useState(null);
    const location = useLocation();
    const csvInstance = useRef();
    const [csvList, setCsvList] = useState([]);
    const [sticky, setSticky] = useState("");
    const [sortColName, setSortColName] = useState("");
    const [userPerms, setuserPermission] = useState([]);
    const [data, setdata] = useState([]);
    const [showspinner, setshowspinner] = useState('');
    const [checkedValueid, setCheckedValueid] = useState([]);
    const [filteredArray, setFilteredArray] = useState(data)
    const [showLoadMore, setshowLoadMore] = useState('');
    const [showbutton, setshowbutton] = useState('');
    const [filterValue, setFilterValue] = useState('');
    const [filtertext, setfiltertext] = useState('');
    const [filterCol, setFilterCol] = useState('');
    const [dropdownData, setDropdownData] = useState([]);
    const [showfilterpopup, setshowfilterpopup] = useState(false);
    const [columnAscending, setColumnAscending] = useState(true);
    const [totalselectedshow, settotalselectedshow] = useState('');
    const [totalselectedcount, settotalselectedcount] = useState(0);
    const [totalCountlst, settotalCountlst] = useState('');
    const [ToggleStatusModal, setToggleStatusModal] = useState(false);
    const [toggleChange, setToggleChange] = useState("");
    const [toggleId, setToggleId] = useState();
    const [toggleUserId, setToggleUserId] = useState();
    const [filterdropdownData, setFilterDropdownData] = useState([]);
    const [filterSearch, setFilterSearch] = useState("");

    const [dropdownChange, setDropdownChange] = useState('');
    const [filterValueDict, setFilterValueDict] = useState({
        firstname: '',
        lastname: '',
        username: '',
        email: '',
        roles: '',

        created_date: null,
        last_modified_date: null,
    });
    const [paging, setpaging] = useState({
        pageSize: 20,
        pageNumber: 1
    });
    const [multiFilterValue, setMultiFilterValue] = useState({
        createdby: '',
        last_modified_by: '',
        statusName: '',
        frequencyname: ''
    });
    const getUserPermission = (pathname) => {

        Service.GetUserPermission(pathname).then((res) => {
            if (typeof res !== 'undefined') {
                if (res.data !== 'null' && typeof res.data !== 'undefined') {
                    if (res.data.DataList !== 'undefined') {
                        setuserPermission(res.data.DataList)
                    }
                }
            }
        });
    }
    const activeOption = [
        { Name: "Active", Id: "1" },
        { Name: "In-Active", Id: "0" },

    ]
    const checkAll = (mycheckbox) => {
        var checkboxes = document.querySelectorAll("input[type = 'checkbox']");
        if (mycheckbox.target.checked == true) {
            settotalselectedshow(true);
            checkboxes.forEach(function (checkbox) {
                checkbox.checked = true;
            });
            settotalselectedcount(checkboxes.length - 1)
        }
        else {
            settotalselectedshow(false);
            checkboxes.forEach(function (checkbox) {
                checkbox.checked = false;
            });
            settotalselectedcount(0)
        }

    }

    const LoadMore = (lst, totalcount) => {

        if (lst < totalcount) {
            setshowLoadMore(true);
        }
        else if (lst >= totalcount) {
            setshowLoadMore(false);
        } else {
            setshowLoadMore(false);
        }

    }
    const navigate = useNavigate();

    useEffect(() => {
        setColumnAscending(false);
        setSortColName("Id");
        setshowspinner(true);
        getUserPermission(location.pathname);
        getData("SchedulerJob/GetALLSchedulerDetail", paging.pageSize, paging.pageNumber, "Descending", "Id", [], null);
        DropdownHandler("users")
        window.addEventListener("scroll", isSticky);
        return () => {
            window.removeEventListener("scroll", isSticky);
        };

    }, [0]);
    const isSticky = () => {
        const scrollTop = window.scrollY;
        const stickyClass = scrollTop >= 60 ? "is-sticky-thead" : "";
        setSticky(stickyClass);
        const scrollTopActual = scrollTop >= 60 ? scrollTop - 85 : 0;
        var divtop1 = document.getElementById("tableA");
        setdivtop(divtop1.offsetTop + scrollTopActual)
    };
    const class_thead = `text-white rt-thead  ${sticky}`;

    const handleLoadMore = () => {
        let pagenumber = paging.pageNumber + 1;
        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
        Service.getDatawithMultiFilterSearch("SchedulerJob/GetALLSchedulerDetail", paging.pageSize, pagenumber, sortType, sortColName, filterLst(), searchValue).then((res) => {
            if (typeof res !== 'undefined') {
                if (res.data !== 'null' && typeof res.data !== 'undefined') {
                    const currentlength = data.length;
                    setdata(data => [...data, ...res.data.DataList])
                    setFilteredArray(filteredArray => [...filteredArray, ...res.data.DataList])
                    LoadMore(currentlength + res.data.DataList.length, res.data.Totalcount)
                }
            }
        });
      
        paging.pageNumber = pagenumber
        setpaging(paging);
    };

    let role = [];

    const getData = (method, pageSize, pageNumber, sortType, SortProperty, filterValue, search) => {
        Service.getDatawithMultiFilterSearch(method, pageSize, pageNumber, sortType, SortProperty, filterValue, search).then((res) => {
            if (typeof res !== 'undefined') {
                if (res.data !== 'null' && res.data !== 'undefined') {
                    setdata(res.data.DataList);
                  
                    setFilteredArray(res.data.DataList)
                    settotalCountlst(res.data.Totalcount)
                  
                    LoadMore(res.data.DataList.length, res.data.Totalcount)
                    setshowspinner(false)
                }
            }
        });

    }

  


    const handleChange = (e, id) => {
        let isChecked = e.target.checked;   // true if it is checked false if unchecked
      
        let checkedBoxes = document.querySelectorAll('input[name=chkBox]:checked');
        if (isChecked === true) {
            settotalselectedshow(true);
            settotalselectedcount(checkedBoxes.length);
           
            setCheckedValueid(id)
        }
        else {
           
            setCheckedValueid([])
            if (checkedBoxes.length === 0) {
                settotalselectedshow(false);
            }
            settotalselectedcount(checkedBoxes.length);
            
        }


        if (checkedBoxes.length == 1) {
            setshowbutton(true);
        }
        else {
            setshowbutton(false);
        }
    }
    const OndropdownChange = (e, id, name) => {
       
        let isChecked = e.target.checked;
        var lastfilter = [...dropdownData]
        if (isChecked === true) {
            let currentdata = lastfilter.map((i) => {
                if (i.Id == id) {
                    i["ischecked"] = true;
                }
                return i;
            });
            
            setDropdownData(currentdata);
            let data1 = []
            const getallData = {
                Name: name,
            }
            data1.push(getallData)
            setFilterValue(filterValue => [...filterValue, ...data1]);
          
            let updateddata = ([...filterValue, ...data1]);
            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: updateddata,
            }));
        }
        else {
            let currentdata = lastfilter.map((i) => {
                if (i.Id === id) {
                    i["ischecked"] = false;
                }
                return i;
            });
          
            setDropdownData(currentdata);
            setFilterValue(filterValue => filterValue.filter(data => data.Name !== name))
            let data1 = filterValue.filter(data => data.Name !== name);
            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: data1,
            }));
        }
    }

    const lastmodifiedChange = (e, id, name) => {
        let isChecked = e.target.checked;
        var lastfilter = [...dropdownData]
        if (isChecked === true) {
            let currentdata = lastfilter.map((i) => {
                if (i.Id === id) {
                    i["ischecked"] = true;
                }

                return i;
            });
            
            setDropdownData(currentdata);
       
            let data1 = []
            const getallData = {
                Name: name,
            }
            data1.push(getallData)
            setFilterValue(filterValue => [...filterValue, ...data1]);
          
            let updateddata = ([...filterValue, ...data1]);
            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: updateddata,
            }));
        } else {
            let currentdata = lastfilter.map((i) => {
                if (i.Id === id) {
                    i["ischecked"] = false;
                }

                return i;
            });
            
            setDropdownData(currentdata);
        
            setFilterValue(filterValue => filterValue.filter(data => data.Name !== name))
            let data1 = filterValue.filter(data => data.Name !== name);
            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: data1,
            }));
        }

    }
    const customMultiStyles = {
        multiselectContainer: {
            maxWidth: '188px',
            marginLeft: '3px',
            maxHeight: '100px'
        },
        chips: {
            borderRadius: '5px'
        }
    };
    const OpenHandler = (id) => {
        if (id == null || id == 0) {
            alert('Please select the User to view')
        }
        else {
            navigate("/Schedulerhistory/" + id);
        }
    }

    const searchHandler = (event) => {
        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
     
        let pagenumber = 1;
      
        paging.pageNumber = pagenumber;
        setpaging(paging);
        if (event.target.value != '') {
          
            setSearchValue(String(event.target.value).toLowerCase())
            getData("SchedulerJob/GetALLSchedulerDetail", paging.pageSize, pagenumber, sortType, sortColName, filterLst(), event.target.value);
        }
        else {
            setSearchValue(null);
            getData("SchedulerJob/GetALLSchedulerDetail", paging.pageSize, pagenumber, sortType, sortColName, filterLst(), null);
        }
    }


    const UserfilterHandler = (e) => {
        setFilterSearch(e.target.value)
        if (e.target.value !== "") {
            let searchResults = dropdownData.filter((each) => {
                return each.Name.toLowerCase().includes(String(e.target.value).toLowerCase())
            });
            setDropdownData(searchResults);
        }
        else {
           
            setFilterSearch("")
            setDropdownData(filterdropdownData)
        }
    }


    const checkAllFilter = (mycheckbox) => {
        var lastfilter = [...dropdownData]
        let data1 = []
        var checkboxes = document.querySelectorAll("input[name = 'filter-option-all']");
        if (mycheckbox.target.checked == true) {
            checkboxes.forEach(function (checkbox) {
                checkbox.checked = true;
            });
          
            dropdownData.map((each) => {
              
                const getallData = {
                    Name: each.Name,
                }
                data1.push(getallData)
            })
            setFilterValue(filterValue => [...filterValue, ...data1]);
            let updateddata = ([...filterValue, ...data1]);
            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: updateddata,
            }));
        }
        else {
            checkboxes.forEach(function (checkbox) {
                checkbox.checked = false;
            });
            setFilterValue([])
            setMultiFilterValue([])
        }

    }


    const ToggleUserActiveStatus = (id, user_id, flag) => {
        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
        setToggleId(id)
        setToggleUserId(user_id)
        if (flag == "Open-A") {
            setToggleStatusModal(true)
            setToggleChange("DeActivate")
        }
        if (flag == "Open-D") {
            setToggleStatusModal(true)
            setToggleChange("Activate")
        }
        if (flag == "Ok") {
            Service.ToggleUserActiveStatus('ToggleUserActiveStatus', id, user_id).then((res) => {
                if (typeof res !== 'undefined') {
                    if (typeof res.data !== 'undefined' && res.data !== 'null') {
                        if (res.status === 200) {
                            getData("SchedulerJob/GetALLSchedulerDetail", paging.pageSize, paging.pageNumber, sortType, sortColName, filterLst(), searchValue)
                        }
                        setToggleStatusModal(false)
                    }
                }

            });
        }
        if (flag == "Cancel") {
            setToggleStatusModal(false)
        }
    }

    const OpenUser = (event, id, name) => {
        if (event.detail === 2) {
            
            navigate("/Schedulerhistory/" + id);

        }
    }


    const filterInput = (e) => {
       
        let inputValue = e.target.value
        setFilterValue(inputValue)
        
        setFilterValueDict(() => ({
            ...filterValueDict, [filterCol]: e.target.value,
        }));
    }

    const [divleft, setdivleft] = useState('');
    const [divtop, setdivtop] = useState('');


    const FilterColumn = (name) => {
        
        const scrollTop = window.scrollY;
        const scrollTopActual = scrollTop >= 60 ? scrollTop - 85 : 0;
        var divtop1 = document.getElementById("tableA");
        setdivtop(divtop1.offsetTop + scrollTopActual + 30)
        setshowfilterpopup(true)
        setFilterCol(name)

        if (name === 'schedulerName') {
          
            setfiltertext(t('Schedular.SchedulerName'))
            var testDiv = document.getElementById("schedulerName");
            setdivleft(testDiv.offsetLeft + 13 + "px");
        }
        if (name === 'CronExpression') {
            setfiltertext(t('Schedular.CronExpression'))
            var testDiv = document.getElementById("CronExpression");
            setdivleft(testDiv.offsetLeft + 13 + "px");
        }

        if (name === 'schedulerTypeName') {
            setfiltertext(t('Schedular.SchedulerType'))
            var testDiv = document.getElementById("SchedulerType");
            setdivleft(testDiv.offsetLeft + 13 + "px");
        }
        if (name === 'moduleName') {
            setfiltertext(t('Schedular.ModuleName'))
            var testDiv = document.getElementById("ModuleName");
            setdivleft(testDiv.offsetLeft + 13 + "px");
        }
        if (name === 'frequecyName') {
            setfiltertext(t('Schedular.FrequecyName'))
            var testDiv = document.getElementById("FrequecyName");
            setdivleft(testDiv.offsetLeft + 13 + "px");
        }
        if (name === 'created_date') {
            setfiltertext(t('DataImport.CreatedDate'))
            var testDiv = document.getElementById("created_date");
            setdivleft(testDiv.offsetLeft + 13 + "px");
        }
        if (name === 'nextexecution') {
            setfiltertext(t('Schedular.NextExecution'))
            var testDiv = document.getElementById("nextexecution");
            setdivleft(testDiv.offsetLeft + 13 + "px");
        }
        if (name === 'last_modified_by') {
            setfiltertext(t('LastModifiedBy'))
            var testDiv = document.getElementById("last_modified_by");
            setdivleft(testDiv.offsetLeft + 13 + "px");
            DropdownHandler(name)

        }
        if (name === 'statusName') {
            setfiltertext(t('Schedular.Status'))
            var testDiv = document.getElementById("status");
            setdivleft(testDiv.offsetLeft + 13 + "px");
            addnewfield(activeOption, name)
        }
    }

    const addnewfield = (data, key) => {
      
      
        let currentdata = data.map((i) => {
            i["ischecked"] = false;
            return i;
        });
        if (key == 'last_modified_by') {
            let selectedKeys = multiFilterValue.last_modified_by;
            if (selectedKeys != '') {
                currentdata.forEach((each) => {
                    selectedKeys.forEach((eachSelected) => {
                        if (each.Name == eachSelected.Name) {
                            each.ischecked = true
                        }
                    })
                })
            }
            setDropdownData(currentdata);
            setFilterDropdownData(currentdata)
        }
        else {
            let selectedKeys = multiFilterValue.statusName
            if (selectedKeys != '') {
                currentdata.forEach((each) => {
                    selectedKeys.forEach((eachSelected) => {
                        if (each.Name == eachSelected.Name) {
                            each.ischecked = true
                        }
                    })
                })
                setDropdownData(currentdata)
                setFilterDropdownData(currentdata)
            }
            else {
                setDropdownData(currentdata)
                setFilterDropdownData(currentdata)
            }
        }
    }
   
    const DropdownHandler = (key) => {
        Service.getfilterDropdown('Permission/GetFilterValue', key).then((res) => {
            if (typeof res !== 'undefined') {

                if (res.data !== 'null' && typeof res.data !== 'undefined') {
                    addnewfield(res.data, key);
                 
                }
            }
        });
    }
    const filterHandler = (flag) => {
  
        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
        let pagenumber = 1;
        paging.pageNumber = pagenumber;

        if (flag === 'Apply') {
            setshowfilterpopup(false)
            let list = [];

            for (var key in multiFilterValue) {
                if (multiFilterValue[key].length > 0) {
                    let values = ""
                    multiFilterValue[key].map((i) => {
                        values += i.Name + ";";
                    })
                    if (values !== null && values !== '') {
                        list.push({
                            filterKey: key,
                            FilterValue: values
                        })
                    }
                }
            }

            for (var key in filterValueDict) {
                if (filterValueDict[key] !== null && filterValueDict[key] !== '') {
                    list.push({
                        filterKey: key,
                        FilterValue: filterValueDict[key]
                    })
                }
            }
            if (list.length > 0) {
                setpaging(paging);
                getData("SchedulerJob/GetALLSchedulerDetail", paging.pageSize, pagenumber, sortType, sortColName, list, searchValue);
            }
            
        }
        else {
            setshowfilterpopup(false)
            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: '',
            }));
            setFilterValueDict(() => ({
                ...filterValueDict, [filterCol]: '',
            }));

            let list = [];
            for (var key in multiFilterValue) {
                if (key !== filterCol && multiFilterValue[key].length > 0) {
                    let values = ""
                    multiFilterValue[key].map((i) => {
                        values += i.Name + ";";
                    })
                    if (values !== null && values !== '') {
                        list.push({
                            filterKey: key,
                            FilterValue: values
                        })
                    }
                }
            }

            for (var key in filterValueDict) {
                if (filterValueDict[key] !== null && filterValueDict[key] !== '' && key !== filterCol) {
                    list.push({
                        filterKey: key,
                        FilterValue: filterValueDict[key]
                    })
                }
            }
            setpaging(paging);
            getData("SchedulerJob/GetALLSchedulerDetail", paging.pageSize, pagenumber, sortType, sortColName, list, searchValue);

        
            setFilterCol('')
            setFilterValue('')
            setDropdownData([])
            setFilterDropdownData([])
            setDropdownChange("")
        }
    }



    const SortColumn = (colName) => {
        var sortType = '';
        if (columnAscending === true) {
            setColumnAscending(false);
            sortType = "Descending";
        } else {
            setColumnAscending(true);
            sortType = "Ascending";
        }
        setSortColName(colName);
        
        let pagenumber = 1;
       
        paging.pageNumber = pagenumber;
        setpaging(paging);
        getData("SchedulerJob/GetALLSchedulerDetail", paging.pageSize, paging.pageNumber, sortType, colName, filterLst(), searchValue);
    }
    const filterLst = () => {
        let list = [];
        for (var key in multiFilterValue) {
            if (multiFilterValue[key].length > 0) {
                let values = ""
                multiFilterValue[key].map((i) => {
                    values += i.Name + ";";
                })
                if (values !== null && values !== '') {
                    list.push({
                        filterKey: key,
                        FilterValue: values
                    })
                }
            }
        }

        for (var key in filterValueDict) {
            if (filterValueDict[key] !== null && filterValueDict[key] !== '') {
                list.push({
                    filterKey: key,
                    FilterValue: filterValueDict[key]
                })
            }
        }
        return list;
    }
    let headers = [
        { label: "First Name", key: "FirstName" },
        { label: "Last Name ", key: "LastName" },
        { label: "UserName ", key: "Username" },
        { label: "Email ", key: "Email" },

        { label: "Last Modified By", key: "LastModifiedUser_Name" },
        { label: "Last Modified Date", key: "LastModifiedDate" },
        { label: "Is Active ", key: "IsActive" },
    ];
    const ExporttoCSV = () => {
        let checkedBoxes = document.querySelectorAll("input[name=chkBox]:checked");
        let values = "";
        if (checkedBoxes.length >= 1) {
            for (let checkbox of checkedBoxes) {
                if (values === "") {
                    values = checkbox.id;
                } else {
                    values = values + ";" + checkbox.id;
                }
            }
        }
        let objFilter = [];
        let objFilterValue = [
            {
                filterKey: "user_id",
                FilterValue: values,
            },
        ];
        if (totalCountlst != checkedBoxes.length) {
            objFilter = objFilterValue;
        }
        Service.getDatawithMultiFilterSearch("SchedulerJob/GetALLSchedulerDetail", 20000, 1, "Ascending", "Id", objFilter, null).then((res) => {
          
            if (typeof res !== "undefinfilterHandlered") {
                if (res.data !== "null" && res.data !== "undefined") {
                    setCsvList(res.data.DataList);
                    setTimeout(() => {
                        csvInstance.current.link.click();
                    });
                }
            }
        });
    };

    return (<>
        <div className="container-fluid content-top-gap">

            <nav aria-label="breadcrumb">
                <ol className="breadcrumb my-breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">{t('Home.title')}</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">{t('Schedular.SchedulerList')}</li>
                   
                </ol>
            </nav>
            <div style={{ display: "none" }}>
                <CSVLink data={csvList} headers={headers} ref={csvInstance} filename="users.csv" > </CSVLink>
            </div>
            <div className="">
                <div className="row">
                    <div className="col-lg-12 pr-lg-2">
                        <div className="card text-center card_border">

                            <div className="card-body" style={{ minHeight: "70vh" }}>
                                <div className="row">

                                    <div className="col-md-8">
                                        <input type="text" className="form-control form-control-user" autoComplete='no' id="firstName" name="firstName" placeholder={t('Schedular.SearchHeader')} onChange={(event) => searchHandler(event)} />
                                    </div>
                                    <div className="col-md-4">
                                        {Service.editPerms(userPerms) == false ? "" : <Link className="btn btn-update btn-sm ml-2" id="create" style={{ width: "90px", backgroundColor: "buttonface", float: "right" }} to="/Createscheduler">{t('CreateNew')}</Link>}
                                        {totalselectedcount <= 0 ? "" : Service.exportPerms(userPerms) == false ? "" : <button className="btn btn-update  btn-sm ml-2" type="submit" style={{ float: "right",width: "50px" }} onClick={(event) => ExporttoCSV()}>{t('Export')}</button>}


                                        {showbutton === true ?
                                            <>
                                                <button className="btn btn-update btn-sm ml-2" id="open" type="submit" style={{ float: "right",width: "40px" }} onClick={() => OpenHandler(checkedValueid)}>{t('Open')}</button>
                                               
                                            </>
                                            :
                                            ''
                                        }
                                    </div>

                                </div>

                                <table className="table table-bordered text-left mt-2" id="tableA">
                                    <thead className={class_thead} style={{ backgroundColor: "var(--primary)" }}>
                                        <tr>
                                            <th className="tableheader" style={{ width: '30px' }}>
                                                <input type="checkbox" id="option-all" onChange={checkAll} />
                                            </th>

                                            <th id="schedulerName" style={{ backgroundColor: (filterValueDict.firstname ? '#6cae55' : ''), width: '250px' }}>
                                                {t('Schedular.SchedulerName')} <span className='fa fa-sort' onClick={() => SortColumn('SchedulerName')}></span>
                                                <span className='fa fa-filter' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('schedulerName')}></span>
                                            </th>
                                            <th id="CronExpression" style={{ backgroundColor: (filterValueDict.lastname ? '#6cae55' : ''), width: '250px' }}>
                                                {t('Schedular.CronExpression')}<span className='fa fa-sort' onClick={() => SortColumn('CronExpression')}></span>
                                                <span className='fa fa-filter' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('CronExpression')}></span>
                                            </th>
                                            <th id="SchedulerTypeName" style={{ backgroundColor: (filterValueDict.username ? '#6cae55' : ''), width: '250px' }}>
                                                {t('Schedular.SchedulerType')}  <span className='fa fa-sort' onClick={() => SortColumn('SchedulerTypeName')}></span>
                                                <span className='fa fa-filter' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('schedulerTypeName')}></span>
                                            </th>
                                            <th id="ModuleName" style={{ backgroundColor: (filterValueDict.email ? '#6cae55' : ''), width: '250px' }}>
                                                {t('Schedular.ModuleName')}<span className='fa fa-sort' onClick={() => SortColumn('ModuleName')}></span>
                                                <span className='fa fa-filter' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('moduleName')}></span>
                                            </th>

                                            <th id="FrequecyName" style={{ backgroundColor: (multiFilterValue.frequencyname ? '#6cae55' : ''), width: '220px' }}>
                                                {t('Schedular.FrequecyName')} <span className='fa fa-sort' onClick={() => SortColumn('FrequecyName')}></span>
                                                <span className='fa fa-filter' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('frequecyName')}></span>

                                            </th>

                                            <th id="last_modified_by" style={{ backgroundColor: (multiFilterValue.last_modified_by ? '#6cae55' : ''), width: '200px' }}>
                                                {t('ModifiedBy')}  <span className='fa fa-sort' onClick={() => SortColumn('CreatedUserName')}></span>
                                                <span className='fa fa-filter' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('last_modified_by')}></span>


                                            </th>

                                          
                                            <th id="status" style={{ backgroundColor: (multiFilterValue.statusName ? '#6cae55' : ''), width: '200px' }}  >
                                                {t('Schedular.Status')} &nbsp;
                                                <span className='fa fa-sort' onClick={() => SortColumn('StatusName')}></span>
                                                <span className='fa fa-filter' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('statusName')}></span>
                                            </th>
                                            <th id="created_date" style={{ backgroundColor: (filterValueDict.last_modified_date ? '#6cae55' : ''), width: '200px' }}>
                                                {t('DataImport.CreatedDate')}   <span className='fa fa-sort' onClick={() => SortColumn('created_date')}></span>

                                            </th>
                                            <th id="nextexecution" style={{ backgroundColor: (filterValueDict.last_modified_date ? '#6cae55' : ''), width: '200px' }}>
                                                {t('Schedular.NextExecution')}  <span className='fa fa-sort' onClick={() => SortColumn('NextExecution')}></span>


                                            </th>
                                            <th id="lastexecution" style={{ backgroundColor: (filterValueDict.last_modified_date ? '#6cae55' : ''), width: '200px' }}>
                                                {t('Schedular.LastExecution')}  <span className='fa fa-sort' onClick={() => SortColumn('LastExecution')}></span>


                                            </th>
                                        </tr>
                                    </thead>

                                    <tbody>

                                        {
                                            showspinner === true ?

                                                <>
                                                    <Spinner animation="grow" size="sm" variant="success" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                    <Spinner animation="grow" size="sm" variant="danger" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                    <Spinner animation="grow" size="sm" variant="warning" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                </>
                                                :
                                                filteredArray.length === 0 ?
                                                    <tr><td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td>
                                                            {t('NoDataAvailable')}
                                                        </td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td></tr>
                                                    :
                                                    filteredArray.map((item, index) => (

                                                        <tr key={index} onDoubleClick={(event) => OpenUser(event, item.Id, item.SchedulerName)}>

                                                            <td><input id={item.Id} data-index="1" name="chkBox" type="checkbox" onChange={(event) => handleChange(event, item.Id)} /></td>

                                                          
                                                            <td>{item.SchedulerName}</td>
                                                            <td>{item.CronExpression}</td>
                                                            <td>{item.SchedulerTypeName}</td>
                                                            <td>{item.ModuleName} </td>

                                                            <td>{item.FrequecyName}</td>
                                                            <td>{item.CreatedUserName}</td>
                                                            <td>{item.StatusName}</td>
                                                            <td>

                                                                {moment(item.CreatedDate).format(SessionManage.getcurrentdateformat())}
                                                            </td>
                                                            <td>{item.NextExecution}</td>
                                                            <td>{item.LastExecution}</td>
                                                           
                                                        </tr>


                                                    ))
                                        }

                                    </tbody>
                                </table>
                                {
                                    showLoadMore === true ?
                                        <div className='text-center container row'>
                                            <div className='col-md-5'></div>
                                            <div className='col-md-2'>
                                                <button className='btn btn-sm btn-update ml-1' onClick={handleLoadMore} style={{width: "70px"}}>{t('LoadMore')}</button>
                                            </div>
                                            <div className='col-md-5'></div>

                                        </div>
                                        :
                                        ""
                                }
                                <div className='row'>

                                    {
                                        totalselectedshow === true ?

                                            <div className='col-lg-2 forgettext col-sm-12 border' style={{ textAlign: "center" }}>
                                                {t('TotalRecordsSelected')} - <span id="totalselected">{totalselectedcount}</span>
                                            </div>

                                            :
                                            <div className='col-lg-2  col-sm-12' style={{ textAlign: "center" }}>

                                            </div>
                                    }



                                    <div className='col-lg-8 col-sm-12'></div>


                                    <div className='col-lg-2 forgettext  col-sm-12 border' style={{ textAlign: "center" }}>
                                    {t('TotalRecords')}  - {totalCountlst}
                                    </div>
                                </div>

                                {showfilterpopup === true ?
                                    <div className='pop-up' style={{ left: divleft, top: divtop }}>
                                       
                                        {filterCol === 'firstname' || filterCol === 'lastname' || filterCol === 'username' || filterCol === 'email' || filterCol === 'roles' ?

                                           
                                            <>
                                                <div className="container">
                                                    <div className="row">
                                                        <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>
                                                    </div>
                                                </div>
                                                <div className="container mt-2">
                                                    <div className="row">
                                                        <input type='textbox' placeholder={t('Search')} className='filtertextboxbackground col-md-11 textbox-padding ml-2' value={filterValueDict[filterCol]} name={filterCol} onChange={(event) => filterInput(event)}></input>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <></>

                                        }



                                        {filterCol === 'last_modified_date' ?

                                           
                                            <>
                                                <div className="container">
                                                    <div className="row">
                                                        <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>
                                                    </div>
                                                </div>
                                                <div className="container mt-2">
                                                    <div className="row">
                                                        <input type='date' placeholder={t('Search')} className='filtertextboxbackground col-md-11 textbox-padding ml-2' value={filterValueDict[filterCol]} name={filterCol} onChange={(event) => filterInput(event)}></input>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <></>
                                        }

                                        {filterCol === 'last_modified_by' || filterCol === 'statusName' ?
                                            <>
                                                <div className="container">
                                                    <div className="row">
                                                        <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>

                                                    </div>

                                                </div>

                                                <div className="container mt-2">
                                                    <div className="row">
                                                        <input type="text" placeholder={t('Search')} value={filterSearch} onChange={(event) => UserfilterHandler(event)} className='filtertextboxbackground col-md-11 textbox-padding ml-2' />
                                                    </div>

                                                    <div className="row contentoverflow mt-2">
                                                        <table className="filtertable">
                                                            <tbody>
                                                                <tr>
                                                                    <td><input type="checkbox" id="option-all" onChange={(event) => checkAllFilter(event)} /></td>
                                                                    <td>{t('SelectAll')}</td>
                                                                </tr>
                                                                {
                                                                    dropdownData.map((item, index) => (

                                                                        <tr key={index}>

                                                                            <td><input id={item.Id} data-index="1" name="chkBox" checked={item.ischecked} type="checkbox" onChange={(event) => OndropdownChange(event, item.Id, item.Name)} /></td>

                                                                            <td>{item.Name}</td>

                                                                        </tr>


                                                                    ))
                                                                }

                                                            </tbody>
                                                        </table>

                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <></>
                                        }
                                        {filterCol === 'schedulerName' || filterCol === 'CronExpression' || filterCol === 'schedulerTypeName' || filterCol === 'moduleName' || filterCol === 'frequecyName' ?
                                            <>
                                                <div className="container">
                                                    <div className="row">
                                                        <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>

                                                    </div>

                                                </div>
                                                <div className="container mt-2">
                                                    <div className="row">
                                                        <input type="text" placeholder="Search" value={filterValueDict[filterCol]} onChange={(event) => filterInput(event)} className='filtertextboxbackground col-md-11 textbox-padding ml-2' />
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <></>
                                        }
                                       

                                        <div style={{ paddingTop: '10px', paddingLeft: '60px', paddingBottom: '7px' }}>
                                            <button className="btn btn-sm btn-update" style={{ width: "45px" }} onClick={() => filterHandler('Apply')}>{t('Apply')}</button>
                                            <button className="btn btn-sm btn-update" style={{ marginLeft: '10px', width: "45px" }} onClick={() => filterHandler('Reset')}>{t('Reset')}</button>
                                        </div>
                                        <div>
                                        </div>
                                    </div>
                                    :
                                    <></>
                                }

                                <Modal show={ToggleStatusModal}
                                    size="sm"
                                    aria-labelledby="contained-modal-title-vcenter"
                                    centered>
                                    <Modal.Body style={{ "textAlign": 'center', fontFamily: 'Segoe UI' }}>{`${t('Policies.AreYouSureYouWantTo')} ${toggleChange}` + "?"}</Modal.Body>
                                    <Modal.Footer>
                                        <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "35px" }} size='sm' onClick={() => ToggleUserActiveStatus(toggleId, toggleUserId, "Ok")}>
                                            {t('Yes')}
                                        </Button>
                                        <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "50px" }} size='sm' onClick={() => ToggleUserActiveStatus(toggleId, toggleUserId, "Cancel")}>
                                            {t('Cancel')}
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                            </div>

                        </div>
                    </div>

                </div>
            </div>



        </div>
    </>)
}
export default SchedulerListComponent