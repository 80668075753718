import { Link, useLocation } from "react-router-dom"
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState, useRef, forwardRef } from 'react';
import { CSVLink } from "react-csv";
import { Form, Dropdown, Button, InputGroup, FormControl, Modal } from 'react-bootstrap';
import Service from '../../../Services/Service';
import moment from 'moment';
import Spinner from 'react-bootstrap/Spinner';
import classes from '../../RebateProgramComponent/CreateRebateComponent/Form.module.css'
import SessionManage from "../../../SessionManagement/SessionManage";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import CustomErrorPopup from '../../../UI/CustomErrorPopup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function PendingActionComponent(props) {
  const [searchValue, setSearchValue] = useState(null)
  const csvInstance = useRef();
  const [csvList, setCsvList] = useState([]);
  const [headers, setHeaders] = useState([
    { label: "Claim ID", key: "ClaimId" },
    { label: "Agreement ID ", key: "AgreementId" },
    { label: "End Customer", key: "DistributorName" },
    { label: "Claim Amount", key: "ClaimAmount" },
    { label: "Validity Start Date", key: "ValidityFrom" },
    { label: "Validity End Date", key: "ValidityTo" },
    { label: "Submitted On", key: "CreatedDate" },
    { label: "Submitted By", key: "CreatedUserName" },
    { label: "Claim Status", key: "Status" },

  ]);
  const [sortColName, setSortColName] = useState("");
  const [sticky, setSticky] = useState("");
  const [userPerms, setuserPermission] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const [paging, setpaging] = useState({
    pageSize: 20,
    pageNumber: 1
  })
  const [showspinner, setshowspinner] = useState('');
  const [showLoadMore, setshowLoadMore] = useState('');
  let loggedInUser = SessionManage.getuserid();
  const [pendingRebateData, setPendingRebateData] = useState([]);
  const [checkedValueRebateId, setCheckedValueRebateId] = useState([]);
  const [checkedRebateLineId, setCheckedRebateLineId] = useState([]);
  const [showbutton, setshowbutton] = useState('');
  const [filterValue, setFilterValue] = useState('');
  const [filterCol, setFilterCol] = useState('');
  const [dropdownData, setDropdownData] = useState([]);
  const [t, i18n] = useTranslation('translation');
  const [filterSearch, setFilterSearch] = useState("");
  const [filterdropdownData, setFilterDropdownData] = useState([]);
  const [filtertext, setfiltertext] = useState('');
  const [dropdownChange, setDropdownChange] = useState('');
  const [checkedMultiValueid, setCheckedMultiValueid] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showApprovedModal, setShowApprovedModal] = useState(false)
  const [showDeniedModal, setShowDeniedModal] = useState(false)
  const [commentsForApproved, setCommentsForApproved] = useState("")
  const [commentsForDenied, setCommentsForDenied] = useState("")
  const [multiValueids, setMultiValueIds] = useState([{
    ClaimId: 0,
    UserId: 0,
    Comments:'',
   }
  ]);
  const [filterValueDict, setFilterValueDict] = useState({
    AgreementId: '',
    ClaimAmount: '',
    CreatedDate: null,
    ClaimId: '',
    ValidityFrom: null,
    ValidityTo: null,
  });
  const [multiFilterValue, setMultiFilterValue] = useState({
    DistributorName: '',
    EndcustomerName: '',
    CreatedUserName: ''
  });
  const [showfilterpopup, setshowfilterpopup] = useState(false);
  const [columnAscending, setColumnAscending] = useState(true);
  const [totalselectedshow, settotalselectedshow] = useState('');
  const [totalselectedcount, settotalselectedcount] = useState(0);
  const [totalCountlst, settotalCountlst] = useState('');
  const LoadMore = (lst, totalcount) => {

    if (lst < totalcount) {
      setshowLoadMore(true);
    }
    else if (lst >= totalcount) {
      setshowLoadMore(false);
    } else {
      setshowLoadMore(false);
    }

  }
  const [filteredArray, setFilteredArray] = useState([])
  {
    useEffect(() => {

      setshowspinner(true);
      settotalselectedshow(false);
      setColumnAscending(false);
      setSortColName("Id");
      getUserPermission(location.pathname);
      getPendingApprovalData(paging.pageSize, paging.pageNumber, "Descending", "Id", [], null)
      getColumnVisibilityList()
      getColVisibilityListData()
      window.addEventListener("scroll", isSticky);
      return () => {
        window.removeEventListener("scroll", isSticky);
      };

    }, [props.id])
  }
  const getUserPermission = (pathname) => {
    Service.GetUserPermission(pathname).then((res) => {
      if (typeof res !== 'undefined') {

        if (res.data !== 'null' && typeof res.data !== 'undefined' && res.data !== '') {
          if (typeof res.data.DataList !== 'undefined') {
            setuserPermission(res.data.DataList)
          }
        }
      }
    });
  }
  const [operatorValOption, setOperatorValueOption] = useState([
    { value: "=", label: "equals" },
    { value: "<", label: "less then" },
    { value: ">", label: "greater then" },
    { value: "<=", label: "less or equal" },
    { value: ">=", label: "greater or equal" }
  ])
  const [operatorVal, setOperatorValue] = useState({ value: "=", label: "equals" })

  const customStyles = {
    control: base => ({
      ...base,
      minHeight: 28
    }),
    dropdownIndicator: base => ({
      ...base,
      padding: 2
    }),
    clearIndicator: base => ({
      ...base,
      padding: 2
    }),
    multiValue: base => ({
      ...base,

    }),
    valueContainer: base => ({
      ...base,
      padding: '0px 2px'
    }),
    input: base => ({
      ...base,
      margin: 0,
      padding: 0
    })
  };
  const isSticky = () => {
    const scrollTop = window.scrollY;
    const stickyClass = scrollTop >= 60 ? "is-sticky-thead" : "";
    setSticky(stickyClass);
    const scrollTopActual = scrollTop >= 60 ? scrollTop - 85 : 0;
    var divtop1 = document.getElementById("tableA");
    setdivtop(divtop1.offsetTop + scrollTopActual)
  };
  const class_thead = `text-white rt-thead  ${sticky}`;
  const ExporttoCSV = () => {
    let checkedBoxes = document.querySelectorAll('input[name=chkBox]:checked');
    let values = "";
    if (checkedBoxes.length >= 1) {
      for (let checkbox of checkedBoxes) {
        values = values + ';' + checkbox.id;

      }
    }
    let objFilter = [];
    let objFilterValue = [{
      filterKey: "id",
      FilterValue: values,
    }]
    objFilter = objFilterValue;
    Service.getdataWithPagingForApprovalsSearch("SDClaimApproval/GetPendingSpecialagreement", loggedInUser, 20000, 1, "Ascending", "Id", objFilter, null).then((res) => {


      if (typeof res !== "undefined") {
        if (res.data !== "null" && res.data !== "undefined") {

          console.log(res.data.DataList, 'data-list');
          console.log(hideColumn, 'hide');
          console.log(typeof hideColumn);

          let csvLst = res.data.DataList;

          let filteredCsvList = csvLst.map(item => {
            let newItem = {};
            Object.keys(item).forEach(key => {
              if (!hideColumn.includes(key)) {
                newItem[key] = item[key];
              }
            });
            return newItem;
          });

          let filteredHeaders = headers.filter(header => !hideColumn.includes(header.key));

          setCsvList(filteredCsvList);
          setHeaders(filteredHeaders);

          setTimeout(() => {
            csvInstance.current.link.click();
            toast.success('Exported Successfully !', {
              position: toast.POSITION.TOP_RIGHT,
            });
          });
        }
      }
    });
  };

  const showApprovedDialog = (flag) => {
    if (flag === 'showApprovedDialog') {
       setCommentsForApproved("")
       setShowApprovedModal(!showApprovedModal)
    }
    else if (flag === 'proceed') {
        Approve()
        setShowApprovedModal(!showApprovedModal)

    }
}

const showDeniedDialog = (flag) => {
    if (flag === 'showDeniedDialog') {
        setCommentsForDenied("")
        setShowDeniedModal(!showDeniedModal)
    }
    else if (flag === 'proceed') {
        Deny()
        setShowDeniedModal(!showDeniedModal)

    }
}
const Deny = () => {
  
    setLoading(true);
    Service.createorupdate('SDClaim/MassDeny?comment='+commentsForDenied, multiValueids).then((res) => {
      if (typeof res !== 'undefined' && typeof res.status !== 'undefined') {
        if (res.status === 200) {
          setLoading(false);
          toast.success('Claim Denied Successfully !', {
            position: toast.POSITION.TOP_RIGHT,
            //onClose: () => navigate("/claims/approvals/pending")
          });

        }
        setTimeout(() => {
          navigate("/reconcilation/pendingaction/0")
        }, 1000)
      }
      else if (res.status !== 200) {
        //setErrorPopup(true)
      }
    });
}
const Approve = () => {
 debugger
    setLoading(true);
    Service.createorupdate('SDClaim/MassApprove?comment='+commentsForApproved, multiValueids).then((res) => {
      if (typeof res !== 'undefined' && typeof res.status !== 'undefined') {

        setLoading(false);
       // unLockEntity();
        if (res.status === 200) {
          toast.success('Claim Approved Successfully !', {
            position: toast.POSITION.TOP_RIGHT,
           // onClose: () => navigate("/claims/approvals/pending")
          });
          setTimeout(() => {
            navigate("/reconcilation/pendingaction/0")
          }, 1000)
        }
      }
      else if (res.status !== 200) {
       // setErrorPopup(true)
      }
    });
}


  const checkAll = (mycheckbox) => {
    var checkboxes = document.querySelectorAll("input[type = 'checkbox']");
    if (mycheckbox.target.checked == true) {
      settotalselectedshow(true);
      checkboxes.forEach(function (checkbox) {
        checkbox.checked = true;
      });
      settotalselectedcount(checkboxes.length - 1)
    }
    else {
      settotalselectedshow(false);
      checkboxes.forEach(function (checkbox) {
        checkbox.checked = false;
      });
      settotalselectedcount(0)
    }

  }
  const getPendingApprovalData = (pageSize, pageNumber, sortType, SortProperty, filterValue, search) => {
    Service.getdataWithPagingForApprovalsSearch("SDClaimApproval/GetPendingSpecialagreement", loggedInUser, pageSize, pageNumber, sortType, SortProperty, filterValue, search).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res && res.status === 200) {

            if (res.data !== 'null' && res.data !== 'undefined') {
              let rData = res.data.DataList;

              setPendingRebateData(rData)
              setFilteredArray(rData)
              LoadMore(res.data.DataList.length, res.data.Totalcount)

              setshowspinner(false);
              settotalCountlst(res.data.Totalcount)
            }
          }
        }
      }

    });
  }
  const handleLoadMore = () => {
    let pagenumber = paging.pageNumber + 1;
    var sortType = columnAscending === true ? 'Ascending' : 'Descending';
    Service.getdataWithPagingForApprovalsSearch("SDClaimApproval/GetPendingSpecialagreement", loggedInUser, paging.pageSize, pagenumber, sortType, sortColName, filterLst(), searchValue).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res && res.status === 200) {

            if (res.data !== 'null' && res.data !== 'undefined') {
              let rData = res.data.DataList;
              const currentlength = pendingRebateData.length;
              setPendingRebateData(pendingRebateData => [...pendingRebateData, ...res.data.DataList])
              setPendingRebateData(filteredArray => [...filteredArray, ...res.data.DataList])
              setFilteredArray(filteredArray => [...filteredArray, ...res.data.DataList])
              LoadMore(currentlength + res.data.DataList.length, res.data.Totalcount)

            }
          }
        }
      }

    });

    paging.pageNumber = pagenumber
    setpaging(paging);
  };
  const searchHandler = (event) => {

    var sortType = columnAscending === true ? 'Ascending' : 'Descending';
    let pagenumber = 1;
    paging.pageNumber = pagenumber;
    setpaging(paging);
    if (event.target.value != '') {

      setSearchValue(String(event.target.value).toLowerCase())
      getPendingApprovalData(paging.pageSize, pagenumber, sortType, sortColName, filterLst(), event.target.value);
    }
    else {
      setSearchValue(null);
      getPendingApprovalData(paging.pageSize, pagenumber, sortType, sortColName, filterLst(), null);
    }
  }
  const filterLst = () => {
    let list = [];
    for (var key in multiFilterValue) {
      if (multiFilterValue[key].length > 0) {
        let values = ""
        multiFilterValue[key].map((i) => {
          values += i.Name + ";";
        })
        if (values !== null && values !== '') {
          list.push({
            filterKey: key,
            FilterValue: values
          })
        }
      }
    }
    for (var key in filterValueDict) {
      if (filterValueDict[key] !== null && filterValueDict[key] !== '') {
        list.push({
          filterKey: key,
          FilterValue: filterValueDict[key]
        })
      }
    }
    return list;
  }
  const OnChangeOperatorValue = (data) => {
    setOperatorValue({ value: data.value, label: data.label });
  }

  const handleChange = (e, status, id, rebateProgramLineId) => {
    debugger
    let isChecked = e.target.checked;

    let checkedBoxes = document.querySelectorAll('input[name=chkBox]:checked');
    if (isChecked === true) {
      setMultiValueIds(prevMultiValueIds => [
        ...prevMultiValueIds,
        { 
          ...multiValueids,
          'ClaimId': id,
          'Userid': loggedInUser,
          'comments':""
  
        }
      ]);
      setCheckedValueRebateId(id)
      setCheckedRebateLineId(rebateProgramLineId)
      settotalselectedshow(true);
      settotalselectedcount(checkedBoxes.length);

    }
    else {
      setMultiValueIds((prevMultiValueIds) =>
      prevMultiValueIds.filter((item) => item.ClaimId !== id)
      );
      setCheckedValueRebateId([])
      setCheckedRebateLineId([])
      settotalselectedshow(false);
      settotalselectedcount(checkedBoxes.length);
    }
    if(checkedBoxes.length > 0){
      setApproveDenybutton(true);
      setCheckedMultiValueid([])
      for (let checkbox of checkedBoxes) {
      debugger
        checkedMultiValueid.push(checkbox.defaultValue)
      
      }
       
     }
    if (checkedBoxes.length == 1)
      setshowbutton(true);
    else
      setshowbutton(false);
  }
 
  const navigateToRebate = (id) => {
    if (id == null || id == 0)
      alert('Please select the Rebate to view')
    else
      navigate("/claims/new/" + id + "/fromApproval");
  }

  const OpenRebate = (event, id) => {
    if (event.detail === 2)
      navigate("/claims/new/" + id + "/fromApproval");
  }
  const filterInput = (e) => {

    let inputValue = e.target.value
    setFilterValue(inputValue)
    setFilterValueDict(() => ({
      ...filterValueDict, [filterCol]: e.target.value,
    }));
  }
  const addnewfield = (data, key) => {
    let selectedKeys = multiFilterValue[key];
    let currentdata = data.map((i) => {
      i["ischecked"] = false;
      return i;
    });
    if (key != 'status') {
      if (selectedKeys != '') {
        currentdata.forEach((each) => {
          selectedKeys.forEach((eachSelected) => {
            if (each.Name == eachSelected.Name) {
              each.ischecked = true
            }
          })
        })
      }

      setDropdownData(currentdata);
      setFilterDropdownData(currentdata)
    }
    else {
      if (selectedKeys != '') {
        currentdata.forEach((each) => {
          selectedKeys.forEach((eachSelected) => {
            if (each.Name == eachSelected.Name) {
              each.ischecked = true
            }
          })
        })
        setDropdownData(currentdata)
        setFilterDropdownData(currentdata)
      }
      else {
        setDropdownData(currentdata)
        setFilterDropdownData(currentdata)
      }
    }
  }


  const checkAllFilter = (mycheckbox) => {
    var lastfilter = [...dropdownData]
    let data1 = []
    var checkboxes = document.querySelectorAll("input[name = 'filter-option-all']");
    if (mycheckbox.target.checked == true) {
      ;
      let currentdata = lastfilter.map((i) => {
        i["ischecked"] = true;
        return i;
      });
      setDropdownData(currentdata)

      dropdownData.map((each) => {

        const getallData = {
          Name: each.Name,
        }
        data1.push(getallData)
      })
      setFilterValue(filterValue => [...filterValue, ...data1]);
      let updateddata = ([...filterValue, ...data1]);
      setMultiFilterValue(() => ({
        ...multiFilterValue, [filterCol]: updateddata,
      }));
    }
    else {

      let currentdata = lastfilter.map((i) => {
        i["ischecked"] = false;
        return i;
      });
      setFilterValue([])
      setMultiFilterValue([])
    }

  }



  const OndropdownChange = (e, id, name) => {
    let isChecked = e.target.checked;
    var lastfilter = [...dropdownData]
    if (isChecked === true) {
      let currentdata = lastfilter.map((i) => {
        if (i.Id == id) {
          i["ischecked"] = true;
        }
        return i;
      });

      setDropdownData(currentdata);
      let data1 = []
      const getallData = {
        Name: name,
      }
      data1.push(getallData)
      setFilterValue(filterValue => [...filterValue, ...data1]);
      let updateddata = ([...filterValue, ...data1]);
      setMultiFilterValue(() => ({
        ...multiFilterValue, [filterCol]: updateddata,
      }));
    }
    else {
      let currentdata = lastfilter.map((i) => {
        if (i.Id === id) {
          i["ischecked"] = false;
        }
        return i;
      });

      setDropdownData(currentdata);
      setFilterValue(filterValue => filterValue.filter(data => data.Name !== name))
      let data1 = multiFilterValue.filter(data => data.Name !== name);
      setMultiFilterValue(() => ({
        ...multiFilterValue, [filterCol]: data1,
      }));
    }
  }

  const UserfilterHandler = (e) => {

    setFilterSearch(e.target.value)
    if (e.target.value !== "") {
      let searchResults = dropdownData.filter((each) => {
        return each.Name.toLowerCase().includes(String(e.target.value).toLowerCase())
      });
      setDropdownData(searchResults);
    }
    else {

      setFilterSearch("")
      setDropdownData(filterdropdownData)
    }
  }
  const [divleft, setdivleft] = useState('');
  const [divtop, setdivtop] = useState('');
  const FilterColumn = (name) => {

    const scrollTop = window.scrollY;
    const scrollTopActual = scrollTop >= 60 ? scrollTop - 85 : 0;
    var divtop1 = document.getElementById("tableA");
    setdivtop((divtop1.offsetTop + 30) + scrollTopActual)
    setshowfilterpopup(true)
    setFilterCol(name)
    var testDiv = document.getElementById(name);
    setdivleft((testDiv.offsetLeft + 30) + "px");
    if (name === 'ClaimId') {
      setfiltertext(t('sdClaim.ClaimId'))
    }
    if (name === 'AgreementId') {
      setfiltertext(t('ship&debit.AgreementId'))
    }

    if (name === 'DistributorName') {
      setfiltertext(t('ship&debit.DistributorName'))
    }
    if (name === 'ClaimAmount') {
      setfiltertext(t('sdClaim.ClaimAmount'))
    }
    if (name === 'ValidityFrom') {
      setfiltertext(t('Rebates.ValidityStartDate'))
    }
    if (name === 'ValidityTo') {
      setfiltertext(t('Rebates.ValidityEndDate'))
    }
    if (name === 'CreatedDate') {
      setfiltertext(t('Accruals.SubmittedOn'))
    }
    if (name === 'CreatedUserName') {
      setfiltertext(t('Accruals.SubmittedBy'))
    }
    if (name === 'Status') {
      setfiltertext(t('sdClaim.ClaimStatus'))
    }
    if (name === 'EndcustomerName' || name === 'CreatedUserName' || name === 'DistributorName')
      DropdownHandler(name)
    setshowfilterpopup(true)

  }

  const DropdownHandler = (key) => {
    Service.getfilterDropdownByUser('SDClaimApproval/GetAllFilters', key).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {

          addnewfield(res.data, key)
        }
      }
    });
  }
  const filterHandler = (flag) => {

    var sortType = columnAscending === true ? 'Ascending' : 'Descending';
    let pagenumber = 1;
    paging.pageNumber = pagenumber;

    if (flag === 'Apply') {
      setshowfilterpopup(false)
      let list = [];

      for (var key in multiFilterValue) {
        if (multiFilterValue[key].length > 0) {
          let values = ""
          multiFilterValue[key].map((i) => {
            values += i.Name + ";";
          })
          if (values !== null && values !== '') {
            list.push({
              filterKey: key,
              FilterValue: values
            })
          }
        }
      }
      for (var key in filterValueDict) {
        if (filterValueDict[key] !== null && filterValueDict[key] !== '') {
          if (key === 'ClaimAmount') {
            list.push({
              filterKey: key,
              FilterValue: filterValueDict[key],
              operatorValue: operatorVal.value
            })
          }
          else {
            list.push({
              filterKey: key,
              FilterValue: filterValueDict[key]
            })
          }
        }
      }
      if (list.length > 0) {
        let pagesize = 20;
        let pagenumber = 1;
        paging.pageSize = pagesize;
        paging.pageNumber = pagenumber;
        setpaging(paging);
        if (list.length > 0) {
          setpaging(paging);
          getPendingApprovalData(paging.pageSize, pagenumber, sortType, sortColName, list, searchValue);
        }
      }
    }
    else {
      setshowfilterpopup(false)
      setMultiFilterValue(() => ({
        ...multiFilterValue, [filterCol]: '',
      }));
      setFilterValueDict(() => ({
        ...filterValueDict, [filterCol]: '',
      }));

      let list = [];
      for (var key in multiFilterValue) {
        if (key !== filterCol && multiFilterValue[key].length > 0) {
          let values = ""
          multiFilterValue[key].map((i) => {
            values += i.Name + ";";
          })
          if (values !== null && values !== '') {
            list.push({
              filterKey: key,
              FilterValue: values
            })
          }
        }
      }

      for (var key in filterValueDict) {
        if (filterValueDict[key] !== null && filterValueDict[key] !== '' && key !== filterCol) {
          list.push({
            filterKey: key,
            FilterValue: filterValueDict[key]
          })
        }
      }

      setpaging(paging);
      getPendingApprovalData(paging.pageSize, pagenumber, sortType, sortColName, list, searchValue);
      setFilterCol('')
      setFilterValue('')
      setDropdownData([])
      setDropdownChange("")
      setFilterSearch("")
      setFilterDropdownData([])
      if (filterCol === 'accruals_aggregate') {
        setOperatorValue({ value: "=", label: "equals" });
      }
    }
  }
  const SortColumn = (colName) => {

    var sortType = '';
    if (columnAscending === true) {
      setColumnAscending(false);
      sortType = "Descending";
    } else {
      setColumnAscending(true);
      sortType = "Ascending";
    }
    setSortColName(colName);

    let pagenumber = 1;

    paging.pageNumber = pagenumber;
    setpaging(paging);


    getPendingApprovalData(paging.pageSize, pagenumber, sortType, colName, filterLst(), searchValue);

  }
  const customMultiStyles = {
    multiselectContainer: {
      maxWidth: '170px',
      marginLeft: '3px'
    },
    chips: {
      borderRadius: '5px'
    }
  };


  // -----------------------------------------------------------------
  const columnList = [
    { "label": "sdClaim.ClaimId", "key": "ClaimId", "value": true },
    { "label": "ship&debit.AgreementId", "key": "AgreementId", "value": true },
    { "label": "ship&debit.DistributorName", "key": "DistributorName", "value": true },
    { "label": "sdClaim.ClaimAmount", "key": "ClaimAmount", "value": true },
    { "label": "Rebates.ValidityStartDate", "key": "ValidityFrom", "value": true },
    { "label": "Rebates.ValidityEndDate", "key": "ValidityTo", "value": true },
    { "label": "Accruals.SubmittedOn", "key": "CreatedDate", "value": true },
    { "label": "Accruals.SubmittedBy", "key": "CreatedUserName", "value": true },
    { "label": "sdClaim.ClaimStatus", "key": "StatusName", "value": true }
  ]

  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorStatus, setErrorStatus] = useState("Error");
  const [addRemoveCol, setAddRemoveCol] = useState(columnList);
  const [createColumnListOption, setCreateColumnListOption] = useState(columnList)
  const [showAddColumnPopup, setShowAddColumnPopup] = useState(false)
  const [showCreateColumnListPopup, setShowCreateColumnListPopup] = useState(false)
  const [hideColumn, setHideColumn] = useState([]);
  const [resetColumnKey, setResetColumnKey] = useState([]);
  const [colVisibilityOption, setColVisibilityOption] = useState([]);
  const [colvisiOptionVal, setColvisiOptionVal] = useState({ value: null, label: 'Default' })
  const [divleft1, setdivleft1] = useState('');
  const [divtop1, setdivtop1] = useState('');
  const [existingViewVal, setExistingViewVal] = useState(false);
  const [defaultView, setDefaultView] = useState(false);
  const [colViewName, setColViewName] = useState('');
  const [recorddId, setRecordId] = useState(0);
  const [ApproveDenybutton, setApproveDenybutton] = useState(false);


  const OnChangedefaultView = (event,) => {
    setDefaultView(event.target.checked)
  }
  const OnChangeexistingViewVal = (event,) => {
    setExistingViewVal(event.target.checked)
  }

  const OnChangeSetViewName = (event,) => {
    setColViewName(event.target.value)
  }

  const OnChangeColVisibilityList = (data) => {
    setColvisiOptionVal({ value: data.value, label: data.label });
    getColVisibilityListData(data.value)
  }
  const CreateColumnListPopup = () => {
    setShowCreateColumnListPopup(true)
    var divtop1 = document.getElementById("addRemoveCol");
    setdivtop1(divtop1.offsetTop + 30)
    setdivleft1((divtop1.offsetLeft) + 100 + "px");
  }

  const CloseCreateColumnListPopup = () => {
    setShowCreateColumnListPopup(false)
    setColViewName('')
    setDefaultView(false)
  }

  const AddRemoveColumn = () => {
    setShowAddColumnPopup(true)
    var divtop1 = document.getElementById("addRemoveCol");
    setdivtop(divtop1.offsetTop + 45)
    setdivleft((divtop1.offsetLeft) + "px");
    getColumnVisibilityList()
  }
  const OnchangeAddRemoveCol = (event, column) => {
    let isChecked = event.target.checked;
    let lst = [...addRemoveCol];
    for (let d in lst) {
      if (lst[d].key == column.key && isChecked) {
        lst[d]['value'] = true
      }
      else if (lst[d].key == column.key && !isChecked) {
        lst[d]['value'] = false
      }
    }
    setResetColumnKey([...resetColumnKey, column.key])
    setAddRemoveCol(lst)
  }
  const OnchangeCreateColumnListOption = (event, column) => {
    let isChecked = event.target.checked;
    let lst = [...createColumnListOption];
    for (let d in lst) {
      if (lst[d].key == column.key && isChecked) {
        lst[d]['value'] = true
      }
      else if (lst[d].key == column.key && !isChecked) {
        lst[d]['value'] = false
      }
    }
    setCreateColumnListOption(lst)
  }
  const updateColumnVisibilityList = (tag) => {
    let lst = [...addRemoveCol];
    if (tag == 'reset') {
      for (let d in lst) {
        lst[d]['value'] = true
      }
      setHideColumn([])
      setAddRemoveCol(lst)
    }
    else {
      let data = [];
      for (let d in lst) {
        if (!lst[d].value) {
          data.push(lst[d].key)
        }
      }
      setHideColumn(data)
    }
    let viewName = colvisiOptionVal.value;
    Service.setColumnVisibilityListData('ColumnVisibility/CreateOrUpdateColumnList', recorddId, loggedInUser, 'pendingSDClaimList', viewName, existingViewVal, lst).then((res) => { })
    setShowAddColumnPopup(false)
    setResetColumnKey([])
  }


  const CreateColumnVisibilityList = (tag) => {
    let veiwName = colViewName;
    let idDefault = defaultView;
    if (veiwName.trim() == 'undefined' || veiwName.trim() == '' || veiwName.trim() == null) {
      setErrorStatus("Error")
      setErrorMessage("Please set View Name")
      setShowErrorModal(true)
    }
    else {
      let lst = [...createColumnListOption];
      if (tag == 'reset') {
        for (let d in lst) {
          lst[d]['value'] = true
        }
        setHideColumn([])
        setAddRemoveCol(lst)
      }
      else {
        let data = [];
        for (let d in lst) {
          if (!lst[d].value) {
            data.push(lst[d].key)
          }
        }
        setHideColumn(data)
      }
      Service.setColumnVisibilityListData('ColumnVisibility/CreateOrUpdateColumnList', 0, loggedInUser, 'pendingSDClaimList', colViewName, defaultView, lst).then((res) => {
        getColumnVisibilityList()
        setColvisiOptionVal({ value: colViewName, label: colViewName });
        getColVisibilityListData(colViewName)
        CloseCreateColumnListPopup()
        setShowAddColumnPopup(false)
      })
    }
  }

  const closeAddRemoveColumnPopup = () => {
    let lst = [...addRemoveCol];
    let resetKey = [...resetColumnKey];
    for (let rk in resetKey) {
      for (let d in lst) {
        if (lst[d].key == resetKey[rk]) {
          lst[d]['value'] = !lst[d].value
        }
      }
    }
    setAddRemoveCol(lst)
    setShowAddColumnPopup(false)
    setResetColumnKey([])

  }

  const getColumnVisibilityList = () => {
    Service.getColumnVisibilityListData('ColumnVisibility/GetColumnList', loggedInUser, 'pendingSDClaimList', null).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {

            if (typeof res.data !== 'undefined' && res.data !== 'null') {
              let data = []
              for (let d in res.data) {
                data.push({ value: res.data[d].label, label: res.data[d].label })

              }

              setColVisibilityOption(data)
            }
          }
        }
      }
    })
  }


  const getColVisibilityListData = (viewName = null) => {
    if (!viewName) {
      viewName = colvisiOptionVal.value
    }
    if (viewName == 'Deleted') {
      viewName = null
    }
    Service.getColumnVisibilityListData('ColumnVisibility/GetColumnListJsonData', loggedInUser, 'pendingSDClaimList', viewName).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {
            if (typeof res.data.jsonData !== 'undefined' && res.data.jsonData !== 'null') {

              let jsonData = JSON.parse(res.data.jsonData)
              let data = [];
              for (let d in jsonData) {
                if (!jsonData[d].value) {
                  data.push(jsonData[d].key)
                }
              }
              setColvisiOptionVal({ value: res.data.label, label: res.data.label });
              setHideColumn(data)
              setAddRemoveCol(jsonData)
              setExistingViewVal(res.data.defaultView)
              setResetColumnKey([])
              setRecordId(res.data.id)
            }
          }
        }
      }
    })
  }

  const deleteColumnVisibilityView = () => {
    Service.deleteDataByPolicyID("ColumnVisibility/Delete", recorddId, loggedInUser).then(res => {
      if (res.status === 200) {
        getColumnVisibilityList()
        getColVisibilityListData('Deleted')
        setShowAddColumnPopup(false)
        toast.error('Deleted Successfully !', {
          position: toast.POSITION.TOP_RIGHT,
        });

      }
    })
  }
  // ------------------------------------------------------------

  return (<>
    <div className="container-fluid content-top-gap">

      <nav aria-label="breadcrumb">
        <ol className="breadcrumb my-breadcrumb">
          <li className="breadcrumb-item"><a href="/">{t('Home.title')}</a></li>
          <li className="breadcrumb-item active" aria-current="page">{t('sdClaim.PendingHeader')}</li>
        </ol>
      </nav>
      {loading ? <><div className="loader-container"><div className="spinner"></div> </div> </> : ""}
      <div style={{ display: "none" }}>
        <CSVLink data={csvList} headers={headers} ref={csvInstance} filename="ClaimAgreementPending.csv" > </CSVLink>
      </div>
      <ToastContainer autoClose={1000} />
      <div className="">
        <div className="row">
          <div className="col-lg-12 pr-lg-2">
            <div className="card text-center card_border overflow-auto">

              <div className="card-body" style={{ minHeight: "70vh" }}>
                <div className="row ml-1" style={{ position: "sticky" }}>

                  <div className="col-md-10">
                    <div className="row">
                      <input type="text" className="form-control form-control-user col-md-10" id="firstName" name="firstName" autoComplete='off' placeholder={`${t('SearchFor')} ${t('sdClaim.ClaimId')} , ${t('ship&debit.AgreementId')},${t('ship&debit.DistributorName')} , ${t('sdClaim.ClaimStatus')} or ${t('CreatedBy')}`} onChange={(event) => searchHandler(event)} />
                      <button id="addRemoveCol" icon className="btn btn-update ml-2 mt-1 pa-0" type="submit"
                        style={{ float: "left", cursor: "pointer", padding: '0px', height: '29px', width: '26px' }}
                        onClick={AddRemoveColumn} title='Manage View'><i class="fa fa-columns" aria-hidden="true"></i></button>
                    </div>
                  </div>
                  <div className="col-md-2">
                    {totalselectedcount <= 0 ? "" : Service.exportPerms(userPerms) == false ? "" : <button className="btn btn-update  btn-sm ml-2" type="submit" style={{ float: "right", width: "50px" }} onClick={(event) => ExporttoCSV()}>{t('Export')}</button>}
                    {showbutton === true ?
                      <>
                        <a className="btn btn-update btn-sm ml-2" style={{ backgroundColor: "buttonface", float: "right", width: "40px" }} onClick={() => navigateToRebate(checkedValueRebateId)}>{t('View')}</a>
                      </> : ''}
                      {ApproveDenybutton===true ?
                               <>
 <button type="button" style={{ float: "right", width: "57px" }} className="btn btn-sm btn-update ml-1" onClick={() => showApprovedDialog('showApprovedDialog')} >{t('Approve')}</button>
 <button type="button" style={{ float: "right", width: "40px" }} className="btn btn-sm btn-update ml-1" onClick={() => showDeniedDialog('showDeniedDialog')} >{t('Deny')}</button>
 </>  : ''
                              }
                  </div>
                </div>
                <div className="scrollbar-class">
                  <table className="table table-bordered text-left mt-2 table-responsive-sm" id="tableA">
                    <thead className="text-white is-sticky-thead-product" style={{ backgroundColor: "var(--primary)" }}>
                      <tr style={{ position: "sticky", top: "0", backgroundColor: "var(--primary)" }}>
                        <th className="tableheader">
                          <input type="checkbox" id="option-all" onChange={checkAll} />
                        </th>
                        <th id="ClaimId" style={{ backgroundColor: (filterValueDict.ClaimId ? '#6cae55' : ''), maxWidth: '250px' }} hidden={hideColumn.includes('ClaimId') ? true : false}>
                          <div className='d-flex text-nowrap'>
                            <span>{t('sdClaim.ClaimId')}</span>
                            <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('ClaimId')} ></span>
                            <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('ClaimId')} ></span>
                          </div>
                        </th>
                        <th id="AgreementId" style={{ backgroundColor: (filterValueDict.AgreementId ? '#6cae55' : ''), maxWidth: '250px' }} hidden={hideColumn.includes('AgreementId') ? true : false}>
                          <div className='d-flex text-nowrap'>
                            <span>{t('ship&debit.AgreementId')}</span>
                            <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('AgreementId')} ></span>
                            <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('AgreementId')} ></span>
                          </div>
                        </th>
                        <th id="DistributorName" style={{ backgroundColor: (multiFilterValue.DistributorName ? '#6cae55' : ''), maxWidth: '250px' }} hidden={hideColumn.includes('DistributorName') ? true : false}>
                          {t('ship&debit.DistributorName')} <span className='fa fa-sort' onClick={() => SortColumn('DistributorName')} ></span>
                          <span className='fa fa-filter' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('DistributorName')} ></span>
                        </th>

                        <th id="ClaimAmount" style={{ backgroundColor: (filterValueDict.ClaimAmount ? '#6cae55' : ''), maxWidth: "145px" }} hidden={hideColumn.includes('ClaimAmount') ? true : false}>
                          <div className='d-flex text-nowrap'>
                            <span>{t('sdClaim.ClaimAmount')}</span>
                            <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('ClaimAmount')}></span>
                            <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('ClaimAmount')}></span>
                          </div>
                        </th>
                        <th id="ValidityFrom" style={{ backgroundColor: (filterValueDict.ValidityFrom ? '#6cae55' : ''), minWidth: "150px" }}
                          hidden={hideColumn.includes('ValidityFrom') ? true : false}>
                          <div className='d-flex text-nowrap'>
                            <span>{t('Rebates.ValidityStartDate')}</span>
                            <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('ValidityFrom')}></span>
                            <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('ValidityFrom')}></span>
                          </div>
                        </th>
                        <th id="ValidityTo" style={{ backgroundColor: (filterValueDict.ValidityTo ? '#6cae55' : ''), minWidth: "150px" }}
                          hidden={hideColumn.includes('ValidityTo') ? true : false}>
                          <div className='d-flex text-nowrap'>
                            <span>{t('Rebates.ValidityEndDate')}</span>
                            <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('ValidityTo')}></span>
                            <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('ValidityTo')}></span>
                          </div>
                        </th>
                        <th id="CreatedDate" style={{ backgroundColor: (filterValueDict.CreatedDate ? '#6cae55' : ''), maxWidth: '200px' }} hidden={hideColumn.includes('CreatedDate') ? true : false}>
                          <div className='d-flex text-nowrap'>
                            <span>{t('Accruals.SubmittedOn')}</span>
                            <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('CreatedDate')} ></span>
                            <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('CreatedDate')} ></span>
                          </div>
                        </th>
                        <th id="CreatedUserName" style={{ backgroundColor: (multiFilterValue.CreatedUserName ? '#6cae55' : ''), maxWidth: '220px' }} hidden={hideColumn.includes('CreatedUserName') ? true : false}>
                          <div className='d-flex text-nowrap'>
                            <span>{t('Accruals.SubmittedBy')}</span>
                            <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('CreatedUserName')} ></span>
                            <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('CreatedUserName')} ></span>
                          </div>
                        </th>
                        <th id="StatusName" style={{ backgroundColor: (filterValueDict.status ? '#6cae55' : ''), maxWidth: '250px' }} hidden={hideColumn.includes('StatusName') ? true : false}>
                          <div className='d-flex text-nowrap'>
                            <span>{t('sdClaim.ClaimStatus')}</span>
                            <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('Status')} ></span>
                          </div>
                        </th>


                      </tr>
                    </thead>
                    <tbody>

                      {
                        showspinner === true ?

                          <Spinner animation="border" size="sm" />
                          :
                          filteredArray.length === 0 ?
                            <tr><td></td>
                              <td></td>
                              <td colSpan="8" style={{ textAlign: 'center' }}>
                                {t('NoDataAvailable')}
                              </td>
                            </tr>
                            :
                            filteredArray.map((listVal) =>
                              <tr onDoubleClick={(event) => OpenRebate(event, listVal.Id)}>
                                <td><input type="checkbox" id={listVal.Id} name="chkBox" value={listVal.Rebate_id} onChange={(event) => handleChange(event, listVal.Status, listVal.Id, listVal.RebateProgramLineId)} /></td>
                                <td style={{ maxWidth: "110px" }} hidden={hideColumn.includes('ClaimId') ? true : false}>{listVal.ClaimId}</td>
                                <td style={{ maxWidth: "110px" }} hidden={hideColumn.includes('AgreementId') ? true : false}>{listVal.AgreementId}</td>

                                <td style={{ maxWidth: "110px" }} hidden={hideColumn.includes('DistributorName') ? true : false}>{listVal.DistributorName}</td>
                                <td style={{ minWidth: "110px" }} hidden={hideColumn.includes('ClaimAmount') ? true : false}>{listVal.CurrencyPrefix} {listVal.ClaimAmount}</td>
                                <td hidden={hideColumn.includes('ValidityFrom') ? true : false}>{moment(listVal.ValidityFrom).format("MM/DD/YYYY")}</td>
                                <td hidden={hideColumn.includes('ValidityTo') ? true : false}>{moment(listVal.ValidityTo).format("MM/DD/YYYY")}</td>
                                <td style={{ maxWidth: "150px" }} hidden={hideColumn.includes('CreatedDate') ? true : false}>{moment(listVal.CreatedDate).format(SessionManage.getcurrentdateformat())}</td>
                                <td style={{ maxWidth: "110px" }} hidden={hideColumn.includes('CreatedUserName') ? true : false}>{listVal.CreatedUserName}</td>
                                <td style={{ maxWidth: "110px" }} hidden={hideColumn.includes('StatusName') ? true : false}>{listVal.Status}</td>

                              </tr>
                            )
                      }

                    </tbody>
                  </table>
                </div>

                {
                  showLoadMore === true ?
                    <div className='text-center container row'>
                      <div className='col-md-5'></div>
                      <div className='col-md-2'>
                        <button className='btn btn-sm btn-update ml-1' onClick={handleLoadMore} style={{ width: "70px" }}>{t('LoadMore')}</button>
                      </div>
                      <div className='col-md-5'></div>

                    </div>
                    :
                    ""}
                <div className='row'>
                  {
                    totalselectedshow === true ?

                      <div className='col-lg-2 forgettext col-sm-12 border form-control' style={{ textAlign: "center", marginLeft: "0.938rem" }}>
                        {t('TotalRecordsSelected')} - <span id="totalselected">{totalselectedcount}</span>
                      </div>
                      :
                      <div className='col-lg-2  col-sm-12' style={{ textAlign: "center" }}>

                      </div>
                  }

                  <div className='col-lg-7 col-sm-12'></div>
                  <div className='col-lg-2 forgettext  col-sm-12 border form-control' style={{ textAlign: "center" }}>
                    {t('TotalRecords')} - {totalCountlst}
                  </div>
                </div>
                {showfilterpopup === true ?
                  <div className='pop-up' style={{ left: divleft, top: divtop, width: "200px" }}>

                    {filterCol === 'AgreementId' || filterCol === 'ClaimId' ?

                      <>
                        <div className="container">
                          <div className="row">
                            <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>
                          </div>
                        </div>
                        <div className="container mt-2">
                          <div className="row">
                            <input type='textbox' placeholder={t('Search')} className='filtertextboxbackground col-md-11 textbox-padding ml-2' style={{ outline: 'none' }} value={filterValueDict[filterCol]} onChange={(event) => filterInput(event)}></input>
                          </div>
                        </div>
                      </>

                      :
                      <>
                      </>

                    }
                    {filterCol === 'ClaimAmount' ?
                      <div>
                        <div className="row">
                          <div className="row" style={{ marginLeft: "40px", paddingBottom: "5px" }}>
                            <b>{filtertext}</b>
                          </div>
                          <Select
                            className='col-md-9 textbox-padding ml-4 fontsizedropdown'
                            options={operatorValOption}
                            placeholder="Select"
                            value={operatorVal}
                            onChange={OnChangeOperatorValue}
                            styles={customStyles}
                          />
                        </div>
                        <div className="mt-2">
                          <input type='text' className='filtertextboxbackground col-md-11 textbox-padding ml-2' onKeyPress={(e) => !/^(\d)*(\.)?([0-9]{1})?$/.test(e.key) && e.preventDefault()}
                            value={filterValueDict[filterCol]} onChange={(event) => filterInput(event)} />
                        </div>
                      </div>
                      :
                      <></>
                    }

                    {filterCol === 'ValidityFrom' || filterCol === 'ValidityTo' || filterCol === 'CreatedDate' ?

                      <>
                        <div className="container">
                          <div className="row">
                            <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>
                          </div>
                        </div>
                        <div className="container mt-2">
                          <div className="row">
                            <input type='date' className='filtertextboxbackground col-md-11 textbox-padding ml-2' value={filterValueDict[filterCol]} onChange={(event) => filterInput(event)}></input>
                          </div>
                        </div>
                      </>
                      :
                      <></>

                    }

                    {filterCol === 'DistributorName' || filterCol === 'EndcustomerName' || filterCol === 'CreatedUserName' ?

                      <>
                        <div className="container">
                          <div className="row">
                            <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>

                          </div>

                        </div>

                        <div className="container mt-2">
                          <div className="row">
                            <input type="text" placeholder={t('Search')} value={filterSearch} onChange={(event) => UserfilterHandler(event)} className='filtertextboxbackground col-md-11 textbox-padding ml-2' />
                          </div>

                          <div className="row contentoverflow mt-2">
                            <table className="filtertable">
                              <tbody>
                                <tr>
                                  <td><input type="checkbox" name="filter-option-all" id="option-all" onChange={checkAllFilter} /></td>
                                  <td>{t('SelectAll')}</td>
                                </tr>
                                {
                                  dropdownData.map((item, index) => (

                                    <tr key={index}>

                                      <td><input id={item.Id} data-index="1" name="filter-option-all" checked={item.ischecked} type="checkbox" onChange={(event) => OndropdownChange(event, item.Id, item.Name)} /></td>

                                      <td>{item.Name}</td>

                                    </tr>


                                  ))
                                }

                              </tbody>
                            </table>

                          </div>
                        </div>
                      </>
                      :
                      <></>
                    }
                    <div style={{ paddingTop: '10px', paddingLeft: '33px', paddingBottom: '7px' }}>
                      <button className="btn btn-sm btn-update" style={{ maxWidth: "45px" }} onClick={() => filterHandler('Apply')}>{t('Apply')}</button>
                      <button className="btn btn-sm btn-update" style={{ marginLeft: '10px', maxWidth: "45px" }} onClick={() => filterHandler('Reset')}>{t('Reset')}</button>
                    </div>
                    <div>
                    </div>
                  </div>
                  :
                  <></>
                }

                {showAddColumnPopup === true ?
                  <div className='pop-up' style={{ left: divleft, top: divtop, width: "250px" }}>
                    <div className="container">
                      <div className="row">
                        <Select
                          className='col-md-10 textbox-padding fontsizedropdown'
                          options={colVisibilityOption}
                          placeholder="Select"
                          value={colvisiOptionVal}
                          onChange={OnChangeColVisibilityList}
                          styles={customStyles}
                        />
                        <button id="createColumnListPopup" icon className="btn btn-update ml-1 pa-0" type="submit"
                          style={{ float: "left", cursor: "pointer", padding: '0px', height: '28px', width: '26px' }}
                          onClick={CreateColumnListPopup} title='Add New View'><i class="fa fa-plus" aria-hidden="true"></i></button>
                      </div>
                      <div className="row mb-2" style={{ textAlign: 'right' }}>
                        <div className="col-md-10 mt-1 mr-1" style={{ padding: '0px', fontSize: '12px' }}>
                          <input id="existingViewVal" data-index="1" name="filter-option-all" checked={existingViewVal} type="checkbox"
                            onChange={(event) => OnChangeexistingViewVal(event)} /> <b>Is Default</b>
                        </div>
                      </div>
                      <div className="row">
                        <table className='ml-2' style={{ fontSize: '0.80rem' }}>
                          {
                            addRemoveCol.map((item, index) => (
                              <tr>
                                <td key={index}><input id={item.label} data-index="1" name="filter-option-all" checked={item.value} type="checkbox"
                                  onChange={(event) => OnchangeAddRemoveCol(event, item)}
                                /> {t(item.label)}</td>
                              </tr>
                            ))
                          }
                        </table>
                      </div>
                      <div className="row mr-2" style={{ float: "right" }}>
                        <div style={{ paddingTop: '10px', paddingLeft: '15px', paddingBottom: '7px' }}>
                          <button className="btn btn-sm btn-update" style={{ width: "45px" }} onClick={() => updateColumnVisibilityList('reset')}>{t('Reset')}</button>
                          <button className="btn btn-sm btn-update ml-1" style={{ width: "45px" }} onClick={closeAddRemoveColumnPopup}>{t('Close')}</button>
                          <button className="btn btn-sm btn-update ml-1" style={{ width: "50px", background: '#4755ab', color: 'white' }} onClick={() => updateColumnVisibilityList('apply')} title='Update View'>{t('Update')}</button>
                          <button className="btn btn-sm btn-update ml-1" style={{ width: "50px", background: 'rgb(233 43 0)', color: 'white' }} onClick={deleteColumnVisibilityView} title='Delete View'>{t('Delete')}</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  : <></>
                }
 <Modal show={showApprovedModal} size="bg"
        centered>
        <Modal.Header>
          <Modal.Title>{t('Rebates.ApprovalComments')}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ "textAlign": 'center', fontFamily: 'Segoe UI' }} >
          <Form.Control className={classes['Textarea-width']} as="textarea" rows={2} value={commentsForApproved} name="commentsForApproved"
            onChange={(e) => { setCommentsForApproved(e.target.value) }} />
        </Modal.Body>
        <Modal.Footer>
          <Button size='sm' style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "50px" }} onClick={() => showApprovedDialog('showApprovedDialog')}>
            {t('Cancel')}
          </Button>
          <Button size='sm' style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "50px" }} onClick={() => showApprovedDialog('proceed')}>
            {t('Submit')}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showDeniedModal} size="bg"
        centered>
        <Modal.Header>
          <Modal.Title>{t('Rebates.DeniedComments')}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ "textAlign": 'center', fontFamily: 'Segoe UI' }} >
          <Form.Control className={classes['Textarea-width']} as="textarea" rows={2} value={commentsForDenied} name="commentsForDenied"
            onChange={(e) => { setCommentsForDenied(e.target.value) }} />
        </Modal.Body>
        <Modal.Footer>
          <Button size='sm' style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "50px" }} onClick={() => showDeniedDialog('showDeniedDialog')}>
            {t('Cancel')}
          </Button>
          <Button size='sm' style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "50px" }} onClick={() => showDeniedDialog('proceed')}>
            {t('Submit')}
          </Button>
        </Modal.Footer>
      </Modal>

                {showCreateColumnListPopup === true ?
                  <div className='pop-up' style={{ left: divleft1, top: divtop1, width: "190px" }}>
                    <div className="container">
                      <div className="row">
                        <div className="col-12 ml-2" style={{ padding: '0px' }}>
                          <input type='textbox' placeholder='View Name' style={{ height: '30px' }}
                            value={colViewName} name={colViewName} onChange={(event) => OnChangeSetViewName(event)}></input>
                        </div>
                      </div>
                      <div className="row mb-2" style={{ textAlign: 'right' }}>
                        <div className="col-10 mt-2" style={{ padding: '0px', fontSize: '12px' }}>
                          <input id="defaultView" data-index="1" name="filter-option-all" checked={defaultView} type="checkbox"
                            onChange={(event) => OnChangedefaultView(event)} /> <b>Set Default</b>
                        </div>
                      </div>
                      <div className="row">
                        <table className='ml-2' style={{ fontSize: '0.80rem' }}>
                          {
                            createColumnListOption.map((item, index) => (
                              <tr>
                                <td key={index}><input id={item.label} data-index="1" name="filter-option-all" checked={item.value} type="checkbox"
                                  onChange={(event) => OnchangeCreateColumnListOption(event, item)}
                                /> {t(item.label)}</td>
                              </tr>
                            ))
                          }
                        </table>
                      </div>
                      <div className="row mr-2" style={{ float: "right" }}>
                        <div style={{ paddingTop: '10px', paddingLeft: '35px', paddingBottom: '7px' }}>
                          <button className="btn btn-sm btn-update ml-1" style={{ width: "50px" }} onClick={CloseCreateColumnListPopup}>{t('Cancel')}</button>
                          <button className="btn btn-sm btn-update ml-1" style={{ width: "40px", background: '#4755ab', color: 'white' }} onClick={() => CreateColumnVisibilityList('apply')}>{t('Save')}</button>
                        </div>
                      </div>
                    </div>


                  </div>
                  : <></>
                }

                <CustomErrorPopup ErrorMessage={errorMessage} show={showErrorModal} ErrorStatus={errorStatus}
                  onHide={() => setShowErrorModal(false)}></CustomErrorPopup>
                <ToastContainer autoClose={1000} />
              </div>

            </div>
          </div>

        </div>
      </div>



    </div>
  </>)
}
export default PendingActionComponent