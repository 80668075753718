import React, { Component, useState, useEffect } from "react";

import { Link, useLocation } from "react-router-dom"
import Service from "../../Services/Service";
import CustomErrorPopup from "../../UI/CustomErrorPopup";
import SessionManage from "../../SessionManagement/SessionManage";
import classes from '../RebateProgramComponent/CreateRebateComponent/Form.module.css'
import { Form, FormGroup, Col, Row, Button, InputGroup, Collapse, Modal } from 'react-bootstrap';
import $ from 'jquery';
import { useTranslation } from "react-i18next";

function EditPermissionSetComponent(props) {
    const location = useLocation();
    const [userPerms, setuserPermission] = useState([]);
    const [t, i18n] = useTranslation('translation');
    const [errorStatus, setErrorStatus] = useState("Error");
    const [isValid, setIsValid] = useState(true);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [data, setdata] = useState([]);
    const [filteredArray, setFilteredArray] = useState(data);
    const [name, setname] = useState('');
    const [closeModal, setCloseModal] = useState(false);
    const [handleChangeValue, setHandleChangeValue] = useState(false);

    let loggedInUser = sessionStorage.getItem("id");
    const [isLocked, setIsLocked] = useState(false);
    const [lockedBy, setLockedBy] = useState('');
    let disable = false;
    const [inputField, setInputField] = useState({
        Id: props.id,
        Label: '',
        Name: '',
        Description: '',
    })
    const inputsHandler = (e) => {
       
        setHandleChangeValue(true)
        const { name, value } = e.target;
        setInputField((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    }
    useEffect(() => {
        getUserPermission(location.pathname);
        getData("Permission/GetMenuList", props.id);
        setname(props.name)
    }, [0])
    const getData = (method, id) => {
        Service.LockUnLockEntity('Configurationprop/LockEntity', "Permission", props.id, loggedInUser).then((res1) => {
            if (typeof res1 !== 'undefined') {
                Service.GetAllMenu(method, id).then((res) => {
                    if (typeof res !== 'undefined') {
                        if (res.data !== 'null' && typeof res.data !== undefined) {
                            
                            setFilteredArray(res.data.DataList);
                            if (typeof res.data.PermissionSet !== undefined) {
                                setInputField(res.data.PermissionSet);
                                setLockedBy(res.data.PermissionSet.LockedById);
                                setIsLocked(res.data.PermissionSet.Locked);
                               
                                if ((res.data.PermissionSet.Locked === true && res.data.PermissionSet.LockedById.toString() !== loggedInUser)) {
                                    $("#libread").html("Edit Permission Set " + `Locked By ${res.data.LockedByUser}`);
                                }
                            }
                        }
                    }
                });
            }
        });
    }
    const getUserPermission = (pathname) => {
        Service.GetUserPermission(pathname).then((res) => {
            if (typeof res !== undefined) {

                if (res.data !== 'null' && typeof res.data !== undefined && res.data !== '') {
                    if (typeof res.data.DataList !== undefined) {
                        setuserPermission(res.data.DataList)
                    }
                }
            }
        });
    }
    const handleChangeLevel1 = (e, moduleid, roleid, actionname, index1) => {
        setHandleChangeValue(true)

        let isChecked = e.target.checked;   // true if it is checked false if unchecked
        let tra = [...filteredArray];
        let newdata = tra[index1];

        for (var i = 0; i < newdata.ActionLists.length; i++) {
            if (newdata.ActionLists[i].ActionName === actionname) {
                if (isChecked === true)
                    newdata.ActionLists[i].IsAdded = true;
                else
                    newdata.ActionLists[i].IsAdded = false;
            } else {
                newdata.ActionLists[i].IsAdded = false;
            }
        }
        ///sub menu dashbaord 
        let newdatasubmenu = newdata.SubMenuList;
        for (var i = 0; i < newdatasubmenu.length; i++) {
            let data = newdatasubmenu[i];
            if (isChecked === true) {
                newdatasubmenu[i].IsAdded = true;
            } else {
                newdatasubmenu[i].IsAdded = false;
            }


        }

        setFilteredArray(tra);


    }
    const handleChangeLevel2 = (e, moduleid, roleid, actionname, indexlevel1, indexlevel2) => {
        setHandleChangeValue(true)
        let isChecked = e.target.checked;   // true if it is checked false if unchecked

        let tra = [...filteredArray];
        let newdata = tra[indexlevel1].SubMenuList[indexlevel2];

        for (var i = 0; i < newdata.ActionLists.length; i++) {

            //first for view only
            if (actionname === "View Only") {

                if (newdata.ActionLists[i].ActionName === actionname) {
                    if (isChecked === true)
                        newdata.ActionLists[i].IsAdded = true;
                    else
                        newdata.ActionLists[i].IsAdded = false;
                } else if (newdata.ActionLists[i].ActionName === "Export") {
                    if (isChecked === true) {
                        newdata.ActionLists[i].IsAdded = true;
                    } else {
                        newdata.ActionLists[i].IsAdded = false;
                    }
                }
                else {
                    if (isChecked === true)
                        newdata.ActionLists[i].IsAdded = false;
                }
            }
            else if (actionname === "Submit") {
                if (newdata.ActionLists[i].ActionName === "Submit") {
                    if (isChecked === true)
                        newdata.ActionLists[i].IsAdded = true;
                    else
                        newdata.ActionLists[i].IsAdded = false;
                } else if (newdata.ActionLists[i].ActionName === "Edit" || newdata.ActionLists[i].ActionName === "Export") {
                  
                } else if (newdata.ActionLists[i].ActionName !== "Edit" && newdata.ActionLists[i].ActionName !== "Export") {
                    newdata.ActionLists[i].IsAdded = false;
                }

            } else if (actionname === "Edit") {
                if (newdata.ActionLists[i].ActionName === actionname) {
                    if (isChecked === true)
                        newdata.ActionLists[i].IsAdded = true;
                    else if (newdata.ActionLists[i].ActionName === 'Submit')
                        newdata.ActionLists[i].IsAdded = true;
                    else
                        newdata.ActionLists[i].IsAdded = false;
                } else if (newdata.ActionLists[i].ActionName === "Export") {
                    if (isChecked === true) {
                        newdata.ActionLists[i].IsAdded = true;
                    } else {
                        newdata.ActionLists[i].IsAdded = false;
                    }
                }
                else {
                    if (isChecked === true && newdata.ActionLists[i].ActionName === 'Submit') {
                        newdata.ActionLists[i].IsAdded = true;
                    } else {
                        newdata.ActionLists[i].IsAdded = false;
                    }
                }
            }
            else if (actionname === "Export") {

                if (newdata.ActionLists[i].ActionName === actionname) {
                    if (isChecked === true)
                        if (newdata.ActionLists.filter((item) => (item.ActionName === 'View Only' || item.ActionName === 'Edit') && item.IsAdded == true).length > 0) {
                            newdata.ActionLists[i].IsAdded = true;
                        } else {
                            newdata.ActionLists[i].IsAdded = false;
                        }

                    else
                        newdata.ActionLists[i].IsAdded = false;
                }
            }
        }
        if ((newdata.Name === 'Create Claim' || newdata.Name === 'Create Rebate' || newdata.Name === 'Create Ship & Debit' || newdata.Name === 'Create Special Price Agreement') && ((actionname === 'Submit' && isChecked === true) || actionname === 'Edit')) {

            let newdatasibling = tra[indexlevel1].SubMenuList[indexlevel2 - 1]; //Agreement List
            if (isChecked === true) {
                newdatasibling.IsAdded = true;
                newdata.ActionLists[0].IsAdded = true;
                newdata.ActionLists[1].IsAdded = true;
            } else {
                newdatasibling.IsAdded = false;
                newdata.ActionLists[0].IsAdded = false;
                newdata.ActionLists[1].IsAdded = false;
            }

            if (isChecked === true) {
                newdatasibling.ActionLists[1].IsAdded = true;
                newdatasibling.ActionLists[2].IsAdded = true;
                newdatasibling.ActionLists[0].IsAdded = false;
            } else {
                newdatasibling.ActionLists[1].IsAdded = false;
                newdatasibling.ActionLists[0].IsAdded = false;
                newdatasibling.ActionLists[2].IsAdded = false;
            }


        } else if ((newdata.Name === 'Create Claim' || newdata.Name === 'Create Rebate' || newdata.Name === 'Create Ship & Debit' || newdata.Name === 'Create Special Price Agreement') && actionname === 'Submit') {


        }
        if (newdata.Name === 'Claim List' || newdata.Name === 'Agreement List' || newdata.Name === 'Ship & Debit' || newdata.Name === 'Special Price Agreement') {

            let newdatasibling = tra[indexlevel1].SubMenuList[indexlevel2 + 1]; //Create Rebate           
            for (var i = 0; i < newdatasibling.ActionLists.length; i++) {
                if (isChecked === true && actionname === 'View Only') {
                    newdatasibling.IsAdded = false;
                    newdatasibling.ActionLists[i].IsAdded = false;
                } else if (isChecked === true && actionname !== 'Export') {
                    newdatasibling.ActionLists[i].IsAdded = true;
                    newdatasibling.IsAdded = true;
                }
                else if (actionname !== 'Export') {
                    newdatasibling.IsAdded = false;
                    newdatasibling.ActionLists[i].IsAdded = false;
                }
            }
        }


        let actionischeck = false;
        for (var i = 0; i < newdata.ActionLists.length; i++) {
            if (newdata.ActionLists[i].IsAdded == true) {
                actionischeck = true;
            }
        }
        if (actionischeck === false) {
            newdata.IsAdded = false
        } else {
            newdata.IsAdded = true;
        }
    
        setFilteredArray(tra);


    }

    const handleChangeLevel3 = (e, moduleid, roleid, actionname, indexlevel1, indexlevel2, indexlevel3) => {
        setHandleChangeValue(true)

        let isChecked = e.target.checked;   // true if it is checked false if unchecked

        let tra = [...filteredArray];
        let newdata = tra[indexlevel1].SubMenuList[indexlevel2].SubMenuList[indexlevel3];

        for (var i = 0; i < newdata.ActionLists.length; i++) {
            //first for view only
            if (actionname === "View Only") {

                if (newdata.ActionLists[i].ActionName === actionname) {
                    if (isChecked === true)
                        newdata.ActionLists[i].IsAdded = true;
                    else
                        newdata.ActionLists[i].IsAdded = false;
                } else if (newdata.ActionLists[i].ActionName === "Export") {
                    if (isChecked === true) {
                        newdata.ActionLists[i].IsAdded = true;
                    } else {
                        newdata.ActionLists[i].IsAdded = false;
                    }
                }
                else {
                    if (isChecked === true)
                        newdata.ActionLists[i].IsAdded = false;
                }
            }
            else if (actionname === "Submit") {
                if (newdata.ActionLists[i].ActionName === "Submit") {
                    if (isChecked === true)
                        newdata.ActionLists[i].IsAdded = true;
                    else
                        newdata.ActionLists[i].IsAdded = false;
                } else if (newdata.ActionLists[i].ActionName === "Edit" || newdata.ActionLists[i].ActionName === "Export") {
     
                } else if (newdata.ActionLists[i].ActionName !== "Edit" && newdata.ActionLists[i].ActionName !== "Export") {
                    newdata.ActionLists[i].IsAdded = false;
                }

            } else if (actionname === "Edit") {
                if (newdata.ActionLists[i].ActionName === actionname) {
                    if (isChecked === true)
                        newdata.ActionLists[i].IsAdded = true;
                    else if (newdata.ActionLists[i].ActionName === 'Submit')
                        newdata.ActionLists[i].IsAdded = true;
                    else
                        newdata.ActionLists[i].IsAdded = false;
                } else if (newdata.ActionLists[i].ActionName === "Export") {
                    if (isChecked === true) {
                        newdata.ActionLists[i].IsAdded = true;
                    } else {
                        newdata.ActionLists[i].IsAdded = false;
                    }
                }
                else {
                    if (isChecked === true && newdata.ActionLists[i].ActionName === 'Submit') {
                        newdata.ActionLists[i].IsAdded = true;
                    } else {
                        newdata.ActionLists[i].IsAdded = false;
                    }
                }
            } else if (actionname === "Export") {
                if (newdata.ActionLists[i].ActionName === actionname) {
                    if (isChecked === true)
                        if (newdata.ActionLists.filter((item) => (item.ActionName === 'View Only' || item.ActionName === 'Edit') && item.IsAdded == true).length > 0) {
                            newdata.ActionLists[i].IsAdded = true;
                        } else {
                            newdata.ActionLists[i].IsAdded = false;
                        }

                    else
                        newdata.ActionLists[i].IsAdded = false;
                }
            }
        }

        if ((newdata.Name === 'Create Claim' || newdata.Name === 'Create Rebate' || newdata.Name === 'Create Ship & Debit' || newdata.Name === 'Create Special Price Agreement') && ((actionname === 'Submit' && isChecked === true) || actionname === 'Edit')) {

            let newdatasibling = tra[indexlevel1].SubMenuList[indexlevel2].SubMenuList[indexlevel3 - 1];

         
            if (isChecked === true) {
                newdatasibling.IsAdded = true;
                newdata.ActionLists[0].IsAdded = true;
                newdata.ActionLists[1].IsAdded = true;
            } else {
                newdatasibling.IsAdded = false;
                newdata.ActionLists[0].IsAdded = false;
                newdata.ActionLists[1].IsAdded = false;
            }

            if (isChecked === true) {
                newdatasibling.ActionLists[1].IsAdded = true;
                newdatasibling.ActionLists[2].IsAdded = true;
                newdatasibling.ActionLists[0].IsAdded = false;
            } else {
                newdatasibling.ActionLists[1].IsAdded = false;
                newdatasibling.ActionLists[2].IsAdded = false;
                newdatasibling.ActionLists[0].IsAdded = false;
            }


        } else if ((newdata.Name === 'Create Claim' || newdata.Name === 'Create Rebate' || newdata.Name === 'Create Ship & Debit' || newdata.Name === 'Create Special Price Agreement') && actionname === 'Submit') {


        }
        if (newdata.Name === 'Claim List' || newdata.Name === 'Agreement List' || newdata.Name === 'Ship & Debit' || newdata.Name === 'Special Price Agreement') {

            let newdatasibling = tra[indexlevel1].SubMenuList[indexlevel2].SubMenuList[indexlevel3 + 1];

               
            for (var i = 0; i < newdatasibling.ActionLists.length; i++) {
                if (isChecked === true && actionname === 'View Only') {
                    newdatasibling.IsAdded = false;
                    newdatasibling.ActionLists[i].IsAdded = false;
                } else if (isChecked === true && actionname !== 'Export') {
                    newdatasibling.ActionLists[i].IsAdded = true;
                    newdatasibling.IsAdded = true;
                }
                else if (actionname !== 'Export') {
                    newdatasibling.IsAdded = false;
                    newdatasibling.ActionLists[i].IsAdded = false;
                }
            }
        }
        let actionischeck = false;
        for (var i = 0; i < newdata.ActionLists.length; i++) {
            if (newdata.ActionLists[i].IsAdded == true) {
                actionischeck = true;
            }
        }
        if (actionischeck === false) {
            newdata.IsAdded = false
        } else {
            newdata.IsAdded = true;
        }
        setFilteredArray(tra);


    }
    const handleChangeLevel4 = (e, moduleid, roleid, actionname, indexlevel1, indexlevel2, indexlevel3, indexlevel4) => {
        setHandleChangeValue(true)

        let isChecked = e.target.checked;   // true if it is checked false if unchecked

        let tra = [...filteredArray];
        let newdata = tra[indexlevel1].SubMenuList[indexlevel2].SubMenuList[indexlevel3].SubMenuList[indexlevel4];
     
        for (var i = 0; i < newdata.ActionLists.length; i++) {
            //first for view only
            if (actionname === "View Only") {

                if (newdata.ActionLists[i].ActionName === actionname) {
                    if (isChecked === true)
                        newdata.ActionLists[i].IsAdded = true;
                    else
                        newdata.ActionLists[i].IsAdded = false;
                } else if (newdata.ActionLists[i].ActionName === "Export") {
                    if (isChecked === true) {
                        newdata.ActionLists[i].IsAdded = true;
                    } else {
                        newdata.ActionLists[i].IsAdded = false;
                    }
                }
                else {
                    if (isChecked === true)
                        newdata.ActionLists[i].IsAdded = false;
                }
            }
            else if (actionname === "Submit") {
                if (newdata.ActionLists[i].ActionName === "Submit") {
                    if (isChecked === true)
                        newdata.ActionLists[i].IsAdded = true;
                    else
                        newdata.ActionLists[i].IsAdded = false;
                } else if (newdata.ActionLists[i].ActionName === "Edit" || newdata.ActionLists[i].ActionName === "Export") {
               
                } else if (newdata.ActionLists[i].ActionName !== "Edit" && newdata.ActionLists[i].ActionName !== "Export") {
                    newdata.ActionLists[i].IsAdded = false;
                }

            } else if (actionname === "Edit") {
                if (newdata.ActionLists[i].ActionName === actionname) {
                    if (isChecked === true)
                        newdata.ActionLists[i].IsAdded = true;
                    else if (newdata.ActionLists[i].ActionName === 'Submit')
                        newdata.ActionLists[i].IsAdded = true;
                    else
                        newdata.ActionLists[i].IsAdded = false;
                } else if (newdata.ActionLists[i].ActionName === "Export") {
                    if (isChecked === true) {
                        newdata.ActionLists[i].IsAdded = true;
                    } else {
                        newdata.ActionLists[i].IsAdded = false;
                    }
                }
                else {
                    if (isChecked === true && newdata.ActionLists[i].ActionName === 'Submit') {
                        newdata.ActionLists[i].IsAdded = true;
                    } else {
                        newdata.ActionLists[i].IsAdded = false;
                    }
                }
            } else if (actionname === "Export") {
                if (newdata.ActionLists[i].ActionName === actionname) {
                    if (isChecked === true)
                        if (newdata.ActionLists.filter((item) => (item.ActionName === 'View Only' || item.ActionName === 'Edit') && item.IsAdded == true).length > 0) {
                            newdata.ActionLists[i].IsAdded = true;
                        } else {
                            newdata.ActionLists[i].IsAdded = false;
                        }

                    else
                        newdata.ActionLists[i].IsAdded = false;
                }
            }
        }

        if ((newdata.Name === 'Create Claim' || newdata.Name === 'Create Rebate' || newdata.Name === 'Create Ship & Debit' || newdata.Name === 'Create Special Price Agreement') && ((actionname === 'Submit' && isChecked === true) || actionname === 'Edit')) {

            let newdatasibling = tra[indexlevel1].SubMenuList[indexlevel2].SubMenuList[indexlevel3 - 1];

          
            if (isChecked === true) {
                newdatasibling.IsAdded = true;
                newdata.ActionLists[0].IsAdded = true;
                newdata.ActionLists[1].IsAdded = true;
            } else {
                newdatasibling.IsAdded = false;
                newdata.ActionLists[0].IsAdded = false;
                newdata.ActionLists[1].IsAdded = false;
            }

            if (isChecked === true) {
                newdatasibling.ActionLists[1].IsAdded = true;
                newdatasibling.ActionLists[2].IsAdded = true;
                newdatasibling.ActionLists[0].IsAdded = false;
            } else {
                newdatasibling.ActionLists[1].IsAdded = false;
                newdatasibling.ActionLists[2].IsAdded = false;
                newdatasibling.ActionLists[0].IsAdded = false;
            }


        } else if ((newdata.Name === 'Create Claim' || newdata.Name === 'Create Rebate' || newdata.Name === 'Create Ship & Debit' || newdata.Name === 'Create Special Price Agreement') && actionname === 'Submit') {


        }
        if (newdata.Name === 'Claim List' || newdata.Name === 'Agreement List' || newdata.Name === 'Ship & Debit' || newdata.Name === 'Special Price Agreement') {

            let newdatasibling = tra[indexlevel1].SubMenuList[indexlevel2].SubMenuList[indexlevel3 + 1];

             
            for (var i = 0; i < newdatasibling.ActionLists.length; i++) {
                if (isChecked === true && actionname === 'View Only') {
                    newdatasibling.IsAdded = false;
                    newdatasibling.ActionLists[i].IsAdded = false;
                } else if (isChecked === true && actionname !== 'Export') {
                    newdatasibling.ActionLists[i].IsAdded = true;
                    newdatasibling.IsAdded = true;
                }
                else if (actionname !== 'Export') {
                    newdatasibling.IsAdded = false;
                    newdatasibling.ActionLists[i].IsAdded = false;
                }
            }
        }
        let actionischeck = false;
        for (var i = 0; i < newdata.ActionLists.length; i++) {
            if (newdata.ActionLists[i].IsAdded == true) {
                actionischeck = true;
            }
        }
        if (actionischeck === false) {
            newdata.IsAdded = false
        } else {
            newdata.IsAdded = true;
        }
       
        setFilteredArray(tra);


    }

    const checkValidation = () => {
        var hasError = false;

       
        if (inputField.Name == null || inputField.Name == '') {
            setErrorMessage(t('UserList.PleaseEnterPermissionSetName'))
            setIsValid(false)
            setShowErrorModal(true)
            hasError = true;
        }
        if (inputField.Label == '' || inputField.Label == null) {
            setErrorMessage(t('UserList.PleaseEnterPermissionSetLabel'))
            setIsValid(false)
            setShowErrorModal(true)
            hasError = true;
        }
        return hasError;
    }
    const submitButton = (e) => {
        e.preventDefault();

        var hasError = checkValidation()
        if (hasError == false) {
            var userid = SessionManage.getuserid();
            Service.CreatePermissionSet("Permission/UpdatePermissionSetModule", inputField.Label, inputField.Name, filteredArray, inputField.Id, userid).then(res => {
                if (typeof res !== 'undefined') {
                    if (typeof res.data !== 'undefined' && res.data !== 'null') {
                        if (res.data.Status == 'success') {
                            Service.LockUnLockEntity('Configurationprop/UnLockEntity', "Permission", props.id, loggedInUser).then((res) => {
                            });
                            props.navigate('/permissionset');
                        } else {
                            setErrorMessage(res.data.Status)
                            setIsValid(false)
                            setShowErrorModal(true)
                        }
                    }
                }

            });
        }
    }

    const closeHandler = (flag) => {
        if (flag == "Open" && handleChangeValue == true) {
            setCloseModal(true)
        }
        if (flag == "Open" && handleChangeValue == false) {
            Service.LockUnLockEntity('Configurationprop/UnLockEntity', "Permission", props.id, loggedInUser).then((res) => {
            });
            props.navigate("/PermissionSet")
        }
        if (flag == "Close") {
            Service.LockUnLockEntity('Configurationprop/UnLockEntity', "Permission", props.id, loggedInUser).then((res) => {
            });
            props.navigate("/PermissionSet")
        }
        if (flag == "Cancel") {
            setCloseModal(false)
        }

    }
    if (isLocked === false || (isLocked === true && lockedBy.toString() === loggedInUser)) {
        disable = false;
    }
    else {
        disable = true;
    }
    window.onbeforeunload = function () {
        unlockEntity();
      
    };

    const unlockEntity = () => {
        Service.LockUnLockEntity('Configurationprop/UnLockEntity', "Permission", props.id, loggedInUser).then((res) => {
        });

    }
    const class_edit = `col-md-2 textbox-padding  ${Service.editPerms(userPerms) == false ? "disabledClass" : ""}`;

    const class_chk = `  ${Service.editPerms(userPerms) == false ? "disabledClass" : ""}`;
    return (
        <>
            <div class="container-fluid content-top-gap">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb my-breadcrumb">
                        <li class="breadcrumb-item"><Link to="/">{t('Home.title')}</Link></li>
                        <li className="breadcrumb-item"><Link to="/admin">{t('Menu.Admin')}</Link></li>
                        <li class="breadcrumb-item active" aria-current="page"><Link to="/PermissionSet">{t('UserList.PermissionSetList')}</Link></li>
                        <li class="breadcrumb-item active" id="libread" aria-current="page">{t('UserList.EditPermissionSet')}</li>
                    </ol>
                </nav>

                <div class="">
                    <div class="row">
                        <div class="col-lg-12 pr-lg-2">
                            <div class="card text-center card_border">

                                <div class="card-header chart-grid__header">
                                    <div class="row">
                                        <div class="col-md-2">
                                            {t('UserList.EditPermissionSet')}
                                        </div>
                                        <div class="col-md-10">
                                            {Service.editPerms(userPerms) == false ? "" : (isLocked === true && lockedBy.toString() == loggedInUser) ? <button class="btn  btn-update btn-sm ml-2" style={{ float: "right",width: "50px" }} onClick={submitButton} type="submit">{t('Submit')}</button> : ''}
                                            <button className="btn  btn-update btn-sm ml-2" id="close" style={{ width: "45px", backgroundColor: "buttonface", float: "right" }} onClick={(event) => closeHandler("Open")} >{t('Close')}</button>
                                        </div>
                                    </div>
                                </div>
                                

                               
                                <div class="card-body" style={{ minHeight: "70vh" }}>
                                    <div className="container">

                                        <div class="row">
                                            <label class="col-md-2 text-left">{t('UserList.PermissionSetName')}<span className={classes.mandatory}>*</span></label>
                                            <input id="aid"

                                                onKeyPress={(e) => { e.key === ' ' && e.preventDefault(); }}
                                                autocomplete="off" list="autocompleteOff"
                                                onChange={inputsHandler} value={inputField.Name} name="Name" readOnly="true" placeholder={t('UserList.PermissionSetName')} autoComplete='no' type="text" class="col-md-2 textbox-padding" />
                                        </div>
                                        <div class="row mt-3">
                                            <label class="col-md-2 text-left">{t('UserList.PermissionSetLabel')}<span className={classes.mandatory}>*</span></label>
                                            <input id="aid" onChange={inputsHandler} type="text" autoComplete='no' value={inputField.Label} placeholder={t('UserList.PermissionSetLabel')} name="Label" className={class_edit}
                                                disabled={disable} />
                                        </div>
                                        <br></br><br></br>

                                        <table aria-labelledby="tabelLabel" className="table " border="0">


                                            <thead>
                                                <tr>
                                                    <th style={{ width: 350 + "px" }}>{t('UserList.Modules/Section/Sub-sections')}</th>
                                                    <th>{t('Rebates.Actions')}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {filteredArray.map((root, index1) => (
                                                    <>
                                                        <tr>
                                                            <td>

                                                                {root.Name}

                                                            </td>
                                                            <td>
                                                                <div className="row">
                                                                    {root.HasAction == true ? (
                                                                        root.ActionLists.map((item, index) => (
                                                                            <div className="col-sm-2">
                                                                                <input radioGroup={root.Id} className={class_chk}
                                                                                    style={{ fontStyle: "normal" }}
                                                                                    type="checkbox"
                                                                                    id={item.ActionName}
                                                                                    checked={item.IsAdded}
                                                                                    onChange={(event) => handleChangeLevel1(event, root.Id, root.Roleid, '' + item.ActionName + '', index1)}
                                                                                    disabled={disable} />
                                                                                <label>
                                                                                    &nbsp; {item.ActionName} &nbsp;
                                                                                </label>
                                                                            </div>
                                                                        ))


                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        {root.SubMenuList.map((d, index2) => (


                                                            <>
                                                                <tr>
                                                                    <td> &nbsp; &nbsp; &nbsp; &nbsp;
                                                                        {d.Name === "Rebate Agreement" || d.Name === "Accruals" ? "" :
                                                                            <>
                                                                                <input type="checkbox" className={class_chk} style={{ fontStyle: "normal", pointerEvents: "none" }} checked={d.IsAdded} />
                                                                            </>}&nbsp; {d.Name}
                                                                    </td>
                                                                    <td>
                                                                        <div className="row">
                                                                            {d.HasAction == true ? (

                                                                                d.ActionLists.map((item, index) => (
                                                                                    <>


                                                                                        {d.ActionLists.filter((item) => item.ActionName === 'View Only').length == 0 && index == 0 ? <div className="col-sm-2">&nbsp;</div> : ""}

                                                                                        <div className="col-sm-2">
                                                                                            <input className={class_chk}
                                                                                                style={{ fontStyle: "normal" }}
                                                                                                type="checkbox"
                                                                                                id={item.ActionName}
                                                                                                checked={item.IsAdded}
                                                                                                value={[d.Id, d.Roleid]} onChange={(event) => handleChangeLevel2(event, d.Id, d.Roleid, '' + item.ActionName + '', index1, index2)}
                                                                                                disabled={disable} />
                                                                                            <label>
                                                                                                &nbsp; {item.ActionName} &nbsp;
                                                                                            </label>
                                                                                        </div> </>
                                                                                ))


                                                                            ) : (
                                                                                ""
                                                                            )}
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                               
                                                                {d.SubMenuList.map((f, index3) => (
                                                                    <>
                                                                        <tr>
                                                                            <td> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;

                                                                                &nbsp;
                                                                                {f.HasAction == false ? f.Name :
                                                                                    <>
                                                                                        <input type="checkbox" className={class_chk} style={{ fontStyle: "normal", pointerEvents: "none" }} checked={f.IsAdded} />   &nbsp;{f.Name}
                                                                                    </>
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                <div className="row">
                                                                                    {f.HasAction == true ? (

                                                                                        f.ActionLists.map((item, index) => (
                                                                                            <>


                                                                                                {f.ActionLists.filter((item) => item.ActionName === 'View Only').length == 0 && index == 0 ? <div className="col-sm-2">&nbsp;</div> : ""}

                                                                                                <div className="col-sm-2">
                                                                                                    <input className={class_chk}
                                                                                                        style={{ fontStyle: "normal" }}
                                                                                                        type="checkbox"
                                                                                                        id={item.ActionName}
                                                                                                        checked={item.IsAdded}
                                                                                                        value={[f.Id, f.Roleid]} onChange={(event) => handleChangeLevel3(event, f.Id, f.Roleid, '' + item.ActionName + '', index1, index2, index3)}
                                                                                                        disabled={disable} />
                                                                                                    <label>
                                                                                                        &nbsp; {item.ActionName} &nbsp;
                                                                                                    </label>
                                                                                                </div> </>
                                                                                        ))


                                                                                    ) : (
                                                                                        ""
                                                                                    )}
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                        
                                                                        {!!f.SubMenuList &&

                                                                            f.SubMenuList.map((t, index4) => (
                                                                                <>
                                                                                    <tr>
                                                                                        <td> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;

                                                                                            &nbsp;
                                                                                            {t.HasAction == false ? t.Name :
                                                                                                <>
                                                                                                    <input type="checkbox" style={{ fontStyle: "normal", pointerEvents: "none" }} checked={t.IsAdded} />   &nbsp;{t.Name}
                                                                                                </>
                                                                                            }
                                                                                        </td>
                                                                                        <td>
                                                                                            <div className="row">
                                                                                                {(t.HasAction == true  && !!t.ActionLists) && (
                                                                                                    t.ActionLists.map((item, index) => (
                                                                                                        <>


                                                                                                            {t.ActionLists.filter((item) => item.ActionName === 'View Only').length == 0 && index == 0 ? <div className="col-sm-2">&nbsp;</div> : ""}

                                                                                                            <div className="col-sm-2">
                                                                                                                <input
                                                                                                                    style={{ fontStyle: "normal" }}
                                                                                                                    type="checkbox"
                                                                                                                    id={item.ActionName}
                                                                                                                    checked={item.IsAdded}
                                                                                                                    value={[t.Id, t.Roleid]} onChange={(event) => handleChangeLevel4(event, t.Id, t.Roleid, '' + item.ActionName + '', index1, index2, index3, index4)}
                                                                                                                />
                                                                                                                <label>
                                                                                                                    &nbsp; {item.ActionName} &nbsp;
                                                                                                                </label>
                                                                                                            </div> </>
                                                                                                    ))


                                                                                                ) 
                                                                                                }
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>                                                                                   
                                                                                    
                                                                                </>
                                                                                
                                                                            ))

                                                                        }
                                                                    </>
                                                                ))
                                                                }
                                                            </>
                                                        ))}
                                                    </>
                                                ))}
                                            </tbody>
                                        </table></div></div></div></div></div> </div>
                <Modal show={closeModal}
                    size="sm"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <Modal.Body style={{ "textAlign": 'center', fontFamily: 'Segoe UI' }}>{t('DefaultValue.YourChangesWillBeLost!AreYouSureYouWantToClose?')}</Modal.Body>
                    <Modal.Footer>
                        <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black',width: "45px" }} size='sm' onClick={() => closeHandler("Close")}>
                            {t('Close')}
                        </Button>
                        <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black',width: "50px" }} size='sm' onClick={() => closeHandler("Cancel")}>
                            {t('Cancel')}
                        </Button>
                    </Modal.Footer>
                </Modal>
                <CustomErrorPopup ErrorMessage={errorMessage} show={showErrorModal} ErrorStatus={errorStatus}
                    onHide={() => setShowErrorModal(false)}></CustomErrorPopup>
            </div></>
    );

}

export default EditPermissionSetComponent