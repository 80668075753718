import Navbar from "../Navbar/Navbar"
import { Form, FormGroup, Col, Row, Button, InputGroup, Collapse, Modal } from 'react-bootstrap';
import Footer from "../Footer/Footer"
import Header from "../Header/Header"
import SessionManage from '../../../SessionManagement/SessionManage';

import routes from "../../../Routes/Route";

import {
     BrowserRouter as Router,
    Routes,
    Route,
    useLocation,
    matchPath
} from "react-router-dom";
import Service from "../../../Services/Service";
import { useState,useEffect } from "react";

function Content() {
    const [closeModal, setCloseModal] = useState(false);
    const [signoutTime, setSignoutTime] = useState(SessionManage.getidletimeout()*60*1000);      
    const [warningTime, setWarningTime] = useState(10*60*1000);
  let warnTimeout;
    let logoutTimeout;
    let roleid = sessionStorage.getItem('roleid');
    const [HasPermission, setHasPermission] = useState(true);
    const [HasPermsCheckFirsttime, setHasPermsCheckFirsttime] = useState(false);
    const location = useLocation();
    const GetUserPermissionByUrl = (pathname,fullpath) => {
        if(HasPermsCheckFirsttime==false){
        Service.GetUserPermissionByUrl(pathname,fullpath).then((res) => {
            if (typeof res !== 'undefined') {

                if (res.data !== 'null' && typeof res.data !== 'undefined' && res.data !== '') {
                    if (typeof res.data.HasPermission !== 'undefined') {                    
                        setHasPermission(res.data.HasPermission)
                        setHasPermsCheckFirsttime(res.data.HasPermission);
                       
                    }
                }
            }
        });
    }
    }

     const logout = () => {
           setCloseModal(true);
            Service.getdata("LockedDocuments/UnlockAllRecordByUser?userId="+SessionManage.getuserid()).then((res) => {
            if (typeof res !== 'undefined') {
              sessionStorage.clear();          
            
            }});
        }
      const warn = () => {
        Service.getdata("LockedDocuments/UpdateLastLogin?userId="+SessionManage.getuserid()).then((res) => {
            if (typeof res !== 'undefined') {
             
            }});
             if (warnTimeout) clearTimeout(warnTimeout);   
         }
        const setTimeouts = () => {     
            warnTimeout = setTimeout(warn, warningTime);
            logoutTimeout = setTimeout(logout, signoutTime);
        };
        const clearTimeouts = () => {    
            if (logoutTimeout) clearTimeout(logoutTimeout);
        }; 
   window.onbeforeunload = function () {
        //debugger;
       if (window.location.href.toLowerCase().includes('new-rebate') || window.location.href.toLowerCase().includes('accruals/new')) {
        if(closeModal===true){
 

        }else{
          
            var url = window.location.href.toLowerCase();
              if (url.includes("new-rebate") ) {
             if(sessionStorage.getItem("ids")!=null){
                 Service.LockUnLockEntity(
                    "Configurationprop/UnLockEntity",
                    "Rebate",
                    sessionStorage.getItem("ids"),
                    SessionManage.getuserid()
                    ).then((res) => {});
                 }
              }else   if (url.includes("accruals/new")) {
                if(sessionStorage.getItem("ids")!=null){
                   Service.LockUnLockEntity(
                    "Configurationprop/UnLockEntity",
                    "Accrual",
                    sessionStorage.getItem("ids"),
                    SessionManage.getuserid()
                    ).then((res) => {});
              }
            }

          
            return '';
        }
       }
      
    };



        
   useEffect(() => {

    //alert(pathname);
   
            const events = [
                'load',
                'mousemove',
                'mousedown',
                'click',
                'scroll',
                'keypress'
            ];
            const resetTimeout = () => {
                clearTimeouts();
                setTimeouts();
            };

            for (let i in events) {
                window.addEventListener(events[i], resetTimeout);
            }

            setTimeouts();
            return () => {
                for(let i in events){
                    window.removeEventListener(events[i], resetTimeout);
                    clearTimeouts();
                }
            }
        },[]);

         const okHandler = () => {
             setCloseModal(false);
              window.location.href='/';
         }
    return (
        <>
            <section>
                <div className="se-pre-con"></div>
                <Navbar />
                <Header />

                <div id="wrapp" className="main-content">
                    <Routes>
                        {routes.map((route, idx) => {
                            if (route.path != "*" && roleid != null && route.path!='/notfound') {
                                if (matchPath({ path: route.path, exact: false }, location.pathname))
                                {
                                    GetUserPermissionByUrl(route.path,location.pathname);
                                }
                            }
                            if (HasPermission == false) {
                                { window.location = '/notfound' }
                            } else {

                                return (
                                    route.component && (
                                        <Route
                                            key={idx}
                                            path={route.path}
                                            exact={route.exact}
                                            name={route.name}
                                            // element
                                            element={<route.component />}

                                        />
                                    )
                                )
                            }
                        })}

                    </Routes>
                </div>

                {/* <Footer /> */}


            <Modal show={closeModal}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Body style={{ "textAlign": 'center', fontFamily: 'Segoe UI' }}>You have been logged out due to inactivity. Please log in again.</Modal.Body>
                <Modal.Footer>
                    <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black' }} size='sm' onClick={() => okHandler()}>
                        Ok
                    </Button>
                 
                </Modal.Footer>
            </Modal>
            </section>
        </>
    )
}
export default Content