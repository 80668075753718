import { Link, useLocation } from "react-router-dom"
import Service from "../../Services/Service";
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import moment from 'moment';
import Multiselect from 'multiselect-react-dropdown';
import SessionManage from "../../SessionManagement/SessionManage";

import { CSVLink } from "react-csv";

function SchedulerhistoryComponent(props) {
    const [showbutton, setshowbutton] = useState('');
    const location = useLocation();
    const [userPerms, setuserPermission] = useState([]);
    const [filterValue, setFilterValue] = useState('');
    const [filterCol, setFilterCol] = useState('');
    const [dropdownData, setDropdownData] = useState([]);
    const [showfilterpopup, setshowfilterpopup] = useState(false);
    const [columnAscending, setColumnAscending] = useState(true);
    const [totalselectedshow, settotalselectedshow] = useState('');
    const [totalselectedcount, settotalselectedcount] = useState(0);
    const [totalCountlst, settotalCountlst] = useState('');
    const csvInstance = useRef();

    const [dropdownChange, setDropdownChange] = useState('');
    const [csvList, setCsvList] = useState([]);
    const [sortColName, setSortColName] = useState("");
    const [sticky, setSticky] = useState("");
    const [searchValue, setSearchValue] = useState(null);
    const [filterValueDict, setFilterValueDict] = useState({
        rolename: '',
        rolelabel: '',
        last_modified_date: null,
    });
    const checkAll = (mycheckbox) => {
        var checkboxes = document.querySelectorAll("input[type = 'checkbox']");
        if (mycheckbox.target.checked == true) {
            settotalselectedshow(true);
            checkboxes.forEach(function (checkbox) {
                checkbox.checked = true;
            });
            settotalselectedcount(checkboxes.length - 1)
        }
        else {
            settotalselectedshow(false);
            checkboxes.forEach(function (checkbox) {
                checkbox.checked = false;
            });
            settotalselectedcount(0)
        }

    }

    const [paging, setpaging] = useState({
        pageSize: 20,
        pageNumber: 1
    })


    const [showspinner, setshowspinner] = useState('');
    const [data, setdata] = useState([]);

    const [showLoadMore, setshowLoadMore] = useState('');

    const LoadMore = (lst, totalcount) => {

        if (lst < totalcount) {
            setshowLoadMore(true);
        }
        else if (lst >= totalcount) {
            setshowLoadMore(false);
        } else {
            setshowLoadMore(false);
        }

    }
    const [multiFilterValue, setMultiFilterValue] = useState({
        createdby: '',
        last_modified_by: '',
        IsActive: '',
    });
    const [checkedValueid, setCheckedValueid] = useState([]);
    const [filteredArray, setFilteredArray] = useState(data);
    const navigate = useNavigate();


    useEffect(() => {
        setshowspinner(true);

        setColumnAscending(false);
        setSortColName("Id");
        getData("SchedulerJob/GetALLSchedulerhistory/", props.id, paging.pageSize, paging.pageNumber, "Descending", "Id", [], null);
        window.addEventListener("scroll", isSticky);
        return () => {
            window.removeEventListener("scroll", isSticky);
        };
    }, [0])

    const isSticky = () => {
        const scrollTop = window.scrollY;
        const stickyClass = scrollTop >= 60 ? "is-sticky-thead" : "";
        setSticky(stickyClass);
        const scrollTopActual = scrollTop >= 60 ? scrollTop - 85 : 0;
        var divtop1 = document.getElementById("tableA");
        setdivtop(divtop1.offsetTop + scrollTopActual)
    };
    const class_thead = `text-white rt-thead  ${sticky}`;

    const getUserPermission = (pathname) => {
        Service.GetUserPermission(pathname).then((res) => {
            if (res !== 'undefined') {
                if (res.data !== 'null' && res.data !== 'undefined' && res.data !== '') {
                    if (res.data.DataList !== 'undefined') {
                        setuserPermission(res.data.DataList)
                    }
                }
            }
        });
    }
    const getData = (method, id, pageSize, pageNumber, sortType, SortProperty, filterValue, search) => {

        Service.getDatawithMultiFilterSearchwithId(method, id, pageSize, pageNumber, sortType, SortProperty, filterValue, search).then((res) => {
            if (typeof res !== 'undefined') {

                if (res.data !== 'null' && typeof res.data !== 'undefined') {
                    setdata(res.data.DataList);
                    setFilteredArray(res.data.DataList)
                    settotalCountlst(res.data.Totalcount)
                    LoadMore(res.data.DataList.length, res.data.Totalcount)
                    setshowspinner(false);
                }
            }
        });

    }

    function Delete(id) {
        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
        if (id == null || id == 0) {
            alert('Please select the Role to Delete')
        }
        else {

            Service.delete("Roles/Delete/", id).then(res => {
                if (typeof res !== 'undefined') {
                    if (typeof res.data !== 'undefined' && res.data !== 'null') {
                        getData("Roles/GetAll", paging.pageSize, paging.pageNumber, sortType, sortColName, filterLst(), searchValue)

                    }
                }

            });
        }
    }


    const handleLoadMore = () => {
        let pagenumber = paging.pageNumber + 1;
        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
        Service.getDatawithMultiFilterSearchwithId("SchedulerJob/GetALLSchedulerhistory/", props.id, paging.pageSize, pagenumber, sortType, sortColName, filterLst(), searchValue).then((res) => {
            if (typeof res !== 'undefined') {
                if (res.data !== 'null' && typeof res.data !== 'undefined') {
                    const currentlength = data.length;
                    setdata(data => [...data, ...res.data.DataList])
                    setFilteredArray(filteredArray => [...filteredArray, ...res.data.DataList])
                    LoadMore(currentlength + res.data.DataList.length, res.data.Totalcount)
                }
            }
        });

        paging.pageNumber = pagenumber
        setpaging(paging);
    };
    const OndropdownChange = (e) => {

        let selectedOption = (e);

        setFilterValue([])
        let data = []

        for (let i = 0; i < selectedOption.length; i++) {

            const getallData = {
                Name: selectedOption[i].Name,
            }
            data.push(getallData)
        }

        setFilterValue(data);
        setMultiFilterValue(() => ({
            ...multiFilterValue, [filterCol]: data,
        }));
    }
    const customMultiStyles = {
        multiselectContainer: {
            maxWidth: '188px',
            marginLeft: '3px'
        },
        chips: {
            borderRadius: '5px'
        }
    };
    const handleChange = (e, id) => {


        let isChecked = e.target.checked;   // true if it is checked false if unchecked

        let checkedBoxes = document.querySelectorAll('input[name=btSelectItem]:checked');
        if (isChecked === true) {
            settotalselectedshow(true);
            settotalselectedcount(checkedBoxes.length);
            setCheckedValueid(id)
        }
        else {
            if (checkedBoxes.length === 0) {
                settotalselectedshow(false);
            }
            settotalselectedcount(checkedBoxes.length);
            setCheckedValueid([])
        }

        if (checkedBoxes.length == 1) {
            setshowbutton(true);
        }
        else {
            setshowbutton(false);
        }
    }



    const searchHandler = (event) => {
        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
        let pagenumber = 1;
        paging.pageNumber = pagenumber;
        setpaging(paging);
        if (event.target.value != '') {
           
            setSearchValue(String(event.target.value).toLowerCase())
            getData("SchedulerJob/GetALLSchedulerhistory/", props.id, paging.pageSize, pagenumber, sortType, sortColName, filterLst(), event.target.value);
        }
        else {
            setSearchValue(null);
            getData("SchedulerJob/GetALLSchedulerhistory/", props.id, paging.pageSize, pagenumber, sortType, sortColName, filterLst(), null);
        }
    }

    const filterInput = (e) => {
        
        let inputValue = e.target.value
        setFilterValue(inputValue)
        setFilterValueDict(() => ({
            ...filterValueDict, [filterCol]: e.target.value,
        }));
      
    }

    const [divleft, setdivleft] = useState('');
    const [divtop, setdivtop] = useState('');

    const FilterColumn = (name) => {
        const scrollTop = window.scrollY;
        const scrollTopActual = scrollTop >= 60 ? scrollTop - 85 : 0;
        var divtop1 = document.getElementById("tableA");
        setdivtop(divtop1.offsetTop + scrollTopActual + 30)
       
        setshowfilterpopup(true)
        setFilterCol(name)

        if (name === 'rolename') {
            var testDiv = document.getElementById("rolename");
            setdivleft(testDiv.offsetLeft + 20 + "px");
        }
        if (name === 'rolelabel') {
            var testDiv = document.getElementById("rolelabel");
            setdivleft(testDiv.offsetLeft + 20 + "px");
        }
        if (name === 'last_modified_date') {
            var testDiv = document.getElementById("last_modified_date");
            setdivleft(testDiv.offsetLeft + 20 + "px");
        }
        if (name === 'last_modified_by') {
            var testDiv = document.getElementById("last_modified_by");
            setdivleft(testDiv.offsetLeft + 20 + "px");
            DropdownHandler("roles")
        }
    }


  
    const filterHandler = (flag) => {

        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
        let pagenumber = 1;
        paging.pageNumber = pagenumber;

        if (flag === 'Apply') {
            setshowfilterpopup(false)
            let list = [];

            for (var key in multiFilterValue) {
                if (multiFilterValue[key].length > 0) {
                    let values = ""
                    multiFilterValue[key].map((i) => {
                        values += i.Name + ";";
                    })
                    if (values !== null && values !== '') {
                        list.push({
                            filterKey: key,
                            FilterValue: values
                        })
                    }
                }
            }

            for (var key in filterValueDict) {
                if (filterValueDict[key] !== null && filterValueDict[key] !== '') {
                    list.push({
                        filterKey: key,
                        FilterValue: filterValueDict[key]
                    })
                }
            }
            if (list.length > 0) {
                setpaging(paging);
                getData("SchedulerJob/GetALLSchedulerhistory/", props.id, paging.pageSize, pagenumber, sortType, sortColName, list, searchValue);
            }
            
        }
        else {
            setshowfilterpopup(false)
            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: '',
            }));
            setFilterValueDict(() => ({
                ...filterValueDict, [filterCol]: '',
            }));

            let list = [];
            for (var key in multiFilterValue) {
                if (key !== filterCol && multiFilterValue[key].length > 0) {
                    let values = ""
                    multiFilterValue[key].map((i) => {
                        values += i.Name + ";";
                    })
                    if (values !== null && values !== '') {
                        list.push({
                            filterKey: key,
                            FilterValue: values
                        })
                    }
                }
            }

            for (var key in filterValueDict) {
                if (filterValueDict[key] !== null && filterValueDict[key] !== '' && key !== filterCol) {
                    list.push({
                        filterKey: key,
                        FilterValue: filterValueDict[key]
                    })
                }
            }
            setpaging(paging);
            getData("SchedulerJob/GetALLSchedulerhistory/", props.id, paging.pageSize, pagenumber, sortType, sortColName, list, searchValue);

           
            setFilterCol('')
            setFilterValue('')
            setDropdownData([])
            setDropdownChange("")
        }
    }

    const filterLst = () => {
        let list = [];
        for (var key in multiFilterValue) {
            if (multiFilterValue[key].length > 0) {
                let values = ""
                multiFilterValue[key].map((i) => {
                    values += i.Name + ";";
                })
                if (values !== null && values !== '') {
                    list.push({
                        filterKey: key,
                        FilterValue: values
                    })
                }
            }
        }

        for (var key in filterValueDict) {
            if (filterValueDict[key] !== null && filterValueDict[key] !== '') {
                list.push({
                    filterKey: key,
                    FilterValue: filterValueDict[key]
                })
            }
        }
        return list;
    }

    const SortColumn = (colName) => {
        var sortType = '';
        if (columnAscending === true) {
            setColumnAscending(false);
            sortType = "Descending";
        } else {
            setColumnAscending(true);
            sortType = "Ascending";
        }
        setSortColName(colName);
 
        let pagenumber = 1;
      
        paging.pageNumber = pagenumber;
        setpaging(paging);
        getData("SchedulerJob/GetALLSchedulerhistory/", props.id, paging.pageSize, paging.pageNumber, sortType, colName, filterLst(), searchValue);
    }


    let headers = [
        { label: "Role Name", key: "Name" },
        { label: "Role Label", key: "label" },
        { label: "Last Modified By", key: "LastModifiedUser" },
        { label: "Last Modified Date", key: "LastModifiedDate" },

    ];
    const ExporttoCSV = () => {

        let checkedBoxes = document.querySelectorAll("input[name=btSelectItem]:checked");
        let values = "";
        if (checkedBoxes.length >= 1) {
            for (let checkbox of checkedBoxes) {
                if (values === "") {
                    values = checkbox.id;
                } else {
                    values = values + ";" + checkbox.id;
                }
            }
        }
        let objFilter = [];
        let objFilterValue = [
            {
                filterKey: "role_id",
                FilterValue: values,
            },
        ];
     
        objFilter = objFilterValue;
        
        Service.getdataWithPagingByGroup("SchedulerJob/GetALLSchedulerhistory/", props.id, 20000, 1, "Ascending", "Id", objFilter).then((res) => {
          
            if (typeof res !== "undefined") {
                if (res.data !== "null" && res.data !== "undefined") {
                    setCsvList(res.data.DataList);
                    setTimeout(() => {
                        csvInstance.current.link.click();
                    });
                }
            }
        });
    };
    const DropdownHandler = (key) => {
        Service.getfilterDropdown('Permission/GetFilterValue', key).then((res) => {
            if (typeof res !== 'undefined') {

                if (res.data !== 'null' && typeof res.data !== 'undefined') {
                    setDropdownData(res.data)
                }
            }
        });
    }
    return (<>
        <div class="container-fluid content-top-gap">
            <div style={{ display: "none" }}>
                <CSVLink data={csvList} headers={headers} ref={csvInstance} filename="Schedulerhistory.csv" > </CSVLink>
            </div>


            <nav aria-label="breadcrumb">
                <ol class="breadcrumb my-breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                    <li class="breadcrumb-item active" aria-current="page">Scheduler history</li>
                  
                </ol>
            </nav>

            <div class="">
                <div class="row">
                    <div class="col-lg-12 pr-lg-2">
                        <div class="card text-center card_border">

                            <div class="card-body" style={{ minHeight: "70vh" }}>
                                <div class="row">

                                    <div class="col-md-8">
                                        <input type="text" autoComplete='off' class="form-control form-control-user" id="firstName" name="firstName" placeholder="Search for RoleName & Role label" onChange={(event) => searchHandler(event)} />

                                    </div>
                                    <div class="col-md-4">
                                        {Service.editPerms(userPerms) == false ? "" : <Link class="btn btn-sm btn-update ml-2" id="create" style={{ width: "90px", backgroundColor: "buttonface", float: "right" }} to="/roles/add">Create New</Link>}
                                        {totalselectedcount <= 0 ? "" : Service.exportPerms(userPerms) == false ? "" : <button className="btn btn-update  btn-sm ml-2" type="submit" style={{ float: "right", width: "50px" }} onClick={(event) => ExporttoCSV()}>Export</button>}

                                        {showbutton === true ?
                                            <>
                                                <button className="btn btn-update btn-sm ml-2" id="open" type="submit" style={{ float: "right", width: "40px" }} >Open</button>

                                            </>
                                            :
                                            ''
                                        }
                                    </div>

                                </div>

                                <table class="table table-bordered text-left mt-2" id="tableA">
                                    <thead className={class_thead} style={{ backgroundColor: "var(--primary)" }}>
                                        <tr>
                                            <th class="tableheader" style={{ width: '30px' }}>
                                                <input type="checkbox" id="option-all" onChange={checkAll} />
                                            </th>
                                          
                                            <th id="rolename" style={{ backgroundColor: (filterValueDict.rolename ? '#6cae55' : ''), width: '330px' }}>
                                                Status <span className='fa fa-sort' onClick={() => SortColumn('Name')}></span>
                                                <span className='fa fa-filter' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('rolename')}></span>
                                            </th>
                                         
                                            <th id="last_modified_by" style={{ backgroundColor: (multiFilterValue.last_modified_by ? '#6cae55' : ''), width: '330px' }} >Start Date Time <span className='fa fa-sort' onClick={() => SortColumn('LastModifiedUser')}></span>
                                                <span className='fa fa-filter' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('last_modified_by')}></span>

                                            </th>
                                            <th id="last_modified_by" style={{ backgroundColor: (multiFilterValue.last_modified_by ? '#6cae55' : ''), width: '330px' }} >End Date Time<span className='fa fa-sort' onClick={() => SortColumn('LastModifiedUser')}></span>
                                                <span className='fa fa-filter' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('last_modified_by')}></span>

                                            </th>
                                            <th id="last_modified_date" style={{ backgroundColor: (filterValueDict.last_modified_date ? '#6cae55' : ''), width: '300px' }} >Next Schedule Date Time <span className='fa fa-sort' onClick={() => SortColumn('LastModifiedDate')}></span>
                                                <span className='fa fa-filter' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('last_modified_date')}></span>
                                            </th>

                                        </tr>
                                    </thead>
                                    <tbody>

                                        {
                                            showspinner === true ?

                                                <>
                                                    <Spinner animation="grow" size="sm" variant="success" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                    <Spinner animation="grow" size="sm" variant="danger" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                    <Spinner animation="grow" size="sm" variant="warning" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                </>
                                                :
                                                filteredArray.length === 0 ?
                                                    <tr><td></td>
                                                        <td></td>

                                                        <td>
                                                            No Data Available
                                                        </td>
                                                        <td></td>
                                                    </tr>
                                                    :
                                                    filteredArray.map((item, index) => (
                                                        <tr key={item.Id}>
                                                            <td><input data-index="1" name="btSelectItem" type="checkbox" onChange={(event) => handleChange(event, item.Id)} id={item.Id} /></td>
                                                           
                                                            <td>{item.Status}</td>
                                                            <td>{item.Starttime === null ? "" : moment(item.Starttime).format(SessionManage.getcurrentdateformat() + " hh:mm")}</td>
                                                            <td>{item.Endtime === null ? "" : moment(item.Endtime).format(SessionManage.getcurrentdateformat() + " hh:mm")}</td>
                                                            <td>{item.Nextscheuleddate === null ? "" : moment(item.Nextscheuleddate).format(SessionManage.getcurrentdateformat() + " hh:mm")}</td>

                                                           
                                                           
                                                        </tr>


                                                    ))


                                        }


                                    </tbody>
                                </table>
                                <div className='row'>

                                    {
                                        totalselectedshow === true ?

                                            <div className='col-lg-2 forgettext col-sm-12 border' style={{ textAlign: "center" }}>
                                                Total Records Selected - <span id="totalselected">{totalselectedcount}</span>
                                            </div>

                                            :
                                            <div className='col-lg-2  col-sm-12' style={{ textAlign: "center" }}>

                                            </div>
                                    }



                                    <div className='col-lg-8 col-sm-12'></div>


                                    <div className='col-lg-2 forgettext  col-sm-12 border' style={{ textAlign: "center" }}>
                                        Total Records - {totalCountlst}
                                    </div>
                                </div>
                                {
                                    showLoadMore === true ?
                                        <div className='text-center container row'>
                                            <div className='col-md-5'></div>
                                            <div className='col-md-2'>
                                                <button className='btn btn-sm btn-update ml-1' onClick={handleLoadMore} style={{ width: "70px" }}>Load More</button>
                                            </div>
                                            <div className='col-md-5'></div>

                                        </div>
                                        :
                                        ""
                                }
                                {showfilterpopup === true ?
                                    <div className='pop-up' style={{ left: divleft, top: divtop }}>
                                      
                                        {filterCol === 'rolename' || filterCol === 'rolelabel' ?

                                            <div>
                                                <span className='fa fa-filter' /><input type='textbox' placeholder='Search...' className='col-md-10 ml-2' value={filterValueDict[filterCol]} onChange={(event) => filterInput(event)}></input>
                                            </div>
                                            :
                                            <></>

                                        }

                                        {filterCol === 'last_modified_date' ?

                                            <div>
                                                <span className='fa fa-filter' /><input type='date' className='col-md-10 ml-2' value={filterValueDict[filterCol]} onChange={(event) => filterInput(event)}></input>
                                            </div>

                                            :
                                            <></>
                                        }

                                        {filterCol === 'createdby' || filterCol === 'last_modified_by' ?
                                            <>
                                                <div className="row">
                                                    <span className='col-md-1 fa fa-filter' />
                                                    <Multiselect
                                                        onSelect={(Event) => OndropdownChange(Event)}
                                                        onRemove={(Event) => OndropdownChange(Event)}
                                                        options={dropdownData}
                                                        displayValue="Name"
                                                       
                                                        showCheckbox={true}
                                                        selectedValues={multiFilterValue[filterCol]}
                                                        className='col-md-9 textbox-padding fontsizedropdown'
                                                        style={customMultiStyles}
                                                    />
                                                </div>
                                            </>
                                            :
                                            <></>
                                        }
                                        <div style={{ paddingTop: '10px', paddingLeft: '47px', paddingBottom: '7px' }}>
                                            <button className="btn btn-sm btn-update" onClick={() => filterHandler('Apply')} style={{ width: "30px" }}>Ok</button>
                                            <button className="btn btn-sm btn-update" style={{ marginLeft: '10px', width: "50px" }} onClick={() => filterHandler('Reset')}>Remove</button>
                                        </div>
                                        <div>
                                        </div>
                                    </div>
                                    :
                                    <></>
                                }
                            </div>

                        </div>
                    </div>

                </div>
            </div>



        </div>



    </>)
}
export default SchedulerhistoryComponent