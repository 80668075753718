import { Link, useLocation } from "react-router-dom"
import React, { useEffect, useState, useRef, forwardRef } from 'react';
import { CSVLink } from "react-csv";

import Service from '../../Services/Service';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import Multiselect from 'multiselect-react-dropdown';
import SessionManage from '../../SessionManagement/SessionManage';
import CustomErrorPopup from '../../UI/CustomErrorPopup';
import { useTranslation } from "react-i18next";

function LockedDocumentsComponent(props) {
    const csvInstance = useRef();
    const location = useLocation();
    const [csvList, setCsvList] = useState([]);
    const [sortColName, setSortColName] = useState("");
    const [sticky, setSticky] = useState("");
    const [t, i18n] = useTranslation('translation');
    const [userPerms, setuserPermission] = useState([]);
    const navigate = useNavigate();
    const [showbutton, setshowbutton] = useState('');
    const [showspinner, setshowspinner] = useState('');
    const [filterValue, setFilterValue] = useState('');
    const [filterCol, setFilterCol] = useState('');
    const [showfilterpopup, setshowfilterpopup] = useState(false);
    const [dropdownData, setDropdownData] = useState([]);
    const [dropdownChange, setDropdownChange] = useState('');
    let rowData = [];
    const [listViewData, setListViewData] = useState(rowData)
    const [filteredArray, setFilteredArray] = useState(rowData)
    const [state, setState] = useState({ status: '2' });
    const [checkedValueids, setCheckedValueids] = useState([]);
    const [checkedValueDocumentIds, setCheckedValueDocumentIds] = useState([]);
    const [searchValue, setSearchValue] = useState('')
    const [totalselectedshow, settotalselectedshow] = useState('');
    const [totalselectedcount, settotalselectedcount] = useState(0);
    const [totalCountlst, settotalCountlst] = useState('');
    const [columnAscending, setColumnAscending] = useState(true);
    const [paging, setpaging] = useState({
        pageSize: 20,
        pageNumber: 1
    })
    const [showLoadMore, setshowLoadMore] = useState('');
    const [filtertext, setfiltertext] = useState('');
    const [filterSearch, setFilterSearch] = useState("");
    const [filterdropdownData, setFilterDropdownData] = useState([]);
    const [filterValueDict, setFilterValueDict] = useState({
        rebate_agreement_id: '',
        rebate_program_id: '',
        amount: '',
        accrualid: '',
        version: ''
    });
    const [multiFilterValue, setMultiFilterValue] = useState({
        customer: '',
        lockeduser: ''
    });

    let loggedInUser = SessionManage.getuserid();
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [errorStatus, setErrorStatus] = useState("Error");

    {
        useEffect(() => {
            setshowspinner(true);
            getUserPermission(location.pathname);
            getLockedDocumentsData("LockedDocuments/GetAll", paging.pageSize, paging.pageNumber, "Ascending", "Id", []);
            window.addEventListener("scroll", isSticky);
            return () => {
                window.removeEventListener("scroll", isSticky);
            };
        }, [])
    }
    const isSticky = () => {
        const scrollTop = window.scrollY;
        const stickyClass = scrollTop >= 60 ? "is-sticky-thead" : "";
        setSticky(stickyClass);
        const scrollTopActual = scrollTop >= 60 ? scrollTop - 85 : 0;
        var divtop1 = document.getElementById("tableA");
        setdivtop(divtop1.offsetTop + scrollTopActual)
    };
    const class_thead = `text-white rt-thead  ${sticky}`;
    const ExporttoCSV = (event) => {
        debugger;
        let checkedBoxes = document.querySelectorAll('input[name=chkBox]:checked');
        let values = "";
        let id = "";
        let export_document_id = "";
        let idTypeVal = "";
        if (checkedBoxes.length >= 1) {
      
            checkedBoxes.forEach(function (checkbox) {
                checkbox.checked = true;
           
                values = checkbox.id.split(',');
                if (values.length > 0) {
                    values.forEach(i => {
                        idTypeVal = i.split(';');
                        if (idTypeVal.length > 0) {
                            export_document_id = export_document_id + ";" + idTypeVal[2];
                        }
                    })
                }

            });

        }
        let objFilter = [];
        let objFilterValue = [{
            filterKey: "export_document_id",
            FilterValue: export_document_id,
        },

        ]

        objFilter = objFilterValue;

        Service.getdataWithPagingByGroup("LockedDocuments/GetAll", 20000, 1, "Ascending", "Document_id", objFilter).then((res) => {
    
            if (typeof res !== 'undefined') {
                if (res.data !== 'null' && res.data !== 'undefined') {
                    setCsvList(res.data.DataList);
                    setTimeout(() => {
                        csvInstance.current.link.click();
                    });

                }
            }
        });
    }

    const getLockedDocumentsData = (method, pageSize, pageNumber, sortType, SortProperty, filterValue) => {
       
        Service.getAll(method, pageSize, pageNumber, sortType, SortProperty, filterValue).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                            rowData = res.data.DataList
                            LoadMore(res.data.DataList.length, res.data.Totalcount)
                            settotalCountlst(res.data.Totalcount)
                            setListViewData(rowData)
                            setFilteredArray(rowData)
                            setshowspinner(false);
                        }
                    }
                }
            }

        });
    }


    const searchHandler = (event) => {
        if (event.target.value != '') {
            setSearchValue(String(event.target.value).toLowerCase())
            let searchResults = listViewData.filter((each) => {
                return each.Document_id.toLowerCase().includes(searchValue) || each.type.toLowerCase().includes(searchValue) ||
                    each.LockedByUserName.toLowerCase().includes(searchValue);
            })
            setFilteredArray(searchResults)
        }
        else {
            setFilteredArray(listViewData)
        }
    }

    const handleLoadMore = () => {
        let pagesize = 20;
        let pagenumber = paging.pageNumber + 1;
        Service.getdataWithPagingByGroup("LockedDocuments/GetAll", pagesize, pagenumber, null, null, []).then((res) => {
            if (res && res.status === 200) {
                if (res.data !== 'null' && res.data !== 'undefined') {
                    rowData = res.data.DataList;
                  
                    if (res.data.DataList !== 'null' && res.data.DataList !== 'undefined') {
                        const currentlength = listViewData.length;
                        setListViewData(listViewData => [...listViewData, ...res.data.DataList])
                        setFilteredArray(filteredArray => [...filteredArray, ...res.data.DataList])
                        LoadMore(currentlength + rowData.length, res.data.Totalcount)
                    }

                }
            }
        });
        paging.pageSize = pagesize;
        paging.pageNumber = pagenumber
        setpaging(paging);
    };


    const handleChange = (e, listVal) => {
     
        let isChecked = e.target.checked;   // true if it is checked false if unchecked
      
        let check_lst = checkedValueids
      
        if (isChecked === true) {
            let concatValue = listVal.Id + ";" + listVal.type;
            setCheckedValueids([...checkedValueids, concatValue])
          
            check_lst.push(listVal.Id)
        
        }
        else {
            setCheckedValueids(checkedValueids.filter((e) => e !== (listVal.Id || ';' || listVal.Type)))
            check_lst = check_lst.filter((e) => e !== (listVal.Id || ';' || listVal.Type))
        }
        if (check_lst.length > 0) {
            setshowbutton(true);
            settotalselectedshow(true);
            settotalselectedcount(check_lst.length);
        }
        else {
            setshowbutton(false);
            settotalselectedshow(false);
        }

        let checkedBoxess = document.querySelectorAll('input[name=chkBox]:checked');
        if (checkedBoxess.length > 0) {
            settotalselectedshow(true);
            settotalselectedcount(checkedBoxess.length);
        } else {
            settotalselectedcount(0);
            settotalselectedshow(false);
        }
    }

    const getUserPermission = (pathname) => {
        Service.GetUserPermission(pathname).then((res) => {
            if (typeof res !== 'undefined') {

                if (res.data !== 'null' && typeof res.data !== 'undefined' && res.data !== '') {
                    if (typeof res.data.DataList !== 'undefined') {
                        setuserPermission(res.data.DataList)
                    }
                }
            }
        });
    }
    const UnlockHandler = (e) => {
      
        Service.PostWithIdAndUser("LockedDocuments/UnlockDocuments", checkedValueids, loggedInUser).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        alert(res.data.Status)
                        if (res.data.Status === 'Failure') {
                            setErrorStatus("Error")
                            setErrorMessage(res.data.ErrorMessage)
                            setShowErrorModal(true)
                        }
                        getLockedDocumentsData("LockedDocuments/GetAll", paging.pageSize, paging.pageNumber, null, null, []);
                    }
                }
            }

        });

    }

    const filterInput = (e) => {
        let inputValue = e.target.value
        setFilterValue(inputValue)
        setFilterValueDict(() => ({
            ...filterValueDict, [filterCol]: e.target.value,
        }));
    }

    const [divleft, setdivleft] = useState('');
    const [divtop, setdivtop] = useState('');

    const FilterColumn = (name) => {

        const scrollTop = window.scrollY;
        const scrollTopActual = scrollTop >= 60 ? scrollTop - 85 : 0;
        var divtop1 = document.getElementById("tableA");
        setdivtop(divtop1.offsetTop + scrollTopActual)

        setFilterCol(name)
        setshowfilterpopup(true)
        if (name === 'type') {
            setfiltertext(t('UserList.DocumentType'))
            var testDiv = document.getElementById("type");
            setdivleft(testDiv.offsetLeft + "px");
            DropdownHandler(name)
        }
        if (name === 'document_id') {
            setfiltertext(t('UserList.LockedDocumentID/Name'))
            var testDiv = document.getElementById("document_id");
            setdivleft(testDiv.offsetLeft + "px");
        }
        if (name === 'lockeduser') {
            setfiltertext(t('UserList.LockedBy'))
            var testDiv = document.getElementById("lockeduser");
            setdivleft(testDiv.offsetLeft + "px");
            DropdownHandler(name)
        }


        setshowfilterpopup(true)
    }


    const DropdownHandler = (key) => {
        Service.getfilterDropdownByGroup('LockedDocuments/GetDropDownFilterValue', key).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                      
                            addnewfield(res.data, key)
                        }
                    }
                }
            }

        });
    }
    const addnewfield = (data, key) => {
    
        let selectedKeys = multiFilterValue[key];
        let currentdata = data.map((i) => {
            i["ischecked"] = false;
            return i;
        });
        if (key != 'status') {
            if (selectedKeys != '') {
                currentdata.forEach((each) => {
                    selectedKeys.forEach((eachSelected) => {
                        if (each.Name == eachSelected.Name) {
                            each.ischecked = true
                        }
                    })
                })
            }
         
            setDropdownData(currentdata);
            setFilterDropdownData(currentdata)
        }
        else {
            if (selectedKeys != '') {
                currentdata.forEach((each) => {
                    selectedKeys.forEach((eachSelected) => {
                        if (each.Name == eachSelected.Name) {
                            each.ischecked = true
                        }
                    })
                })
                setDropdownData(currentdata)
                setFilterDropdownData(currentdata)
            }
            else {
                setDropdownData(currentdata)
                setFilterDropdownData(currentdata)
            }
        }
    }


    const checkAllFilter = (mycheckbox) => {
        var lastfilter = [...dropdownData]
        let data1 = []
        var checkboxes = document.querySelectorAll("input[name = 'filter-option-all']");
        if (mycheckbox.target.checked == true) {
          
            let currentdata = lastfilter.map((i) => {
                i["ischecked"] = true;
                return i;
            });
            setDropdownData(currentdata)
         
            dropdownData.map((each) => {
              
                const getallData = {
                    Name: each.Name,
                }
                data1.push(getallData)
            })
            setFilterValue(filterValue => [...filterValue, ...data1]);
            let updateddata = ([...filterValue, ...data1]);
            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: updateddata,
            }));
        }
        else {
            
            let currentdata = lastfilter.map((i) => {
                i["ischecked"] = false;
                return i;
            });
            setFilterValue([])
            setMultiFilterValue([])
        }

    }
    const OndropdownChange = (e, id, name) => {
        let isChecked = e.target.checked;
        var lastfilter = [...dropdownData]
        if (isChecked === true) {
            let currentdata = lastfilter.map((i) => {
                if (i.Id == id) {
                    i["ischecked"] = true;
                }
                return i;
            });
           
            setDropdownData(currentdata);
            let data1 = []
            const getallData = {
                Name: name,
            }
            data1.push(getallData)
            setFilterValue(filterValue => [...filterValue, ...data1]);
            let updateddata = ([...filterValue, ...data1]);
            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: updateddata,
            }));
        }
        else {
            let currentdata = lastfilter.map((i) => {
                if (i.Id === id) {
                    i["ischecked"] = false;
                }
                return i;
            });
           
            setDropdownData(currentdata);
            setFilterValue(filterValue => filterValue.filter(data => data.Name !== name))
            let data1 = multiFilterValue.filter(data => data.Name !== name);
            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: data1,
            }));
        }
    }
    const UserfilterHandler = (e) => {
     
        setFilterSearch(e.target.value)
        if (e.target.value !== "") {
            let searchResults = dropdownData.filter((each) => {
                return each.Name.toLowerCase().includes(String(e.target.value).toLowerCase())
            });
            setDropdownData(searchResults);
        }
        else {
    
            setFilterSearch("")
            setDropdownData(filterdropdownData)
        }
    }
    const filterHandler = (flag) => {
        if (flag === 'Apply') {
            setshowfilterpopup(false)
            let list = [];

            for (var key in multiFilterValue) {
                if (multiFilterValue[key].length > 0) {
                    let values = ""
                    multiFilterValue[key].map((i) => {
                        values += i.Name + ";";
                    })
                    if (values !== null && values !== '') {
                        list.push({
                            filterKey: key,
                            FilterValue: values
                        })
                    }
                }
            }

            for (var key in filterValueDict) {
                if (filterValueDict[key] !== null && filterValueDict[key] !== '') {
                    list.push({
                        filterKey: key,
                        FilterValue: filterValueDict[key]
                    })
                }
            }
            getLockedDocumentsData("LockedDocuments/GetAll", paging.pageSize, paging.pageNumber, null, null, list);
        }
        else {
            setshowfilterpopup(false)
            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: '',
            }));
            setFilterValueDict(() => ({
                ...filterValueDict, [filterCol]: '',
            }));

            let list = [];
            for (var key in multiFilterValue) {
                if (key !== filterCol && multiFilterValue[key].length > 0) {
                    let values = ""
                    multiFilterValue[key].map((i) => {
                        values += i.Name + ";";
                    })
                    if (values !== null && values !== '') {
                        list.push({
                            filterKey: key,
                            FilterValue: values
                        })
                    }
                }
            }

            for (var key in filterValueDict) {
                if (filterValueDict[key] !== null && filterValueDict[key] !== '' && key !== filterCol) {
                    list.push({
                        filterKey: key,
                        FilterValue: filterValueDict[key]
                    })
                }
            }
            getLockedDocumentsData("LockedDocuments/GetAll", paging.pageSize, paging.pageNumber, null, null, list);
            setFilterCol('')
            setFilterValue('')
            setDropdownData([])
            setFilterSearch("")
            setFilterDropdownData([])
        }
    }

    const SortColumn = (colName) => {
        var sortType = '';
        if (columnAscending === true) {
            setColumnAscending(false);
            sortType = "Descending";
        } else {
            setColumnAscending(true);
            sortType = "Ascending";
        }
        setSortColName(colName);
       
        let pagenumber = 1;
     
        paging.pageNumber = pagenumber;
        setpaging(paging);
        getLockedDocumentsData("LockedDocuments/GetAll", paging.pageSize, paging.pageNumber, sortType, colName, filterLst());
    }
    const LoadMore = (lst, totalcount) => {
   
        if (lst < totalcount) {
            setshowLoadMore(true);
        }
        else if (lst >= totalcount) {
            setshowLoadMore(false);
        }
        else {
            setshowLoadMore(false);
        }
    }

    const customMultiStyles = {
        multiselectContainer: {
            maxWidth: '188px',
            marginLeft: '3px'
        },
        chips: {
            borderRadius: '5px'
        }
    };
    let headers = [
        { label: "Locked Document Type", key: "type" },
        { label: "Locked Document ID/Name", key: "Document_id" },
        { label: "Locked By User", key: "LockedByUserName" },

    ];
    const filterLst = () => {
        let list = [];
        for (var key in multiFilterValue) {
            if (multiFilterValue[key].length > 0) {
                let values = ""
                multiFilterValue[key].map((i) => {
                    values += i.Name + ";";
                })
                if (values !== null && values !== '') {
                    list.push({
                        filterKey: key,
                        FilterValue: values
                    })
                }
            }
        }

        for (var key in filterValueDict) {
            if (filterValueDict[key] !== null && filterValueDict[key] !== '') {
                list.push({
                    filterKey: key,
                    FilterValue: filterValueDict[key]
                })
            }
        }
        return list;
    }
    const checkAll = (mycheckbox) => {
     
        var checkboxes = document.querySelectorAll("input[ name='chkBox']");
   
        const value = []
        if (mycheckbox.target.checked == true) {
            let check_lst = checkedValueids
            checkboxes.forEach(function (checkbox) {
                checkbox.checked = true;
                value.push(checkbox.id);

            });

            setshowbutton(true)
            settotalselectedshow(true);
            settotalselectedcount(checkboxes.length)
            setCheckedValueids([...checkedValueids, value])
            
        }
        else {
            checkboxes.forEach(function (checkbox) {
                checkbox.checked = false;
            });
            setshowbutton(false);
            settotalselectedshow(false);
            settotalselectedcount(0)
            setCheckedValueids([])
        }

    }
    return (
        <div className="container-fluid content-top-gap">


            <nav aria-label="breadcrumb">
                <ol className="breadcrumb my-breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">{t('Home.title')}</Link></li>
                    <li className="breadcrumb-item"><Link to="/admin">{t('Menu.Admin')}</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">{t('UserList.LockedDocuments')}</li>
                </ol>
            </nav>

            <div style={{ display: "none" }}>
                <CSVLink data={csvList} headers={headers} ref={csvInstance} filename="lockeddocuments.csv" > </CSVLink>
            </div>

            <div className="">
                <div className="row">
                    <div className="col-lg-12 pr-lg-2">
                        <div className="card text-center card_border overflow-auto">

                            <div className="card-body" style={{ minHeight: "70vh" }}>
                                <div className="row" style={{ position: "sticky" }}>

                                    <div className="col-md-7">
                                        <input type="text" className="form-control form-control-user" onChange={(event) => searchHandler(event)} id="firstName" autoComplete='off' name="firstName" placeholder={t('UserList.LockedHearedSearch')} />
                                    </div>
                                    <div className="col-md-5">
                                        {totalselectedcount <= 0 ? "" : Service.exportPerms(userPerms) == false ? "" : <button className="btn btn-update  btn-sm ml-2" type="submit" style={{ float: "right", width: "50px" }} onClick={(event) => ExporttoCSV(event)}>{t('Export')}</button>}
                                        {showbutton === true ?
                                            <>
                                                {Service.editPerms(userPerms) == false ? "" : <button className="btn btn-update btn-sm ml-2" style={{ float: "right", width: "50px" }} type="submit" onClick={UnlockHandler}>{t('Unlock')}</button>}
                                            </> : ''}
                                    </div>

                                </div>
                                <div className="scrollbar-class">
                                    <table className="table table-bordered text-left mt-2 table-responsive-sm" id="tableA">
                                        <thead className="text-white is-sticky-thead-product" style={{ backgroundColor: "var(--primary)" }}>
                                            <tr style={{ position: "sticky", top: "0", backgroundColor: "var(--primary)" }}>
                                                <th className="tableheader">
                                                    <input type="checkbox" onChange={checkAll} />
                                                </th>
                                                <th id="type" style={{ backgroundColor: (filterValueDict.type ? '#6cae55' : '') }}>
                                                    <div className='d-flex text-nowrap'>
                                                        <span>{t('UserList.DocumentType')}</span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('type')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('type')}></span>
                                                    </div>
                                                </th>
                                                <th id="document_id" style={{ backgroundColor: (filterValueDict.document_id ? '#6cae55' : '') }}>
                                                    <div className='d-flex text-nowrap'>
                                                        <span>{t('UserList.LockedDocumentID/Name')}</span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('Document_id')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('document_id')}></span>
                                                    </div>
                                                </th>
                                                <th id="lockeduser" style={{ backgroundColor: (multiFilterValue.lockeduser ? '#6cae55' : '') }}>
                                                    <div className='d-flex text-nowrap'>
                                                        <span>{t('UserList.LockedBy')}</span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('LockedByUserName')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('lockeduser')}></span>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                showspinner === true ?

                                                    <>
                                                        <Spinner animation="grow" size="sm" variant="success" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                        <Spinner animation="grow" size="sm" variant="danger" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                        <Spinner animation="grow" size="sm" variant="warning" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                    </>
                                                    :
                                                    filteredArray.length === 0 ?
                                                        <tr>

                                                            <td></td>
                                                            <td>
                                                                {t('NoDataAvailable')}
                                                            </td>
                                                            <td></td>
                                                        </tr>
                                                        :
                                                        filteredArray.map((listVal, index) =>
                                                            <tr key={listVal.Rebate_id}>
                                                                <td><input id={[listVal.Id + ";" + listVal.type + ";" + listVal.Document_id]} type="checkbox" name="chkBox" value={listVal.Rebate_id} onChange={(event) => handleChange(event, listVal)} /></td>
                                                                <td>{listVal.type}</td>
                                                                <td>{listVal.Document_id}</td>
                                                                <td>{listVal.LockedByUserName}</td>

                                                            </tr>
                                                        )

                                            }

                                        </tbody>
                                    </table>
                                </div>

                                {
                                    showLoadMore === true ?
                                        <div className='text-center container row'>
                                            <div className='col-md-5'></div>
                                            <div className='col-md-2'>
                                                <button className='btn btn-sm btn-update ml-1' onClick={handleLoadMore} style={{ width: "70px" }}>{t('LoadMore')}</button>
                                            </div>
                                            <div className='col-md-5'></div>

                                        </div>
                                        :
                                        ""
                                }
                                <div className='row'>
                                    {
                                        totalselectedshow === true ?

                                            <div className='col-lg-2 forgettext col-sm-12 border form-control' style={{ textAlign: "center", marginLeft: "0.938rem" }}>
                                                {t('TotalRecordsSelected')} - <span id="totalselected">{totalselectedcount}</span>
                                            </div>

                                            :
                                            <div className='col-lg-2  col-sm-12' style={{ textAlign: "center" }}>

                                            </div>
                                    }



                                    <div className='col-lg-7 col-sm-12'></div>


                                    <div className='col-lg-2 forgettext  col-sm-12 border form-control' style={{ textAlign: "center" }}>
                                        {t('TotalRecords')} - {totalCountlst}
                                    </div>
                                </div>
                            </div>

                            {showfilterpopup === true ?
                                <div className='pop-up' style={{ left: divleft, top: divtop }}>
                                   
                                    {filterCol === 'type' || filterCol === 'document_id' ?
                                        <>
                                            <div className="container">
                                                <div className="row">
                                                    <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>
                                                </div>
                                            </div>
                                            <div className="container mt-2">
                                                <div className="row">
                                                    <input type='textbox' placeholder={t('Search')} className='filtertextboxbackground col-md-11 textbox-padding ml-2' style={{ outline: 'none' }} value={filterValueDict[filterCol]} onChange={(event) => filterInput(event)}></input>
                                                </div>
                                            </div>
                                        </>

                                        :
                                        <>
                                        </>

                                    }


                                    {filterCol === 'lockeduser' ?
                                        
                                        <>
                                            <div className="container">
                                                <div className="row">
                                                    <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>

                                                </div>

                                            </div>

                                            <div className="container mt-2">
                                                <div className="row">
                                                    <input type="text" placeholder={t('Search')} value={filterSearch} onChange={(event) => UserfilterHandler(event)} className='filtertextboxbackground col-md-11 textbox-padding ml-2' />
                                                </div>

                                                <div className="row contentoverflow mt-2">
                                                    <table className="filtertable">
                                                        <tbody>
                                                            <tr>
                                                                <td><input type="checkbox" name="filter-option-all" id="option-all" onChange={checkAllFilter} /></td>
                                                                <td>{t('SelectAll')}</td>
                                                            </tr>
                                                            {
                                                                dropdownData.map((item, index) => (

                                                                    <tr key={index}>

                                                                        <td><input id={item.Id} data-index="1" name="filter-option-all" checked={item.ischecked} type="checkbox" onChange={(event) => OndropdownChange(event, item.Id, item.Name)} /></td>

                                                                        <td>{item.Name}</td>

                                                                    </tr>


                                                                ))
                                                            }

                                                        </tbody>
                                                    </table>

                                                </div>
                                            </div>
                                        </>
                                        :
                                        <></>
                                    }
                                    <div style={{ paddingTop: '10px', paddingLeft: '47px', paddingBottom: '7px' }}>
                                        <button className="btn btn-sm btn-update" onClick={() => filterHandler('Apply')} style={{ width: "45px" }}>{t('Apply')}</button>
                                        <button className="btn btn-sm btn-update" style={{ marginLeft: '10px', width: "45px" }} onClick={() => filterHandler('Reset')}>{t('Reset')}</button>
                                    </div>
                                    <div>
                                    </div>
                                </div>
                                :
                                <></>
                            }

                        </div>
                        <CustomErrorPopup ErrorMessage={errorMessage} show={showErrorModal} ErrorStatus={errorStatus}
                            onHide={() => setShowErrorModal(false)}></CustomErrorPopup>
                    </div>

                </div>
            </div>

        </div >


    )
}
export default LockedDocumentsComponent;

