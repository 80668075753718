import { Link, useLocation, useNavigate } from "react-router-dom"
import React, { useState, useEffect } from 'react'
import classes from '../../RebateProgramComponent/CreateRebateComponent/Form.module.css'
import { Form, FormGroup, Col, Row, Button, InputGroup, Collapse, Modal } from 'react-bootstrap';
import Service from '../../../Services/Service';
import 'react-multiple-select-dropdown-lite/dist/index.css'
import CustomErrorPopup from '../../../UI/CustomErrorPopup';
import Multiselect from 'multiselect-react-dropdown';
import $ from 'jquery';
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment'
import SessionManage from '../../../SessionManagement/SessionManage';
import Spinner from 'react-bootstrap/Spinner'
import DatePicker from "react-datepicker";
import ProductTreeDialog from '../../RebateProgramComponent/CreateRebateComponent/ProductTreeDialog';
import Validation from '../../../Services/Validation';



function NewExceptionPolicy(props) {
  const navigate = useNavigate();
  let loggedInUser = sessionStorage.getItem("id")
  const [t, i18n] = useTranslation('translation');
  const [userPerms, setuserPermission] = useState([]);
  const [changeHandle, setChangeHandle] = useState(false);
  const [showAccruals, setshowAccruals] = useState(false);
  const [showtargetselection, setShowTargetSelection] = useState('');
  const [showExceptionTypeListselection, setshowExceptionTypeListselection] = useState('');
  const [subdivideList, setsubdivideList] = useState({ 'Period': "", 'Operator': "", Value: "" })
  const [fyThresholdList, setfyThresholdList] = useState({ 'ThresholdValue': "", 'PayoutValue': "", Operator: "" })
  const [showtargettable, setShowTargetTable] = useState(true);
  const [subdivideTargetTypeList, setSubdivideTargetTypeList] = useState([]);
  const [subdivideTargetid, setSubdivideTargetid] = useState();
  const [ExceptionTypeid, setExceptionTypeid] = useState();
  const [addCategory, setaddCategory] = useState(false);
  const [CategoryValues, setCategoryValues] = useState([]);
  const [SubCategoryValues, setSubCategoryValues] = useState([]);
  const [ProductValues, setProductValues] = useState([]);
  const [CustomerValues, setCustomerValues] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const [CustomerGroupValues, setCustomerGroupValues] = useState([]);
  const [attribute1Values, setAttribute1Values] = useState([]);
  const [attribute2Values, setAttribute2Values] = useState([]);
  const [attribute3Values, setAttribute3Values] = useState([]);
  const [attribute4Values, setAttribute4Values] = useState([]);
  const [attribute5Values, setAttribute5Values] = useState([]);
  const [UpdatedCategoryValues, setUpdatedCategoryValues] = useState([]);
  const [showWorkflowAlert, setShowWorkflowAlert] = useState(false)

  const [attribute1ValuesHold, setAttribute1ValuesHold] = useState([]);
  const [attribute2ValuesHold, setAttribute2ValuesHold] = useState([]);
  const [attribute3ValuesHold, setAttribute3ValuesHold] = useState([]);
  const [attribute4ValuesHold, setAttribute4ValuesHold] = useState([]);
  const [attribute5ValuesHold, setAttribute5ValuesHold] = useState([]);

  const [settlFreqValues, setSettlFreqValues] = useState([]);
  const [rebateTypValues, setRebateTypeValues] = useState([]);
  const [SCDropDownValues, setSCDropDownValues] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [selectedValues, setSelectedValues] = useState("");
  const [showProductPopup, setShowProductPopup] = useState("");
  const [showCategoryPopup, setshowCategoryPopup] = useState("");
  const [showCustomerPopup, setshowCustomerPopup] = useState("");
  const [showCustomerGroupPopup, setshowCustomerGroupPopup] = useState("");
  const [showSetlFreqPopup, setshowSetlFreqPopup] = useState("");
  const [showRebateTypePopup, setshowRebateTypePopup] = useState("");
  const [sidebarwidth, setsidebarwidth] = useState('');
  const [clickProduct, setclickProduct] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [dualListBoxOptionsByHeirarchy, setdualListBoxOptionsByHeirarchy] = useState([])
  const [dualListBoxOptionsByAttribute, setdualListBoxOptionsByAttribute] = useState([])
  const [PrevSelectedTypeState, setPrevSelectedTypeState] = useState('Heirarchy');
  const [selectedType, setSelectedType] = useState('Attribute');
  const [rebatelinestatus, setrebatelinestatus] = useState('New');
  const [FilteredProducts, SetFilteredProducts] = useState([]);
  const [attbCheckAll, setattbCheckAll] = useState(false);
  const [keyValue, SetKeyValue] = useState("");
  const [itemNo, setItemNo] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorStatus, setErrorStatus] = useState("Error");
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [datagroupCust, setDataGroupCust] = useState([]);
  const [commentsForSubmit, setCommentsForSubmit] = useState("")
  const [showSubmitModal, setShowSubmitModal] = useState(false)
  const [loading, setLoading] = useState(false);
  let disableField = false;
  let catValues = [];
  const [dropDownValues, setDropDownValues] = useState([{
    id: 0,
    Category: [],
    SubCategory: [],
  }])
  const [ExceptionValueList, setExceptionValueList] = useState([{
    id: 0,
    Category: "",
    SubCategory: [],
    Operator: "",
    Value: "",
    Condition: "",
    dropDownValues: [],
    ValueListString: [],
    ExceptionDataId: 0,
    ExceptionDataCategoryLabel: "",
    ExceptionDataSubCategoryLabel: "",
    SelectedItemSubCategory: "",
    ValueString: "",
    StatusLabel: ""
  },]);
  const [exceptionValues, setExceptionValues] = useState([])
  let initial_value = [
    { id: 0, Category: "", Operator: "", Value: "", Condition: "", dropDownValues: [], ValueListString: [], SelectedItemSubCategory: "", SubCategory: [] },
  ]
  let initExceptionValues = [
    { id: 0, Operator: "", ValueListString: "", Condition: "", ExceptionDataId: 0 },
  ]
  const [attbSearch, SetAttbSearch] = useState({
    "Category1": [],
    "Category2": [],
    "Category3": [],
    "Category4": [],
    "Category5": [],
  })
  const [paging, setpaging] = useState({
    pageSize: 20,
    pageNumber: 1
  })
  const [exceptionPolicyData, setExceptionPolicyData] = useState({
    Id: 0,
    CreatedUserId: parseInt(loggedInUser),
    LastModifiedUserId: parseInt(loggedInUser),
    ExceptionPolicyName: "",
    ExceptionTypeId: 0,
    SubtiertargettypeId: 0,
    RebateFor: "",
    exceptionPoliciesValues: [],
    CreatedUser: [],
    subdivideMinThresholdValue: [],
    fYThresholdValue: [],
    SubDivideTargetType: "",
  });
  let disabled = false;

  const [showExceptionWorkflow, setshowExceptionWorkflow] = useState('');
  const [showWorkflow, setshowWorkflow] = useState('');
  const [workflowchart, setworkflowchart] = useState([]);
  const [showspinnerworkflow, setshowspinnerworkflow] = useState('');
  const [workflowData, setWorkflowData] = useState([]);
  const [woData, setWoData] = useState('');
  const [totalAccrualMoney, setTotalAccrualMoney] = useState(0);
  const [showspinneraccural, setshowspinneraccural] = useState('');
  const [activepolicyProgramLine, setActivePolicyProgramLine] = useState([])
  const [expandAccurals, setExpandAccruals] = useState('')
  const [icon, setIcon] = useState('fa fa-plus');
  const [loadedFrom, setLoadedFrom] = useState('');
  const [checkedValue, setCheckedValue] = useState([])
  const [state, setState] = useState({})
  const clickCloseAccuralWorkflow = () => {
    setshowExceptionWorkflow(false);
  }
  const [workflowApprovers, setWorkflowApprovers] = useState([])
  const [workflowLevelCount, setWorkflowLevelCount] = useState('');
  let wData = [0]
  const [rebateprogrmlinedetail, setrebateProgramLinedetail] = useState({ id: 0 })
  var currentDate = new Date();
  var year = currentDate.getFullYear();
  var month = currentDate.getMonth();
  var day = currentDate.getDate();
  const [defaultDate, setDefaultDate] = useState({ from_date: new Date(), to_date: (new Date(year + 1, month, day)) })
  const [saveProgramLine, setSaveProgramLine] = useState(false);

  const [customerDataFilter, setCustomerDataFilter] = useState([]);
  const [customerSearch, setCustomerSearch] = useState('');
  const [data, setData] = useState([]);
  const [filtertextCust, setfiltertextCust] = useState('');
  const [custFilterCol, setCustomerFilterCol] = useState('');
  const [showCustomerfilterpopup, setshowCustomerfilterpopup] = useState(false);
  const [divleft, setdivleft] = useState('');
  const [divtop, setdivtop] = useState('');
  const [filterCusDropDownData, setFilterCusDropDownData] = useState([]);
  const [filterValueCust, setFilterValueCust] = useState([]);
  const [filterValue, setFilterValue] = useState([]);
  const [customercheckAll, setCustomercheckAll] = useState(false);
  const [totalselectedshow, settotalselectedshow] = useState('');
  const [customerChanged, setCustomerChanged] = useState(false);
  const disabledClass = `  ${Service.editPerms(userPerms) == false ? "disabledClass" : ""}`;
  const [filterSearchCust, setFilterSearchCust] = useState("");
  const [filtercustData, setfilterCustData] = useState([]);
  const [customerGroupSearch, setCustomerGroupSearch] = useState('');
  const [showCustomerGroupfilterpopup, setshowCustomerGroupfilterpopup] = useState(false);
  const [custGroupFilterCol, setCustomerGroupFilterCol] = useState('');
  const [filterSearchCustGroup, setFilterSearchCustGroup] = useState("");
  const [filtertextCustGroup, setfiltertextCustGroup] = useState('');
  const [currentCustGroupFilterKey, setcurrentCustGroupFilterKey] = useState('');
  const [filtercustGroupData, setfilterCustGroupData] = useState([]);
  const [filterValueCustGroup, setFilterValueCustGroup] = useState([]);
  const [columnAscending, setColumnAscending] = useState(true);
  const [sortColName, setSortColName] = useState('Id');
  const [searchValue, setSearchValue] = useState("")
  const [filterSearch, setFilterSearch] = useState("");
  const [settlFreqcheckAll, setsettlFreqcheckAll] = useState(false);
  const [RebateTypecheckAll, setRebateTypecheckAll] = useState(false);
  const [filterCustGroupDropDownData, setFilterCustGroupDropDownData] = useState([]);
  const [customerGroupDataFilter, setCustomerGroupDataFilter] = useState([]);
  const [filterValueGroupCustomer, setFilterValuegroupCustomer] = useState({
    label: '',
  });
  const [filterValueCustomer, setFilterValueCustomer] = useState({
    Name: '',
    Label: '',
    Station: '',
    State: '',
    Zone: '',

  });

  const [showSelectedCustomerpopup, setShowSelectedCustomerpopup] = useState(false);
  const [showSelectedCustomerGrouppopup, setShowSelectedCustomerGrouppopup] = useState(false);
  const [showSelectedProductpopup, setShowSelectedProductpopup] = useState(false);
  const [showSelectedCategorypopup, setShowSelectedCategorypopup] = useState(false);
  const [showSelectedRebateType, setShowSelectedRebateType] = useState(false);
  const [showSelectedSettlFreq, setShowSelectedSettlFreq] = useState(false);
  const [countKeyValue, SetCountKeyValue] = useState("");
  const [SettlFreqDataSelected, setSettlFreqDataSelected] = useState([])
  const [RebateTypeDataSelected, setRebateTypeDataSelected] = useState([])
  const [customerDataSelected, setCustomerDataSelected] = useState([]);
  const [customerGroupDataSelected, setCustomerGroupDataSelected] = useState([]);
  const [showApprovedModal, setShowApprovedModal] = useState(false)
  const [commentsForApproved, setCommentsForApproved] = useState("")
  const [showDeniedModal, setShowDeniedModal] = useState(false)
  const [commentsForDenied, setCommentsForDenied] = useState("")
  const [showAddButton, setShowAddButton] = useState(false)
  const [isLocked, setIsLocked] = useState(false);
  const [lockedBy, setLockedBy] = useState('');
  const [modifiedLst, setModifiedLst] = useState([])
  {
    useEffect(() => {
      setLoadedFrom(props.name);
      getUserPermission("/ExceptionPolicylist")
      ExceptionValueList.length = 0
      
      GetSubdivideTargetType();
      GetExceptionType();
      GetProductPopUpValues('rebate/ProductSelectorByAttributeNew');
      GetProductAtrribute1("CategoryException/GetAttributeValue")
      GetProductAtrribute2("CategoryException/GetAttributeValue")
      GetProductAtrribute3("CategoryException/GetAttributeValue")
      GetProductAtrribute4("CategoryException/GetAttributeValue")
      GetProductAtrribute5("CategoryException/GetAttributeValue")
      GetCustomerData("Customer/GetIndividualCustomersWithoutGroup")
      GetSettlFreqData("settlementfrequency")
      GetRebateTypeData("RebateType/GetAll")
      GetGroupCustomerData("CustomerGroups/GetAll", paging.pageSize, paging.pageNumber, "Descending", "Id", [], null)
      if (props.id !== '0') {
   
        getExceptionPolicyById("ExceptionPolicies/GetById", props.id)
      }
      else {
        setLockedBy(loggedInUser);
        setIsLocked(true);
        GetExceptionCategorySubCategoryValues("ExceptionPolicies/GetDropdownValues");
      }
     
    }, [props.id])
  }



  const [ExceptionTypeList, setExceptionTypeList] = useState([]);
  const GetSubdivideTargetType = () => {
    Service.getdata("Rebate/GetSubdivideTargetType").then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          setSubdivideTargetTypeList(res.data);
        }
      }
    });
  }

  const GetExceptionType = () => {
    Service.getdata("ExceptionPolicy/GetAllExceptionType").then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
 
          setExceptionTypeList(res.data);
        }
      }
    });
  }
  const GetExceptionCategorySubCategoryValues = (method) => {
    Service.getdata(method).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
   
          res.data.DataList.map((each) => {
            each.SubCategory.map((eachsub) => {
              eachsub["dropdownState"] = {
                'selected': false,
                'row': -1
              }
            })
          })
        
          setDropDownValues(res.data.DataList);
      
          let CatData = [];
          let SubCatData = [];
          res.data.DataList.map((i) => {
            const data = {
              id: i.Id,
              Id: i.Id,
              Name: i.Category,
              label: i.Category,
              value: i.Category,
            }
           
            CatData.push(data);
            i.SubCategory.map((s) => {
              const subdata = {
                id: s.Id,
                Id: s.value,
                Name: s.label,
                label: s.label,
                value: s.value,
              }
              SubCatData.push(subdata);
            })
         
          });

          setCategoryValues(CatData);
          setSubCategoryValues(SubCatData);
        
          setUpdatedCategoryValues(list => ([...CatData]));
          catValues = CatData;
      
          setModifiedLst(res.data.DataList)
        }
      }
    });
  }

  const GetProductPopUpValues = (programId) => {

    Service.getdataWithPagingandattributeSearchingByGroup('rebate/ProductSelectorByAttributeNew', '', 200000, 1, 0, [], 0).then((res) => {
      if (typeof res !== 'undefined' && typeof res.data !== 'undefined') {
      
        setProductValues(ProductValues => ([...ProductValues, ...res.data.DataList]));
      }
    });
  };

  const GetCustomerData = (method) => {
    
    Service.getdata(method).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {
            if (res.data !== 'null' && res.data !== 'undefined') {
              
              setCustomerValues(res.data)

              setCustomerData(res.data)
            }
          }
        }
      }

    });
  }
  const GetGroupCustomerData = (method, pageSize, pageNumber, sortType, SortProperty, filterValue, search) => {
    
    Service.getdataWithPagingByGroupSearch(method, pageSize, pageNumber, sortType, SortProperty, filterValue, search).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {
            if (res.data !== 'null' && res.data !== 'undefined') {
           
              setCustomerGroupValues(res.data.DataList)
         
            }
          }
        }
      }

    });
  }
  const GetProductAtrribute1 = (method) => {
    let key = "Cat-I"
    Service.getAttributeValue(method, key).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {
            if (res.data !== 'null' && res.data !== 'undefined') {
           
              setAttribute1Values(res.data)
              setAttribute1ValuesHold(res.data)
              SetAttbSearch({ ...attbSearch, ['Category1']: res.data })
           
            }
          }
        }
      }

    });
  }
  const GetProductAtrribute2 = (method) => {
    let key = "Cat-II"
    Service.getAttributeValue(method, key).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {
            if (res.data !== 'null' && res.data !== 'undefined') {
            
              setAttribute2Values(res.data)
              setAttribute2ValuesHold(res.data)
              SetAttbSearch({ ...attbSearch, ['Category2']: res.data })
            
            }
          }
        }
      }

    });
  }
  const GetProductAtrribute3 = (method) => {
    let key = "Cat-III"
    Service.getAttributeValue(method, key).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {
            if (res.data !== 'null' && res.data !== 'undefined') {
        
              setAttribute3Values(res.data)
              setAttribute3ValuesHold(res.data)
              SetAttbSearch({ ...attbSearch, ['Category3']: res.data })
            }
          }
        }
      }

    });
  }
  const GetProductAtrribute4 = (method) => {
    let key = "Cat-IV"
    Service.getAttributeValue(method, key).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {
            if (res.data !== 'null' && res.data !== 'undefined') {
            
              setAttribute4Values(res.data)
              setAttribute4ValuesHold(res.data)
              SetAttbSearch({ ...attbSearch, ['Category4']: res.data })
            }
          }
        }
      }

    });
  }
  const GetProductAtrribute5 = (method) => {
    let key = "Cat-V"
    Service.getAttributeValue(method, key).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {
            if (res.data !== 'null' && res.data !== 'undefined') {
           
              setAttribute5Values(res.data)
              setAttribute5ValuesHold(res.data)
              SetAttbSearch({ ...attbSearch, ['Category5']: res.data })
            }
          }
        }
      }

    });
  }
  const GetSettlFreqData = (method) => {
    Service.getAlldata(method).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {
            if (res.data !== 'null' && res.data !== 'undefined') {
              res.data.forEach((each) => {
                each['Selected'] = false;
              })
              setSettlFreqValues(res.data)
            }
          }
        }
      }

    });
  }

  const GetRebateTypeData = (method) => {
    Service.getdata(method).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {
            if (res.data !== 'null' && res.data !== 'undefined') {
              res.data.forEach((each) => {
                each['Selected'] = false;
              })
              setRebateTypeValues(res.data)
            }
          }
        }
      }

    });
  }


  const onChangeTargetSelection = (e) => {

    setShowTargetSelection(e.name)

    setSubdivideTargetid(e.value);


    if (e.value === "Select") {
      setShowTargetTable(false)
    }
    else {
      setShowTargetTable(true)
      setShowTargetSelection(e.name)
    }
    setExceptionPolicyData({ ...exceptionPolicyData, ['SubTierTargetTypeId']: e.value, ['SubDivideTargetType']: e.name });
  }

  const [subdividefield, setSubdividefield] = useState(false);
  const [FYThresholdfield, setFYThresholdfield] = useState(false);

  const onChangeExceptionTypeSelection = (e) => {
    setshowExceptionTypeListselection(e.Name)
    if (ExceptionValueList.length != -1) {
      let data = []
      setExceptionValueList(data);
    }
    if (e.Name == 'SubDivideThreshold') {
      setSubdividefield(true)
      setShowAddButton(true)
      setShowTargetTable(false)
      setaddCategory(false)
      UpdateCategoryValuesFromType();
      setsubdivideList({ 'Period': "", 'Operator': "", Value: "" });
      setExceptionValueList([])
    }
    else {
      setSubdividefield(false)
      setShowTargetSelection('')
    }
    if (e.Name == 'FYThreshold') {
      setFYThresholdfield(true)
      setShowAddButton(true)
      setShowTargetTable(false)
      setaddCategory(false)
      UpdateCategoryValuesFromType();
      setfyThresholdList({ 'ThresholdValue': "", 'PayoutValue': "", Operator: "" });
      setExceptionValueList([])

    }
    else {
      setFYThresholdfield(false)
    }
    if (e.Name == 'AccrualException') {
      setExceptionValues(initExceptionValues);
      setShowAddButton(false)
   
      let defaultValue = []
      dropDownValues.forEach((each) => {
        if ((each.Category).toLowerCase() == 'product') {
          defaultValue.push({
            id: 0,
            Category: each.Category,
            Operator: "",
            Value: "",
            Condition: "",
            dropDownValues: [],
            ValueListString: [],
            SelectedItemSubCategory: '',
            SubCategory: each.SubCategory
          })
        }
      })
      setExceptionValueList(defaultValue);
      setCategoryValues(UpdatedCategoryValues);
      setsubdivideList({ 'Period': "", 'Operator': "", Value: "" });
      setfyThresholdList({ 'ThresholdValue': "", 'PayoutValue': "", Operator: "" });
      
    }
    setExceptionPolicyData({ ...exceptionPolicyData, ['ExceptionTypeId']: e.Id, ['ExceptionTypeName']: e.Name })
  }


  const add = (flag) => {
    setExceptionValues(initExceptionValues);
    if (ExceptionValueList.length == 0) {
      setExceptionValueList(initial_value)
    }
    setaddCategory(true);

  }


  const AddVolume = (index) => {

    let volCheck = false

    if (ExceptionValueList.length >= 0) {
      ExceptionValueList.forEach((each, index) => {
        let idx = parseInt(ExceptionValueList.indexOf(each)) + 1

        if (each.Category == '') {
          alert("Please Select Category in Row " + idx)
          volCheck = true
        }
        else if (each.SelectedItemSubCategory == '') {
          alert("Please Select SubCategory in Row " + idx)
          volCheck = true
        }
        else if (each.Operator == '') {
          alert("Please Select Operator in Row " + idx)
          volCheck = true
        }
        else if (each.ValueListString.length == 0) {
          alert("Please Select Value in Row " + idx)
          volCheck = true
        }
      })
    }
    if (volCheck == false) {
      const _item = {
        id: 0,
        Category: "",
        Operator: "",
        Value: "",
        Condition: "",
        SubCategory: [],
        ValueListString: [],
        SelectedItemSubCategory: '',
      }
      const item2 = {
        id: 0,
        ExceptionDataId: 0,
        Operator: "",
        ValueListString: [],
        Condition: "",
      }
      setExceptionValueList([...ExceptionValueList, _item]);
      setExceptionValues([...exceptionValues, item2])
    }
  }

  const RemoveVolume = (idx) => {
    let list = [...ExceptionValueList]

    list[idx].SubCategory.map((each) => {
      if (each.dropdownState.selected == true && each.dropdownState.row - 1 == idx) {
        each.dropdownState = {
          selected: false, row: idx + 1
        }
        list[idx]['ValueListString'] = [];
        list[idx]['Operator'] = "";
        if (list[idx].SelectedItemSubCategory.toLowerCase() == 'label') {
          SetFilteredProducts([])
        }
        else if (list[idx].SelectedItemSubCategory.toLowerCase() == 'category1') {
          attribute1Values.map((each) => { each.Selected = false })
        }
        else if (list[idx].SelectedItemSubCategory.toLowerCase() == 'category2') {
          attribute2Values.map((each) => { each.Selected = false })
        }
        else if (list[idx].SelectedItemSubCategory.toLowerCase() == 'category3') {
          attribute3Values.map((each) => { each.Selected = false })
        }
        else if (list[idx].SelectedItemSubCategory.toLowerCase() == 'category4') {
          attribute1Values.map((each) => { each.Selected = false })
        }
        else if (list[idx].SelectedItemSubCategory.toLowerCase() == 'category5') {
          attribute1Values.map((each) => { each.Selected = false })
        }
        else if (list[idx].SelectedItemSubCategory.toLowerCase() == 'individual customer') {
          CustomerValues.map((each) => { each.Selected = false })
        }
        else if (list[idx].SelectedItemSubCategory.toLowerCase() == 'customer group') {
          CustomerGroupValues.map((each) => { each.Selected = false })
        }
        else if (list[idx].SelectedItemSubCategory.toLowerCase() == 'rebate type') {
          rebateTypValues.map((each) => { each.Selected = false })
        }
        list[idx]['SelectedItemSubCategory'] = "";
      }
    })

    setExceptionValueList(list);
    setExceptionValueList(ExceptionValueList.filter((item, index) => index !== idx));
    setExceptionValues(exceptionValues.filter((item, index) => index !== idx));
    
    setChangeHandle(true)
  }

  const OnCategoryChange = (data, index) => {
    const list = [...ExceptionValueList];
 

    list[index].SubCategory.map((each) => {
      if (each.dropdownState.selected == true && each.dropdownState.row - 1 == index) {
        each.dropdownState = {
          selected: false, row: index + 1
        }
        list[index]['SelectedItemSubCategory'] = '';
        list[index]['ExceptionDataId'] = '';
        list[index]['ValueListString'] = [];
        list[index]['Operator'] = "";
        if (list[index].SelectedItemSubCategory.toLowerCase() == 'label') {
          SetFilteredProducts([])
        }
        else if (list[index].SelectedItemSubCategory.toLowerCase() == 'category1') {
          attribute1Values.map((each) => { each.Selected = false })
        }
        else if (list[index].SelectedItemSubCategory.toLowerCase() == 'category2') {
          attribute2Values.map((each) => { each.Selected = false })
        }
        else if (list[index].SelectedItemSubCategory.toLowerCase() == 'category3') {
          attribute3Values.map((each) => { each.Selected = false })
        }
        else if (list[index].SelectedItemSubCategory.toLowerCase() == 'category4') {
          attribute1Values.map((each) => { each.Selected = false })
        }
        else if (list[index].SelectedItemSubCategory.toLowerCase() == 'category5') {
          attribute1Values.map((each) => { each.Selected = false })
        }
        else if (list[index].SelectedItemSubCategory.toLowerCase() == 'individual customer') {
          CustomerValues.map((each) => { each.Selected = false })
        }
        else if (list[index].SelectedItemSubCategory.toLowerCase() == 'customer group') {
          CustomerGroupValues.map((each) => { each.Selected = false })
        }
        else if (list[index].SelectedItemSubCategory.toLowerCase() == 'rebate type') {
          rebateTypValues.map((each) => { each.Selected = false })
        }
        list[index]['SelectedItemSubCategory'] = "";
      }
    })

    if (data.Id !== 0) {
    
      list[index]['Category'] = data.Name;


      let SubCatData = [];
      dropDownValues.map((i) => {
 
        if (i.Category == data.label) {
          list[index]['SubCategory'] = i.SubCategory;
          
        }
      });
      var test = []
    }

    setExceptionValueList(list);
  }



  const OnSubCategoryChange = (data, index) => {
    const list = [...ExceptionValueList];
    const list2 = [...exceptionValues];
 
    let currentCategory = list[index]['Category'];

    list[index].SubCategory.map((each) => {
      if (each.dropdownState.selected == true && each.dropdownState.row - 1 == index) {
        each.dropdownState = {
          selected: false, row: index + 1
        }
        list[index]['ValueListString'] = [];
        list[index]['Operator'] = "";
        if (list[index].SelectedItemSubCategory.toLowerCase() == 'label') {
          SetFilteredProducts([])
        }
        else if (list[index].SelectedItemSubCategory.toLowerCase() == 'category1') {
          attribute1Values.map((each) => { each.Selected = false })
        }
        else if (list[index].SelectedItemSubCategory.toLowerCase() == 'category2') {
          attribute2Values.map((each) => { each.Selected = false })
        }
        else if (list[index].SelectedItemSubCategory.toLowerCase() == 'category3') {
          attribute3Values.map((each) => { each.Selected = false })
        }
        else if (list[index].SelectedItemSubCategory.toLowerCase() == 'category4') {
          attribute1Values.map((each) => { each.Selected = false })
        }
        else if (list[index].SelectedItemSubCategory.toLowerCase() == 'category5') {
          attribute1Values.map((each) => { each.Selected = false })
        }
        else if (list[index].SelectedItemSubCategory.toLowerCase() == 'individual customer') {
          CustomerValues.map((each) => { each.Selected = false })
        }
        else if (list[index].SelectedItemSubCategory.toLowerCase() == 'customer group') {
          CustomerGroupValues.map((each) => { each.Selected = false })
        }
        else if (list[index].SelectedItemSubCategory.toLowerCase() == 'rebate type') {
          rebateTypValues.map((each) => { each.Selected = false })
        }
        list[index]['SelectedItemSubCategory'] = "";
      }

      if (each.label == data.label) {
 
        each.dropdownState = {
          selected: true, row: index + 1
        }
      }
    })
    if (data.Id !== 0) {
     
      list[index]['SelectedItemSubCategory'] = data.label;
      list[index]['ExceptionDataId'] = data.value;
      list2[index]['ExceptionDataId'] = data.value;
    }
    setExceptionValueList(list);
    setExceptionValues(list2);
    if (currentCategory.label == 'Product') {
      if (data.label == 'Label') {
 
        setSelectedSubCategory("Product");
      }
      else if (data.label == 'Category1') {

        setSelectedSubCategory("Category1");
      }
      else if (data.label == 'Category2') {

        setSelectedSubCategory("Category2");
      }
      else if (data.label == 'Category3') {

        setSelectedSubCategory("Category3");
      }
      else if (data.label == 'Category4') {

        setSelectedSubCategory("Category4");
      }
      else if (data.label == 'Category5') {

        setSelectedSubCategory("Category5");
      }
    }

  }

  const OnOperatorChange = (data, index) => {
    const list = [...ExceptionValueList];
    const list2 = [...exceptionValues];
  
    list[index]['Operator'] = data;
    list2[index]['Operator'] = data;
    setExceptionValueList(list);
    setExceptionValues(list2);
   
  }

 
  const UpdateProducts = (_SelectedProducts) => {

    setChangeHandle(true)
 
    setPrevSelectedTypeState(selectedType);

    let ids = []
    SetFilteredProducts(_SelectedProducts)
    setShowProductPopup(false);

    _SelectedProducts.forEach((each) => {
      const pieces = each.split("_")
      const last = pieces[pieces.length - 1]
      ids.push(last)
    })
    const list = [...ExceptionValueList];
    const list2 = [...exceptionValues];
   
    list[itemNo]['ValueListString'] = ids;
    list2[itemNo]['ValueListString'] = ids;
    setExceptionValueList(list);
    setExceptionValues(list2);
  }

  const SelectedProductTypeHandler = (event) => {
    setChangeHandle(true)
    setSelectedType(event.target.value);

    if (event.target.value === 'Heirarchy') {
      setPrevSelectedTypeState("Attribute");
    } else {
      setPrevSelectedTypeState("Heirarchy");
    }
  }

  const CloseProductPicker = () => {

 
  }


  const ClickValueCountPopup = (item, index) => {
    if (item.SelectedItemSubCategory != null && item.SelectedItemSubCategory != '' && item.SelectedItemSubCategory != undefined) {
      if ((item.SelectedItemSubCategory).toLowerCase() == ('Label').toLowerCase() && (item.Category).toLowerCase() == ('Product').toLowerCase()) {
        let selectedValue = item.ValueListString;
        let selectedData = []
        let productData = [...ProductValues]
        let filteredData = []
        if (selectedValue != null && selectedValue.length > 0) {
          selectedValue.forEach((selectedpro, index) => {
            ProductValues.forEach((eachpro) => {
              if (selectedpro == eachpro.Id) {
                eachpro.Selected = true
                let z = `${eachpro.Label}${"_"}${eachpro.Id}`
                filteredData.push(z)
              }
            })
          })
        }
        setdualListBoxOptionsByAttribute(productData)
        setShowSelectedProductpopup(true);
        setsidebarwidth("70vw")
      }
      else if ((item.SelectedItemSubCategory).toLowerCase() == ('Category1').toLowerCase()) {
   
        let selectedValue = item.ValueListString;
        let selectedCat1 = [];

        if (selectedValue && selectedValue.length > 0) {
          for (let item of attribute1Values) {
            if (selectedValue.includes(item.Id.toString())) {
              item.Selected = true;
            }
            else {
              item.Selected = false;
            }
          }
        }
        setShowSelectedCategorypopup(true)
        setsidebarwidth("60vw");
        SetCountKeyValue(item.SelectedItemSubCategory)
      }
      else if ((item.SelectedItemSubCategory).toLowerCase() == ('Category2').toLowerCase()) {
        let selectedValue = item.ValueListString;
        let selectedCat1 = [];
      
        if (selectedValue && selectedValue.length > 0) {
          for (let item of attribute2Values) {
            if (selectedValue.includes(item.Id.toString())) {
              item.Selected = true;
            }
            else {
              item.Selected = false;
            }
          }
        }
        setShowSelectedCategorypopup(true)
        setsidebarwidth("60vw");
        SetCountKeyValue(item.SelectedItemSubCategory)
      }
      else if ((item.SelectedItemSubCategory).toLowerCase() == ('Category3').toLowerCase()) {
        let selectedValue = item.ValueListString;
        let selectedCat1 = [];
 
        if (selectedValue && selectedValue.length > 0) {
          for (let item of attribute3Values) {
            if (selectedValue.includes(item.Id.toString())) {
              item.Selected = true;
            }
            else {
              item.Selected = false;
            }
          }
        }
        setShowSelectedCategorypopup(true)
        setsidebarwidth("60vw");
        SetCountKeyValue(item.SelectedItemSubCategory)
      }
      else if ((item.SelectedItemSubCategory).toLowerCase() == ('Category4').toLowerCase()) {
        let selectedValue = item.ValueListString;
        let selectedCat1 = [];
      
        if (selectedValue && selectedValue.length > 0) {
          for (let item of attribute4Values) {
            if (selectedValue.includes(item.Id.toString())) {
              item.Selected = true;
            }
            else {
              item.Selected = false;
            }
          }
        }
        setShowSelectedCategorypopup(true)
        setsidebarwidth("60vw");
        SetCountKeyValue(item.SelectedItemSubCategory)
      }
      else if ((item.SelectedItemSubCategory).toLowerCase() == ('Category5').toLowerCase()) {
        let selectedValue = item.ValueListString;
        let selectedCat1 = [];
 
        if (selectedValue && selectedValue.length > 0) {
          for (let item of attribute5Values) {
            if (selectedValue.includes(item.Id.toString())) {
              item.Selected = true;
            }
            else {
              item.Selected = false;
            }
          }
        }
        setShowSelectedCategorypopup(true)
        setsidebarwidth("60vw");
        SetCountKeyValue(item.SelectedItemSubCategory)
      }
      else if ((item.SelectedItemSubCategory).toLowerCase() == ('Individual Customer').toLowerCase()) {
        let selectedValue = item.ValueListString;
        let selectedCat1 = [];
     
        if (selectedValue && selectedValue.length > 0) {
          for (let item of CustomerValues) {
            if (selectedValue.includes(item.Id.toString())) {
              item.Selected = true;
            }
            else {
              item.Selected = false;
            }
          }
        }
        setShowSelectedCustomerpopup(true)
        setsidebarwidth("60vw");
        clickSelectedShowCustomerPopup()
      }
      else if ((item.SelectedItemSubCategory).toLowerCase() == ('Customer Group Name').toLowerCase()) {
        let selectedValue = item.ValueListString;
        let selectedCat1 = [];
 
        if (selectedValue && selectedValue.length > 0) {
          for (let item of CustomerValues) {
            if (selectedValue.includes(item.Id.toString())) {
              item.Selected = true;
            }
            else {
              item.Selected = false;
            }
          }
        }
        setShowSelectedCustomerGrouppopup(true)
        setsidebarwidth("60vw");
        clickSelectedShowCustomerGroupPopup()
      }
      else if ((item.SelectedItemSubCategory).toLowerCase() == ('Label').toLowerCase() && (item.Category).toLowerCase() == ('Settlementfrequency').toLowerCase()) {
        setShowSelectedSettlFreq(true);
        clickShowSelectedSettlFreq()
        setsidebarwidth("70vw")
      }
      else if ((item.SelectedItemSubCategory).toLowerCase() == ('Label').toLowerCase() && (item.Category).toLowerCase() == ('Rebate Type').toLowerCase()) {
        let selectedValue = item.ValueListString;
        let selectedCat1 = [];
    
        if (selectedValue && selectedValue.length > 0) {
          for (let item of rebateTypValues) {
            if (selectedValue.includes(item.Id.toString())) {
              item.Selected = true;
            }
            else {
              item.Selected = false;
            }
          }
        }
        setShowSelectedRebateType(true);
        clickShowSelectedRebateType()
        setsidebarwidth("70vw")
      }
    }
    else {
      alert('Please Select Category & SubCategory')
    }
  }

  const ClickValuePopupClose = (flag, value, popup) => {
    if (flag == 'Close' && value == 'Product' && popup == 'All') {
      setShowProductPopup(false);
      setShowModal(false)
    }
    if (flag == 'Close' && value == 'Product' && popup == 'Selected') {
      setShowSelectedProductpopup(false);
      setShowModal(false)
    }
    if (flag == 'Close' && value == 'Category1' && popup == 'All') {
      setshowCategoryPopup(false);
      SetKeyValue("")
    }
    if (flag == 'Close' && value == 'Category1' && popup == 'Selected') {
      setShowSelectedCategorypopup(false);
      SetCountKeyValue("")
    }
    if (flag == 'Close' && value == 'Category2' && popup == 'All') {
      setshowCategoryPopup(false);
      SetKeyValue("")
    }
    if (flag == 'Close' && value == 'Category2' && popup == 'Selected') {
      setShowSelectedCategorypopup(false);
      SetCountKeyValue("")
    }
    if (flag == 'Close' && value == 'Category3' && popup == 'All') {
      setshowCategoryPopup(false);
      SetKeyValue("")
    }
    if (flag == 'Close' && value == 'Category3' && popup == 'Selected') {
      setShowSelectedCategorypopup(false);
      SetCountKeyValue("")
    }
    if (flag == 'Close' && value == 'Category4' && popup == 'All') {
      setshowCategoryPopup(false);
      SetKeyValue("")
    }
    if (flag == 'Close' && value == 'Category4' && popup == 'Selected') {
      setShowSelectedCategorypopup(false);
      SetCountKeyValue("")
    }
    if (flag == 'Close' && value == 'Category5' && popup == 'All') {
      setshowCategoryPopup(false);
      SetKeyValue("")
    }
    if (flag == 'Close' && value == 'Category5' && popup == 'Selected') {
      setShowSelectedCategorypopup(false);
      SetCountKeyValue("")
    }
    if (flag == 'Close' && value == 'Customer' && popup == 'All') {
      setshowCustomerPopup(false);
    }
    if (flag == 'Close' && value == 'Customer' && popup == 'Selected') {
      setShowSelectedCustomerpopup(false);
    }
    if (flag == 'Close' && value == 'CustomerGroup' && popup == 'All') {
      setshowCustomerGroupPopup(false);
    }
    if (flag == 'Close' && value == 'GroupCustomer' && popup == 'Selected') {
      setShowSelectedCustomerGrouppopup(false);
    }
    if (flag == 'Close' && value == 'SettlementFreq' && popup == 'All') {
      setshowSetlFreqPopup(false);
    }
    if (flag == 'Close' && value == 'SettlementFreq' && popup == 'Selected') {
      setShowSelectedSettlFreq(false);
    }
    if (flag == 'Close' && value == 'RebateType' && popup == 'All') {
      setshowRebateTypePopup(false);
    }
    if (flag == 'Close' && value == 'RebateType' && popup == 'Selected') {
      setShowSelectedRebateType(false);
    }
  }

  const ClickValuePopupCancel = () => {
    setshowCategoryPopup(false);
  }

  const clickShowWorkflow = (val, id) => {
    setsidebarwidth("70vw");
    if (val === "Workflow")
      setshowExceptionWorkflow(true);
    setshowWorkflow(false);
  }
  const _ = require('lodash');



  const getExceptionWorkflowApprovers = (method, id) => {
    Service.getdatabyId(method, id).then((res) => {

      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          setWorkflowApprovers(res.data)
          setWorkflowLevelCount(res.data.length)
          const sortdata = _.orderBy(res.data, ['Workflowlevel'], ['asc'])
          setworkflowchart(sortdata);
        
        }

      }

    });
  }

  const getExceptionWorkflowDetails = (id, pagesize, pagenumber) => {
    Service.getdatabyId('ExceptionPolicies/GetExceptionWorkflowById', id, pagesize, pagenumber).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {
            if (res.data.DataList !== 'null' && res.data.DataList !== 'undefined') {
        
              wData = res.data.DataList;
              setWorkflowData(wData);
              setshowspinnerworkflow(false)
              setWoData('get data');
            }
          }
        }
      }
    });

  }



  const changeIcon = (e, id) => {
    setExpandAccruals(id)

    if (icon == 'fa fa-plus') {
      setIcon('fa fa-minus');
    }
    if (icon == 'fa fa-minus') {
      setIcon('fa fa-plus');
    }

  }


  const ClickValuePopup = (item, index) => {
 
    setItemNo(index);
    if (item.SelectedItemSubCategory != null && item.SelectedItemSubCategory != '' && item.SelectedItemSubCategory != undefined) {
      if ((item.SelectedItemSubCategory).toLowerCase() == ('Label').toLowerCase() && item.Category == 'Product') {
        if (props.id != 0) {
         


          let selectedData = []
          let productData = [...ProductValues]
          let filteredData = []
      
          let selectedValue = item.ValueListString;
          if (selectedValue != null && selectedValue.length > 0) {
            selectedValue.forEach((selectedpro, index) => {
              ProductValues.forEach((eachpro) => {
                if (selectedpro == eachpro.Id) {
                  eachpro.Selected = true
                  let z = `${eachpro.Label}${"_"}${eachpro.Id}`
                  filteredData.push(z)
                }
              })
            })
          }
          setdualListBoxOptionsByAttribute(productData)
          SetFilteredProducts(filteredData)
        }

        setdualListBoxOptionsByAttribute(ProductValues)
        setclickProduct(true)
        setShowModal(true)
        setSelectedType('Attribute')
        setShowProductPopup(true);
        setsidebarwidth("70vw")
      }
      else if ((item.SelectedItemSubCategory).toLowerCase() == ('Category1').toLowerCase()) {
        if (props.id != 0) {
          let selectedValue = item.ValueListString;
          let selectedCat1 = [];
        
          if (selectedValue && selectedValue.length > 0) {
            for (let item of attribute1Values) {
              if (selectedValue.includes(item.Id.toString())) {
                item.Selected = true;
              }
              else {
                item.Selected = false;
              }
            }
          }
        }
    
        setshowCategoryPopup(true)
        setsidebarwidth("60vw");
        SetKeyValue(item.SelectedItemSubCategory)
      }
      else if ((item.SelectedItemSubCategory).toLowerCase() == ('Category2').toLowerCase()) {
        if (props.id != 0) {
          let selectedValue = item.ValueListString;
          let selectedCat1 = [];
      
          if (selectedValue && selectedValue.length > 0) {
            for (let item of attribute2Values) {
              if (selectedValue.includes(item.Id.toString())) {
                item.Selected = true;
              }
              else {
                item.Selected = false;
              }
            }
          }
        }
        setshowCategoryPopup(true)
        setsidebarwidth("60vw");
        SetKeyValue(item.SelectedItemSubCategory)
      }
      else if ((item.SelectedItemSubCategory).toLowerCase() == ('Category3').toLowerCase()) {
        if (props.id != 0) {
          let selectedValue = item.ValueListString;
          let selectedCat1 = [];
          
          if (selectedValue && selectedValue.length > 0) {
            for (let item of attribute3Values) {
              if (selectedValue.includes(item.Id.toString())) {
                item.Selected = true;
              }
              else {
                item.Selected = false;
              }
            }
          }
        }
        setshowCategoryPopup(true)
        setsidebarwidth("60vw");
        SetKeyValue(item.SelectedItemSubCategory)
      }
      else if ((item.SelectedItemSubCategory).toLowerCase() == ('Category4').toLowerCase()) {
        if (props.id != 0) {
          let selectedValue = item.ValueListString;
          let selectedCat1 = [];
    
          if (selectedValue && selectedValue.length > 0) {
            for (let item of attribute4Values) {
              if (selectedValue.includes(item.Id.toString())) {
                item.Selected = true;
              }
              else {
                item.Selected = false;
              }
            }
          }
        }
        setshowCategoryPopup(true)
        setsidebarwidth("60vw");
        SetKeyValue(item.SelectedItemSubCategory)
      }
      else if ((item.SelectedItemSubCategory).toLowerCase() == ('Category5').toLowerCase()) {
        if (props.id != 0) {
          let selectedValue = item.ValueListString;
          let selectedCat1 = [];

          if (selectedValue && selectedValue.length > 0) {
            for (let item of attribute5Values) {
              if (selectedValue.includes(item.Id.toString())) {
                item.Selected = true;
              }
              else {
                item.Selected = false;
              }
            }
          }
        }
        setshowCategoryPopup(true)
        setsidebarwidth("60vw");
        SetKeyValue(item.SelectedItemSubCategory)
      }
      else if ((item.SelectedItemSubCategory).toLowerCase() == ('Individual Customer').toLowerCase()) {
        if (props.id != 0) {
          let selectedValue = item.ValueListString;
          let selectedCat1 = [];
     
          if (selectedValue && selectedValue.length > 0) {
            for (let item of CustomerValues) {
              if (selectedValue.includes(item.Id.toString())) {
                item.Selected = true;
              }
              else {
                item.Selected = false;
              }
            }
          }
        }
        setshowCustomerPopup(true)
        setsidebarwidth("60vw");
      }

      else if ((item.SelectedItemSubCategory).toLowerCase() == ('Customer Group').toLowerCase()) {
        if (props.id != 0) {
          let selectedValue = item.ValueListString;
          let selectedCat1 = [];
   
          if (selectedValue && selectedValue.length > 0) {
            for (let item of CustomerValues) {
              if (selectedValue.includes(item.Id.toString())) {
                item.Selected = true;
              }
              else {
                item.Selected = false;
              }
            }
          }
        }
        setshowCustomerGroupPopup(true)
        setsidebarwidth("60vw");
      }
      else if ((item.SelectedItemSubCategory).toLowerCase() == ('Label').toLowerCase() && (item.Category).toLowerCase() == ('Settlementfrequency').toLowerCase()) {
        if (props.id != 0) {
          let selectedValue = item.ValueListString;
          let selectedCat1 = [];
    
          if (selectedValue && selectedValue.length > 0) {
            for (let item of settlFreqValues) {
              if (selectedValue.includes(item.Id.toString())) {
                item.Selected = true;
              }
              else {
                item.Selected = false;
              }
            }
          }
        }
        setshowSetlFreqPopup(true);
        setsidebarwidth("70vw")
      }
      else if ((item.SelectedItemSubCategory).toLowerCase() == ('Label').toLowerCase() && (item.Category).toLowerCase() == ('Rebate Type').toLowerCase()) {
        if (props.id != 0) {
          let selectedValue = item.ValueListString;
          let selectedCat1 = [];
   
          if (selectedValue && selectedValue.length > 0) {
            for (let item of rebateTypValues) {
              if (selectedValue.includes(item.Id.toString())) {
                item.Selected = true;
              }
              else {
                item.Selected = false;
              }
            }
          }
        }
        setshowRebateTypePopup(true);
        setsidebarwidth("70vw")
      }
    }
    else if (item.Category == '') {
      let idx = itemNo + 1
      alert("Please Select Category in Row " + idx)
    }
    else if (item.SelectedItemSubCategory == '') {
      let idx = itemNo + 1
      alert("Please Select SubCategory in Row " + idx)
    }
    else if (item.Operator == '') {
      let idx = itemNo + 1
      alert("Please Select Operator in Row " + idx)
    }
   
  }

 

  const OnNameChange = (e) => {
    let value = e.target.value;
    setExceptionPolicyData({ ...exceptionPolicyData, ['ExceptionPolicyName']: value })
  }
  const OnChangeRebateFor = (e) => {
    let value = e.target.value;
    setExceptionPolicyData({ ...exceptionPolicyData, ['RebateFor']: value })
  }
  const OnSubDividePeriodChange = (e) => {

    let value = e.target.value;
    setsubdivideList({ ...subdivideList, ['Period']: value })
  }
  const OnSubDivideOperatorChange = (e) => {
    let value = e.target.value;
    setsubdivideList({ ...subdivideList, ['Operator']: value })
  }
  const OnSubDivideValueChange = (e) => {
    let value = e.target.value;
    setsubdivideList({ ...subdivideList, ['Value']: value })
  }
  const SaveHandler = (e, action) => {

    let isValid = Validation.NewException(exceptionPolicyData, ExceptionValueList, subdivideList, fyThresholdList);
    if (isValid != '') {
      setErrorMessage(isValid);
      setErrorStatus("Error")
      setShowErrorModal(true);
      return false;
    }
    else if (isValid === '') {


      let inputData = []

      inputData = {
        Id: exceptionPolicyData.Id,
        ExceptionPolicyName: exceptionPolicyData.ExceptionPolicyName,
        ExceptionTypeId: exceptionPolicyData.ExceptionTypeId,
        ExceptionTypeName: exceptionPolicyData.ExceptionTypeName,
        SubTierTargetTypeId: exceptionPolicyData.SubTierTargetTypeId,
        RebateFor: exceptionPolicyData.RebateFor,
        LastModifiedUserId: loggedInUser,
        CreatedUserId: loggedInUser,
        ...(exceptionPolicyData.ExceptionTypeName == "SubDivideThreshold" ? { subdivideMinThresholdValue: subdivideList } : {}),
        ExceptionValueList: exceptionValues,

        ...(exceptionPolicyData.ExceptionTypeName == "FYThreshold" ? { fYThresholdValue: fyThresholdList } : {}),

      }
   
      Service.createorupdate('ExceptionPolicies/CreateOrUpdate', inputData).then((res) => {
        if (typeof res !== 'undefined') {
          if (res.data !== 'null' && typeof res.data !== 'undefined') {
            let rowData = res.data
            let id = parseInt(res.data)
            if (action != "Submit") {
              toast.success('Saved Successfully !', {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
            if (action == 'SaveClose') {
              Service.LockUnLockEntity('Configurationprop/UnLockEntity', "ExceptionPolicy", id, loggedInUser).then((res) => {
              });
              navigate("/ExceptionPolicylist");
            }
            else {
              navigate("/ExceptionPolicylist/new/" + id + "/fromPolicy");
            }
          }
        }
      });
    }
    else {
      setShowErrorModal(true);
    }
  }

  const getExceptionPolicyById = (method, id) => {

    let apiData = []
    Service.getdatabyId(method, id).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {

          if (res.status === 200) {
            if (typeof res.data !== 'undefined' && res.data !== 'null') {

              let rData = res.data[0];
              setExceptionPolicyData({
                Id: rData.Id,
                ExceptionPolicyId: rData.ExceptionPolicyId,
                ExceptionPolicyName: rData.ExceptionPolicyName,
                ExceptionTypeName: rData.ExceptionTypeName,
                ExceptionTypeId: rData.ExceptionTypeId,
                ExceptionTypeId: rData.ExceptionTypeId,
                SubTierTargetTypeId: rData.SubTierTargetTypeId,
                RebateFor: rData.RebateFor,
                LastModifiedUserId: rData.LastModifiedUserId,
                CreatedUserId: rData.CreatedUserId,
                exceptionPoliciesValues: rData.ExceptionValueList,
                StatusLabel: rData.StatusLabel,
                CreatedUser: rData.CreatedUser,
                CreatedDate: moment(rData.CreatedDate).format(SessionManage.getcurrentdateformat()),
                SubDivideTargetType: rData.SubDivideTargetType,
              })
           
              if ((rData.Locked == true && rData.LockedById.toString() !== loggedInUser)) {
                $("#libread").html(rData.ExceptionPolicyId + " " + `Locked By ${rData.LockedByUserName}`);
              }
              else {
                $("#libread").html(rData.ExceptionPolicyId);
              }

              setshowExceptionTypeListselection(rData.ExceptionTypeName)
              if (((props.name == 'fromApproval' || props.name == 'fromapprovalcompleted') && rData.StatusLabel == 'InReview' && rData.Locked == false) || (rData.StatusLabel == 'New' && rData.Locked == false)) {
                Service.LockUnLockEntity('Configurationprop/LockEntity', "ExceptionPolicy", id, loggedInUser).then((res1) => {
                  if (typeof res1 !== 'undefined') {
                    setLockedBy(loggedInUser);
                    setIsLocked(true);
                  }
                });
              }
              else {
                setLockedBy(rData.LockedById);
                setIsLocked(rData.Locked);
              }
              rData.ExceptionValueList.map((each, index) => {
                each.SubCategory.map((eachsub) => {
                  if (each.SelectedItemSubCategory == eachsub.label) {
                    eachsub["dropdownState"] = {
                      'selected': true,
                      'row': index + 1
                    }
                  }
                  else {
                    eachsub["dropdownState"] = {
                      'selected': false,
                      'row': -1
                    }
                  }
                })
              })

              let values = dropDownValues
     

              rData.ExceptionValueList.map((each, index) => {
                rData.dropDownData.map((val) => {
                  val.SubCategory.map((sub) => {
                    if (each.SelectedItemSubCategory == sub.label) {
                      sub["dropdownState"] = {
                        'selected': true,
                        'row': index + 1
                      }
                    }
                    else {
                      sub["dropdownState"] = {
                        'selected': false,
                        'row': -1
                      }
                    }
                  })
                })
              })
              setExceptionValueList(rData.ExceptionValueList)
              setDropDownValues(rData.dropDownData)

              let CatData = [];
              let SubCatData = [];
              rData.dropDownData.map((i) => {
                const data = {
                  id: i.Id,
                  Id: i.Id,
                  Name: i.Category,
                  label: i.Category,
                  value: i.Category,
                }
                
                CatData.push(data);
                i.SubCategory.map((s) => {
                  const subdata = {
                    id: s.Id,
                    Id: s.value,
                    Name: s.label,
                    label: s.label,
                    value: s.value,
                  }
                  SubCatData.push(subdata);
                })
               
              });

              setCategoryValues(CatData);
              setSubCategoryValues(SubCatData);
             
              setUpdatedCategoryValues(list => ([...CatData]));
              catValues = CatData;
              
              setModifiedLst(rData.dropDownData)


              rData.ExceptionValueList.map((i) => {
            
                let row = 0;

                let item = {
                  Id: i.Id,
                  ExceptionDataId: i.ExceptionDataId,
                  Operator: i.Operator,
                  ValueListString: i.ValueListString
                };
               
                setExceptionValues((prevData) => {
                  // Remove the default value before adding items from the list
                  const newData = prevData.filter((item) => item.id !== 0);
                  return [...newData, item];
                });
              });

              setsubdivideList(rData.subdivideMinThresholdValue);
              if (rData.subdivideMinThresholdValue != null) {
                if (rData.subdivideMinThresholdValue.Period != null) {
                  setSubdividefield(true)
                  setShowTargetTable(true)
                  setShowTargetSelection(rData.SubDivideTargetType)
                  if (rData.ExceptionValueList.length > 0) {
                    setaddCategory(true);
                  } else {
                    setShowAddButton(true)
                  }
                }
              }
              setfyThresholdList(rData.fYThresholdValue)
              if (rData.fYThresholdValue != null) {
                setFYThresholdfield(true)
                if (rData.ExceptionValueList.length > 0) {
                  setaddCategory(true);
                } else {
                  setShowAddButton(true)
                }
              }
              if (rData.ExceptionTypeName == "FYThreshold" || rData.ExceptionTypeName == "SubDivideThreshold") {
               
                UpdateCategoryValues();
           

              }

             
              if (rData.StatusLabel == 'New') {
                setrebatelinestatus('New')
              }
              else {
                setrebatelinestatus('')
                setShowAddButton(false)
              }
              if (rData.StatusLabel == 'New') {
                insertWorkflowDetails(id, loggedInUser)
              }
              setTimeout(() => {
                getExceptionWorkflowDetails(id, paging.pageSize, paging.pageNumber)
                getExceptionWorkflowApprovers('ExceptionPolicies/GetExceptionWorkflowApprovers', id);
              }, 500);
            }
          }
        }
      }
    });
  }

  const updatedropdownValues = () => {
   
    ExceptionValueList.map((each, index) => {
      dropDownValues.map((val) => {
        each.SubCategory.map((sub) => {
          if (each.SelectedItemSubCategory == sub.label) {
         
            sub.dropdownState.selected = true
            sub.dropdownState.row = index + 1
          }
        })
      })
    })
  }

  const insertWorkflowDetails = (id, userId) => {
    Service.workflowDataExcep('ExceptionPolicies/InsertWorkflow', id, userId).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
       
          if (res.status == 200) {
            if (res.data !== 'null' && res.data !== 'undefined') {
             
            }
          }
        }
      }
    })
  }

  

  const attributeSearch = (event) => {

    let names = [
      { key: "Category1", data: attribute1ValuesHold, setter: setAttribute1Values },
      { key: "Category2", data: attribute2ValuesHold, setter: setAttribute2Values },
      { key: "Category3", data: attribute3ValuesHold, setter: setAttribute3Values },
      { key: "Category4", data: attribute4ValuesHold, setter: setAttribute4Values },
      { key: "Category5", data: attribute5ValuesHold, setter: setAttribute5Values }
    ]
    let list = []
   
    names.forEach((each) => {
      if (each.key == keyValue) {
        list = each.data
        if (event.target.value != '') {
          let searchResults = list.filter((each) => {
           
            return each.Name.toLowerCase().includes(String(event.target.value).toLowerCase())
          });
          each.setter(searchResults)
        }
        else {
          each.setter(each.data)
        }
      }
    })
  }

  const checkAllAttribute = (mycheckbox) => {
    let names = [
      { key: "Cat-I", data: attribute1Values, setter: setAttribute1Values, cat: 'lstcat1' },
      { key: "Cat-II", data: attribute2Values, setter: setAttribute2Values, cat: 'lstcat2' },
      { key: "Cat-III", data: attribute3Values, setter: setAttribute3Values, cat: 'lstcat3' },
      { key: "Cat-IV", data: attribute4Values, setter: setAttribute4Values, cat: 'lstcat4' },
      { key: "Cat-V", data: attribute5Values, setter: setAttribute5Values, cat: 'lstcat5' },
    ]
    let list = []
    names.forEach((each) => {
      if (each.key == keyValue) {
        list = each.data
      
        var checkboxes = document.querySelectorAll("input[name = 'attribute-check']");
        if (mycheckbox.target.checked == true) {
          setattbCheckAll(true);
         
        }
        else {
          setattbCheckAll(false);
          
        }
      }
    })
  }


  const OnChangeHandlerAttribute = (e, label, id, flag) => {
    let names = [
      { key: "Cat-I", data: attribute1Values, setter: setAttribute1Values, cat: 'lstcat1' },
      { key: "Cat-II", data: attribute2Values, setter: setAttribute2Values, cat: 'lstcat2' },
      { key: "Cat-III", data: attribute3Values, setter: setAttribute3Values, cat: 'lstcat3' },
      { key: "Cat-IV", data: attribute4Values, setter: setAttribute4Values, cat: 'lstcat4' },
      { key: "Cat-V", data: attribute5Values, setter: setAttribute5Values, cat: 'lstcat5' },
    ]

    let temp = []
    let ids = [];
    names.forEach((each) => {  //iterating names array
      if (each.key == flag) {
        each.data.forEach((cat) => {  // iterating attribute data
          if (cat.Id === id) {
            e.target.checked ? cat.Selected = true : cat.Selected = false
          }
        })
        temp = each //storing selected obj in var
      }
    })
    switch (flag) {
      case "Cat-I":
        setAttribute1Values(temp.data)
        ids = formatAttributes(temp.data);
        break;

      case "Cat-II":
        setAttribute2Values(temp.data)
        ids = formatAttributes(temp.data);
        break;

      case "Cat-III":
        setAttribute3Values(temp.data)
        ids = formatAttributes(temp.data);
        break;

      case "Cat-IV":
        setAttribute4Values(temp.data)
        ids = formatAttributes(temp.data);
        break;
      case "Cat-V":
        setAttribute5Values(temp.data)
        ids = formatAttributes(temp.data);
        break;

    }
    const list = [...ExceptionValueList];
    const list2 = [...exceptionValues];

    list[itemNo]['ValueListString'] = ids;
    list2[itemNo]['ValueListString'] = ids;
    setExceptionValueList(list);
    setExceptionValues(list2);
  }
  

  const formatAttributes = (data) => {
    let temp = []
    data.forEach((i) => {
      if (i.Selected == true) {
        temp.push(i.Id.toString())
      }
    })
  
    return temp
  }


  const okSelectedCustomer = () => {


  }
  const okCustomer = () => {
    setshowCustomerPopup(false);
    let ids = [];
    ids = formatAttributes(CustomerValues);
    const list = [...ExceptionValueList];
    const list2 = [...exceptionValues];
 
    list[itemNo]['ValueListString'] = ids;
    list2[itemNo]['ValueListString'] = ids;
    setExceptionValueList(list);
    setExceptionValues(list2);
 

  }



  const FilterColumnCustomer = (name) => {
    var divtop1 = document.getElementById("Customer");
    var cardtop1 = document.getElementById("Customer");
    setdivtop(divtop1.offsetTop + cardtop1.offsetTop + 30)
    setCustomerFilterCol(name)
    setshowCustomerfilterpopup(true)

    if (name === 'Name') {
      setfiltertextCust("Customer Code")
      var testDiv = document.getElementById("Name");
      setdivleft((testDiv.offsetLeft + 30) + "px");
    }
    if (name === 'Label') {
      setfiltertextCust("Customer Name")
      var testDiv = document.getElementById("Label");
      setdivleft((testDiv.offsetLeft + 30) + "px");
    }

    if (name === 'Station') {
      setfiltertextCust("Station")
      var testDiv = document.getElementById("Station");
      setdivleft((testDiv.offsetLeft + 30) + "px");

    }
    if (name === 'State') {
      setfiltertextCust("State")
      var testDiv = document.getElementById("State");
      setdivleft((testDiv.offsetLeft + 30) + "px");

    }
    if (name === 'Zone') {
      setfiltertextCust("Zone")
      var testDiv = document.getElementById("Zone");
      setdivleft(((testDiv.offsetLeft + 30) - 100) + "px");
    }
    DropdownHandlerCus(name);
  }
  const DropdownHandlerCus = (key) => {
    
    Service.getfilterDropdownByGroup('Rebate/GetAllCustomerFilters', key).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.data !== 'null' && res.data !== 'undefined') {
           
            addnewfieldCustomer(res.data, key)
          }
        }
      }

    });
 
  }
  const addnewfieldCustomer = (data, key) => {
   
    let currentdata = data.map((i) => {
      i.Selected = false;
      return i;
    });
    let selectedKeys = filterValueCustomer[key];
   
    if (selectedKeys != '') {
   
      currentdata.forEach((each) => {
        selectedKeys.forEach((eachSelected) => {
          if (each.Name == eachSelected.Name) {
            each.Selected = true
          }
        })
      })
    }
    setFilterCusDropDownData(currentdata);
    setfilterCustData(currentdata)
  }


  const OnCusdropdownChange = (e, id, name) => {

    let isChecked = e.target.checked;
    var lastfilter = [...filterCusDropDownData]
    if (isChecked === true) {
      let currentdata = lastfilter.map((i) => {
        if (i.Name == name) {
          i.Selected = true;
        }
        return i;
      });
      setFilterCusDropDownData(currentdata);
      let data1 = []
      const getallData = {
        Name: name,
      }
      data1.push(getallData)
      setFilterValueCust(filterValueCust => [...filterValueCust, ...data1]);
      let updateddata = ([...filterValueCust, ...data1]);
      setFilterValueCustomer(() => ({
        ...filterValueCustomer, [custFilterCol]: updateddata,
      }));
    }
    else {
      let currentdata = lastfilter.map((i) => {
        if (i.Id === id) {
          i.Selected = false;
        }
        return i;
      });
   
      setFilterCusDropDownData(currentdata);
      setFilterValueCust(filterValueCust => filterValueCust.filter(data => data.Name !== name))
      let data1 = filterValueCust.filter(data => data.Name !== name);
      setFilterValueCustomer(() => ({
        ...filterValueCustomer, [custFilterCol]: data1,
      }));
    }
  }

  const UserfilterHandlerCustomer = (e) => {
  
    setFilterSearchCust(e.target.value)
    if (e.target.value !== "") {
      let searchResults = filterCusDropDownData.filter((each) => {
        return each.Name.toLowerCase().includes(String(e.target.value).toLowerCase())
      });
      setFilterCusDropDownData(searchResults);
    }
    else {

      setFilterSearchCust("")
      setFilterCusDropDownData(filtercustData)
    }
  }

  const checkAllFilterCustomer = (mycheckbox) => {
    var lastfilter = [...filterCusDropDownData]
    let data1 = []
    var checkboxes = document.querySelectorAll("input[name = 'filter-option-all']");
    if (mycheckbox.target.checked == true) {
   
      let currentdata = lastfilter.map((i) => {
        i.Selected = true;
        return i;
      });
      
      setFilterCusDropDownData(currentdata)
      filterCusDropDownData.map((each) => {
 
        const getallData = {
          Name: each.Name,
        }
        data1.push(getallData)
      })
      setFilterValueCust(filterValueCust => [...filterValueCust, ...data1]);
      let updateddata = ([...filterValueCust, ...data1]);
      setFilterValueCustomer(() => ({
        ...filterValueCustomer, [custFilterCol]: updateddata,
      }));
    }
    else {

      let currentdata = lastfilter.map((i) => {
        i.Selected = false;
        return i;
      });
      setFilterValueCust([])
      setFilterValueCustomer([])
   
    }

  }

  const CustFilterHandler = (flag) => {
    let list = [];

    var selectedCus = data;
    for (var i = 0; i < CustomerValues.length; i++) {
      if (CustomerValues[i].Selected == true) {
        for (var k = 0; k < selectedCus.length; k++) {
          if (CustomerValues[i].Id === selectedCus[k].Id) {
            selectedCus[k].Selected = true;
          }
        }
      }
    }
    setData(selectedCus);
    setshowCustomerfilterpopup(false)
    if (flag === 'Apply') {
      
      for (var key in filterValueCustomer) {
        if (filterValueCustomer[key].length > 0) {
          let values = ""
          filterValueCustomer[key].map((i) => {
            values += i.Name + ";";
          })
          if (values !== null && values !== '') {
            list.push({
              filterKey: key,
              FilterValue: values
            })
          }
        }
      }
    
      Service.getdataWithFilters('rebate/filterCustomerAll', customerData, list).then((res) => {
        if (typeof res !== 'undefined') {
          if (res.data !== 'null' && typeof res.data !== 'undefined') {
            setCustomerValues(res.data.DataList)
          }
        }
      });

    }
    else {
      setshowCustomerfilterpopup(false)
      setFilterValueCustomer(() => ({
        ...filterValueCustomer, [custFilterCol]: '',
      }));
    
      for (var key in filterValueCustomer) {
        if (key !== custFilterCol && filterValueCustomer[key].length > 0) {
          let values = ""
          filterValueCustomer[key].map((i) => {
            values += i.Name + ";";
          })
          if (values !== null && values !== '') {
            list.push({
              filterKey: key,
              FilterValue: values
            })
          }
        }
      }
  
      Service.getdataWithFilters('rebate/filterCustomerAll', customerData, list).then((res) => {
        if (typeof res !== 'undefined') {
          if (res.data !== 'null' && typeof res.data !== 'undefined') {
            setCustomerValues(res.data.DataList)
          }
        }
      });
      setFilterValueCust([])
      setfilterCustData([])
      setFilterCusDropDownData([])
   
      setFilterSearchCust('')
    }

  }
  const SearchCustomer = (event) => {
    var list = []
    let lstSelected = CustomerValues.filter((e) => e.Selected === true).map(item => {
      return { ...item }
    })
    setCustomerSearch(event.target.value)
    if (customerDataFilter.length === 0) {
      list = CustomerValues;

      setCustomerDataFilter(list);
    }
    else {
      list = customerDataFilter
    }
    if (event.target.value != '') {
      let searchResults = list.filter((each) => {
      
        return each.label.toLowerCase().includes(String(event.target.value).toLowerCase()) ||
          each.Name.toLowerCase().includes(String(event.target.value).toLowerCase()) ||
          each.Station.toLowerCase().includes(String(event.target.value.toLowerCase())) ||
          each.State.toLowerCase().includes(String(event.target.value.toLowerCase())) ||
          each.Zone.toLowerCase().includes(String(event.target.value.toLowerCase()))

      });

      let newlist = [...searchResults, ...lstSelected];
      const uniquelst = [];
      newlist.map((item) => {
        var findItem = uniquelst.find((x) => x.Id === item.Id);
        if (!findItem) uniquelst.push(item);
      });

      setCustomerValues(uniquelst)

    
    }
    else {
      setCustomerValues(list)
    }
  }

  const checkAllCustomer = (mycheckbox) => {

    let Ids = [];
    var checkboxes = document.querySelectorAll("input[name = 'customer-check']");
    if (mycheckbox.target.checked == true) {
      setCustomercheckAll(true);
      checkboxes.forEach(function (checkbox) {
        checkbox.checked = true;
      });
      let olddata = [...CustomerValues];
      olddata.map((i) => {
        i.Selected = true;
        Ids.push(i.Id);
      })

 
    }
    else {
      setCustomercheckAll(false);
      settotalselectedshow(false);
      checkboxes.forEach(function (checkbox) {
        checkbox.checked = false;
      });
      let olddata = [...CustomerValues];
      olddata.map((i) => {
        i.Selected = false;

      })
    
    }

  }
  const handleCustSelection = (e, label, id, State) => {


    let checkboxes = document.querySelectorAll("input[id = '" + id + "']:checked");
    let Selected = [];
    let update = [];

    let olddata = [...CustomerValues];
   
    if (checkboxes.length >= 1) {
      olddata.map((i) => {
        if (i.Id === id) {
          i.Selected = true;
        }
      })
      data.map((i) => {
        if (i.Id === id) {
          i.Selected = true;
        }
      })
      setData(data)
      setCustomerData(olddata)
      
      setCustomerValues(olddata);
      
    }
    else {
      olddata.map((i) => {
        if (i.Id === id) {
          i.Selected = false;
        }
        update.push(i);
      })
      data.map((i) => {
        if (i.Id === id) {
          i.Selected = false;
        }
      })
      setData(data)
    
      setCustomerValues(olddata);
    }
    setCustomerChanged(true)
  }




  const handleCustomerChanges = (e, label, id, State) => {
 
    let Selected = [];
    let update = [];

    let olddata = [...CustomerValues];
    if (e.target.checked === true) {

      olddata.map((i) => {
        if (i.Id === id) {
          i.Selected = true;
        }
       
      })

      customerData.map((i) => {
        if (i.Id === id) {
          i.Selected = true;
        }
      })
      setData(customerData)


      setCustomerValues(customerData);

    }
    else {
      olddata.map((i) => {
        if (i.Id === id) {
          i.Selected = false;
        }
        update.push(i);
      })

      customerData.map((i) => {
        if (i.Id === id) {
          i.Selected = false;
        }
      })
      setData(customerData)

     
      setCustomerValues(customerData);
    }
    
  }

  const SearchGroupCustomer = (event) => {
    var list = []
    let lstSelected = CustomerGroupValues.filter((e) => e.Selected === true).map(item => {
      return { ...item }
    })
 
    if (event.target.value != '') {
      let searchResults = list.filter((each) => {
       
        return each.label.toLowerCase().includes(String(event.target.value).toLowerCase()) ||
          each.Name.toLowerCase().includes(String(event.target.value).toLowerCase())
       

      });

      let newlist = [...searchResults, ...lstSelected];
      const uniquelst = [];
      newlist.map((item) => {
        var findItem = uniquelst.find((x) => x.Id === item.Id);
        if (!findItem) uniquelst.push(item);
      });

      
    }
    else {

    }
  }


  const checkAllGroupCustomer = (mycheckbox) => {
    

  }


  const FilterColumnGroupCustomer = (name) => {
    var divtop1 = document.getElementById("CustomerGroup");
    var cardtop1 = document.getElementById("CustomerGroup");
    setdivtop(divtop1.offsetTop + cardtop1.offsetTop + 30)
    setCustomerGroupFilterCol(name)
    setshowCustomerGroupfilterpopup(true)

    if (name === 'label') {
      setfiltertextCustGroup("Customer Group Label")
      var testDiv = document.getElementById("GroupLabel");
      setdivleft((testDiv.offsetLeft + 30) + "px");
    }
    DropdownHandlerCustGroup(name);
  }

  const DropdownHandlerCustGroup = (key) => {
   
    setcurrentCustGroupFilterKey(key)
    Service.getfilterDropdownByGroup('CustomerGroups/GetFilterValue', key).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.data !== 'null' && res.data !== 'undefined') {
   
            addnewfieldCustomerGroup(res.data, key)
          }
        }
      }

    });
    
  }

  const addnewfieldCustomerGroup = (data, key) => {

    let currentdata = data.map((i) => {
      i.Selected = false;
      return i;
    });
    let selectedKeys = filterValueGroupCustomer[key];

    if (selectedKeys != '') {
  
      currentdata.forEach((each) => {
        selectedKeys.forEach((eachSelected) => {
          if (each.Value == eachSelected.Name) {
            each.Selected = true
          }
        })
      })
    }
    setFilterCustGroupDropDownData(currentdata);
    setfilterCustGroupData(currentdata)
  }




  const OnChangeHandlerGroupCustomer = (e, label, id, State) => {
    let Selected = [];
    let update = [];
    const getallData = [];
    let olddata = [...CustomerGroupValues];

    if (e.target.checked === true) {

      olddata.map((i) => {
        if (i.Id === id) {
          i.Selected = true;
        }

      })

      datagroupCust.map((i) => {
        if (i.Id === id) {
          i.Selected = true;
        }

      })

      getallData.push(id);
      setCustomerGroupValues(olddata);
    }
    else {
      olddata.map((i) => {
        if (i.Id === id) {
          i.Selected = false;
        }
        update.push(i);
      })

      datagroupCust.map((i) => {
        if (i.Id === id) {
          i.Selected = false;
        }
        update.push(i);
      })

      setCustomerGroupValues(olddata);
    }
  }

  const okCustomerGroup = () => {
    setshowCustomerGroupPopup(false);
    let ids = [];
    ids = formatAttributes(CustomerGroupValues);
    const list = [...ExceptionValueList];
    const list2 = [...exceptionValues];
  
    list[itemNo]['ValueListString'] = ids;
    list2[itemNo]['ValueListString'] = ids;
    setExceptionValueList(list);
    setExceptionValues(list2);


  }
  const UserfilterHandlerCustomerGroup = (e) => {
  
    setFilterSearchCustGroup(e.target.value)
    if (e.target.value !== "") {
      let searchResults = filterCustGroupDropDownData.filter((each) => {
        return each.Value.toLowerCase().includes(String(e.target.value).toLowerCase())
      });
      setFilterCustGroupDropDownData(searchResults);
    }
    else {
      setFilterSearchCustGroup("")
      setFilterCustGroupDropDownData(filtercustGroupData)
    }
  }
  const checkAllFilterCustomerGroup = (mycheckbox) => {
    var lastfilter = [...filterCustGroupDropDownData]
    let data1 = []
    var checkboxes = document.querySelectorAll("input[name = 'filter-option-all']");
    if (mycheckbox.target.checked == true) {
   
      let currentdata = lastfilter.map((i) => {
        i.Selected = true;
        return i;
      });
   
      setFilterCustGroupDropDownData(currentdata)
      filterCustGroupDropDownData.map((each) => {
        const getallData = {
          Name: each.label,
        }
        data1.push(getallData)
      })
      setFilterValueCustGroup(filterValueCustGroup => [...filterValueCustGroup, ...data1]);
      let updateddata = ([...filterValueCustGroup, ...data1]);
      setFilterValuegroupCustomer(() => ({
        ...filterValueGroupCustomer, [custGroupFilterCol]: updateddata,
      }));
    }
    else {
     
      let currentdata = lastfilter.map((i) => {
        i.Selected = false;
        return i;
      });
      setFilterValuegroupCustomer([])
      setFilterValueCustGroup([])
     
    }

  }
  const OnCustGroupdropdownChange = (e, id, name) => {
    
    let isChecked = e.target.checked;
    var lastfilter = [...filterCustGroupDropDownData]
    if (isChecked === true) {
      let currentdata = lastfilter.map((i) => {
        if (i.label == name) {
          i.Selected = true;
        }
        return i;
      });
      setFilterCustGroupDropDownData(currentdata);
      let data1 = []
      const getallData = {
        Name: name,
      }
      data1.push(getallData)

      setFilterValueCustGroup(filterValueCustGroup => [...filterValueCustGroup, ...data1]);
      let updateddata = ([...filterValueCustGroup, ...data1]);
      setFilterValuegroupCustomer(() => ({
        ...filterValueGroupCustomer, [custGroupFilterCol]: updateddata,
      }));
    }
    else {
      let currentdata = lastfilter.map((i) => {
        if (i.Id === id) {
          i.Selected = false;
        }
        return i;
      });
  
      setFilterCustGroupDropDownData(currentdata);
      setFilterValueCustGroup(filterValueCustGroup => filterValueCustGroup.filter(data => data.label !== name))
      let data1 = filterValueCustGroup.filter(data => data.label !== name);
      setFilterValuegroupCustomer(() => ({
        ...filterValueGroupCustomer, [custGroupFilterCol]: data1,
      }));
    }
  }
  const filterHandlerCustomerGroup = (flag) => {

    var sortType = columnAscending === true ? 'Ascending' : 'Descending';
    let pagenumber = 1;
    paging.pageNumber = pagenumber;

    if (flag === 'Apply') {
      setshowCustomerGroupfilterpopup(false)
      let list = [];

      for (var key in filterValueGroupCustomer) {
        if (filterValueGroupCustomer[key].length > 0) {
          let values = ""
          filterValueGroupCustomer[key].map((i) => {
            values += i.Name + ";";
          })
          if (values !== null && values !== '') {
            list.push({
              filterKey: key,
              FilterValue: values
            })
          }
        }
      }

   
     
      if (list.length > 0) {
        setpaging(paging);
        GetGroupCustomerData("CustomerGroups/GetAll", paging.pageSize, paging.pageNumber, sortType, sortColName, list, searchValue)
      }

     
    }
    else {
      setshowCustomerGroupfilterpopup(false)

      setFilterValuegroupCustomer(() => ({
        ...filterValueGroupCustomer, [custGroupFilterCol]: '',
      }));
  

      let list = [];
      for (var key in filterValueGroupCustomer) {
        if (key !== custGroupFilterCol && filterValueGroupCustomer[key].length > 0) {
          let values = ""
          filterValueGroupCustomer[key].map((i) => {
            values += i.Name + ";";
          })
          if (values !== null && values !== '') {
            list.push({
              filterKey: key,
              FilterValue: values
            })
          }
        }
      }

     
      setpaging(paging);
     
      GetGroupCustomerData("CustomerGroups/GetAll", paging.pageSize, paging.pageNumber, sortType, sortColName, list, searchValue)
  
      setCustomerGroupFilterCol('')
      setFilterValueCustGroup([])
      setFilterSearchCustGroup("")
      setFilterCustGroupDropDownData([])
      setfilterCustGroupData([])
    }
  }



  const checkAllSettlFreqChange = (mycheckbox) => {
    var checkboxes = document.querySelectorAll("input[name = 'settlfreq-check']");
    if (mycheckbox.target.checked == true) {
      setsettlFreqcheckAll(true);
      checkboxes.forEach(function (checkbox) {
        checkbox.checked = true;
      });
    

      settlFreqValues.map((i) => {
        i.Selected = true
      
      })

      
    }
    else {
      setsettlFreqcheckAll(false);
      checkboxes.forEach(function (checkbox) {
        checkbox.checked = false;
      });
      settlFreqValues.map((i) => {
        i.Selected = false;
      })

     
    }
  }

  const checkAllRebateTypeChange = (mycheckbox) => {
    var checkboxes = document.querySelectorAll("input[name = 'settlfreq-check']");
    if (mycheckbox.target.checked == true) {
      setRebateTypecheckAll(true);
      checkboxes.forEach(function (checkbox) {
        checkbox.checked = true;
      });
     

      rebateTypValues.map((i) => {
        i.Selected = true
        
      })

      
    }
    else {
      setRebateTypecheckAll(false);
      checkboxes.forEach(function (checkbox) {
        checkbox.checked = false;
      });
      rebateTypValues.map((i) => {
        i.Selected = false;
      })

      
    }
  }

  const handleSettlFreqChanges = (e, label, id) => {
    let Selected = [];
    let updatedSetlFreq = [];


    let olddata = [...settlFreqValues];
    if (e.target.checked === true) {

      olddata.map((i) => {
        if (i.Id === id) {
          i.Selected = e.target.checked;
        }
      })
     

      setSettlFreqValues(olddata)
      

    }
    else {

 
      olddata.map((i) => {
        if (i.Id === id) {
          i.Selected = e.target.checked;
        }
      })

    

      setSettlFreqValues(olddata)
     

    }

  }
  const handleRebateTypeChanges = (e, label, id) => {
    let Selected = [];
    let updatedSetlFreq = [];


    let olddata = [...rebateTypValues];
    if (e.target.checked === true) {

      olddata.map((i) => {
        if (i.Id === id) {
          i.Selected = e.target.checked;
        }
      })
    
      setRebateTypeValues(olddata)
      

    }
    else {

 
      olddata.map((i) => {
        if (i.Id === id) {
          i.Selected = e.target.checked;
        }
      })

     

      setRebateTypeValues(olddata)
      

    }

  }

  const oksettlFreq = () => {
    setshowSetlFreqPopup(false);
    let ids = [];
    ids = formatAttributes(settlFreqValues);
    const list = [...ExceptionValueList];
    const list2 = [...exceptionValues];

    list[itemNo]['ValueListString'] = ids;
    list2[itemNo]['ValueListString'] = ids;
    setExceptionValueList(list);
    setExceptionValues(list2);
  }
  const okRebateType = () => {
    setshowRebateTypePopup(false);
    let ids = [];
    ids = formatAttributes(rebateTypValues);
    const list = [...ExceptionValueList];
    const list2 = [...exceptionValues];

    list[itemNo]['ValueListString'] = ids;
    list2[itemNo]['ValueListString'] = ids;
    setExceptionValueList(list);
    setExceptionValues(list2);
  }


  const clickShowSelectedSettlFreq = (val) => {

    let lstcopy = settlFreqValues.filter((e) => e.Selected == true).map(item => {
      return { ...item }
    })

    let lst1 = [];
    setSettlFreqDataSelected([...lstcopy])
    setsidebarwidth("63vw");

  }
  const clickShowSelectedRebateType = (val) => {

    let lstcopy = rebateTypValues.filter((e) => e.Selected == true).map(item => {
      return { ...item }
    })

    let lst1 = [];
    setRebateTypeDataSelected([...lstcopy])
    setsidebarwidth("63vw");

  }

  const clickSelectedShowCustomerPopup = (val) => {

    let lstcopy = CustomerValues.filter((e) => e.Selected == true).map(item => {
      return { ...item }
    })

    let lst1 = [];
    setCustomerDataSelected([...lstcopy])
  }

  const okSelectedCustomerGroup = () => {
    setShowSelectedCustomerGrouppopup(false);
  }

  const clickSelectedShowCustomerGroupPopup = (val) => {

    let lstcopy = CustomerGroupValues.filter((e) => e.Selected === true).map(item => {
      return { ...item }
    })

    let lst1 = [];
    setCustomerGroupDataSelected([...lstcopy])
  }

  const CloseHandler = (e) => {
    Service.LockUnLockEntity('Configurationprop/UnLockEntity', "ExceptionPolicy", exceptionPolicyData.Id, loggedInUser).then((res) => {

    });
    if (loadedFrom === 'fromPendingApproval') {
      navigate("/ExceptionPolicylist/approvals/pending");
    }
    else if (loadedFrom === 'fromCompletedWorkflow') {
      navigate("/ExceptionPolicylist/approvals/archive");
    }
    else if (loadedFrom == "fromAccrual") {
      navigate("/accruals")
    }
    else {
      navigate("/ExceptionPolicylist");
    }
  }
  const OnFYThresholdOperatorChange = (e) => {
    let value = e.target.value;
    setfyThresholdList({ ...fyThresholdList, ['Operator']: value })
  }
  const OnFYThresholdValueChange = (e) => {
    let value = e.target.value;
    setfyThresholdList({ ...fyThresholdList, ['ThresholdValue']: value })
  }

  const OnFYThresholdPayoutChange = (e) => {
     let value = e.target.value;
    setfyThresholdList({ ...fyThresholdList, ['PayoutValue']: value })
  }




  const workflowAlert = (e, flag) => {
    if (flag == 'open') {
      if (workflowApprovers.length > 0) {
        setShowWorkflowAlert(true)
      }
      else {
        showSubmitDialog(e, 'showSubmitDialog')
      }
    }
    else if (flag == 'close') {
      setShowWorkflowAlert(false)
      showSubmitDialog(e, 'showSubmitDialog')
    }
  }



  const showSubmitDialog = (e, flag) => {

    if (flag === 'showSubmitDialog') {
      setShowSubmitModal(!showSubmitModal)

    }
    else if (flag === 'proceed') {
      setShowSubmitModal(false)
      SubmitHandler()

    }
  }
  const SubmitHandler = (e) => {

    let isValid = Validation.NewException(exceptionPolicyData, ExceptionValueList, subdivideList, fyThresholdList);

    let inputData = [];
    if (isValid != '') {
      setErrorMessage(isValid);
      setErrorStatus("Error")
      setShowErrorModal(true);
      return false;
    }
    else if (isValid === '') {

      inputData = {
        Id: exceptionPolicyData.Id,
        ExceptionPolicyName: exceptionPolicyData.ExceptionPolicyName,
        ExceptionTypeId: exceptionPolicyData.ExceptionTypeId,
        ExceptionTypeName: exceptionPolicyData.ExceptionTypeName,
        SubTierTargetTypeId: exceptionPolicyData.SubTierTargetTypeId,
        RebateFor: exceptionPolicyData.RebateFor,
        LastModifiedUserId: loggedInUser,
        CreatedUserId: loggedInUser,
        ...(exceptionPolicyData.ExceptionTypeName == "SubDivideThreshold" ? { subdivideMinThresholdValue: subdivideList } : {}),
        ExceptionValueList: exceptionValues,

        ...(exceptionPolicyData.ExceptionTypeName == "FYThreshold" ? { fYThresholdValue: fyThresholdList } : {}),

      }
    }
    setLoading(true);
    Service.SubmitExceptionPolicy("ExceptionPolicies/SubmitException", exceptionPolicyData.Id, loggedInUser, commentsForSubmit, inputData).then((res) => {

      if (typeof res !== 'undefined') {
    
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          let rData = res.data;
          if (res.data.Code == 200) {
            if (res.data !== 'null' && res.data !== 'undefined') {
              if (res.data.Status != 'Failure') {
            
                let id = parseInt(res.data.Id)
                setCommentsForSubmit('')
                setShowSubmitModal(!showSubmitModal)
                toast.success('Submitted Successfully !', {
                  position: toast.POSITION.TOP_RIGHT,
                  onClose: () => navigate("/ExceptionPolicylist")
                });
                getExceptionPolicyById("ExceptionPolicies/GetById", res.data.Id)
              }
              else {
                toast.success('Error in submission !', {
                  position: toast.POSITION.TOP_RIGHT,
                });
                setLoading(false)
                setErrorMessage(res.data.ErrorMessage);
                setErrorStatus("Error")
                setShowErrorModal(true)
                setShowSubmitModal(!showSubmitModal)
                getExceptionPolicyById("ExceptionPolicies/GetById", res.data.Id)
              }
            }
          }
        }
      }
    });
  }
  const ApproveException = () => {

    let Id = exceptionPolicyData.Id
    let Comments = commentsForApproved
    let UserId = SessionManage.getuserid();//state.createdUserId
    let data = { Id, Comments, UserId }

    Service.createorupdate('ExceptionPolicies/ApproveException', data).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {
         
            if (res.data !== 'null' && res.data !== 'undefined') {
          
              Service.LockUnLockEntity('Configurationprop/UnLockEntity', "ExceptionPolicy", Id, loggedInUser).then((res) => {
                if (typeof res !== 'undefined') {
                  if (typeof res.data !== 'undefined' && res.data !== 'null') {

                  }
                }

              });
              toast.success('Approved Successfully !', {
                position: toast.POSITION.TOP_RIGHT,
                onClose: () => navigate("/ExceptionPolicylist/approvals/pending")
              });
             
            }

          }
          else if (res.status !== 200) {
            toast.success('Error in approval !', {
              position: toast.POSITION.TOP_RIGHT,
            });
            setErrorMessage(res.data.ErrorMessage);
            setErrorStatus("Error")
            setShowErrorModal(true)
          }
        }
      }

    });
  }

  const DenyException = () => {

    let Id = exceptionPolicyData.Id
    let Comments = commentsForDenied
    let UserId = SessionManage.getuserid();//state.createdUserId
    let data = { Id, Comments, UserId }
   
    Service.createorupdate('ExceptionPolicies/DenyException', data).then((res) => {
 
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {
        
            if (res.data !== 'null' && res.data !== 'undefined') {
            
              Service.LockUnLockEntity('Configurationprop/UnLockEntity', "ExceptionPolicy", Id, loggedInUser).then((res) => {
                if (typeof res !== 'undefined') {
                  if (typeof res.data !== 'undefined' && res.data !== 'null') {

                  }
                }

              });
              toast.success('Denied Successfully', {
                position: toast.POSITION.TOP_RIGHT,
                onClose: () => navigate("/ExceptionPolicylist/approvals/pending")
              });
            
            }

          }
          else if (res.status !== 200) {
            toast.success('Error in deny !', {
              position: toast.POSITION.TOP_RIGHT,
            });
            setErrorMessage(res.data.ErrorMessage);
            setErrorStatus("Error")
            setShowErrorModal(true)
          }
        }
      }

    });
  }
  const getUserPermission = (pathname) => {
    Service.GetUserPermission(pathname).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null' && res.data !== '') {
          if (res.data.DataList !== undefined) {
            setuserPermission(res.data.DataList)
          }
        }
      }
    });
  }
  const showApprovedDialog = (flag) => {
    if (flag === 'showApprovedDialog') {
      setCommentsForApproved("")
      setShowApprovedModal(!showApprovedModal)
    }
    else if (flag === 'proceed') {
      ApproveException()
      setShowApprovedModal(!showApprovedModal)

    }
  }


  const showDeniedDialog = (flag) => {
    if (flag === 'showDeniedDialog') {
      setCommentsForDenied("")
      setShowDeniedModal(!showDeniedModal)
    }
    else if (flag === 'proceed') {
      DenyException()
      setShowDeniedModal(!showDeniedModal)

    }
  }
  const OnConditionChange = (data, index) => {
    const list = [...ExceptionValueList];
    const list2 = [...exceptionValues];
  
    list[index]['Condition'] = data;
    list2[index]['Condition'] = data;
    setExceptionValueList(list);
    setExceptionValues(list2);
  
  }

  const UpdateCategoryValues = () => {
    const updatedList = catValues.filter((item) => item.Name !== "Product");
    setCategoryValues(list => [...updatedList])
 
  }
  const UpdateCategoryValuesFromType = () => {
    const updatedList = CategoryValues.filter((item) => item.Name !== "Product");
    setCategoryValues(list => [...updatedList])
    
  }

  const customStyles = {
    control: base => ({
      ...base,
      minHeight: 26
    }),
    dropdownIndicator: base => ({
      ...base,
      padding: 2
    }),
    clearIndicator: base => ({
      ...base,
      padding: 2
    }),
    multiValue: base => ({
      ...base,

    }),
    valueContainer: base => ({
      ...base,
      padding: '0px 2px'
    }),
    input: base => ({
      ...base,
      margin: 0,
      padding: 0
    })
  };

  if (isLocked === false || (isLocked === true && lockedBy == loggedInUser)) {
    if (exceptionPolicyData.StatusLabel == null || exceptionPolicyData.StatusLabel == "New") {
      disableField = false;
    }
    else if (exceptionPolicyData.StatusLabel != "New" || exceptionPolicyData.StatusLabel == "Approved" || exceptionPolicyData.StatusLabel == "InReview") {
      disableField = true;
    }

  }
  else {
    disableField = true;
  }

  return (
    <>
      <div className="container-fluid content-top-gap">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb my-breadcrumb">
            <li className="breadcrumb-item"><Link to="/">{t('Home.title')}</Link></li>
            <li className="breadcrumb-item active" aria-current="page"><Link to="/policies">{t('Menu.Policies')}</Link></li>
            <li className="breadcrumb-item active" aria-current="page"><Link to="/ExceptionPolicylist">{t('ExceptionPolicy.ExceptionList')}</Link></li>
            <li className="breadcrumb-item active" id="libread" aria-current="page">{t('ExceptionPolicy.CreateNewException')}</li>
          </ol>
        </nav>
        <CustomErrorPopup ErrorMessage={errorMessage} show={showErrorModal} ErrorStatus={errorStatus}
          onHide={() => setShowErrorModal(false)}></CustomErrorPopup>
        {loading ? <><div className="loader-container"><div className="spinner"></div></div> </> : ''}
        <ToastContainer autoClose={1000} />
        <div className="row">
          <div className="col-lg-12 pr-lg-2">
            <div className="card text-center card_border">
              <div className="card-header chart-grid__header">
                <div className="row">
                  <div className="col-md-6 text-left">
                    {t('ExceptionPolicy.ExceptionHeader')}
                  </div>

                  <div className="col-md-6">

                    {Service.editPerms(userPerms) == false ? "" : disableField === false ?
                      <>
                        <button className="btn  btn-update btn-sm ml-1" style={{ width: "40px", float: "right" }} type="submit" onClick={(e) => SaveHandler(e, 'Save')} >{t('Save')}</button>
                        <button className="btn  btn-update btn-sm ml-1" style={{ width: "80px", float: "right" }} type="submit" onClick={(e) => SaveHandler(e, 'SaveClose')}>{t('Save & Close')}</button>
                        <button className="btn  btn-update btn-sm ml-1" style={{ width: "50px", float: "right" }} type="submit" onClick={(event) => workflowAlert(event, 'open')}>{t('Submit')}</button>
                      </>
                      : ''}
                    <button className="btn  btn-update btn-sm ml-1" style={{ width: "50px", float: "right" }} type="submit" onClick={CloseHandler}>{t('Cancel')}</button>
                    <button className="btn  btn-update btn-sm ml-1" style={{ width: "65px", float: "right" }} type="submit" onClick={() => { clickShowWorkflow("Workflow", exceptionPolicyData.Id) }}>{t('Workflow')}</button>
                   
                    {Service.editPerms(userPerms) == false ? "" :
                      (loadedFrom == 'fromApproval' && exceptionPolicyData.StatusLabel == 'InReview') ?
                        (isLocked == true && lockedBy.toString() == loggedInUser) ?
                          <>
                            <button type="button" style={{ float: "right",width: "55px" }} className="btn btn-sm btn-update ml-1" onClick={() => showApprovedDialog('showApprovedDialog')} >{t('Approve')}</button>
                            <button type="button" style={{ float: "right",width: "40px" }} className="btn btn-sm btn-update ml-1" onClick={() => showDeniedDialog('showDeniedDialog')} >{t('Deny')}</button>
                          </>
                          : ""
                        : <></>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-1">
          <div className="col-sm-4">
            <div className="row">
              <div className="col-lg-12 pr-lg-2">
                <div className="card text-center card_border">
                  <div className="card-body" style={{ minHeight: "135px" }} >
                    <div className="container">
                      <div className="row">
                        <label className="col-md-6 text-left">{t('ExceptionPolicy.ExceptionID')}</label>
                        <input disabled={true} name="ExceptionID" type="text" className="col-md-6 mb-2 textbox-padding form-control" value={exceptionPolicyData.ExceptionPolicyId} />
                        <label className="col-md-6 text-left">{t('ExceptionPolicy.ExceptionName')}</label>
                        <input name="ExceptionName" type="text" className="col-md-6 mb-2 textbox-padding form-control" value={exceptionPolicyData.ExceptionPolicyName} disabled={disableField} onChange={(e) => OnNameChange(e)} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="row">
              <div className="col-lg-12 pr-lg-2">
                <div className="card text-center card_border">

                  <div className="card-body" style={{ minHeight: "135px" }} >
                    <div className="container">
                      <div className="row">
                        <label className="col-md-6 text-left">{t('ExceptionPolicy.Status')}</label>
                        <input disabled={true} name="Status" type="text" className="col-md-6 mb-2 textbox-padding form-control" value={exceptionPolicyData.StatusLabel} />
                        <label className="col-md-6 mt-2 text-left" style={{ paddingBottom: "10px" }}>{t('ExceptionPolicy.ExceptionType')}<span className={classes.mandatory}>*</span></label>
                        <Select
                          className={"col-md-6 mb-2 textbox-padding-dropdown mt-2 fontsizedropdown"}
                          options={ExceptionTypeList}
                          placeholder={t('Select')}
                          value={ExceptionTypeList.filter(function (option) {
                            return option.Name === `${showExceptionTypeListselection}`;
                          })}
                          onChange={(event) => onChangeExceptionTypeSelection(event)}
                          isSearchable={true}
                          styles={customStyles}
                          isDisabled={disableField}
                        />
                        {subdividefield === true ?
                          <>
                            <label className="col-md-6 mt-2 text-left" style={{ paddingBottom: "10px" }}>{t('ExceptionPolicy.SubDivideTarget')}<span className={classes.mandatory}>*</span> </label>
                            <Select className={"col-md-6 textbox-padding-dropdown mt-2 fontsizedropdown"}
                              id="subdivideTargetTypeId"
                              styles={customStyles}
                              options={subdivideTargetTypeList}
                              placeholder={t('Select')}
                              onChange={(event) => onChangeTargetSelection(event)}
                              isSearchable={true}
                              value={subdivideTargetTypeList.filter(function (option) {
                                return option.name === `${exceptionPolicyData.SubDivideTargetType}`;
                              })}
                              isDisabled={disableField}
                            />
                          </>
                          : <></>
                        }
                        {FYThresholdfield === true ?
                          <>
                            <label className="col-md-6 mt-2 text-left" style={{ paddingBottom: "10px" }}>{t('ExceptionPolicy.Rebate$/%/LS')}<span className={classes.mandatory}>*</span></label>
                            <Form.Control style={{ width: "100px" }} className={`${classes['Form-Input-control']}`} as="select" disabled={disableField} value={exceptionPolicyData.RebateFor} name="Rebate_Value"
                              onChange={(Event) => OnChangeRebateFor(Event)}>
                              <option>Select</option>
                              <option>%</option>
                              <option>$</option>
                              <option>LS</option>

                            </Form.Control>
                          </>

                          : <></>
                        }


                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Modal show={showApprovedModal} size="bg"
              centered>
              <Modal.Header>
                <Modal.Title>{t('Rebates.ApprovalComments')}</Modal.Title>
              </Modal.Header>
              <Modal.Body style={{ "textAlign": 'center', fontFamily: 'Segoe UI' }} >
                <Form.Control className={classes['Textarea-width']} as="textarea" rows={2} value={commentsForApproved} name="commentsForApproved"
                  onChange={(e) => { setCommentsForApproved(e.target.value) }} />
              </Modal.Body>
              <Modal.Footer>
                <Button size='sm' style={{ background: 'buttonface', color: 'black', borderColor: 'black',width: "50px" }} onClick={() => showApprovedDialog('showApprovedDialog')}>
                  {t('Cancel')}
                </Button>
                <Button size='sm' style={{ background: 'buttonface', color: 'black', borderColor: 'black',width: "50px" }} onClick={() => showApprovedDialog('proceed')}>
                  {t('Submit')}
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal show={showWorkflowAlert}
              size="sm"
              aria-labelledby="contained-modal-title-vcenter"
              centered>
              <Modal.Body style={{ "textAlign": 'center' }}>{t('Accruals.WorkflowAlert')}</Modal.Body>
              <Modal.Footer>
                <Button size='sm' style={{ background: '#eff1f9', color: 'black', borderColor: 'black',width: "30px" }} onClick={(e) => workflowAlert(e, 'close')}>
                  {t('Ok')}
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal show={showDeniedModal} size="bg"
              centered>
              <Modal.Header>
                <Modal.Title>{t('Rebates.DeniedComments')}</Modal.Title>
              </Modal.Header>
              <Modal.Body style={{ "textAlign": 'center', fontFamily: 'Segoe UI' }} >
                <Form.Control className={classes['Textarea-width']} as="textarea" rows={2} value={commentsForDenied} name="commentsForDenied"
                  onChange={(e) => { setCommentsForDenied(e.target.value) }} />
              </Modal.Body>
              <Modal.Footer>
                <Button size='sm' style={{ background: 'buttonface', color: 'black', borderColor: 'black',width: "50px" }} onClick={() => showDeniedDialog('showDeniedDialog')}>
                  {t('Cancel')}
                </Button>
                <Button size='sm' style={{ background: 'buttonface', color: 'black', borderColor: 'black',width: "50px" }} onClick={() => showDeniedDialog('proceed')}>
                  {t('Submit')}
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal show={showSubmitModal} size="bg"
              centered>
              <Modal.Header>
                <Modal.Title>{t('Rebates.SubmitComments')}</Modal.Title>
              </Modal.Header>
              <Modal.Body style={{ "textAlign": 'center' }} >
                <Form.Control className={classes['Textarea-width']} as="textarea" rows={2} value={commentsForSubmit} name="commentsForSubmit"
                  onChange={(e) => { setCommentsForSubmit(e.target.value) }} />
              </Modal.Body>
              <Modal.Footer>
                <Button size='sm' style={{ background: 'buttonface', color: 'black', borderColor: 'black',width: "50px" }} onClick={(event) => showSubmitDialog(event, 'showSubmitDialog')}>
                  {t('Cancel')}
                </Button>
                <Button size='sm' style={{ background: 'buttonface', color: 'black', borderColor: 'black',width: "50px" }} onClick={(event) => showSubmitDialog(event, 'proceed')}>
                  {t('Submit')}
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
          <div className="col-sm-4">
            <div className="row">
              <div className="col-lg-12 pr-lg-2">
                <div className="card text-center card_border">
                  <div className="card-body" style={{ minHeight: "135px" }} >
                    <div className="container">
                      <div className="row">
                        <label className="col-md-6 text-left">{t('DataImport.CreatedBy')}</label>
                        <input disabled={true} name="CreatedBy" type="text" className="col-md-6 mb-2 textbox-padding form-control" value={exceptionPolicyData.CreatedUser} />

                        <label className="col-md-6 mt-2 text-left">{t('DataImport.CreatedDate')}</label>
                        <input type="text" className="col-md-6 textbox-padding datetimepicker-input form-control" data-target="#reservationdate" disabled={true}
                          name="createdDate" value={exceptionPolicyData.CreatedDate} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-1">
          {
            showExceptionTypeListselection == 'AccrualException' ?
              <div className="col-lg-12  pr-lg-2 mt-3">
                <div className="card text-center card_border">
                  <div className="card-header chart-grid__header">
                    <div className="row">
                      <div className="col-md-12 text-left">
                        {t('ExceptionPolicy.AccuralsExceptionPage')}
                      </div>
                    </div>
                  </div>
                  <div className="card-body" style={{ minHeight: "60vh" }}>
                    <div className="container">
                      <div className="table-responsive">
                        <table className="table table-bordered table-head-fixed text-nowrap table-bordered table-striped" style={{ width: "850px", marginBottom: "170px" }}>
                          <thead className="thead-light">
                            <tr className='text-left'>
                              <th>{t('ExceptionPolicy.Category')}</th>
                              <th>{t('ExceptionPolicy.SubCategory')}</th>
                              <th>{t('ExceptionPolicy.Operator')}</th>
                              <th>{t('ExceptionPolicy.Value')}</th>
                              <th>{t('ExceptionPolicy.And/Or')}</th>
                              {exceptionPolicyData.StatusLabel == 'New' || exceptionPolicyData.StatusLabel == undefined ? <th>{t('')}</th> : ''}
                            </tr>
                          </thead>
                          <tbody>
                            {
                              ExceptionValueList.map((item, index) => (
                                <tr>
                                
                                  <td>
                                    <Select
                                      options={CategoryValues}
                                      placeholder={t('Select')}
                                      value={CategoryValues.filter(function (option) {
                                        return option.Name == `${item.Category}`;
                                      })}
                                 
                                      onChange={(event) => OnCategoryChange(event, index)}
                                      isSearchable={true}
                                     
                                      isDisabled={index == 0 ? true : disableField}
                                      styles={customStyles}
                                    /></td>
                                  <td>
                                    <Select
                                      options={item?.SubCategory?.filter((x) => x.dropdownState.selected == false)}
                                      placeholder={t('Select')}
                                      value={SubCategoryValues.filter(function (option) {
                                     
                                        return (option.value == `${item.ExceptionDataId}`);
                                      })}
                                  
                                      onChange={(event) => OnSubCategoryChange(event, index)}
                                      isSearchable={true}

                                      isDisabled={disableField}
                                      styles={customStyles}
                                    /></td>

                                  <td><Form.Control style={{ width: "100px" }}
                                    onChange={(event) => OnOperatorChange(event.target.value, index)}
                                    className={`${classes['Form-Input-control']}`} as="select" value={item.Operator} name="Operator"
                                    disabled={disableField}
                                  >
                                    <option>{t('Select')}</option>
                                    <option>Equals</option>
                                    <option>Does not equal</option>
                                  </Form.Control></td>

                                  <td>
                                    <button className="btn  btn-update btn-sm ml-2" onClick={(event) => ClickValuePopup(item, index)} style={{width: "50px"}}>{t('Select')}</button>
                                    <span style={{ marginLeft: "20px" }}><a href='javascript:void(0)' onClick={(event) => ClickValueCountPopup(item, index)}>
                                      <label style={{ paddingTop: "5px" }}>({(item.ValueListString.length)})</label></a></span>
                                  </td>


                                  <td>
                                    {(index != ExceptionValueList.length - 1) ?
                                      <div className={classes['gridwidth']} style={{ margin: "5px" }}>
                                        <Form.Control className={`${classes['Form-Input-control']}`} as="select" disabled={disableField} autoComplete="off" style={{ width: "80px", margin: "auto" }} value={item.Condition}
                                          onChange={(event) => OnConditionChange(event.target.value, index)} >
                                          <option value="AND">{t('ExceptionPolicy.And')}</option>
                                          <option value="OR">{t('ExceptionPolicy.OR')}</option>
                                        </Form.Control>
                                      </div>
                                      :
                                      ''
                                    }
                                  </td>
                                 
                                  {exceptionPolicyData.StatusLabel == 'New' || exceptionPolicyData.StatusLabel == undefined ?
                                    <td>
                                      <div className={classes['gridwidth']} style={{ margin: "5px" }}>
                                        {disableField == false ? <>
                                          {ExceptionValueList.length - 1 === index ? <a onClick={() => AddVolume(index)} className="btn btn-outline-dark  btn-sm">{t('ExceptionPolicy.ADD')}</a> : null}
                                          {index !== 0 && ExceptionValueList.length !== 1 ? <a style={{ marginLeft: "5px" }} onClick={() => RemoveVolume(index)} className="btn btn-outline-dark  btn-sm">{t('Remove')}</a> : null}
                                        </> : ''}
                                      </div>
                                    </td>
                                    :
                                    ''}
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
              :
              <></>}
          {
            showExceptionTypeListselection == 'SubDivideThreshold' && showtargettable == true ? <>
              <div className="col-lg-12 pr-lg-2 mt-1">
                <div className="card text-center card_border">
                  <div className="card-header chart-grid__header">
                    <div className="row">
                      <div className="col-md-8 text-left">
                        {t('ExceptionPolicy.SubdivideThresholdPage')}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 mt-2" style={{ minHeight: "60vh" }}>
                    {showtargettable === true ?
                      <div className="container">
                        <div className="table-responsive">
                          <table className="table table-bordered table-head-fixed text-nowrap table-bordered table-striped" style={{ width: "700px" }}>
                            <thead className="thead-light">
                              {showtargetselection === "Quarterly%" ? <>
                                <tr className='text-left'>
                                  <th>{t('ExceptionPolicy.Quarterly')}</th>
                                  <th>{`${t('ExceptionPolicy.Operator')}`}</th>
                                  <th>{`${t('ExceptionPolicy.Value')}`}</th>
                                </tr>
                                <tr>
                                  <td>
                                    <Form.Control className={`${classes['Form-Input-control']}`} as="select" disabled={disableField} autoComplete="off" style={{ width: "80px", margin: "auto" }} onChange={(event) => OnSubDividePeriodChange(event)} value={subdivideList.Period} >
                                      <option>{t('Select')}</option>
                                      <option>Q1</option>
                                      <option>Q2</option>
                                      <option>Q3</option>
                                      <option>Q4</option>
                                    </Form.Control>
                                  </td>
                                  <td>
                                    <Form.Control className={`${classes['Form-Input-control']}`} as="select" disabled={disableField} autoComplete="off" style={{ width: "80px", margin: "auto" }} onChange={(event) => OnSubDivideOperatorChange(event)} value={subdivideList.Operator} >
                                      <option>{t('Select')}</option>
                                      <option>&gt;</option>
                                      <option>&ge;</option>
                                    </Form.Control>
                                  </td>
                                  <td><Form.Control style={{ width: "100px", display: "inline-block" }} disabled={disableField} className={`${classes['Form-Input-control']}`} name="Value" onChange={(event) => OnSubDivideValueChange(event)} value={subdivideList.Value}></Form.Control>{"%"}</td>
                                </tr>

                              </> : ""}
                              {showtargetselection === "HalfYearly%" ? <>
                                <tr className='text-left'>
                                  <th style={{ width: "20%" }}>{t('ExceptionPolicy.HalfYearly')}</th>
                                  <th>{`${t('ExceptionPolicy.Operator')}`}</th>
                                  <th>{`${t('ExceptionPolicy.Value')}`}</th>
                                </tr>
                                <>


                                  <tr>
                                    <td>
                                      <Form.Control className={`${classes['Form-Input-control']}`} as="select" disabled={disableField} autoComplete="off" style={{ width: "80px", margin: "auto" }} onChange={(event) => OnSubDividePeriodChange(event)} value={subdivideList.Period} >
                                        <option>{t('Select')}</option>
                                        <option>H1</option>
                                        <option>H2</option>
                                      </Form.Control>
                                    </td>
                                    <td>
                                      <Form.Control className={`${classes['Form-Input-control']}`} as="select" disabled={disableField} autoComplete="off" style={{ width: "80px", margin: "auto" }} onChange={(event) => OnSubDivideOperatorChange(event)} value={subdivideList.Operator} >
                                        <option>{t('Select')}</option>
                                        <option>&gt;</option>
                                        <option>&ge;</option>
                                      </Form.Control>
                                    </td>
                                    <td><Form.Control style={{ width: "100px", display: "inline-block" }} className={`${classes['Form-Input-control']}`} disabled={disableField} name="Value" onChange={(event) => OnSubDivideValueChange(event)} value={subdivideList.Value}></Form.Control>{"%"}</td>
                                  </tr>
                                </>
                              </> : ""}


                              {showtargetselection === "Monthly%" ? <>
                                <tr className='text-left'>
                                  <th>{t('ExceptionPolicy.Monthly')}</th>
                                  <th>{`${t('ExceptionPolicy.Operator')}`}</th>
                                  <th>{`${t('ExceptionPolicy.Value')}`}</th>
                                </tr>

                                <tr>
                                  <td>
                                    <Form.Control className={`${classes['Form-Input-control']}`} as="select" autoComplete="off" style={{ width: "80px", margin: "auto" }} disabled={disableField} onChange={(event) => OnSubDividePeriodChange(event)} value={subdivideList.Period} >
                                      <option>{t('Select')}</option>
                                      <option>M1</option>
                                      <option>M2</option>
                                      <option>M3</option>
                                      <option>M4</option>
                                      <option>M5</option>
                                      <option>M6</option>
                                      <option>M8</option>
                                      <option>M9</option>
                                      <option>M10</option>
                                      <option>M11</option>
                                      <option>M12</option>
                                    </Form.Control>
                                  </td>
                                  <td>
                                    <Form.Control className={`${classes['Form-Input-control']}`} as="select" disabled={disableField} autoComplete="off" style={{ width: "80px", margin: "auto" }} onChange={(event) => OnSubDivideOperatorChange(event)} value={subdivideList.Operator} >
                                      <option>{t('Select')}</option>
                                      <option>&gt;</option>
                                      <option>&ge;</option>
                                    </Form.Control>
                                  </td>
                                  <td>     <Form.Control className={`${classes['Form-Input-control']}`} autoComplete="off" style={{ width: "100px", display: "inline-block" }} disabled={disableField} value={subdivideList.Value} name="Value"
                                    onChange={(Event) => OnSubDivideValueChange(Event)} onKeyPress={(e) => !/^(\d)*(\.)?([0-9]{1})?$/.test(e.key) && e.preventDefault()} />{"%"}
                                  </td>
                                </tr>

                              </> : ""}
                            </thead>
                            <tbody>

                            </tbody>

                          </table>
                        </div>
                        <div style={{ paddingTop: '10px', paddingLeft: '47px', paddingBottom: '7px' }}>
                          {ExceptionValueList.length == 0 && showAddButton == true ?
                            <button className="btn btn-sm btn-update" onClick={() => add('ADD')} style={{width: "35px"}}>{t('ExceptionPolicy.ADD')}</button>
                            : ''}
                        </div>
                      </div>

                      :
                      <></>
                    }
                    {addCategory === true && ExceptionValueList.length != 0 ? <>
                      <div className="card-body">
                        <div className="container">
                          <div className="table-responsive">
                            <table className="table table-bordered table-head-fixed text-nowrap table-striped" style={{ width: "850px", marginBottom: "170px" }}>
                              <thead className="thead-light">
                                <tr className='text-left'>
                                  <th>{t('ExceptionPolicy.Category')}</th>
                                  <th>{t('ExceptionPolicy.SubCategory')}</th>
                                  <th>{t('ExceptionPolicy.Operator')}</th>
                                  <th>{t('ExceptionPolicy.Value')}</th>
                                  <th>{t('ExceptionPolicy.And/Or')}</th>
                                  {exceptionPolicyData.StatusLabel == 'New' || exceptionPolicyData.StatusLabel == undefined ? <th>{t('')}</th> : ''}
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  ExceptionValueList.map((item, index) => (
                                    <tr>
                                  
                                      <td>
                                        <Select
                                          options={CategoryValues}
                                          placeholder={t('Select')}
                                          value={CategoryValues.filter(function (option) {
                                            return option.Name == `${item.Category}`;
                                          })}
                                     
                                          onChange={(event) => OnCategoryChange(event, index)}
                                          isSearchable={true}
                                          styles={customStyles}
                                          isDisabled={disableField}
                                        /></td>
                                      <td>
                                        <Select
                                          options={item?.SubCategory?.filter((x) => x.dropdownState.selected == false)}
                                          placeholder={t('Select')}
                                          styles={customStyles}
                                          value={SubCategoryValues.filter(function (option) {
                                            
                                            return option.value == `${item.ExceptionDataId}`;
                                          })}
                                         
                                          onChange={(event) => OnSubCategoryChange(event, index)}
                                          isSearchable={true}
                                          isDisabled={disableField}
                                        /></td>
                                      <td><Form.Control style={{ width: "100px" }}
                                        onChange={(event) => OnOperatorChange(event.target.value, index)}
                                        className={`${classes['Form-Input-control']}`} as="select" value={item.Operator} disabled={disableField} name="Operator"
                                      >
                                        <option>{t('Select')}</option>
                                        <option>Equals</option>
                                        <option>Does not equal</option>
                                      </Form.Control></td>
                                      <td>
                                        <button className="btn  btn-update btn-sm ml-2" onClick={(event) => ClickValuePopup(item, index)} style={{width: "50px"}}>{t('Select')}</button>
                                        <span style={{ marginLeft: "20px" }}><a href='javascript:void(0)' onClick={(event) => ClickValueCountPopup(item, index)}>
                                          <label style={{ paddingTop: "5px" }}>({(item.ValueListString.length)})</label></a></span>
                                      </td>
                                      <td>
                                        {(index != ExceptionValueList.length - 1) ?
                                          <div className={classes['gridwidth']} style={{ margin: "5px" }}>
                                            <Form.Control className={`${classes['Form-Input-control']}`} as="select" disabled={disableField} autoComplete="off" style={{ width: "80px", margin: "auto" }} value={item.Condition}
                                              onChange={(event) => OnConditionChange(event.target.value, index)} >
                                              <option>{t('ExceptionPolicy.And')}</option>
                                              <option>{t('ExceptionPolicy.OR')}</option>
                                            </Form.Control>
                                          </div>
                                          :
                                          ''
                                        }
                                      </td>
                                      {exceptionPolicyData.StatusLabel == 'New' || exceptionPolicyData.StatusLabel == undefined ?
                                        <td>
                                          <div className={classes['gridwidth']} style={{ margin: "5px" }}>
                                            {ExceptionValueList.length - 1 === index ? <a onClick={() => AddVolume(index)} className="btn btn-outline-dark  btn-sm">{t('ExceptionPolicy.ADD')}</a> : null}
                                            {ExceptionValueList.length !== 0 ? <a style={{ marginLeft: "5px" }} onClick={() => RemoveVolume(index)} className="btn btn-outline-dark  btn-sm">{t('Remove')}</a> : ''}

                                          </div>
                                        </td>
                                        :
                                        ''
                                      }
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>

                        </div>
                      </div>
                    </> : ""}
                  </div>
                 

                </div>
              </div>

            </>
              :
              <></>}

          {
            showExceptionTypeListselection == 'FYThreshold' ?
              <div className="col-lg-12 pr-lg-2 mt-3">
                <div className="card text-center card_border">
                  <div className="card-header chart-grid__header">
                    <div className="row">
                      <div className="col-md-8 text-left">
                        {t('ExceptionPolicy.FYThresholdPage')}
                      </div>
                    </div>
                  </div>
                  <div className="card-body" style={{ minHeight: "60vh" }}>
                    <div className="container">
                      <div className="table-responsive">
                        <table className="table table-bordered table-head-fixed text-nowrap table-bordered table-striped table-responsive-sm" style={{ width: "500px" }}>
                          <thead className="thead-light">
                            <tr className='text-left'>
                              <th>{t('ExceptionPolicy.Operator')}</th>
                              <th>{t('ExceptionPolicy.FyThresholdValue')}</th>
                              <th>{t('ExceptionPolicy.FYPayout')}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              <tr >
                               
                                <td>
                                  <Form.Control className={`${classes['Form-Input-control']}`} as="select" disabled={disableField} autoComplete="off" style={{ width: "80px", margin: "auto" }} value={fyThresholdList.Operator} onChange={(event) => OnFYThresholdOperatorChange(event)} >
                                    <option>{t('Select')}</option>
                                    <option>&gt;</option>
                                    <option>&ge;</option>
                                  </Form.Control>
                                </td>
                                
                                <td>
                                  <Form.Control className={`${classes['Form-Input-control']} `} autoComplete="off" disabled={disableField} style={{ width: "100px", display: "inline-block" }} value={fyThresholdList.ThresholdValue} name="ThresholdValue"
                                    onChange={(Event) => OnFYThresholdValueChange(Event)} onKeyPress={(e) => !/^(\d)*(\.)?([0-9]{1})?$/.test(e.key) && e.preventDefault()} />
                                  {"%"}
                                </td>
                                {/* </>
                                } */}

                                 <td>     <Form.Control className={`${classes['Form-Input-control']}`} autoComplete="off" style={{ width: "100px" }} disabled={disableField} value={fyThresholdList.PayoutValue} name="PayoutValue"
                                  onChange={(Event) => OnFYThresholdPayoutChange(Event)} onKeyPress={(e) => !/^(\d)*(\.)?([0-9]{1})?$/.test(e.key) && e.preventDefault()} />
                                </td>
                              </tr>
                            }
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {ExceptionValueList.length == 0 && showAddButton == true ?
                      <div style={{ paddingTop: '21px', paddingLeft: '47px', paddingBottom: '7px' }}>
                        <button disabled={disableField} className="btn btn-sm btn-update" onClick={() => add('ADD')} style={{width: "40px"}}>{t('ExceptionPolicy.ADD')}</button>
                      </div>
                      :
                      <></>
                    }
                    {addCategory === true && ExceptionValueList.length != 0 ? <>
                      <div className="card-body" style={{ minHeight: "27vh" }}>
                        <div className="container">
                          <div className="table-responsive">
                            <table className="table table-bordered table-head-fixed text-nowrap table-bordered table-striped table-responsive-sm" style={{ width: "850px", marginBottom: "170px" }}>
                              <thead className="thead-light">
                                <tr className='text-left'>
                                  <th>{t('ExceptionPolicy.Category')}</th>
                                  <th>{t('ExceptionPolicy.SubCategory')}</th>
                                  <th>{t('ExceptionPolicy.Operator')}</th>
                                  <th>{t('ExceptionPolicy.Value')}</th>
                                  <th>{t('ExceptionPolicy.And/Or')}</th>
                                  {exceptionPolicyData.StatusLabel == 'New' || exceptionPolicyData.StatusLabel == undefined ? <th>{t('')}</th> : ''}
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  ExceptionValueList.map((item, index) => (
                                    <tr >
                                   
                                      <td>
                                        <Select
                                          options={CategoryValues}
                                          placeholder={t('Select')}
                                          value={CategoryValues.filter(function (option) {
                                            return option.Name == `${item.Category}`;
                                          })}
                                     
                                          onChange={(event) => OnCategoryChange(event, index)}
                                          isSearchable={true}
                                          isDisabled={disableField}
                                          styles={customStyles}
                                        /></td>
                                      <td>
                                        <Select
                                          options={item?.SubCategory?.filter((x) => x.dropdownState.selected == false)}
                                          placeholder={t('Select')}
                                          value={SubCategoryValues.filter(function (option) {
                                        

                                            return option.value == `${item.ExceptionDataId}`;
                                          })}
                                    
                                          onChange={(event) => OnSubCategoryChange(event, index)}
                                          isSearchable={true}
                                          isDisabled={disableField}
                                          styles={customStyles}
                                        /></td>
                                      <td><Form.Control style={{ width: "100px" }}
                                        onChange={(event) => OnOperatorChange(event.target.value, index)}
                                        className={`${classes['Form-Input-control']}`} as="select" disabled={disableField} value={item.Operator} name="Operator"
                                      >
                                        <option>{t('Select')}</option>
                                        <option>Equals</option>
                                        <option>Does not equal</option>
                                      </Form.Control></td>
                                      <td>
                                        <button className="btn  btn-update btn-sm ml-2" onClick={(event) => ClickValuePopup(item, index)} style={{width: "50px"}}>{t('Select')}</button>
                                        <span style={{ marginLeft: "20px" }}><a href='javascript:void(0)' onClick={(event) => ClickValueCountPopup(item, index)}>
                                          <label style={{ paddingTop: "5px" }}>({(item.ValueListString.length)})</label></a></span>
                                      </td>
                                      <td>
                                        {(index != ExceptionValueList.length - 1) ?

                                          <div className={classes['gridwidth']} style={{ margin: "5px" }}>
                                            <Form.Control className={`${classes['Form-Input-control']}`} as="select" disabled={disableField} autoComplete="off" style={{ width: "80px", margin: "auto" }} value={item.Condition}
                                              onChange={(event) => OnConditionChange(event.target.value, index)} >
                                              <option value="AND">{t('ExceptionPolicy.And')}</option>
                                              <option value="OR">{t('ExceptionPolicy.OR')}</option>
                                            </Form.Control>
                                          </div>
                                          :
                                          ''
                                        }
                                      </td>
                                      {exceptionPolicyData.StatusLabel == 'New' || exceptionPolicyData.StatusLabel == undefined ?
                                        <td>
                                          <div className={classes['gridwidth']} disabled={disableField} style={{ margin: "5px" }}>
                                            {ExceptionValueList.length - 1 === index ? <a onClick={() => AddVolume(index)} className="btn btn-outline-dark  btn-sm">{t('ExceptionPolicy.ADD')}</a> : null}
                                            {ExceptionValueList.length !== 0 ? <a style={{ marginLeft: "5px" }} onClick={() => RemoveVolume(index)} className="btn btn-outline-dark  btn-sm">{t('Remove')}</a> : null}
                                          </div>
                                        </td>
                                        :
                                        ''
                                      }
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>

                        </div>
                      </div>
                    </> : ""}
                  </div>

                
                </div>

              </div>
              :
              <></>}
        </div>
        {
          showProductPopup === true ?
            <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, padding: "0" }}>
              <div className="container" style={{ padding: "0" }}>
                <div className="row">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">
                        <div className="row">
                          <div className="col-md-10 text-left">
                            {t('Rebates.ProductListing')}
                          </div>
                          <div className="col-md-2">
                            <button className="btn btn-outline-dark  btn-sm ml-1" onClick={() => ClickValuePopupClose("Close", "Product", "All")} type="submit" style={{width: "45px"}}>{t('Close')}</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-1">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-lg-12 pr-lg-2">
                        <div className="card text-center card_border">
                          <div className="card-header chart-grid__header">
                          </div>
                          <div style={{ minHeight: "66vh" }}>
                            <div className="container">
                              <div className='col-md-12'>
                                {clickProduct === true ?
                                  <ProductTreeDialog
                                    show={showModal}
                                    onOkClick={UpdateProducts}
                                    onHide={() => CloseProductPicker}
                                    dataByHeirarchy={dualListBoxOptionsByHeirarchy}
                                    rebatelinestatus={rebatelinestatus}
                                    dialogType="Product"
                                    SelectedData={FilteredProducts}
                                   
                                    selectedType={selectedType}
                                    dualListBoxOptionsByAttribute={dualListBoxOptionsByAttribute}
                                  >
                                    <div className={classes['Radiobutton-header']}>
                                      <div className={classes['Radiobutton-internal-div']} >
                                        <input type="radio" checked={selectedType === 'Attribute'}
                                          value="Attribute" onChange={SelectedProductTypeHandler}
                                          name="product" className={classes['radio-button-Right form-control']} /> {t('Rebates.Attribute')}
                                      

                                      </div>
                                    </div>
                                  </ProductTreeDialog>
                                  : ''
                                }

                              </div>

                            </div>




                          </div>

                        </div>
                      </div>

                    </div>
                  </div>
                </div>


              </div>
            </div>
            :
            ""
        }

        {
          showCategoryPopup === true ?
            <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, padding: "0" }}>
              <div className="container" style={{ padding: "0" }}>
                <div className="row">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">
                        <div className="row">
                          <div className="col-md-12 text-left">
                            {t('Policies.AttributeListing')}

                         
                            <button className="btn btn-outline-dark  btn-sm ml-1" onClick={() => ClickValuePopupCancel()} type="submit" style={{ float: "right",width: "50px" }}>{t('Cancel')} </button>
                            <button className="btn btn-outline-dark  btn-sm ml-1" onClick={(event) => ClickValuePopupClose('Close', keyValue, "All")} type="submit" style={{ float: "right" ,width: "40px"}}>{t('ADD')}</button>

                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
                <div className="row mt-1">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-lg-12 pr-lg-2">
                        <div className="card text-center card_border">
                          <div className="card-header chart-grid__header">
                          </div>
                          <div className='scrollbar-class'>
                            <div className="container">
                              <div style={{ paddingTop: "5px" }}>
                                <input type="text" className='form-control form-control-user' placeholder={`${t('SearchFor')} ${"Categories"}`} onChange={(event) => attributeSearch(event)} />
                              </div>
                              <div className='col-md-12'>
                                <table className="table table-bordered text-left mt-2 table-responsive-sm " id="tableA">
                                  <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                                    <tr>
                                      <th className="tableheader"><input checked={attbCheckAll} type="checkbox" name="attribute-check" id="option-all-customer" onChange={checkAllAttribute} /><label></label></th>
                                      <th id="Attribute1" >
                                        {keyValue}
                                      </th>
                                    </tr>
                                  </thead>
                                  {keyValue === "Category1" ?
                                    <tbody>
                                      {attribute1Values.map((i, index) => (
                                        <tr>
                                          <td><input type="checkbox" checked={i.Selected == null || i.Selected == false ? false : true} value={i.Id} id={i.Id} onChange={(event) => OnChangeHandlerAttribute(event, i.label, i.Id, "Cat-I")} disabled={disableField}></input></td>
                                          <td>
                                            {i.Name}
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                    : ""}
                                  {keyValue === "Category2" ?
                                    <tbody>
                                      {attribute2Values.map((i, index) => (
                                        <tr>
                                          <td><input type="checkbox" checked={i.Selected == null || i.Selected == false ? false : true} value={i.Id} id={i.Id} onChange={(event) => OnChangeHandlerAttribute(event, i.label, i.Id, "Cat-II")} disabled={disableField}></input></td>
                                          <td>
                                            {i.Name}
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                    : ""}
                                  {keyValue === "Category3" ?
                                    <tbody>
                                      {attribute3Values.map((i, index) => (
                                        <tr>
                                          <td><input type="checkbox" checked={i.Selected == null || i.Selected == false ? false : true} value={i.Id} id={i.Id} onChange={(event) => OnChangeHandlerAttribute(event, i.label, i.Id, "Cat-III")} disabled={disableField}></input></td>
                                          <td>
                                            {i.Name}
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                    : ""}
                                  {keyValue === "Category4" ?
                                    <tbody>
                                      {attribute4Values.map((i, index) => (
                                        <tr>
                                          <td><input type="checkbox" checked={i.Selected == null || i.Selected == false ? false : true} value={i.Id} id={i.Id} onChange={(event) => OnChangeHandlerAttribute(event, i.label, i.Id, "Cat-IV")} disabled={disableField} ></input></td>
                                          <td>
                                            {i.Name}
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                    : ""
                                  }
                                  {keyValue === "Category5" ?
                                    <tbody>
                                      {attribute5Values.map((i, index) => (
                                        <tr>
                                          <td><input type="checkbox" checked={i.Selected == null || i.Selected == false ? false : true} value={i.Id} id={i.Id} onChange={(event) => OnChangeHandlerAttribute(event, i.label, i.Id, "Cat-V")} disabled={disableField}></input></td>
                                          <td>
                                            {i.Name}
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                    : ""
                                  }
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            :
            ""
        }


        {

          showCustomerPopup === true ?
            <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, marginRight: "10px", padding: "0" }}>
              <div className="container" style={{ padding: "0" }}>
                <div className="row">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">
                        <div className="row">
                          <div className="col-md-12 text-left">
                            {t('Rebates.CustomerListing')}
                          
                            <button className="btn btn-outline-dark  btn-sm ml-1" onClick={() => ClickValuePopupClose("Close", "Customer", "All")} type="submit" style={{ float: "right",width: "45px" }}>{t('Close')}</button>
                            <button style={{ float: "right",width: "35px"}} className='btn btn-outline-dark  btn-sm ml-1' onClick={okCustomer} >{t('Add')}</button>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

                <div className="row mt-1" id="Customer">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">

                      </div>

                      <div className='scrollbar-class'>

                        <div className="container">
                          <div>

                            <input type="text" className='form-control form-control-user' value={customerSearch} onChange={(event) => SearchCustomer(event)} placeholder={`${t('Search')} ${t('Rebates.Customer')}`} />
                          </div>
                          <table className="table table-bordered text-left mt-2 table-responsive-sm" id="Customer">
                            <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                              <tr>
                                <th>
                                  <input checked={customercheckAll} type="checkbox" disabled={disableField} id="option-all-customer" onChange={checkAllCustomer} /><label></label>
                                </th>
                                <th id="Name" style={{ backgroundColor: (filterValueCustomer.Name ? '#6cae55' : ''), width: "110px" }}>{t('Rebates.CustomerCode')} <span className='fa fa-filter' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumnCustomer('Name')}></span></th>
                                <th id="Label" style={{ backgroundColor: (filterValueCustomer.Label ? '#6cae55' : ''), maxWidth: "110px" }}>{t('Rebates.CustomerName')} <span className='fa fa-filter' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumnCustomer('Label')}></span></th>
                                <th id="Station" style={{ backgroundColor: (filterValueCustomer.Station ? '#6cae55' : ''), maxWidth: "110px" }}>{t('Rebates.Station')}	 <span className='fa fa-filter' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumnCustomer('Station')}></span></th>
                                <th id="State" style={{ backgroundColor: (filterValueCustomer.State ? '#6cae55' : ''), maxWidth: "110px" }}>{t('Rebates.State')}	 <span className='fa fa-filter' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumnCustomer('State')}></span></th>
                                <th id="Zone" style={{ backgroundColor: (filterValueCustomer.Zone ? '#6cae55' : ''), maxWidth: "110px" }}>{t('Rebates.Zone')}  <span className='fa fa-filter' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumnCustomer('Zone')}></span></th>
                              </tr>
                            </thead>
                            <thead>
                              {
                                CustomerValues.map((cslist, index) => (

                                  <tr onClick={(event) => handleCustSelection(event, cslist.label, cslist.Id, cslist.State)}>
                                    <td style={{ width: "30px" }}>

                                      {disableField == true ? <>
                                        <input type="checkbox"
                                          disabled={true}
                                          className={disabledClass}
                                          checked={cslist.Selected == null || cslist.Selected == false ? false : true}
                                          name="customer-check" value={cslist.Id} id={cslist.Id} onChange={(event) => handleCustomerChanges(event, cslist.label, cslist.Id, cslist.State)} />

                                      </> : <>
                                        <input type="checkbox"
                                          className={disabledClass}
                                          checked={cslist.Selected == null || cslist.Selected == false ? false : true} name="customer-check" value={cslist.Id} id={cslist.Id} onChange={(event) => handleCustomerChanges(event, cslist.label, cslist.Id, cslist.State)} />

                                      </>

                                      }</td>
                                    <td style={{ width: "90px" }}>
                                      <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                        title={cslist.Name}>{cslist.Name}</div>


                                    </td>
                                    <td style={{ width: "260px" }}>
                                      <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                        title={cslist.label}>{cslist.label}</div>


                                    </td>
                                    <td style={{ width: "100px" }}>
                                      <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                        title={cslist.Station}>{cslist.Station}</div>

                                    </td>
                                    <td style={{ width: "100px" }}>
                                      <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                        title={cslist.State}>{cslist.State}</div>

                                    </td>
                                    <td style={{ width: "100px" }}>
                                      <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                        title={cslist.Zone}>{cslist.Zone}</div>

                                    </td>
                                  </tr>
                                ))
                              }
                            </thead>
                          </table>


                        </div>

                      </div>
                    </div>
                  </div>

                </div>

              </div>
              {
                showCustomerfilterpopup === true ?
                  <div className='pop-up' style={{ left: divleft, top: divtop, width: "200px" }}>

                    {custFilterCol === 'Name' || custFilterCol === 'Label' || custFilterCol === 'Station' || custFilterCol === 'State' || custFilterCol === 'Zone' ?


                      <>
                        <div className="container">
                          <div className="row">
                            <b className="forgettext col-md-11 textbox-padding ml-2">{filtertextCust}</b>

                          </div>

                        </div>

                        <div className="container mt-2">
                          <div className="row">
                            <input type="text" placeholder="Search" value={filterSearchCust} onChange={(event) => UserfilterHandlerCustomer(event)} className='filtertextboxbackground col-md-11 textbox-padding ml-2 form-control' />
                          </div>

                          <div className="row contentoverflow mt-2">
                            <table className="filtertable table-responsive-sm">
                              <tbody>
                                <tr>
                                  <td><input type="checkbox" name="filter-option-all" id="option-all" onChange={checkAllFilterCustomer} /></td>
                                  <td>Select All</td>
                                </tr>
                                {
                                  filterCusDropDownData.map((item, index) => (

                                    <tr key={index}>

                                      <td><input id={item.Id} data-index="1" name="filter-option-all" checked={item.Selected} type="checkbox" onChange={(event) => OnCusdropdownChange(event, item.Id, item.Name)} /></td>

                                      <td>{item.Name}</td>

                                    </tr>


                                  ))
                                }

                              </tbody>
                            </table>

                          </div>
                        </div>
                      </>
                      :
                      <></>

                    }
                    <div style={{ paddingTop: '10px', paddingLeft: '33px', paddingBottom: '7px' }}>

                      <button className="btn btn-sm btn-update" style={{ width: "45px" }} onClick={() => CustFilterHandler('Apply')}>{t('Apply')}</button>
                      <button className="btn btn-sm btn-update" style={{ marginLeft: '10px', width: "45px" }} onClick={() => CustFilterHandler('Reset')}>{t('Reset')}</button>
                    </div>
                    <div>
                    </div>
                  </div>
                  :
                  <></>
              }
            </div>
            :
            ""
        }


        {
          showCustomerGroupPopup === true ?
            <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, marginRight: "10px", padding: "0" }}>
              <div className="container" style={{ padding: "0" }}>
                <div className="row">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">
                        <div className="row">
                          <div className="col-md-12 text-left">
                            {t('Rebates.GroupCustomerListing')}
                           
                            <button className="btn btn-outline-dark  btn-sm ml-1" onClick={() => ClickValuePopupClose("Close", "CustomerGroup", "All")} type="submit" style={{ float: "right" ,width: "45px"}}>{t('Close')}</button>
                            <button style={{ float: "right",width: "35px" }} className='btn btn-outline-dark  btn-sm ml-1' onClick={okCustomerGroup}>{t('Add')}</button>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

                <div className="row mt-1" id="CustomerGroup">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">

                      </div>

                      <div className='scrollbar-class'>

                        <div className="container">
                          <div>
                            <input type="text" className='form-control form-control-user' value={customerGroupSearch} onChange={(event) => SearchGroupCustomer(event)} placeholder={`${t('Search')} ${t('Rebates.GroupCustomer')}`} />
                          </div>
                          <table className="table table-bordered text-left mt-2 table-responsive-sm" id="CustomerGroup">
                            <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                              <tr>
                                <th>
                                  <input checked={customercheckAll} disabled={disableField} type="checkbox" id="option-all-customer" onChange={checkAllGroupCustomer} /><label></label>
                                </th>
                               
                                <th id="GroupLabel" style={{ backgroundColor: (filterValueGroupCustomer.label ? '#6cae55' : ''), maxWidth: "110px" }}>{`${t('Rebates.CustomerGroup')} ${t('Policies.Label')}`}<span className='fa fa-filter' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumnGroupCustomer('label')}></span></th>
                              </tr>
                            </thead>
                            <thead>
                              {
                                CustomerGroupValues.map((cslist, index) => (

                                  <tr>
                                    <td style={{ width: "30px" }}>


                                      <input type="checkbox"
                                        disabled={disableField}
                                     
                                        checked={cslist.Selected == null || cslist.Selected == false ? false : true}
                                        name="customer-check" value={cslist.Id} id={cslist.Id} onChange={(event) => OnChangeHandlerGroupCustomer(event, cslist.label, cslist.Id, cslist.State)} />

                                    </td>


                                    <td style={{ width: "260px" }}>
                                      <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                        title={cslist.label}>{cslist.label}</div>
                                    </td>

                                  </tr>

                                ))
                              }


                            </thead>
                          </table>
                        </div>

                      </div>
                    </div>
                  </div>
                  {
                    showCustomerGroupfilterpopup === true ?
                      <div className='pop-up' style={{ left: divleft, top: divtop, width: "200px" }}>

                        {custGroupFilterCol === 'label' ?
                          <>
                            <div className="container">
                              <div className="row">
                                <b className="forgettext col-md-11 textbox-padding ml-2">{filtertextCustGroup}</b>

                              </div>

                            </div>

                            <div className="container mt-2">
                              <div className="row">
                                <input type="text" placeholder="Search" value={filterSearchCustGroup} onChange={(event) => UserfilterHandlerCustomerGroup(event)} className='filtertextboxbackground col-md-11 textbox-padding ml-2' />
                              </div>

                              <div className="row contentoverflow mt-2">
                                <table className="filtertable table-responsive-sm">
                                  <tbody>
                                    <tr>
                                      <td><input type="checkbox" name="filter-option-all" id="option-all" onChange={checkAllFilterCustomerGroup} /></td>
                                      <td>Select All</td>
                                    </tr>
                                    {
                                      CustomerGroupValues.map((item, index) => (

                                        <tr key={index}>

                                          <td><input id={item.Id + item.label} data-index="1" name="filter-option-all" checked={item.Selected} type="checkbox" onChange={(event) => OnCustGroupdropdownChange(event, item.Id, item.label)} /></td>

                                          <td>{item.label}</td>

                                        </tr>


                                      ))
                                    }

                                  </tbody>
                                </table>

                              </div>
                            </div>
                          </>
                          :
                          <></>

                        }
                        <div style={{ paddingTop: '10px', paddingLeft: '33px', paddingBottom: '7px' }}>

                          <button className="btn btn-sm btn-update" style={{ width: "45px" }} onClick={() => filterHandlerCustomerGroup('Apply')}>{t('Apply')}</button>
                          <button className="btn btn-sm btn-update" style={{ marginLeft: '10px', width: "45px" }} onClick={() => filterHandlerCustomerGroup('Reset')}>{t('Reset')}</button>
                        </div>
                        <div>
                        </div>
                      </div>
                      :
                      <></>
                  }
                </div>
              </div>
            </div>
            :
            ""
        }

        {
          showSetlFreqPopup === true ?
            <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, padding: "0", height: "72vh" }}
            >
              <div className="container" style={{ padding: "0" }}>
                <div className="row">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">
                        <div className="row">
                          <div className="col-md-12 text-left">
                            {t('MassAction.SettlementsFreq')}
                           
                            <button className="btn btn-outline-dark  btn-sm ml-1" onClick={() => ClickValuePopupClose("Close", "SettlementFreq", "All")} type="submit" style={{ float: "right" ,width: "45px"}}>{t('Close')}</button>
                            <button className='btn btn-outline-dark  btn-sm ml-1' onClick={oksettlFreq} style={{ float: "right" ,width: "35px"}}>{t('Add')}</button>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

                <div className="row mt-1">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">

                      </div>
                      <div style={{ minHeight: "58vh" }}>
                        <div className="container">
                          <table className="table table-bordered text-left mt-2 table-responsive-sm " id="tableregion">
                            <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                              <tr>
                                <th style={{ width: "20px" }}>
                                  <input checked={settlFreqcheckAll} disabled={disableField} onChange={checkAllSettlFreqChange} type="checkbox" name="option-all-region" />
                                </th>

                                <th id="Product">
                                  {t('MassAction.SettlementsFreq')}
                                </th>

                              </tr>
                            </thead>
                          </table>
                          < div className='row' id="div_customer">
                            {
                              settlFreqValues.map((settlFreq) =>
                                <div className='col-md-3' style={{ padding: "5px;" }}>
                                  <input type="checkbox"
                                    disabled={disableField}
                                 
                                    checked={settlFreq.Selected}
                                    name="settlfreq-check" value={settlFreq.Id} id={settlFreq.Id} onChange={(event) => handleSettlFreqChanges(event, settlFreq.label, settlFreq.Id)} />
                                  &nbsp;<label>{settlFreq.label}</label>
                                </div>
                              )
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              
              </div>
            </div>
            :
            ""
        }

        {
          showRebateTypePopup === true ?
            <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, padding: "0", height: "72vh" }}
            >
              <div className="container" style={{ padding: "0" }}>
                <div className="row">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">
                        <div className="row">
                          <div className="col-md-12 text-left">
                            {t('MassAction.RebateType')}
                          
                            <button className="btn btn-outline-dark  btn-sm ml-1" onClick={() => ClickValuePopupClose("Close", "RebateType", "All")} type="submit" style={{ float: "right" ,width: "45px"}}>{t('Close')}</button>
                            <button className='btn btn-outline-dark  btn-sm ml-1' onClick={okRebateType} style={{ float: "right",width: "35px" }}>{t('Add')}</button>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

                <div className="row mt-1">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">

                      </div>
                      <div style={{ minHeight: "58vh" }}>
                        <div className="container">
                          <table className="table table-bordered text-left mt-2 table-responsive-sm" id="tableregion">
                            <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                              <tr>
                             

                              </tr>
                            </thead>
                          </table>
                          < div className='row' id="div_customer">
                            {
                              rebateTypValues.map((rebateType) =>
                                <div className='col-md-3' style={{ padding: "5px;" }}>
                                  <input type="checkbox"
                                    disabled={disableField}
                                   
                                    checked={rebateType.Selected}
                                    name="rebateType-check" value={rebateType.Id} id={rebateType.Id} onChange={(event) => handleRebateTypeChanges(event, rebateType.label, rebateType.Id)} />
                                  &nbsp;<label>{rebateType.label}</label>
                                </div>
                              )
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              
              </div>
            </div>
            :
            ""
        }

        {
          showSelectedProductpopup === true ?
            <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, padding: "0" }}>
              <div className="container" style={{ padding: "0" }}>
                <div className="row">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">
                        <div className="row">
                          <div className="col-md-10 text-left">
                            {t('Rebates.ProductListing')}
                          </div>
                          <div className="col-md-2">
                            <button className="btn btn-outline-dark  btn-sm ml-1" onClick={() => ClickValuePopupClose("Close", "Product", "Selected")} type="submit" style={{width: "45px"}}>{t('Close')}</button>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

                <div className="row mt-1">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-lg-12 pr-lg-2">
                        <div className="card text-center card_border">
                          <div className="card-header chart-grid__header">

                          </div>

                          <div className='scrollbar-class'>

                            <div className="container">
                              <div className='col-md-12'>

                                <table className="table table-bordered text-left mt-2 table-responsive-sm" id="tableA">
                                  <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                                    <tr>
                                      <th className="tableheader">

                                      </th>
                                      <th id="ProductId" >
                                        {t('Rebates.MaterialNo')}
                                      </th>
                                      <th id="Product" >
                                        {t('Rebates.ItemName')}
                                      </th>
                                      <th id="Attribute1" >
                                        Cat I
                                      </th>
                                      <th id="Attribute2" >
                                        Cat II
                                      </th>
                                      <th id="Attribute3" >
                                        Cat III
                                      </th>
                                      <th id="Attribute4" >
                                        Cat IV
                                      </th>


                                    </tr>
                                  </thead>
                                  <tbody>
                                    {dualListBoxOptionsByAttribute.filter((e) => e.Selected == true).map((listVal) =>
                                      <tr key={listVal.Id} >

                                        <td style={{ width: "30px" }}>

                                          {

                                            <input id={listVal.Id} disabled={true} checked={listVal.Selected} type="checkbox" name="chkBoxproduct" value={listVal.Id} />

                                          }

                                        </td>
                                        <td style={{ width: "100px" }}>
                                          <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right" title={listVal.Name}>{listVal.Name}</div>


                                        </td>
                                        <td style={{ width: "200px" }}>
                                          <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right" title={listVal.Label}>{listVal.Label}</div>


                                        </td>
                                        <td style={{ width: "100px" }}>
                                          <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right" title={listVal.ProductAttribute1Name}>{listVal.ProductAttribute1Name}</div>

                                        </td>
                                        <td style={{ width: "100px" }}>

                                          <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right" title={listVal.ProductAttribute2Name}>{listVal.ProductAttribute2Name}</div>

                                        </td>
                                        <td style={{ width: "100px" }}>

                                          <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right" title={listVal.ProductAttribute3Name}>{listVal.ProductAttribute3Name}</div>

                                        </td>
                                        <td style={{ width: "100px" }} >

                                          <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right" title={listVal.ProductAttribute4Name}>{listVal.ProductAttribute4Name}</div>


                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>


                              </div>
                            </div>
                          </div>

                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
            :
            ""
        }

        {
          showSelectedCategorypopup === true ?
            <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, padding: "0" }}>
              <div className="container" style={{ padding: "0" }}>
                <div className="row">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">
                        <div className="row">
                          <div className="col-md-12 text-left">
                            {t('Policies.AttributeListing')}
                          </div>
                          <div className="col-md-2">
                            <button className="btn btn-outline-dark  btn-sm ml-1" onClick={(event) => ClickValuePopupClose('Close', countKeyValue, "Selected")} type="submit"style={{width: "45px"}}>{t('Close')}</button>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

                <div className="row mt-1">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-lg-12 pr-lg-2">
                        <div className="card text-center card_border">
                          <div className="card-header chart-grid__header">
                          </div>
                          <div className='scrollbar-class'>
                            <div className="container">
                              <div className='col-md-12'>
                                <table className="table table-bordered text-left mt-2 table-responsive-sm" id="tableA">
                                  <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                                    <tr>
                                      <th className="tableheader"><input type="checkbox" disabled="true" ></input></th>
                                      <th id="Attribute1" >
                                        {countKeyValue}
                                      </th>
                                    </tr>
                                  </thead>
                                  {countKeyValue === "Category1" ?
                                    <tbody>
                                      {attribute1Values.filter((e) => e.Selected === true).map((i, index) => (
                                        <tr>
                                          <td>
                                            <input type="checkbox"
                                              checked={i.Selected == null || i.Selected == false ? false : true} name="customer-check" value={i.Id} id={i.Id} disabled="true" />
                                          </td>
                                          <td>
                                            {i.Name}
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                    : ""}
                                  {countKeyValue === "Category2" ?
                                    <tbody>
                                      {attribute2Values.filter((e) => e.Selected === true).map((i, index) => (
                                        <tr>
                                          <td>
                                            <input type="checkbox"
                                              checked={i.Selected == null || i.Selected == false ? false : true} name="customer-check" value={i.Id} id={i.Id} disabled="true" />
                                          </td>
                                          <td>
                                            {i.Name}
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                    : ""}
                                  {countKeyValue === "Category3" ?
                                    <tbody>
                                      {attribute3Values.filter((e) => e.Selected === true).map((i, index) => (
                                        <tr>
                                          <td>
                                            <input type="checkbox"
                                              checked={i.Selected == null || i.Selected == false ? false : true} name="customer-check" value={i.Id} id={i.Id} disabled="true" />
                                          </td>
                                          <td>
                                            {i.Name}
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                    : ""}
                                  {countKeyValue === "Category4" ?
                                    <tbody>
                                      {attribute4Values.filter((e) => e.Selected === true).map((i, index) => (
                                        <tr>
                                          <td>
                                            <input type="checkbox"
                                              checked={i.Selected == null || i.Selected == false ? false : true} name="customer-check" value={i.Id} id={i.Id} disabled="true" />
                                          </td>
                                          <td>
                                            {i.Name}
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                    : ""
                                  }
                                  {countKeyValue === "Category5" ?
                                    <tbody>
                                      {attribute5Values.filter((e) => e.Selected === true).map((i, index) => (
                                        <tr>
                                          <td>
                                            <input type="checkbox"
                                              checked={i.Selected == null || i.Selected == false ? false : true} name="customer-check" value={i.Id} id={i.Id} disabled="true" />
                                          </td>
                                          <td>
                                            {i.Name}
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                    : ""
                                  }
                                </table>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
            :
            ""
        }

        {
          showSelectedRebateType === true ?
            <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, marginRight: "10px", padding: "0" }}>
              <div className="container" style={{ padding: "0" }}>
                <div className="row">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">
                        <div className="row">
                          <div className="col-md-10 text-left">
                            {t('Rebates.RebateType')}
                          </div>
                          <div className="col-md-2">
                            <button className="btn btn-outline-dark  btn-sm ml-1" onClick={() => ClickValuePopupClose("Close", "RebateType", "Selected")} type="submit" style={{width: "45px"}}>{t('Close')}</button>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

                <div className="row mt-1">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">

                      </div>
                      <div className='scrollbar-class'>

                        <div className="container">
                          <div>
                          </div>
                          <table className="table table-bordered text-left mt-2 table-responsive-sm" id="tableregion">
                            <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                              <tr>
                                <th >
                                </th>
                                <th id="Product">
                                  {t('Rebates.RebateType')}
                                </th>
                              </tr>
                            </thead>

                            <tbody>

                              {
                                RebateTypeDataSelected.map((cslist, index) => (
                                  <tr>

                                    <td style={{ width: "30px" }}>
                                      {disableField == true ? <>
                                        <input type="checkbox"
                                          disabled={true}
                                          className={disabledClass}
                                          checked={cslist.Selected} name="customer-check" value={cslist.Id} id={cslist.Id} />

                                      </> : <>
                                        <input type="checkbox"
                                          className={disabledClass}
                                          checked={cslist.Selected} name="customer-check" value={cslist.Id} id={cslist.Id} />

                                      </>

                                      } </td>

                                    <td style={{ width: "90px" }}>
                                      <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                        title={cslist.Name}>{cslist.Name}</div>


                                    </td>
                                  </tr>


                                ))
                              }

                            </tbody> </table>


                        </div>

                      </div>
                    </div>
                  </div>
                </div>


              </div>
            </div>
            :
            ""
        }

        {
          showSelectedSettlFreq === true ?
            <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, marginRight: "10px", padding: "0" }}>
              <div className="container" style={{ padding: "0" }}>
                <div className="row">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">
                        <div className="row">
                          <div className="col-md-10 text-left">
                            {t('Rebates.SettlementFreq')}
                          </div>
                          <div className="col-md-2">
                            <button className="btn btn-outline-dark  btn-sm ml-1" onClick={() => ClickValuePopupClose("Close", "SettlementFreq", "Selected")} type="submit" style={{width: "45px"}}>{t('Close')}</button>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

                <div className="row mt-1">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">

                      </div>
                      <div className='scrollbar-class'>

                        <div className="container">
                          <div>

                           
                          </div>
                          <table className="table table-bordered text-left mt-2 table-responsive-sm" id="tableregion">
                            <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                              <tr>
                                <th >
                                </th>
                                <th id="Product">
                                  {t('Rebates.SettlementFreq')}
                                </th>
                              </tr>
                            </thead>

                            <tbody>

                              {
                                SettlFreqDataSelected.map((cslist, index) => (
                                 
                                  <tr>

                                    <td style={{ width: "30px" }}>
                                      {disableField == true ? <>
                                        <input type="checkbox"
                                          disabled={true}
                                          className={disabledClass}
                                          checked={cslist.Selected} name="customer-check" value={cslist.Id} id={cslist.Id} />

                                      </> : <>
                                        <input type="checkbox"
                                          className={disabledClass}
                                          checked={cslist.Selected} name="customer-check" value={cslist.Id} id={cslist.Id} />

                                      </>

                                      } </td>

                                    <td style={{ width: "90px" }}>
                                      <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                        title={cslist.Name}>{cslist.Name}</div>


                                    </td>
                                  </tr>


                                ))
                              }

                            </tbody> </table>


                        </div>

                      </div>
                    </div>
                  </div>
                </div>


              </div>
            </div>
            :
            ""
        }

        {
          showSelectedCustomerpopup === true ?
            <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, marginRight: "10px", padding: "0" }}>
              <div className="container" style={{ padding: "0" }}>
                <div className="row">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">
                        <div className="row">
                          <div className="col-md-12 text-left">
                            {t('Rebates.CustomerListing')}
                          </div>
                          <div className="col-md-2">

                            <button className="btn btn-outline-dark  btn-sm ml-1" onClick={() => ClickValuePopupClose("Close", "Customer", "Selected")} type="submit" style={{width: "45px"}}>{t('Close')}</button>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

                <div className="row mt-1">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">

                      </div>
                      <div className='scrollbar-class'>

                        <div className="container">
                          <div>

                           
                          </div>
                          <table className="table table-bordered text-left mt-2 table-responsive-sm" id="tableregion">
                            <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                              <tr>
                                <th>

                                </th>
                                <th>{t('Rebates.CustomerCode')}</th>
                                <th>{t('Rebates.CustomerName')}</th>
                                <th>{t('Rebates.Station')}</th>
                                <th>{t('Rebates.State')}</th>
                                <th>{t('Rebates.Zone')}</th>
                              </tr>
                            </thead>

                            <tbody>

                              {
                                customerDataSelected.map((cslist, index) => (
                                
                                  <tr>

                                    <td style={{ width: "30px" }}>
                                      {disableField == true ? <>
                                        <input type="checkbox"
                                          disabled={true}
                                          className={disabledClass}
                                          checked={cslist.Selected} name="customer-check" value={cslist.Id} id={cslist.Id} />

                                      </> : <>
                                        <input type="checkbox"
                                          className={disabledClass}
                                          checked={cslist.Selected} name="customer-check" value={cslist.Id} id={cslist.Id} />

                                      </>

                                      } </td>

                                    <td style={{ width: "90px" }}>
                                      <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                        title={cslist.Name}>{cslist.Name}</div>


                                    </td>
                                    <td style={{ width: "260px" }}>
                                      <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                        title={cslist.label}>{cslist.label}</div>


                                    </td>
                                    <td style={{ width: "100px" }}>
                                      <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                        title={cslist.Station}>{cslist.Station}</div>

                                    </td>
                                    <td style={{ width: "100px" }}>
                                      <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                        title={cslist.State}>{cslist.State}</div>

                                    </td>
                                    <td style={{ width: "100px" }}>
                                      <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                        title={cslist.Zone}>{cslist.Zone}</div>

                                    </td>



                                  </tr>


                                ))


                              }

                            </tbody> </table>


                        </div>

                      </div>
                    </div>
                  </div>
                </div>

                
              </div>
            </div>
            :
            ""
        }

        {
          showSelectedCustomerGrouppopup === true ?
            <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, marginRight: "10px", padding: "0" }}>
              <div className="container" style={{ padding: "0" }}>
                <div className="row">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">
                        <div className="row">
                          <div className="col-md-12 text-left">
                            {t('Rebates.GroupCustomerListing')}
                          </div>
                          <div className="col-md-2">

                            <button className="btn btn-outline-dark  btn-sm ml-1" onClick={() => ClickValuePopupClose("Close", "GroupCustomer", "Selected")} type="submit" style={{width: "45px"}}>{t('Close')}</button>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

                <div className="row mt-1">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">

                      </div>
                      <div className='scrollbar-class'>

                        <div className="container">
                          <div>

                            
                          </div>
                          <table className="table table-bordered text-left mt-2 table-responsive-sm" id="tableregion">
                            <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                              <tr>
                                <th>

                                </th>
                                <th>{t('Rebates.CustomerCode')}</th>
                                <th>{t('Rebates.CustomerName')}</th>

                              </tr>
                            </thead>

                            <tbody>

                              {
                                customerGroupDataSelected.map((cslist, index) => (
                                 
                                  <tr>

                                    <td style={{ width: "30px" }}>
                                      {disableField == true ? <>
                                        <input type="checkbox"
                                          disabled={true}
                                          className={disabledClass}
                                          checked={cslist.Selected} name="customer-check" value={cslist.Id} id={cslist.Id} />

                                      </> : <>
                                        <input type="checkbox"
                                          className={disabledClass}
                                          checked={cslist.Selected} name="customer-check" value={cslist.Id} id={cslist.Id} />

                                      </>

                                      } </td>

                                    <td style={{ width: "90px" }}>
                                      <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                        title={cslist.Name}>{cslist.Name}</div>


                                    </td>
                                    <td style={{ width: "260px" }}>
                                      <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                        title={cslist.label}>{cslist.label}</div>
                                    </td>

                                  </tr>


                                ))
                              }

                            </tbody> </table>


                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              
              </div>
            </div>
            :
            ""
        }

        {
          showExceptionWorkflow === true ?
            <div id="mySidepanel" className="sidepanel sidepanelborder" style={{ width: sidebarwidth }}>

              {showWorkflow === false ?
                <div className="container" id="workflow">
                  <div className="row mt-3">
                    <div className="col-lg-12 pr-lg-2">
                      <div className="card text-center card_border">
                        <div className="card-header chart-grid__header">
                          <div className="row">
                            <div className="col-md-10 text-left">
                              {t('Workflow')}
                            </div>
                            <div className="col-md-2">
                              <button className="btn btn-outline-dark  btn-sm" onClick={clickCloseAccuralWorkflow} type="submit" style={{width: "45px"}}>{t('Close')}</button>
                            </div>
                          </div>
                        </div>

                        <div className="card-body" style={{ minHeight: "55vh" }}>
                        

                          {workflowchart.length > 0 ?
                            <div className='col-md-12'>
                              <table className="table table-responsive-sm">
                                <thead>
                                  <tr>
                                    <th className='workflowchart-levelwidth workflowchart-font'>Level</th>
                                    <th className='font-center workflowchart-font'>Users</th>
                                    <th className='font-center workflowchart-font'>Approval</th>
                                  </tr>
                                </thead>

                                <tbody>
                                  {
                                    workflowchart.map((i) =>
                                      <tr key={i.Id}>
                                        <td className='workflowchart-font square-levels'>{i.Levels}</td>
                                        <td className='font-center'>
                                          <ul >
                                            {
                                              i.lstworkflowapprover.map((j, index) => {
                                                return <>

                                                  {
                                                    index < 2 ?
                                                      j.IsApproved === true ?
                                                        <li className='workflowchartperson'>
                                                          <span className='workflowtpersoncolor'><i class="fa fa-user-circle-o" aria-hidden="true"></i>
                                                            <br></br> {j.FullName}</span>
                                                        </li>
                                                        :
                                                        <>
                                                          <li className='workflowchartperson'>
                                                            <i class="fa fa-user-circle-o" aria-hidden="true"></i>
                                                            <br></br> {j.FullName}
                                                          </li>
                                                        </>
                                                      :
                                                      ''
                                                  }
                                                </>

                                              })
                                            }

                                            {
                                              i.lstworkflowapprover.length > 2 ?
                                                <li className='workflowchartperson tooltip_workflow'>
                                                  <i class="fa fa-user-circle-o" aria-hidden="true"></i>
                                                  <br></br> {t('MoreUsers')}
                                                  <span class="tooltiptext">

                                                    {
                                                      i.lstworkflowapprover.map((k, index) => {
                                                        return <span>
                                                          {
                                                            index > 1 ? <><i class="fa fa-user-circle-o" aria-hidden="true"></i>
                                                              &nbsp; {k.FullName}
                                                              <hr></hr></> : null
                                                          }


                                                        </span>
                                                      })
                                                    }

                                                  </span>
                                                </li>
                                                :
                                                ''
                                            }


                                          </ul>

                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                          {
                                            i.IsApproved === true ?
                                              <span className='workflowrightcolor'>
                                                <i class="fa fa-check" aria-hidden="true"></i>
                                              </span>

                                              : i.IsDenied == true ?
                                                <span className='workflowrightcolor'>
                                                  <i class="fa fa-times" aria-hidden="true" style={{ color: "red" }}></i>
                                                </span>
                                                : ''
                                          }
                                        </td>
                                      </tr>)

                                  }
                                </tbody>

                              </table>

                            </div>
                            :
                            <div className={classes.flow}>

                            </div>
                          }


                          <div className="row">
                            <div className="col-md-12">
                              <table className="table table-bordered text-left mt-2 table-responsive-sm">
                                <thead className='text-white' style={{ backgroundColor: "var(--primary)" }}>
                                  <tr>

                                    <th>{t('Rebates.Event')}</th>
                                    <th>{t('Rebates.CreatedDate')}</th>
                                    <th>{t('Rebates.User')}</th>
                                    <th>{t('Rebates.Comments')}</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {
                                    showspinnerworkflow === true ?

                                      <>
                                        <Spinner animation="grow" size="sm" variant="success" style={{ width: "0.7rem", height: "0.7rem" }} />
                                        <Spinner animation="grow" size="sm" variant="danger" style={{ width: "0.7rem", height: "0.7rem" }} />
                                        <Spinner animation="grow" size="sm" variant="warning" style={{ width: "0.7rem", height: "0.7rem" }} />
                                      </>
                                      :
                                      workflowData.length === 0 ?
                                        <tr>
                                          <td></td>
                                          <td></td>
                                          <td>
                                            {t('NoDataAvailable')}
                                          </td> <td></td></tr>
                                        :
                                        woData === '' || woData === 'undefined' ?
                                          <tr></tr> :
                                          workflowData.map((i) =>
                                            <tr key={i.id}>
                                              <td>{i.EventName}</td>
                                              {i.CreatedDate === null ? <td></td> : <td>{moment(i.CreatedDate).format(SessionManage.getcurrentdateformat())}</td>}
                                              <td>{i.CreateUserName}</td>
                                              <td>{i.Comments}</td>
                                            </tr>)

                                  }

                                </tbody>
                              </table>
                            </div>

                          </div>


                        </div>

                      </div>
                    </div>

                  </div>
                </div>
                :
                ''
              }

            </div>
            :
            ""
        }

      </div >
    </>
  )
}

export default NewExceptionPolicy