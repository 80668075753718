import { Link, useLocation } from "react-router-dom"
import React, { useState, useEffect, useRef } from 'react'
import Service from '../../../Services/Service';
import { useNavigate } from 'react-router-dom';
import { Form, FormGroup, Col, Row, Button, InputGroup, Collapse, Modal } from 'react-bootstrap';
import moment from 'moment';
import Spinner from 'react-bootstrap/Spinner';
import Multiselect from 'multiselect-react-dropdown';
import SessionManage from "../../../SessionManagement/SessionManage";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomErrorPopup from '../../../UI/CustomErrorPopup';
import Select from "react-select";

import { CSVLink } from "react-csv";
import { useTranslation } from "react-i18next";

function SpecialPriceAgreementListComponent() {
    
    let loggedInUser = SessionManage.getuserid();
    const [loading, setLoading] = useState(false);
    const [checkedMultiValueid, setCheckedMultiValueid] = useState([]);
    const [deleteshowbutton, setDeleteshowbutton] = useState('');
    const [checkedValue, setCheckedValue] = useState([]);
    const [errorDeleteModal, setErrorDeleteModal] = useState(false);
    const [checkedValueStatus, setCheckedValueStatus] = useState([]);
    const [showDeleteModal, toggleModal] = useState(false)
    const [multiValueids, setMultiValueIds] = useState([]);
    const [searchValue, setSearchValue] = useState(null);
    const location = useLocation();
    const csvInstance = useRef();
    const [csvList, setCsvList] = useState([]);
    const [sticky, setSticky] = useState("");
    const [sortColName, setSortColName] = useState("");
    const [userPerms, setuserPermission] = useState([]);
    const [data, setdata] = useState([]);
    const [showspinner, setshowspinner] = useState('');
    const [checkedValueid, setCheckedValueid] = useState([]);
    const [filteredArray, setFilteredArray] = useState(data)
    const [showLoadMore, setshowLoadMore] = useState('');
    const [showbutton, setshowbutton] = useState('');
    const [filterValue, setFilterValue] = useState('');
    const [filterCol, setFilterCol] = useState('');
    const [dropdownData, setDropdownData] = useState([]);
    const [showfilterpopup, setshowfilterpopup] = useState(false);
    const [columnAscending, setColumnAscending] = useState(true);
    const [totalselectedshow, settotalselectedshow] = useState('');
    const [totalselectedcount, settotalselectedcount] = useState(0);
    const [totalCountlst, settotalCountlst] = useState('');
    const [ToggleStatusModal, setToggleStatusModal] = useState(false);
    const [toggleChange, setToggleChange] = useState("");
    const [toggleId, setToggleId] = useState();
    const [toggleUserId, setToggleUserId] = useState();
    const [dropdownChange, setDropdownChange] = useState('');
    const [t, i18n] = useTranslation('translation');
    const [filtertext, setfiltertext] = useState('');
    const [filterSearch, setFilterSearch] = useState("");
    const [filterdropdownData, setFilterDropdownData] = useState([]);
    
    const customStyles = {
        control: base => ({
            ...base,
            minHeight: 28
        }),
        dropdownIndicator: base => ({
            ...base,
            padding: 2
        }),
        clearIndicator: base => ({
            ...base,
            padding: 2
        }),
        multiValue: base => ({
            ...base,

        }),
        valueContainer: base => ({
            ...base,
            padding: '0px 2px'
        }),
        input: base => ({
            ...base,
            margin: 0,
            padding: 0
        })
    };

    const [paging, setpaging] = useState({
        pageSize: 20,
        pageNumber: 1
    });
    const [filterValueDict, setFilterValueDict] = useState({
        last_modified_date: null,
        created_date: null,
        agreementid: '',
        status: '',
    });
    const [multiFilterValue, setMultiFilterValue] = useState({
        distributorname: '',
        endcustomername: '',
        last_modified_by: '',
        created_by: ''
    });

    const getUserPermission = (pathname) => {
        Service.GetUserPermissionNew(pathname).then((res) => {
            if (typeof res !== 'undefined') {
                if (res.data !== 'null' && typeof res.data !== 'undefined') {
                    if (res.data.DataList !== 'undefined') {
                        setuserPermission(res.data.DataList)
                    }
                }
            }
        });
    }
    const activeOption = [
        { Name: "Active", Id: "1" },
        { Name: "In-Active", Id: "0" },

    ]
    const checkAll = (mycheckbox) => {
        var checkboxes = document.querySelectorAll("input[type = 'checkbox']");
        if (mycheckbox.target.checked == true) {
            settotalselectedshow(true);
            checkboxes.forEach(function (checkbox) {
                checkbox.checked = true;
            });
            settotalselectedcount(checkboxes.length - 1)
        }
        else {
            settotalselectedshow(false);
            checkboxes.forEach(function (checkbox) {
                checkbox.checked = false;
            });
            settotalselectedcount(0)
        }

    }

    const LoadMore = (lst, totalcount) => {

        if (lst < totalcount) {
            setshowLoadMore(true);
        }
        else if (lst >= totalcount) {
            setshowLoadMore(false);
        } else {
            setshowLoadMore(false);
        }

    }
    const navigate = useNavigate();

    useEffect(() => {
        setColumnAscending(false);
        setSortColName("Id");
        setshowspinner(true);
        getUserPermission('Special Price Agreement');
        getData("SpecialAgreement/GetAll", paging.pageSize, paging.pageNumber, "Descending", "Id", [], null);    
        getColumnVisibilityList()
        getColVisibilityListData()
        window.addEventListener("scroll", isSticky);
        return () => {
            window.removeEventListener("scroll", isSticky);
        };

    }, [0]);
    const isSticky = () => {
        const scrollTop = window.scrollY;
        const stickyClass = scrollTop >= 60 ? "is-sticky-thead" : "";
        setSticky(stickyClass);
        const scrollTopActual = scrollTop >= 60 ? scrollTop - 85 : 0;
        var divtop1 = document.getElementById("tableA");
        setdivtop(divtop1.offsetTop + scrollTopActual)
    };
    const class_thead = `text-white rt-thead  ${sticky}`;
    const handleLoadMore = () => {
        let pagenumber = paging.pageNumber + 1;
        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
        Service.getDatawithMultiFilterSearch("SpecialAgreement/GetAll", paging.pageSize, pagenumber, sortType, sortColName, filterLst(), searchValue).then((res) => {
            if (typeof res !== 'undefined') {
                if (res.data !== 'null' && typeof res.data !== 'undefined') {
                    const currentlength = data.length;
                    setdata(data => [...data, ...res.data.DataList])
                    setFilteredArray(filteredArray => [...filteredArray, ...res.data.DataList])
                    LoadMore(currentlength + res.data.DataList.length, res.data.Totalcount)
                }
            }
        });
    
        paging.pageNumber = pagenumber
        setpaging(paging);
    };

    let role = [];
    const getData = (method, pageSize, pageNumber, sortType, SortProperty, filterValue, search) => {
        Service.getDatawithMultiFilterSearch(method, pageSize, pageNumber, sortType, SortProperty, filterValue, search).then((res) => {
            if (typeof res !== 'undefined') {
                if (res.data !== 'null' && res.data !== 'undefined') {
                    setdata(res.data.DataList);
                    setFilteredArray(res.data.DataList)
                    settotalCountlst(res.data.Totalcount)
                    LoadMore(res.data.DataList.length, res.data.Totalcount)
                    setshowspinner(false)
                }
            }
        });

    }

    const handleChange = (e, id, status, name) => {
        let isChecked = e.target.checked;   // true if it is checked false if unchecked
     
        let checkedBoxes = document.querySelectorAll('input[name=chkBox]:checked');
        if (isChecked === true) {
            settotalselectedshow(true);
            settotalselectedcount(checkedBoxes.length);
            setCheckedValue(id)  // check box value - id
            setCheckedValueStatus(status) // check box value - status
            setCheckedValueid(name) //check box value - RA-0000
        }
        else {
            if (checkedBoxes.length === 0) {
                settotalselectedshow(false);
            }

            settotalselectedcount(checkedBoxes.length);
            setCheckedValue([])  // check box value - id
            setCheckedValueStatus([]) // check box value - status
            setCheckedValueid([]) //check box value - RA-0000
        }


        if (checkedBoxes.length == 1) {
            setshowbutton(true);
            setDeleteshowbutton(false)
        }
        else if (checkedBoxes.length > 1) {
            setshowbutton(false);
            setDeleteshowbutton(true)
        }
        else {
            setshowbutton(false);
            setDeleteshowbutton(false)
        }
    }
    const addnewfield = (data, key) => {
        let selectedKeys = multiFilterValue[key];
        let currentdata = data.map((i) => {
            i["ischecked"] = false;
            return i;
        });
        if (key != 'status') {
            if (selectedKeys != '') {
                currentdata.forEach((each) => {
                    selectedKeys.forEach((eachSelected) => {
                        if (each.Name == eachSelected.Name) {
                            each.ischecked = true
                        }
                    })
                })
            }
           
            setDropdownData(currentdata);
            setFilterDropdownData(currentdata)
        }
        else {
            if (selectedKeys != '') {
                currentdata.forEach((each) => {
                    selectedKeys.forEach((eachSelected) => {
                        if (each.Name == eachSelected.Name) {
                            each.ischecked = true
                        }
                    })
                })
                setDropdownData(currentdata)
                setFilterDropdownData(currentdata)
            }
            else {
                setDropdownData(currentdata)
                setFilterDropdownData(currentdata)
            }
        }
    }


    const checkAllFilter = (mycheckbox) => {
        var lastfilter = [...dropdownData]
        let data1 = []
        var checkboxes = document.querySelectorAll("input[name = 'filter-option-all']");
        if (mycheckbox.target.checked == true) {
            
            let currentdata = lastfilter.map((i) => {
                i["ischecked"] = true;
                return i;
            });
            setDropdownData(currentdata)
            
            dropdownData.map((each) => {
                
                const getallData = {
                    Name: each.Name,
                }
                data1.push(getallData)
            })
            setFilterValue(filterValue => [...filterValue, ...data1]);
            let updateddata = ([...filterValue, ...data1]);
            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: updateddata,
            }));
        }
        else {
          
            let currentdata = lastfilter.map((i) => {
                i["ischecked"] = false;
                return i;
            });
            setFilterValue([])
            setMultiFilterValue([])
        }

    }

   

    const OndropdownChange = (e, id, name) => {
        let isChecked = e.target.checked;
        var lastfilter = [...dropdownData]
        if (isChecked === true) {
            let currentdata = lastfilter.map((i) => {
                if (i.Id == id) {
                    i["ischecked"] = true;
                }
                return i;
            });
           
            setDropdownData(currentdata);
            let data1 = []
            const getallData = {
                Name: name,
            }
            data1.push(getallData)
            setFilterValue(filterValue => [...filterValue, ...data1]);
            let updateddata = ([...filterValue, ...data1]);
            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: updateddata,
            }));
        }
        else {
            let currentdata = lastfilter.map((i) => {
                if (i.Id === id) {
                    i["ischecked"] = false;
                }
                return i;
            });
            
            setDropdownData(currentdata);
            setFilterValue(filterValue => filterValue.filter(data => data.Name !== name))
            let data1 = multiFilterValue.filter(data => data.Name !== name);
            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: data1,
            }));
        }
    }

    const UserfilterHandler = (e) => {
      
        setFilterSearch(e.target.value)
        if (e.target.value !== "") {
            let searchResults = dropdownData.filter((each) => {
                return each.Name.toLowerCase().includes(String(e.target.value).toLowerCase())
            });
            setDropdownData(searchResults);
        }
        else {
           
            setFilterSearch("")
            setDropdownData(filterdropdownData)
        }
    }
    const OpenHandler = (id) => {
        if (id == null || id == 0) {
            alert('Please select the User to view')
        }
        else {
            navigate("/specialprice/new/" + id);
        }
    }

    const searchHandler = (event) => {
        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
     
        let pagenumber = 1;
    
        paging.pageNumber = pagenumber;
        setpaging(paging);
        if (event.target.value != '') {
       
            setSearchValue(String(event.target.value).toLowerCase())
            getData("SpecialAgreement/GetAll", paging.pageSize, pagenumber, sortType, sortColName, filterLst(), event.target.value);
        }
        else {

            setSearchValue(null);
            getData("SpecialAgreement/GetAll", paging.pageSize, pagenumber, sortType, sortColName, filterLst(), null);
        }
    }
    const filterInput = (e) => {
        
        let inputValue = e.target.value
        setFilterValue(inputValue)
    
        setFilterValueDict(() => ({
            ...filterValueDict, [filterCol]: e.target.value,
        }));
    }

    const [divleft, setdivleft] = useState('');
    const [divtop, setdivtop] = useState('');

    const FilterColumn = (name) => {

        const scrollTop = window.scrollY;
        const scrollTopActual = scrollTop >= 60 ? scrollTop - 85 : 0;
        var divtop1 = document.getElementById("tableA");
        setdivtop((divtop1.offsetTop + 30) + scrollTopActual)
        setshowfilterpopup(true)
        setFilterCol(name)
        var testDiv = document.getElementById(name);
        setdivleft((testDiv.offsetLeft + 30) + "px");
        if (name === 'agreementid') {
            setfiltertext(t('ship&debit.AgreementId'))
        }
        if (name === 'distributorname') {
            setfiltertext(t('ship&debit.DistributorName'))
        }
        if (name === 'endcustomername') {
            setfiltertext(t('ship&debit.EndCustomer'))
        }
        if (name === 'status') {
            setfiltertext(t('ship&debit.Status'))
        }
        if (name === 'created_by') {
            setfiltertext(t('CreatedBy'))
        }
        if (name === 'created_date') {
            setfiltertext(t('ship&debit.CreatedOn'))
        }
        if (name === 'last_modified_by') {
            setfiltertext(t('ModifiedBy'))
        }
        if (name === 'last_modified_date') {
            setfiltertext(t('ship&debit.ModifiedOn'))
        }
        if (name === 'endcustomername' || name === 'last_modified_by' || name === 'created_by' || name === 'distributorname')
            DropdownHandler(name)
        setshowfilterpopup(true)

    }
    const DropdownHandler = (key) => {
        Service.getfilterDropdownByGroup('SpecialAgreement/GetAllFilters', key).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {

                        if (res.data !== 'null' && res.data !== 'undefined') {
                            
                            addnewfield(res.data, key)
                        }
                    }
                }
            }

        });
    }
    const filterHandler = (flag) => {

        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
        let pagenumber = 1;
        paging.pageNumber = pagenumber;

        if (flag === 'Apply') {
            setshowfilterpopup(false)
            let list = filterLst();
            if (list.length > 0) {
                setpaging(paging);
                getData("SpecialAgreement/GetAll", paging.pageSize, pagenumber, sortType, sortColName, list, searchValue);
            }
        }
        else {
            setshowfilterpopup(false)
            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: '',
            }));
            setFilterValueDict(() => ({
                ...filterValueDict, [filterCol]: '',
            }));

            let list = [];
            for (var key in multiFilterValue) {
                if (key !== filterCol && multiFilterValue[key].length > 0) {
                    let values = ""
                    multiFilterValue[key].map((i) => {
                        values += i.Name + ";";
                    })
                    if (values !== null && values !== '') {
                        list.push({
                            filterKey: key,
                            FilterValue: values
                        })
                    }
                }
            }

            for (var key in filterValueDict) {
                if (filterValueDict[key] !== null && filterValueDict[key] !== '' && key !== filterCol) {
                    list.push({
                        filterKey: key,
                        FilterValue: filterValueDict[key]
                    })
                }
            }
            setpaging(paging);
            getData("SpecialAgreement/GetAll", paging.pageSize, pagenumber, sortType, sortColName, list, searchValue);

           
            setFilterCol('')
            setFilterValue('')
            setDropdownData([])
            setDropdownChange("")
            setFilterSearch("")
            setFilterDropdownData([])
        }
    }



    const SortColumn = (colName) => {
        var sortType = '';
        if (columnAscending === true) {
            setColumnAscending(false);
            sortType = "Descending";
        } else {
            setColumnAscending(true);
            sortType = "Ascending";
        }
        setSortColName(colName);
      
        let pagenumber = 1;
      
        paging.pageNumber = pagenumber;
        setpaging(paging);
        getData("SpecialAgreement/GetAll", paging.pageSize, paging.pageNumber, sortType, colName, filterLst(), searchValue);
    }
    const filterLst = () => {
        let list = [];
        for (var key in multiFilterValue) {
            if (multiFilterValue[key].length > 0) {
                let values = ""
                multiFilterValue[key].map((i) => {
                    values += i.Name + ";";
                })
                if (values !== null && values !== '') {
                    list.push({
                        filterKey: key,
                        FilterValue: values
                    })
                }
            }
        }

        for (var key in filterValueDict) {
            if (filterValueDict[key] !== null && filterValueDict[key] !== '') {
                list.push({
                    filterKey: key,
                    FilterValue: filterValueDict[key]
                })
            }
        }
        return list;
    }


    const CopyHandler = (id) => {
        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
        let rowData = {}
        let pagenumber = 1;
        paging.pageNumber = pagenumber;
        setpaging(paging);
        setLoading(true);
        Service.PostWithIdAndUser('SpecialAgreement/Copy', id, Service.getUserId()).then((res) => {
            if (typeof res !== 'undefined') {
                if (res.data !== 'null' && res.data !== 'undefined') {
                    setLoading(false);
                    getData("SpecialAgreement/GetAll", paging.pageSize, pagenumber, sortType, sortColName, filterLst(), searchValue)
                    toast.success('Copy Successfully !', {
                        position: toast.POSITION.TOP_RIGHT,
                    });
              
                }
            }
            else
                setLoading(false);
        });
    }
    const checkDelete = () => {
        let checkedBoxes = document.querySelectorAll('input[name=chkBox]:checked');
        if (checkedBoxes.length == 1) {
            if (checkedValueStatus.includes('Approved') || checkedValueStatus.includes('InReview') || checkedValueStatus.includes('Denied')) {
                setErrorDeleteModal(true)
            }
            else {
                
                setMultiValueIds(checkedValue)
                showDeleteDialog('open')
            }
        }
        if (checkedBoxes.length > 1) {
            setCheckedMultiValueid([])
            for (let checkbox of checkedBoxes) {
               
                checkedMultiValueid.push(checkbox.defaultValue)
              
            }
            checkedMultiValueid.map(i => {
                if (i.includes('Approved') || i.includes('InReview') || i.includes('Denied')) {
                    setErrorDeleteModal(true)
                }
                else {
                    var z = i.split(",");
                    multiValueids.push(z[0])
                    showDeleteDialog('open')
                }
            })
        }
    }
    const showDeleteDialog = (flag) => {
        if (flag === 'open') {
          
            toggleModal(true)
        }
        if (flag === 'close') {
        
            toggleModal(false)
            let checkedBoxes = document.querySelectorAll('input[name=chkBox]:checked');
            for (let checkbox of checkedBoxes) {
                checkbox.checked = false;
            }
            setMultiValueIds([])
            setDeleteshowbutton(false)
        }
        if (flag === 'proceed') {
        
            handleDelete(multiValueids)
            let checkedBoxes = document.querySelectorAll('input[name=chkBox]:checked');
            for (let checkbox of checkedBoxes) {
                checkbox.checked = false;
            }
            setDeleteshowbutton(false)
        }
    }
    const handleDelete = (id) => {
        toggleModal(false)
        let pagenumber = 1;
        paging.pageNumber = pagenumber;
        setpaging(paging);
        setLoading(true);
        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
        Service.deleteDataBySDId('SpecialAgreement/Delete', id, Service.getUserId()).then((res) => {
         
            if (typeof res !== 'undefined') {
                setLoading(false);
                toast.error('Deleted Successfully !', {
                    position: toast.POSITION.TOP_RIGHT,
                });


                  
                if (res.data !== 'null' && res.data !== 'undefined') {

                    getData("SpecialAgreement/GetAll", paging.pageSize, pagenumber, sortType, sortColName, filterLst(), searchValue)
                    setMultiValueIds([])
                }
            } else {
                setLoading(false);
            }
        });
    }
    let headers = [
        { label: "Agreement ID", key: "AgreementId" },
        { label: "Distributor Name ", key: "DistributorName" },
        { label: "End Customer", key: "EndcustomerName" },
        { label: "Status", key: "Status" },
        { label: "Created By", key: "CreatedUserName" },
        { label: "Created On ", key: "CreatedDate" },
        { label: "Modified By ", key: "LastModifiedUserName" },
        { label: "Modified On ", key: "LastModifiedDate" },

    ];
    const ExporttoCSV = () => {
        let checkedBoxes = document.querySelectorAll("input[name=chkBox]:checked");
        let values = "";
        if (checkedBoxes.length >= 1) {
            for (let checkbox of checkedBoxes) {
                if (values === "") {
                    values = checkbox.id;
                } else {
                    values = values + ";" + checkbox.id;
                }
            }
        }
        let objFilter = [];
        let objFilterValue = [
            {
                filterKey: "ids",
                FilterValue: values,
            },
        ];
        if (totalCountlst != checkedBoxes.length) {
            objFilter = objFilterValue;
        }
        Service.getDatawithMultiFilterSearch("SpecialAgreement/GetAll", 20000, 1, "Ascending", "Id", objFilter, null).then((res) => {
       
            if (typeof res !== "undefined") {
                if (res.data !== "null" && res.data !== "undefined") {
                    setCsvList(res.data.DataList);
                    setTimeout(() => {
                        csvInstance.current.link.click();
                        toast.success('Export Successfully !', {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    });
                }
            }
        });
    };

    const Open = (event, id) => {
        if (event.detail === 2) {
       
            navigate("/specialprice/new/" + id);
        }
    }
    
    // -----------------------------------------------------------------
    const columnList = [
        { "label": "ship&debit.AgreementId", "key": "agreementid", "value": true},
        { "label": "ship&debit.DistributorName", "key": "distributorname", "value": true},
        { "label": "ship&debit.EndCustomer", "key": "endcustomername", "value": true},
        { "label": "ship&debit.Status", "key": "status", "value": true},
        { "label": "CreatedBy", "key": "created_by", "value": true},
        { "label": "ship&debit.CreatedOn", "key": "created_date", "value": true},
        { "label": "ModifiedBy", "key": "last_modified_by", "value": true},
        { "label": "ship&debit.ModifiedOn", "key": "last_modified_date", "value": true},
    ]
  
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [errorStatus, setErrorStatus] = useState("Error");
    const [addRemoveCol, setAddRemoveCol] = useState(columnList);
    const [createColumnListOption, setCreateColumnListOption] = useState(columnList)
    const [showAddColumnPopup, setShowAddColumnPopup] = useState(false)    
    const [showCreateColumnListPopup, setShowCreateColumnListPopup] = useState(false)
    const [hideColumn, setHideColumn] = useState([]);
    const [resetColumnKey, setResetColumnKey] = useState([]);
    const [colVisibilityOption, setColVisibilityOption] = useState([]);
    const [colvisiOptionVal, setColvisiOptionVal] = useState({ value: null, label: 'Default'})    
    const [divleft1, setdivleft1] = useState('');
    const [divtop1, setdivtop1] = useState('');    
    const [existingViewVal, setExistingViewVal] = useState(false);    
    const [defaultView, setDefaultView] = useState(false);    
    const [colViewName, setColViewName] = useState('');
    const [recorddId, setRecordId] = useState(0);
  
  
    const OnChangedefaultView = (event,) => {       
        setDefaultView( event.target.checked)
    }    
    const OnChangeexistingViewVal = (event,) => {       
        setExistingViewVal( event.target.checked)
    }    

    const OnChangeSetViewName= (event,) => {       
        setColViewName( event.target.value)
    }    

    const OnChangeColVisibilityList = (data) => {
        setColvisiOptionVal({ value: data.value, label: data.label });
        getColVisibilityListData(data.value)
    }    
    const CreateColumnListPopup = () => {
        setShowCreateColumnListPopup(true)
        var divtop1 = document.getElementById("addRemoveCol");
        setdivtop1(divtop1.offsetTop + 30)     
        setdivleft1((divtop1.offsetLeft) + 100 + "px");
    }

    const CloseCreateColumnListPopup = () =>{
        setShowCreateColumnListPopup(false)
        setColViewName('')
        setDefaultView(false)
    }

    const AddRemoveColumn = () => {
        setShowAddColumnPopup(true)
        var divtop1 = document.getElementById("addRemoveCol");
        setdivtop(divtop1.offsetTop + 45)     
        setdivleft((divtop1.offsetLeft) + "px");
        getColumnVisibilityList()
    }
    const OnchangeAddRemoveCol = (event, column) =>{
        let isChecked = event.target.checked; 
        let lst = [...addRemoveCol];
        for (let d in lst) {
            if (lst[d].key == column.key && isChecked)
            {
                lst[d]['value'] = true                
            }
            else if(lst[d].key == column.key && !isChecked){
                lst[d]['value'] = false
            }            
        }
        setResetColumnKey([...resetColumnKey, column.key])
        setAddRemoveCol(lst)
    }
    const OnchangeCreateColumnListOption = (event, column) =>{
        let isChecked = event.target.checked; 
        let lst = [...createColumnListOption];
        for (let d in lst) {
            if (lst[d].key == column.key && isChecked)
            {
                lst[d]['value'] = true                
            }
            else if(lst[d].key == column.key && !isChecked){
                lst[d]['value'] = false
            }            
        }
        setCreateColumnListOption(lst)
    }
    const updateColumnVisibilityList = (tag) => {
        let lst = [...addRemoveCol];
        if (tag == 'reset'){
            for (let d in lst) {
                lst[d]['value'] = true                        
            }
            setHideColumn([])
            setAddRemoveCol(lst) 
        }
        else{            
            let data = [];
            for (let d in lst) {
                if (!lst[d].value)
                {
                    data.push(lst[d].key)
                }           
            }
            setHideColumn(data)
        }
        let viewName = colvisiOptionVal.value;
        Service.setColumnVisibilityListData('ColumnVisibility/CreateOrUpdateColumnList', recorddId, loggedInUser, 'specialPriceAgreement', viewName, existingViewVal, lst).then((res) => {})
        setShowAddColumnPopup(false)         
        setResetColumnKey([])       
    }

    
    const CreateColumnVisibilityList = (tag) => {
        let veiwName = colViewName;
        let idDefault = defaultView;  
        if (veiwName.trim() == 'undefined' || veiwName.trim() == '' || veiwName.trim() == null) {
            setErrorStatus("Error")
            setErrorMessage("Please set View Name")
            setShowErrorModal(true)
        }
        else{
            let lst = [...createColumnListOption];
            if (tag == 'reset'){
                for (let d in lst) {
                    lst[d]['value'] = true                        
                }
                setHideColumn([])
                setAddRemoveCol(lst) 
            }
            else{            
                let data = [];
                for (let d in lst) {
                    if (!lst[d].value)
                    {
                        data.push(lst[d].key)
                    }           
                }
                setHideColumn(data)
            }
            Service.setColumnVisibilityListData('ColumnVisibility/CreateOrUpdateColumnList', 0, loggedInUser, 'specialPriceAgreement', colViewName, defaultView, lst).then((res) => {
                getColumnVisibilityList()
                setColvisiOptionVal({ value: colViewName, label: colViewName });
                getColVisibilityListData(colViewName)                   
                CloseCreateColumnListPopup()          
                setShowAddColumnPopup(false)     
            })            
        }        
    }

    const closeAddRemoveColumnPopup =() =>{
        let lst = [...addRemoveCol];  
        let resetKey = [...resetColumnKey]; 
        for (let rk in resetKey){
            for (let d in lst) {
                if (lst[d].key == resetKey[rk])
                {
                    lst[d]['value'] = !lst[d].value
                }
            }
        } 
        setAddRemoveCol(lst)     
        setShowAddColumnPopup(false)   
        setResetColumnKey([]) 
            
    }

    const getColumnVisibilityList = ()=>{
        Service.getColumnVisibilityListData('ColumnVisibility/GetColumnList', loggedInUser, 'specialPriceAgreement', null).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                      
                        if (typeof res.data !== 'undefined' && res.data !== 'null') {
                            let data = []
                            for (let d in res.data) {
                                data.push({value: res.data[d].label, label: res.data[d].label})
                            
                            }
                         
                            setColVisibilityOption(data)    
                        }
                    }
                }
            }
        })
    }


    const getColVisibilityListData = (viewName=null)=>{
        if (!viewName){
            viewName = colvisiOptionVal.value
        }        
        if (viewName == 'Deleted'){
            viewName = null
        }
        Service.getColumnVisibilityListData('ColumnVisibility/GetColumnListJsonData', loggedInUser, 'specialPriceAgreement', viewName).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (typeof res.data.jsonData !== 'undefined' && res.data.jsonData !== 'null') {
                        
                            let jsonData = JSON.parse(res.data.jsonData)
                            let data = [];
                            for (let d in jsonData) {
                                if (!jsonData[d].value)
                                {
                                    data.push(jsonData[d].key)
                                }           
                            }
                            setColvisiOptionVal({ value: res.data.label, label: res.data.label });
                            setHideColumn(data)                            
                            setAddRemoveCol(jsonData)
                            setExistingViewVal(res.data.defaultView)
                            setResetColumnKey([]) 
                            setRecordId(res.data.id)
                        }
                    }
                }
            }
        })
    }

    const deleteColumnVisibilityView = () =>{
      Service.deleteDataByPolicyID("ColumnVisibility/Delete", recorddId, loggedInUser).then(res => {
          if (res.status === 200) {     
              getColumnVisibilityList()
              getColVisibilityListData('Deleted')               
              setShowAddColumnPopup(false)           
              alert('Deleted Successfully')  
          }
      })
    }
    // ------------------------------------------------------------

    return (<>
        <div className="container-fluid content-top-gap">
            <ToastContainer />

            <nav aria-label="breadcrumb">
                <ol className="breadcrumb my-breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">{t('Home.title')}</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">{t('SpecialPriceAgreement.SpecialPriceAgreement')}</li>
                </ol>
            </nav>
            {loading ? <><div className="loader-container"><div className="spinner"></div> </div> </> : ""}
            <div style={{ display: "none" }}>
                <CSVLink data={csvList} headers={headers} ref={csvInstance} filename="SpecialPriceAgreement.csv" > </CSVLink>
            </div>
            <div className="">
                <div className="row">
                    <div className="col-lg-12 pr-lg-2">
                        <div className="card text-center card_border overflow-auto">

                            <div className="card-body" style={{ minHeight: "70vh" }}>
                                <div className="row ml-1">

                                    <div className="col-md-8">
                                        <div className="row">
                                            <input type="text" className="form-control form-control-user col-md-10" autoComplete='no' id="firstName" name="firstName" placeholder={`${t('SearchFor')} ${t('ship&debit.AgreementId')},${t('ship&debit.DistributorName')} , ${t('ship&debit.EndCustomer')} & ${t('ship&debit.Status')}`} onChange={(event) => searchHandler(event)} />
                                            <button  id ="addRemoveCol" icon className="btn btn-update ml-2 mt-1 pa-0" type="submit" 
                                                    style={{ float: "left", cursor:"pointer",    padding: '0px', height: '29px', width: '26px'}} 
                                                    onClick={AddRemoveColumn} title='Manage View'><i class="fa fa-columns" aria-hidden="true"></i></button>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        {Service.editPerms(userPerms) == false ? "" : <a className="btn btn-update btn-sm ml-2" id="create" style={{ width: "75px", backgroundColor: "buttonface", float: "right" }} href="/specialprice/new">{t('CreateNew')}</a>}
                                        {totalselectedcount <= 0 ? "" : Service.exportPerms(userPerms) == false ? "" : <button className="btn btn-update  btn-sm ml-2" type="submit" style={{ float: "right",width: "50px" }} onClick={(event) => ExporttoCSV()}>{t('Export')}</button>}
                                        {showbutton === true ?
                                            <>
                                                <button className="btn btn-update  btn-sm ml-2" type="submit" style={{ float: "right",width: "40px" }} onClick={(event) => OpenHandler(checkedValue)} >{t('Open')}</button>
                                                {Service.editPerms(userPerms) == false ? "" : <button className="btn btn-update  btn-sm ml-2" type="submit" style={{ float: "right",width: "40px" }} onClick={(event) => CopyHandler(checkedValue)}>{t('Copy')}</button>}
                                                {Service.editPerms(userPerms) == false ? "" : <button className="btn btn-update  btn-sm ml-2" type="submit" style={{ float: "right",width: "50px" }} onClick={(event) => checkDelete()}>{t('Delete')}</button>}

                                            </>
                                            :
                                            deleteshowbutton === true ?
                                                <>
                                                    {Service.editPerms(userPerms) == false ? "" : <button className="btn btn-update  btn-sm ml-2" style={{ float: "right",width: "50px" }} type="submit" onClick={(event) => checkDelete()}>{t('Delete')}</button>}
                                                </> :
                                                ''
                                        }
                                    </div>

                                </div>

                                <table className="table table-bordered text-left mt-2 table-responsive-sm" id="tableA">
                                    <thead className="text-white rt-thead" style={{ backgroundColor: "var(--primary)" }}>
                                        <tr>
                                            <th className="tableheader" style={{ width: '30px' }}>
                                                <input type="checkbox" id="option-all" onChange={checkAll} />
                                            </th>
                                            <th id="agreementid" style={{ backgroundColor: (filterValueDict.agreementid ? '#6cae55' : ''), maxWidth: '250px' }} hidden={hideColumn.includes('agreementid')?true:false}>
                                                <div className='d-flex text-nowrap'>
                                                    <span>{t('ship&debit.AgreementId')}</span>
                                                    <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('AgreementId')} ></span>
                                                    <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('agreementid')} ></span>
                                                </div>
                                            </th>
                                            <th id="distributorname" style={{ backgroundColor: (multiFilterValue.distributorname ? '#6cae55' : ''), maxWidth: '250px' }} hidden={hideColumn.includes('distributorname')?true:false}>
                                                <div className='d-flex text-nowrap'>
                                                    <span>{t('ship&debit.DistributorName')}</span>
                                                    <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('DistributorName')} ></span>
                                                    <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('distributorname')} ></span>
                                                </div>
                                            </th>
                                            <th id="endcustomername" style={{ backgroundColor: (multiFilterValue.endcustomername ? '#6cae55' : ''), maxWidth: '250px' }} hidden={hideColumn.includes('endcustomername')?true:false}>
                                                <div className='d-flex text-nowrap'>
                                                    <span>{t('ship&debit.EndCustomer')}</span>
                                                    <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('EndcustomerName')} ></span>
                                                    <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('endcustomername')} ></span>
                                                </div>
                                            </th>
                                            <th id="status" style={{ backgroundColor: (filterValueDict.status ? '#6cae55' : ''), maxWidth: '250px' }} hidden={hideColumn.includes('status')?true:false}>
                                                <div className='d-flex text-nowrap'>
                                                    <span>{t('ship&debit.Status')}</span>
                                                    <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('Status')} ></span>
                                                    <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('status')} ></span>
                                                </div>
                                            </th>

                                            <th id="created_by" style={{ backgroundColor: (multiFilterValue.created_by ? '#6cae55' : ''), maxWidth: '220px' }} hidden={hideColumn.includes('created_by')?true:false}>
                                                <div className='d-flex text-nowrap'>
                                                    <span>{t('CreatedBy')}</span>
                                                    <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('CreatedUserName')} ></span>
                                                    <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('created_by')} ></span>
                                                </div>
                                            </th>

                                            <th id="created_date" style={{ backgroundColor: (filterValueDict.created_date ? '#6cae55' : ''), maxWidth: '200px' }} hidden={hideColumn.includes('created_date')?true:false}>
                                                <div className='d-flex text-nowrap'>
                                                    <span>{t('ship&debit.CreatedOn')}</span>
                                                    <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('CreatedDate')} ></span>
                                                    <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('created_date')} ></span>
                                                </div>
                                            </th>
                                            <th id="last_modified_by" style={{ backgroundColor: (multiFilterValue.last_modified_by ? '#6cae55' : ''), maxWidth: '200px' }} hidden={hideColumn.includes('last_modified_by')?true:false}>
                                                <div className='d-flex text-nowrap'>
                                                    <span>{t('ModifiedBy')} &nbsp;</span>
                                                    <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('LastModifiedUserName')} ></span>
                                                    <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('last_modified_by')}></span>
                                                </div>
                                            </th>
                                            <th id="last_modified_date" style={{ backgroundColor: (filterValueDict.last_modified_date ? '#6cae55' : ''), maxWidth: '200px' }} hidden={hideColumn.includes('last_modified_date')?true:false}>
                                                <div className='d-flex text-nowrap'>
                                                    <span>{t('ship&debit.ModifiedOn')}  </span>
                                                    <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('LastModifiedDate')}></span>
                                                    <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('last_modified_date')}></span>
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            showspinner === true ?
                                                <tr>
                                                    <Spinner animation="border" size="sm" />
                                                </tr>
                                                :
                                                filteredArray.length === 0 ?
                                                    <tr>
                                                        <td></td>
                                                        <td colSpan="8" style={{textAlign: 'center'}}>{t('NoDataAvailable')}</td>
                                                    </tr>
                                                    :
                                                    filteredArray.map((listVal, index) =>
                                                        <tr key={listVal.AgreementId} onDoubleClick={(event) => Open(event, listVal.Id)}>
                                                            <td><input id={listVal.Id} type="checkbox" name="chkBox" value={[listVal.Id, listVal.Status]} onChange={(event) => handleChange(event, listVal.Id, listVal.Status, listVal.AgreementId)} /></td>
                                                            <td style={{ minWidth: "110px" }} hidden={hideColumn.includes('agreementid')?true:false}>{listVal.AgreementId}</td>
                                                            <td style={{ minWidth: "110px" }} hidden={hideColumn.includes('distributorname')?true:false}>{listVal.DistributorName}</td>
                                                            <td style={{ minWidth: "110px" }} hidden={hideColumn.includes('endcustomername')?true:false}>{listVal.EndcustomerName}</td>
                                                            <td style={{ minWidth: "110px" }} hidden={hideColumn.includes('status')?true:false}>{listVal.Status}</td>
                                                            <td style={{ minWidth: "110px" }} hidden={hideColumn.includes('created_by')?true:false}>{listVal.CreatedUserName}</td>
                                                            <td style={{ minWidth: "110px" }} hidden={hideColumn.includes('created_date')?true:false}>{moment(listVal.CreatedDate).format(SessionManage.getcurrentdateformat())}</td>
                                                            <td style={{ minWidth: "110px" }} hidden={hideColumn.includes('last_modified_by')?true:false}>{listVal.LastModifiedUserName}</td>
                                                            <td style={{ minWidth: "110px" }} hidden={hideColumn.includes('last_modified_date')?true:false}>{moment(listVal.LastModifiedDate).format(SessionManage.getcurrentdateformat())}</td>
                                                        </tr>
                                                    )
                                        }
                                    </tbody>
                                </table>

                                {
                                    showLoadMore === true ?
                                        <div className='text-center container row'>
                                            <div className='col-md-5'></div>
                                            <div className='col-md-2'>
                                                <button className='btn btn-sm btn-update ml-1' onClick={handleLoadMore} style={{width: "70px"}}>{t('LoadMore')}</button>
                                            </div>
                                            <div className='col-md-5'></div>

                                        </div>
                                        :
                                        ""
                                }
                                <div className='row'>

                                    {
                                        totalselectedshow === true ?

                                            <div className='col-lg-2 forgettext col-sm-12 border' style={{ textAlign: "center" }}>
                                                {t('TotalRecordsSelected')} - <span id="totalselected">{totalselectedcount}</span>
                                            </div>

                                            :
                                            <div className='col-lg-2  col-sm-12' style={{ textAlign: "center" }}>

                                            </div>
                                    }



                                    <div className='col-lg-8 col-sm-12'></div>


                                    <div className='col-lg-2 forgettext  col-sm-12 border' style={{ textAlign: "center" }}>
                                        {t('TotalRecords')} - {totalCountlst}
                                    </div>
                                </div>

                                {showfilterpopup === true ?
                                    <div className='pop-up' style={{ left: divleft, top: divtop }}>
                                        {filterCol === 'agreementid' || filterCol === 'status' ?

                                            <>
                                                <div className="container">
                                                    <div className="row">
                                                        <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>
                                                    </div>
                                                </div>
                                                <div className="container mt-2">
                                                    <div className="row">
                                                        <input type='textbox' placeholder={t('Search')} className='filtertextboxbackground col-md-11 textbox-padding ml-2' style={{ outline: 'none' }} value={filterValueDict[filterCol]} onChange={(event) => filterInput(event)}></input>
                                                    </div>
                                                </div>
                                            </>

                                            :
                                            <>
                                            </>
                                        }

                                        {filterCol === 'last_modified_date' || filterCol === 'created_date' ?
                                            <>
                                                <div className="container">
                                                    <div className="row">
                                                        <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>
                                                    </div>
                                                </div>
                                                <div className="container mt-2">
                                                    <div className="row">
                                                        <input type='date' className='filtertextboxbackground col-md-11 textbox-padding ml-2' value={filterValueDict[filterCol]} onChange={(event) => filterInput(event)}></input>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <></>
                                        }

                                        {filterCol === 'endcustomername' || filterCol === 'last_modified_by' || filterCol === 'created_by' || filterCol === 'distributorname' ?
                                           
                                            <>
                                                <div className="container">
                                                    <div className="row">
                                                        <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>

                                                    </div>

                                                </div>

                                                <div className="container mt-2">
                                                    <div className="row">
                                                        <input type="text" placeholder={t('Search')} value={filterSearch} onChange={(event) => UserfilterHandler(event)} className='filtertextboxbackground col-md-11 textbox-padding ml-2' />
                                                    </div>

                                                    <div className="row contentoverflow mt-2">
                                                        <table className="filtertable">
                                                            <tbody>
                                                                <tr>
                                                                    <td><input type="checkbox" name="filter-option-all" id="option-all" onChange={checkAllFilter} /></td>
                                                                    <td>{t('SelectAll')}</td>
                                                                </tr>
                                                                {
                                                                    dropdownData.map((item, index) => (

                                                                        <tr key={index}>

                                                                            <td><input id={item.Id} data-index="1" name="filter-option-all" checked={item.ischecked} type="checkbox" onChange={(event) => OndropdownChange(event, item.Id, item.Name)} /></td>

                                                                            <td>{item.Name}</td>

                                                                        </tr>


                                                                    ))
                                                                }

                                                            </tbody>
                                                        </table>

                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <></>
                                        }
                                        <div style={{ paddingTop: '10px', paddingLeft: '47px', paddingBottom: '7px' }}>

                                            <button className="btn btn-sm btn-update" onClick={() => filterHandler('Apply')} style={{width: "45px"}}>{t('Apply')}</button>
                                            <button className="btn btn-sm btn-update" style={{ marginLeft: '10px',width: "45px" }} onClick={() => filterHandler('Reset')}>{t('Reset')}</button>
                                        </div>
                                        <div>
                                        </div>
                                    </div>
                                    :
                                    <></>
                                }
                                
                                {showAddColumnPopup === true ?
                                    <div className='pop-up' style={{ left: divleft, top: divtop, width: "250px" }}>
                                            <div className="container">
                                            <div className="row">
                                                <Select
                                                    className='col-md-10 textbox-padding fontsizedropdown'
                                                    options={colVisibilityOption}
                                                    placeholder="Select"
                                                    value={colvisiOptionVal}
                                                    onChange={OnChangeColVisibilityList}
                                                    styles={customStyles}
                                                />
                                                    <button  id ="createColumnListPopup" icon className="btn btn-update ml-1 pa-0" type="submit" 
                                                    style={{ float: "left", cursor: "pointer", padding: '0px', height: '28px', width: '26px'}} 
                                                    onClick={CreateColumnListPopup} title='Add New View'><i class="fa fa-plus" aria-hidden="true"></i></button>
                                            </div>
                                            <div className="row mb-2" style={{textAlign:'right'}}>
                                                <div className="col-md-10 mt-1 mr-1" style={{padding: '0px',fontSize:'12px'}}>
                                                    <input id="existingViewVal" data-index="1" name="filter-option-all" checked={existingViewVal} type="checkbox" 
                                                    onChange={(event) => OnChangeexistingViewVal(event)} /> <b>Is Default</b>
                                                    </div>
                                            </div>
                                            <div className="row">
                                                    <table className='ml-2' style={{fontSize:'0.80rem'}}>
                                                    {
                                                        addRemoveCol.map((item, index) => (
                                                            <tr>
                                                            <td key={index}><input id={item.label} data-index="1" name="filter-option-all" checked={item.value} type="checkbox"
                                                                onChange={(event) => OnchangeAddRemoveCol(event, item)} 
                                                            /> {t(item.label)}</td>
                                                            </tr>                                         
                                                        ))
                                                    }  
                                                    </table>                                      
                                            </div>
                                            <div className="row mr-2" style={{float: "right"}}>
                                            <div style={{ paddingTop: '10px', paddingLeft: '15px', paddingBottom: '7px' }}>
                                                <button className="btn btn-sm btn-update" style={{ width: "45px" }} onClick={() => updateColumnVisibilityList('reset')}>{t('Reset')}</button>
                                                <button className="btn btn-sm btn-update ml-1" style={{ width: "45px"}} onClick={closeAddRemoveColumnPopup}>{t('Close')}</button>
                                                <button className="btn btn-sm btn-update ml-1" style={{ width: "50px", background: '#4755ab',color: 'white'  }} onClick={() => updateColumnVisibilityList('apply')} title='Update View'>{t('Update')}</button>
                                                <button className="btn btn-sm btn-update ml-1" style={{ width: "50px", background: 'rgb(233 43 0)',color: 'white'  }} onClick={deleteColumnVisibilityView} title='Delete View'>{t('Delete')}</button>                                    
                                            </div>
                                            </div>
                                        </div>                                                
                                    </div>
                                    :<></>
                                }
                                

                                {showCreateColumnListPopup === true ?
                                    <div className='pop-up' style={{ left: divleft1, top: divtop1, width: "190px" }}>
                                            <div className="container">
                                            <div className="row">
                                                <div className="col-12 ml-2" style={{padding: '0px'}}>
                                                    <input type='textbox' placeholder='View Name' style={{height: '30px'}}
                                                    value={colViewName} name={colViewName} onChange={(event) => OnChangeSetViewName(event)}></input> 
                                                </div>
                                                </div>
                                                <div className="row mb-2" style={{textAlign:'right'}}>
                                                <div className="col-10 mt-2" style={{padding: '0px',fontSize:'12px'}}>
                                                    <input id="defaultView" data-index="1" name="filter-option-all" checked={defaultView} type="checkbox" 
                                                    onChange={(event) => OnChangedefaultView(event)} /> <b>Set Default</b>
                                                    </div>
                                            </div>
                                            <div className="row">
                                                    <table className='ml-2' style={{fontSize:'0.80rem'}}>
                                                    {
                                                        createColumnListOption.map((item, index) => (
                                                            <tr>
                                                            <td key={index}><input id={item.label} data-index="1" name="filter-option-all" checked={item.value} type="checkbox"
                                                                onChange={(event) => OnchangeCreateColumnListOption(event, item)} 
                                                            /> {t(item.label)}</td>
                                                            </tr>                                         
                                                        ))
                                                    }  
                                                    </table>                                      
                                            </div>
                                            <div className="row mr-2" style={{float: "right"}}>
                                            <div style={{ paddingTop: '10px', paddingLeft: '35px', paddingBottom: '7px' }}>
                                                <button className="btn btn-sm btn-update ml-1" style={{ width: "50px"}} onClick={CloseCreateColumnListPopup}>{t('Cancel')}</button>
                                                <button className="btn btn-sm btn-update ml-1" style={{ width: "40px", background: '#4755ab',color: 'white'  }} onClick={() => CreateColumnVisibilityList('apply')}>{t('Save')}</button>
                                            </div>
                                            </div>
                                        </div>

                                                    
                                    </div>
                                    :<></>
                                }

                                <CustomErrorPopup ErrorMessage={errorMessage} show={showErrorModal} ErrorStatus={errorStatus}
                                    onHide={() => setShowErrorModal(false)}></CustomErrorPopup>

                                <Modal show={showDeleteModal}
                                    size="sm"
                                    aria-labelledby="contained-modal-title-vcenter"
                                    centered>
                                    <Modal.Body style={{ "textAlign": 'center' }}>{t('Rebates.AreYouSureYouWantToDelete')}</Modal.Body>
                                    <Modal.Footer>
                                        <Button style={{ background: '#eff1f9', color: 'black', borderColor: 'black',width: "50px" }} size='sm' onClick={() => showDeleteDialog('close')}>
                                            {t('Cancel')}
                                        </Button>
                                        <Button style={{ background: '#eff1f9', color: 'black', borderColor: 'black',width: "50px" }} size='sm' onClick={() => showDeleteDialog('proceed')}>
                                            {t('Delete')}
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                                <Modal show={errorDeleteModal}
                                    size="sm"
                                    aria-labelledby="contained-modal-title-vcenter"
                                    centered>
                                    <Modal.Body style={{ "textAlign": 'center', fontFamily: 'Segoe UI' }}>
                                        {t('OnlyAgreementswithnotSubmittedcanbeDeleted')}
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button style={{ background: '#eff1f9', color: 'black', borderColor: 'black', width: "45px"}} size='sm' onClick={() => setErrorDeleteModal(false)}>
                                            {t('Close')}
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                            </div>

                        </div>
                    </div>

                </div>
            </div>



        </div>
    </>)
}
export default SpecialPriceAgreementListComponent