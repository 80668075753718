import React, { Component } from 'react' 
import { useNavigate ,useParams } from 'react-router-dom';
import MassActionSubmit from '../../../../Components/ClaimReconcilationComponent/MassAction/Submit/MassActionSubmitListReconcilation';

function MassActionSubmitListReconcilation(props){
    let navigate = useNavigate();
    const params = useParams();
     
     return (  
         <div> 
             <MassActionSubmit {...props}  navigate={navigate} id={params.id} name={params.name} />
         </div>
     );  

}
export default MassActionSubmitListReconcilation
