import React, { Component } from 'react' 
import { useNavigate ,useParams } from 'react-router-dom';
import CreateNewRebatePayoutCondPolicy from '../../../Components/PoliciesComponent/RebatePayoutCondPolicyComponent/CreateNewRebatePayoutCondPolicy';

function NewRebatePayoutCondPolicy(props){
    let navigate = useNavigate();
    const params = useParams();
     
     return (  
         <div> 
             <CreateNewRebatePayoutCondPolicy {...props}  navigate={navigate} id={params.id} name={params.name} />
         </div>
     );  

}

export default NewRebatePayoutCondPolicy