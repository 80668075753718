import React, { Component } from 'react' 
import { useNavigate ,useParams } from 'react-router-dom';
import CreateClaimReconcilationComponent from '../../Components/ClaimReconcilationComponent/CreateClaimReconcilationComponent/CreateClaimReconcilationComponent';

function CreateClaimReconcilation(props){
    let navigate = useNavigate();
    const params = useParams();
     
     return (  
         <div> 
             <CreateClaimReconcilationComponent {...props}  navigate={navigate} id={params.id} name={params.name} value={params.value}/>
         </div>
     );  

}

export default CreateClaimReconcilation