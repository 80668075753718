
import React, { useEffect, useState, useRef, forwardRef } from 'react';
import { CSVLink } from "react-csv";

import { Form, FormGroup, Col, Row, Button, InputGroup, Collapse, Modal } from 'react-bootstrap';
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import DatePicker from "react-datepicker";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { Link, useLocation } from "react-router-dom"
import Service from '../../../Services/Service';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import CustomErrorPopup from '../../../UI/CustomErrorPopup';
import Multiselect from 'multiselect-react-dropdown';
import SessionManage from '../../../SessionManagement/SessionManage';
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from 'react-toastify';
import Select from "react-select";

function CashDiscountPolicyList(props) {
    const [loading, setLoading] = useState(false);
    const [dropdownChange, setDropdownChange] = useState('');
    const csvInstance = useRef();
    const [csvList, setCsvList] = useState([]);
    const [headers, setHeaders] = useState([

        { label: "Cash Discount Id", key: "CashdiscountId" },
        { label: "Days Less Than", key: "DaysLessthan" },
        { label: "Cash Percent", key: "CashPercent" },
        { label: "Validity Start Date", key: "ValidityFrom" },
        { label: "Validity End Date", key: "ValidityTo" },
        { label: "Last Modified By", key: "LastModifiedUser" },
        { label: "Last Modified Date", key: "LastModifiedDate" },
        { label: "Created By", key: "CreatedUser" },
        { label: "Created Date", key: "CreatedDate" },
        { label: "status", key: "Status" },

    ]);
    const [t, i18n] = useTranslation('translation');
    const [sortColName, setSortColName] = useState("");
    const [sticky, setSticky] = useState("");

    const location = useLocation();
    const [userPerms, setuserPermission] = useState([]);
    const navigate = useNavigate();
    let rowData = [];
    const [showspinner, setshowspinner] = useState('');
    const [listViewData, setListViewData] = useState(rowData)
    const [filteredArray, setFilteredArray] = useState(rowData)
    const [searchValue, setSearchValue] = useState(null)
    const [checkedValue, setCheckedValue] = useState([]);
    const [checkedValueid, setCheckedValueid] = useState([]);
    const [checkedValueStatus, setCheckedValueStatus] = useState([]);
    const [errorStatus, setErrorStatus] = useState("Error");
    const [errorMessage, setErrorMessage] = useState("");
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [showbutton, setshowbutton] = useState('');
    const [isValid, setIsValid] = useState(true);
    const [showfilterpopup, setshowfilterpopup] = useState(false);
    const [columnAscending, setColumnAscending] = useState(true);
    const [filterCol, setFilterCol] = useState('');
    const [dropdownData, setDropdownData] = useState([]);
    const [totalselectedshow, settotalselectedshow] = useState('');
    const [totalselectedcount, settotalselectedcount] = useState(0);
    const [totalCountlst, settotalCountlst] = useState('');
    const [ToggleStatusModal, setToggleStatusModal] = useState(false);
    const [toggleChange, setToggleChange] = useState("");
    const [toggleId, setToggleId] = useState();
    const [filtertext, setfiltertext] = useState('');
    const [filterSearch, setFilterSearch] = useState("");
    const [filterdropdownData, setFilterDropdownData] = useState([]);
    const [filterValue, setFilterValue] = useState([]);
    const [multiValueids, setMultiValueIds] = useState([]);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [checkedMultiValueid, setCheckedMultiValueid] = useState([]);
    const [deleteshowbutton, setDeleteshowbutton] = useState('');
    const [filterValueDict, setFilterValueDict] = useState({
        CashdiscountId: '',
        CashPercent: '',
        DaysLessthan: '',
        CreatedDate: null,
        ValidityFrom: null,
        ValidityTo: null,
        LastModifiedDate: null
    });
    const [multiFilterValue, setMultiFilterValue] = useState({
        CreatedBy: '',
        LastModifiedUser: '',
        Status: ''
    });
    const [paging, setpaging] = useState({
        pageSize: 20,
        pageNumber: 1
    })
    let showSuccessMsg = false;
    let showErrorMsg = false;
    let showDetails = '';
    let loggedInUser = sessionStorage.getItem("id");
    {
        useEffect(() => {
            getUserPermission(location.pathname);
            setshowspinner(true);
            setColumnAscending(false);
            setSortColName("Id");
            getCashDiscountPolicyListViewData("CashDiscount/GetALL", paging.pageSize, paging.pageNumber, "Descending", "Id", [], null);
            getColumnVisibilityList()
            getColVisibilityListData()
            window.addEventListener("scroll", isSticky);
            return () => {
                window.removeEventListener("scroll", isSticky);
            };
        }, [props.id])
    }
    const isSticky = () => {
        const scrollTop = window.scrollY;
        const stickyClass = scrollTop >= 60 ? "is-sticky-thead" : "";
        setSticky(stickyClass);
        const scrollTopActual = scrollTop >= 60 ? scrollTop - 85 : 0;
        var divtop1 = document.getElementById("tableA");
        setdivtop(divtop1.offsetTop + scrollTopActual)
    };
    const activeOption = [
        { Name: "Active", Id: "1" },
        { Name: "In-Active", Id: "0" },

    ]

    const class_thead = `text-white rt-thead  ${sticky}`;

    const filterLst = () => {
        let list = [];
        for (var key in multiFilterValue) {
            if (multiFilterValue[key].length > 0) {
                let values = ""
                multiFilterValue[key].map((i) => {
                    values += i.Name + ";";
                })
                if (values !== null && values !== '') {
                    list.push({
                        filterKey: key,
                        FilterValue: values
                    })
                }
            }
        }

        for (var key in filterValueDict) {
            if (filterValueDict[key] !== null && filterValueDict[key] !== '') {
                list.push({
                    filterKey: key,
                    FilterValue: filterValueDict[key]
                })
            }
        }
        return list;
    }

    const ExporttoCSV = () => {
        let checkedBoxes = document.querySelectorAll("input[name=chkBox]:checked");
        let values = "";
        if (checkedBoxes.length >= 1) {
            for (let checkbox of checkedBoxes) {
                if (values === "") {
                    values = checkbox.id;
                } else {
                    values = values + ";" + checkbox.id;
                }
            }
        }
        let objFilter = [];
        let objFilterValue = [
            {
                filterKey: "ids",
                FilterValue: values,
            },
        ];
        if (totalCountlst != checkedBoxes.length) {
            objFilter = objFilterValue;
        }

        Service.getDatawithMultiFilterSearch("CashDiscount/GetALL", 20000, 1, "Ascending", "Id", objFilter, null).then((res) => {
     
            if (typeof res !== 'undefined') {
                if (res.data !== 'null' && res.data !== 'undefined') {

                 

                    let csvLst = res.data.DataList;

                    let filteredCsvList = csvLst.map(item => {
                        let newItem = {};
                        Object.keys(item).forEach(key => {
                            if (!hideColumn.includes(key)) {
                                newItem[key] = item[key];
                            }
                        });
                        return newItem;
                    });

                    let filteredHeaders = headers.filter(header => !hideColumn.includes(header.key));

                    setCsvList(filteredCsvList);
                    setHeaders(filteredHeaders);

                  

                    setTimeout(() => {
                        toast.success('Exported Successfully !', {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                        csvInstance.current.link.click();
                    });

                }
            }
        });
    }



    const [operatorValOption, setOperatorValueOption] = useState([
        { value: "=", label: "equals" },
        { value: "<", label: "less then" },
        { value: ">", label: "greater then" },
        { value: "<=", label: "less or equal" },
        { value: ">=", label: "greater or equal" }
    ])

    const [operatorVal, setOperatorValue] = useState({ value: "=", label: "equals" })

    const customStyles = {
        control: base => ({
            ...base,
            minHeight: 28
        }),
        dropdownIndicator: base => ({
            ...base,
            padding: 2
        }),
        clearIndicator: base => ({
            ...base,
            padding: 2
        }),
        multiValue: base => ({
            ...base,

        }),
        valueContainer: base => ({
            ...base,
            padding: '0px 2px'
        }),
        input: base => ({
            ...base,
            margin: 0,
            padding: 0
        })
    };
    const OnChangeOperatorValue = (data) => {
        setOperatorValue({ value: data.value, label: data.label });
    }

    const checkAll = (mycheckbox) => {
        var checkboxes = document.querySelectorAll("input[type = 'checkbox']");
        if (mycheckbox.target.checked == true) {
            settotalselectedshow(true);
            checkboxes.forEach(function (checkbox) {
                checkbox.checked = true;
            });
            settotalselectedcount(totalCountlst)
        }
        else {
            settotalselectedshow(false);
            checkboxes.forEach(function (checkbox) {
                checkbox.checked = false;
            });
            settotalselectedcount(0)
        }

    }
    const getUserPermission = (pathname) => {
        Service.GetUserPermission(pathname).then((res) => {

            if (res !== undefined) {
        
                if (res.data !== 'null' && res.data !== 'undefined' && res.data !== '') {
                    if (res.data.DataList !== undefined) {
                        setuserPermission(res.data.DataList)
                    }
                }
            }
        });
    }
    const searchHandler = (event) => {
        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
        let pagenumber = 1;
        paging.pageNumber = pagenumber;
        setpaging(paging);
        if (event.target.value != '') {
         
            setSearchValue(String(event.target.value).toLowerCase())
            getCashDiscountPolicyListViewData("CashDiscount/GetALL", paging.pageSize, pagenumber, sortType, sortColName, filterLst(), event.target.value);
        }
        else {
            setSearchValue(null);
            getCashDiscountPolicyListViewData("CashDiscount/GetALL", paging.pageSize, pagenumber, sortType, sortColName, filterLst(), null);
        }
    }

    const handleLoadMore = () => {

        let pagenumber = paging.pageNumber + 1;
        var sortType = columnAscending === true ? 'Ascending' : 'Descending';

        Service.getAllPolicydataCashDiscount("CashDiscount/GetALL", paging.pageSize, pagenumber, sortType, sortColName, filterLst(), searchValue).then((res) => {
            if (typeof res !== 'undefined') {
                if (res.data !== 'null' && typeof res.data !== 'undefined') {
                    const currentlength = data.length;
                    setdata(data => [...data, ...res.data.DataList])
                    setFilteredArray(filteredArray => [...filteredArray, ...res.data.DataList])
                    LoadMore(currentlength + res.data.DataList.length, res.data.Totalcount)
                }
            }
        });
        //paging.pageSize = pagesize;
        paging.pageNumber = pagenumber
        setpaging(paging);
    };
    const [data, setdata] = useState([]);

    const [showLoadMore, setshowLoadMore] = useState('');
    const LoadMore = (lst, totalcount) => {

        if (lst < totalcount) {
            setshowLoadMore(true);
        }
        else if (lst >= totalcount) {
            setshowLoadMore(false);
        } else {
            setshowLoadMore(false);
        }

    }
    const handleChange = (e, id, name, Status) => {
        let isChecked = e.target.checked;   // true if it is checked false if unchecked

        let checkedBoxes = document.querySelectorAll('input[name=chkBox]:checked');
        if (isChecked === true) {

            setCheckedValue(id)  // check box value - id
            setCheckedValueStatus(Status) // check box value - Active status
            setCheckedValueid(name) //check box value - RA-0000
            settotalselectedshow(true);
            settotalselectedcount(checkedBoxes.length);
        }
        else {

            setCheckedValue([])  // check box value - id 
            setCheckedValueStatus([]) // check box value - Active status
            setCheckedValueid([]) //check box value - RA-0000
            if (checkedBoxes.length === 0) {
                settotalselectedshow(false);
            }
            settotalselectedcount(checkedBoxes.length);
        }

       
        if (checkedBoxes.length === 1) {
            setshowbutton(true);
        }
         else if (checkedBoxes.length > 1) {
          setshowbutton(false);
          setDeleteshowbutton(true)
        }
        else {
            setshowbutton(false);
        }

    }

    const OpenHandler = (id) => {
        if (id == null || id == 0) {
            alert('Please select the Workflow Rule to view')
        }
        else {
            navigate("/CashDiscountPolicylist/new/" + id);
        }
    }



   const getCashDiscountPolicyListViewData = (method, pageSize, pageNumber, sortType, SortProperty, filterValue, search) => {
        console.log('inside getreb', method)

        Service.getAllPolicydataCashDiscount(method, pageSize, pageNumber, sortType, SortProperty, filterValue, search).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                            rowData = res.data.DataList;
                            setListViewData(rowData)
                            settotalCountlst(res.data.Totalcount)
                            setFilteredArray(rowData)
                            setshowspinner(false)
                            setdata(rowData)
                            LoadMore(rowData.length, res.data.Totalcount)
                        }
                    }
                }
            }

        })
    }

  


    const TogglePolicyActiveStatus = (id, flag) => {
        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
        setToggleId(id)
        if (flag == "Open-A") {
            setToggleStatusModal(true)
            setToggleChange("DeActivate")
        }
        if (flag == "Open-D") {
            setToggleStatusModal(true)
            setToggleChange("Activate")
        }
        if (flag == "Ok") {
            Service.TogglePolicyActiveStatus('CashDiscount/ToggleCashDiscountActiveStatus', toggleId, loggedInUser).then((res) => {
                if (typeof res !== 'undefined') {
                    if (typeof res.data !== 'undefined' && res.data !== 'null') {
                        if (res.status === 200) {
                            getCashDiscountPolicyListViewData("CashDiscount/GetALL", paging.pageSize, paging.pageNumber, sortType, sortColName, filterLst(), searchValue)

                        }
                    }
                }

            });
            setToggleStatusModal(false)
        }
        if (flag == "Cancel") {
            setToggleStatusModal(false)
        }
    }

    const DropdownHandler = (key) => {
        Service.getfilterDropdown('CashDiscount/GetAllFilters', key).then((res) => {
            if (res && res.status === 200) {
                if (typeof res !== 'undefined') {
                    if (typeof res.data !== 'undefined' && res.data !== 'null') {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                            //setDropdownData(res.data)
                            addnewfield(res.data, key)
                        }
                    }
                }

            }
        });
    }

    const [divleft, setdivleft] = useState('');
    const [divtop, setdivtop] = useState('');
    const FilterColumn = (name) => {

        const scrollTop = window.scrollY;
        const scrollTopActual = scrollTop >= 60 ? scrollTop - 85 : 0;
        var divtop1 = document.getElementById("tableA");
        setdivtop(divtop1.offsetTop + scrollTopActual + 30)


        setshowfilterpopup(true)
        setFilterCol(name)
        if (name === 'CashdiscountId') {
            setfiltertext(t('Policies.CashDiscountId'))
            var testDiv = document.getElementById("CashdiscountId");
            setdivleft(testDiv.offsetLeft + 20 + "px");
        }
        if (name === 'DaysLessthan') {
            setfiltertext(t('Policies.DaysLessThan'))
            var testDiv = document.getElementById("DaysLessthan");
            setdivleft(testDiv.offsetLeft + 20 + "px");
        }
        if (name === 'CashPercent') {
            setfiltertext(t('Policies.CashPercent'))
            var testDiv = document.getElementById("CashPercent");
            setdivleft(testDiv.offsetLeft + 20 + "px");
        }
        if (name === 'CreatedDate') {
            setfiltertext(t('CreatedDate'))
            var testDiv = document.getElementById("CreatedDate");
            setdivleft(testDiv.offsetLeft + 20 + "px");
        }
        if (name === 'ValidityFrom') {
            setfiltertext(t('Rebates.ValidityPeriod'))
            var testDiv = document.getElementById("ValidityFrom");
            setdivleft(testDiv.offsetLeft + 20 + "px");
        }
        if (name === 'ValidityFrom') {
            setfiltertext(t('Rebates.ValidityStartDate'));
            setdivleft(testDiv.offsetLeft + 20 + "px");
        }
        if (name === 'ValidityTo') {
            setfiltertext(t('Rebates.ValidityEndDate'));
            setdivleft(testDiv.offsetLeft + 20 + "px");
        }
        if (name === 'LastModifiedDate') {
            setfiltertext(t('LastModifiedDate'))
            var testDiv = document.getElementById("LastModifiedDate");
            setdivleft(testDiv.offsetLeft + 20 + "px");
        }
        if (name === 'CreatedBy') {
            setfiltertext(t('CreatedBy'))
            var testDiv = document.getElementById("CreatedBy");
            setdivleft(testDiv.offsetLeft + 20 + "px");
            DropdownHandler(name)
        }
        if (name === 'LastModifiedUser') {
            setfiltertext(t('LastModifiedBy'))
            var testDiv = document.getElementById("LastModifiedUser");
            setdivleft(testDiv.offsetLeft + 20 + "px");
            DropdownHandler(name)
        }
        if (name === 'Status') {
            setfiltertext(t('Status'))
            var testDiv = document.getElementById("Status");
            setdivleft(((testDiv.offsetLeft + 30) - 100) + "px");
            DropdownHandler(name)
        }
    }

    const OpenPolicy = (event, id) => {
        if (event.detail === 2) {
            // alert('doubleclicked')
            navigate("/CashDiscountPolicylist/new/" + id);
        }
    }
    const SortColumn = (colName) => {

        //   getCashDiscountPolicyListViewData("CashDiscount", paging.pageSize, paging.pageNumber, sortType, colName, []);
        var sortType = '';
        if (columnAscending === true) {
            setColumnAscending(false);
            sortType = "Descending";
        } else {
            setColumnAscending(true);
            sortType = "Ascending";
        }
        setSortColName(colName);
    
        let pagenumber = 1;
  
        paging.pageNumber = pagenumber;
        setpaging(paging);
        getCashDiscountPolicyListViewData("CashDiscount/GetALL", paging.pageSize, paging.pageNumber, sortType, colName, filterLst(), searchValue);
    }


    const filterInput = (e) => {

        let inputValue = e.target.value
        setFilterValueDict(() => ({
            ...filterValueDict, [filterCol]: e.target.value,
        }));
    }

    const OndropdownChange = (e, id, name) => {
        let isChecked = e.target.checked;
        var lastfilter = [...dropdownData]
        if (isChecked === true) {
            let currentdata = lastfilter.map((i) => {
                if (i.Id == id) {
                    i["ischecked"] = true;
                }
                return i;
            });
         
            setDropdownData(currentdata);
            let data1 = []
            const getallData = {
                Name: name,
            }
            data1.push(getallData)
            setFilterValue(filterValue => [...filterValue, ...data1]);
            let updateddata = ([...filterValue, ...data1]);
            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: updateddata,
            }));
        }
        else {
            let currentdata = lastfilter.map((i) => {
                if (i.Id === id) {
                    i["ischecked"] = false;
                }
                return i;
            });
       
            setDropdownData(currentdata);
            setFilterValue(filterValue => filterValue.filter(data => data.Name !== name))
            let data1 = multiFilterValue.filter(data => data.Name !== name);
            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: data1,
            }));
        }
    }
    const addnewfield = (data, key) => {
        let selectedKeys = multiFilterValue[key];
        let currentdata = data.map((i) => {
            i["ischecked"] = false;
            return i;
        });
        if (key != 'Status') {
            if (selectedKeys != '') {
                currentdata.forEach((each) => {
                    selectedKeys.forEach((eachSelected) => {
                        if (each.Name == eachSelected.Name) {
                            each.ischecked = true
                        }
                    })
                })
            }
          
            setDropdownData(currentdata);
            setFilterDropdownData(currentdata)
        }
        else {
            if (selectedKeys != '') {
                currentdata.forEach((each) => {
                    selectedKeys.forEach((eachSelected) => {
                        if (each.Name == eachSelected.Name) {
                            each.ischecked = true
                        }
                    })
                })
                setDropdownData(currentdata)
                setFilterDropdownData(currentdata)
            }
            else {
                setDropdownData(currentdata)
                setFilterDropdownData(currentdata)
            }
        }
    }

    const checkAllFilter = (mycheckbox) => {
        var lastfilter = [...dropdownData]
        let data1 = []
        var checkboxes = document.querySelectorAll("input[name = 'filter-option-all']");
        if (mycheckbox.target.checked == true) {
           
            let currentdata = lastfilter.map((i) => {
                i["ischecked"] = true;
                return i;
            });
            setDropdownData(currentdata)
        
            dropdownData.map((each) => {
             
                const getallData = {
                    Name: each.Name,
                }
                data1.push(getallData)
            })
            setFilterValue(filterValue => [...filterValue, ...data1]);
            let updateddata = ([...filterValue, ...data1]);
            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: updateddata,
            }));
        }
        else {
          
            let currentdata = lastfilter.map((i) => {
                i["ischecked"] = false;
                return i;
            });
            setFilterValue([])
            setMultiFilterValue([])
        }

    }
    const UserfilterHandler = (e) => {

        setFilterSearch(e.target.value)
        if (e.target.value !== "") {
            let searchResults = dropdownData.filter((each) => {
                return each.Name.toLowerCase().includes(String(e.target.value).toLowerCase())
            });
            setDropdownData(searchResults);
        }
        else {

            setFilterSearch("")
            setDropdownData(filterdropdownData)
        }
    }

    const filterHandler = (flag) => {

        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
        let pagenumber = 1;
        paging.pageNumber = pagenumber;

        if (flag === 'Apply') {
            setshowfilterpopup(false)
            let list = [];

            for (var key in multiFilterValue) {
                if (multiFilterValue[key].length > 0) {
                    let values = ""
                    multiFilterValue[key].map((i) => {
                        values += i.Name + ";";
                    })
                    if (values !== null && values !== '') {
                        list.push({
                            filterKey: key,
                            FilterValue: values
                        })
                    }
                }
            }
            for (var key in filterValueDict) {
                if (filterValueDict[key] !== null && filterValueDict[key] !== '') {
                    if (key === 'DaysLessthan' || key === 'CashPercent') {
                        list.push({
                            filterKey: key,
                            FilterValue: filterValueDict[key],
                            operatorValue: operatorVal.value
                        })
                    }
                    else {
                        list.push({
                            filterKey: key,
                            FilterValue: filterValueDict[key]
                        })
                    }
                }
            }
            if (list.length > 0) {
                let pagesize = 20;
                let pagenumber = 1;
                paging.pageSize = pagesize;
                paging.pageNumber = pagenumber;
                setpaging(paging);
                if (list.length > 0) {
                    setpaging(paging);
                 
                    getCashDiscountPolicyListViewData("CashDiscount/GetALL", paging.pageSize, pagenumber, sortType, sortColName, list, searchValue);
                }
            }
        }
        else {
            setshowfilterpopup(false)
            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: '',
            }));
            setFilterValueDict(() => ({
                ...filterValueDict, [filterCol]: '',
            }));

            let list = [];
            for (var key in multiFilterValue) {
                if (key !== filterCol && multiFilterValue[key].length > 0) {
                    let values = ""
                    multiFilterValue[key].map((i) => {
                        values += i.Name + ";";
                    })
                    if (values !== null && values !== '') {
                        list.push({
                            filterKey: key,
                            FilterValue: values
                        })
                    }
                }
            }

            for (var key in filterValueDict) {
                if (filterValueDict[key] !== null && filterValueDict[key] !== '' && key !== filterCol) {
                    list.push({
                        filterKey: key,
                        FilterValue: filterValueDict[key]
                    })
                }
            }

            setpaging(paging);
            getCashDiscountPolicyListViewData("CashDiscount/GetALL", paging.pageSize, pagenumber, sortType, sortColName, list, searchValue);
            setFilterCol('')
            setFilterValue('')
            setDropdownData([])
            setDropdownChange("")
            setFilterSearch("")
            setFilterDropdownData([])
            if (filterCol === 'accruals_aggregate') {
                setOperatorValue({ value: "=", label: "equals" });
            }
        }
    }
    const customMultiStyles = {
        multiselectContainer: {
            maxWidth: '188px',
            marginLeft: '3px'
        },
        chips: {
            borderRadius: '5px'
        }
    };
    const CopyHandler = (id) => {
        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
        let rowData = {}
        let pagenumber = 1;
        paging.pageNumber = pagenumber;
        setpaging(paging);
        setLoading(true);
        Service.PostWithIdAndUser('CashDiscount/Copy', id, Service.getUserId()).then((res) => {
            if (typeof res !== 'undefined') {
                if (res.data !== 'null' && res.data !== 'undefined') {
                    setLoading(false);
                    getCashDiscountPolicyListViewData("CashDiscount/GetAll", paging.pageSize, pagenumber, sortType, sortColName, filterLst(), searchValue)

                    toast.success('Copy Successfully !', {
                        position: toast.POSITION.TOP_RIGHT,
                    });

                }
            }
            else
                setLoading(false);
        });
    }
    const DeleteHandler = (id) => {
        var sortType = columnAscending === true ? 'Ascending' : 'Descending';

        Service.deleteDataByPolicyID("CashDiscount/DeleteRecord", id, loggedInUser).then(res => {
         
            if (res.status == 200) {
                if (res.data !== 'null' && res.data !== 'undefined') {
                console.log('12/12',res.data)
                    if (res.data == "Success") {
                        toast.error('Deleted successfully !', {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                       
                        getCashDiscountPolicyListViewData("CashDiscount/GetAll", paging.pageSize, paging.pageNumber, sortType, sortColName, filterLst(), searchValue)
                        setShowDeleteModal(false)
                    }
                    else {
                        setShowDeleteModal(false)
                        setShowErrorModal(true)
                        setErrorMessage(res.data);
                        setErrorStatus("Error")

                    }
                }
            }
            else {
                setShowErrorModal(true)
                setErrorStatus("Error")
                setErrorMessage("Active Policy cannot be deleted")
            }
        })
    }
    const CreateNew = () => {
        navigate("/CashDiscountPolicylist/new/0")
    }

    // -----------------------------------------------------------------
    const columnList = [
        { "label": "Policies.CashDiscountId", "key": "CashdiscountId", "value": true },
        { "label": "Policies.DaysLessThan", "key": "DaysLessthan", "value": true },
        { "label": "Policies.CashPercent", "key": "CashPercent", "value": true },
        { "label": "Rebates.ValidityStartDate", "key": "ValidityFrom", "value": true },
        { "label": "Rebates.ValidityEndDate", "key": "ValidityTo", "value": true },
        { "label": "LastModifiedBy", "key": "LastModifiedUser", "value": true },
        { "label": "LastModifiedDate", "key": "LastModifiedDate", "value": true },
        { "label": "CreatedBy", "key": "CreatedUser", "value": true },
        { "label": "CreatedDate", "key": "CreatedDate", "value": true },
        { "label": "Status", "key": "Status", "value": true }
    ]
    const [addRemoveCol, setAddRemoveCol] = useState(columnList);
    const [createColumnListOption, setCreateColumnListOption] = useState(columnList)
    const [showAddColumnPopup, setShowAddColumnPopup] = useState(false)
    const [showCreateColumnListPopup, setShowCreateColumnListPopup] = useState(false)
    const [hideColumn, setHideColumn] = useState([]);
    const [resetColumnKey, setResetColumnKey] = useState([]);
    const [colVisibilityOption, setColVisibilityOption] = useState([]);
    const [colvisiOptionVal, setColvisiOptionVal] = useState({ value: null, label: 'Default' })
    const [divleft1, setdivleft1] = useState('');
    const [divtop1, setdivtop1] = useState('');
    const [existingViewVal, setExistingViewVal] = useState(false);
    const [defaultView, setDefaultView] = useState(false);
    const [colViewName, setColViewName] = useState('');
    const [recorddId, setRecordId] = useState(0);


    const OnChangedefaultView = (event,) => {
        setDefaultView(event.target.checked)
    }
    const OnChangeexistingViewVal = (event,) => {
        setExistingViewVal(event.target.checked)
    }

    const OnChangeSetViewName = (event,) => {
        setColViewName(event.target.value)
    }

    const OnChangeColVisibilityList = (data) => {
        setColvisiOptionVal({ value: data.value, label: data.label });
        getColVisibilityListData(data.value)
    }
    const CreateColumnListPopup = () => {
        setShowCreateColumnListPopup(true)
        var divtop1 = document.getElementById("addRemoveCol");
        setdivtop1(divtop1.offsetTop + 30)
        setdivleft1((divtop1.offsetLeft) + 100 + "px");
    }

    const CloseCreateColumnListPopup = () => {
        setShowCreateColumnListPopup(false)
        setColViewName('')
        setDefaultView(false)
    }

    const AddRemoveColumn = () => {
        setShowAddColumnPopup(true)
        var divtop1 = document.getElementById("addRemoveCol");
        setdivtop(divtop1.offsetTop + 45)
        setdivleft((divtop1.offsetLeft) + "px");
        getColumnVisibilityList()
    }
    const OnchangeAddRemoveCol = (event, column) => {
        let isChecked = event.target.checked;
        let lst = [...addRemoveCol];
        for (let d in lst) {
            if (lst[d].key == column.key && isChecked) {
                lst[d]['value'] = true
            }
            else if (lst[d].key == column.key && !isChecked) {
                lst[d]['value'] = false
            }
        }
        setResetColumnKey([...resetColumnKey, column.key])
        setAddRemoveCol(lst)
    }
    const OnchangeCreateColumnListOption = (event, column) => {
        let isChecked = event.target.checked;
        let lst = [...createColumnListOption];
        for (let d in lst) {
            if (lst[d].key == column.key && isChecked) {
                lst[d]['value'] = true
            }
            else if (lst[d].key == column.key && !isChecked) {
                lst[d]['value'] = false
            }
        }
        setCreateColumnListOption(lst)
    }
    const updateColumnVisibilityList = (tag) => {
        let lst = [...addRemoveCol];
        if (tag == 'reset') {
            for (let d in lst) {
                lst[d]['value'] = true
            }
            setHideColumn([])
            setAddRemoveCol(lst)
        }
        else {
            let data = [];
            for (let d in lst) {
                if (!lst[d].value) {
                    data.push(lst[d].key)
                }
            }
            setHideColumn(data)
        }
        let viewName = colvisiOptionVal.value;
        Service.setColumnVisibilityListData('ColumnVisibility/CreateOrUpdateColumnList', recorddId, loggedInUser, 'cashDiscountList', viewName, existingViewVal, lst).then((res) => { })
        setShowAddColumnPopup(false)
        setResetColumnKey([])
    }


    const CreateColumnVisibilityList = (tag) => {
        let veiwName = colViewName;
        let idDefault = defaultView;
        if (veiwName.trim() == 'undefined' || veiwName.trim() == '' || veiwName.trim() == null) {
            setErrorStatus("Error")
            setErrorMessage("Please set View Name")
            setShowErrorModal(true)
        }
        else {
            let lst = [...createColumnListOption];
            if (tag == 'reset') {
                for (let d in lst) {
                    lst[d]['value'] = true
                }
                setHideColumn([])
                setAddRemoveCol(lst)
            }
            else {
                let data = [];
                for (let d in lst) {
                    if (!lst[d].value) {
                        data.push(lst[d].key)
                    }
                }
                setHideColumn(data)
            }
            Service.setColumnVisibilityListData('ColumnVisibility/CreateOrUpdateColumnList', 0, loggedInUser, 'cashDiscountList', colViewName, defaultView, lst).then((res) => {
                getColumnVisibilityList()
                setColvisiOptionVal({ value: colViewName, label: colViewName });
                getColVisibilityListData(colViewName)
                CloseCreateColumnListPopup()
                setShowAddColumnPopup(false)
            })
        }
    }

    const closeAddRemoveColumnPopup = () => {
        let lst = [...addRemoveCol];
        let resetKey = [...resetColumnKey];
        for (let rk in resetKey) {
            for (let d in lst) {
                if (lst[d].key == resetKey[rk]) {
                    lst[d]['value'] = !lst[d].value
                }
            }
        }
        setAddRemoveCol(lst)
        setShowAddColumnPopup(false)
        setResetColumnKey([])

    }

    const getColumnVisibilityList = () => {
        Service.getColumnVisibilityListData('ColumnVisibility/GetColumnList', loggedInUser, 'cashDiscountList', null).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                  
                        if (typeof res.data !== 'undefined' && res.data !== 'null') {
                            let data = []
                            for (let d in res.data) {
                                data.push({ value: res.data[d].label, label: res.data[d].label })

                            }
                      
                            setColVisibilityOption(data)
                        }
                    }
                }
            }
        })
    }


    const getColVisibilityListData = (viewName = null) => {
        if (!viewName) {
            viewName = colvisiOptionVal.value
        }
        if (viewName == 'Deleted') {
            viewName = null
        }
        Service.getColumnVisibilityListData('ColumnVisibility/GetColumnListJsonData', loggedInUser, 'cashDiscountList', viewName).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (typeof res.data.jsonData !== 'undefined' && res.data.jsonData !== 'null') {
                          
                            let jsonData = JSON.parse(res.data.jsonData)
                            let data = [];
                            for (let d in jsonData) {
                                if (!jsonData[d].value) {
                                    data.push(jsonData[d].key)
                                }
                            }
                            setColvisiOptionVal({ value: res.data.label, label: res.data.label });
                            setHideColumn(data)
                            setAddRemoveCol(jsonData)
                            setExistingViewVal(res.data.defaultView)
                            setResetColumnKey([])
                            setRecordId(res.data.id)
                        }
                    }
                }
            }
        })
    }

    const deleteColumnVisibilityView = () => {
        Service.deleteDataByPolicyID("ColumnVisibility/Delete", recorddId, loggedInUser).then(res => {
            if (res.status === 200) {
                getColumnVisibilityList()
                getColVisibilityListData('Deleted')
                setShowAddColumnPopup(false)
                alert('Deleted Successfully')
            }
        })
    }
          const checkDelete = () => {
          debugger;
            let checkedBoxes = document.querySelectorAll('input[name=chkBox]:checked');
            if (checkedBoxes.length == 1) {
                setMultiValueIds(checkedValue)
                showDeleteDialog('open')
            }
            if (checkedBoxes.length > 1) {
              setCheckedMultiValueid([])
              for (let checkbox of checkedBoxes) {
              console.log('12/12',checkbox.defaultValue)
                checkedMultiValueid.push(checkbox.defaultValue)
              }
              checkedMultiValueid.map(i => {

                  var z = i.split(",");
                  multiValueids.push(z[0])
                  showDeleteDialog('open')

              })
            }
          }
     // delete modal handler
      const showDeleteDialog = (flag) => {
        if (flag === 'open') {
          setShowDeleteModal(true)
        }
        if (flag === 'close') {
          setShowDeleteModal(false)
          let checkedBoxes = document.querySelectorAll('input[name=chkBox]:checked');
          for (let checkbox of checkedBoxes) {
            checkbox.checked = false;
          }
          setMultiValueIds([])
           setDeleteshowbutton(false)
        }
        if (flag === 'proceed') {

          DeleteHandler(multiValueids)
          let checkedBoxes = document.querySelectorAll('input[name=chkBox]:checked');
          for (let checkbox of checkedBoxes) {
            checkbox.checked = false;
          }
           setDeleteshowbutton(false)
        }
      }

    // ------------------------------------------------------------

    return (
        <div className="container-fluid content-top-gap">

            <nav aria-label="breadcrumb">
                <ol className="breadcrumb my-breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">{t('Home.title')}</Link></li>

                    <li className="breadcrumb-item active" aria-current="page"><Link to="/policies">{t('Policies.Policy')}</Link></li>

                    <li className="breadcrumb-item active" aria-current="page">{`${t('Policies.CashDiscount')}${t('Policies.PolicyList')}`}</li>
                </ol>
            </nav>

            <div style={{ display: "none" }}>
                <CSVLink data={csvList} headers={headers} ref={csvInstance} filename="CashDiscount.csv" > </CSVLink>
            </div>
            <div className="">
                <div className="row">
                    <div className="col-lg-12 pr-lg-2">
                        <div className="card text-center card_border overflow-auto">

                            <div className="card-body" style={{ minHeight: "70vh" }}>
                                <div className="row ml-1">

                                    <div className="col-md-7">
                                        <div className='row'>
                                            <input type="text" className="form-control form-control-user col-md-10" id="firstName" name="firstName" autoComplete='off' placeholder={`${t('SearchFor')} ${t('Policies.CashDiscountId')},${t('CreatedBy')} ${t('Or')} ${t('LastModifiedBy')}`} onChange={(event) => searchHandler(event)} />
                                            <button id="addRemoveCol" icon className="btn btn-update ml-2 mt-1 pa-0" type="submit"
                                                style={{ float: "left", cursor: "pointer", padding: '0px', height: '29px', width: '26px' }}
                                                onClick={AddRemoveColumn} title='Manage View'><i class="fa fa-columns" aria-hidden="true"></i></button>
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                        <a className="btn btn-update  btn-sm ml-2" type="submit" style={{ width: "90px", backgroundColor: "buttonface", float: "right", width: "75px" }} onClick={() => CreateNew()} >{t('CreateNew')}</a>
                                        {totalselectedcount <= 0 ? "" : Service.exportPerms(userPerms) == false ? "" : <button className="btn btn-update  btn-sm ml-2" type="submit" style={{ float: "right", width: "50px" }} onClick={(event) => ExporttoCSV()}>{t('Export')}</button>}
                                        {/* Service.editPerms(userPerms)==false?"":<Link className="btn btn-sm btn-update ml-5" id="create" style={{ width: "90px", backgroundColor: "buttonface" }} to="/policies/CashDiscount/add/0" >Create New</Link> */}

                                        {showbutton === true ?
                                            <>
                                                <a className="btn btn-update  btn-sm ml-2" type="submit" style={{ width: "40px", backgroundColor: "buttonface", float: "right" }} onClick={() => OpenHandler(checkedValue)} >{t('Open')}</a>
                                                {Service.editPerms(userPerms) == false ? "" : <button className="btn btn-update  btn-sm ml-2" type="submit" style={{ float: "right", width: "40px" }} onClick={(event) => CopyHandler(checkedValue)}>{t('Copy')}</button>}
                                                {Service.editPerms(userPerms) == false ? "" : <button className="btn btn-update  btn-sm ml-2" type="submit" style={{ float: "right", width: "50px" }} onClick={(event) => checkDelete()}>{t('Delete')}</button>}</>
                                            :
                                         deleteshowbutton === true ?
                                            <>

                                              <button className="btn btn-update  btn-sm ml-2" style={{ float: "right", width: "50px" }} type="submit" onClick={(event) => checkDelete()}>{t('Delete')}</button>

                                            </> :
                                            ''
                                        } </div>
                                </div>


                                <table className="table table-bordered text-left mt-2 table-responsive-sm" id="tableA">
                                    <thead className={class_thead} style={{ backgroundColor: "var(--primary)" }}>
                                        <tr>
                                            <th style={{ width: '30px' }}><input type="checkbox" id="option-all" onChange={checkAll} /></th>
                                            {/* <th>No</th> */}
                                            <th id="CashdiscountId" style={{ backgroundColor: (filterValueDict.CashdiscountId ? '#6cae55' : ''), minWidth: '150px' }}
                                                hidden={hideColumn.includes('CashdiscountId') ? true : false}>
                                                <div className='d-flex text-nowrap'>
                                                    <span>{t('Policies.CashDiscountId')}</span>
                                                    <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('CashdiscountId')}></span>
                                                    <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('CashdiscountId')}></span>
                                                </div>
                                            </th>
                                            <th id="DaysLessthan" style={{ backgroundColor: (filterValueDict.DaysLessthan ? '#6cae55' : ''), minWidth: '150px' }}
                                                hidden={hideColumn.includes('DaysLessthan') ? true : false}>
                                                <div className='d-flex text-nowrap'>
                                                    <span>{t('Policies.DaysLessThan')}</span>
                                                    <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('DaysLessthan')}></span>
                                                    <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('DaysLessthan')}></span>
                                                </div>
                                            </th>
                                            <th id="CashPercent" style={{ backgroundColor: (filterValueDict.CashPercent ? '#6cae55' : ''), minWidth: '150px' }}
                                                hidden={hideColumn.includes('CashPercent') ? true : false}>
                                                <div className='d-flex text-nowrap'>
                                                    <span>{t('Policies.CashPercent')}</span>
                                                    <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('CashPercent')}></span>
                                                    <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('CashPercent')}></span>
                                                </div>
                                            </th>
                                            <th id="ValidityFrom" style={{ backgroundColor: (filterValueDict.ValidityFrom ? '#6cae55' : ''), minWidth: "150px" }}
                                                hidden={hideColumn.includes('ValidityFrom') ? true : false}>
                                                <div className='d-flex text-nowrap'>
                                                    <span>{t('Rebates.ValidityStartDate')}</span>
                                                    <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('ValidityFrom')}></span>
                                                    <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('ValidityFrom')}></span>
                                                </div>
                                            </th>
                                            <th id="ValidityTo" style={{ backgroundColor: (filterValueDict.ValidityTo ? '#6cae55' : ''), minWidth: "150px" }}
                                                hidden={hideColumn.includes('ValidityTo') ? true : false}>
                                                <div className='d-flex text-nowrap'>
                                                    <span>{t('Rebates.ValidityEndDate')}</span>
                                                    <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('ValidityTo')}></span>
                                                    <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('ValidityTo')}></span>
                                                </div>
                                            </th>
                                            <th id="LastModifiedUser" style={{ backgroundColor: (multiFilterValue.LastModifiedUser ? '#6cae55' : ''), width: '330px' }}
                                                hidden={hideColumn.includes('LastModifiedUser') ? true : false}>
                                                <div className='d-flex text-nowrap'>
                                                    <span>{t('LastModifiedBy')}</span>
                                                    <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('LastModifiedUser')}></span>
                                                    <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('LastModifiedUser')}></span>
                                                </div>
                                            </th>
                                            <th id="LastModifiedDate" style={{ backgroundColor: (filterValueDict.LastModifiedDate ? '#6cae55' : ''), width: '250px' }}
                                                hidden={hideColumn.includes('LastModifiedDate') ? true : false}>
                                                <div className='d-flex text-nowrap'>
                                                    <span>{t('LastModifiedDate')}</span>
                                                    <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('LastModifiedDate')}></span>
                                                    <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('LastModifiedDate')}></span>
                                                </div>
                                            </th>
                                            <th id="CreatedUser" style={{ backgroundColor: (multiFilterValue.CreatedUser ? '#6cae55' : ''), width: '330px' }}
                                                hidden={hideColumn.includes('CreatedUser') ? true : false}>
                                                <div className='d-flex text-nowrap'>
                                                    <span>{t('CreatedBy')}</span>
                                                    <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('CreatedUser')}></span>
                                                    <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('CreatedUser')}></span>
                                                </div>
                                            </th>
                                            <th id="CreatedDate" style={{ backgroundColor: (filterValueDict.CreatedDate ? '#6cae55' : ''), width: '250px' }}
                                                hidden={hideColumn.includes('CreatedDate') ? true : false}>
                                                <div className='d-flex text-nowrap'>
                                                    <span>{t('CreatedDate')}</span>
                                                    <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('CreatedDate')}></span>
                                                    <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('CreatedDate')}></span>
                                                </div>
                                            </th>
                                            <th id="Status" style={{ backgroundColor: (multiFilterValue.Status ? '#6cae55' : ''), width: '250px' }}
                                                hidden={hideColumn.includes('Status') ? true : false}>
                                                <div className='d-flex text-nowrap'>
                                                    <span>{t('Status')} &nbsp;</span>
                                                    <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('Status')}></span>
                                                    <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('Status')}></span>
                                                </div>
                                            </th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            showspinner === true ?
                                                <tr>
                                                    <>
                                                        <Spinner animation="grow" size="sm" variant="success" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                        <Spinner animation="grow" size="sm" variant="danger" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                        <Spinner animation="grow" size="sm" variant="warning" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                    </>
                                                </tr>

                                                :
                                                filteredArray.length === 0 ?
                                                    <tr>
                                                        <td></td>
                                                        <td colSpan="9" style={{ textAlign: 'center' }}>
                                                            {t('NoDataAvailable')}
                                                        </td>
                                                    </tr>
                                                    :
                                                    filteredArray.map((listVal, index) =>
                                                        <tr key = {listVal.CashdiscountId} onDoubleClick={(event) => OpenPolicy(event, listVal.Id)}>
                                                            <td ><input id={listVal.Id} type="checkbox" value={listVal.Id} onChange={(event) => handleChange(event, listVal.Id, listVal.Name, listVal.ISActive)} name="chkBox" /></td>
                                                            {/* <td>{index + 1}</td> */}
                                                            <td hidden={hideColumn.includes('CashdiscountId') ? true : false}>{listVal.CashdiscountId}</td>
                                                            <td hidden={hideColumn.includes('DaysLessthan') ? true : false}>{listVal.DaysLessthan}</td>
                                                            <td hidden={hideColumn.includes('CashPercent') ? true : false}>{listVal.CashPercent}</td>
                                                            <td hidden={hideColumn.includes('ValidityFrom') ? true : false}>{moment(listVal.ValidityFrom).format("MM/DD/YYYY")}</td>
                                                            <td hidden={hideColumn.includes('ValidityTo') ? true : false}>{moment(listVal.ValidityTo).format("MM/DD/YYYY")}</td>
                                                            <td hidden={hideColumn.includes('LastModifiedUser') ? true : false}>{listVal.LastModifiedUser}</td>
                                                            <td hidden={hideColumn.includes('LastModifiedDate') ? true : false}>{moment(listVal.LastModifiedDate).format("DD/MM/YYYY")}</td>
                                                            <td hidden={hideColumn.includes('CreatedUser') ? true : false}>{listVal.CreatedUser}</td>
                                                            <td hidden={hideColumn.includes('CreatedDate') ? true : false}>{moment(listVal.CreatedDate).format(SessionManage.extractDateFromTimestamp(listVal.CreatedDate))}</td>
                                                            <td hidden={hideColumn.includes('Status') ? true : false}>{listVal.Status}</td>
                                                        </tr>
                                                    )

                                        }


                                    </tbody>
                                </table>
                                <div className='row'>
                                    {
                                        totalselectedshow === true ?

                                            <div className='col-lg-2 forgettext col-sm-12 border form-control' style={{ textAlign: "center", marginLeft: "0.938rem" }}>
                                                {t('TotalRecordsSelected')} - <span id="totalselected">{totalselectedcount}</span>
                                            </div>

                                            :
                                            <div className='col-lg-2  col-sm-12' style={{ textAlign: "center" }}>

                                            </div>
                                    }



                                    <div className='col-lg-7 col-sm-12'></div>


                                    <div className='col-lg-2 forgettext  col-sm-12 border form-control' style={{ textAlign: "center" }}>
                                        {t('TotalRecords')} - {totalCountlst}
                                    </div>
                                </div>
                                {
                                    showLoadMore === true ?
                                        <div className='text-center container row'>
                                            <div className='col-md-5'></div>
                                            <div className='col-md-2'>
                                                <button className='btn btn-sm btn-update ml-1' onClick={handleLoadMore} style={{ width: "70px" }}>{t('LoadMore')}</button>
                                            </div>
                                            <div className='col-md-5'></div>

                                        </div> : ""}
                                {showfilterpopup === true ?
                                    <div className='pop-up' style={{ left: divleft, top: divtop }}>
                                        {/* <div className='row'>
                                            <div className='col-md-1'><span className='fa fa-filter'></span></div>
                                            <div className='col-md-10'>Clear Filter</div>
                                        </div> */}
                                        {filterCol === 'CashdiscountId' ?
                                            <>
                                                <div className="container">
                                                    <div className="row">
                                                        <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>
                                                    </div>
                                                </div>
                                                <div className="container mt-2">
                                                    <div className="row">
                                                        <input type='textbox' placeholder={t('Search')} className='filtertextboxbackground col-md-11 textbox-padding ml-2' style={{ outline: 'none' }} value={filterValueDict[filterCol]} onChange={(event) => filterInput(event)}></input>
                                                    </div>
                                                </div>
                                            </>

                                            :
                                            <>
                                            </>

                                        }

                                        {filterCol === 'CreatedDate' || filterCol === 'LastModifiedDate' || filterCol === 'ValidityFrom' || filterCol === 'ValidityTo' ?

                                            <>
                                                <div className="container">
                                                    <div className="row">
                                                        <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>
                                                    </div>
                                                </div>
                                                <div className="container mt-2">
                                                    <div className="row">
                                                        <input type='date' className='filtertextboxbackground col-md-11 textbox-padding ml-2' value={filterValueDict[filterCol]} onChange={(event) => filterInput(event)}></input>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <></>
                                        }

                                        {filterCol === 'LastModifiedUser' || filterCol === 'Status' || filterCol === 'CreatedUser' ?
                                             <>
                                                <div className="container">
                                                    <div className="row">
                                                        <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>

                                                    </div>

                                                </div>

                                                <div className="container mt-2">
                                                    <div className="row">
                                                        <input type="text" placeholder={t('Search')} value={filterSearch} onChange={(event) => UserfilterHandler(event)} className='filtertextboxbackground col-md-11 textbox-padding ml-2' />
                                                    </div>

                                                    <div className="row contentoverflow mt-2">
                                                        <table className="filtertable">
                                                            <tbody>
                                                                <tr>
                                                                    <td><input type="checkbox" name="filter-option-all" id="option-all" onChange={checkAllFilter} /></td>
                                                                    <td>{t('SelectAll')}</td>
                                                                </tr>
                                                                {
                                                                    dropdownData.map((item, index) => (

                                                                        <tr key={index}>

                                                                            <td><input id={item.Id} data-index="1" name="filter-option-all" checked={item.ischecked} type="checkbox" onChange={(event) => OndropdownChange(event, item.Id, item.Name)} /></td>

                                                                            <td>{item.Name}</td>

                                                                        </tr>


                                                                    ))
                                                                }

                                                            </tbody>
                                                        </table>

                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <></>
                                        }

                                        {filterCol === 'DaysLessthan' || filterCol === 'CashPercent' ?
                                            <div>
                                                <div className="row">
                                                    <div className="row" style={{ marginLeft: "40px", paddingBottom: "5px" }}>
                                                        <b>{filtertext}</b>
                                                    </div>
                                                    <Select
                                                        className='col-md-9 textbox-padding ml-4 fontsizedropdown'
                                                        options={operatorValOption}
                                                        placeholder="Select"
                                                        value={operatorVal}
                                                        onChange={OnChangeOperatorValue}
                                                        styles={customStyles}
                                                    />
                                                </div>
                                                <div className="mt-2">
                                                    <input type='text' className='filtertextboxbackground col-md-11 textbox-padding ml-2' onKeyPress={(e) => !/^(\d)*(\.)?([0-9]{1})?$/.test(e.key) && e.preventDefault()}
                                                        value={filterValueDict[filterCol]} onChange={(event) => filterInput(event)} />
                                                </div>
                                            </div>
                                            :
                                            <></>
                                        }
                                        <div style={{ paddingTop: '10px', paddingLeft: '47px', paddingBottom: '7px' }}>
                                            <button className="btn btn-sm btn-update" onClick={() => filterHandler('Apply')} style={{ width: "45px" }}>{t('Apply')}</button>
                                            <button className="btn btn-sm btn-update" style={{ marginLeft: '10px', width: "45px" }} onClick={() => filterHandler('Reset')}>{t('Reset')}</button>
                                        </div>
                                        <div>
                                        </div>
                                    </div>
                                    :
                                    <></>
                                }

                                {showAddColumnPopup === true ?
                                    <div className='pop-up' style={{ left: divleft, top: divtop, width: "250px" }}>
                                        <div className="container">
                                            <div className="row">
                                                <Select
                                                    className='col-md-10 textbox-padding fontsizedropdown'
                                                    options={colVisibilityOption}
                                                    placeholder="Select"
                                                    value={colvisiOptionVal}
                                                    onChange={OnChangeColVisibilityList}
                                                    styles={customStyles}
                                                />
                                                <button id="createColumnListPopup" icon className="btn btn-update ml-1 pa-0" type="submit"
                                                    style={{ float: "left", cursor: "pointer", padding: '0px', height: '28px', width: '26px' }}
                                                    onClick={CreateColumnListPopup} title='Add New View'><i class="fa fa-plus" aria-hidden="true"></i></button>
                                            </div>
                                            <div className="row mb-2" style={{ textAlign: 'right' }}>
                                                <div className="col-md-10 mt-1 mr-1" style={{ padding: '0px', fontSize: '12px' }}>
                                                    <input id="existingViewVal" data-index="1" name="filter-option-all" checked={existingViewVal} type="checkbox"
                                                        onChange={(event) => OnChangeexistingViewVal(event)} /> <b>Is Default</b>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <table className='ml-2' style={{ fontSize: '0.80rem' }}>
                                                    {
                                                        addRemoveCol.map((item, index) => (
                                                            <tr>
                                                                <td key={index}><input id={item.label} data-index="1" name="filter-option-all" checked={item.value} type="checkbox"
                                                                    onChange={(event) => OnchangeAddRemoveCol(event, item)}
                                                                /> {t(item.label)}</td>
                                                            </tr>
                                                        ))
                                                    }
                                                </table>
                                            </div>
                                            <div className="row mr-2" style={{ float: "right" }}>
                                                <div style={{ paddingTop: '10px', paddingLeft: '15px', paddingBottom: '7px' }}>
                                                    <button className="btn btn-sm btn-update" style={{ width: "45px" }} onClick={() => updateColumnVisibilityList('reset')}>{t('Reset')}</button>
                                                    <button className="btn btn-sm btn-update ml-1" style={{ width: "45px" }} onClick={closeAddRemoveColumnPopup}>{t('Close')}</button>
                                                    <button className="btn btn-sm btn-update ml-1" style={{ width: "50px", background: '#4755ab', color: 'white' }} onClick={() => updateColumnVisibilityList('apply')} title='Update View'>{t('Update')}</button>
                                                    <button className="btn btn-sm btn-update ml-1" style={{ width: "50px", background: 'rgb(233 43 0)', color: 'white' }} onClick={deleteColumnVisibilityView} title='Delete View'>{t('Delete')}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : <></>
                                }


                                {showCreateColumnListPopup === true ?
                                    <div className='pop-up' style={{ left: divleft1, top: divtop1, width: "190px" }}>
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-12 ml-2" style={{ padding: '0px' }}>
                                                    <input type='textbox' placeholder='View Name' style={{ height: '30px' }}
                                                        value={colViewName} name={colViewName} onChange={(event) => OnChangeSetViewName(event)}></input>
                                                </div>
                                            </div>
                                            <div className="row mb-2" style={{ textAlign: 'right' }}>
                                                <div className="col-10 mt-2" style={{ padding: '0px', fontSize: '12px' }}>
                                                    <input id="defaultView" data-index="1" name="filter-option-all" checked={defaultView} type="checkbox"
                                                        onChange={(event) => OnChangedefaultView(event)} /> <b>Set Default</b>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <table className='ml-2' style={{ fontSize: '0.80rem' }}>
                                                    {
                                                        createColumnListOption.map((item, index) => (
                                                            <tr>
                                                                <td key={index}><input id={item.label} data-index="1" name="filter-option-all" checked={item.value} type="checkbox"
                                                                    onChange={(event) => OnchangeCreateColumnListOption(event, item)}
                                                                /> {t(item.label)}</td>
                                                            </tr>
                                                        ))
                                                    }
                                                </table>
                                            </div>
                                            <div className="row mr-2" style={{ float: "right" }}>
                                                <div style={{ paddingTop: '10px', paddingLeft: '35px', paddingBottom: '7px' }}>
                                                    <button className="btn btn-sm btn-update ml-1" style={{ width: "50px" }} onClick={CloseCreateColumnListPopup}>{t('Cancel')}</button>
                                                    <button className="btn btn-sm btn-update ml-1" style={{ width: "40px", background: '#4755ab', color: 'white' }} onClick={() => CreateColumnVisibilityList('apply')}>{t('Save')}</button>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                    : <></>
                                }

                            </div>

                        </div>
                    </div>

                </div>
            </div >
            <Modal show={ToggleStatusModal}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Body style={{ "textAlign": 'center', fontFamily: 'Segoe UI' }}>{`${t('Policies.AreYouSureYouWantTo')} ${toggleChange}` + "?"}</Modal.Body>
                <Modal.Footer>
                    <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "35px" }} size='sm' onClick={() => TogglePolicyActiveStatus(toggleId, "Ok")}>
                        {t('Yes')}
                    </Button>
                    <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "50px" }} size='sm' onClick={() => TogglePolicyActiveStatus(toggleId, "Cancel")}>
                        {t('Cancel')}
                    </Button>
                </Modal.Footer>
            </Modal>
             <Modal show={showDeleteModal}
              size="sm"
              aria-labelledby="contained-modal-title-vcenter"
              centered>
              <Modal.Body style={{ "textAlign": 'center' }}>Are you sure you want to delete ?</Modal.Body>
              <Modal.Footer>
                <Button style={{ background: '#eff1f9', color: 'black', borderColor: 'black', width: "50px" }} size='sm' onClick={() => showDeleteDialog('close')}>
                  Cancel
                </Button>
                <Button style={{ background: '#eff1f9', color: 'black', borderColor: 'black', width: "50px" }} size='sm' onClick={() => showDeleteDialog('proceed')}>
                  Delete
                </Button>
              </Modal.Footer>
            </Modal>
            <CustomErrorPopup ErrorMessage={errorMessage} show={showErrorModal} ErrorStatus={errorStatus}
                onHide={() => setShowErrorModal(false)}></CustomErrorPopup>
            <ToastContainer autoClose={1000} />
        </div >

    )

}
export default CashDiscountPolicyList