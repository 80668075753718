import { Link } from "react-router-dom"
import { useNavigate, useLocation } from 'react-router-dom';
import React, { useEffect, useState, useRef, forwardRef } from 'react';
import { CSVLink } from "react-csv";

import Service from '../../../Services/Service';
import moment from 'moment';
import Spinner from 'react-bootstrap/Spinner';
import Multiselect from 'multiselect-react-dropdown';
import SessionManage from "../../../SessionManagement/SessionManage";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import CustomErrorPopup from '../../../UI/CustomErrorPopup';
import { ToastContainer, toast } from 'react-toastify';

function ArchiveAccrualComponent(props) {
    const navigate = useNavigate();
    const [paging, setpaging] = useState({
        pageSize: 20,
        pageNumber: 1
    })
    const [userPerms, setuserPermission] = useState([]);
    const location = useLocation();
    const [t, i18n] = useTranslation('translation');


    const [showspinner, setshowspinner] = useState('');
    const [showLoadMore, setshowLoadMore] = useState('');
    const [searchValue, setSearchValue] = useState('')
    let loggedInUser = SessionManage.getuserid();
    const [CompletedAccrualData, setCompletedAccrualData] = useState([]);
    const [checkedValueid, setCheckedValueid] = useState([]);
    const [showbutton, setshowbutton] = useState('');
    const [filterValue, setFilterValue] = useState('');
    const [filterCol, setFilterCol] = useState('');
    const [filtertext, setfiltertext] = useState('');
    const [filterdropdownData, setFilterDropdownData] = useState([]);
    const [filterSearch, setFilterSearch] = useState("");
    const [filterValueDict, setFilterValueDict] = useState({
        rebateagreement_id: '',
        rebateprogram_id: '',
        desc: '',
        Accrualid: '',
        post_date: null,
    });
    const [multiFilterValue, setMultiFilterValue] = useState({
        customer: '',
        submittedby: ''
    });
    const [dropdownData, setDropdownData] = useState([]);
    const [showfilterpopup, setshowfilterpopup] = useState(false);
    const [columnAscending, setColumnAscending] = useState(true);
    const [totalselectedshow, settotalselectedshow] = useState('');
    const [totalselectedcount, settotalselectedcount] = useState(0);
    const [totalCountlst, settotalCountlst] = useState('');
    const csvInstance = useRef();
    const [csvList, setCsvList] = useState([]);
    const [sortColName, setSortColName] = useState("");
    const [sticky, setSticky] = useState("");

    const customStyles = {
        control: base => ({
            ...base,
            minHeight: 28
        }),
        dropdownIndicator: base => ({
            ...base,
            padding: 2
        }),
        clearIndicator: base => ({
            ...base,
            padding: 2
        }),
        multiValue: base => ({
            ...base,

        }),
        valueContainer: base => ({
            ...base,
            padding: '0px 2px'
        }),
        input: base => ({
            ...base,
            margin: 0,
            padding: 0
        })
    };
    const checkAll = (mycheckbox) => {
        var checkboxes = document.querySelectorAll("input[type = 'checkbox']");
        if (mycheckbox.target.checked == true) {
            settotalselectedshow(true);
            checkboxes.forEach(function (checkbox) {
                checkbox.checked = true;
            });
            settotalselectedcount(checkboxes.length - 1)
        }
        else {
            settotalselectedshow(false);
            checkboxes.forEach(function (checkbox) {
                checkbox.checked = false;
            });
            settotalselectedcount(0)
        }

    }
    const getUserPermission = (pathname) => {
        Service.GetUserPermission(pathname).then((res) => {
            if (typeof res !== 'undefined') {

                if (res.data !== 'null' && typeof res.data !== 'undefined' && res.data !== '') {
                    if (typeof res.data.DataList !== 'undefined') {
                        setuserPermission(res.data.DataList)
                    }
                }
            }
        });
    }
    const LoadMore = (lst, totalcount) => {

        if (lst < totalcount) {
            setshowLoadMore(true);
        }
        else if (lst >= totalcount) {
            setshowLoadMore(false);
        } else {
            setshowLoadMore(false);
        }

    }

    useEffect(() => {
        getUserPermission(location.pathname);
        setshowspinner(true);
        settotalselectedshow(false);
        setColumnAscending(false);
        setSortColName("Id");
        getAccrualCompletedApprovalData("Approval/GetCompletedAccrual", paging.pageSize, paging.pageNumber, "Descending", "Id", [], null)
        getColumnVisibilityList()
        getColVisibilityListData()

        window.addEventListener("scroll", isSticky);
        return () => {
            window.removeEventListener("scroll", isSticky);
        };
    }, [])



    const isSticky = () => {
        const scrollTop = window.scrollY;
        const stickyClass = scrollTop >= 60 ? "is-sticky-thead" : "";
        setSticky(stickyClass);
        const scrollTopActual = scrollTop >= 60 ? scrollTop - 85 : 0;
        var divtop1 = document.getElementById("tableA");
        setdivtop(divtop1.offsetTop + scrollTopActual)
    };
    const class_thead = `text-white rt-thead  ${sticky}`;
    const ExporttoCSV = () => {
        let checkedBoxes = document.querySelectorAll('input[name=chkBox]:checked');
        let values = "";
        if (checkedBoxes.length >= 1) {
            for (let checkbox of checkedBoxes) {
                values = values + ';' + checkbox.id;

            }
        }
        let objFilter = [];
        let objFilterValue = [{
            filterKey: "id",
            FilterValue: values,
        }]

        objFilter = objFilterValue;

        Service.getdataWithPagingForApprovalsSearch("Approval/GetCompletedAccrual", loggedInUser, 20000, 1, "Ascending", "Id", objFilter, null).then((res) => {

            if (typeof res !== 'undefined') {
                if (res.data !== 'null' && res.data !== 'undefined') {
                    setCsvList(res.data.DataList);
                    setTimeout(() => {
                        csvInstance.current.link.click();
                    });

                }
            }
        });
    }

    let headers = [
        { label: "Rebate Agreement ID", key: "RebateId" },
        { label: "Customer ", key: "CustomerName" },
        { label: "Rebate Program ID", key: "RebateProgramLineIdStr" },
        { label: "Rebate Program Desc", key: "RebateProgramLineDesc" },
        { label: "Accrual ID", key: "AccrualId" },
        { label: "Submitted On", key: "SubmittedDate" },
        { label: "Submitted By", key: "SubmittedBy" },
        { label: "Accruals(Money)", key: "AccrualAggregate" },
        { label: "Accrual Status", key: "StatusName" },

    ];
    const getAccrualCompletedApprovalData = (method, pageSize, pageNumber, sortType, SortProperty, filterValue, search) => {

        Service.getdataWithPagingForApprovalsSearch(method, loggedInUser, pageSize, pageNumber, sortType, SortProperty, filterValue, search).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res && res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                            let rData = res.data.DataList;
                            setCompletedAccrualData(rData)
                            LoadMore(res.data.DataList.length, res.data.Totalcount)

                            setshowspinner(false);
                            settotalCountlst(res.data.Totalcount)
                        }
                    }
                }
            }

        });
    }
    const filterLst = () => {
        let list = [];
        for (var key in multiFilterValue) {
            if (multiFilterValue[key].length > 0) {
                let values = ""
                multiFilterValue[key].map((i) => {
                    values += i.Name + ";";
                })
                if (values !== null && values !== '') {
                    list.push({
                        filterKey: key,
                        FilterValue: values
                    })
                }
            }
        }

        for (var key in filterValueDict) {
            if (filterValueDict[key] !== null && filterValueDict[key] !== '') {
                list.push({
                    filterKey: key,
                    FilterValue: filterValueDict[key]
                })
            }
        }
        return list;
    }
    const handleLoadMore = () => {
        let pagenumber = paging.pageNumber + 1;
        var sortType = columnAscending === true ? 'Ascending' : 'Descending';

        Service.getdataWithPagingForApprovalsSearch("Approval/GetCompletedAccrual", loggedInUser, paging.pageSize, pagenumber, sortType, sortColName, filterLst(), searchValue).then((res) => {

            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res && res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                            let rData = res.data.DataList;

                            const currentlength = CompletedAccrualData.length;
                            setCompletedAccrualData(CompletedAccrualData => [...CompletedAccrualData, ...res.data.DataList])

                            LoadMore(currentlength + res.data.DataList.length, res.data.Totalcount)


                        }
                    }
                }
            }

        });

        paging.pageNumber = pagenumber
        setpaging(paging);
    };

    const searchHandler = (event) => {

        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
        let pagenumber = 1;
        paging.pageNumber = pagenumber;
        setpaging(paging);
        if (event.target.value != '') {

            setSearchValue(String(event.target.value).toLowerCase())
            getAccrualCompletedApprovalData("Approval/GetCompletedAccrual", paging.pageSize, pagenumber, sortType, sortColName, filterLst(), event.target.value);
        }
        else {
            setSearchValue(null);
            getAccrualCompletedApprovalData("Approval/GetCompletedAccrual", paging.pageSize, pagenumber, sortType, sortColName, filterLst(), null);
        }
    }

    const handleChange = (e, status, id) => {
        let isChecked = e.target.checked;   // true if it is checked false if unchecked

        let checkedBoxes = document.querySelectorAll('input[name=chkBox]:checked');
        if (isChecked === true) {

            setCheckedValueid(id)
            settotalselectedshow(true);
            settotalselectedcount(checkedBoxes.length);

        }
        else {

            setCheckedValueid([])
            settotalselectedshow(false);
            settotalselectedcount(checkedBoxes.length);
        }

        if (checkedBoxes.length == 1) {
            setshowbutton(true);
        }
        else {
            setshowbutton(false);
        }
    }

    const navigateToAccrual = (id) => {
        if (id == null || id == 0) {
            alert('Please select the Accrual to view')
        }
        else {
            navigate("/accruals/new/" + id + "/fromApprovalCompleted");
        }
    }

    const OpenAccrual = (event, id) => {
        if (event.detail === 2) {
            navigate("/accruals/new/" + id + "/fromApprovalCompleted");
        }
    }


    const filterInput = (e) => {

        let inputValue = e.target.value
        setFilterValue(inputValue)
        setFilterValueDict(() => ({
            ...filterValueDict, [filterCol]: e.target.value,
        }));
    }

    const OndropdownChange = (e, id, name) => {
        let isChecked = e.target.checked;
        var lastfilter = [...dropdownData]
        if (isChecked === true) {
            let currentdata = lastfilter.map((i) => {
                if (i.Id == id) {
                    i["ischecked"] = true;
                }
                return i;
            });

            setDropdownData(currentdata);
            let data1 = []
            const getallData = {
                Name: name,
            }
            data1.push(getallData)
            setFilterValue(filterValue => [...filterValue, ...data1]);

            let updateddata = ([...filterValue, ...data1]);
            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: updateddata,
            }));
        }
        else {
            let currentdata = lastfilter.map((i) => {
                if (i.Id === id) {
                    i["ischecked"] = false;
                }
                return i;
            });

            setDropdownData(currentdata);
            setFilterValue(filterValue => filterValue.filter(data => data.Name !== name))
            let data1 = filterValue.filter(data => data.Name !== name);
            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: data1,
            }));
        }
    }

    const UserfilterHandler = (e) => {

        setFilterSearch(e.target.value)
        if (e.target.value !== "") {
            let searchResults = dropdownData.filter((each) => {
                return each.Name.toLowerCase().includes(String(e.target.value).toLowerCase())
            });
            setDropdownData(searchResults);
        }
        else {

            setFilterSearch("")
            setDropdownData(filterdropdownData)
        }
    }

    const checkAllFilter = (mycheckbox) => {
        var lastfilter = [...dropdownData]
        let data1 = []
        var checkboxes = document.querySelectorAll("input[name = 'filter-option-all']");
        if (mycheckbox.target.checked == true) {

            let currentdata = lastfilter.map((i) => {
                i["ischecked"] = true;
                return i;
            });
            setDropdownData(currentdata)

            lastfilter.map((each) => {

                const getallData = {
                    Name: each.Name,
                }
                data1.push(getallData)
            })
            setFilterValue(filterValue => [...filterValue, ...data1]);
            let updateddata = ([...filterValue, ...data1]);
            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: updateddata,
            }));
        }
        else {

            let currentdata = lastfilter.map((i) => {
                i["ischecked"] = false;
                return i;
            });
            setFilterValue([])
            setMultiFilterValue([])
        }
    }



    const [divleft, setdivleft] = useState('');
    const [divtop, setdivtop] = useState('');

    const FilterColumn = (name) => {
        const scrollTop = window.scrollY;
        const scrollTopActual = scrollTop >= 60 ? scrollTop - 85 : 0;
        var divtop1 = document.getElementById("tableA");
        setdivtop(divtop1.offsetTop + scrollTopActual + 30)
        setshowfilterpopup(true)
        setFilterCol(name)
        if (name == 'rebate_id') {

        }

        if (name === 'customer') {
            setfiltertext(t('Accruals.Customer'))
            var testDiv = document.getElementById("customer");
            setdivleft((testDiv.offsetLeft + 30) + "px");
            DropdownHandler(name)
        }
        if (name === 'submittedby') {
            setfiltertext(t('Accruals.SubmittedBy'))
            var testDiv = document.getElementById("submittedby");
            setdivleft((testDiv.offsetLeft + 30) + "px");
            DropdownHandler(name)
        }
        if (name === 'rebateagreement_id') {
            setfiltertext(t('Accruals.RebateAgreementId'))
            var testDiv = document.getElementById("rebateagreement_id");
            setdivleft((testDiv.offsetLeft + 30) + "px");
        }
        if (name === 'rebateprogram_id') {
            setfiltertext(t('Accruals.RebateProgramId'))
            var testDiv = document.getElementById("rebateprogram_id");
            setdivleft((testDiv.offsetLeft + 30) + "px");
        }
        if (name === 'desc') {
            setfiltertext(t('Accruals.RebateProgramDesc'))
            var testDiv = document.getElementById("desc");
            setdivleft((testDiv.offsetLeft + 30) + "px");
        }
        if (name === 'Accrualid') {
            setfiltertext(t('Accruals.AccrualId'))
            var testDiv = document.getElementById("Accrualid");
            setdivleft((testDiv.offsetLeft + 30) + "px");
        }
        if (name === 'post_date') {
            setfiltertext(t('Accruals.SubmittedOn'))
            var testDiv = document.getElementById("post_date");
            setdivleft((testDiv.offsetLeft + 30) + "px");
        }
        setshowfilterpopup(true)
    }



    const DropdownHandler = (key) => {
        Service.getApprovalsFilterDropdown('Approval/GetAccrualCompletedApprovalFilterValue', key, loggedInUser).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {

                        if (res.data !== 'null' && res.data !== 'undefined') {

                            addnewfield(res.data, key);
                        }
                    }
                }
            }

        });
    }

    const addnewfield = (data, key) => {
        let currentdata = data.map((i) => {
            i["ischecked"] = false;
            return i;
        });
        let selectedKeys = multiFilterValue[key];
        if (key != 'active') {
            if (selectedKeys != '') {
                currentdata.forEach((each) => {
                    selectedKeys.forEach((eachSelected) => {
                        if (each.Name == eachSelected.Name) {
                            each.ischecked = true
                        }
                    })
                })
            }

            setDropdownData(currentdata);
            setFilterDropdownData(currentdata)
        }
        else {
            if (selectedKeys != '') {
                currentdata.forEach((each) => {
                    selectedKeys.forEach((eachSelected) => {
                        if (each.Name == eachSelected.Name) {
                            each.ischecked = true
                        }
                    })
                })
                setDropdownData(currentdata)
                setFilterDropdownData(currentdata)
            }
            else {
                setDropdownData(currentdata)
                setFilterDropdownData(currentdata)
            }
        }
    }



    const filterHandler = (flag) => {
        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
        let pagenumber = 1;
        paging.pageNumber = pagenumber;

        if (flag === 'Apply') {
            setshowfilterpopup(false)
            let list = [];

            for (var key in multiFilterValue) {
                if (multiFilterValue[key].length > 0) {
                    let values = ""
                    multiFilterValue[key].map((i) => {
                        values += i.Name + ";";
                    })
                    if (values !== null && values !== '') {
                        list.push({
                            filterKey: key,
                            FilterValue: values
                        })
                    }
                }
            }

            for (var key in filterValueDict) {
                if (filterValueDict[key] !== null && filterValueDict[key] !== '') {
                    list.push({
                        filterKey: key,
                        FilterValue: filterValueDict[key]
                    })
                }
            }
            if (list.length > 0) {
                setpaging(paging);
                getAccrualCompletedApprovalData("Approval/GetCompletedAccrual", paging.pageSize, pagenumber, sortType, sortColName, list, searchValue);
            }

        }
        else {
            setshowfilterpopup(false)
            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: '',
            }));
            setFilterValueDict(() => ({
                ...filterValueDict, [filterCol]: '',
            }));

            let list = [];
            for (var key in multiFilterValue) {
                if (key !== filterCol && multiFilterValue[key].length > 0) {
                    let values = ""
                    multiFilterValue[key].map((i) => {
                        values += i.Name + ";";
                    })
                    if (values !== null && values !== '') {
                        list.push({
                            filterKey: key,
                            FilterValue: values
                        })
                    }
                }
            }

            for (var key in filterValueDict) {
                if (filterValueDict[key] !== null && filterValueDict[key] !== '' && key !== filterCol) {
                    list.push({
                        filterKey: key,
                        FilterValue: filterValueDict[key]
                    })
                }
            }

            setpaging(paging);
            getAccrualCompletedApprovalData("Approval/GetCompletedAccrual", paging.pageSize, pagenumber, sortType, sortColName, list, searchValue);

            setFilterCol('')
            setFilterValue('')
            setDropdownData([])
        }
    }

    const SortColumn = (colName) => {
        var sortType = '';
        if (columnAscending === true) {
            setColumnAscending(false);
            sortType = "Descending";
        } else {
            setColumnAscending(true);
            sortType = "Ascending";
        }
        setSortColName(colName);

        let pagenumber = 1;

        paging.pageNumber = pagenumber;
        setpaging(paging);


        getAccrualCompletedApprovalData("Approval/GetCompletedAccrual", paging.pageSize, pagenumber, sortType, colName, filterLst(), searchValue);

    }
    const customMultiStyles = {
        multiselectContainer: {
            maxWidth: '170px',
            marginLeft: '3px'
        },
        chips: {
            borderRadius: '5px'
        }
    };

    // -----------------------------------------------------------------
    const columnList = [
        { "label": "Accruals.RebateAgreementId", "key": "rebateagreement_id", "value": true },
        { "label": "Accruals.Customer", "key": "customer", "value": true },
        { "label": "Accruals.RebateProgramId", "key": "rebateprogram_id", "value": true },
        { "label": "Accruals.RebateProgramDesc", "key": "desc", "value": true },
        { "label": "Accruals.AccrualId", "key": "Accrualid", "value": true },
        { "label": "Accruals.SubmittedOn", "key": "post_date", "value": true },
        { "label": "Accruals.SubmittedBy", "key": "submittedby", "value": true },
        { "label": "Accruals.AccrualsMoney", "key": "AccrualsMoney", "value": true },
        { "label": "Accruals.AccrualStatus", "key": "AccrualStatus", "value": true },
    ]
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [errorStatus, setErrorStatus] = useState("Error");
    const [addRemoveCol, setAddRemoveCol] = useState(columnList);
    const [createColumnListOption, setCreateColumnListOption] = useState(columnList)
    const [showAddColumnPopup, setShowAddColumnPopup] = useState(false)
    const [showCreateColumnListPopup, setShowCreateColumnListPopup] = useState(false)
    const [hideColumn, setHideColumn] = useState([]);
    const [resetColumnKey, setResetColumnKey] = useState([]);
    const [colVisibilityOption, setColVisibilityOption] = useState([]);
    const [colvisiOptionVal, setColvisiOptionVal] = useState({ value: null, label: 'Default' })
    const [divleft1, setdivleft1] = useState('');
    const [divtop1, setdivtop1] = useState('');
    const [existingViewVal, setExistingViewVal] = useState(false);
    const [defaultView, setDefaultView] = useState(false);
    const [colViewName, setColViewName] = useState('');
    const [recorddId, setRecordId] = useState(0);


    const OnChangedefaultView = (event,) => {
        setDefaultView(event.target.checked)
    }
    const OnChangeexistingViewVal = (event,) => {
        setExistingViewVal(event.target.checked)
    }

    const OnChangeSetViewName = (event,) => {
        setColViewName(event.target.value)
    }

    const OnChangeColVisibilityList = (data) => {
        setColvisiOptionVal({ value: data.value, label: data.label });
        getColVisibilityListData(data.value)
    }
    const CreateColumnListPopup = () => {
        setShowCreateColumnListPopup(true)
        var divtop1 = document.getElementById("addRemoveCol");
        setdivtop1(divtop1.offsetTop + 30)
        setdivleft1((divtop1.offsetLeft) + 100 + "px");
    }

    const CloseCreateColumnListPopup = () => {
        setShowCreateColumnListPopup(false)
        setColViewName('')
        setDefaultView(false)
    }

    const AddRemoveColumn = () => {
        setShowAddColumnPopup(true)
        var divtop1 = document.getElementById("addRemoveCol");
        setdivtop(divtop1.offsetTop + 45)
        setdivleft((divtop1.offsetLeft) + "px");
        getColumnVisibilityList()
    }
    const OnchangeAddRemoveCol = (event, column) => {
        let isChecked = event.target.checked;
        let lst = [...addRemoveCol];
        for (let d in lst) {
            if (lst[d].key == column.key && isChecked) {
                lst[d]['value'] = true
            }
            else if (lst[d].key == column.key && !isChecked) {
                lst[d]['value'] = false
            }
        }
        setResetColumnKey([...resetColumnKey, column.key])
        setAddRemoveCol(lst)
    }
    const OnchangeCreateColumnListOption = (event, column) => {
        let isChecked = event.target.checked;
        let lst = [...createColumnListOption];
        for (let d in lst) {
            if (lst[d].key == column.key && isChecked) {
                lst[d]['value'] = true
            }
            else if (lst[d].key == column.key && !isChecked) {
                lst[d]['value'] = false
            }
        }
        setCreateColumnListOption(lst)
    }
    const updateColumnVisibilityList = (tag) => {
        let lst = [...addRemoveCol];
        if (tag == 'reset') {
            for (let d in lst) {
                lst[d]['value'] = true
            }
            setHideColumn([])
            setAddRemoveCol(lst)
        }
        else {
            let data = [];
            for (let d in lst) {
                if (!lst[d].value) {
                    data.push(lst[d].key)
                }
            }
            setHideColumn(data)
        }
        let viewName = colvisiOptionVal.value;
        Service.setColumnVisibilityListData('ColumnVisibility/CreateOrUpdateColumnList', recorddId, loggedInUser, 'accrualApprovalWorkFlowComplete', viewName, existingViewVal, lst).then((res) => { })
        setShowAddColumnPopup(false)
        setResetColumnKey([])
    }


    const CreateColumnVisibilityList = (tag) => {
        let veiwName = colViewName;
        let idDefault = defaultView;
        if (veiwName.trim() == 'undefined' || veiwName.trim() == '' || veiwName.trim() == null) {
            setErrorStatus("Error")
            setErrorMessage("Please set View Name")
            setShowErrorModal(true)
        }
        else {
            let lst = [...createColumnListOption];
            if (tag == 'reset') {
                for (let d in lst) {
                    lst[d]['value'] = true
                }
                setHideColumn([])
                setAddRemoveCol(lst)
            }
            else {
                let data = [];
                for (let d in lst) {
                    if (!lst[d].value) {
                        data.push(lst[d].key)
                    }
                }
                setHideColumn(data)
            }
            Service.setColumnVisibilityListData('ColumnVisibility/CreateOrUpdateColumnList', 0, loggedInUser, 'accrualApprovalWorkFlowComplete', colViewName, defaultView, lst).then((res) => {
                getColumnVisibilityList()
                setColvisiOptionVal({ value: colViewName, label: colViewName });
                getColVisibilityListData(colViewName)
                CloseCreateColumnListPopup()
                setShowAddColumnPopup(false)
            })
        }
    }

    const closeAddRemoveColumnPopup = () => {
        let lst = [...addRemoveCol];
        let resetKey = [...resetColumnKey];
        for (let rk in resetKey) {
            for (let d in lst) {
                if (lst[d].key == resetKey[rk]) {
                    lst[d]['value'] = !lst[d].value
                }
            }
        }
        setAddRemoveCol(lst)
        setShowAddColumnPopup(false)
        setResetColumnKey([])

    }

    const getColumnVisibilityList = () => {
        Service.getColumnVisibilityListData('ColumnVisibility/GetColumnList', loggedInUser, 'accrualApprovalWorkFlowComplete', null).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {

                        if (typeof res.data !== 'undefined' && res.data !== 'null') {
                            let data = []
                            for (let d in res.data) {
                                data.push({ value: res.data[d].label, label: res.data[d].label })

                            }

                            setColVisibilityOption(data)
                        }
                    }
                }
            }
        })
    }


    const getColVisibilityListData = (viewName = null) => {
        if (!viewName) {
            viewName = colvisiOptionVal.value
        }
        if (viewName == 'Deleted') {
            viewName = null
        }

        Service.getColumnVisibilityListData('ColumnVisibility/GetColumnListJsonData', loggedInUser, 'accrualApprovalWorkFlowComplete', viewName).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (typeof res.data.jsonData !== 'undefined' && res.data.jsonData !== 'null') {

                            let jsonData = JSON.parse(res.data.jsonData)
                            let data = [];
                            for (let d in jsonData) {
                                if (!jsonData[d].value) {
                                    data.push(jsonData[d].key)
                                }
                            }
                            setColvisiOptionVal({ value: res.data.label, label: res.data.label });
                            setHideColumn(data)
                            setAddRemoveCol(jsonData)
                            setExistingViewVal(res.data.defaultView)
                            setResetColumnKey([])
                            setRecordId(res.data.id)
                        }
                    }
                }
            }
        })
    }

    const deleteColumnVisibilityView = () => {
        Service.deleteDataByPolicyID("ColumnVisibility/Delete", recorddId, loggedInUser).then(res => {
            if (res.status === 200) {
                getColumnVisibilityList()
                getColVisibilityListData('Deleted')
                setShowAddColumnPopup(false)
                toast.error('Deleted Successfully !', {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        })
    }
    // ------------------------------------------------------------

    return (<>
        <div className="container-fluid content-top-gap">

            <nav aria-label="breadcrumb">
                <ol className="breadcrumb my-breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">{t('Home.title')}</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">{t('Accruals.AccrualApprovalWorkflowCompleted')}</li>
                </ol>
            </nav>
            <div style={{ display: "none" }}>
                <CSVLink data={csvList} headers={headers} ref={csvInstance} filename="CompletedAccrual.csv" > </CSVLink>
            </div>


            <div className="">
                <div className="row">
                    <div className="col-lg-12 pr-lg-2">
                        <div className="card text-center card_border">

                            <div className="card-body" style={{ minHeight: "70vh" }}>
                                <div className="row ml-1" style={{ position: "sticky" }}>
                                    <div className="col-md-10">
                                        <div className="row">
                                            <input type="text" className="form-control form-control-user col-md-10" id="firstName" autoComplete='off' name="firstName" placeholder={`${t('SearchFor')} ${t('Accruals.AgreementId')} , ${t('Accruals.Customer')} ,${t('Accruals.RebateProgramId')} ,${t('Accruals.AccrualId')} ,${t('Accruals.SubmittedBy')} `} onChange={(event) => searchHandler(event)} />

                                            <button id="addRemoveCol" icon className="btn btn-update ml-2 mt-1 pa-0" type="submit"
                                                style={{ float: "left", cursor: "pointer", padding: '0px', height: '29px', width: '26px' }}
                                                onClick={AddRemoveColumn} title="Manage View"><i class="fa fa-columns" aria-hidden="true"></i></button>
                                        </div>                                    </div>
                                    <div className="col-md-2">
                                        {totalselectedcount <= 0 ? "" : Service.exportPerms(userPerms) == false ? "" : <button className="btn btn-update  btn-sm ml-2" type="submit" style={{ float: "right" }} onClick={(event) => ExporttoCSV()}>{t('Export')}</button>}  {showbutton === true ?
                                            <>
                                                <a className="btn btn-update btn-sm ml-2" style={{ backgroundColor: "buttonface", float: "right", width: "40px" }} onClick={() => navigateToAccrual(checkedValueid)}>{t('View')}</a>
                                            </> : ''}
                                    </div>
                                </div>

                                <div className="scrollbar-class">
                                    <table className="table table-bordered text-left mt-2 table-responsive-sm" id="tableA">
                                        <thead className="text-white is-sticky-thead-product" style={{ backgroundColor: "var(--primary)" }}>
                                            <tr style={{ position: "sticky", top: "0", backgroundColor: "var(--primary)" }}>
                                                <th className="tableheader">
                                                    <input type="checkbox" id="option-all" onChange={checkAll} />
                                                </th>
                                                <th id="rebateagreement_id" style={{ backgroundColor: (filterValueDict.rebateagreement_id ? '#6cae55' : ''), maxWidth: "150px" }}
                                                    hidden={hideColumn.includes('rebateagreement_id') ? true : false}>
                                                    <div className="d-flex text-nowrap">
                                                        <span>{t('Accruals.RebateAgreementId')}</span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('RebateId')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('rebateagreement_id')}></span>
                                                    </div>
                                                </th>
                                                <th id="customer" style={{ backgroundColor: (multiFilterValue.customer ? '#6cae55' : ''), maxWidth: "100px" }}
                                                    hidden={hideColumn.includes('customer') ? true : false}>
                                                    <div className="d-flex text-nowrap">
                                                        <span>{t('Accruals.Customer')}</span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('CustomerName')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('customer')}></span>
                                                    </div>
                                                </th>
                                                <th id="rebateprogram_id" style={{ backgroundColor: (filterValueDict.rebateprogram_id ? '#6cae55' : ''), maxWidth: "140px" }}
                                                    hidden={hideColumn.includes('rebateprogram_id') ? true : false}>
                                                    <div className="d-flex text-nowrap">
                                                        <span>{t('Accruals.RebateProgramId')}</span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('RebateProgramLineIdStr')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('rebateprogram_id')}></span>
                                                    </div>
                                                </th>
                                                <th id="desc" style={{ backgroundColor: (filterValueDict.desc ? '#6cae55' : ''), maxWidth: "150px" }}
                                                    hidden={hideColumn.includes('desc') ? true : false}>
                                                    <div className="d-flex text-nowrap">
                                                        <span>{t('Accruals.RebateProgramDesc')}</span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('RebateProgramLineDesc')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('desc')}></span>
                                                    </div>
                                                </th>
                                                <th id="Accrualid" style={{ backgroundColor: (filterValueDict.Accrualid ? '#6cae55' : ''), maxWidth: "150px" }}
                                                    hidden={hideColumn.includes('Accrualid') ? true : false}>
                                                    <div className="d-flex text-nowrap">
                                                        <span>{t('Accruals.AccrualId')} </span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('AccrualId')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('Accrualid')}></span>
                                                    </div>
                                                </th>
                                                <th id="post_date" style={{ backgroundColor: (filterValueDict.post_date ? '#6cae55' : ''), maxWidth: "110px" }}
                                                    hidden={hideColumn.includes('post_date') ? true : false}>
                                                    <div className="d-flex text-nowrap">
                                                        <span>{t('Accruals.SubmittedOn')}</span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('SubmittedDate')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('post_date')}></span>
                                                    </div>
                                                </th>
                                                <th id="submittedby" style={{ backgroundColor: (multiFilterValue.submittedby ? '#6cae55' : ''), maxWidth: "110px" }}
                                                    hidden={hideColumn.includes('submittedby') ? true : false}>
                                                    <div className="d-flex text-nowrap">
                                                        <span>{t('Accruals.SubmittedBy')}</span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('SubmittedBy')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('submittedby')}></span>
                                                    </div>
                                                </th>
                                                <th id="AccrualsMoney" style={{ backgroundColor: (filterValueDict.rebateagreement_id ? '#6cae55' : ''), maxWidth: "120px" }} hidden={hideColumn.includes('AccrualsMoney') ? true : false}>
                                                    <div className="d-flex text-nowrap">
                                                        <span>{t('Accruals.AccrualsMoney')}</span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('AccrualsMoney')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('AccrualsMoney')}></span>
                                                    </div>
                                                </th>
                                                <th id="AccrualStatus" style={{ backgroundColor: (filterValueDict.rebateagreement_id ? '#6cae55' : ''), maxWidth: "120px" }} hidden={hideColumn.includes('AccrualStatus') ? true : false}>
                                                    <div className="d-flex text-nowrap">
                                                        <span> {t('Accruals.AccrualStatus')}</span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('AccrualStatus')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('AccrualStatus')}></span>
                                                    </div>
                                                </th>

                                            </tr>
                                        </thead>
                                        <tbody>


                                            {
                                                showspinner === true ?

                                                    <>
                                                        <Spinner animation="grow" size="sm" variant="success" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                        <Spinner animation="grow" size="sm" variant="danger" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                        <Spinner animation="grow" size="sm" variant="warning" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                    </>
                                                    :
                                                    CompletedAccrualData.length === 0 ?
                                                        <tr><td></td>
                                                            <td colSpan="9" style={{ textAlign: 'center' }}>
                                                                {t('NoDataAvailable')}
                                                            </td>
                                                        </tr>
                                                        :
                                                        CompletedAccrualData.map((listVal) =>
                                                            <tr key={listVal.Rebate_id} onDoubleClick={(event) => OpenAccrual(event, listVal.Id)}>
                                                                <td><input type="checkbox" id={listVal.Id} name="chkBox" value={listVal.Rebate_id} onChange={(event) => handleChange(event, listVal.Status, listVal.Id)} /></td>
                                                                <td style={{ maxWidth: "140px" }} hidden={hideColumn.includes('rebateagreement_id') ? true : false}>{listVal.RebateId}</td>

                                                                <td style={{ width: "160px" }} hidden={hideColumn.includes('customer') ? true : false}><div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right" title={listVal.CustomerName}>{listVal.CustomerName}</div></td>
                                                                <td style={{ maxWidth: "130px" }} hidden={hideColumn.includes('rebateprogram_id') ? true : false}>{listVal.RebateProgramLineIdStr}</td>
                                                                <td style={{ maxWidth: "140px" }} hidden={hideColumn.includes('desc') ? true : false}><div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right" title={listVal.RebateProgramLineDesc}>{listVal.RebateProgramLineDesc}</div></td>
                                                                <td style={{ maxWidth: "150px" }} hidden={hideColumn.includes('Accrualid') ? true : false}>{listVal.AccrualId}</td>
                                                                <td style={{ maxWidth: "110px" }} hidden={hideColumn.includes('post_date') ? true : false}>{moment(listVal.SubmittedDate).format(SessionManage.getcurrentdateformat())}</td>
                                                                <td style={{ maxWidth: "110px" }} hidden={hideColumn.includes('submittedby') ? true : false}>{listVal.SubmittedBy}</td>
                                                                <td style={{ maxWidth: "110px" }} hidden={hideColumn.includes('AccrualsMoney') ? true : false}>{listVal.Currency} {Service.currencyFormat(parseFloat(listVal.AccrualAggregate).toFixed(SessionManage.getroundingPrecision()))}</td>
                                                                <td style={{ maxWidth: "110px" }} hidden={hideColumn.includes('AccrualStatus') ? true : false}>{listVal.StatusName}</td>

                                                            </tr>
                                                        )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                {
                                    showLoadMore === true ?
                                        <div className='text-center container row'>
                                            <div className='col-md-5'></div>
                                            <div className='col-md-2'>
                                                <button className='btn btn-sm btn-update ml-1' onClick={handleLoadMore} style={{ width: "70px" }}>{t('LoadMore')}</button>
                                            </div>
                                            <div className='col-md-5'></div>

                                        </div>
                                        :
                                        ""
                                }
                                <div className='row'>

                                    {
                                        totalselectedshow === true ?

                                            <div className='col-lg-2 forgettext col-sm-12 border form-control' style={{ textAlign: "center", marginLeft: "0.938rem" }}>
                                                {t('TotalRecordsSelected')} - <span id="totalselected">{totalselectedcount}</span>
                                            </div>

                                            :
                                            <div className='col-lg-2  col-sm-12' style={{ textAlign: "center" }}>

                                            </div>
                                    }



                                    <div className='col-lg-7 col-sm-12'></div>


                                    <div className='col-lg-2 forgettext  col-sm-12 border form-control' style={{ textAlign: "center" }}>
                                        {t('TotalRecords')} - {totalCountlst}
                                    </div>
                                </div>

                                {showfilterpopup === true ?
                                    <div className='pop-up' style={{ left: divleft, top: divtop, width: "200px" }}>
                                        {filterCol === 'rebateagreement_id' || filterCol === 'rebateprogram_id' || filterCol === 'desc' || filterCol === 'Accrualid' ?
                                            <>
                                                <div className="container">
                                                    <div className="row">
                                                        <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>
                                                    </div>
                                                </div>
                                                <div className="container mt-2">
                                                    <div className="row">
                                                        <input type='textbox' placeholder={t('Search')} className='filtertextboxbackground col-md-11 textbox-padding ml-2' style={{ outline: 'none' }} value={filterValueDict[filterCol]} onChange={(event) => filterInput(event)}></input>
                                                    </div>
                                                </div>
                                            </>

                                            :
                                            <>
                                            </>
                                        }

                                        {filterCol === 'post_date' ?
                                            <>
                                                <div className="container">
                                                    <div className="row">
                                                        <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>
                                                    </div>
                                                </div>
                                                <div className="container mt-2">
                                                    <div className="row">
                                                        <input type='date' className='filtertextboxbackground col-md-11 textbox-padding ml-2' value={filterValueDict[filterCol]} onChange={(event) => filterInput(event)}></input>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <></>
                                        }

                                        {filterCol === 'customer' || filterCol === 'submittedby' ?

                                            <>
                                                <div className="container">
                                                    <div className="row">
                                                        <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>

                                                    </div>

                                                </div>

                                                <div className="container mt-2">
                                                    <div className="row">
                                                        <input type="text" placeholder={t('Search')} value={filterSearch} onChange={(event) => UserfilterHandler(event)} className='filtertextboxbackground col-md-11 textbox-padding ml-2' />
                                                    </div>

                                                    <div className="row contentoverflow mt-2">
                                                        <table className="filtertable">
                                                            <tbody>
                                                                <tr>
                                                                    <td><input type="checkbox" name="filter-option-all" id="option-all" onChange={checkAllFilter} /></td>
                                                                    <td>{t('SelectAll')}</td>
                                                                </tr>
                                                                {
                                                                    dropdownData.map((item, index) => (

                                                                        <tr key={index}>

                                                                            <td><input id={item.Id} data-index="1" name="filter-option-all" checked={item.ischecked} type="checkbox" onChange={(event) => OndropdownChange(event, item.Id, item.Name)} /></td>

                                                                            <td className="hovr" style={{ overflow: "hidden", display: "block", maxWidth: "163px" }}>{item.Name}</td>

                                                                        </tr>


                                                                    ))
                                                                }

                                                            </tbody>
                                                        </table>

                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <></>
                                        }
                                        <div style={{ paddingTop: '10px', paddingLeft: '35px', paddingBottom: '7px' }}>
                                            <button className="btn btn-sm btn-update" style={{ width: "45px" }} onClick={() => filterHandler('Apply')}>{t('Apply')}</button>
                                            <button className="btn btn-sm btn-update" style={{ marginLeft: '10px', width: "45px" }} onClick={() => filterHandler('Reset')}>{t('Reset')}</button>
                                        </div>
                                        <div>
                                        </div>
                                    </div>
                                    :
                                    <></>
                                }

                                {showAddColumnPopup === true ?
                                    <div className='pop-up' style={{ left: divleft, top: divtop, width: "250px" }}>
                                        <div className="container">
                                            <div className="row">
                                                <Select
                                                    className='col-md-10 textbox-padding fontsizedropdown'
                                                    options={colVisibilityOption}
                                                    placeholder="Select"
                                                    value={colvisiOptionVal}
                                                    onChange={OnChangeColVisibilityList}
                                                    styles={customStyles}
                                                />
                                                <button id="createColumnListPopup" icon className="btn btn-update ml-1 pa-0" type="submit"
                                                    style={{ float: "left", cursor: "pointer", padding: '0px', height: '28px', width: '26px' }}
                                                    onClick={CreateColumnListPopup} title='Add New View'><i class="fa fa-plus" aria-hidden="true"></i></button>
                                            </div>
                                            <div className="row mb-2" style={{ textAlign: 'right' }}>
                                                <div className="col-md-10 mt-1 mr-1" style={{ padding: '0px', fontSize: '12px' }}>
                                                    <input id="existingViewVal" data-index="1" name="filter-option-all" checked={existingViewVal} type="checkbox"
                                                        onChange={(event) => OnChangeexistingViewVal(event)} /> <b>Is Default</b>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <table className='ml-2' style={{ fontSize: '0.80rem' }}>
                                                    {
                                                        addRemoveCol.map((item, index) => (
                                                            <tr>
                                                                <td key={index}><input id={item.label} data-index="1" name="filter-option-all" checked={item.value} type="checkbox"
                                                                    onChange={(event) => OnchangeAddRemoveCol(event, item)}
                                                                /> {t(item.label)}</td>
                                                            </tr>
                                                        ))
                                                    }
                                                </table>
                                            </div>
                                            <div className="row mr-2" style={{ float: "right" }}>
                                                <div style={{ paddingTop: '10px', paddingLeft: '15px', paddingBottom: '7px' }}>
                                                    <button className="btn btn-sm btn-update" style={{ width: "45px" }} onClick={() => updateColumnVisibilityList('reset')}>{t('Reset')}</button>
                                                    <button className="btn btn-sm btn-update ml-1" style={{ width: "45px" }} onClick={closeAddRemoveColumnPopup}>{t('Close')}</button>
                                                    <button className="btn btn-sm btn-update ml-1" style={{ width: "50px", background: '#4755ab', color: 'white' }} onClick={() => updateColumnVisibilityList('apply')} title='Update View'>{t('Update')}</button>
                                                    <button className="btn btn-sm btn-update ml-1" style={{ width: "50px", background: 'rgb(233 43 0)', color: 'white' }} onClick={deleteColumnVisibilityView} title='Delete View'>{t('Delete')}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : <></>
                                }


                                {showCreateColumnListPopup === true ?
                                    <div className='pop-up' style={{ left: divleft1, top: divtop1, width: "190px" }}>
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-12 ml-2" style={{ padding: '0px' }}>
                                                    <input type='textbox' placeholder='View Name' style={{ height: '30px' }}
                                                        value={colViewName} name={colViewName} onChange={(event) => OnChangeSetViewName(event)}></input>
                                                </div>
                                            </div>
                                            <div className="row mb-2" style={{ textAlign: 'right' }}>
                                                <div className="col-10 mt-2" style={{ padding: '0px', fontSize: '12px' }}>
                                                    <input id="defaultView" data-index="1" name="filter-option-all" checked={defaultView} type="checkbox"
                                                        onChange={(event) => OnChangedefaultView(event)} /> <b>Set Default</b>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <table className='ml-2' style={{ fontSize: '0.80rem' }}>
                                                    {
                                                        createColumnListOption.map((item, index) => (
                                                            <tr>
                                                                <td key={index}><input id={item.label} data-index="1" name="filter-option-all" checked={item.value} type="checkbox"
                                                                    onChange={(event) => OnchangeCreateColumnListOption(event, item)}
                                                                /> {t(item.label)}</td>
                                                            </tr>
                                                        ))
                                                    }
                                                </table>
                                            </div>
                                            <div className="row mr-2" style={{ float: "right" }}>
                                                <div style={{ paddingTop: '10px', paddingLeft: '35px', paddingBottom: '7px' }}>
                                                    <button className="btn btn-sm btn-update ml-1" style={{ width: "50px" }} onClick={CloseCreateColumnListPopup}>{t('Cancel')}</button>
                                                    <button className="btn btn-sm btn-update ml-1" style={{ width: "40px", background: '#4755ab', color: 'white' }} onClick={() => CreateColumnVisibilityList('apply')}>{t('Save')}</button>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                    : <></>
                                }

                            </div>
                            <CustomErrorPopup ErrorMessage={errorMessage} show={showErrorModal} ErrorStatus={errorStatus}
                                onHide={() => setShowErrorModal(false)}></CustomErrorPopup>
                        </div>
                    </div>

                </div>
            </div>



        </div>
    </>)
}
export default ArchiveAccrualComponent