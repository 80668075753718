 
import React, { Component } from 'react' 
import { useNavigate ,useParams } from 'react-router-dom';
import NewSpecialPriceAgreementComponent from '../../../Components/SpecialPriceAgreementComponent/CreateNewComponent/NewSpecialPriceAgreementComponent';
// import '../index.css';



function NewSpecialPriceAgreement(props){
    let navigate = useNavigate();
    const params = useParams();

    return (  
        <div> 
            <NewSpecialPriceAgreementComponent {...props}  navigate={navigate} id={params.id} name={params.name} value={params.value}/>
        </div>
    );  
}
export default NewSpecialPriceAgreement