import React, { useState, forwardRef } from 'react';
import { Form, FormGroup, Col, Row, Button, InputGroup, Collapse } from 'react-bootstrap';
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import DatePicker from "react-datepicker";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';



function OrganizationDetails() {



    return (
        <div className="content-wrapper">
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0 text-dark">Organization Details</h1>
                        </div>
                        <div className="tab-custom-content" style={{ marginLeft: "200px", marginBottom: "0px", marginTop: "0px", borderTop: "none" }}>
                            <Button className='btn btn-secondary btn-sm m-1' style={{ marginLeft: "10px" , float: "right",width: "50px" }} >Cancel</Button>
                            <Button className='btn btn-primary btn-sm m-1' style={{ marginLeft: "10px" , float: "right",width: "40px" }} ><i className="fas fa-save"></i> Save</Button>
                            <Button className='btn btn-warning btn-sm m-1' style={{ marginLeft: "10px" , float: "right",width: "80px" }} ><i className="fas fa-save"></i> Save & Close</Button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="card card-primary card-outline">
                                <div className="card-body">
                                    <form>
                                        <div className="form-group row">
                                            <div className='col-sm-6 row'>
                                                <div className='col-sm-5'>
                                                    <label className="text-right">Organization Name</label>
                                                </div>
                                                <div className='col-sm-5'>
                                                    <input type="text" className="form-control form-control-user" id="OrganizationName"
                                                        name="OrganizationName" placeholder="Organization Name" />
                                                </div>
                                            </div>
                                            <div className='col-sm-6 row'>
                                                <div className='col-sm-5'>
                                                    <label className="text-right">Logo</label>
                                                </div>
                                                <div className='col-sm-5'>
                                                    <textarea rows="4" cols="3" className="form-control form-control-user" id="Logo"
                                                        name="Logo" placeholder="Logo"  style={{resize:"none"}} />
                                                </div>
                                            </div>
                                            <div className='col-sm-6 row'>
                                                <div className='col-sm-5'>
                                                    <label className="text-right">Organization Address</label>
                                                </div>
                                                <div className='col-sm-5'>
                                                    <textarea rows="4" cols="2" className="form-control form-control-user" id="OrganizationAddress"
                                                        name="OrganizationAddres" placeholder="Organization Address"  style={{overflowY:"auto" , height:"100px" ,resize:"none"}}/>
                                                </div>
                                            </div>
                                        </div>
                                        <br></br>
                                        <div className="form-group row">
                                            <div className='col-sm-6 row'>
                                                <div className='col-sm-5'>
                                                    <label className="text-right">Business Calendar</label>
                                                </div>
                                                <div className='col-sm-5'>
                                                    <input type="date" className="form-control form-control-user" id="dob"
                                                         name="dob" placeholder="Valid" />
                                                </div>
                                            </div>
                                            <div className='col-sm-6 row'>
                                                <div className='col-sm-5'>
                                                    <label className="text-right">Website</label>
                                                </div>
                                                <div className='col-sm-5'>
                                                    <input type="text" className="form-control form-control-user" id="Website"
                                                        name="Website" placeholder="Website" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className='col-sm-6 row'>
                                                <div className='col-sm-5'>
                                                    <label className="text-right">Start Date</label>
                                                </div>
                                                <div className='col-sm-5'>
                                                    <input type="date" className="form-control form-control-user" id="dob"
                                                         name="dob" placeholder="Valid" />
                                                </div>
                                            </div>
                                            <div className='col-sm-6 row'>
                                                <div className='col-sm-5'>
                                                    <label className="text-right">End Date</label>
                                                </div>
                                                <div className='col-sm-5'>
                                                    <input type="date" className="form-control form-control-user" id="dob"
                                                         name="dob" placeholder="Valid" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className='col-sm-6 row'>
                                                <div className='col-sm-5'>
                                                    <label className="text-right">Default Currency</label>
                                                </div>
                                                <div className='col-sm-5'>
                                                    <select name="Currency" id="Currency" className='form-control'>
                                                        <option value="Dollar" selected>USD</option>
                                                        <option value="Euro">EUR</option>
                                                        <option value="Rupee">INR</option>
                                                        <option value="Pound">GBP</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className='col-sm-6 row'>
                                                <div className='col-sm-5'>
                                                    <label className="text-right">System TimeZone</label>
                                                </div>
                                                <div className='col-sm-5'>
                                                    <input type="time" className="form-control form-control-user" id="appt"
                                                    name="appt" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className='col-sm-6 row'>
                                                <div className='col-sm-5'>
                                                    <label className="text-right">Modules Subscribed</label>
                                                </div>
                                                <div className='col-sm-5' classes="text-area">
                                                    <textarea rows="4" className="form-control form-control-user" id="Modules Subscribed"
                                                        name="Modules Subscribed" placeholder="Modules Subscribed"   style={{overflowY:"auto" , height:"100px" ,resize:"none"}}/>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )



}

export default OrganizationDetails