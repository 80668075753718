import React, { Component } from 'react' 
import { useNavigate ,useParams } from 'react-router-dom';
import CreateAccuralComponent from '../../Components/AccuralsComponent/CreateAccuralComponent/CreateAccuralComponent';

function CreateAccurals(props){
    let navigate = useNavigate();
    const params = useParams();
     
     return (  
         <div> 
             <CreateAccuralComponent {...props}  navigate={navigate} id={params.id} name={params.name} value={params.value}/>
         </div>
     );  

}

export default CreateAccurals