import { Link, useLocation } from "react-router-dom"
import React, { useState, useEffect } from 'react'
import classes from '../RebateProgramComponent/CreateRebateComponent/Form.module.css'
import Service from '../../Services/Service';
import 'react-multiple-select-dropdown-lite/dist/index.css'
import CustomErrorPopup from '../../UI/CustomErrorPopup';
import Multiselect from 'multiselect-react-dropdown';
import SessionManage from "../../SessionManagement/SessionManage";

function CreateNewCongfigurationProperty(props) {
    const location = useLocation();
    const [userPerms, setuserPermission] = useState([]);
    let rowData = [];
    const [listViewData, setListViewData] = useState(rowData);
    const [errorMessage, setErrorMessage] = useState("");
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorStatus, setErrorStatus] = useState("Error");
    const [isValid, setIsValid] = useState(true);
    const [policyStatus, setPolicyStatus] = useState(false);
    const [isfiscalyear, setFiscalYear] = useState(false);
    let loggedInUser = SessionManage.getuserid();
    const [inputField, setInputField] = useState({
        Name: '',
        Label: '',
        fromMonth: '',
        toMonth: '',
        isFiscalYear: false,
        CreatedUserId: loggedInUser,
        LastModifiedUserId: loggedInUser
    })
    useEffect(() => {
        getUserPermission(location.pathname);
        if (props.id !== 0) {

            // getdata("congurationprop/GetById", props.id);
            setInputField({
                "Id": 1,
                "CreatedDate": "2022-05-16T16:03:37.470356+05:30",
                "LastModifiedDate": "2022-05-16T16:03:37.470356+05:30",
                "Deleted": false,
                "Name": "url",
                "Label": "http://localhost:8000/api/",
                "CreatedUserId": null,
                "LastModifiedUserId": null,
                "DefaultGroupId": null,
                "fromMonth": '',
                "toMonth": '',
            })
            setPolicyStatus(true)

        }
    }, [])
    const getUserPermission = (pathname) => {
        Service.GetUserPermission(pathname).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res !== undefined) {
                        if (res.data !== 'null' && res.data !== undefined && res.data !== '') {
                            if (res.data.DataList !== undefined) {
                                setuserPermission(res.data.DataList)
                            }
                        }
                    }
                }
            }

        });
    }
    const getdata = (method, id) => {
        Service.getdatabyId(method, id).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                            setInputField(res.data);
                            setPolicyStatus(true)
                        }
                    }
                }
            }

        });
    }

    const inputsHandler = (e) => {
        if (policyStatus === false) {
            const { name, value } = e.target;
            setInputField(() => ({
                ...inputField, [name]: value,
            }));
        }
        else {
            const { name, value } = e.target;
            setInputField(() => ({
                ...inputField, [name]: value,
            }));
        }
    }

    const fiscalYearHandler = (e) => {
        setFiscalYear(e.target.checked)
        setInputField(() => ({
            ...inputField, ['isFiscalYear']: e.target.checked,
        }));

    }

    const fiscalYearMonthChnage = (e) => {
        const { name, value } = e.target;
        setInputField(() => ({
            ...inputField, [name]: value,
        }));

        if (name == 'fromMonth') {
            setInputField(() => ({
                ...inputField, ['Label']: value,
            }));
        }
        if (name == 'toMonth') {
            let existing_label = inputField.Label
            existing_label = existing_label.split("-")[0]
            let label = String(existing_label) + "-" + String(value)
            setInputField(() => ({
                ...inputField, ['Label']: label,
            }));
        }
    }

    const checkValidation = () => {
        if (inputField.Name == null || inputField.Name == '') {
            setErrorMessage('Please Enter Name')
            setIsValid(false)
            setShowErrorModal(true)
        }
        else if ((inputField.Label == '' || inputField.Label == null) && inputField.isFiscalYear == null) {
            setErrorMessage('Please Enter Label')
            setIsValid(false)
            setShowErrorModal(true)
        }
        else {
            // alert('calling func')
            saveHandler()
        }
    }

    const saveHandler = () => {
        let data = inputField
        // if (isValid === true) {
        //    // alert('calling api')
        //     Service.createorupdate("congurationprop/CreateOrUpdate", data).then(res => {
        //        // alert(res.data)
        //         if (res.data > 0) {
        //             props.navigate('/congurationprop');
        //         }
        //         else {
        //             setErrorMessage('Same configuration Property already exists')
        //             setShowErrorModal(true)
        //         }
        //     });
        // }
        props.navigate('/congurationprop');
    }


const class_textbox = `col-md-2 mt-2 textbox-padding  ${Service.editPerms(userPerms) == false ? "disabledClass":""}`;
    return (
        <>
            <div className="container-fluid content-top-gap">

                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb my-breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                        <li className="breadcrumb-item active" aria-current="page"><Link to="/congurationprop">Configuration Properties List</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">Configuration Property</li>
                    </ol>
                </nav>

                <div className="row mt-3" id="rebateProgramdiv">
                    <div className="col-lg-12 pr-lg-2">
                        <div className="card text-center card_border">
                            <div className="card-header chart-grid__header">
                                <div className="row">
                                    <div className="col-md-7 text-left">
                                        Create New Configuration Property
                                    </div>
                                    <div className="col-md-5">
                                        {Service.editPerms(userPerms) == false ? "" : <button className="btn  btn-update btn-sm ml-2" type="submit" style={{float:"right", width: "40px"}} onClick={checkValidation}>Save</button>}
                                        <Link className="btn  btn-update btn-sm ml-2" id="close" style={{ width: "80px", backgroundColor: "buttonface",float:"right" }} to="/congurationprop" >Close</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body" style={{ minHeight: "60vh" }}>
                                <div className="container">
                                    <div className="row">
                                        <label className="col-md-2 text-left">Is Fiscal Year Property<span className={classes.mandatory}></span></label>
                                        <input name="isFiscalYear" type="checkbox" onChange={(event) => fiscalYearHandler(event)} value={inputField.isFiscalYear} />
                                        <div className="col-md-2">
                                        </div>
                                    </div>

                                    <div className="row"> <div className="col-md-2">
                                    </div>
                                        {isfiscalyear ?
                                            <>
                                                <select name="fromMonth" onChange={fiscalYearMonthChnage}  className={class_textbox}> 
                                                    <option value=''></option>
                                                    <option value='January'>January</option>
                                                    <option value='February'>February</option>
                                                    <option value='March'>March</option>
                                                    <option value='April'>April</option>
                                                    <option value='May'>May</option>
                                                    <option value='June'>June</option>
                                                    <option value='July'>July</option>
                                                    <option value='August'>August</option>
                                                    <option value='September'>September</option>
                                                    <option value='October'>October</option>
                                                    <option value='November'>November</option>
                                                    <option value='December'>December</option>
                                                </select><span className={classes.mandatory}>*</span>
                                                &nbsp;To&nbsp;
                                                <select name="toMonth" onChange={fiscalYearMonthChnage}  className={class_textbox}>
                                                    <option value=''></option>
                                                    <option value='January'>January</option>
                                                    <option value='February'>February</option>
                                                    <option value='March'>March</option>
                                                    <option value='April'>April</option>
                                                    <option value='May'>May</option>
                                                    <option value='June'>June</option>
                                                    <option value='July'>July</option>
                                                    <option value='August'>August</option>
                                                    <option value='September'>September</option>
                                                    <option value='October'>October</option>
                                                    <option value='November'>November</option>
                                                    <option value='December'>December</option>
                                                </select><span className={classes.mandatory}>*</span>
                                            </> : ""
                                        }
                                    </div>
                                    <div className="row">
                                        <label className="col-md-2 text-left">Name<span className={classes.mandatory}>*</span></label>
                                        <input name="Name" type="text" onChange={inputsHandler} className="col-md-2 mt-2 textbox-padding" value={inputField.Name} disabled={policyStatus} />
                                        <div className="col-md-2">
                                        </div>
                                    </div>
                                    <div className="row">
                                        <label className="col-md-2 text-left">Label<span className={classes.mandatory}>*</span></label>
                                        <input name="Label" type="text" onChange={inputsHandler} className={class_textbox} value={inputField.Label} disabled={isfiscalyear} />
                                        <div className="col-md-2">
                                        </div>
                                    </div>
                                </div>


                            </div>

                        </div>
                    </div>
                </div>
                <CustomErrorPopup ErrorMessage={errorMessage} show={showErrorModal} ErrorStatus={errorStatus}
                    onHide={() => setShowErrorModal(false)}></CustomErrorPopup>
            </div>
        </>
    )
}
export default CreateNewCongfigurationProperty