
import { Link, Navigate, useLocation } from "react-router-dom"
import { Form, FormGroup, Col, Row, Button, InputGroup, Collapse, Modal } from 'react-bootstrap';
import React, { useState, useEffect } from 'react'
import classes from '../RebateProgramComponent/CreateRebateComponent/Form.module.css'
import Service from '../../Services/Service';
import 'react-multiple-select-dropdown-lite/dist/index.css'
import CustomErrorPopup from '../../UI/CustomErrorPopup';
import $, { each } from 'jquery';
import Multiselect from 'multiselect-react-dropdown';
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function CreateExceptionConfiguratonView(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const [userPerms, setuserPermission] = useState([]);
    const [t, i18n] = useTranslation('translation');
    // let disableHeaderField = false;
    const [errorMessage, setErrorMessage] = useState("");
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorStatus, setErrorStatus] = useState("Error");
    let loggedInUser = sessionStorage.getItem("id");
    const [closeModal, setCloseModal] = useState(false);
    const [handleChange, setHandleChange] = useState(false);
    const [isLocked, setIsLocked] = useState(false);
    const [lockedBy, setLockedBy] = useState('');
    let disabled = false;
    const [selectedOptionsExceptionType, setSelectedOptionsExceptionType] = useState('');
    const [showDeleteModal, SetShowDeleteModal] = useState(false);

    // var tempData = [
    //     { 'cataegory': "Product", 'subcategory': "Product Name", "selected": true },
    //     { 'cataegory': "Product", 'subcategory': "Product Label", "selected": false },
    //     { 'cataegory': "Product", 'subcategory': "Category 1", "selected": true },
    //     { 'cataegory': "Product", 'subcategory': "Category 2", "selected": false },
    //     { 'cataegory': "Product", 'subcategory': "Category 3", "selected": false },
    //     { 'cataegory': "Product", 'subcategory': "Category 4", "selected": false },
    //     { 'cataegory': "Product", 'subcategory': "Category 5", "selected": false },

    //     { 'cataegory': "Customer", 'subcategory': "Customer Name", "selected": true },
    //     { 'cataegory': "Customer", 'subcategory': "Customer Label", "selected": false },
    //     { 'cataegory': "Customer", 'subcategory': "Customer Group Name", "selected": false },
    //     { 'cataegory': "Customer", 'subcategory': "Customer Group Label", "selected": false },

    //     { 'cataegory': "MasterData", 'subcategory': "Rebate Type", "selected": true },
    //     { 'cataegory': "MasterData", 'subcategory': "Settlement Frequency", "selected": false }
    // ]

    const [showSelecteddata, setSelectedData] = useState([]);
    const [newList, setNewList] = useState([])
    const [exceptionTypeData, setExceptionTypeData] = useState([])
    const [priorityOptions, setPriorityOptions] = useState([])
    const [priorityOptionscheck, setPriorityOptionscheck] = useState(false)
    const [excpData, setExcepData] = useState(
        {
            "Id": 0,
            "User_id": loggedInUser,
            // "ExcpTypeId": 0,
            "ExcepData": []
        }
    )



    useEffect(() => {
        getExceptionType("ExceptionPolicy/GetAllExceptionType");

        if (props.id != 0) {
            getExcepById("ExceptionPolicy/GetByID", props.id)
        }// else {
            getExceptionData("ExceptionPolicy/ExceptionPolicyData");
        //}

    }, [])

    const getExceptionType = (method) => {
        console.log('26/9', method)
        Service.getdata(method).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                            // rowData = res.data
                            setExceptionTypeData(res.data)
                        }
                    }
                }
            }
        });
    }
    const getExceptionData = (method) => {
        console.log('13/6', method)
        Service.getdata(method).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                            console.log("13/6", res.data.DataList)
                            let rowData = [...showSelecteddata];
                            rowData = res.data.DataList
                            console.log("19/6", rowData)
                            let selectedData = []
                            rowData.forEach((each) => {
                                if (each.Category.toLowerCase() == ('Product').toLowerCase()) {
                                    each.SubCategory.map((subcat) => {
                                        if (subcat.SubCategory.toLowerCase() == ('Label').toLowerCase() || subcat.SubCategory.toLowerCase() == ('Category1').toLowerCase()) {
                                            subcat.disabled = true
                                            subcat.selected = true
                                            selectedData.push({
                                                "Excp_Id": subcat.Id,
                                                "ExcpCat": each.Category,
                                            })
                                        }
                                    })
                                }
                                if (each.Category.toLowerCase() == ('Customer').toLowerCase()) {
                                    each.SubCategory.map((subcat) => {
                                        subcat.disabled = true
                                        subcat.selected = true
                                        selectedData.push({
                                            "Excp_Id": subcat.Id,
                                            "ExcpCat": each.Category,
                                        })
                                    })
                                }
                                if (each.Category.toLowerCase() == ('SettlementFrequency').toLowerCase()) {
                                    each.SubCategory.map((subcat) => {
                                        subcat.disabled = true
                                        subcat.selected = true
                                        selectedData.push({
                                            "Excp_Id": subcat.Id,
                                            "ExcpCat": each.Category,
                                        })
                                    })
                                }
                            })
                            setSelectedData(rowData)
                            setExcepData({ ...excpData, ['ExcepData']: selectedData })
                            let count = res.data.Totalcount
                            let options = []
                            for (let i = 1; i <= count; i++) {
                                let data = { "attr": "", "selected": false, "value": i }
                                options.push(data)
                            }
                            setPriorityOptions(options)
                        }
                    }
                }
            }
        });
    }
    console.log("19/6 - api", excpData)
    const getExcepById = (method, id) => {
        console.log("13/6", showSelecteddata)
        Service.getdata("ExceptionPolicy/ExceptionPolicyData").then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                            console.log("13/6", res.data.DataList)
                            let rowData = [...showSelecteddata];
                            rowData = res.data.DataList
                            setSelectedData(rowData)
                            setTimeout(() => {
                                Service.getdata(method).then((res) => {
                                    if (typeof res !== 'undefined') {
                                        if (typeof res.data !== 'undefined' && res.data !== 'null') {
                                            if (res.status === 200) {
                                                if (res.data !== 'null' && res.data !== 'undefined') {
                                                    let data = res.data[0].exceptionPolicyValues;
                                                    console.log("22/6 - res", res.data)
                                                    console.log("22/6 - menu", data)
                                                    console.log("22/6 - id", res.data[0].Id)
                                                    let Id = res.data[0].Id
                                                    let data1 = [];
                                                    rowData.map((item) => {
                                                        const matchingItem = data.find(list2Item => list2Item.Category === item.Category);
                                                        if (!matchingItem) {
                                                            data1.push(item);
                                                        }
                                                        else {
                                                            data1.push(matchingItem);
                                                        }
                                                    });
                                                    setSelectedData(data1)
                                                    setSelectedOptionsExceptionType(res.data[0].ExcpTypeId)

                                                    // const options = []
                                                    // let existingprio = []
                                                    // data1.forEach((each) => {
                                                    //     if (each.priority) {
                                                    //         options.push({ "attr": each.Category, "selected": true, "value": each.priority })
                                                    //         existingprio.push(each.priority)
                                                    //     }
                                                    // })
                                                    // for (let i = 0; i < data1.length; i++) {
                                                    //     if (!existingprio.includes(i + 1)) {
                                                    //         options.push({ "attr": '', "selected": false, "value": i + 1 })
                                                    //     }
                                                    // }
                                                    // setPriorityOptions(options)

                                                    console.log("19/6-apidatabyid", data1)
                                                    data1.forEach((each) => {
                                                        if (each.Category.toLowerCase() == ('Product').toLowerCase()) {
                                                            each.SubCategory.map((subcat) => {
                                                                if (subcat.SubCategory.toLowerCase() == ('Label').toLowerCase() || subcat.SubCategory.toLowerCase() == ('Category1').toLowerCase()) {
                                                                    subcat.disabled = true
                                                                }
                                                            })
                                                        }
                                                        if (each.Category.toLowerCase() == ('Customer').toLowerCase()) {
                                                            each.SubCategory.map((subcat) => {
                                                                subcat.disabled = true
                                                            })
                                                        }
                                                        if (each.Category.toLowerCase() == ('SettlementFrequency').toLowerCase()) {
                                                            each.SubCategory.map((subcat) => {
                                                                subcat.disabled = true
                                                            })
                                                        }
                                                    })

                                                    let temp = []
                                                    data.forEach((cat) => {
                                                        cat.SubCategory.forEach((sub) => {
                                                            if (sub.selected == true) {
                                                                // temp.push({ "Excp_Id": sub.Id, "ExcpCat": cat.Category, "priority": cat.priority })
                                                                temp.push({ "Excp_Id": sub.Id, "ExcpCat": cat.Category, "priority": cat.priority })
                                                            }
                                                        })
                                                    })
                                                    setExcepData({ ...excpData, ['Id']: Id, ['User_id']: loggedInUser, ['ExcepData']: temp })
                                                }
                                            }
                                        }
                                    }
                                });
                            }, 350);
                        }
                    }
                }
            }
        });
    }
    // console.log("15/6 - pri", priorityOptions)
    console.log("22/6 - api", excpData)

    const checkValidation = () => {

    }

    console.log("12/6 prio", priorityOptions)
    console.log("12/6 api data", excpData)
    // console.log("12/6 " , priorityOptions)

    const saveHandler = (flag) => {

        let priorityCheck = false
        // if (priorityOptionscheck == false) {
        //     setShowErrorModal(true)
        //     setErrorMessage("Please select priority options")
        // }

        // priorityOptions.forEach((each, index) => {
        //     excpData.ExcepData.forEach((data, index) => {
        //         if (each.attr == data.ExcpCat) {
        //             data.priority = each.value
        //         }
        //     })
        // })

        // excpData.ExcepData.forEach((data, index) => {
        //     if (data.priority == '') {
        //         setShowErrorModal(true)
        //         setErrorMessage("Please select priority options for" + data.ExcpCat)
        //     }
        //     else{
        //         priorityCheck = true
        //     }
        // })
        // setExcepData(excpData)

        // if (flag == 'Save' && priorityOptionscheck == true &&  priorityCheck == true) {
        if (flag == 'Save') {
            Service.databyMassActions("ExceptionPolicy/CreateOrUpdate", excpData).then((res) => {
                if (typeof res !== 'undefined') {
                    if (typeof res.data !== 'undefined' && res.data !== 'null') {
                        let rData = res.data;
                        if (res.data.Code === 200) {
                            if (res.data !== 'null' && res.data !== 'undefined') {
                                if (res.data.Code == 200) {
                                    console.log("8/5", res)
                                    console.log("8/5", res.data)
                                    let id = parseInt(res.data.Status)
                                    // getMassActionById("MassAction/GetByID", id)
                                    toast.success('Saved Successfully !', {
                                        position: toast.POSITION.TOP_RIGHT,
                                    });
                                    getExcepById("ExceptionPolicy/GetByID",id);
                                }
                                else {
                                    setErrorMessage(res.data.ErrorMessage);
                                    setErrorStatus("Error")
                                    setShowErrorModal(true)
                                }
                            }
                        }
                    }
                }
            });
        }
        // if (flag == 'Save&Close' &&  priorityOptionscheck == true &&  priorityCheck == true) {
        if (flag == 'Save&Close') {
            Service.databyMassActions("ExceptionPolicy/CreateOrUpdate", excpData).then((res) => {
                if (typeof res !== 'undefined') {
                    if (typeof res.data !== 'undefined' && res.data !== 'null') {
                        let rData = res.data;
                        if (res.data.Code === 200) {
                            if (res.data !== 'null' && res.data !== 'undefined') {
                                if (res.data.Code == 200) {
                                    console.log("8/5", res)
                                    console.log("8/5", res.data)
                                    // let id = parseInt(res.data.Status)
                                    toast.success('Saved Successfully !', {
                                        position: toast.POSITION.TOP_RIGHT,
                                    });
                                    navigate("/");
                                }
                                else {
                                    setErrorMessage(res.data.ErrorMessage);
                                    setErrorStatus("Error")
                                    setShowErrorModal(true)
                                }
                            }
                        }
                    }
                }
            });
        }
    }


    const customStyles = {
        control: base => ({
            ...base,
            minHeight: 28
        }),
        dropdownIndicator: base => ({
            ...base,
            padding: 2,
            fontSize: "0.75rem",
        }),
        clearIndicator: base => ({
            ...base,
            padding: 2
        }),
        multiValue: base => ({
            ...base,
            fontSize: "0.75rem",
        }),
        valueContainer: base => ({
            ...base,
            padding: '0px 2px',
            fontSize: "0.75rem",
        }),
        input: base => ({
            ...base,
            margin: 0,
            padding: 0
        })
    };

    const class_select = ` col-md-2 textbox-padding mt-2 fontsizedropdown  ${Service.editPerms(userPerms) == false ? "disabledClass" : ""}`;

    const DeleteHandler = () => {

    }
    const checkDelete = (flag) => {
        if (flag == "Open") {
            SetShowDeleteModal(true)
        }
        if (flag == "Close") {
            SetShowDeleteModal(false)
        }
        if (flag == "Proceed") {
            SetShowDeleteModal(false)
            DeleteHandler()
        }
    }


    const closeHandler = (flag) => {
        if (flag == "Open" && handleChange == true) {
            setCloseModal(true)
        }
        // if (flag == "Open" && handleChange == false) {
        //     Service.LockUnLockEntity('Configurationprop/UnLockEntity', "RebateType", props.id, loggedInUser).then((res) => {
        //     });
        props.navigate("/")
        // }
        if (flag == "Close") {
            // Service.LockUnLockEntity('Configurationprop/UnLockEntity', "RebateType", props.id, loggedInUser).then((res) => {

            // });
            props.navigate("/")
        }
        if (flag == "Cancel") {
            setCloseModal(false)
        }

    }
    if (isLocked === false || (isLocked === true && lockedBy.toString() === loggedInUser)) {
        disabled = false;
    }
    else {
        disabled = true;
    }

    const OnChangeExceptionType = (data) => {
        setSelectedOptionsExceptionType(data.Id)
        setExcepData({ ...excpData, ['ExcpTypeId']: data.Id })
    }

    console.log("6/6 - apidata all", showSelecteddata)
    const checkData = (event, subcat, cat) => {
        var olddata = [...showSelecteddata];
        olddata.map((i) => {
            i.SubCategory.map((each) => {
                if (i.Id == cat.Id && each.SubCategory == subcat.SubCategory) {
                    each.selected = event.target.checked
                    if (event.target.checked == true) {
                        excpData.ExcepData.push(
                            {
                                "Excp_Id": each.Id,
                                "ExcpCat": cat.Category,
                                // "priority": ''
                            }
                        )
                    }
                    if (event.target.checked == false) {
                        excpData.ExcepData = excpData.ExcepData.filter((x) => x.Excp_Id != each.Id)
                    }
                }
            })
        })
        setExcepData(excpData)
        setSelectedData(olddata)
    }

    console.log("9/6", excpData)

    const handlePriorityOptions = (event, parent) => {
        console.log("8/6- event", event.target.value)
        let po = [...priorityOptions]
        po.forEach((each) => {
            if (each.attr == parent) {
                each.attr = ''
                each.selected = false
            }
        })
        po.forEach((each) => {
            if (event.target.value == each.value) {
                each.selected = true
                each.attr = parent
            }
        })
        setPriorityOptions(po)
        setPriorityOptionscheck(true)
        setSelectedData([...showSelecteddata])
        console.log("8/6", priorityOptions)
    }





    return (
        <>
            <div className="container-fluid content-top-gap">

                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb my-breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">{t('Home.title')}</Link></li>
                        {/* <li className="breadcrumb-item active" aria-current="page"><Link to="/ExceptionConfigList">{t('ExceptionConfig.ExceptionConfigList')}</Link></li> */}
                        <li className="breadcrumb-item active" id="libread" aria-current="page">{t('ExceptionConfig.CreateExceptionConfig')}</li>
                    </ol>
                </nav>

                <div className="row mt-3" id="rebateProgramdiv">
                    <div className="col-lg-12 pr-lg-2">
                        <div className="card text-center card_border">
                            <div className="card-header chart-grid__header">
                                <div className="row">
                                    <div className="col-md-7 text-left">
                                        {t('ExceptionConfig.CreateExceptionConfig')}
                                    </div>
                                    <div className="col-md-5">
                                        <button className="btn  btn-update btn-sm ml-2" type="submit" style={{ float: "right", width: "40px" }} onClick={(event) => saveHandler("Save")}>{t('Save')}</button>
                                        <button className="btn  btn-update btn-sm ml-2" id="close" style={{ width: "45px", backgroundColor: "buttonface", float: "right" }} onClick={(event) => closeHandler("Close")} >{t('Close')}</button>
                                        {/* <button className="btn  btn-update btn-sm ml-2" id="close" style={{ width: "80px", backgroundColor: "buttonface", float: "right" }} onClick={(event) => checkDelete("Open")} >{t('Delete')}</button> */}
                                        <button className="btn  btn-update btn-sm ml-2" id="close" style={{ width: "80px", backgroundColor: "buttonface", float: "right" }} onClick={(event) => saveHandler("Save&Close")} >{t('Save&Close')}</button>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body" style={{ minHeight: "80vh" }}>
                                <div className="container">
                                    <div className="row">
                                        {/* <label className="col-md-2 text-left">{t('ExceptionConfig.ExceptionType')}<span className={classes.mandatory}>*</span></label>
                                        <Select
                                            className={`col-md-2 textbox-padding-dropdown mt-2 fontsizedropdown`}
                                            options={exceptionTypeData}
                                            placeholder={t('Select')}
                                            value={
                                                exceptionTypeData.filter(function (option) {
                                                    return option.Id === selectedOptionsExceptionType;
                                                })
                                            }
                                            onChange={OnChangeExceptionType}
                                            isSearchable={true}
                                            styles={customStyles}
                                        /> */}
                                        <div className="col-md-2">
                                        </div>
                                    </div>
                                </div>
                                {/* {console.log("6/6", showSelecteddata)} */}
                                {showSelecteddata.map((each, parentIndex) =>
                                    <div>
                                        <div className="row mt-5" id="card-RPL">
                                            <div className="col-lg-12 pr-lg-2">
                                                <div className="card text-center card_border">
                                                    <div className="card-header chart-grid__header">
                                                        <div className="row">
                                                            <div className="col-md-12 text-left">
                                                                <b>{each.Category}</b>
                                                                &nbsp;
                                                                {/* <select style={{ width: "70px" }} name="Priority" onChange={(e) => handlePriorityOptions(e, each.Category)}>
                                                                    <option value={-1}>Priority</option>
                                                                    {priorityOptions.map((i) => (
                                                                        (i.selected == false || i.attr == each.Category) ?
                                                                            <option selected={i.attr == each.Category} value={i.value}> {i.value}</option>
                                                                            :
                                                                            <></>
                                                                    ))
                                                                    }
                                                                </select> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card-body" style={{ minHeight: "95px" }} >
                                                        <div className="container" >
                                                            <div className="row">
                                                                {each.SubCategory.map((i) =>
                                                                    <div className="col-md-6 mt-2 text-left">
                                                                        <input  type="checkbox"  name="filter-option-all" id="option-all" disabled={i.disabled} checked={i.selected} onChange={(event) => checkData(event, i, each)} />
                                                                        &nbsp;{i.SubCategory}
                                                                    </div>
                                                                )
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div >
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div >







                <Modal show={closeModal}
                    size="sm"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <Modal.Body style={{ "textAlign": 'center', fontFamily: 'Segoe UI' }}>{t('Policies.YourChangesWillBeLost!AreYouSureYouWantToClose?')}</Modal.Body>
                    <Modal.Footer>
                        <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "45px" }} size='sm' onClick={() => closeHandler("Close")}>
                            {t('Close')}
                        </Button>
                        <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "50px" }} size='sm' onClick={() => closeHandler("Cancel")}>
                            {t('Cancel')}
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={showDeleteModal}
                    size="sm"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <Modal.Body style={{ "textAlign": 'center' }}>{t('Rebates.AreYouSureYouWantToDelete')}</Modal.Body>
                    <Modal.Footer>
                        <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "45px" }} size='sm' onClick={() => checkDelete('Close')}>
                            {t('Close')}
                        </Button>
                        <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "50px" }} size='sm' onClick={() => checkDelete('Proceed')}>
                            {t('Delete')}
                        </Button>
                    </Modal.Footer>
                </Modal>
                <CustomErrorPopup ErrorMessage={errorMessage} show={showErrorModal} ErrorStatus={errorStatus}
                    onHide={() => setShowErrorModal(false)}></CustomErrorPopup>
            </div >
        </>
    )
}
export default CreateExceptionConfiguratonView