import React, { useEffect, useState, useRef } from 'react';
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import { Dropdown, Button, InputGroup, FormControl, Modal } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import Service from '../../../Services/Service';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import Select from "react-select";
import SessionManage from '../../../SessionManagement/SessionManage';
import { CSVLink } from "react-csv";
import { useTranslation } from "react-i18next";

function MassActionGenerateAccrualsList(props) {

    let loggedInUser = sessionStorage.getItem("id")
    const csvInstance = useRef();
    const [t, i18n] = useTranslation('translation');
    const [csvList, setCsvList] = useState([]);
    const [sortColName, setSortColName] = useState("");
    const [sticky, setSticky] = useState("");
    const [userPerms, setuserPermission] = useState([]);
    const location = useLocation();
    const navigate = useNavigate();
    const [showbutton, setshowbutton] = useState(false);
    const [showspinner, setshowspinner] = useState(false);
    const [filterValue, setFilterValue] = useState('');
    const [filterCol, setFilterCol] = useState('');
    const [showfilterpopup, setshowfilterpopup] = useState(false);
    const [dropdownData, setDropdownData] = useState([]);
    const [searchValue, setSearchValue] = useState(null)
    const [filterdropdownData, setFilterDropdownData] = useState([]);
    const [filterSearch, setFilterSearch] = useState("");
    const [filtertext, setfiltertext] = useState('');
    const [filterValueDict, setFilterValueDict] = useState({
        MassActionId: '',
        Desc: '',
        created_date: '',
        LastModifiedDate: '',
    });
    const [multiFilterValue, setMultiFilterValue] = useState({
        CreatedUser: '',
        LastModifiedUser: ''
    });

    const [totalselectedshow, settotalselectedshow] = useState('');
    const [totalselectedcount, settotalselectedcount] = useState(0);
    const [totalCountlst, settotalCountlst] = useState('');
    const checkAll = (mycheckbox) => {
        var checkboxes = document.querySelectorAll("input[type = 'checkbox']");
        if (mycheckbox.target.checked == true) {
            settotalselectedshow(true);
            checkboxes.forEach(function (checkbox) {
                checkbox.checked = true;
            });
            settotalselectedcount(totalCountlst)
        }
        else {
            settotalselectedshow(false);
            checkboxes.forEach(function (checkbox) {
                checkbox.checked = false;
            });
            settotalselectedcount(0)
        }

    }
    let rowData = [];

    const [listViewData, setListViewData] = useState(rowData)
    const [filteredArray, setFilteredArray] = useState(rowData)
    const [state, setState] = useState({ status: '2' });
    const [checkedValueid, setCheckedValueid] = useState([]);
    const [columnAscending, setColumnAscending] = useState(true);
    const [checkedMultiValueid, setCheckedMultiValueid] = useState([]);
    const [multiValueids, setMultiValueIds] = useState([]);
    const [errorDeleteModal, setErrorDeleteModal] = useState(false);
    const [showDeleteModal, toggleModal] = useState(false)
    const [deleteshowbutton, setDeleteshowbutton] = useState('');
    const [checkedValue, setCheckedValue] = useState([]);
    const [checkedValueStatus, setCheckedValueStatus] = useState([]);
    const [paging, setpaging] = useState({
        pageSize: 20,
        pageNumber: 1
    })
    const [operatorVal, setOperatorValue] = useState({ value: "=", label: "equals" })
    const [operatorValOption, setOperatorValueOption] = useState([
        { value: "=", label: "equals" },
        { value: "<", label: "less then" },
        { value: ">", label: "greater then" },
        { value: "<=", label: "less or equal" },
        { value: ">=", label: "greater or equal" }
    ])

    const [showLoadMore, setshowLoadMore] = useState('');

    useEffect(() => {
        debugger
        getUserPermission(location.pathname);
        setColumnAscending(false);
        setSortColName("Id");
        settotalselectedshow(false);
        setshowspinner(true);
        getMassActionViewData("MassAction/GetAllGenerate", paging.pageSize, paging.pageNumber, "Descending", "Id", [], searchValue, "Accrual");
        window.addEventListener("scroll", isSticky);
        return () => {
            window.removeEventListener("scroll", isSticky);
        };
    }, [])
    const getUserPermission = (pathname) => {
        Service.GetUserPermission(pathname).then((res) => {
            if (typeof res !== 'undefined') {

                if (res.data !== 'null' && typeof res.data !== 'undefined' && res.data !== '') {
                    if (typeof res.data.DataList !== 'undefined') {
                        setuserPermission(res.data.DataList)
                    }
                }
            }
        });
    }
    const isSticky = () => {
        const scrollTop = window.scrollY;
        const stickyClass = scrollTop >= 60 ? "is-sticky-thead" : "";
        setSticky(stickyClass);
        const scrollTopActual = scrollTop >= 60 ? scrollTop - 85 : 0;
        var divtop1 = document.getElementById("tableA");
        setdivtop(divtop1.offsetTop + scrollTopActual)
    };
    const class_thead = `text-white rt-thead  ${sticky}`;

    const getMassActionViewData = (method, pageSize, pageNumber, sortType, SortProperty, filterValue, search, ActionFor) => {
        console.log('26/9', method, pageSize, pageNumber, sortType, SortProperty, filterValue, search, ActionFor)
        Service.getdataWithPagingByMassGroupSearch(method, pageSize, pageNumber, sortType, SortProperty, filterValue, search, ActionFor).then((res) => {

            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {

                            rowData = res.data.DataList
                            console.log(res.data)
                            setListViewData(rowData)
                            setFilteredArray(rowData)
                            // console.log(filteredArray)
                            settotalCountlst(res.data.Totalcount)
                            setshowspinner(false);
                            LoadMore(rowData.length, res.data.Totalcount)
                        }
                    }
                }
            }
        });
    }



    console.log(filteredArray)



    const searchHandler = (event) => {
        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
        //let pagesize = 20;
        let pagenumber = 1;
        //paging.pageSize = pagesize;
        paging.pageNumber = pagenumber;
        setpaging(paging);
        if (event.target.value != '') {
            // filterHandler('Reset');
            setSearchValue(String(event.target.value).toLowerCase())
            // getMassActionViewData("MassAction/GetAllGenerate", paging.pageSize, pagenumber, sortType, sortColName, filterLst(), event.target.value);
        }
        else {

            setSearchValue(null);
            // getMassActionViewData("MassAction/GetAllGenerate", paging.pageSize, pagenumber, sortType, sortColName, filterLst(), null);
        }
    }

    const handleLoadMore = () => {

        let pagenumber = paging.pageNumber + 1;
        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
        paging.pageNumber = pagenumber
        setpaging(paging);

    };


    const handleChange = (e, id, status, name) => {
        let isChecked = e.target.checked;   // true if it is checked false if unchecked
        console.log('checked', isChecked)
        console.log('checked', id)
        let checkedBoxes = document.querySelectorAll('input[name=chkBox]:checked');
        if (isChecked === true) {
            setCheckedValue(e.target.value)  // check box value
            setCheckedValueStatus(status)
            settotalselectedshow(true);
            settotalselectedcount(checkedBoxes.length);
            setCheckedValueid(id)
            // console.log('checked value', checkedValue)
        }
        else {
            setCheckedValue([])  // check box value
            setCheckedValueStatus([])
            setCheckedValueid([])
            settotalselectedshow(false);
            // console.log('checked value', checkedValue)
            settotalselectedcount(checkedBoxes.length);
        }

        if (checkedBoxes.length == 1) {
            setshowbutton(true);
            setDeleteshowbutton(false)
        }
        else if (checkedBoxes.length > 1) {
            setshowbutton(false);
            setDeleteshowbutton(true)
        }
        else {
            setshowbutton(false);
            setDeleteshowbutton(false)
        }
    }
    const OpenHandler = (id) => {
        if (id == null || id == 0) {
            alert('Please select the Mass Action to view')
        }
        else {
            navigate("/MassActionGenerateAccrualList/new/" + id);
        }
    }


    const filterInput = (e) => {
        console.log('9/7', e.target.value)
        let inputValue = e.target.value
        setFilterValue(inputValue)
        setFilterValueDict(() => ({
            ...filterValueDict, [filterCol]: e.target.value,
        }));
    }

    const OndropdownChange = (e, id, name) => {
        let isChecked = e.target.checked;
        var lastfilter = [...dropdownData]
        if (isChecked === true) {
            let currentdata = lastfilter.map((i) => {
                if (i.Id == id) {
                    i["ischecked"] = true;
                }
                return i;
            });
            console.log("20/3", dropdownData)
            console.log("Add New Column", currentdata)
            setDropdownData(currentdata);
            let data1 = []
            const getallData = {
                Name: name,
            }
            data1.push(getallData)
            setFilterValue(filterValue => [...filterValue, ...data1]);
            // setFilterValue(data1);
            let updateddata = ([...filterValue, ...data1]);
            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: updateddata,
            }));
        }
        else {
            let currentdata = lastfilter.map((i) => {
                if (i.Id === id) {
                    i["ischecked"] = false;
                }
                return i;
            });
            console.log("Add New Column", currentdata)
            setDropdownData(currentdata);
            setFilterValue(filterValue => filterValue.filter(data => data.Name !== name))
            let data1 = filterValue.filter(data => data.Name !== name);
            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: data1,
            }));
        }
    }

    const [divleft, setdivleft] = useState('');
    const [divtop, setdivtop] = useState('');

    const FilterColumn = (name) => {

        const scrollTop = window.scrollY;
        const scrollTopActual = scrollTop >= 60 ? scrollTop - 85 : 0;
        var divtop1 = document.getElementById("tableA");
        setdivtop(divtop1.offsetTop + scrollTopActual + 30)


        setFilterCol(name)
        setshowfilterpopup(true)
        if (name === 'CreatedUser') {
            setfiltertext(t('CreatedBy'))
            var testDiv = document.getElementById("CreatedUser");
            setdivleft((testDiv.offsetLeft + 30) + "px");
            DropdownHandler(name)
        }
        if (name === 'MassActionId') {
            setfiltertext(t('MassAction.massActionId'))
            var testDiv = document.getElementById("MassActionId");
            setdivleft((testDiv.offsetLeft + 30) + "px");
        }
        if (name === 'Desc') {
            setfiltertext(t('MassAction.Description'))
            var testDiv = document.getElementById("Desc");
            setdivleft((testDiv.offsetLeft + 30) + "px");
        }
        if (name === 'CreatedDate') {
            setfiltertext(t('CreatedDate'))
            var testDiv = document.getElementById("CreatedDate");
            setdivleft((testDiv.offsetLeft + 30) + "px");
        }
        if (name === 'LastModifiedUser') {
            setfiltertext(t('ModifiedBy'))
            var testDiv = document.getElementById("LastModifiedUser");
            setdivleft((testDiv.offsetLeft + 30) + "px");
            DropdownHandler(name);
        }

        if (name === 'LastModifiedDate') {
            setfiltertext(t('ModifiedDate'))
            var testDiv = document.getElementById("LastModifiedDate");
            setdivleft(((testDiv.offsetLeft + 30) - 100) + "px");
        }

        setshowfilterpopup(true)
    }


    const DropdownHandler = (key) => {
        Service.getfilterDropdownByGroup('MassAction/GetMassActionFilterValue', key).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                            // setDropdownData(res.data)
                            addnewfield(res.data, key);
                        }
                    }
                }
            }

        });
    }

    const UserfilterHandler = (e) => {
        // debugger;
        setFilterSearch(e.target.value)
        if (e.target.value !== "") {
            let searchResults = dropdownData.filter((each) => {
                return each.Name.toLowerCase().includes(String(e.target.value).toLowerCase())
            });
            setDropdownData(searchResults);
        }
        else {
            console.log("Testing Filtervalue", filterValue);
            setFilterSearch("")
            setDropdownData(filterdropdownData)
        }
    }

    const addnewfield = (data, key) => {
        console.log("4/7 - res", data)
        console.log("4/7 - key", key)
        debugger
        let currentdata = data.map((i) => {
            i["ischecked"] = false;
            return i;
        });
        let selectedKeys = multiFilterValue[key];
        if (key != 'active') {
            if (selectedKeys != '') {
                currentdata.forEach((each) => {
                    selectedKeys.forEach((eachSelected) => {
                        if (each.Name == eachSelected.Name) {
                            each.ischecked = true
                        }
                    })
                })
            }
            console.log("4/7", currentdata)
            setDropdownData(currentdata);
            setFilterDropdownData(currentdata)
        }
        else {
            if (selectedKeys != '') {
                currentdata.forEach((each) => {
                    selectedKeys.forEach((eachSelected) => {
                        if (each.Name == eachSelected.Name) {
                            each.ischecked = true
                        }
                    })
                })
                setDropdownData(currentdata)
                setFilterDropdownData(currentdata)
            }
            else {
                setDropdownData(currentdata)
                setFilterDropdownData(currentdata)
            }
        }
    }
    console.log("4/7", multiFilterValue)

    console.log('4/4-dropdown', dropdownData)
    const checkAllFilter = (mycheckbox) => {
        var lastfilter = [...dropdownData]
        let data1 = []
        var checkboxes = document.querySelectorAll("input[name = 'filter-option-all']");
        if (mycheckbox.target.checked == true) {
            // checkboxes.forEach(function (checkbox) {
            //   debugger
            //   checkbox.checked = true;
            // });
            let currentdata = lastfilter.map((i) => {
                i["ischecked"] = true;
                return i;
            });
            setDropdownData(currentdata)
            lastfilter.map((each) => {
                console.log('20/3-eacch', each)
                const getallData = {
                    Name: each.Name,
                }
                data1.push(getallData)
            })
            setFilterValue(filterValue => [...filterValue, ...data1]);
            let updateddata = ([...filterValue, ...data1]);
            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: updateddata,
            }));
        }
        else {
            // checkboxes.forEach(function (checkbox) {
            //   checkbox.checked = false;
            // });
            let currentdata = lastfilter.map((i) => {
                i["ischecked"] = false;
                return i;
            });
            setFilterValue([])
            setMultiFilterValue([])
        }
    }

    console.log('9/8', dropdownData)

    const filterHandler = (flag) => {
        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
        let pagenumber = 1;
        paging.pageNumber = pagenumber;

        if (flag === 'Apply') {
            setshowfilterpopup(false)
            let list = [];

            for (var key in multiFilterValue) {
                if (multiFilterValue[key].length > 0) {
                    let values = ""
                    multiFilterValue[key].map((i) => {
                        values += i.Name + ";";
                    })
                    if (values !== null && values !== '') {
                        list.push({
                            filterKey: key,
                            FilterValue: values
                        })
                    }
                }
            }
            for (var key in filterValueDict) {
                if (filterValueDict[key] !== null && filterValueDict[key] !== '') {
                    list.push({
                        filterKey: key,
                        FilterValue: filterValueDict[key]
                    })
                }
            }
            console.log('29-05', list)
            if (list.length > 0) {
                let pagesize = 20;
                let pagenumber = 1;
                paging.pageSize = pagesize;
                paging.pageNumber = pagenumber;
                setpaging(paging);
                if (list.length > 0) {
                    setpaging(paging);
                    // getMassActionViewData("MassAction/GetAllGenerate", paging.pageSize, pagenumber, sortType, sortColName, list, searchValue);
                }
            }
        }
        else {
            setshowfilterpopup(false)
            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: '',
            }));
            setFilterValueDict(() => ({
                ...filterValueDict, [filterCol]: '',
            }));

            let list = [];
            for (var key in multiFilterValue) {
                if (key !== filterCol && multiFilterValue[key].length > 0) {
                    let values = ""
                    multiFilterValue[key].map((i) => {
                        values += i.Name + ";";
                    })
                    if (values !== null && values !== '') {
                        list.push({
                            filterKey: key,
                            FilterValue: values
                        })
                    }
                }
            }

            for (var key in filterValueDict) {
                if (filterValueDict[key] !== null && filterValueDict[key] !== '' && key !== filterCol) {
                    list.push({
                        filterKey: key,
                        FilterValue: filterValueDict[key]
                    })
                }
            }

            setpaging(paging);
            // getMassActionViewData("MassAction/GetAllGenerate", paging.pageSize, pagenumber, sortType, sortColName, list, searchValue);
            setFilterCol('')
            setFilterValue('')
            setDropdownData([])
            setFilterDropdownData([])
            setFilterSearch("")

            if (filterCol === 'accruals_aggregate') {
                setOperatorValue({ value: "=", label: "equals" });
            }
        }
    }


    const OpenAccrual = (event, id) => {
        debugger;
        if (event.detail === 2) {
            debugger
            navigate("/MassActionGenerateAccrualList/new/" + id);
        }
    }


    const SortColumn = (colName) => {
        var sortType = '';
        if (columnAscending === true) {
            setColumnAscending(false);
            sortType = "Descending";
        } else {
            setColumnAscending(true);
            sortType = "Ascending";
        }
        setSortColName(colName);
        // let pagesize = 20;
        let pagenumber = 1;
        // paging.pageSize = pagesize;
        paging.pageNumber = pagenumber;
        setpaging(paging);
        // getMassActionViewData("MassAction/GetAllGenerate", paging.pageSize, paging.pageNumber, sortType, colName, filterLst(), searchValue);
    }

    const filterLst = () => {
        let list = [];

        for (var key in multiFilterValue) {
            if (multiFilterValue[key].length > 0) {
                let values = ""
                multiFilterValue[key].map((i) => {
                    values += i.Name + ";";
                })
                if (values !== null && values !== '') {
                    list.push({
                        filterKey: key,
                        FilterValue: values
                    })
                }
            }
        }
        return list;
    }

    const LoadMore = (lst, totalcount) => {

        if (lst < totalcount) {
            setshowLoadMore(true);
        }
        else if (lst >= totalcount) {
            setshowLoadMore(false);
        }
        else {
            setshowLoadMore(false);
        }

    }

    let headers = [
        { label: "Mass Action Id", key: "MassActionId" },
        { label: "Description", key: "Description" },
        { label: "Created By", key: "CreatedBy" },
        { label: "Created Date", key: "CreatedDate" },
        { label: "Last Modified By", key: "LastUserName" },
        { label: "Last Modified Date", key: "LastModifiedDate" }
    ];

    const ExporttoCSV = () => {
        let checkedBoxes = document.querySelectorAll('input[name=chkBox]:checked');
        let values = "";
        if (checkedBoxes.length >= 1) {
            for (let checkbox of checkedBoxes) {
                values = values + ';' + checkbox.id;

            }
        }
        let objFilter = [];
        let objFilterValue = [{
            filterKey: "MassActionIdNo",
            FilterValue: values,
        }]
        //if(checkedBoxes.length>=20){
        //}else{
        objFilter = objFilterValue;
        //}

        Service.getdataWithPagingByGroupSearch("MassAction/GetAllGenerate", 20000, 1, "Ascending", "Id", objFilter, null).then((res) => {
            console.log("20/3-resp", res)
            if (typeof res !== 'undefined') {
                if (res.data !== 'null' && res.data !== 'undefined') {
                    setCsvList(res.data.DataList);
                    setTimeout(() => {
                        csvInstance.current.link.click();
                    });

                }
            }
        });
    }

    const showDeleteDialog = (flag) => {
        if (flag === 'open') {
            // alert('inside')
            toggleModal(true)
        }
        if (flag === 'close') {
            // alert('inside')
            toggleModal(false)
            let checkedBoxes = document.querySelectorAll('input[name=chkBox]:checked');
            for (let checkbox of checkedBoxes) {
                checkbox.checked = false;
            }
            setMultiValueIds([])
            setDeleteshowbutton(false)
        }
        if (flag === 'proceed') {
            console.log('8/5', multiValueids)
            handleDelete(multiValueids)
            let checkedBoxes = document.querySelectorAll('input[name=chkBox]:checked');
            for (let checkbox of checkedBoxes) {
                checkbox.checked = false;
            }
            setDeleteshowbutton(false)
        }
    }


    const handleDelete = (id) => {
        let pagesize = paging.pageSize * paging.pageNumber;
        let pagenumber = 1;

    }


    const checkDelete = () => {
        let checkedBoxes = document.querySelectorAll('input[name=chkBox]:checked');
        if (checkedBoxes.length == 1) {
            // alert('only 1')
            if (checkedValueStatus.includes('Approved') || checkedValueStatus.includes('InReview') || checkedValueStatus.includes('Denied')) {
                setErrorDeleteModal(true)
            }
            else {
                console.log('8/5', checkedValue)
                setMultiValueIds(checkedValue)
                showDeleteDialog('open')
            }
        }
        if (checkedBoxes.length > 1) {
            setCheckedMultiValueid([])
            for (let checkbox of checkedBoxes) {
                checkedMultiValueid.push(checkbox.defaultValue)
                console.log('8/5', checkedMultiValueid)
            }
            checkedMultiValueid.map(i => {
                if (i.includes('Approved') || i.includes('InReview') || i.includes('Denied')) {
                    setErrorDeleteModal(true)
                }
                else {
                    var z = i.split(",");
                    multiValueids.push(z[0])
                    showDeleteDialog('open')
                }
            })
        }
    }


    const CreateNew = () => {
        navigate("/MassActionGenerateAccrualList/new/0")
    }


    return (
        <div className="container-fluid content-top-gap">


            <nav aria-label="breadcrumb">
                <ol className="breadcrumb my-breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">{t('Home.title')}</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">{t('MassAction.massActionGenerateList')}</li>
                </ol>
            </nav>

            <div className="">
                <div className="row">
                    <div className="col-lg-12 pr-lg-2">
                        <div className="card text-center card_border overflow-auto ">

                            <div className="card-body" style={{ minHeight: "70vh", width: "100%" }}>
                                <div className="row ml-1" style={{ position: "sticky" }}>

                                    <div className="col-md-8">
                                        <input type="text" className="form-control form-control-user" onChange={(event) => searchHandler(event)} id="firstName" autoComplete='off' name="firstName" placeholder={`${t('SearchFor')} ${t('MassAction.MassActionId')}, ${t('CreatedBy')}, ${t('LastModifiedBy')} `} />
                                    </div>
                                    <div className="col-md-4">

                                        {showbutton === false ?
                                            <>
                                                <button className="btn btn-sm btn-update ml-2" id="create" style={{ width: "4.688rem", backgroundColor: "buttonface", float: "right" }} onClick={() => CreateNew()}>{t('CreateNew')}</button>
                                            </>
                                            :
                                            <></>
                                        }
                                        {showbutton === true ?
                                            <>
                                                <a className="btn btn-update btn-sm ml-2" style={{ backgroundColor: "buttonface", float: "right", width: "40px" }} onClick={() => OpenHandler(checkedValueid)}>{t('Open')}</a>
                                                <button className="btn btn-update  btn-sm ml-2" type="Generate" style={{ float: "right", width: "50px" }} onClick={(event) => checkDelete()}>{t('Delete')}</button>
                                            </>
                                            :
                                            deleteshowbutton === true ?
                                                <>
                                                    <button className="btn btn-update  btn-sm ml-2" style={{ float: "right", width: "50px" }} type="Generate" onClick={(event) => checkDelete()}>{t('Delete')}</button>
                                                </> :
                                                ''
                                        }
                                        {totalselectedcount <= 0 ? "" : Service.exportPerms(userPerms) == false ? "" : <button className="btn btn-update  btn-sm ml-2" type="Generate" style={{ float: "right", width: "50px" }} onClick={(event) => ExporttoCSV()}>{t('Export')}</button>}

                                    </div>

                                </div>
                                <div style={{ display: "none" }}>
                                    <CSVLink data={csvList} headers={headers} ref={csvInstance} filename="MassGenerate.csv" > </CSVLink>
                                </div>
                                <div className='scrollbar-class'>
                                    <table className="table table-bordered text-left mt-2 table-responsive-sm" id="tableA">
                                        <thead className="text-white is-sticky-thead-product" style={{ backgroundColor: "var(--primary)" }}>
                                            <tr style={{ position: "sticky", top: "0", backgroundColor: "var(--primary)" }}>
                                                <th className="tableheader" style={{ width: '30px' }}><input type="checkbox" id="option-all" onChange={checkAll} /> </th>
                                                <th id="MassActionId" style={{ backgroundColor: (filterValueDict.MassActionId ? '#6cae55' : ''), maxWidth: "145px", verticalAlign: "middle" }}>
                                                    <div className='d-flex text-nowrap'>
                                                        <span>{t('MassAction.MassActionId')}</span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('MassActionId')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('MassActionId')}></span>
                                                    </div>
                                                </th>

                                                <th id="Desc" style={{ backgroundColor: (filterValueDict.desc ? '#6cae55' : ''), maxWidth: "150px", verticalAlign: "middle" }}>
                                                    <div className='d-flex text-nowrap'>
                                                        <span>{t('MassAction.Description')} </span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('Desc')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('Desc')}></span>
                                                    </div>
                                                </th>
                                                <th id="StartTime" style={{ backgroundColor: (filterValueDict.desc ? '#6cae55' : ''), maxWidth: "150px", verticalAlign: "middle" }}>
                                                    <div className='d-flex text-nowrap'>
                                                        <span>{t('MassAction.StartTime')} </span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('StartTime')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('StartTime')}></span>
                                                    </div>
                                                </th>
                                                <th id="EndTime" style={{ backgroundColor: (filterValueDict.EndTime ? '#6cae55' : ''), maxWidth: "150px", verticalAlign: "middle" }}>
                                                    <div className='d-flex text-nowrap'>
                                                        <span>{t('MassAction.EndTime')} </span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('EndTime')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('EndTime')}></span>
                                                    </div>
                                                </th>
                                                <th id="Status" style={{ backgroundColor: (filterValueDict.Status ? '#6cae55' : ''), maxWidth: "150px", verticalAlign: "middle" }}>
                                                    <div className='d-flex text-nowrap'>
                                                        <span>{t('MassAction.Status')} </span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('Status')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('Status')}></span>
                                                    </div>
                                                </th>
                                                <th id="CreatedUser" style={{ backgroundColor: (multiFilterValue.CreatedUser ? '#6cae55' : ''), maxWidth: "145px", verticalAlign: "middle" }}>
                                                    <div className='d-flex text-nowrap'>
                                                        <span> {t('CreatedBy')} </span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('CreatedBy')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('CreatedUser')}></span>
                                                    </div>
                                                </th>
                                                <th id="CreatedDate" style={{ backgroundColor: (filterValueDict.CreatedDate ? '#6cae55' : ''), maxWidth: "145px", verticalAlign: "middle" }}>
                                                    <div className='d-flex text-nowrap'>
                                                        <span>{t('CreatedDate')} </span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('CreatedDate')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('CreatedDate')}></span>
                                                    </div>
                                                </th>

                                                <th id="LastModifiedUser" style={{ backgroundColor: (multiFilterValue.LastModifiedUser ? '#6cae55' : ''), maxWidth: "145px", verticalAlign: "middle" }}>
                                                    <div className='d-flex text-nowrap'>
                                                        <span> {t('ModifiedBy')} </span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('LastUserName')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('LastModifiedUser')}></span>
                                                    </div>
                                                </th>
                                                <th id="LastModifiedDate" style={{ backgroundColor: (filterValueDict.LastModifiedDate ? '#6cae55' : ''), maxWidth: "145px", verticalAlign: "middle" }}>
                                                    <div className='d-flex text-nowrap'>
                                                        <span>{t('ModifiedDate')} </span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('LastModifiedDate')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('LastModifiedDate')}></span>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                showspinner === true ?

                                                    <>
                                                        <Spinner animation="grow" size="sm" variant="success" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                        <Spinner animation="grow" size="sm" variant="danger" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                        <Spinner animation="grow" size="sm" variant="warning" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                    </>
                                                    :
                                                    filteredArray.length === 0 ?
                                                        <tr>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td>
                                                                {t('NoDataAvailable')}
                                                            </td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                        :
                                                        filteredArray.map((listVal, index) =>
                                                            <tr key={listVal.MassActionId} onDoubleClick={(event) => OpenAccrual(event, listVal.Id)}>
                                                                <td><input id={listVal.Id} type="checkbox" name="chkBox" value={listVal.Id} onChange={(event) => handleChange(event, listVal.Id, listVal.MassActionId)} /></td>
                                                                <td style={{ maxWidth: "145px" }}>{listVal.MassActionId}</td>
                                                                <td style={{ width: "130px" }}><div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right" title={listVal.Desc}>{listVal.Desc}</div></td>
                                                                <td style={{ maxWidth: "145px" }}>{listVal.StartTime}</td>
                                                                <td style={{ maxWidth: "145px" }}>{listVal.EndTime}</td>
                                                                <td style={{ maxWidth: "145px" }}>{listVal.Status}</td>
                                                                <td style={{ maxWidth: "145px" }}>{listVal.CreatedUser}</td>
                                                                <td>{moment(listVal.CreatedDate).format(SessionManage.getcurrentdateformat())}</td>
                                                                <td style={{ maxWidth: "145px" }}>{listVal.LastModifiedUser}</td>
                                                                <td>{moment(listVal.LastModifiedDate).format(SessionManage.getcurrentdateformat())}</td>
                                                            </tr>
                                                        )
                                            }

                                        </tbody>
                                    </table>
                                </div>
                                {
                                    showLoadMore === true ?
                                        <div className='text-center container row'>
                                            <div className='col-md-5'></div>
                                            <div className='col-md-2'>
                                                <button className='btn btn-sm btn-update ml-1' onClick={handleLoadMore} style={{ width: "65px" }}>{t('LoadMore')}</button>
                                            </div>
                                            <div className='col-md-5'></div>

                                        </div>
                                        :
                                        ""
                                }
                                <div className='row'>

                                    {
                                        totalselectedshow === true ?

                                            <div className='col-lg-2 forgettext col-sm-12 border form-control' style={{ textAlign: "center", marginLeft: "0.938rem" }}>
                                                {t('TotalRecordsSelected')} - <span id="totalselected">{totalselectedcount}</span>
                                            </div>

                                            :
                                            <div className='col-lg-2  col-sm-12' style={{ textAlign: "center" }}>

                                            </div>
                                    }



                                    <div className='col-lg-7 col-sm-12'></div>


                                    <div className='col-lg-2 forgettext  col-sm-12 border form-control' style={{ textAlign: "center" }}>
                                        {t('TotalRecords')} - {totalCountlst}
                                    </div>
                                </div>
                            </div>

                            <Modal show={showDeleteModal}
                                size="sm"
                                aria-labelledby="contained-modal-title-vcenter"
                                centered>
                                <Modal.Body style={{ "textAlign": 'center' }}>Are you sure you want to delete ?</Modal.Body>
                                <Modal.Footer>
                                    <Button style={{ background: '#eff1f9', color: 'black', borderColor: 'black', width: "50px" }} size='sm' onClick={() => showDeleteDialog('close')}>
                                        Cancel
                                    </Button>
                                    <Button style={{ background: '#eff1f9', color: 'black', borderColor: 'black', width: "50px" }} size='sm' onClick={() => showDeleteDialog('proceed')}>
                                        Delete
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                            <Modal show={errorDeleteModal}
                                size="sm"
                                aria-labelledby="contained-modal-title-vcenter"
                                centered>
                                <Modal.Body style={{ "textAlign": 'center', fontFamily: 'Segoe UI' }}>
                                    Only Agreements with Accruals not Generateted can be Deleted
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button style={{ background: '#eff1f9', color: 'black', borderColor: 'black', width: "45px" }} size='sm' onClick={() => setErrorDeleteModal(false)}>
                                        Close
                                    </Button>
                                </Modal.Footer>
                            </Modal>


                            {showfilterpopup === true ?
                                <div className='pop-up' style={{ left: divleft, top: divtop, width: "220px" }}>
                                    {filterCol === 'MassActionId' || filterCol === 'Desc' ?

                                        <>
                                            <div className="container">
                                                <div className="row">
                                                    <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>
                                                </div>
                                            </div>
                                            <div className="container mt-2">
                                                <div className="row">
                                                    <input type='textbox' placeholder='Search...' className='filtertextboxbackground col-md-11 textbox-padding ml-2' value={filterValueDict[filterCol]} name={filterCol} onChange={(event) => filterInput(event)}></input>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <></>
                                    }

                                    {filterCol === 'CreatedDate' || filterCol === 'LastModifiedDate' ?
                                        <>
                                            <div className="container">
                                                <div className="row">
                                                    <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>
                                                </div>
                                            </div>
                                            <div className="container mt-2">
                                                <div className="row">
                                                    <input type='date' placeholder='Search...' className='filtertextboxbackground col-md-11 textbox-padding ml-2' value={filterValueDict[filterCol]} onChange={(event) => filterInput(event)}></input>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <></>
                                    }

                                    {filterCol === 'CreatedUser' || filterCol === 'LastModifiedUser' ?
                                        <>
                                            <div className="container">
                                                <div className="row">
                                                    <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>
                                                </div>

                                            </div>

                                            <div className="container mt-2">
                                                <div className="row">
                                                    <input type="text" placeholder="Search" value={filterSearch} onChange={(event) => UserfilterHandler(event)} className='filtertextboxbackground col-md-11 textbox-padding ml-2' />
                                                </div>

                                                <div className="row contentoverflow mt-2">
                                                    <table className="filtertable">
                                                        <tbody>
                                                            <tr>
                                                                <td><input type="checkbox" name="filter-option-all" id="option-all" onChange={checkAllFilter} /></td>
                                                                <td>Select All</td>
                                                            </tr>
                                                            {
                                                                dropdownData.map((item, index) => (

                                                                    <tr key={index}>

                                                                        <td><input id={item.Id} data-index="1" name="filter-option-all" checked={item.ischecked} type="checkbox" onChange={(event) => OndropdownChange(event, item.Id, item.Name)} /></td>

                                                                        <td className="hovr" style={{ overflow: "hidden", display: "block", maxWidth: "183px" }}>{item.Name}</td>

                                                                    </tr>

                                                                ))
                                                            }
                                                        </tbody>
                                                    </table>

                                                </div>
                                            </div>
                                        </>
                                        :
                                        <></>
                                    }
                                    <div style={{ paddingTop: '10px', paddingLeft: '35px', paddingBottom: '7px' }}>
                                        <button className="btn btn-sm btn-update" style={{ width: "45px" }} onClick={() => filterHandler('Apply')}>{t('Apply')}</button>
                                        <button className="btn btn-sm btn-update" style={{ marginLeft: '10px', width: "45px" }} onClick={() => filterHandler('Reset')}>{t('Reset')}</button>
                                    </div>
                                    <div>
                                    </div>
                                </div>
                                :
                                <></>
                            }

                        </div>
                    </div>

                </div>
            </div>


        </div>
    )
}

export default MassActionGenerateAccrualsList