
import React, { useEffect, useState, useRef, forwardRef } from 'react';
import { CSVLink } from "react-csv";
import { Link } from 'react-router-dom';
import Service from '../../../Services/Service';
import moment from 'moment';
import { useNavigate, useLocation } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import Multiselect from 'multiselect-react-dropdown';
import SessionManage from '../../../SessionManagement/SessionManage';
import CustomErrorPopup from '../../../UI/CustomErrorPopup';
import Select from "react-select";
import { useTranslation } from "react-i18next";

function ClaimReconcilationPostingComponent(props) {
    const csvInstance = useRef();
    const [t, i18n] = useTranslation('translation');
    const [csvList, setCsvList] = useState([]);
    const [sortColName, setSortColName] = useState("");
    const [sticky, setSticky] = useState("");
    const [userPerms, setuserPermission] = useState([]);
    const location = useLocation();
    const [columnAscending, setColumnAscending] = useState(true);
    const navigate = useNavigate();
    const [showbutton, setshowbutton] = useState('');
    const [showspinner, setshowspinner] = useState('');
    const [filterValue, setFilterValue] = useState('');
    const [filterCol, setFilterCol] = useState('');
    const [showfilterpopup, setshowfilterpopup] = useState(false);
    const [dropdownData, setDropdownData] = useState([]);
    let rowData = [];
    const [listViewData, setListViewData] = useState(rowData)
    const [filteredArray, setFilteredArray] = useState(rowData)
    const [state, setState] = useState({ status: '2' });
    const [checkedValueids, setCheckedValueids] = useState([]);
    const [searchValue, setSearchValue] = useState('')
    const [totalselectedshow, settotalselectedshow] = useState('');
    const [totalselectedcount, settotalselectedcount] = useState(0);
    const [totalCountlst, settotalCountlst] = useState('');
    const [filtertext, setfiltertext] = useState('');
    const [paging, setpaging] = useState({
        pageSize: 20,
        pageNumber: 1
    })
    const [showLoadMore, setshowLoadMore] = useState('');
    const [filterValueDict, setFilterValueDict] = useState({

        sd_agreement_id: '',
        sd_claim_id: '',
        distributor: '',
        reconcilation_id: '',
        claim_amount: '',
        status: '',
        reconciled_amount: '',
        PostedDate: '',
    });
    const [multiFilterValue, setMultiFilterValue] = useState({
        customer: '',
        PostedByUserName: '',
        ClaimReconcilations_posted: '',
        autopost_ClaimReconcilations: ''
    });

    let loggedInUser = SessionManage.getuserid();
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [errorStatus, setErrorStatus] = useState("Error");
    const [operatorVal, setOperatorValue] = useState({ value: "=", label: "equals" })
    const [operatorValOption, setOperatorValueOption] = useState([
        { value: "=", label: "equals" },
        { value: "<", label: "less then" },
        { value: ">", label: "greater then" },
        { value: "<=", label: "less or equal" },
        { value: ">=", label: "greater or equal" }
    ])
    const OnChangeOperatorValue = (data) => {
        setOperatorValue({ value: data.value, label: data.label });
    }
    const customStyles = {
        control: base => ({
            ...base,
            minHeight: 28
        }),
        dropdownIndicator: base => ({
            ...base,
            padding: 2
        }),
        clearIndicator: base => ({
            ...base,
            padding: 2
        }),
        multiValue: base => ({
            ...base,

        }),
        valueContainer: base => ({
            ...base,
            padding: '0px 2px'
        }),
        input: base => ({
            ...base,
            margin: 0,
            padding: 0
        })
    };

    const checkAll = async (mycheckbox) => {
        var checkboxes = document.querySelectorAll("input[name = 'chkBox']");
        if (mycheckbox.target.checked == true) {
            let check_lst = checkedValueids
            checkboxes.forEach(function (checkbox) {
                checkbox.checked = true;
                let obj = checkbox.id.split(',')
                if (obj[1] == 'false' && obj[2] == 'false') {
                    setCheckedValueids([...checkedValueids, parseInt(obj[0])])
                    check_lst.push(parseInt(obj[0]))
                }
            });
            if (check_lst.length > 0) {
                setshowbutton(true);
            }
            settotalselectedshow(true);
            settotalselectedcount(checkboxes.length)
        }
        else {
            checkboxes.forEach(function (checkbox) {
                checkbox.checked = false;
            });
            setshowbutton(false);
            settotalselectedshow(false);
            settotalselectedcount(0)
            setCheckedValueids([])
        }

    }
    {
        useEffect(() => {
            getUserPermission(location.pathname);
            setshowspinner(true);
            setColumnAscending(false);
            setSortColName("Id");
            getClaimReconcilationListViewData("ClaimReconcilation/GetAllClaimReconcilationsPosting", paging.pageSize, paging.pageNumber, "Descending", "Id", [], null);
            window.addEventListener("scroll", isSticky);
            return () => {
                window.removeEventListener("scroll", isSticky);
            };
        }, [])
    }
    const isSticky = () => {
        const scrollTop = window.scrollY;
        const stickyClass = scrollTop >= 60 ? "is-sticky-thead" : "";
        setSticky(stickyClass);
        const scrollTopActual = scrollTop >= 60 ? scrollTop - 85 : 0;
        var divtop1 = document.getElementById("tableA");
        setdivtop(divtop1.offsetTop + scrollTopActual)
    };
    const getUserPermission = (pathname) => {
        Service.GetUserPermission(pathname).then((res) => {

            if (res !== undefined) {
             
                if (res.data !== 'null' && res.data !== 'undefined' && res.data !== '') {
                    if (res.data.DataList !== undefined) {
                        setuserPermission(res.data.DataList)
                    }
                }
            }
        });
    }
    const class_thead = `text-white rt-thead  ${sticky}`;
    const ExporttoCSV = () => {
        let checkedBoxes = document.querySelectorAll('input[name=chkBox]:checked');
        let values = "";
        if (checkedBoxes.length >= 1) {
            for (let checkbox of checkedBoxes) {
                values = values + ';' + checkbox.id.split(',')[0];
            }
        }
        let objFilter = [];
        let objFilterValue = [{
            filterKey: "id",
            FilterValue: values,
        }]

        objFilter = objFilterValue;

        Service.getdataWithPagingByGroupSearch("ClaimReconcilation/GetAllClaimReconcilationsPosting", 20000, 1, "Ascending", "Id", objFilter, null).then((res) => {

            if (typeof res !== 'undefined') {
                if (res.data !== 'null' && res.data !== 'undefined') {
                    setCsvList(res.data.DataList);
                    setTimeout(() => {
                        csvInstance.current.link.click();
                    });

                }
            }
        });
    }
    const activeOption = [
        { Name: "Yes", Id: "1" },
        { Name: "No", Id: "0" },

    ]
    const getClaimReconcilationListViewData = (method, pageSize, pageNumber, sortType, SortProperty, filterValue, search) => {
       
        Service.getdataWithPagingByGroupSearch(method, pageSize, pageNumber, null, null, filterValue, search).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                            rowData = res.data.DataList
                            LoadMore(res.data.DataList.length, res.data.Totalcount)
                            settotalCountlst(res.data.Totalcount)
                            setListViewData(rowData)
                            setFilteredArray(rowData)
                            setshowspinner(false);
                        }
                    }
                }
            }

        });
    }

    const searchHandler = (event) => {
        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
        let pagenumber = 1;
        paging.pageNumber = pagenumber;
        setpaging(paging);
        if (event.target.value != '') {
          
            setSearchValue(String(event.target.value).toLowerCase())
           
            getClaimReconcilationListViewData("ClaimReconcilation/GetAllClaimReconcilationsPosting", paging.pageSize, pagenumber, sortType, sortColName, filterLst(), event.target.value);
        }
        else {
            setSearchValue(null);
            getClaimReconcilationListViewData("ClaimReconcilation/GetAllClaimReconcilationsPosting", paging.pageSize, pagenumber, sortType, sortColName, filterLst(), null);
        }
    }
    const filterLst = () => {
        let list = [];
        for (var key in multiFilterValue) {
            if (multiFilterValue[key].length > 0) {
                let values = ""
                multiFilterValue[key].map((i) => {
                    values += i.Name + ";";
                })
                if (values !== null && values !== '') {
                    list.push({
                        filterKey: key,
                        FilterValue: values
                    })
                }
            }
        }

        for (var key in filterValueDict) {
            if (filterValueDict[key] !== null && filterValueDict[key] !== '') {
                list.push({
                    filterKey: key,
                    FilterValue: filterValueDict[key]
                })
            }
        }
        return list;
    }

    const handleLoadMore = () => {

        let pagenumber = paging.pageNumber + 1;
        var sortType = columnAscending === true ? 'Ascending' : 'Descending';

        Service.getdataWithPagingByGroupSearch("ClaimReconcilation/GetAllClaimReconcilationsPosting", paging.pageSize, pagenumber, sortType, sortColName, filterLst(), searchValue).then((res) => {
            if (res && res.status === 200) {
                if (res.data !== 'null' && res.data !== 'undefined') {
                    rowData = res.data.DataList;
                   
                    if (res.data.DataList !== 'null' && res.data.DataList !== 'undefined') {
                        const currentlength = listViewData.length;
                        setListViewData(listViewData => [...listViewData, ...res.data.DataList])
                        setFilteredArray(filteredArray => [...filteredArray, ...res.data.DataList])
                        LoadMore(currentlength + rowData.length, res.data.Totalcount)
                    }

                }
            }
        });

        paging.pageNumber = pagenumber
        setpaging(paging);
    };


    const handleChange = (e, listVal) => {
      
        let isChecked = e.target.checked;   // true if it is checked false if unchecked
        let check_lst = checkedValueids
        if (isChecked === true && listVal.AutoPostReconciliation === false && listVal.ReconciliationPosted === false) {
            setCheckedValueids([...checkedValueids, listVal.Id])
            check_lst.push(listVal.Id)
        }
        else {
            setCheckedValueids(checkedValueids.filter((e) => e !== listVal.Id))
            check_lst = check_lst.filter((e) => e !== listVal.Id)
        }
        if (check_lst.length > 0) {
            setshowbutton(true);
        }
        else {
            setshowbutton(false);
        }

        let checkedBoxess = document.querySelectorAll('input[name=chkBox]:checked');
        if (checkedBoxess.length > 0) {
            settotalselectedshow(true);
            settotalselectedcount(checkedBoxess.length);
        } else {
            settotalselectedcount(0);
            settotalselectedshow(false);
        }
    }
    const PostHandler = (e) => {
        Service.PostWithIdAndUser("ClaimReconcilation/PostClaimReconcilations", checkedValueids, loggedInUser).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        alert(res.data.Status)
                        if (res.data.Status === 'Failure') {
                            setErrorStatus("Error")
                            setErrorMessage(res.data.ErrorMessage)
                            setShowErrorModal(true)
                        }
                        window.location.reload();

                    }
                }
            }

        });
    }

    const filterInput = (e) => {
        let inputValue = e.target.value
        setFilterValue(inputValue)
        setFilterValueDict(() => ({
            ...filterValueDict, [filterCol]: e.target.value,
        }));
    }


    const OndropdownChange = (e) => {
        let selectedOption = (e);
        setFilterValue([])
        let data = []
        for (let i = 0; i < selectedOption.length; i++) {
            const getallData = {
                Name: selectedOption[i].Name,
            }
            data.push(getallData)
        }
        setFilterValue(data);
        setMultiFilterValue(() => ({
            ...multiFilterValue, [filterCol]: data,
        }));
    }

    const [divleft, setdivleft] = useState('');
    const [divtop, setdivtop] = useState('');

    const FilterColumn = (name) => {

        const scrollTop = window.scrollY;
        const scrollTopActual = scrollTop >= 60 ? scrollTop - 85 : 0;
        var divtop1 = document.getElementById("tableA");
        setdivtop(divtop1.offsetTop + scrollTopActual + 30)

        setFilterCol(name)
        setshowfilterpopup(true)
        if (name === 'sd_agreement_id') {
            var testDiv = document.getElementById("sd_agreement_id");
            setdivleft((testDiv.offsetLeft + 30) + "px");
            setfiltertext(t('ClaimReconcilation.AgreementId'))
           
        }
        if (name === 'distributor') {
            var testDiv = document.getElementById("distributor");
            setdivleft((testDiv.offsetLeft + 30) + "px");
            setfiltertext(t('ClaimReconcilation.Distributor'))
            
        }
        if (name === 'sd_claim_id') {
            var testDiv = document.getElementById("sd_claim_id");
            setdivleft((testDiv.offsetLeft + 30) + "px");
            setfiltertext(t('ClaimReconcilation.ClaimId'))
        }
        if (name === 'reconcilation_id') {
            var testDiv = document.getElementById("reconcilation_id");
            setdivleft((testDiv.offsetLeft + 30) + "px");
            setfiltertext(t('ClaimReconcilation.ReconciliationId'))
        }
        if (name === 'claim_amount') {
            var testDiv = document.getElementById("claim_amount");
            setdivleft((testDiv.offsetLeft + 30) + "px");
            setfiltertext(t('ClaimReconcilation.ClaimAmount'))
        }
        if (name === 'reconciled_amount') {
            var testDiv = document.getElementById("reconciled_amount");
            setdivleft((testDiv.offsetLeft + 30) + "px");
            setfiltertext(t('ClaimReconcilation.ReconciledAmount'))
        }
        if (name === 'autopost_ClaimReconcilations') {
            var testDiv = document.getElementById("autopost_ClaimReconcilations");
            setdivleft((testDiv.offsetLeft + 30) + "px");
            setfiltertext(t('ReconcilationPosting.AutoPostClaimReconcilation'))
        }
        if (name === 'ClaimReconcilations_posted') {
            var testDiv = document.getElementById("ClaimReconcilations_posted");
            setdivleft((testDiv.offsetLeft + 30) + "px");
            setfiltertext(t('ReconcilationPosting.ClaimReconcilationPosted'))
        }
        if (name === 'PostedByUserName') {
            var testDiv = document.getElementById("PostedByUserName");
            setdivleft((testDiv.offsetLeft + 30) + "px");
            setfiltertext(t('ReconcilationPosting.PostedBy'))
        }
        if (name === 'PostedDate') {
            var testDiv = document.getElementById("PostedDate");
            setdivleft((testDiv.offsetLeft + 30) + "px");
            setfiltertext(t('ReconcilationPosting.PostedOn'))
        }
        setshowfilterpopup(true)
    }


    const DropdownHandler = (key) => {
        Service.getfilterDropdownByGroup('ClaimReconcilation/GetClaimReconcilationsFilterValuelst', key).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                            setDropdownData(res.data)
                        }
                    }
                }
            }

        });
    }

    const filterHandler = (flag) => {

        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
        let pagenumber = 1;
        paging.pageNumber = pagenumber;

        if (flag === 'Apply') {
            setshowfilterpopup(false)
            let list = [];

            for (var key in multiFilterValue) {
                if (multiFilterValue[key].length > 0) {
                    let values = ""
                    multiFilterValue[key].map((i) => {
                        values += i.Name + ";";
                    })
                    if (values !== null && values !== '') {
                        list.push({
                            filterKey: key,
                            FilterValue: values
                        })
                    }
                }
            }

            for (var key in filterValueDict) {
                if (filterValueDict[key] !== null && filterValueDict[key] !== '') {
                    if (key === 'ClaimReconcilations_aggregate') {
                        list.push({
                            filterKey: key,
                            FilterValue: filterValueDict[key],
                            operatorValue: operatorVal.value
                        })
                    }
                    else {
                        list.push({
                            filterKey: key,
                            FilterValue: filterValueDict[key]
                        })
                    }
                }
            }
            if (list.length > 0) {
                setpaging(paging);
                getClaimReconcilationListViewData("ClaimReconcilation/GetAllClaimReconcilationsPosting", paging.pageSize, pagenumber, sortType, sortColName, list, searchValue);
            }
          
        }
        else {
            setshowfilterpopup(false)
            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: '',
            }));
            setFilterValueDict(() => ({
                ...filterValueDict, [filterCol]: '',
            }));

            let list = [];
            for (var key in multiFilterValue) {
                if (key !== filterCol && multiFilterValue[key].length > 0) {
                    let values = ""
                    multiFilterValue[key].map((i) => {
                        values += i.Name + ";";
                    })
                    if (values !== null && values !== '') {
                        list.push({
                            filterKey: key,
                            FilterValue: values
                        })
                    }
                }
            }

            for (var key in filterValueDict) {
                if (filterValueDict[key] !== null && filterValueDict[key] !== '' && key !== filterCol) {
                    list.push({
                        filterKey: key,
                        FilterValue: filterValueDict[key]
                    })
                }
            }

            setpaging(paging);
            getClaimReconcilationListViewData("ClaimReconcilation/GetAllClaimReconcilationsPosting", paging.pageSize, pagenumber, sortType, sortColName, list, searchValue);

          
            setFilterCol('')
            setFilterValue('')
            setDropdownData([])
        }
    }

   
    const LoadMore = (lst, totalcount) => {
        if (lst < totalcount) {
            setshowLoadMore(true);
        }
        else if (lst >= totalcount) {
            setshowLoadMore(false);
        }
        else {
            setshowLoadMore(false);
        }
    }
    const SortColumn = (colName) => {
        var sortType = '';
        if (columnAscending === true) {
            setColumnAscending(false);
            sortType = "Descending";
        } else {
            setColumnAscending(true);
            sortType = "Ascending";
        }
        setSortColName(colName);
        
        let pagenumber = 1;
     
        paging.pageNumber = pagenumber;
        setpaging(paging);
      
    }


    const customMultiStyles = {
        multiselectContainer: {
            maxWidth: '170px',
            marginLeft: '3px'
        },
        chips: {
            borderRadius: '5px'
        }
    };
    let headers = [
        { label: "Agreement ID", key: "SDAgreementId" },
        { label: "Distributor", key: "DistributorName" },
        { label: "Claim ID", key: "SDClaimId" },
        { label: "Claim Reconcilation ID", key: "ConcilationId" },
        { label: "Currency", key: "Currency" },
        { label: "ClaimReconcilation Amount", key: "reconciled_amount" },
        { label: "Auto Post ClaimReconcilation", key: "AutoPostReconciliation" },
        { label: "ClaimReconcilation Posted", key: "ReconciliationPosted" },
        { label: "Posted By", key: "ReconciliationPostedby" },
        { label: "Posted On", key: "ReconciliationPostedOn" },

    ];
    return (
        <div className="container-fluid content-top-gap">

            <nav aria-label="breadcrumb">
                <ol className="breadcrumb my-breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">{t('Home.title')}</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">{t('ReconcilationPosting.ReconcilationPosting')}</li>
                </ol>
            </nav>

            <div style={{ display: "none" }}>
                <CSVLink data={csvList} headers={headers} ref={csvInstance} filename="ClaimReconcilationsposting.csv" > </CSVLink>
            </div>

            <div className="">
                <div className="row">
                    <div className="col-lg-12 pr-lg-2">
                        <div className="card text-center card_border">

                            <div className="card-body" style={{ minHeight: "70vh" }}>
                                <div className="row">

                                    <div className="col-md-7">
                                        <input type="text" className="form-control form-control-user" onChange={(event) => searchHandler(event)} id="firstName" autoComplete='off' name="firstName" placeholder={`${t('SearchFor')} ${t('ClaimReconcilation.AgreementId')}, ${t('ClaimReconcilation.Distributor')}, ${t('ClaimReconcilation.ClaimId')}, ${t('ClaimReconcilation.ReconcilationId')}, ${t('LastModifiedBy')} `} />
                                    </div>
                                    <div className="col-md-5">

                                        {totalselectedcount <= 0 ? "" : Service.exportPerms(userPerms) == false ? "" : <button className="btn btn-update  btn-sm ml-3" type="submit" style={{ float: "right", width: "50px" }} onClick={(event) => ExporttoCSV()}>Export</button>}


                                        {showbutton === true ?
                                            <>
                                                {Service.editPerms(userPerms) == false ? "" : <button className="btn btn-update btn-sm ml-2" style={{ width: '95px', float: "right" }} onClick={PostHandler}>Post ClaimReconcilations</button>}
                                            </>
                                            : ''
                                        }

                                    </div>

                                </div>
                                <div className="scrollbar-class">
                                    <table className="table table-bordered text-left mt-2 table-responsive-sm" id="tableA">
                                        <thead className="text-white is-sticky-thead-product" style={{ backgroundColor: "var(--primary)" }}>
                                            <tr style={{ position: "sticky", top: "0", backgroundColor: "var(--primary)" }}>
                                                <th className="tableheader">
                                                    <input type="checkbox" id="option-all" onChange={checkAll} />
                                                </th>
                                                <th id="sd_agreement_id" style={{ backgroundColor: (filterValueDict.sd_agreement_id ? '#6cae55' : ''), maxWidth: "145px" }}>
                                                    {t('ClaimReconcilation.AgreementId')}
                                                    <span className='fa fa-filter' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('sd_agreement_id')}></span>
                                                </th>
                                                <th id="distributor" style={{ backgroundColor: (multiFilterValue.distributor ? '#6cae55' : ''), maxWidth: "145px" }}>
                                                    {t('ClaimReconcilation.Distributor')}
                                                    <span className='fa fa-filter' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('distributor')}></span>
                                                </th>
                                                <th id="sd_claim_id" style={{ backgroundColor: (filterValueDict.sd_claim_id ? '#6cae55' : ''), maxWidth: "145px" }}>
                                                    {t('ClaimReconcilation.ClaimId')}
                                                    <span className='fa fa-filter' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('sd_claim_id')}></span>
                                                </th>
                                                <th id="reconcilation_id" style={{ backgroundColor: (filterValueDict.reconcilation_id ? '#6cae55' : ''), maxWidth: "145px" }}>
                                                    {t('ClaimReconcilation.ReconcilationId')}
                                                    <span className='fa fa-filter' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('reconcilation_id')}></span>
                                                </th>
                                                <th id="reconciled_amount" style={{ backgroundColor: (filterValueDict.reconciled_amount ? '#6cae55' : ''), maxWidth: "145px" }}>
                                                    {t('ClaimReconcilation.ReconciledAmount')}
                                                    <span className='fa fa-filter' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('reconciled_amount')}></span>
                                                </th>
                                                <th id="autopost_ClaimReconcilations" style={{ backgroundColor: (multiFilterValue.autopost_ClaimReconcilations ? '#6cae55' : ''), maxWidth: "130px" }} >
                                                    {t('ReconcilationPosting.AutoPostClaimReconcilation')}<span className='fa fa-filter' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('autopost_ClaimReconcilations')}></span>

                                                </th>
                                                <th id="ClaimReconcilations_posted" style={{ backgroundColor: (multiFilterValue.ClaimReconcilations_posted ? '#6cae55' : ''), maxWidth: "110px" }} >
                                                    {t('ReconcilationPosting.ClaimReconcilationPosted')}<span className='fa fa-filter' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('ClaimReconcilations_posted')}></span>

                                                </th>
                                                <th id='PostedByUserName' style={{ backgroundColor: (multiFilterValue.PostedByUserName ? '#6cae55' : ''), maxWidth: "110px" }}>
                                                    {t('ReconcilationPosting.PostedBy')}
                                                    <span className='fa fa-filter' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('PostedByUserName')}></span>
                                                </th>
                                                <th id='PostedDate' style={{ backgroundColor: (filterValueDict.PostedDate ? '#6cae55' : ''), maxWidth: "110px" }}>
                                                    {t('ReconcilationPosting.PostedOn')}
                                                    <span className='fa fa-filter' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('PostedDate')}></span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                showspinner === true ?

                                                    <>
                                                        <Spinner animation="grow" size="sm" variant="success" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                        <Spinner animation="grow" size="sm" variant="danger" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                        <Spinner animation="grow" size="sm" variant="warning" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                    </>
                                                    :
                                                    filteredArray.length === 0 ?
                                                        <tr>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td>
                                                                No Data Available
                                                            </td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                        :
                                                        filteredArray.map((listVal, index) =>
                                                            <tr key={listVal.Rebate_id}>
                                                              
                                                                <td><input id={[listVal.Id, listVal.AutoPostClaimReconcilations, listVal.ClaimReconcilationsPosted]} type="checkbox" name="chkBox" value={listVal.Rebate_id} onChange={(event) => handleChange(event, listVal)}
                                                              
                                                                /></td>
                                                                <td style={{ maxWidth: "140px" }}>{listVal.SDAgreementId}</td>
                                                                <td style={{ maxWidth: "130px" }}>{listVal.DistributorName}</td>
                                                                <td style={{ maxWidth: "130px" }}>{listVal.SDClaimId}</td>
                                                                <td style={{ maxWidth: "110px" }}>{listVal.ConcilationId}</td>
                                                                <td style={{ maxWidth: "110px" }}>{listVal.Currency} {parseFloat(listVal.reconciled_amount).toFixed(SessionManage.getroundingPrecision())}</td>
                                                                <td style={{ maxWidth: "130px" }}><input type="checkbox" checked={listVal.AutoPostReconciliation} disabled /></td>
                                                                <td style={{ maxWidth: "110px" }}><input type="checkbox" checked={listVal.ReconciliationPosted} disabled /></td>
                                                                <td style={{ maxWidth: "110px" }}>{listVal.ReconciliationPostedby}</td>
                                                                {listVal.ReconciliationPostedOn === null || listVal.ReconciliationPostedOn === '' ?
                                                                    <td></td>
                                                                    :
                                                                    <td>{moment(listVal.ReconciliationPostedOn).format(SessionManage.getcurrentdateformat())}</td>
                                                                }
                                                            </tr>
                                                        )

                                            }

                                        </tbody>
                                    </table>
                                </div>
                                {
                                    showLoadMore === true ?
                                        <div className='text-center container row'>
                                            <div className='col-md-5'></div>
                                            <div className='col-md-2'>
                                                <button className='btn btn-sm btn-update ml-1' onClick={handleLoadMore} style={{ width: "70px" }}>{t('LoadMore')}</button>
                                            </div>
                                            <div className='col-md-5'></div>

                                        </div>
                                        :
                                        ""
                                }
                                <div className='row'>
                                    {
                                        totalselectedshow === true ?

                                            <div className='col-lg-2 forgettext col-sm-12 border form-control' style={{ textAlign: "center", marginLeft: "0.938rem" }}>
                                                {t('TotalRecordsSelected')} - <span id="totalselected">{totalselectedcount}</span>
                                            </div>

                                            :
                                            <div className='col-lg-2  col-sm-12' style={{ textAlign: "center" }}>

                                            </div>
                                    }



                                    <div className='col-lg-7 col-sm-12'></div>


                                    <div className='col-lg-2 forgettext  col-sm-12 border form-control' style={{ textAlign: "center" }}>
                                        {t('TotalRecords')} - {totalCountlst}
                                    </div>
                                </div>
                            </div>

                            {showfilterpopup === true ?
                                <div className='pop-up' style={{ left: divleft, top: divtop, width: "200px" }}>
                                    {filterCol === 'sd_agreement_id' || filterCol === 'distributor' || filterCol === 'sd_claim_id' || filterCol === 'autopost_ClaimReconcilations' || filterCol === 'reconcilation_id' || filterCol === 'ClaimReconcilations_posted' ?

                                        <>
                                            <div className="container">
                                                <div className="row">
                                                    <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>
                                                </div>
                                            </div>
                                            <div className="container mt-2">
                                                <div className="row">
                                                    <input type='textbox' placeholder={t('Search')} className='filtertextboxbackground col-md-11 textbox-padding ml-2' style={{ outline: 'none' }} value={filterValueDict[filterCol]} onChange={(event) => filterInput(event)}></input>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <></>

                                    }

                                    {filterCol === 'PostedDate' ?
                                        <>
                                            <div className="container">
                                                <div className="row">
                                                    <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>
                                                </div>
                                            </div>
                                            <div className="container mt-2">
                                                <div className="row">
                                                    <span className='fa fa-filter' /><input type='date' className='col-md-10 ml-2' value={filterValueDict[filterCol]} onChange={(event) => filterInput(event)}></input>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <></>
                                    }

                                    {filterCol === 'PostedByUserName' ?
                                        <>
                                            <div className="container">
                                                <div className="row">
                                                    <b className="forgettext col-md-11 textbox-padding ml-1">{filtertext}</b>
                                                </div>
                                            </div>
                                            <div className="container mt-2">
                                                <div className="row">
                                                    <span className='col-md-0 fa fa-filter' />
                                                    <Multiselect
                                                        onSelect={(Event) => OndropdownChange(Event)}
                                                        onRemove={(Event) => OndropdownChange(Event)}
                                                        options={dropdownData}
                                                        displayValue="Name"
                                                       
                                                        showCheckbox={true}
                                                        selectedValues={multiFilterValue[filterCol]}
                                                        className='col-md-9 textbox-padding fontsizedropdown'
                                                        style={customMultiStyles}
                                                    />
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <></>
                                    }

                                    {filterCol === 'reconciled_amount' ?
                                        <>
                                            <div className="container">
                                                <div className="row">
                                                    <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>
                                                </div>
                                            </div>
                                            <div className="container mt-2">
                                                <div className="row">
                                                    <span className='col-md-1 fa fa-filter' />
                                                    <Select
                                                        className='col-md-9 textbox-padding fontsizedropdown'
                                                        options={operatorValOption}
                                                        placeholder="Select"
                                                        value={operatorVal}
                                                        onChange={OnChangeOperatorValue}
                                                        styles={customStyles}
                                                    />
                                                </div> <div className="mt-2">
                                                    <input type='text' className='filtertextboxbackground col-md-11 textbox-padding ml-2' onKeyPress={(e) => !/^(\d)*(\.)?([0-9]{1})?$/.test(e.key) && e.preventDefault()}
                                                        value={filterValueDict[filterCol]} onChange={(event) => filterInput(event)} />
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <></>
                                    }
                                    <div style={{ paddingTop: '10px', paddingLeft: '35px', paddingBottom: '7px' }}>
                                        <button className="btn btn-sm btn-update" style={{ width: "45px" }} onClick={() => filterHandler('Apply')}>{t('Apply')}</button>
                                        <button className="btn btn-sm btn-update" style={{ marginLeft: '10px', width: "45px" }} onClick={() => filterHandler('Reset')}>{t('Reset')}</button>
                                    </div>
                                </div>
                                :
                                <></>
                            }

                        </div>
                        <CustomErrorPopup ErrorMessage={errorMessage} show={showErrorModal} ErrorStatus={errorStatus}
                            onHide={() => setShowErrorModal(false)}></CustomErrorPopup>
                    </div>

                </div>
            </div>

        </div>


    )
}
export default ClaimReconcilationPostingComponent;

