import React, { Component } from 'react' 
import { useNavigate ,useParams } from 'react-router-dom';
import SettlementFreqPoliciesList from '../../../Components/PoliciesComponent/SettlementFreqPolicyComponent/SettlementFreqPoliciesList';

function SettlementFreqPolicy(props){
    let navigate = useNavigate();
    const params = useParams();
     
     return (  
         <div> 
             <SettlementFreqPoliciesList {...props}  navigate={navigate} id={params.id} />
         </div>
     );  

}

export default SettlementFreqPolicy