import React, { Component } from 'react'

function Footer() {
    return (<>
        <div>
            <footer className="main-footer">
                <strong>Copyright © 2022 <a href="#">Qualitech Group</a> .</strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 3.0.0
                </div>
            </footer>
        </div>
    </>);
}
export default Footer