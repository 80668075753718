import { Link, useLocation } from "react-router-dom"
import React, { useState, useEffect } from 'react'
import classes from '../../RebateProgramComponent/CreateRebateComponent/Form.module.css'
import { Form, FormGroup, Col, Row, Button, InputGroup, Collapse, Modal } from 'react-bootstrap';
import Service from '../../../Services/Service';
import 'react-multiple-select-dropdown-lite/dist/index.css'
import Multiselect from 'multiselect-react-dropdown';
import CustomErrorPopup from "../../../UI/CustomErrorPopup";
import usercss from "../UserManagement/users.css";
import $ from 'jquery';
import SessionManage from "../../../SessionManagement/SessionManage";

import { useTranslation } from "react-i18next";
function EditUserComponent(props) {

    const [t, i18n] = useTranslation('translation');


    const location = useLocation();
    const [userPerms, setuserPermission] = useState([]);
    const [Groups, setGroup] = useState([]);
    const [editPerms, setEditPerms] = useState([]);


    const [selected, setselected] = useState([]);
    const [useravailable, setuseravailable] = useState('');

    const [Roles, setRole] = useState([]);
    const [selectedRoles, setSelectedRoles] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState([]);

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [errorStatus, setErrorStatus] = useState("Error");
    const [isValid, setIsValid] = useState(true);
    const [closeModal, setCloseModal] = useState(false);
    const [handleChangeValue, setHandleChangeValue] = useState(false);
    let loggedInUser = sessionStorage.getItem('id');
    const [isLocked, setIsLocked] = useState(false);
    const [lockedBy, setLockedBy] = useState('');
    let disable = false;
    useEffect(() => {
        getUserPermission(location.pathname);
        getUserDatabyId("Users/GetById/", props.id);
        getData("Users/GetRoleAndGroup");

    }, [0])

    const [inputField, setInputField] = useState({
        Id: 0,
        FirstName: '',
        LastName: '',
        Username: '',
        Email: '',
        Password: '',
        ConfirmPassword: '',
        lstRole: [],
        lstGroup: [],
        Active: false,
        IsSuperuser: false,
        IsActive: false,
        LastModifiedUser: '',
        CreatedUser: '',
        dateformat: '',
        Locale: '',
        Languages: '',
    })
    const getUserPermission = (pathname) => {
        Service.GetUserPermission(pathname).then((res) => {
       
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null' && res.data !== '') {
                    if (typeof res.data.DataList !== 'undefined') {
                        var permission = res.data.DataList;
                        if (typeof permission !== 'undefined') {
                            if (permission.filter((item) => item.ActionName === 'Edit' || item.ActionName === 'Submit').length == 0) {
                                setEditPerms(false);
                            } else {
                                setEditPerms(true);
                            }
                        }
                        else {
                            setEditPerms(false);
                        }
                    }
                }
            }
        });
    }
    const getData = (method) => {
        Service.getdata(method).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && typeof res.data.DataList !== 'undefined') {

                    if (res.data.DataList.lstRoles !== 'undefined')
                        setRole(res.data.DataList.lstRoles);
                    if (res.data.DataList.lstGroups !== 'undefined')
                        setGroup(res.data.DataList.lstGroups);

                }
            }
        });
    }


    const getUserDatabyId = (method, id) => {
        Service.LockUnLockEntity('Configurationprop/LockEntity', "User", props.id, loggedInUser).then((res1) => {
            if (typeof res1 !== 'undefined') {
                Service.getdatabyId(method, id).then((res) => {
                    if (typeof res !== 'undefined') {
                        if (typeof res.data !== 'undefined' && res.data !== 'null') {
                            setInputField(res.data);

                            setSelectedRoles(res.data.lstRole);
                            setSelectedGroups(res.data.lstGroup);
                            setLockedBy(res.data.LockedBy);
                            setIsLocked(res.data.Locked);
                            if ((res.data.Locked === true && res.data.LockedBy.toString() !== loggedInUser)) {
                                $("#libread").html(t('LockedBy') + ' ' + res.data.LockedByUserName);
                            }
                        }
                    }
                });
            }

        });
    }



    const emailinputhandler = (e) => {
        setHandleChangeValue(true)
        const { name, value } = e.target;
        setInputField((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        if (value !== null) {
            Service.getdatabyId("Users/CheckUserExist", value).then((res) => {
                if (typeof res !== 'undefined') {
                    if (typeof res.data !== 'undefined' && res.data !== 'null') {
                        if (res.status === 200) {
                            setuseravailable(res.data);

                        }
                    }
                }


            });
        }

    }

    const checkValidation = () => {
        var has_errors = false;
        if (inputField.FirstName == null || inputField.FirstName == '') {


            setErrorMessage(t("Validation-Createuser.FirstName"))
            has_errors = true;
            setShowErrorModal(true)
        }
        else if (inputField.LastName == '' || inputField.LastName == null) {
            setErrorMessage(t("Validation-Createuser.LastName"))
            has_errors = true;
            setShowErrorModal(true)
        }
        else if (inputField.Email == '' || inputField.Email == null) {
            setErrorMessage(t("Validation-Createuser.Email"))
            has_errors = true;
            setShowErrorModal(true)
        }
        else if (inputField.Username == '' || inputField.Username == null) {
            setErrorMessage(t("Validation-Createuser.Username"))
            has_errors = true;
            setShowErrorModal(true)
        }
        else if (inputField.lstRole == '' || inputField.lstRole == null) {
            setErrorMessage(t("Validation-Createuser.Role "))
            has_errors = true;
            setShowErrorModal(true)
        }
        else if (inputField.lstGroup == '' || inputField.lstGroup == null) {
            setErrorMessage(t("Validation-Createuser.Group"))
            has_errors = true;
            setShowErrorModal(true)
        }
        else if (inputField.Password == '' || inputField.Password == null) {
            setErrorMessage(t("Validation-Createuser.Password"))
            has_errors = true;
            setShowErrorModal(true)
        }
        else if (inputField.ConfirmPassword != inputField.Password) {
            setErrorMessage('Your Password and Confirmation Password Do Not Match')
            setIsValid(false)
            setShowErrorModal(true)
            has_errors = true;
        }

        return has_errors;

    }

    const inputsHandler = (e) => {
      
        setHandleChangeValue(true)
        const { name, value } = e.target;
        setInputField((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    }

    const closeHandler = (flag) => {
        if (flag == "Open" && handleChangeValue == true) {
            setCloseModal(true)
        }
        if (flag == "Open" && handleChangeValue == false) {
            Service.LockUnLockEntity('Configurationprop/UnLockEntity', "User", props.id, loggedInUser).then((res) => {
            });
            props.navigate('/Users')
        }
        if (flag == "Close") {
            Service.LockUnLockEntity('Configurationprop/UnLockEntity', "User", props.id, loggedInUser).then((res) => {
            });
            props.navigate('/Users')
        }
        if (flag == "Cancel") {
            setCloseModal(false)
        }

    }



    let selectedData = [];
    const submitButton = (e) => {
        e.preventDefault();
        let id = SessionManage.getuserid();
        inputField.CreatedUser = id;
        inputField.LastModifiedUser = id;
        let Users = inputField;
        let name = sessionStorage.getItem('name');


        var has_errors = checkValidation();

        if (has_errors === false) {
            Service.createorupdate("Users/CreateOrUpdate", Users).then(res => {
                if (typeof res !== 'undefined') {
                    if (typeof res.data !== 'undefined' && res.data !== 'null') {
                        if (id === props.id) {
                            sessionStorage.setItem('name', Users.FirstName + ' ' + Users.LastName);
                            SessionManage.setdateformat(Users.dateformat);
                        }
                        Service.LockUnLockEntity('Configurationprop/UnLockEntity', "User", props.id, loggedInUser).then((res) => {
                        });
                        window.location.href = "/Users";
                    }
                }
            });
        }
    }




    const handleChange = (selectedList, selectedItem) => {
        setHandleChangeValue(true)
        const selected = [];
        const selectedId = [];
        inputField.lstRole = [];
        let selectedOption = (selectedList);

        for (let i = 0; i < selectedOption.length; i++) {
            selected.push(selectedOption[i].Name);
            selectedId.push(selectedOption[i].Id);
            const getallData = {
                id: selectedOption[i].Id,

            }
            inputField.lstRole.push(getallData);
        }


    }
    const handleChangeGroup = (selectedList, selectedItem) => {
        setHandleChangeValue(true)
        const selected = [];
        const selectedId = [];
        inputField.lstGroup = [];
        let selectedOption = (selectedList);

        for (let i = 0; i < selectedOption.length; i++) {
            selected.push(selectedOption[i].Name);
            selectedId.push(selectedOption[i].Id);
            const getallData = {
                id: selectedOption[i].Id,

            }
            inputField.lstGroup.push(getallData);
        }


    }
    if (isLocked === false || (isLocked === true && lockedBy.toString() === loggedInUser)) {
        disable = false;
    }
    else {
        disable = true;
    }
    window.onbeforeunload = function () {
        unlockEntity();
       
    };


    const regionStyles = {
        chips: {
            fontSize: "12px",
            lineHeight: "13px"
        },

        multiSelectContainer: {
            "&:hover": { background: "red" }
        }

    }

    const unlockEntity = () => {
        Service.LockUnLockEntity('Configurationprop/UnLockEntity', "User", props.id, loggedInUser).then((res) => {
        });
    }
    const class_textbox = `col-md-2 textbox-padding  ${editPerms == false ? "disabledClass" : ""}`;
    const class_multiselect = ` textbox-padding  ${editPerms == false ? "disabledClass" : ""}`;

    return (<>
        <div className="container-fluid content-top-gap">

            <nav aria-label="breadcrumb">
                <ol className="breadcrumb my-breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">
                        {t('Home.title')}
                    </Link></li>
                    <li className="breadcrumb-item active" aria-current="page"><Link to="/Users">
                        {t('UserList.title')}

                    </Link></li>
                    <li className="breadcrumb-item active" aria-current="page">
                        {t('Createuser.titleEdit')} &nbsp;
                        <span id="libread"></span>
                    </li>

                </ol>
            </nav>

            <div className="row mt-3" id="rebateProgramdiv">
                <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                        <div className="card-header chart-grid__header">
                            <div className="row">
                                <div className="col-md-7 text-left">


                                    {t('Createuser.title')}
                                </div>

                                <div className="col-md-5">
                                    {editPerms == false ? "" : (isLocked === true && lockedBy.toString() == loggedInUser) ? <button className="btn btn-sm btn-update ml-2" style={{ float: "right", width: "50px" }} onClick={submitButton} type="submit">{t('Submit')}</button> : ''}
                                    <button className="btn btn-sm btn-update ml-2" id="close" style={{ width: "45px", backgroundColor: "buttonface", float: "right" }} onClick={(event) => closeHandler("Open")}>{t('Close')}    </button>
                                </div>
                            </div>
                        </div>
                        <div className="card-body" style={{ minHeight: "60vh" }}>
                            <div className="container">
                                <div className="row">
                                    <label className="col-md-2 text-left">
                                        {t('FirstName')}
                                        <span className={classes.mandatory}>*</span>
                                    </label>
                                    <input id="aid" disabled={disable} onChange={inputsHandler} value={inputField.FirstName} autoComplete='no' name="FirstName" type="text" className={class_textbox} />
                                    <div className="col-md-2"></div>
                                    <label className="col-md-2 text-left">{t('LastName')} </label>
                                    <input id="aid" disabled={disable} onChange={inputsHandler} value={inputField.LastName} autoComplete='no' name="LastName" type="text" className={class_textbox} />

                                </div>
                                <div className="row">
                                    <label className="col-md-2 mt-2 text-left">{t('Email')}<span className={classes.mandatory}>*</span> </label>
                                    <input id="aid" disabled={true} onChange={emailinputhandler} value={inputField.Email} style={{ marginTop: "10px" }} autoComplete='no' name="Email" type="text" className={class_textbox} />
                                    <div className="col-md-6 mt-2 text-left">
                                        <span className='errormessage'>{useravailable}</span>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-md-2 mt-2 text-left">{t('UserName')}<span className={classes.mandatory}>*</span> </label>
                                    <input id="aid" onChange={inputsHandler} value={inputField.Username} autoComplete='no' name="Username" type="text" className="col-md-2 mt-2 textbox-padding" disabled />
                                    <div className="col-md-2"></div>
                                    <label className="col-md-2 mt-2 text-left">{t('Password')} <span className={classes.mandatory}>*</span> </label>
                                    <input id="aid" disabled={disable} onChange={inputsHandler} value={inputField.Password} autoComplete='no' name="Password" type="password" className="col-md-2 mt-2 textbox-padding" />
                                </div>
                                <div className="row">
                                    <label className="col-md-2 mt-2 text-left">{t('DateFormat')}<span className={classes.mandatory}>*</span> </label>
                                    <select disabled={disable} name="dateformat" id="dateformat" style={{ fontSize: "0.75rem" }} onChange={inputsHandler} value={inputField.dateformat} className={class_textbox}>
                                        <option>Select</option>
                                        <option value="dd/MM/yyyy">DD/MM/YYYY</option>
                                        <option value="MM/dd/yyyy">MM/DD/YYYY</option>
                                    </select>
                                    <div className="col-md-2"></div>
                                    <label className="col-md-2 mt-2 text-left">{t('ConfirmPassword')} <span className={classes.mandatory}>*</span> </label>
                                    <input id="aid" disabled={disable} onChange={inputsHandler} value={inputField.ConfirmPassword} autoComplete='no' name="ConfirmPassword" type="password" className="col-md-2 mt-2 textbox-padding" />

                                </div>

                                <div className="row">
                                    <label className="col-md-2 mt-2 text-left">{t('Locale')} <span className={classes.mandatory}>*</span></label>
                                    <select name="Locale" id="Locale" onChange={inputsHandler} style={{ fontSize: "0.75rem" }} value={inputField.Locale} className="col-md-2 textbox-padding mt-2">
                                        <option >Select</option>
                                        <option value="Indian">Indian</option>
                                        <option value="International">International</option>
                                    </select>
                                    <div class="col-md-2"></div>
                                    <label className="col-md-2 mt-2 text-left">{t('IsActive')} <span className={classes.mandatory}>*</span> </label>
                                    <input style={{ fontStyle: "normal", pointerEvents: "none" }} type="checkbox" checked={inputField.IsActive} id="isActive" className="mt-2" />

                                </div>
                                <div className="row">
                                    <label className="col-md-2 mt-2 text-left">{t('Language')} <span className={classes.mandatory}>*</span></label>
                                    <select name="Languages" id="Languages" onChange={inputsHandler} style={{ fontSize: "0.75rem" }} value={inputField.Languages} className="col-md-2 textbox-padding mt-2">
                                        <option >Select</option>
                                        <option value="en">English</option>
                                        <option value="it">Italian</option>
                                    </select>
                                    <div class="col-md-2"></div>

                                </div>


                                <div className="row mt-2">
                                    <label className="col-md-2 mt-2 text-left">{t('Roles')} <span className={classes.mandatory}>*</span></label>
                                    <Multiselect className={class_multiselect}
                                        onSelect={handleChange}
                                        onRemove={handleChange}
                                        options={Roles}
                                        displayValue="Name"
                                        showCheckbox={true}
                                        selectedValues={selectedRoles}
                                        disable={disable}
                                        style={regionStyles}

                                    />
                                </div>
                                <div className="row mt-2">
                                    <label className="col-md-2 mt-2 text-left">{t('Groups')}<span className={classes.mandatory}>*</span></label>
                                    <Multiselect className={class_multiselect}
                                        onSelect={handleChangeGroup}
                                        onRemove={handleChangeGroup}
                                        options={Groups}
                                        displayValue="Label"
                                        selectedValues={selectedGroups}
                                        disable={disable}
                                        style={regionStyles}
                                    />
                                </div>

                            </div>


                        </div>

                    </div>
                </div>
            </div>
            <Modal show={closeModal}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Body style={{ "textAlign": 'center', fontFamily: 'Segoe UI' }}>Your changes will be lost! Are you sure you want to close?</Modal.Body>
                <Modal.Footer>
                    <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "45px" }} size='sm' onClick={() => closeHandler("Close")}>
                        Close
                    </Button>
                    <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "50px" }} size='sm' onClick={() => closeHandler("Cancel")}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
            <CustomErrorPopup ErrorMessage={errorMessage} show={showErrorModal} ErrorStatus={errorStatus}
                onHide={() => setShowErrorModal(false)}></CustomErrorPopup>
        </div>
    </>)
}
export default EditUserComponent