import Service from '../../../Services/Service';
import { Form, FormGroup, Col, Row, Button, InputGroup, Collapse, Modal } from 'react-bootstrap';
import React, { useState, useEffect } from 'react'
import { Link, Navigate, useLocation } from "react-router-dom"
import Spinner from 'react-bootstrap/Spinner';
import SessionManage from '../../../SessionManagement/SessionManage';
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import Select from "react-select";
import classes from '../../../Components/RebateProgramComponent/CreateRebateComponent/Form.module.css'
import moment from 'moment'
import $, { type } from 'jquery';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import CustomErrorPopup from '../../../UI/CustomErrorPopup';
import ProductTreeDialog from '../../../Components/RebateProgramComponent/CreateRebateComponent/ProductTreeDialog';

const customStyles = {
    control: base => ({
        ...base,
        minHeight: 28,
        maxHeight: 100,
    }),
    dropdownIndicator: base => ({
        ...base,
        padding: 2,
        fontSize: "0.75rem",
    }),
    clearIndicator: base => ({
        ...base,
        padding: 2
    }),
    multiValue: base => ({
        ...base,
        fontSize: "0.75rem",
    }),
    valueContainer: base => ({
        ...base,
        padding: '0px 2px',
        fontSize: "0.75rem",
    }),
    input: base => ({
        ...base,
        margin: 0,
        padding: 0,
    })
};


//Create Mass Action Submit Claim For Rebate

function CreateNewMassActionSubmitClaimForRebate(props) {


    const navigate = useNavigate();
    const [t, i18n] = useTranslation('translation');
    const [rebateAgreements, setrebateAgreements] = useState([])
    const [showspinner, setshowspinner] = useState('');
    const [showLoadMore, setshowLoadMore] = useState('');
    const [totalselectedcount, settotalselectedcount] = useState(0);
    const [totalselectedshow, settotalselectedshow] = useState('');
    const [totalCountlst, settotalCountlst] = useState(0);
    const [userPerms, setuserPermission] = useState([]);
    const [showRenew, setshowRenew] = useState(false);

    const [rebateTypeData, setRebateTypeData] = useState([]);
    const [selectedOptionsRebateType, setSelectedOptionsRebateType] = useState('');
    const [showRplData, setshowRplData] = useState([])
    const [rplExcludedData, setExcludedRplData] = useState([])
    const [checkExcludeRpl, setcheckExcludeRpl] = useState(false);
    const [showLineItemfilterpopup, setshowLineItemfilterpopup] = useState(false);
    const [divleft1, setdivleft1] = useState('');
    const [divtop1, setdivtop1] = useState('');
    const [showSubmitModal, setShowSubmitModal] = useState(false);
    const [commentsForSubmit, setCommentsForSubmit] = useState("");
    const [filterValueDict, setFilterValueDict] = useState({
        OldAgreementId: '',
        NewAgreementId: '',
        Oldvalidity_from: null,
        Oldvalidity_to: null,
        Newvalidity_from: null,
        Newvalidity_to: null,
    });
    const clickCloseAccuralWorkflow = () => {
        setshowproductpopup(false);
        setshowAccuralWorkflow(false);
        setsidebarwidth("0vw");
        setshowregionpopup(false);
        setshowCustomerpopup(false);
        setShowSelectedCustomerpopup(false);
        setshowCustomerGroupPopup(false);
        setShowSelectedCustomerGroupPopup(false);
        setShowSelectedRegionpopup(false);
        setShowSelectedProductpopup(false);
        setShowSelectedCreatedBy(false);
        setShowSelectedSettlFreq(false);
    }


    {
        useEffect(() => {
            if (props.id == 0) {
                // getCustomerData("Customer/GetIndividualCustomersWithoutGroup")
                getRegionData("SalesOffice/GetBySalesOrgID")
                AddProductHandler()
                getGroupCustomerData("CustomerGroups/GetAll", paging.pageSize, paging.pageNumber, "Descending", "Id", [], searchValue)
                // getSalesOfficeDataNew("SalesOffice/GetBySalesOrgID")
                getSettlFreqData("settlementfrequency")
                getCreatedBy("MassAction/GetALLUser", props.id, "Descending", "Id", [], null)
                getRebateTypeData("rebatetype")
                getdataByGroup("SDCommon/GetAllDistCustCurrencySettlementProduct");


            }

            if (props.id != '0') {
                getMassActionById("MassAction/GetByID", props.id)
                getRebateTypeData("rebatetype")
                getCreatedBy("MassAction/GetALLUser", props.id, "Descending", "Id", [], null)

            }

            setSortColName("Id");

        }, [props.id])
    }

    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [errorStatus, setErrorStatus] = useState("Error");
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [saveProgramLine, setSaveProgramLine] = useState(false);
    const [filterValueCust, setFilterValueCust] = useState([]);
    const [filterValueCustGroup, setFilterValueCustGroup] = useState([]);
    const [customerData, setCustomerData] = useState([]);
    const [groupCustomerData, setGroupCustomerData] = useState([]);
    const [checkCustomerGroup, setcheckCustomerGroup] = useState(false);

    const [regionchangesvalue, setregionchangesvalue] = useState([]);
    const [selectedOptionsRegion, setSelectedOptionsRegion] = useState([]);
    var currentDate = new Date();
    var year = currentDate.getFullYear();
    var month = currentDate.getMonth();
    var day = currentDate.getDate();
    const [defaultDate, setDefaultDate] = useState({ from_date: new Date(), to_date: (new Date(year + 1, month, day)) })
    const [rebateprogrmlinedetail, setrebateProgramLinedetail] = useState({ id: 0, PaymentTermsId: '', rebateProgramLineId: 0, rebateProgramDesc: "", rebateTypeId: 0, rebateType: "", rebateDefinition: "", rebateMeasureId: 0, validityFrom: defaultDate.from_date, validityTo: defaultDate.to_date, settlementFreqId: 0, settlementFreq: "", active: false, deleted: false, statusId: 0, status: "", geography: [], Rebate_Payout_Cond_Id: 0, Rebate_Payout_Cond: "", comparisonEndDate: '', comparisonStartDate: '', comparisondateformatId: 0 })

    const [showAccuralWorkflow, setshowAccuralWorkflow] = useState('');
    const [customercheckAll, setCustomercheckAll] = useState(false);
    const [showproductpopup, setshowproductpopup] = useState('');
    const [showregionpopup, setshowregionpopup] = useState('');
    const [showCustomerGroupPopup, setshowCustomerGroupPopup] = useState('');
    const [customerGroupDataSelected, setCustomerGroupDataSelected] = useState([]);
    const [showSelectedCustomerGroupPopup, setShowSelectedCustomerGroupPopup] = useState(false);
    const [groupCustomerChanged, setGroupCustomerChanged] = useState(false);
    const [customerChanged, setCustomerChanged] = useState(false);
    const [showSelectedCustomerpopup, setShowSelectedCustomerpopup] = useState(false);
    const [showSelectedRegionpopup, setShowSelectedRegionpopup] = useState(false);
    const [showSelectedProductpopup, setShowSelectedProductpopup] = useState(false);
    const [showSelectedCreatedBy, setShowSelectedCreatedBy] = useState(false);
    const [showSelectedSettlFreq, setShowSelectedSettlFreq] = useState(false);
    const [filterCusDropDownData, setFilterCusDropDownData] = useState([]);
    const [filtertextCustGroup, setfiltertextCustGroup] = useState('');
    const [filtertextCust, setfiltertextCust] = useState('');
    const [custFilterCol, setCustomerFilterCol] = useState('');
    const [showCustomerfilterpopup, setshowCustomerfilterpopup] = useState(false);
    let loggedInUser = sessionStorage.getItem("id")
    const [filterValue, setFilterValue] = useState([]);
    const [data, setData] = useState([]);
    const [datagroupCust, setDataGroupCust] = useState([]);
    const [dataCreatedby, setDataCreatedBy] = useState([]);
    const [filterValueCustomer, setFilterValueCustomer] = useState({
        Name: '',
        Label: '',
        Station: '',
        State: '',
        Zone: '',

    });
    const [rplId, setRplId] = useState("");
    const [clickProduct, setclickProduct] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [dualListBoxOptionsByHeirarchy, setdualListBoxOptionsByHeirarchy] = useState([])
    const [dualListBoxOptionsByAttribute, setdualListBoxOptionsByAttribute] = useState([])
    const [rebatelinestatus, setrebatelinestatus] = useState('New');
    const [FiltedProducts, SetFiltedProducts] = useState([]);
    const [PrevSelectedTypeState, setPrevSelectedTypeState] = useState('Heirarchy');
    const [selectedType, setSelectedType] = useState('Attribute');
    const [open, setOpen] = useState(true);
    const [showProdModal, setShowProdModal] = useState(true);
    const [showExcludeModal, setShowExcludeModal] = useState(false);
    const [changeHandle, setChangeHandle] = useState(false);
    const [Products, SetProducts] = React.useState([]);
    const [inputField, setInputField] = useState({
        addproductdata: true,
        excludeproductdata: false
    })
    const [FilteredProducts, SetFilteredProducts] = useState([]);
    const [showpopupcontnent, setshowpopupcontnent] = useState({
        value: 1
    })
    const [ProductList, SetProductList] = useState([]);
    const [ExcludedProductList, SetExcludedProductList] = useState([]);
    const [productDataSelected, setProductDataSelected] = useState([]);


    const [showfilterpopup, setshowfilterpopup] = useState(false);
    const [filterSearch, setFilterSearch] = useState("");

    const [massActionData, setMassActionData] = useState({
        Id: 0,
        User_id: parseInt(loggedInUser),
        CustomerGroup: false,
        Product: [],
        Region: [],
        Customer: [],
        SetlFreq: [],
        CreatedUser: [],
        Distributor: [],
        Desc: "",
        RebateTypeId: 0,
        CreatedBy: '',
        MassActionType: 'Submit',
        MassactionFor: 'ClaimAgreement',
        CreatedDate: moment(new Date()).format(SessionManage.getcurrentdateformat())

    });

    const [renewData, setRenewData] = useState({
        MassAgreement: [],
        MassActionId: massActionData.Id,
        submit_comments: "",
        User_id: parseInt(loggedInUser),
    });

    const [renewCompleted, setRenewCompleted] = useState(false)
    const getdataProductSelectorByAttribute = (programId) => {
        Service.getdataWithPagingandattributeSearchingByGroup('rebate/ProductSelectorByAttributeNew', '', 200000, 1, 0, [], programId).then((res) => {
            if (typeof res !== 'undefined' && typeof res.data !== 'undefined') {
                setdualListBoxOptionsByAttribute(res.data.DataList)
            }
        });
    };


    const AddProductHandler = () => {

        if (dualListBoxOptionsByHeirarchy.length == 0) {
            Service.postdatawithId("Rebate/GetProductSelectorUpdatedByGroup").then((res) => {
                if (typeof res !== 'undefined') {
                    if (typeof res.data !== 'undefined' && res.data !== 'null') {
                        if (res.status === 200) {
                            if (res.data !== 'null' && res.data !== 'undefined') {

                                setdualListBoxOptionsByHeirarchy(res.data)
                            }
                        }
                    }
                }

            });
        }



    }
    const clickSelectedShowProductPopup = (val) => {


        let lstcopy = FiltedProducts

        let lst1 = [];
        setProductDataSelected([...lstcopy])
        setshowAccuralWorkflow(false);
        setshowproductpopup(false);
        setshowregionpopup(false);
        setshowCustomerpopup(false);
        setshowCustomerpopup(false);
        setshowCustomerGroupPopup(false);
        setShowSelectedCustomerGroupPopup(false);
        setShowSelectedRegionpopup(false);
        setShowSelectedProductpopup(true);
        setShowSelectedCreatedBy(false);
        setShowSelectedSettlFreq(false);
        setsidebarwidth("55vw");
    }

    const clickShowProductPopup = () => {

        Service.getdataWithPagingandattributeSearchingByGroup('rebate/ProductSelectorByAttributeNew', '', 200000, 1, 0, [], 0).then((res) => {
            if (typeof res !== 'undefined' && typeof res.data !== 'undefined') {
                setdualListBoxOptionsByAttribute(res.data.DataList)
                setclickProduct(true)
                setShowModal(true)
                setSelectedType('Attribute')
                setshowAccuralWorkflow(false);
                setshowproductpopup(true);
                setsidebarwidth("70vw")
            }
        });
    }


    const FilterProductList = (selectedProducts) => {

        let res = []
        let res2 = []
        let temp_prodList = []
        let temp_excludeProdList = []


        if (ProductList == undefined) {
            ProductList = [];
        } else {
            ProductList.length = 0;
        }
        ExcludedProductList.length = 0;
        temp_prodList.length = 0
        temp_excludeProdList.length = 0

        if (selectedProducts) {
            Service.postDataWithValue("Rebate/ProductSelectorByhierarchyUpdated", selectedProducts).then((res) => {
                if (typeof res !== 'undefined') {
                    if (typeof res.data !== 'undefined' && res.data !== 'null') {
                        if (res.status === 200) {

                            let rData = res.data


                            if (rData !== 'null' && rData !== 'undefined') {

                                rData.forEach((item) => {
                                    if (item) {
                                        temp_prodList.push(item)
                                    }
                                })

                            }
                            SetFiltedProducts(rData)


                        }
                    }
                }

            })
        }
    }

    const SelectedProductTypeHandler = (event) => {
        setChangeHandle(true)
        setSelectedType(event.target.value);

        if (event.target.value === 'Heirarchy') {
            setPrevSelectedTypeState("Attribute");
        } else {
            setPrevSelectedTypeState("Heirarchy");
        }
    }
    const CloseProductPicker = () => {

        setOpen(false);
        setShowProdModal(false);
        setShowExcludeModal(false)
    }

    const UpdateProducts = (_SelectedProducts) => {
        setChangeHandle(true)

        setPrevSelectedTypeState(selectedType);

        let ids = []
        _SelectedProducts.forEach((each) => {
            const pieces = each.split("_")
            const last = pieces[pieces.length - 1]
            ids.push(parseInt(last))
        })

        setMassActionData({ ...massActionData, ['Product']: ids })
        if (rebateprogrmlinedetail.status === "New" || rebateprogrmlinedetail.status === undefined || rebateprogrmlinedetail.status === "") {
            SetFiltedProducts(_SelectedProducts);
            FilterProductList(_SelectedProducts);
        }
        let temp = false;
        inputField.addproductdata = temp
        setInputField(inputField);
        showpopupcontnent.value = 2;
        setshowpopupcontnent(showpopupcontnent);
        setshowproductpopup(false);
    }


    const handleGroupCust = (e) => {
        if (e.target.checked == true) {
            setcheckCustomerGroup(true)
            setMassActionData({ ...massActionData, ['CustomerGroup']: true, ['Customer']: [] })
            if (groupCustomerData.length == 0) {
                getGroupCustomerData("CustomerGroups/GetAll", paging.pageSize, paging.pageNumber, "Descending", "Id", [], searchValue)
            }
        }
        else {
            setcheckCustomerGroup(false)
            setMassActionData({ ...massActionData, ['CustomerGroup']: false, ['Customer']: [] })
            if (customerData.length == 0) {
                getCustomerData("Customer/GetIndividualCustomersWithoutGroup")
            }
        }
    }
    const [divleft, setdivleft] = useState('');
    const [divtop, setdivtop] = useState('');
    let disableProgramLineField = false;
    const [SalesLevelData, setSalesLevelData] = useState([])
    const [regioncheckAll, setRegioncheckAll] = useState(false);
    const [filterValueGroupCustomer, setFilterValuegroupCustomer] = useState({
        label: '',
    });

    const [customerGroupSearch, setCustomerGroupSearch] = useState('');
    const [showCustomerGroupfilterpopup, setshowCustomerGroupfilterpopup] = useState(false);
    const [custGroupFilterCol, setCustomerGroupFilterCol] = useState('');
    const [filterSearchCustGroup, setFilterSearchCustGroup] = useState("");
    let disableField = false;
    const [filterCustGroupDropDownData, setFilterCustGroupDropDownData] = useState([]);
    const [paging, setpaging] = useState({
        pageSize: 20,
        pageNumber: 1
    })
    const [customerGroupDataFilter, setCustomerGroupDataFilter] = useState([]);
    const [regionDataSelected, setRegionDataSelected] = useState([]);
    const [customerDataSelected, setCustomerDataSelected] = useState([]);
    const [CreatedByDataSelected, setCreatedByDataSelected] = useState([]);
    const [state, setState] = useState({ id: 0, rebateTypeID: 0, customerID: 0, salesOrgID: 0, agreementName: "", currencyID: 0, setlMethodID: 0, Customer_Ids: [], setlFreqID: 4, startDate: new Date(), endDate: new Date(SessionManage.getFiscalEndDate()), autoPostAccruals: false, analysis: false, status: 'draft', agreementId: '0', created_by: '', is_active: false, createdDate: moment(new Date()).format(SessionManage.getcurrentdateformat()), CurrencySymbol: '$', LockedByUserName: '', CustomerGroup: false })


    const [customerDataFilter, setCustomerDataFilter] = useState([]);
    const [customerSearch, setCustomerSearch] = useState('');

    const [distributorDataFilter, setDistributorDataFilter] = useState([]);
    const [distributorSearch, setDistributorSearch] = useState('');
    const [distributorcheckAll, setDistributorcheckAll] = useState(false);
    const [filterValueDistributor, setFilterValueDistributor] = useState({
        Name: '',
        Label: '',
    });



    const okSelectedCustomer = () => {
        var customerLst = customerDataSelected.filter((e) => e.Selected === false);
        let olddata = [...customerData];
        olddata.map((i) => {
            var customers = customerDataSelected.filter((e) => e.Selected === false && i.Id === e.Id);
            if (customers.length > 0) {
                i.Selected = false;
            }
        })

        setCustomerData(olddata);
        const getselected = [];
        customerDataSelected.filter((e) => e.Selected === true).map((i) => {
            getselected.push(i.Id);
        });
        setState({ ...state, ['Customer_Ids']: getselected })
        setshowCustomerpopup(false);
        setShowSelectedCustomerpopup(false);
    }
    const okCustomer = () => {
        setshowCustomerpopup(false);
        setShowSelectedCustomerpopup(false);


    }



    const okregion = () => {

        setshowregionpopup(false);
    }



    const okSelectedCustomerGroup = () => {
        var customerLst = customerGroupDataSelected.filter((e) => e.Selected === false);
        let olddata = [...groupCustomerData];
        olddata.map((i) => {
            var customers = customerGroupDataSelected.filter((e) => e.Selected === false && i.Id === e.Id);
            if (customers.length > 0) {
                i.Selected = false;
            }

        })

        setGroupCustomerData(olddata);
        const getselected = [];
        customerGroupDataSelected.filter((e) => e.Selected === true).map((i) => {
            getselected.push(i.Id);
        });
        setState({ ...state, ['Customer_Ids']: getselected })
        setshowCustomerGroupPopup(false);
        setShowSelectedCustomerGroupPopup(false);

    }


    const checkAllRegionchange = (mycheckbox) => {

        let Ids = [];
        var checkboxes = document.querySelectorAll("input[name = 'region-check']");
        if (mycheckbox.target.checked == true) {
            setRegioncheckAll(true);

            let olddata = [...SalesLevelData];
            olddata.map((i) => {
                i.Selected = true;
                Ids.push(i.Name);
            })
            setSalesLevelData(olddata);
            setMassActionData({ ...massActionData, ['Region']: Ids })
        }
        else {
            setRegioncheckAll(false);


            let olddata = [...SalesLevelData];
            olddata.map((i) => {
                i.Selected = false;
            })
            setSalesLevelData(olddata);
            setMassActionData({ ...massActionData, ['Region']: [] })
        }
    }

    const handleCustomerGroupSelectedChanges = (e, label, id, State) => {

        var rowData = SalesLevelData
        for (var i = 0; i < rowData.length; i++) {

            if (rowData[i].label.toLowerCase() == State.toLowerCase())
                if (e.target.checked == true)
                    rowData[i].Selected = true;
                else
                    rowData[i].Selected = false;

        }
        setSalesLevelData(rowData)

        let lists = [];

        let olddata = customerGroupDataSelected;
        if (e.target.checked === true) {

            olddata.map((i) => {
                if (i.Id === id) {
                    i.Selected = true;
                }
                lists.push(i);
            })


        }
        else {
            olddata.map((i) => {
                if (i.Id === id) {
                    i.Selected = false;
                }
                lists.push(i);
            })

        }
        setCustomerGroupDataSelected(lists)

    }

    const SearchGroupCustomer = (event) => {
        var list = []
        let lstSelected = groupCustomerData.filter((e) => e.Selected === true).map(item => {
            return { ...item }
        })
        setCustomerGroupSearch(event.target.value)
        if (customerGroupDataFilter.length === 0) {
            list = groupCustomerData;

            setCustomerGroupDataFilter(list);
        }
        else {
            list = customerGroupDataFilter
        }
        if (event.target.value != '') {
            let searchResults = list.filter((each) => {

                return each.label.toLowerCase().includes(String(event.target.value).toLowerCase()) ||
                    each.Name.toLowerCase().includes(String(event.target.value).toLowerCase())


            });

            let newlist = [...searchResults, ...lstSelected];
            const uniquelst = [];
            newlist.map((item) => {
                var findItem = uniquelst.find((x) => x.Id === item.Id);
                if (!findItem) uniquelst.push(item);
            });

            setGroupCustomerData(uniquelst)

        }
        else {
            setGroupCustomerData(list)
        }
    }


    const getGroupCustomerData = (method, pageSize, pageNumber, sortType, SortProperty, filterValue, search) => {

        Service.getdataWithPagingByGroupSearch(method, pageSize, pageNumber, sortType, SortProperty, filterValue, search).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {

                            setGroupCustomerData(res.data.DataList)
                            if (datagroupCust.length == 0) {
                                setDataGroupCust(res.data.DataList)
                            }
                        }
                    }
                }
            }

        });
    }
    const DropdownHandlerCustGroup = (key) => {

        setcurrentCustGroupFilterKey(key)
        Service.getfilterDropdownByGroup('CustomerGroups/GetFilterValue', key).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.data !== 'null' && res.data !== 'undefined') {

                        addnewfieldCustomerGroup(res.data, key)
                    }
                }
            }

        });
        // }
    }
    const FilterColumnGroupCustomer = (name) => {
        var divtop1 = document.getElementById("CustomerGroup");
        var cardtop1 = document.getElementById("CustomerGroup");
        setdivtop(divtop1.offsetTop + cardtop1.offsetTop + 30)
        setCustomerGroupFilterCol(name)
        setshowCustomerGroupfilterpopup(true)

        if (name === 'label') {
            setfiltertextCustGroup("Customer Group Label")
            var testDiv = document.getElementById("GroupLabel");
            setdivleft((testDiv.offsetLeft + 30) + "px");
        }
        DropdownHandlerCustGroup(name);
    }
    const OnChangeHandlerGroupCustomer = (e, label, id, State) => {

        var rowData = SalesLevelData


        let Selected = [];
        let update = [];
        const getallData = [...massActionData.Customer];

        let olddata = [...groupCustomerData];

        if (e.target.checked === true) {

            olddata.map((i) => {
                if (i.Id === id) {
                    i.Selected = true;
                }

            })

            getallData.push(id);
            datagroupCust.map((i) => {
                if (i.Id === id) {
                    i.Selected = true;
                }

            })
            setGroupCustomerData(datagroupCust);

            setMassActionData({ ...massActionData, ['Customer']: getallData })
            setGroupCustomerChanged(true);
        }
        else {
            olddata.map((i) => {
                if (i.Id === id) {
                    i.Selected = false;
                }
                update.push(i);
            })
            datagroupCust.map((i) => {
                if (i.Id === id) {
                    i.Selected = false;
                }

            })

            setMassActionData({ ...massActionData, ['Customer']: getallData.filter(i => i !== id) })
            setGroupCustomerData(datagroupCust);
        }
    }
    const okCustomerGroup = () => {

        setshowCustomerGroupPopup(false);
        setShowSelectedCustomerGroupPopup(false);

    }
    const UserfilterHandlerCustomerGroup = (e) => {

        setFilterSearchCustGroup(e.target.value)
        if (e.target.value !== "") {
            let searchResults = filterCustGroupDropDownData.filter((each) => {
                return each.Value.toLowerCase().includes(String(e.target.value).toLowerCase())
            });
            setFilterCustGroupDropDownData(searchResults);
        }
        else {
            setFilterSearchCustGroup("")
            setFilterCustGroupDropDownData(filtercustGroupData)
        }
    }
    const checkAllFilterCustomerGroup = (mycheckbox) => {
        var lastfilter = [...filterCustGroupDropDownData]
        let data1 = []
        var checkboxes = document.querySelectorAll("input[name = 'filter-option-all']");
        if (mycheckbox.target.checked == true) {

            let currentdata = lastfilter.map((i) => {
                i.Selected = true;
                return i;
            });

            setFilterCustGroupDropDownData(currentdata)
            filterCustGroupDropDownData.map((each) => {
                const getallData = {
                    Name: each.Value,
                }
                data1.push(getallData)
            })
            setFilterValueCustGroup(filterValueCustGroup => [...filterValueCustGroup, ...data1]);
            let updateddata = ([...filterValueCustGroup, ...data1]);
            setFilterValuegroupCustomer(() => ({
                ...filterValueGroupCustomer, [custGroupFilterCol]: updateddata,
            }));
        }
        else {

            let currentdata = lastfilter.map((i) => {
                i.Selected = false;
                return i;
            });
            setFilterValuegroupCustomer([])
            setFilterValueCustGroup([])

        }

    }
    const OnCustGroupdropdownChange = (e, id, name) => {

        let isChecked = e.target.checked;
        var lastfilter = [...filterCustGroupDropDownData]
        if (isChecked === true) {
            let currentdata = lastfilter.map((i) => {
                if (i.Name == name) {
                    i.Selected = true;
                }
                return i;
            });
            setFilterCustGroupDropDownData(currentdata);
            let data1 = []
            const getallData = {
                Name: name,
            }
            data1.push(getallData)
            setFilterValueCustGroup(filterValueCustGroup => [...filterValueCustGroup, ...data1]);
            let updateddata = ([...filterValueCustGroup, ...data1]);
            setFilterValuegroupCustomer(() => ({
                ...filterValueGroupCustomer, [custGroupFilterCol]: updateddata,
            }));
        }
        else {
            let currentdata = lastfilter.map((i) => {
                if (i.Id === id) {
                    i.Selected = false;
                }
                return i;
            });

            setFilterCustGroupDropDownData(currentdata);
            setFilterValueCustGroup(filterValueCustGroup => filterValueCustGroup.filter(data => data.Name !== name))
            let data1 = filterValueCustGroup.filter(data => data.Name !== name);
            setFilterValuegroupCustomer(() => ({
                ...filterValueGroupCustomer, [custGroupFilterCol]: data1,
            }));
        }
    }
    const filterHandlerCustomerGroup = (flag) => {

        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
        let pagenumber = 1;
        paging.pageNumber = pagenumber;

        if (flag === 'Apply') {
            setshowCustomerGroupfilterpopup(false)
            let list = [];

            for (var key in filterValueGroupCustomer) {
                if (filterValueGroupCustomer[key].length > 0) {
                    let values = ""
                    filterValueGroupCustomer[key].map((i) => {
                        values += i.Name + ";";
                    })
                    if (values !== null && values !== '') {
                        list.push({
                            filterKey: key,
                            FilterValue: values
                        })
                    }
                }
            }


            if (list.length > 0) {
                setpaging(paging);
                getGroupCustomerData("CustomerGroups/GetAll", paging.pageSize, paging.pageNumber, sortType, sortColName, list, searchValue)
            }


        }
        else {
            setshowCustomerGroupfilterpopup(false)

            setFilterValuegroupCustomer(() => ({
                ...filterValueGroupCustomer, [custGroupFilterCol]: '',
            }));


            let list = [];
            for (var key in filterValueGroupCustomer) {
                if (key !== custGroupFilterCol && filterValueGroupCustomer[key].length > 0) {
                    let values = ""
                    filterValueGroupCustomer[key].map((i) => {
                        values += i.Name + ";";
                    })
                    if (values !== null && values !== '') {
                        list.push({
                            filterKey: key,
                            FilterValue: values
                        })
                    }
                }
            }


            setpaging(paging);

            getGroupCustomerData("CustomerGroups/GetAll", paging.pageSize, paging.pageNumber, sortType, sortColName, list, searchValue)

            setCustomerGroupFilterCol('')
            setFilterValueCustGroup([])
            setFilterSearchCustGroup("")
            setFilterCustGroupDropDownData([])
            setfilterCustGroupData([])
        }
    }
    const [currentCustGroupFilterKey, setcurrentCustGroupFilterKey] = useState('');
    const [columnAscending, setColumnAscending] = useState(true);
    const [sortColName, setSortColName] = useState("");
    const [searchValue, setSearchValue] = useState("")
    const [filtercustGroupData, setfilterCustGroupData] = useState([]);

    const [sticky, setSticky] = useState("");
    const [editPerms, setEditPerms] = useState('');
    const [loadedFrom, setLoadedFrom] = useState('')
    const [countryData, setCountryData] = useState([]);
    const [disableHeaderField, setdisableHeaderField] = useState(false);
    const [distributorList, setDistributorList] = useState([]);
    const [disableSubDivideTarget, setdisableSubDivideTarget] = useState(false);

    const [currencyList, setCurrencyList] = useState([]);
    const [settlementMethodsList, setSettlementMethodsList] = useState([]);
    const [productList, setProductList] = useState([]);
    const [statusList, setStatusList] = useState([]);

    const [setlFreqData, setSetlFreqData] = useState([]);
    const [hasSubdivideTarget, setHasSubdivideTarget] = useState(false);

    const addnewfieldCustomerGroup = (data, key) => {


        let currentdata = data.map((i) => {
            i.Selected = false;
            return i;
        });
        let selectedKeys = filterValueGroupCustomer[key];

        if (selectedKeys != '') {

            currentdata.forEach((each) => {
                selectedKeys.forEach((eachSelected) => {
                    if (each.Value == eachSelected.Name) {
                        each.Selected = true
                    }
                })
            })
        }
        setFilterCustGroupDropDownData(currentdata);
        setfilterCustGroupData(currentdata)
    }


    const getSalesOfficeDataNew = (method) => {
        Service.getregionbyId('SalesOffice/GetBySalesOrgID', 0).then((res) => {

            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                            let rowData = res.data
                            if (rowData.length > 0) {

                                for (var i = 0; i < rowData.length; i++) {
                                    for (var j = 0; j < customerData.length; j++) {
                                        try {
                                            if (rowData[i].label.toLowerCase() == customerData[j].State.toLowerCase())
                                                if (customerData[j].Selected == true)
                                                    rowData[i].Selected = true;
                                        } catch { }
                                    }
                                }
                                setSalesLevelData(rowData)
                            }

                            else {
                                setSalesLevelData([])
                            }
                        }
                    }
                }
            }
        });
    }
    const [salesOrgData, setSalesOrgData] = useState({ id: 0, name: "" });
    const [selectedOptions, setSelectedOptions] = useState();
    const [selectedOptionsSetlMethod, setSelectedOptionsSetlMethod] = useState('');
    const [selectedOptionsSetlFreq, setSelectedOptionsSetlFreq] = useState('4');
    const [selectedOptionsCurrency, setSelectedOptionsCurrency] = useState('');
    const [isLocked, setIsLocked] = useState(false);
    const [lockedBy, setLockedBy] = useState('');
    const [selectedSettlementFreq, setSelectedSettlementFreq] = useState('');
    const [customerInvoiceValue, setCustomerInvoiceValue] = useState([]);

    const getSalesOfficeDatabyGroup = (method, id) => {

        let rowData = [];
        Service.getdatabyId(method, id).then((res) => {

            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                            rowData = res.data.DataList
                        }
                    }
                }
            }
        })
        Service.getregionbyId('SalesOffice/GetBySalesOrgID', 0).then((res) => {
            ;
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                            let sData = res.data
                            if (sData.length > 0) {
                                for (var i = 0; i < sData.length; i++) {
                                    for (var j = 0; j < rowData.length; j++) {
                                        try {
                                            if (sData[i].label.toLowerCase() == rowData[j].State.toLowerCase())
                                                if (rowData[j].Selected == true)
                                                    sData[i].Selected = true;
                                        } catch { }
                                    }
                                }
                                setSalesLevelData(sData)
                            }
                            else {
                                setSalesLevelData([])
                            }
                        }
                    }
                }
            }
        });
    }

    const [currencyData, setCurrencyData] = useState([]);
    const getCurrencyData = (method) => {
        Service.getAlldata(method).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {

                            setCurrencyData(res.data)
                            const firstdata = res.data[0]

                            var defCurrency = JSON.parse(SessionManage.getdefaultCurrency());
                            if (defCurrency !== undefined && defCurrency !== null) {

                                setSelectedOptionsCurrency(defCurrency.id);
                                setState({ ...state, ['currencyID']: defCurrency.id })

                            }

                        }
                    }
                }
            }

        });
    }
    const OnCusdropdownChange = (e, id, name) => {

        let isChecked = e.target.checked;
        var lastfilter = [...filterCusDropDownData]
        if (isChecked === true) {
            let currentdata = lastfilter.map((i) => {
                if (i.Name == name) {
                    i.Selected = true;
                }
                return i;
            });
            setFilterCusDropDownData(currentdata);
            let data1 = []
            const getallData = {
                Name: name,
            }
            data1.push(getallData)
            setFilterValueCust(filterValueCust => [...filterValueCust, ...data1]);
            let updateddata = ([...filterValueCust, ...data1]);
            setFilterValueCustomer(() => ({
                ...filterValueCustomer, [custFilterCol]: updateddata,
            }));
        }
        else {
            let currentdata = lastfilter.map((i) => {
                if (i.Id === id) {
                    i.Selected = false;
                }
                return i;
            });

            setFilterCusDropDownData(currentdata);
            setFilterValueCust(filterValueCust => filterValueCust.filter(data => data.Name !== name))
            let data1 = filterValueCust.filter(data => data.Name !== name);
            setFilterValueCustomer(() => ({
                ...filterValueCustomer, [custFilterCol]: data1,
            }));
        }
    }
    const [filterSearchCust, setFilterSearchCust] = useState("");
    const [filtercustData, setfilterCustData] = useState([]);
    const UserfilterHandlerCustomer = (e) => {

        setFilterSearchCust(e.target.value)
        if (e.target.value !== "") {
            let searchResults = filterCusDropDownData.filter((each) => {
                return each.Name.toLowerCase().includes(String(e.target.value).toLowerCase())
            });
            setFilterCusDropDownData(searchResults);
        }
        else {

            setFilterSearchCust("")
            setFilterCusDropDownData(filtercustData)
        }
    }

    const checkAllFilterCustomer = (mycheckbox) => {
        var lastfilter = [...filterCusDropDownData]
        let data1 = []
        var checkboxes = document.querySelectorAll("input[name = 'filter-option-all']");
        if (mycheckbox.target.checked == true) {

            let currentdata = lastfilter.map((i) => {
                i.Selected = true;
                return i;
            });

            setFilterCusDropDownData(currentdata)
            filterCusDropDownData.map((each) => {

                const getallData = {
                    Name: each.Name,
                }
                data1.push(getallData)
            })
            setFilterValueCust(filterValueCust => [...filterValueCust, ...data1]);
            let updateddata = ([...filterValueCust, ...data1]);
            setFilterValueCustomer(() => ({
                ...filterValueCustomer, [custFilterCol]: updateddata,
            }));
        }
        else {

            let currentdata = lastfilter.map((i) => {
                i.Selected = false;
                return i;
            });
            setFilterValueCust([])
            setFilterValueCustomer([])

        }

    }
    const CustFilterHandler = (flag) => {
        let list = [];

        var selectedCus = data;
        for (var i = 0; i < customerData.length; i++) {
            if (customerData[i].Selected == true) {
                for (var k = 0; k < selectedCus.length; k++) {
                    if (customerData[i].Id === selectedCus[k].Id) {
                        selectedCus[k].Selected = true;
                    }
                }
            }
        }
        setData(selectedCus);
        setshowCustomerfilterpopup(false)

        if (flag === 'Apply') {

            for (var key in filterValueCustomer) {
                if (filterValueCustomer[key].length > 0) {
                    let values = ""
                    filterValueCustomer[key].map((i) => {
                        values += i.Name + ";";
                    })
                    if (values !== null && values !== '') {
                        list.push({
                            filterKey: key,
                            FilterValue: values
                        })
                    }
                }
            }

            Service.getdataWithFilters('rebate/filterCustomerAll', data, list).then((res) => {
                if (typeof res !== 'undefined') {
                    if (res.data !== 'null' && typeof res.data !== 'undefined') {
                        setCustomerData(res.data.DataList)
                    }
                }
            });

        }
        else {
            setshowCustomerfilterpopup(false)
            setFilterValueCustomer(() => ({
                ...filterValueCustomer, [custFilterCol]: '',
            }));

            for (var key in filterValueCustomer) {
                if (key !== custFilterCol && filterValueCustomer[key].length > 0) {
                    let values = ""
                    filterValueCustomer[key].map((i) => {
                        values += i.Name + ";";
                    })
                    if (values !== null && values !== '') {
                        list.push({
                            filterKey: key,
                            FilterValue: values
                        })
                    }
                }
            }

            Service.getdataWithFilters('rebate/filterCustomerAll', data, list).then((res) => {
                if (typeof res !== 'undefined') {
                    if (res.data !== 'null' && typeof res.data !== 'undefined') {
                        setCustomerData(res.data.DataList)
                    }
                }
            });
            setFilterValueCust([])
            setfilterCustData([])
            setFilterCusDropDownData([])

            setFilterSearchCust('')
        }

    }
    const SearchCustomer = (event) => {
        var list = []
        let lstSelected = customerData.filter((e) => e.Selected === true).map(item => {
            return { ...item }
        })
        setCustomerSearch(event.target.value)
        if (customerDataFilter.length === 0) {
            list = customerData;

            setCustomerDataFilter(list);
        }
        else {
            list = customerDataFilter
        }
        if (event.target.value != '') {
            let searchResults = list.filter((each) => {

                return each.label.toLowerCase().includes(String(event.target.value).toLowerCase()) ||
                    each.Name.toLowerCase().includes(String(event.target.value).toLowerCase()) ||
                    each.Station.toLowerCase().includes(String(event.target.value.toLowerCase())) ||
                    each.State.toLowerCase().includes(String(event.target.value.toLowerCase())) ||
                    each.Zone.toLowerCase().includes(String(event.target.value.toLowerCase()))

            });

            let newlist = [...searchResults, ...lstSelected];
            const uniquelst = [];
            newlist.map((item) => {
                var findItem = uniquelst.find((x) => x.Id === item.Id);
                if (!findItem) uniquelst.push(item);
            });

            setCustomerData(uniquelst)


        }
        else {
            setCustomerData(list)
        }
    }

    const checkAllCustomer = (mycheckbox) => {

        let Ids = [];
        var checkboxes = document.querySelectorAll("input[name = 'customer-check']");
        if (mycheckbox.target.checked == true) {
            setCustomercheckAll(true);
            checkboxes.forEach(function (checkbox) {
                checkbox.checked = true;
            });
            let olddata = [...customerData];
            olddata.map((i) => {
                i.Selected = true;
                Ids.push(i.Id);
            })

            setMassActionData({ ...massActionData, ['Customer']: Ids })
        }
        else {
            setCustomercheckAll(false);
            settotalselectedshow(false);
            checkboxes.forEach(function (checkbox) {
                checkbox.checked = false;
            });
            let olddata = [...customerData];
            olddata.map((i) => {
                i.Selected = false;

            })
            setMassActionData({ ...massActionData, ['Customer']: [] })
        }

    }


    const checkAllGroupCustomer = (mycheckbox) => {

        let Ids = [];
        var checkboxes = document.querySelectorAll("input[name = 'customer-check']");
        if (mycheckbox.target.checked == true) {
            setCustomercheckAll(true);
            checkboxes.forEach(function (checkbox) {
                checkbox.checked = true;
            });
            let olddata = [...groupCustomerData];
            olddata.map((i) => {
                i.Selected = true;
                Ids.push(i.Id);
            })
            setGroupCustomerData(olddata)

            setMassActionData({ ...massActionData, ['Customer']: Ids })
        }
        else {
            setCustomercheckAll(false);
            settotalselectedshow(false);
            checkboxes.forEach(function (checkbox) {
                checkbox.checked = false;
            });
            let olddata = [...groupCustomerData];
            olddata.map((i) => {
                i.Selected = false;

            })
            setGroupCustomerData(olddata)
            setMassActionData({ ...massActionData, ['Customer']: [] })
        }

    }

    const handleCustomerSelectedChanges = (e, label, id, State) => {

        var rowData = SalesLevelData
        for (var i = 0; i < rowData.length; i++) {

            if (rowData[i].label.toLowerCase() == State.toLowerCase())
                if (e.target.checked == true)
                    rowData[i].Selected = true;
                else
                    rowData[i].Selected = false;

        }
        setSalesLevelData(rowData)

        let lists = [];

        let olddata = customerDataSelected;
        if (e.target.checked === true) {

            olddata.map((i) => {
                if (i.Id === id) {
                    i.Selected = true;
                }
                lists.push(i);
            })


        }
        else {
            olddata.map((i) => {
                if (i.Id === id) {
                    i.Selected = false;
                }
                lists.push(i);
            })

        }
        setCustomerDataSelected(lists)

    }




    const SearchDistributor = (event) => {

        var list = []
        let lstSelected = distributorList.filter((e) => e.Selected === true).map(item => {
            return { ...item }
        })
        setDistributorSearch(event.target.value)
        if (distributorDataFilter.length === 0) {
            list = distributorList;

            setDistributorDataFilter(list);
        }
        else {
            list = distributorDataFilter
        }
        if (event.target.value != '') {
            let searchResults = list.filter((each) => {

                return each.label.toLowerCase().includes(String(event.target.value).toLowerCase()) ||
                    each.Name.toLowerCase().includes(String(event.target.value).toLowerCase())

            });

            let newlist = [...searchResults, ...lstSelected];
            const uniquelst = [];
            newlist.map((item) => {
                var findItem = uniquelst.find((x) => x.value === item.Id);
                if (!findItem) uniquelst.push(item);
            });

            setDistributorList(uniquelst)

        }
        else {
            setDistributorList(list)
        }
    }


    const checkAllDistributor = (mycheckbox) => {

        let Ids = [];
        var checkboxes = document.querySelectorAll("input[name = 'distributor-check']");
        if (mycheckbox.target.checked == true) {
            setDistributorcheckAll(true);
            checkboxes.forEach(function (checkbox) {
                checkbox.checked = true;
            });
            let olddata = [...distributorList];
            olddata.map((i) => {
                i.Selected = true;
                Ids.push(i.Id);
            })

            setMassActionData({ ...massActionData, ['Distributor']: Ids })
        }
        else {
            setDistributorcheckAll(false);
            settotalselectedshow(false);
            checkboxes.forEach(function (checkbox) {
                checkbox.checked = false;
            });
            let olddata = [...distributorList];
            olddata.map((i) => {
                i.Selected = false;

            })
            setMassActionData({ ...massActionData, ['Distributor']: [] })
        }

    }


    console.log("15-12", SalesLevelData)





    const handleregionChanges = (e, name, id) => {
        const getallData = [...massActionData.Region]
        let olddata = [...SalesLevelData];
        if (e.target.checked === true) {

            olddata.map((i) => {
                if (i.Id === id) {
                    i.Selected = true;
                    getallData.push(i.Name)
                }
            })
            setSalesLevelData(olddata)
            setMassActionData({ ...massActionData, ['Region']: getallData })
        }
        else {


            olddata.map((i) => {
                if (i.Id === id) {
                    i.Selected = false;
                    getallData.push(i.Name)
                }

            })



            setSalesLevelData(olddata);
            setMassActionData({ ...massActionData, ['Region']: getallData.filter(i => i !== name) });

        }

    }



    const handleDstSelection = (e, label, id, State) => {
        debugger
        let checkboxes = document.querySelectorAll("input[id = '" + id + "']:checked");
        let Selected = [];
        let update = [];
        const getallData = [...massActionData.Distributor];
        let olddata = [...distributorList];

        if (checkboxes.length < 1) {
            olddata.map((i) => {
                if (i.value == id) {
                    i.Selected = true;
                }
            })
            data.map((i) => {
                if (i.value == id) {
                    i.Selected = true;
                }
            })
            setData(data)


            getallData.push(id);
            setDistributorList(data);
            setMassActionData({ ...massActionData, ['Distributor']: getallData })
        }
        else {
            olddata.map((i) => {
                if (i.value == id) {
                    i.Selected = false;
                }
                update.push(i);
            })
            data.map((i) => {
                if (i.value === id) {
                    i.Selected = false;
                }
            })
            setData(data)

            setMassActionData({ ...massActionData, ['Distributor']: getallData.filter(i => i != id) })
            setDistributorList(data);

        }
        setCustomerChanged(true)

    }


    const [sidebarwidth, setsidebarwidth] = useState('');
    const [showCustomerpopup, setshowCustomerpopup] = useState('');

    const [showSettelmetnFreqpopup, setShowSettelmetnFreqpopup] = useState('');
    const [showCreatedByPopup, setshowCreatedByPopup] = useState('');


    const [SettlementFreqData, setSettlementFreqData] = useState([])
    const [UserData, setUserData] = useState([])
    const [UserDataSelected, setUserDataSelected] = useState([])
    const [SettlFreqDataSelected, setSettlFreqDataSelected] = useState([])
    const [settlFreqcheckAll, setsettlFreqcheckAll] = useState(false);

    const [userFilterValueDict, setUserFilterValueDict] = useState({
        firstname: '',
        lastname: '',
        username: '',
        email: '',
        roles: '',
        created_date: null,
        last_modified_date: null,

    });


    const [userMultiFilterValue, setUserMultiFilterValue] = useState({
        createdby: '',
        last_modified_by: '',
        status: '',
        userstype: '',
    });
    const [lineItemFilterValue, setLineItemFilterValue] = useState({
        rebateid: '',
        RebateProgramLineId: '',
        Validity_From: '',
        Validity_To: '',
        OperationStatus: '',
        ErrorMessage: '',
    });

    const [filterCol, setFilterCol] = useState('');
    const [filtertext, setfiltertext] = useState('');
    const [showUserLoadMore, setUserLoadMore] = useState('');



    const clickShowCustomerPopup = (val) => {

        setshowAccuralWorkflow(false);

        setCustomercheckAll(false);

        setshowproductpopup(false);
        setshowregionpopup(false);
        setshowCustomerpopup(true);
        setShowSelectedCustomerpopup(false);
        setShowSelectedRegionpopup(false);
        setShowSelectedProductpopup(false);
        setshowCustomerGroupPopup(false);
        setShowSelectedCustomerGroupPopup(false);
        setshowCreatedByPopup(false);
        setShowSelectedCreatedBy(false);
        setShowSelectedSettlFreq(false);
        setsidebarwidth("63vw");
    }
    const clickShowregionPopup = (val) => {
        setshowAccuralWorkflow(false);
        setshowproductpopup(false);
        setshowregionpopup(true);
        setsidebarwidth("60vw");
        setshowCustomerpopup(false);
        setshowCustomerGroupPopup(false);
        setShowSelectedCustomerGroupPopup(false);
        setShowSelectedRegionpopup(false);
        setShowSelectedProductpopup(false);
        setShowSelectedCreatedBy(false);
        setShowSelectedSettlFreq(false);

    }
    const clickSelectedShowRegionPopup = (val) => {
        let lstcopy = SalesLevelData.filter((e) => e.Selected === true).map(item => {
            return { ...item }
        })

        let lst1 = [];
        setRegionDataSelected([...lstcopy])
        setshowAccuralWorkflow(false);
        setshowproductpopup(false);
        setshowregionpopup(false);
        setshowCustomerpopup(false);
        setshowCustomerpopup(false);
        setShowSelectedRegionpopup(true);
        setShowSelectedProductpopup(false);
        setsidebarwidth("55vw");
    }
    const clickSelectedShowCustomerPopup = (val) => {

        let lstcopy = customerData.filter((e) => e.Selected == true).map(item => {
            return { ...item }
        })

        let lst1 = [];
        setCustomerDataSelected([...lstcopy])




        setshowAccuralWorkflow(false);
        setshowproductpopup(false);
        setshowregionpopup(false);
        setshowCustomerpopup(false);
        setshowCustomerpopup(false);
        setshowCustomerGroupPopup(false);
        setShowSelectedCustomerGroupPopup(false);
        setShowSelectedCustomerpopup(true);
        setShowSelectedRegionpopup(false);
        setShowSelectedProductpopup(false);
        setshowCreatedByPopup(false);
        setShowSelectedCreatedBy(false);
        setShowSelectedSettlFreq(false);
        setsidebarwidth("63vw");

    }
    const clickGroupCustomerPopup = (val) => {

        setshowAccuralWorkflow(false);

        setCustomercheckAll(false);

        setshowproductpopup(false);
        setshowregionpopup(false);
        setshowCustomerpopup(false);
        setshowCustomerGroupPopup(true)
        setShowSelectedCustomerpopup(false);
        setShowSelectedCustomerGroupPopup(false);
        setShowSelectedRegionpopup(false);
        setShowSelectedProductpopup(false);
        setShowSelectedCreatedBy(false);
        setshowCreatedByPopup(false);
        setShowSelectedSettlFreq(false);
        setsidebarwidth("63vw");
    }
    const clickSelectedShowCustomerGroupPopup = (val) => {


        let lstcopy = groupCustomerData.filter((e) => e.Selected === true).map(item => {
            return { ...item }
        })

        let lst1 = [];
        setCustomerGroupDataSelected([...lstcopy])




        setshowAccuralWorkflow(false);
        setshowproductpopup(false);
        setshowregionpopup(false);
        setshowCustomerpopup(false);
        setshowCustomerGroupPopup(false);
        setShowSelectedCustomerpopup(false);
        setShowSelectedCustomerGroupPopup(true);
        setShowSelectedRegionpopup(false);
        setShowSelectedProductpopup(false);
        setshowCreatedByPopup(false);
        setShowSelectedCreatedBy(false);
        setShowSelectedSettlFreq(false);
        setsidebarwidth("63vw");
    }
    const disabledClass = `  ${Service.editPerms(userPerms) == false ? "disabledClass" : ""}`;
    const [multiFilterValue, setMultiFilterValue] = useState({
        include: '',
        status: '',
    });






    const getRebateTypeData = (method) => {
        Service.getAlldata(method).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                            setRebateTypeData(res.data)
                        }
                    }
                }
            }

        });
    }


    const getMassActionById = (method, id) => {
        Service.getdatabyId(method, id).then((res) => {

            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                            let data = res.data

                            if (res.data[0].CustomerGroup == false) {
                                setCustomerData(res.data[0].CustomerLst)
                                setData(res.data[0].CustomerLst)
                            }
                            else {
                                setGroupCustomerData(res.data[0].CustomerGroupLst)
                                setDataGroupCust(res.data[0].CustomerGroupLst)
                            }
                            setSettlementFreqData(data[0].SettlementFreqLst)
                            setSalesLevelData(data[0].RegionLst)
                            setUserData(data[0].RebateCreatedByLst)
                            setDataCreatedBy(data[0].RebateCreatedByLst)
                            SetFiltedProducts(data[0].ProductLst)
                            setSelectedOptionsRebateType(data[0].RebateTypeId)
                            setrebatelinestatus(data[0].Status)
                            setIsLocked(data[0].Locked)
                            setLockedBy(data[0].Locked_byid)
                            if (dualListBoxOptionsByAttribute.length == 0) {
                                Service.getdataWithPagingandattributeSearchingByGroup('rebate/ProductSelectorByAttributeNew', '', 200000, 1, 0, [], 0).then((res) => {
                                    if (typeof res !== 'undefined' && typeof res.data !== 'undefined') {
                                        setdualListBoxOptionsByAttribute(res.data.DataList)


                                        setclickProduct(true)

                                        setSelectedType('Attribute')
                                    }
                                });
                            }

                            let customer = []
                            if (res.data[0].CustomerGroup == false) {
                                let custdata = data[0].CustomerLst.filter((i) => i.Selected == true).map(i => i.Id)
                                customer = custdata
                            }
                            else {
                                let custdata = data[0].CustomerGroupLst.filter((i) => i.Selected == true).map(i => i.Id)
                                customer = custdata
                                setcheckCustomerGroup(true)
                            }
                            let region = data[0].RegionLst.filter((i) => i.Selected == true).map(i => i.Name)
                            let RebateCreatedBy = data[0].RebateCreatedByLst.filter((i) => i.Selected == true).map(i => i.Id)
                            let SettlementFreq = data[0].SettlementFreqLst.filter((i) => i.Selected == true).map(i => i.Id)
                            let Products = []
                            data[0].ProductLst.forEach((each) => {
                                const pieces = each.split("_")
                                const last = pieces[pieces.length - 1]
                                Products.push(parseInt(last))
                            })
                            let id = data[0].Id;

                            setMassActionData({
                                ...massActionData,
                                ['Id']: data[0].Id, ['User_id']: parseInt(loggedInUser), ['CustomerGroup']: data[0].CustomerGroup,
                                ['Product']: Products,
                                ['Region']: region,
                                ['Customer']: customer,
                                ['SetlFreq']: SettlementFreq,
                                ['CreatedUser']: RebateCreatedBy,
                                ['Status']: data[0].Status,
                                ['CreatedBy']: data[0].CreatedBy,
                                ['CreatedDate']: moment(data[0].CreatedDate).format(SessionManage.getcurrentdateformat()),
                                ['Desc']: data[0].Desc,
                                ['RebateTypeId']: data[0].RebateTypeId,
                                ['massActionId']: data[0].massActionId
                            })
                            setrebateAgreements(data[0].massRenewAgreements[0].MassAgreement)

                            settotalCountlst(data[0].massRenewAgreements[0].MassAgreement.length)

                            if (data[0].massRenewAgreements[0].MassAgreement.length > 0) {

                                setRenewData({
                                    ...renewData,
                                    ['MassAgreement']: data[0].massRenewAgreements[0].MassAgreement,
                                    ['MassActionId']: id,

                                })


                                setshowRenew(true)
                            }
                            if (data[0].Status?.toLowerCase() == "new") {

                                setRenewCompleted(false);
                                if (data[0].Locked === false) {
                                    Service.LockUnLockEntity('Configurationprop/LockEntity', "MassAction", id, loggedInUser).then((res1) => {
                                        if (typeof res1 !== 'undefined') {
                                            setIsLocked(true);
                                            setLockedBy(loggedInUser);
                                        }
                                    })
                                }
                            }
                            else {
                                setRenewCompleted(true);
                            }
                            if ((data[0].Locked === true && data[0].Locked_byid.toString() != loggedInUser)) {
                                $("#libread").html(data[0].massActionId + " " + `Locked By ${data[0].LockedBy}`);
                            }
                            else {
                                $("#libread").html(data[0].massActionId);
                            }

                        }
                    }
                }
            }

        });
    }



    const OnChangeHandlerRebateType = (data) => {

        setSelectedOptionsRebateType(data.Id)
        setMassActionData({ ...massActionData, ['RebateTypeId']: data.Id })
    }




    const handleCustomerChanges = (e, label, id, State) => {

        let Selected = [];
        let update = [];
        const getallData = [...massActionData.Customer];

        let olddata = [...customerData];
        if (e.target.checked === true) {

            olddata.map((i) => {
                if (i.Id === id) {
                    i.Selected = true;
                }

            })
            data.map((i) => {
                if (i.Id === id) {
                    i.Selected = true;
                }
            })
            setData(data)

            getallData.push(id);

            setCustomerData(data);
            setMassActionData({ ...massActionData, ['Customer']: getallData })
        }
        else {
            olddata.map((i) => {
                if (i.Id === id) {
                    i.Selected = false;
                }
                update.push(i);
            })
            data.map((i) => {
                if (i.Id === id) {
                    i.Selected = false;
                }
            })
            setData(data)

            setMassActionData({ ...massActionData, ['Customer']: getallData.filter(i => i !== id) })
            setCustomerData(data);
        }
    }


    const getCustomerData = (method) => {

        Service.getdata(method).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {

                            setCustomerData(res.data)
                            setData(res.data)
                        }
                    }
                }
            }

        });
    }
    const getRegionData = (method) => {

        Service.getdata(method).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {

                            setSalesLevelData(res.data)

                        }
                    }
                }
            }

        });
    }

    const handleDistributorChanges = (e, label, id, State) => {
        let Selected = [];
        let update = [];
        const getallData = [...massActionData.Distributor];

        let olddata = [...distributorList];
        if (e.target.checked === true) {

            olddata.map((i) => {
                if (i.Id === id) {
                    i.Selected = true;
                }

            })

            data.map((i) => {
                if (i.Id === id) {
                    i.Selected = true;
                }
            })
            setData(data)

            getallData.push(id);

            setDistributorList(data);
            setMassActionData({ ...massActionData, ['Distributor']: getallData })
        }
        else {
            olddata.map((i) => {
                if (i.Id === id) {
                    i.Selected = false;
                }
                update.push(i);
            })

            data.map((i) => {
                if (i.Id === id) {
                    i.Selected = false;
                }
            })
            setData(data)

            setMassActionData({ ...massActionData, ['Distributor']: getallData.filter(i => i !== id) })
            setDistributorList(data);
        }
    }


    const FilterColumnCustomer = (name) => {
        var divtop1 = document.getElementById("Customer");
        var cardtop1 = document.getElementById("Customer");
        setdivtop(divtop1.offsetTop + cardtop1.offsetTop + 30)
        setCustomerFilterCol(name)
        setshowCustomerfilterpopup(true)

        if (name === 'Name') {
            setfiltertextCust("Customer Code")
            var testDiv = document.getElementById("Name");
            setdivleft((testDiv.offsetLeft + 30) + "px");
        }
        if (name === 'Label') {
            setfiltertextCust("Customer Name")
            var testDiv = document.getElementById("Label");
            setdivleft((testDiv.offsetLeft + 30) + "px");
        }

        if (name === 'Station') {
            setfiltertextCust("Station")
            var testDiv = document.getElementById("Station");
            setdivleft((testDiv.offsetLeft + 30) + "px");

        }
        if (name === 'State') {
            setfiltertextCust("State")
            var testDiv = document.getElementById("State");
            setdivleft((testDiv.offsetLeft + 30) + "px");

        }
        if (name === 'Zone') {
            setfiltertextCust("Zone")
            var testDiv = document.getElementById("Zone");
            setdivleft(((testDiv.offsetLeft + 30) - 100) + "px");
        }
        DropdownHandlerCus(name);
    }
    const DropdownHandlerCus = (key) => {

        Service.getfilterDropdownByGroup('Rebate/GetAllCustomerFilters', key).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.data !== 'null' && res.data !== 'undefined') {

                        addnewfieldCustomer(res.data, key)
                    }
                }
            }

        });
        // }
    }
    const addnewfieldCustomer = (data, key) => {

        let currentdata = data.map((i) => {
            i.Selected = false;
            return i;
        });
        let selectedKeys = filterValueCustomer[key];

        if (selectedKeys != '') {

            currentdata.forEach((each) => {
                selectedKeys.forEach((eachSelected) => {
                    if (each.Name == eachSelected.Name) {
                        each.Selected = true
                    }
                })
            })
        }
        setFilterCusDropDownData(currentdata);
        setfilterCustData(currentdata)
    }


    const clickShowSettelmentPopup = () => {
        setShowSettelmetnFreqpopup(true)
        setshowCreatedByPopup(false)
        setsidebarwidth("63vw");
    }
    const clickClosePopup = () => {
        setShowSettelmetnFreqpopup(false)
        setshowCreatedByPopup(false)
    }
    const clickShowCreatedByPopup = () => {
        setShowSettelmetnFreqpopup(false)
        setshowCreatedByPopup(true)
        setsidebarwidth("80vw");
        getData("Users/GetAll", 20, 1, "Descending", "Id", [], null);
    }
    const oksettlFreq = () => {
        setShowSettelmetnFreqpopup(false);
        setshowCreatedByPopup(false)
    }


    const getSettlFreqData = (method) => {
        Service.getAlldata(method).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                            res.data.forEach((each) => {
                                each['Selected'] = false;
                            })
                            setSettlementFreqData(res.data)
                        }
                    }
                }
            }

        });
    }
    const getCreatedBy = (method, massActionId, sortType, SortProperty, filterValue, search) => {

        Service.getAllUserData(method, massActionId, sortType, SortProperty, filterValue, search).then((res) => {

            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== null && res.data !== undefined) {
                            if (props.id == 0) {
                                res.data.DataList.forEach((each) => {
                                    each['Selected'] = false;
                                })
                            }
                            setUserData(res.data.DataList)
                            if (dataCreatedby.length == 0) {
                                setDataCreatedBy(res.data.DataList)
                            }

                        }
                    }
                }
            }

        });
    }





    const handleSettlFreqChanges = (e, label, id) => {
        let Selected = [];
        let updatedSetlFreq = [];
        const getallData = [...massActionData.SetlFreq]

        let olddata = [...SettlementFreqData];
        if (e.target.checked === true) {

            olddata.map((i) => {
                if (i.Id === id) {
                    i.Selected = e.target.checked;
                }
            })
            getallData.push(id)

            setSettlementFreqData(olddata)
            setMassActionData({ ...massActionData, ['SetlFreq']: getallData })

        }
        else {


            olddata.map((i) => {
                if (i.Id === id) {
                    i.Selected = e.target.checked;
                }
            })

            getallData.push(id)

            setSettlementFreqData(olddata)
            setMassActionData({ ...massActionData, ['SetlFreq']: getallData.filter((i => i !== id)) })

        }

    }

    const checkAllSettlFreqChange = (mycheckbox) => {
        var checkboxes = document.querySelectorAll("input[name = 'settlfreq-check']");
        if (mycheckbox.target.checked == true) {
            setsettlFreqcheckAll(true);
            checkboxes.forEach(function (checkbox) {
                checkbox.checked = true;
            });
            let getAllData = [...massActionData.SetlFreq]

            SettlementFreqData.map((i) => {
                i.Selected = true
                getAllData.push(i.Id)
            })

            setMassActionData({ ...massActionData, ['SetlFreq']: getAllData })
        }
        else {
            setsettlFreqcheckAll(false);
            checkboxes.forEach(function (checkbox) {
                checkbox.checked = false;
            });
            SettlementFreqData.map((i) => {
                i.Selected = false;
            })

            setMassActionData({ ...massActionData, ['SetlFreq']: [] })
        }
    }

    const getData = (method, pageSize, pageNumber, sortType, SortProperty, filterValue, search) => {
        Service.getDatawithMultiFilterSearch(method, pageSize, pageNumber, sortType, SortProperty, filterValue, search).then((res) => {
            if (typeof res !== 'undefined') {
                if (res.data !== 'null' && res.data !== 'undefined') {
                    res.data.DataList.forEach((each) => {
                        each['Selected'] = false;
                    })

                    LoadMore(res.data.DataList.length, res.data.Totalcount)
                }
            }
        });

    }
    const filterLst = () => {
        let list = [];
        for (var key in userMultiFilterValue) {
            if (userMultiFilterValue[key].length > 0) {
                let values = ""
                userMultiFilterValue[key].map((i) => {
                    values += i.Name + ";";
                })
                if (values !== null && values !== '') {
                    list.push({
                        filterKey: key,
                        FilterValue: values
                    })
                }
            }
        }

        for (var key in userFilterValueDict) {
            if (userFilterValueDict[key] !== null && userFilterValueDict[key] !== '') {
                list.push({
                    filterKey: key,
                    FilterValue: userFilterValueDict[key]
                })
            }
        }
        return list;
    }
    const SearchCreatedBy = (event) => {
        var sortType = columnAscending === true ? 'Ascending' : 'Descending';

        let pagenumber = 1;

        paging.pageNumber = pagenumber;
        setpaging(paging);
        if (event.target.value != '') {

            setSearchValue(String(event.target.value).toLowerCase())
            getCreatedBy("MassAction/GetALLUser", props.id, sortType, sortColName, filterLst(), event.target.value);
        }
        else {

            setSearchValue(null);
            getCreatedBy("MassAction/GetALLUser", props.id, sortType, sortColName, filterLst(), null);
        }
    }

    const UserSortColumn = (colName) => {
        var sortType = '';
        if (columnAscending === true) {
            setColumnAscending(false);
            sortType = "Descending";
        } else {
            setColumnAscending(true);
            sortType = "Ascending";
        }
        setSortColName(colName);

        let pagenumber = 1;

        paging.pageNumber = pagenumber;
        setpaging(paging);
        getCreatedBy("MassAction/GetALLUser", props.id, sortType, colName, filterLst(), searchValue);
    }


    const UserFilterColumn = (name) => {

        const scrollTop = window.scrollY;
        const scrollTopActual = scrollTop >= 60 ? scrollTop - 85 : 0;
        var divtop1 = document.getElementById("tableA");
        setdivtop(divtop1.offsetTop + scrollTopActual + 30)
        setshowfilterpopup(true)
        setFilterCol(name)

        if (name === 'firstname') {
            setfiltertext(t('FirstName'))
            var testDiv = document.getElementById("firstname");
            setdivleft(testDiv.offsetLeft + 20 + "px");
        }
        if (name === 'lastname') {
            setfiltertext(t('LastName'))
            var testDiv = document.getElementById("lastname");
            setdivleft(testDiv.offsetLeft + 20 + "px");
        }

        if (name === 'username') {
            setfiltertext(t('UserName'))
            var testDiv = document.getElementById("username");
            setdivleft(testDiv.offsetLeft + 20 + "px");
        }
        if (name === 'email') {
            setfiltertext('Email')
            var testDiv = document.getElementById("email");
            setdivleft(testDiv.offsetLeft + 20 + "px");
        }
        if (name === 'roles') {
            setfiltertext('Roles')
            var testDiv = document.getElementById("roles");
            setdivleft(testDiv.offsetLeft + 20 + "px");
        }
        if (name === 'status') {
            setfiltertext(t('IsActive'))
            var testDiv = document.getElementById("status");
            setdivleft(testDiv.offsetLeft + 20 + "px");
        }
        if (name === 'userstype') {
            setfiltertext(t('UserType'))
            var testDiv = document.getElementById("userstype");
            setdivleft(testDiv.offsetLeft + 20 + "px");
        }
    }
    const checkAllUser = (mycheckbox) => {
        var checkboxes = document.querySelectorAll("input[name = 'chkBoxUser']");
        if (mycheckbox.target.checked == true) {
            checkboxes.forEach(function (checkbox) {
                checkbox.checked = true;
            });
            let ids = []
            UserData.map((i) => {
                i.Selected = true
                ids.push(i.Id)
            })
            setMassActionData({ ...massActionData, ['CreatedUser']: ids })
        }
        else {
            checkboxes.forEach(function (checkbox) {
                checkbox.checked = false;
            });
            setMassActionData({ ...massActionData, ['CreatedUser']: [] })
        }

    }

    const onChangeUser = (e, id, name) => {
        let olddata = [...UserData];
        let getAllData = [...massActionData.CreatedUser]
        if (e.target.checked === true) {

            olddata.map((i) => {
                if (i.Id === id) {
                    i.Selected = e.target.checked;
                }

            })
            dataCreatedby.map((i) => {
                if (i.Id === id) {
                    i.Selected = e.target.checked;
                }

            })
            getAllData.push(id)

            setUserData(dataCreatedby)
            setMassActionData({ ...massActionData, ['CreatedUser']: getAllData })

        }
        else {
            olddata.map((i) => {
                if (i.Id === id) {
                    i.Selected = e.target.checked;
                }

            })
            dataCreatedby.map((i) => {
                if (i.Id === id) {
                    i.Selected = e.target.checked;
                }

            })

            setUserData(dataCreatedby)
            getAllData.push(id)
            setMassActionData({ ...massActionData, ['CreatedUser']: getAllData.filter((i => i.Id !== id)) })

        }
    }

    const [dropdownData, setDropdownData] = useState([]);
    const [filterdropdownData, setFilterDropdownData] = useState([]);

    const UserfilterHandler = (e) => {

        setFilterSearch(e.target.value)
        if (e.target.value !== "") {
            let searchResults = dropdownData.filter((each) => {
                return each.Name.toLowerCase().includes(String(e.target.value).toLowerCase())
            });
            setDropdownData(searchResults);
        }
        else {

            setFilterSearch("")
            setDropdownData(filterdropdownData)
        }
    }
    const OndropdownChange = (e, id, name) => {
        let isChecked = e.target.checked;
        var lastfilter = [...dropdownData]
        if (isChecked === true) {
            let currentdata = lastfilter.map((i) => {
                if (i.Id == id) {
                    i["ischecked"] = true;
                }
                return i;
            });
            setDropdownData(currentdata);
            let data1 = []
            const getallData = {
                Name: name,
            }
            data1.push(getallData)
            setFilterValue(filterValue => [...filterValue, ...data1]);
            let updateddata = ([...filterValue, ...data1]);
            setUserMultiFilterValue(() => ({
                ...userMultiFilterValue, [filterCol]: updateddata,
            }));
        }
        else {
            let currentdata = lastfilter.map((i) => {
                if (i.Id === id) {
                    i["ischecked"] = false;
                }
                return i;
            });

            setDropdownData(currentdata);
            setFilterValue(filterValue => filterValue.filter(data => data.Name !== name))
            let data1 = userMultiFilterValue.filter(data => data.Name !== name);
            setUserMultiFilterValue(() => ({
                ...userMultiFilterValue, [filterCol]: data1,
            }));
        }
    }
    const filterInput = (e) => {

        let inputValue = e.target.value

        setLineItemFilterValue(inputValue)
        setFilterValue(inputValue)

        setLineItemFilterValue(() => ({
            ...lineItemFilterValue, [filterCol]: e.target.value,
        }));

        setUserFilterValueDict(() => ({
            ...userFilterValueDict, [filterCol]: e.target.value,
        }));
    }


    const filterHandler = (flag) => {

        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
        let pagenumber = 1;
        paging.pageNumber = pagenumber;

        if (flag === 'Apply') {
            setshowfilterpopup(false)
            let list = [];

            for (var key in userMultiFilterValue) {
                if (userMultiFilterValue[key].length > 0) {
                    let values = ""
                    userMultiFilterValue[key].map((i) => {
                        values += i.Name + ";";
                    })
                    if (values !== null && values !== '') {
                        list.push({
                            filterKey: key,
                            FilterValue: values
                        })
                    }
                }
            }

            for (var key in userFilterValueDict) {
                if (userFilterValueDict[key] !== null && userFilterValueDict[key] !== '') {
                    list.push({
                        filterKey: key,
                        FilterValue: userFilterValueDict[key]
                    })
                }
            }
            if (list.length > 0) {
                setpaging(paging);
                getCreatedBy("MassAction/GetALLUser", props.id, "Descending", "Id", list, searchValue);


            }

        }
        else {
            setshowfilterpopup(false)
            setUserMultiFilterValue(() => ({
                ...userMultiFilterValue, [filterCol]: '',
            }));
            setUserFilterValueDict(() => ({
                ...userFilterValueDict, [filterCol]: '',
            }));

            let list = [];
            for (var key in userMultiFilterValue) {
                if (key !== filterCol && userMultiFilterValue[key].length > 0) {
                    let values = ""
                    userMultiFilterValue[key].map((i) => {
                        values += i.Name + ";";
                    })
                    if (values !== null && values !== '') {
                        list.push({
                            filterKey: key,
                            FilterValue: values
                        })
                    }
                }
            }

            for (var key in userFilterValueDict) {
                if (userFilterValueDict[key] !== null && userFilterValueDict[key] !== '' && key !== filterCol) {
                    list.push({
                        filterKey: key,
                        FilterValue: userFilterValueDict[key]
                    })
                }
            }
            setpaging(paging);
            getCreatedBy("MassAction/GetALLUser", props.id, sortType, sortColName, list, searchValue);
            setFilterCol('')
            setFilterValue('')
            setDropdownData([])
            setFilterSearch("")
            setFilterDropdownData([])
        }
    }
    const LoadMore = (lst, totalcount) => {

        if (lst < totalcount) {
            setUserLoadMore(true);
        }
        else if (lst >= totalcount) {
            setUserLoadMore(false);
        } else {
            setUserLoadMore(false);
        }

    }


    const HandleInputChange = (e) => {
        let value = e.target.value;
        setMassActionData({ ...massActionData, ['Desc']: value })
    }

    const handleData = (e) => {
        setMassActionData({
            ...massActionData, ['Id']: 0, ['User_id']: 0, ['CustomerGroup']: false, ['Product']: []
            , ['Region']: [], ['Customer']: [], ['SetlFreq']: [], ['CreatedUser']: [], ['CreatedDate']: '', ['Desc']: '', ['RebateTypeId']: 0, ['Status']: [],
        })
    }

    const closeHandler = (e) => {
        handleData()
        UnlockEntity(props.id)
        navigate("/MassActionSubmitList")

    }
    const SaveHandler = (e) => {
        massActionData.CreatedDate = null
        Service.databyMassActions("MassAction/CreateOrUpdate", massActionData).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    let rData = res.data;
                    if (res.data.Code === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                            if (res.data.Code == 200) {
                                let id = parseInt(res.data.Status)
                                getMassActionById("MassAction/GetByID", id)
                                toast.success('Saved Successfully !', {
                                    position: toast.POSITION.TOP_RIGHT,
                                });

                                navigate("/MassActionSubmitList/new/" + id);
                            }
                            else {
                                setErrorMessage(res.data.ErrorMessage);
                                setErrorStatus("Error")
                                setShowErrorModal(true)
                            }
                        }
                    }
                }
            }
        });
    }

    const SearchHandler = (e) => {
        massActionData.CreatedDate = null
        Service.databyMassActions("MassAction/SearchAgreementsMassSubmit", massActionData).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    let rData = res.data;
                    if (res.data.DataList !== 'null' && res.data.DataList !== 'undefined' && res.data.DataList.length > 0) {

                        toast.success('Search Completed !', {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                        settotalCountlst(res.data.DataList.length)
                        setrebateAgreements(res.data.DataList)
                        setshowRenew(true);
                        navigate("/MassActionSubmitList/new/" + res.data.DataList[0].MassActionId);
                        setRenewData({ ...renewData, ['MassAgreement']: res.data.DataList })
                    }
                    else {
                        setErrorMessage("No Agreements Available ");
                        setErrorStatus("Error")
                        setShowErrorModal(true)
                        setrebateAgreements([])
                    }
                }
            }
        })
    }




    const OnChangeHandlerStartDate = (e) => {
        let validityfrom = e
        let date = moment(moment(validityfrom).toDate()).format("YYYY-MM-DD");

        var validfrom = new Date(e);
        validfrom.setHours(validfrom.getHours() + 5);
        validfrom.setMinutes(validfrom.getMinutes() + 30);
        setRenewData({ ...renewData, ['validityfrom']: validfrom });

    };
    const OnChangeHandlerEndDate = (e) => {
        let date = moment(moment(e).toDate()).format("YYYY-MM-DD");


        if (moment(e).isBefore(renewData.validityfrom)) {
            setErrorMessage(`${t('Rebates.Validations.RenewValidToCannotStartBeforeRenewValidFrom')}`);
            setErrorStatus("Error")
            setShowErrorModal(true)
        }
        else {
            var validto = new Date(e);
            validto.setHours(validto.getHours() + 5);
            validto.setMinutes(validto.getMinutes() + 30);
            setRenewData({ ...renewData, ['validityto']: validto });
        }
    };


    const excludeRpl = (e, id, status, programlineId) => {
        let ag = renewData.MassAgreement;


        ag.forEach((each) => {
            if (each.ProgramlineId == programlineId) {
                each.Excluded = e.target.checked

            }
        })


        setRenewData(renewData)
    }



    const SubmitHandler = (e) => {

        setLoading(true);
        renewData.submit_comments = commentsForSubmit;
        renewData.MassActionId = massActionData.Id;
        ;
        Service.databyMassActions("MassAction/MassSubmitAgreement", renewData).then((res) => {

            if (typeof res !== 'undefined') {

                setShowSubmitModal(!showSubmitModal)
                setCommentsForSubmit('')
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    let rData = res.data;
                    if (res.data.Code == 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                            if (res.data.Status != 'Failure') {

                                let id = parseInt(res.data.Status)
                                setLoading(false);
                                toast.success('Submit Completed !', {
                                    position: toast.POSITION.TOP_RIGHT,
                                });
                                getMassActionById("MassAction/GetByID", renewData.MassActionId)
                            }
                            else {
                                setLoading(false);
                                toast.success('Submit Completed with errors !', {
                                    position: toast.POSITION.TOP_RIGHT,
                                });
                                setErrorMessage(res.data.ErrorMessage);
                                setErrorStatus("Error")
                                setShowErrorModal(true)
                                getMassActionById("MassAction/GetByID", renewData.MassActionId)
                            }
                        }
                    }
                }

            }
        });
    }


    const FilterColumn = (name) => {

        const scrollTop = window.scrollY;
        const scrollTopActual = scrollTop >= 60 ? scrollTop - 85 : 0;
        var divtop1 = document.getElementById("table-Agreement");
        setdivtop1(divtop1.offsetTop + scrollTopActual + 205)
        setshowLineItemfilterpopup(true)
        setFilterCol(name)
        if (name === 'rebateid') {
            setfiltertext(t('MassAction.OldAgreementId'))
            var testDiv = document.getElementById("rebateid");
            setdivleft1(testDiv.offsetLeft + 80 + "px");

        }
        if (name === 'rebateprogramlineid') {
            setfiltertext(t('MassAction.OldProgramLineId'))
            var testDiv = document.getElementById("rebateprogramlineid");
            setdivleft1(testDiv.offsetLeft + 80 + "px");
        }
        if (name === 'validityfrom') {
            setfiltertext(t('MassAction.Oldvalidityfrom'))
            var testDiv = document.getElementById("validityfrom");
            setdivleft1(testDiv.offsetLeft + 80 + "px");
        }
        if (name === 'validityto') {
            setfiltertext(t('MassAction.Oldvalidityto'))
            var testDiv = document.getElementById("validityto");
            setdivleft1(testDiv.offsetLeft + 80 + "px");
        }
        if (name === 'operationstatus') {
            setfiltertext(t('MassAction.OperationStatus'))
            var testDiv = document.getElementById("operationstatus");
            setdivleft1(testDiv.offsetLeft + 80 + "px");
        }
        if (name === 'errormessage') {
            setfiltertext(t('MassAction.ErrorMessage'))
            var testDiv = document.getElementById("errormessage");
            setdivleft(testDiv.offsetLeft + 20 + "px");
        }
    }


    const filterHandlerLineItem = (flag) => {

        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
        let pagenumber = 1;
        paging.pageNumber = pagenumber;

        if (flag === 'Apply') {
            setshowLineItemfilterpopup(false)
            let list = [];


            for (var key in lineItemFilterValue) {
                if (lineItemFilterValue[key] !== null && lineItemFilterValue[key] !== '') {
                    list.push({
                        filterKey: key,
                        FilterValue: lineItemFilterValue[key]
                    })
                }
            }
            if (list.length > 0) {
                setpaging(paging);
                getMassActionViewData("MassAction/GetMassSubmitAgreements", paging.pageSize, pagenumber, sortType, sortColName, list, searchValue, massActionData.MassactionFor);
            }
        }
        else {
            setshowLineItemfilterpopup(false)

            setLineItemFilterValue(() => ({
                ...lineItemFilterValue, [filterCol]: '',
            }));

            let list = [];

            for (var key in lineItemFilterValue) {
                if (lineItemFilterValue[key] !== null && lineItemFilterValue[key] !== '' && key !== filterCol) {
                    list.push({
                        filterKey: key,
                        FilterValue: lineItemFilterValue[key]
                    })
                }
            }
            setpaging(paging);
            getMassActionViewData("MassAction/GetMassSubmitAgreements", paging.pageSize, pagenumber, sortType, sortColName, list, searchValue, massActionData.MassactionFor);
            setFilterCol('')
            setFilterValue('')
            setDropdownData([])
            setFilterSearch("")
            setFilterDropdownData([])
        }
    }

    const showSubmitDialog = (flag) => {
        if (flag === 'showSubmitDialog') {
            setShowSubmitModal(!showSubmitModal)
        }
        else if (flag === 'proceed') {
            setShowSubmitModal(false)
            SubmitHandler()

        }
    }
    const getMassActionViewData = (method, pageSize, pageNumber, sortType, SortProperty, filterValue, search) => {

        Service.getDatawithMultiFilterSearchwithId(method, massActionData.Id, pageSize, pageNumber, sortType, SortProperty, filterValue, search).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {

                            let rowData = res.data.DataList
                            setrebateAgreements(rowData)

                            settotalCountlst(res.data.Totalcount)
                            setshowspinner(false);
                            LoadMore(rowData.length, res.data.Totalcount)
                        }
                    }
                }
            }
        });
    }
    const SortColumn = (colName) => {
        var sortType = '';
        if (columnAscending === true) {
            setColumnAscending(false);
            sortType = "Descending";
        } else {
            setColumnAscending(true);
            sortType = "Ascending";
        }
        setSortColName(colName);

        let pagenumber = 1;

        paging.pageNumber = pagenumber;
        setpaging(paging);
        getMassActionViewData("MassAction/GetMassSubmitAgreements", paging.pageSize, paging.pageNumber, sortType, colName, filterLst(), searchValue, massActionData.MassactionFor);
    }
    const clickShowSelectedCreatedBy = (val) => {

        let lstcopy = UserData.filter((e) => e.Selected == true).map(item => {
            return { ...item }
        })

        let lst1 = [];
        setUserDataSelected([...lstcopy])




        setshowAccuralWorkflow(false);
        setshowproductpopup(false);
        setshowregionpopup(false);
        setshowCustomerpopup(false);
        setshowCustomerpopup(false);
        setshowCustomerGroupPopup(false);
        setShowSelectedCustomerGroupPopup(false);
        setShowSelectedCustomerpopup(false);
        setShowSelectedRegionpopup(false);
        setShowSelectedProductpopup(false);
        setshowCreatedByPopup(false);
        setShowSelectedCreatedBy(true);
        setShowSelectedSettlFreq(false);
        setsidebarwidth("63vw");

    }
    const handleUserSelectedChanges = (e, label, id, State) => {


        let lists = [];

        let olddata = UserDataSelected;
        if (e.target.checked === true) {

            olddata.map((i) => {
                if (i.Id === id) {
                    i.Selected = true;
                }
                lists.push(i);
            })


        }
        else {
            olddata.map((i) => {
                if (i.Id === id) {
                    i.Selected = false;
                }
                lists.push(i);
            })

        }
        setUserDataSelected(lists)

    }
    const handleSetlFreqSelectedChanges = (e, label, id, State) => {


        let lists = [];

        let olddata = SettlFreqDataSelected;
        if (e.target.checked === true) {

            olddata.map((i) => {
                if (i.Id === id) {
                    i.Selected = true;
                }
                lists.push(i);
            })


        }
        else {
            olddata.map((i) => {
                if (i.Id === id) {
                    i.Selected = false;
                }
                lists.push(i);
            })

        }
        setSettlFreqDataSelected(lists)

    }
    const clickShowSelectedSettlFreq = (val) => {

        let lstcopy = SettlementFreqData.filter((e) => e.Selected == true).map(item => {
            return { ...item }
        })

        let lst1 = [];
        setSettlFreqDataSelected([...lstcopy])




        setshowAccuralWorkflow(false);
        setshowproductpopup(false);
        setshowregionpopup(false);
        setshowCustomerpopup(false);
        setshowCustomerpopup(false);
        setshowCustomerGroupPopup(false);
        setShowSelectedCustomerGroupPopup(false);
        setShowSelectedCustomerpopup(false);
        setShowSelectedRegionpopup(false);
        setShowSelectedProductpopup(false);
        setshowCreatedByPopup(false);
        setShowSelectedCreatedBy(false);
        setShowSelectedSettlFreq(true);
        setsidebarwidth("63vw");

    }
    const UnlockEntity = (id) => {
        Service.LockUnLockEntity('Configurationprop/UnLockEntity', "MassAction", id, loggedInUser).then((res) => {

        });
    }
    console.log('6/12', renewCompleted);
    console.log('6/12', isLocked);
    console.log('6/12', lockedBy);
    if (renewCompleted == false && (isLocked === false || (isLocked === true && lockedBy == loggedInUser))) {
        disableField = false;
    }

    else {
        disableField = true;
    }



    const getdataByGroup = (method) => {
        Service.getdataByGroupAndType(method, '').then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined') {
                    if (typeof res.data.settlementMethodsList !== 'undefined')
                        setSettlementMethodsList(res.data.settlementMethodsList);
                    if (typeof res.data.distributorList !== 'undefined')
                        setDistributorList(res.data.distributorList);
                    setData(res.data.distributorList)
                    if (typeof res.data.currencyList !== 'undefined')
                        setCurrencyList(res.data.currencyList);
                    if (typeof res.data.productList !== 'undefined')
                        setProductList(res.data.productList);
                    if (typeof res.data.statusList !== 'undefined')
                        setStatusList(res.data.statusList);
                    if (typeof res.data.countryList !== 'undefined')
                        setSalesLevelData(res.data.countryList)
                    else {

                        var NewItem = res.data.statusList.filter((item) => item.label === 'New');
                        if (NewItem.length > 0) {
                            setInputField((prevState) => ({
                                ...prevState,
                                ["StatusId"]: NewItem[0].value,
                            }));
                            setInputField((prevState) => ({
                                ...prevState,
                                ["Status"]: NewItem[0].label,
                            }));

                        }
                    }
                }
            }
        });
    }

    console.log("14/12", distributorList)
    console.log("14/12", massActionData)
    console.log("14/12", renewData)


    return (
        <>
            <div className="container-fluid content-top-gap">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb my-breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">{t('Home.title')}</Link></li>

                        <li className="breadcrumb-item active" aria-current="page"><Link to="/MassActionSubmitListClaims">{t('MassAction.MassActionSubmitListClaims')}</Link></li>
                        <li className="breadcrumb-item active" id="libread" aria-current="page">{t('MassAction.CreateNewMassActionSubmitClaims')}</li>
                    </ol>
                </nav>
                {loading ? <><div className="loader-container"><div className="spinner"></div> </div> </> : ""}
                <div className="row">
                    <div className="col-lg-12 pr-lg-2">
                        <div className="card text-center card_border">
                            <div className="card-header chart-grid__header">
                                <div className="row">
                                    <div className="col-md-7 text-left">
                                        {t('MassAction.MassActionSubmitClaimHeader')}
                                    </div>
                                    <div className="col-md-5">
                                        {renewCompleted == false && disableField == false ? <>
                                            <button className="btn  btn-update btn-sm ml-1" style={{ width: "50px", float: "right" }} type="submit" onClick={(event) => showSubmitDialog('showSubmitDialog')}>{t('Submit')}</button>
                                            <button className="btn  btn-update btn-sm ml-1" style={{ width: "50px", float: "right" }} onClick={(e) => SearchHandler(e)} type="submit">{t('Search')}</button>
                                            <button className="btn  btn-update btn-sm ml-1" style={{ width: "40px", float: "right" }} onClick={(e) => SaveHandler(e)} type="submit">{t('Save')}</button>
                                            <button className="btn  btn-update btn-sm ml-1" style={{ width: "45px", float: "right" }} onClick={(e) => closeHandler(e)} type="submit">{t('Close')}</button>

                                        </>
                                            : <>
                                                <button className="btn  btn-update btn-sm ml-1" style={{ width: "45px", float: "right" }} onClick={(e) => closeHandler(e)} type="submit">{t('Close')}</button>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="row mt-1">
                    <div className="col-lg-4 col-md-6 col-sm-4" >
                        <div className="row">
                            <div className="col-lg-12 pr-lg-2">
                                <div className="card text-center card_border">
                                    <div className="card-body">
                                        <div className="container">
                                            <div className="row">
                                                <label className="col-md-6 text-left">{t('MassAction.MassActionId')}</label>
                                                <input disabled={true} name="MassActionId" value={massActionData.massActionId} type="text" className="form-control col-md-6 textbox-padding" />
                                                <label className="col-md-6 text-left">{t('Rebates.Description')}</label>
                                                <input name="Description" disabled={disableField} onChange={(e) => HandleInputChange(e)} type="text" value={massActionData.Desc} className="form-control col-md-6 textbox-padding mt-2" />

                                                {checkCustomerGroup === false ?
                                                    <>
                                                        <label className="col-md-6 mt-2 text-left">{t('MassAction.Distributor')}</label>
                                                        <div className={`col-md-4 textbox-padding-dropdown mt-2 fontsizedropdown ${true}  `} >
                                                            <button className="btn btn-outline-dark  btn-sm" onClick={clickShowCustomerPopup} style={{ width: 50 + "px" }} type="submit">{t('Select')}</button>
                                                            &nbsp; <a href='javascript:void(0)' onClick={clickSelectedShowCustomerPopup}  ><label style={{ paddingTop: "5px" }}>({distributorList.filter(i => i.Selected == true).length})</label></a>
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        <label className="col-md-6 mt-2 text-left">{t('MassAction.Distributor')}</label>

                                                        <div className={`col-md-4 textbox-padding-dropdown mt-2 fontsizedropdown ${true}`}>
                                                            <button className="btn btn-outline-dark  btn-sm" onClick={clickGroupCustomerPopup} style={{ width: 50 + "px" }} type="submit">{t('Select')}</button>
                                                            &nbsp; <a href='javascript:void(0)' onClick={clickSelectedShowCustomerGroupPopup}  ><label style={{ paddingTop: "5px" }}>({distributorList.filter(i => i.Selected == true).length})</label></a>
                                                        </div>
                                                    </>
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-4">
                        <div className="row">
                            <div className="col-lg-12 pr-lg-2">
                                <div className="card text-center card_border">

                                    <div className="card-body" >
                                        <div className="container">
                                            <div className="row">


                                                <label className="col-md-7 mt-2 text-left">{t('MassAction.SettlementFrequency')}</label>
                                                <div className={`col-md-5 textbox-padding-dropdown text-right mt-2 fontsizedropdown`}>
                                                    <button className="btn btn-outline-dark btn-sm" onClick={clickShowSettelmentPopup} style={{ width: 50 + "px" }} type="submit">{t('Select')}</button>
                                                    &nbsp;
                                                    <a href='javascript:void(0)' onClick={clickShowSelectedSettlFreq}><label style={{ paddingTop: "5px" }}>({SettlementFreqData.filter(i => i.Selected == true).length}) </label></a>
                                                </div>
                                                <label className="col-md-7 mt-2 text-left">{t('MassAction.Region')}</label>
                                                <div className={`col-md-5 textbox-padding-dropdown text-right  mt-2 fontsizedropdown ${true}`}>
                                                    <button className="btn btn-outline-dark btn-sm  btn-sm" onClick={clickShowregionPopup} style={{ width: 50 + "px" }} type="submit">{t('Select')}</button>
                                                    &nbsp;
                                                    <a href='javascript:void(0)' onClick={clickSelectedShowRegionPopup}><label style={{ paddingTop: "5px" }}>({SalesLevelData.filter(i => i.Selected == true).length})</label></a>
                                                </div>

                                                <label className="col-md-7 mt-2 text-left">{t('MassAction.Product')}</label>
                                                <div className={`col-md-5 textbox-padding-dropdown text-right  mt-2 fontsizedropdown ${true}`}>
                                                    <button className="btn btn-outline-dark  btn-sm btn-sm " onClick={clickShowProductPopup} style={{ width: 50 + "px" }} type="submit">{t('Select')}</button>
                                                    &nbsp;

                                                    <a href='javascript:void(0)' onClick={clickSelectedShowProductPopup}><label style={{ paddingTop: "5px" }}>({FiltedProducts.length})</label></a>
                                                </div>

                                                <label className="col-md-7 mt-2 text-left">{t('MassAction.RebateCreator')}</label>
                                                <div className={`col-md-5 mt-3 textbox-padding-dropdown text-right  mt-2 fontsizedropdown`}>
                                                    <button className="btn btn-outline-dark  btn-sm btn-sm" onClick={clickShowCreatedByPopup} style={{ width: 50 + "px" }} type="submit">{t('Select')}</button>
                                                    &nbsp;
                                                    <a href='javascript:void(0)' onClick={clickShowSelectedCreatedBy}><label style={{ paddingTop: "5px" }}>({UserData.filter(i => i.Selected == true).length})</label></a>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-4">
                        <div className="row">
                            <div className="col-lg-12 pr-lg-2">
                                <div className="card text-center card_border">
                                    <div className="card-body" >
                                        <div className="container">
                                            <div className="row">

                                                <label className="col-md-6 mt-2 text-left" style={{ paddingBottom: "10px" }}>{t('Rebates.RebateType')}</label>
                                                <Select
                                                    className={`col-md-6 textbox-padding mt-2 fontsizedropdown`}
                                                    options={rebateTypeData}
                                                    placeholder="Select"
                                                    value={rebateTypeData.filter(function (option) {
                                                        return option.value === `${selectedOptionsRebateType}`;;
                                                    })}

                                                    onChange={OnChangeHandlerRebateType}
                                                    isSearchable={true}
                                                    styles={customStyles}
                                                    isDisabled={disableField}
                                                />

                                                <label className="col-md-6 mt-2 text-left">{t('CreatedDate')}</label>

                                                <input type="text" className="col-md-6 mt-2 form-control textbox-padding datetimepicker-input" data-target="#reservationdate" disabled={true}
                                                    name="createdDate" value={massActionData.CreatedDate} />


                                                <label className="col-md-6 mt-2 text-left">{t('CreatedBy')}</label>
                                                <input disabled={true} name="CreatedBy" type="text" value={massActionData.CreatedBy} className="col-md-6 form-control mt-2 textbox-padding" />
                                                <label className="col-md-6 mt-2 text-left">{t('Status')}</label>
                                                <input disabled={true} name="Status" type="text" value={massActionData.Status} className="col-md-6 form-control mt-2 textbox-padding" />



                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-3" id="card-RPL">
                    <div className="col-lg-12 pr-lg-2">


                        <div className="card text-center card_border mt-3">
                            <div className="card-header chart-grid__header">
                                <div className="row">
                                    <div className="col-md-7 text-left">
                                        {t('MassAction.MassActionSubmitClaimSearchResults')}
                                    </div>

                                    <div class="col-md-5">

                                    </div>
                                </div>
                            </div>

                            <div className="card-body" style={{ minHeight: "35vh" }}>
                                <div className="table-responsive-lg">
                                    <table className="table table-bordered  text-left mt-2" id="table-Agreement">
                                        <thead style={{ backgroundColor: "var(--primary)", color: "white" }}>
                                            <tr>
                                                <th className="tableheader" style={{ minWidth: "30px" }}>{t('Exclude')}</th>

                                                <th id="rebateid" style={{ backgroundColor: (lineItemFilterValue.rebateid ? '#6CAE55' : ''), minWidth: "170px" }}>
                                                    {t('MassAction.OldAgreementId')}
                                                    <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('RebateId')}></span>
                                                    <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('rebateid')}></span>
                                                </th>
                                                <th id="RebateProgramLineId" style={{ backgroundColor: (lineItemFilterValue.RebateProgramLineId ? '#6CAE55' : ''), minWidth: "190px" }}>
                                                    {t('MassAction.OldProgramLineId')}
                                                    <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('RebateProgramLineId')}></span>
                                                    <span className='fa fa-filter' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('RebateProgramLineId')}>
                                                    </span></th>
                                                <th id='Validity_From' style={{ backgroundColor: (lineItemFilterValue.Validity_From ? '#6CAE55' : ''), minWidth: "110px" }}>
                                                    {t('MassAction.Oldvalidityfrom')}<span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('Validity_From')}></span>
                                                    <span className='fa fa-filter' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('validityfrom')}></span>
                                                </th>
                                                <th id='Validity_To' style={{ backgroundColor: (lineItemFilterValue.Validity_To ? '#6CAE55' : ''), minWidth: "110px" }}>
                                                    {t('MassAction.Oldvalidityto')}<span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('Validity_To')}></span>
                                                    <span className='fa fa-filter' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('Validity_To')}></span>
                                                </th>
                                                <th id="OperationStatus" style={{ backgroundColor: (lineItemFilterValue.OperationStatus ? '#6CAE55' : ''), minWidth: "130px" }} >
                                                    {t('MassAction.OperationStatus')}
                                                    <span className='fa fa-filter' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('OperationStatus')}></span>
                                                </th>
                                                <th id="ErrorMessage" className="tableheader" style={{ backgroundColor: (lineItemFilterValue.ErrorMessage ? '#6CAE55' : ''), minWidth: "110px" }} >
                                                    {t('MassAction.ErrorMessage')}
                                                    <span className='fa fa-filter' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('ErrorMessage')}></span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                showspinner === true ?

                                                    <>
                                                        <Spinner animation="grow" size="sm" variant="success" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                        <Spinner animation="grow" size="sm" variant="danger" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                        <Spinner animation="grow" size="sm" variant="warning" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                    </>
                                                    :
                                                    rebateAgreements.length === 0 ?
                                                        <tr className='text-center'>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td>{t('NoDataAvailable')}</td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                        :
                                                        rebateAgreements.map((line, index) =>
                                                            <tr>
                                                                <td >
                                                                    <input id={line.Id} type="checkbox" name='chkBox' value={[line.Id, line.Status, line.ProgramlineId]} onClick={(e) => excludeRpl(e, line.Id, line.Status, line.ProgramlineId)} checked={line.Excluded} />
                                                                </td>
                                                                <td style={{ maxWidth: "110px" }}>{line.Id != null ? <Link to={`/new-rebate/${line.Id}/fromMassActionSubmit/0`}>{line.RebateId}</Link> : ''}</td>
                                                                <td style={{ maxWidth: "110px" }}>{line.ProgramlineId != null ? <Link to={`/new-rebate/${line.Id}/fromMassActionSubmit/${line.ProgramlineId}`}>{line.RebateProgramLineId}</Link> : ''}</td>
                                                                <td>{moment(line.Validity_From).format(SessionManage.getcurrentdateformat())}</td>
                                                                <td>{moment(line.Validity_To).format(SessionManage.getcurrentdateformat())}</td>
                                                                <td style={{ maxWidth: "100px" }}>{line.OperationStatus}</td>
                                                                <td>{line.ErrorMessage}</td>
                                                            </tr>
                                                        )

                                            }

                                        </tbody>


                                    </table>
                                </div>
                                {
                                    showLoadMore === true ?
                                        <div className='text-center container row'>
                                            <div className='col-md-5'></div>
                                            <div className='col-md-2'>
                                                <button className='btn btn-sm btn-update ml-1' style={{ width: "70px" }}>{t('LoadMore')}</button>
                                            </div>
                                            <div className='col-md-5'></div>

                                        </div>
                                        :
                                        ""
                                }
                                {
                                    showCustomerpopup === true ?
                                        <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, marginRight: "10px", padding: "0" }}>
                                            <div className="container" style={{ padding: "0" }}>
                                                <div className="row">
                                                    <div className="col-lg-12 pr-lg-2">
                                                        <div className="card text-center card_border">
                                                            <div className="card-header chart-grid__header">
                                                                <div className="row">
                                                                    <div className="col-md-10 text-left">
                                                                        {t('Rebates.DistributorListing')}
                                                                    </div>
                                                                    <div className="col-md-2">
                                                                        <button className="btn btn-outline-dark  btn-sm ml-1" onClick={clickCloseAccuralWorkflow} type="submit" style={{ width: "45px" }}>{t('Close')}</button>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row mt-1" id="Distributor">
                                                    <div className="col-lg-12 pr-lg-2">
                                                        <div className="card text-center card_border">
                                                            <div className="card-header chart-grid__header">

                                                            </div>

                                                            <div className='scrollbar-class'>

                                                                <div className="container">
                                                                    <div>

                                                                        <input type="text" className='form-control form-control-user' value={distributorSearch} onChange={(event) => SearchDistributor(event)} placeholder={`${t('Search')} ${t('Rebates.DistributorName')}`} />
                                                                    </div>
                                                                    <table className="table table-bordered text-left mt-2 " id="Distributor">
                                                                        <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                                                                            <tr>
                                                                                <th>
                                                                                    <input checked={distributorcheckAll} type="checkbox" disabled={disableField} id="distributor-check" onChange={checkAllDistributor} /><label></label>
                                                                                </th>
                                                                                <th id="DistributorName" style={{ backgroundColor: (filterValueDistributor.Name ? '#6cae55' : ''), width: "130px" }}>{t('Rebates.DistributorName')} <span className='fa fa-filter' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumnCustomer('DistributorName')}></span></th>
                                                                                <th id="CountryName" style={{ backgroundColor: (filterValueDistributor.Label ? '#6cae55' : ''), width: "140px" }}>{t('Rebates.CountryName')} <span className='fa fa-filter' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumnCustomer('CountryName')}></span></th>
                                                                            </tr>
                                                                        </thead>
                                                                        <thead>

                                                                            {
                                                                                distributorList.map((obj) => (

                                                                                    <tr onClick={(event) => handleDstSelection(event, obj.label, obj.value, obj.Name)}>
                                                                                        <td style={{ width: "30px" }}>

                                                                                            {disableField == true ? <>
                                                                                                <input type="checkbox"
                                                                                                    disabled={true}
                                                                                                    className={disabledClass}
                                                                                                    checked={obj.Selected == null || obj.Selected == false ? false : true}
                                                                                                    name="distributor-check" value={obj.value} id={obj.value} onChange={(event) => handleDistributorChanges(event, obj.label, obj.Name, obj.value)} />

                                                                                            </> : <>
                                                                                                <input type="checkbox"
                                                                                                    className={disabledClass}
                                                                                                    checked={obj.Selected == null || obj.Selected == false ? false : true} name="distributor-check" value={obj.value} id={obj.value} onChange={(event) => handleDistributorChanges(event, obj.label, obj.Name, obj.value)} />

                                                                                            </>

                                                                                            }</td>
                                                                                        <td style={{ width: "90px" }}>
                                                                                            <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                                                                                title={obj.Name}>{obj.Name}</div>


                                                                                        </td>
                                                                                        <td style={{ width: "260px" }}>
                                                                                            <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                                                                                title={obj.label}>{obj.label}</div>
                                                                                        </td>

                                                                                    </tr>





                                                                                ))


                                                                            }


                                                                        </thead>
                                                                    </table>


                                                                </div>

                                                            </div>
                                                            <div>
                                                                <button style={{ float: "right", marginTop: "10px", marginRight: "50px", width: "30px" }} className='btn btn-outline-dark  btn-sm ml-1' onClick={okCustomer}>{t('Ok')}</button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                        :
                                        ""
                                }

                                {
                                    showSelectedCustomerpopup === true ?
                                        <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, marginRight: "10px", padding: "0" }}>
                                            <div className="container" style={{ padding: "0" }}>
                                                <div className="row">
                                                    <div className="col-lg-12 pr-lg-2">
                                                        <div className="card text-center card_border">
                                                            <div className="card-header chart-grid__header">
                                                                <div className="row">
                                                                    <div className="col-md-10 text-left">
                                                                        {t('Rebates.CustomerListing')}
                                                                    </div>
                                                                    <div className="col-md-2">

                                                                        <button className="btn btn-outline-dark  btn-sm ml-1" onClick={clickCloseAccuralWorkflow} type="submit" style={{ width: "45px" }}>{t('Close')}</button>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row mt-1">
                                                    <div className="col-lg-12 pr-lg-2">
                                                        <div className="card text-center card_border">
                                                            <div className="card-header chart-grid__header">

                                                            </div>
                                                            <div className='scrollbar-class'>

                                                                <div className="container">
                                                                    <div>


                                                                    </div>
                                                                    <table className="table table-bordered text-left mt-2 " id="tableregion">
                                                                        <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                                                                            <tr>
                                                                                <th>

                                                                                </th>
                                                                                <th>{t('Rebates.CustomerCode')}</th>
                                                                                <th>{t('Rebates.CustomerName')}</th>
                                                                                <th>{t('Rebates.Station')}</th>
                                                                                <th>{t('Rebates.State')}</th>
                                                                                <th>{t('Rebates.Zone')}</th>
                                                                            </tr>
                                                                        </thead>

                                                                        <tbody>

                                                                            {
                                                                                customerDataSelected.map((cslist, index) => (

                                                                                    <tr>

                                                                                        <td style={{ width: "30px" }}>
                                                                                            {disableField == true ? <>
                                                                                                <input type="checkbox"
                                                                                                    disabled={true}
                                                                                                    className={disabledClass}
                                                                                                    checked={cslist.Selected} name="customer-check" value={cslist.Id} id={cslist.Id} onChange={(event) => handleCustomerSelectedChanges(event, cslist.label, cslist.Id, cslist.State)} />

                                                                                            </> : <>
                                                                                                <input type="checkbox"
                                                                                                    className={disabledClass}
                                                                                                    checked={cslist.Selected} name="customer-check" value={cslist.Id} id={cslist.Id} onChange={(event) => handleCustomerSelectedChanges(event, cslist.label, cslist.Id, cslist.State)} />

                                                                                            </>

                                                                                            } </td>

                                                                                        <td style={{ width: "90px" }}>
                                                                                            <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                                                                                title={cslist.Name}>{cslist.Name}</div>


                                                                                        </td>
                                                                                        <td style={{ width: "260px" }}>
                                                                                            <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                                                                                title={cslist.label}>{cslist.label}</div>


                                                                                        </td>
                                                                                        <td style={{ width: "100px" }}>
                                                                                            <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                                                                                title={cslist.Station}>{cslist.Station}</div>

                                                                                        </td>
                                                                                        <td style={{ width: "100px" }}>
                                                                                            <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                                                                                title={cslist.State}>{cslist.State}</div>

                                                                                        </td>
                                                                                        <td style={{ width: "100px" }}>
                                                                                            <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                                                                                title={cslist.Zone}>{cslist.Zone}</div>

                                                                                        </td>



                                                                                    </tr>


                                                                                ))


                                                                            }

                                                                        </tbody> </table>


                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div style={{ paddingTop: '11px', paddingLeft: '47px', paddingBottom: '3px', backgroundColor: "#fff" }}>
                                                    <button className='btn btn-outline-dark  btn-sm ml-1' onClick={okSelectedCustomer} style={{ width: "30px" }}>{t('Ok')}</button>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        ""
                                }
                                {
                                    showregionpopup === true ?
                                        <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, padding: "0", height: "72vh" }}>
                                            <div className="container" style={{ padding: "0" }}>
                                                <div className="row">
                                                    <div className="col-lg-12 pr-lg-2">
                                                        <div className="card text-center card_border">
                                                            <div className="card-header chart-grid__header">
                                                                <div className="row">
                                                                    <div className="col-md-10 text-left">
                                                                        {t('Rebates.RegionListing')}
                                                                    </div>
                                                                    <div className="col-md-2">

                                                                        <button className="btn btn-outline-dark  btn-sm ml-1" onClick={clickCloseAccuralWorkflow} type="submit" style={{ width: "45px" }}>{t('Close')}</button>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row mt-1">
                                                    <div className="col-lg-12 pr-lg-2">
                                                        <div className="card text-center card_border">
                                                            <div className="card-header chart-grid__header">

                                                            </div>
                                                            <div style={{ minHeight: "58vh" }}>
                                                                <div className="container">
                                                                    <table className="table table-bordered text-left mt-2 " id="tableregion">
                                                                        <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                                                                            <tr>
                                                                                <th style={{ width: "20px" }}>
                                                                                    <input checked={regioncheckAll} disabled={disableField} onChange={checkAllRegionchange} type="checkbox" name="option-all-region" />
                                                                                </th>

                                                                                <th id="Product">
                                                                                    {t('Rebates.Region')}
                                                                                </th>

                                                                            </tr>
                                                                        </thead>
                                                                    </table>
                                                                    < div className='row' id="div_customer">
                                                                        {
                                                                            SalesLevelData.map((regionlist) =>
                                                                                <div className='col-md-3' style={{ padding: "5px;" }}>
                                                                                    <input type="checkbox"
                                                                                        disabled={disableField}

                                                                                        checked={regionlist.Selected} name="region-check" value={regionlist.Id} id={regionlist.Id} onChange={(event) => handleregionChanges(event, regionlist.Name, regionlist.Id)} />
                                                                                    &nbsp;<label>{regionlist.label}</label>
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </div>


                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{ paddingTop: '6px', paddingLeft: '33px', paddingBottom: '6px', backgroundColor: "#fff" }}>
                                                    <button className='btn btn-outline-dark  btn-sm ml-1' onClick={okregion} style={{ width: "30px" }}>{t('Ok')}</button>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        ""
                                }
                                {
                                    showSelectedRegionpopup === true ?
                                        <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, padding: "0", height: "72vh" }}>
                                            <div className="container" style={{ padding: "0" }}>
                                                <div className="row">
                                                    <div className="col-lg-12 pr-lg-2">
                                                        <div className="card text-center card_border">
                                                            <div className="card-header chart-grid__header">
                                                                <div className="row">
                                                                    <div className="col-md-10 text-left">
                                                                        {t('Rebates.RegionListing')}
                                                                    </div>
                                                                    <div className="col-md-2">

                                                                        <button className="btn btn-outline-dark  btn-sm ml-1" onClick={clickCloseAccuralWorkflow} type="submit" style={{ width: "45px" }}>{t('Close')}</button>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row mt-1">
                                                    <div className="col-lg-12 pr-lg-2">
                                                        <div className="card text-center card_border">
                                                            <div className="card-header chart-grid__header">

                                                            </div>
                                                            <div style={{ minHeight: "58vh", overflowY: "scroll" }}>
                                                                <div className="container">
                                                                    <table className="table table-bordered text-left mt-2 " id="tableregion">
                                                                        <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                                                                            <tr>
                                                                                <th>
                                                                                    <input disabled="true" type="checkbox" name="option-all-region" />
                                                                                </th>

                                                                                <th id="Product">
                                                                                    {t('Rebates.Region')}
                                                                                </th>

                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {
                                                                                regionDataSelected.map((i) =>
                                                                                    <tr key={i.Id}>
                                                                                        <td><input type="checkbox"
                                                                                            disabled={true}
                                                                                            className={disabledClass}
                                                                                            checked={i.Selected} name="region-check" value={i.Id} id={i.Id} /></td>
                                                                                        <td>{i.label}</td>
                                                                                    </tr>
                                                                                )
                                                                            }
                                                                        </tbody>

                                                                    </table>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        ""
                                }
                                {
                                    showproductpopup === true ?
                                        <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, padding: "0" }}>
                                            <div className="container" style={{ padding: "0" }}>
                                                <div className="row">
                                                    <div className="col-lg-12 pr-lg-2">
                                                        <div className="card text-center card_border">
                                                            <div className="card-header chart-grid__header">
                                                                <div className="row">
                                                                    <div className="col-md-10 text-left">
                                                                        {t('Rebates.ProductListing')}
                                                                    </div>
                                                                    <div className="col-md-2">

                                                                        <button className="btn btn-outline-dark  btn-sm ml-1" onClick={clickCloseAccuralWorkflow} type="submit" style={{ width: "45px" }}>{t('Close')}</button>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row mt-1">
                                                    <div className="col-md-12">
                                                        <div className="row">
                                                            <div className="col-lg-12 pr-lg-2">
                                                                <div className="card text-center card_border">
                                                                    <div className="card-header chart-grid__header">

                                                                    </div>
                                                                    <div style={{ minHeight: "66vh" }}>
                                                                        <div className="container">
                                                                            <div className='col-md-12'>
                                                                                {clickProduct === true ?
                                                                                    <ProductTreeDialog
                                                                                        show={showModal}
                                                                                        onOkClick={UpdateProducts}
                                                                                        onHide={() => CloseProductPicker}
                                                                                        dataByHeirarchy={dualListBoxOptionsByHeirarchy}
                                                                                        rebatelinestatus={rebatelinestatus}
                                                                                        dialogType="Product"
                                                                                        SelectedData={FiltedProducts}
                                                                                        dataByAtrribute={dualListBoxOptionsByHeirarchy}
                                                                                        selectedType={selectedType}
                                                                                        dualListBoxOptionsByAttribute={dualListBoxOptionsByAttribute}
                                                                                    >
                                                                                        <div className={classes['Radiobutton-header']}>
                                                                                            <div className={classes['Radiobutton-internal-div']} >
                                                                                                <input type="radio" checked={selectedType === 'Attribute'}
                                                                                                    value="Attribute" onChange={SelectedProductTypeHandler}
                                                                                                    name="product" className={classes['radio-button-Right']} /> {t('Rebates.Attribute')}
                                                                                                <input type="radio" checked={selectedType === 'Heirarchy'}
                                                                                                    onChange={SelectedProductTypeHandler}
                                                                                                    className={classes['radio-button-Left']} value="Heirarchy" name="product" /> {t('Rebates.Hierarchy')}

                                                                                            </div>
                                                                                        </div>
                                                                                    </ProductTreeDialog>
                                                                                    : ''
                                                                                }

                                                                            </div>

                                                                        </div>




                                                                    </div>

                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>


                                                </div>


                                            </div>
                                        </div>
                                        :
                                        ""
                                }
                                {
                                    showSelectedProductpopup === true ?
                                        <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, padding: "0" }}>
                                            <div className="container" style={{ padding: "0" }}>
                                                <div className="row">
                                                    <div className="col-lg-12 pr-lg-2">
                                                        <div className="card text-center card_border">
                                                            <div className="card-header chart-grid__header">
                                                                <div className="row">
                                                                    <div className="col-md-10 text-left">
                                                                        {t('Rebates.ProductListing')}
                                                                    </div>
                                                                    <div className="col-md-2">
                                                                        <button className="btn btn-outline-dark  btn-sm ml-1" onClick={clickCloseAccuralWorkflow} type="submit" style={{ width: "45px" }}>{t('Close')}</button>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row mt-1">
                                                    <div className="col-md-12">
                                                        <div className="row">
                                                            <div className="col-lg-12 pr-lg-2">
                                                                <div className="card text-center card_border">
                                                                    <div className="card-header chart-grid__header">

                                                                    </div>

                                                                    <div className='scrollbar-class'>

                                                                        <div className="container">
                                                                            <div className='col-md-12'>

                                                                                <table className="table table-bordered text-left mt-2 " id="tableA">
                                                                                    <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                                                                                        <tr>
                                                                                            <th className="tableheader">

                                                                                            </th>
                                                                                            <th id="ProductId" >
                                                                                                {t('Rebates.MaterialNo')}
                                                                                            </th>
                                                                                            <th id="Product" >
                                                                                                {t('Rebates.ItemName')}
                                                                                            </th>
                                                                                            <th id="Attribute1" >
                                                                                                Cat I
                                                                                            </th>
                                                                                            <th id="Attribute2" >
                                                                                                Cat II
                                                                                            </th>
                                                                                            <th id="Attribute3" >
                                                                                                Cat III
                                                                                            </th>
                                                                                            <th id="Attribute4" >
                                                                                                Cat IV
                                                                                            </th>


                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {dualListBoxOptionsByAttribute.filter((e) => e.Selected === true).map((listVal) =>
                                                                                            <tr key={listVal.Id} >

                                                                                                <td style={{ width: "30px" }}>

                                                                                                    {

                                                                                                        <input id={listVal.Id} disabled={true} checked={listVal.Selected} type="checkbox" name="chkBoxproduct" value={listVal.Id} />

                                                                                                    }

                                                                                                </td>
                                                                                                <td style={{ width: "100px" }}>
                                                                                                    <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right" title={listVal.Name}>{listVal.Name}</div>


                                                                                                </td>
                                                                                                <td style={{ width: "200px" }}>
                                                                                                    <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right" title={listVal.Label}>{listVal.Label}</div>


                                                                                                </td>
                                                                                                <td style={{ width: "100px" }}>
                                                                                                    <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right" title={listVal.ProductAttribute1Name}>{listVal.ProductAttribute1Name}</div>

                                                                                                </td>
                                                                                                <td style={{ width: "100px" }}>

                                                                                                    <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right" title={listVal.ProductAttribute2Name}>{listVal.ProductAttribute2Name}</div>

                                                                                                </td>
                                                                                                <td style={{ width: "100px" }}>

                                                                                                    <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right" title={listVal.ProductAttribute3Name}>{listVal.ProductAttribute3Name}</div>

                                                                                                </td>
                                                                                                <td style={{ width: "100px" }} >

                                                                                                    <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right" title={listVal.ProductAttribute4Name}>{listVal.ProductAttribute4Name}</div>


                                                                                                </td>


                                                                                            </tr>
                                                                                        )}
                                                                                    </tbody>
                                                                                </table>


                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        ""
                                }
                                {
                                    showCustomerGroupPopup === true ?
                                        <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, marginRight: "10px", padding: "0" }}>
                                            <div className="container" style={{ padding: "0" }}>
                                                <div className="row">
                                                    <div className="col-lg-12 pr-lg-2">
                                                        <div className="card text-center card_border">
                                                            <div className="card-header chart-grid__header">
                                                                <div className="row">
                                                                    <div className="col-md-10 text-left">
                                                                        {t('Rebates.GroupCustomerListing')}
                                                                    </div>
                                                                    <div className="col-md-2">

                                                                        <button className="btn btn-outline-dark  btn-sm ml-1" onClick={clickCloseAccuralWorkflow} type="submit" style={{ width: "45px" }}>{t('Close')}</button>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row mt-1" id="CustomerGroup">
                                                    <div className="col-lg-12 pr-lg-2">
                                                        <div className="card text-center card_border">
                                                            <div className="card-header chart-grid__header">

                                                            </div>

                                                            <div className='scrollbar-class'>

                                                                <div className="container">
                                                                    <div>
                                                                        <input type="text" className='form-control form-control-user' value={customerGroupSearch} onChange={(event) => SearchGroupCustomer(event)} placeholder={`${t('Search')} ${t('Rebates.GroupCustomer')}`} />
                                                                    </div>
                                                                    <table className="table table-bordered text-left mt-2 " id="CustomerGroup">
                                                                        <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                                                                            <tr>
                                                                                <th>
                                                                                    <input checked={customercheckAll} disabled={disableField} type="checkbox" id="option-all-customer" onChange={checkAllGroupCustomer} /><label></label>
                                                                                </th>

                                                                                <th id="GroupLabel" style={{ backgroundColor: (filterValueGroupCustomer.label ? '#6cae55' : ''), maxWidth: "110px" }}>{`${t('Rebates.CustomerGroup')} ${t('Policies.Label')}`}<span className='fa fa-filter' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumnGroupCustomer('label')}></span></th>
                                                                            </tr>
                                                                        </thead>
                                                                        <thead>

                                                                            {
                                                                                groupCustomerData.map((cslist, index) => (

                                                                                    <tr>
                                                                                        <td style={{ width: "30px" }}>


                                                                                            <input type="checkbox"
                                                                                                disabled={disableField}

                                                                                                checked={cslist.Selected == null || cslist.Selected == false ? false : true}
                                                                                                name="customer-check" value={cslist.Id} id={cslist.Id} onChange={(event) => OnChangeHandlerGroupCustomer(event, cslist.label, cslist.Id, cslist.State)} />

                                                                                        </td>


                                                                                        <td style={{ width: "260px" }}>
                                                                                            <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                                                                                title={cslist.label}>{cslist.label}</div>
                                                                                        </td>

                                                                                    </tr>

                                                                                ))
                                                                            }


                                                                        </thead>
                                                                    </table>
                                                                </div>

                                                            </div>
                                                            <div>
                                                                <button style={{ float: "right", marginTop: "10px", marginRight: "50px", width: "30px" }} className='btn btn-outline-dark  btn-sm ml-1' onClick={okCustomerGroup}>{t('Ok')}</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        showCustomerGroupfilterpopup === true ?
                                                            <div className='pop-up' style={{ left: divleft, top: divtop, width: "200px" }}>

                                                                {custGroupFilterCol === 'label' ?
                                                                    <>
                                                                        <div className="container">
                                                                            <div className="row">
                                                                                <b className="forgettext col-md-11 textbox-padding ml-2">{filtertextCustGroup}</b>

                                                                            </div>

                                                                        </div>

                                                                        <div className="container mt-2">
                                                                            <div className="row">
                                                                                <input type="text" placeholder="Search" value={filterSearchCustGroup} onChange={(event) => UserfilterHandlerCustomerGroup(event)} className='filtertextboxbackground col-md-11 textbox-padding ml-2' />
                                                                            </div>

                                                                            <div className="row contentoverflow mt-2">
                                                                                <table className="filtertable">
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td><input type="checkbox" name="filter-option-all" id="option-all" onChange={checkAllFilterCustomerGroup} /></td>
                                                                                            <td>Select All</td>
                                                                                        </tr>
                                                                                        {
                                                                                            filterCustGroupDropDownData.map((item, index) => (

                                                                                                <tr key={index}>

                                                                                                    <td><input id={item.Id} data-index="1" name="filter-option-all" checked={item.Selected} type="checkbox" onChange={(event) => OnCustGroupdropdownChange(event, item.Id, item.Value)} /></td>

                                                                                                    <td>{item.Value}</td>

                                                                                                </tr>


                                                                                            ))
                                                                                        }

                                                                                    </tbody>
                                                                                </table>

                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    <></>

                                                                }
                                                                <div style={{ paddingTop: '10px', paddingLeft: '33px', paddingBottom: '7px' }}>

                                                                    <button className="btn btn-sm btn-update" style={{ width: "45px" }} onClick={() => filterHandlerCustomerGroup('Apply')}>{t('Apply')}</button>
                                                                    <button className="btn btn-sm btn-update" style={{ marginLeft: '10px', width: "45px" }} onClick={() => filterHandlerCustomerGroup('Reset')}>{t('Reset')}</button>
                                                                </div>
                                                                <div>
                                                                </div>
                                                            </div>
                                                            :
                                                            <></>
                                                    }
                                                </div>

                                            </div>
                                            {
                                                showCustomerfilterpopup === true ?
                                                    <div className='pop-up' style={{ left: divleft, top: divtop, width: "200px" }}>

                                                        {custFilterCol === 'Name' || custFilterCol === 'Label' || custFilterCol === 'Station' || custFilterCol === 'State' || custFilterCol === 'Zone' ?


                                                            <>
                                                                <div className="container">
                                                                    <div className="row">
                                                                        <b className="forgettext col-md-11 textbox-padding ml-2">{filtertextCust}</b>

                                                                    </div>

                                                                </div>

                                                                <div className="container mt-2">
                                                                    <div className="row">
                                                                        <input type="text" placeholder="Search" value={filterSearch} onChange={(event) => UserfilterHandlerCustomer(event)} className='filtertextboxbackground col-md-11 textbox-padding ml-2' />
                                                                    </div>

                                                                    <div className="row contentoverflow mt-2">
                                                                        <table className="filtertable">
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td><input type="checkbox" name="filter-option-all" id="option-all" onChange={checkAllFilterCustomer} /></td>
                                                                                    <td>Select All</td>
                                                                                </tr>
                                                                                {
                                                                                    filterCusDropDownData.map((item, index) => (

                                                                                        <tr key={index}>

                                                                                            <td><input id={item.Id} data-index="1" name="filter-option-all" checked={item.Selected} type="checkbox" onChange={(event) => OnCusdropdownChange(event, item.Id, item.Name)} /></td>

                                                                                            <td>{item.Name}</td>

                                                                                        </tr>


                                                                                    ))
                                                                                }

                                                                            </tbody>
                                                                        </table>

                                                                    </div>
                                                                </div>
                                                            </>
                                                            :
                                                            <></>

                                                        }
                                                        <div style={{ paddingTop: '10px', paddingLeft: '33px', paddingBottom: '7px' }}>

                                                            <button className="btn btn-sm btn-update" style={{ width: "45px" }} onClick={() => CustFilterHandler('Apply')}>{t('Apply')}</button>
                                                            <button className="btn btn-sm btn-update" style={{ marginLeft: '10px', width: "45px" }} onClick={() => CustFilterHandler('Reset')}>{t('Reset')}</button>
                                                        </div>
                                                        <div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <></>
                                            }
                                        </div>
                                        :
                                        ""
                                }
                                {
                                    showSelectedCustomerGroupPopup === true ?
                                        <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, marginRight: "10px", padding: "0" }}>
                                            <div className="container" style={{ padding: "0" }}>
                                                <div className="row">
                                                    <div className="col-lg-12 pr-lg-2">
                                                        <div className="card text-center card_border">
                                                            <div className="card-header chart-grid__header">
                                                                <div className="row">
                                                                    <div className="col-md-10 text-left">
                                                                        {t('Rebates.CustomerListing')}
                                                                    </div>
                                                                    <div className="col-md-2">

                                                                        <button className="btn btn-outline-dark  btn-sm ml-1" onClick={clickCloseAccuralWorkflow} type="submit" style={{ width: "45px" }}>{t('Close')}</button>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row mt-1">
                                                    <div className="col-lg-12 pr-lg-2">
                                                        <div className="card text-center card_border">
                                                            <div className="card-header chart-grid__header">

                                                            </div>
                                                            <div className='scrollbar-class'>

                                                                <div className="container">
                                                                    <div>


                                                                    </div>
                                                                    <table className="table table-bordered text-left mt-2 " id="tableregion">
                                                                        <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                                                                            <tr>
                                                                                <th>

                                                                                </th>
                                                                                <th>{t('Rebates.CustomerCode')}</th>
                                                                                <th>{t('Rebates.CustomerName')}</th>

                                                                            </tr>
                                                                        </thead>

                                                                        <tbody>

                                                                            {
                                                                                customerGroupDataSelected.map((cslist, index) => (

                                                                                    <tr>

                                                                                        <td style={{ width: "30px" }}>
                                                                                            {disableField == true ? <>
                                                                                                <input type="checkbox"
                                                                                                    disabled={true}
                                                                                                    className={disabledClass}
                                                                                                    checked={cslist.Selected} name="customer-check" value={cslist.Id} id={cslist.Id} onChange={(event) => handleCustomerGroupSelectedChanges(event, cslist.label, cslist.Id)} />

                                                                                            </> : <>
                                                                                                <input type="checkbox"
                                                                                                    className={disabledClass}
                                                                                                    checked={cslist.Selected} name="customer-check" value={cslist.Id} id={cslist.Id} onChange={(event) => handleCustomerGroupSelectedChanges(event, cslist.label, cslist.Id)} />

                                                                                            </>

                                                                                            } </td>

                                                                                        <td style={{ width: "90px" }}>
                                                                                            <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                                                                                title={cslist.Name}>{cslist.Name}</div>


                                                                                        </td>
                                                                                        <td style={{ width: "260px" }}>
                                                                                            <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                                                                                title={cslist.label}>{cslist.label}</div>
                                                                                        </td>

                                                                                    </tr>


                                                                                ))
                                                                            }

                                                                        </tbody> </table>


                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div style={{ paddingTop: '11px', paddingLeft: '47px', paddingBottom: '3px', backgroundColor: "#fff" }}>
                                                    <button className='btn btn-outline-dark  btn-sm ml-1' onClick={okSelectedCustomerGroup} style={{ width: "30px" }}>{t('Ok')}</button>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        ""
                                }

                                <div className='row p-2'>
                                    {
                                        totalselectedshow === true ?

                                            <div className='col-lg-2 forgettext form-control  col-sm-12 border' style={{ textAlign: "center" }}>
                                                {t('TotalRecordsSelected')} - <span id="totalselected">{totalselectedcount}</span>
                                            </div>

                                            :
                                            <div className='col-lg-2 col-sm-12' style={{ textAlign: "center" }}>

                                            </div>
                                    }
                                    <div className='col-lg-8 col-sm-12'></div>


                                    <div className='col-lg-2 forgettext form-control col-sm-12 border' style={{ textAlign: "center" }}>
                                        {t('TotalRecords')} - {totalCountlst}
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                {
                    showSettelmetnFreqpopup === true ?
                        <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, padding: "0", height: "72vh" }}
                        >
                            <div className="container" style={{ padding: "0" }}>
                                <div className="row">
                                    <div className="col-lg-12 pr-lg-2">
                                        <div className="card text-center card_border">
                                            <div className="card-header chart-grid__header">
                                                <div className="row">
                                                    <div className="col-md-10 text-left">
                                                        {t('MassAction.SettlementsFreq')}
                                                    </div>
                                                    <div className="col-md-2">

                                                        <button className="btn btn-outline-dark  btn-sm ml-1" onClick={clickClosePopup} type="submit" style={{ width: "45px" }}>{t('Close')}</button>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className="row mt-1">
                                    <div className="col-lg-12 pr-lg-2">
                                        <div className="card text-center card_border">
                                            <div className="card-header chart-grid__header">

                                            </div>
                                            <div style={{ minHeight: "58vh" }}>
                                                <div className="container">
                                                    <table className="table table-bordered text-left mt-2 " id="tableregion">
                                                        <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                                                            <tr>
                                                                <th style={{ width: "20px" }}>
                                                                    <input checked={settlFreqcheckAll} disabled={disableField} onChange={checkAllSettlFreqChange} type="checkbox" name="option-all-region" />
                                                                </th>

                                                                <th id="Product">
                                                                    {t('MassAction.SettlementsFreq')}
                                                                </th>

                                                            </tr>
                                                        </thead>
                                                    </table>
                                                    < div className='row' id="div_customer">
                                                        {
                                                            SettlementFreqData.map((settlFreq) =>
                                                                <div className='col-md-3' style={{ padding: "5px;" }}>
                                                                    <input type="checkbox"
                                                                        disabled={disableField}

                                                                        checked={settlFreq.Selected}
                                                                        name="settlfreq-check" value={settlFreq.Id} id={settlFreq.Id} onChange={(event) => handleSettlFreqChanges(event, settlFreq.label, settlFreq.Id)} />
                                                                    &nbsp;<label>{settlFreq.label}</label>
                                                                </div>
                                                            )
                                                        }
                                                    </div>


                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ paddingTop: '6px', paddingLeft: '33px', paddingBottom: '6px', backgroundColor: "#fff" }}>
                                    <button className='btn btn-outline-dark  btn-sm ml-1' onClick={oksettlFreq} style={{ width: "30px" }}>{t('Ok')}</button>
                                </div>
                            </div>
                        </div>
                        :
                        ""
                }
                {
                    showCreatedByPopup === true ?
                        <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, padding: "0", height: "72vh" }}>
                            <div className="container" style={{ padding: "0" }}>
                                <div className="row">
                                    <div className="col-lg-12 pr-lg-2">
                                        <div className="card text-center card_border">
                                            <div className="card-header chart-grid__header">
                                                <div className="row">
                                                    <div className="col-md-10 text-left">
                                                        {t('CreatedBy')}
                                                    </div>
                                                    <div className="col-md-2">

                                                        <button className="btn btn-outline-dark  btn-sm ml-1" onClick={clickClosePopup} type="submit" style={{ width: "45px" }}>{t('Close')}</button>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-1" id="User">
                                    <div className="col-lg-12 pr-lg-2">
                                        <div className="card text-center card_border">
                                            <div className="card-header chart-grid__header">

                                            </div>

                                            <div className='scrollbar-class' style={{ height: "70vh" }}>
                                                <div className="container">
                                                    <div>
                                                        <input type="text" className="form-control form-control-user" autoComplete="No" id="firstName" name="firstName" placeholder={`${t('SearchFor')}${t('Policies.FirstName,LastName,UserName&Email')}`} onChange={(event => SearchCreatedBy(event))} />

                                                    </div>
                                                    <table className="table table-bordered text-left mt-2 " id="tableA">
                                                        <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                                                            <tr>
                                                                <th className="tableheader" style={{ width: '30px' }}>
                                                                    <input type="checkbox" id="option-all" onChange={checkAllUser} disabled={disableField} />
                                                                </th>

                                                                <th id="firstname" style={{ backgroundColor: (userFilterValueDict.firstname ? '#6cae55' : ''), minWidth: '200px' }}>
                                                                    <div className='d-flex text-nowrap'>
                                                                        <span>{t('FirstName')}</span>
                                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => UserSortColumn('FirstName')}></span>
                                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => UserFilterColumn('firstname')}></span>
                                                                    </div>
                                                                </th>
                                                                <th id="lastname" style={{ backgroundColor: (userFilterValueDict.lastname ? '#6cae55' : ''), minWidth: '200px' }}>
                                                                    <div className='d-flex text-nowrap'>
                                                                        <span>{t('LastName')}</span>
                                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => UserSortColumn('LastName')}></span>
                                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => UserFilterColumn('lastname')}></span>
                                                                    </div>
                                                                </th>
                                                                <th id="username" style={{ backgroundColor: (userFilterValueDict.username ? '#6cae55' : ''), width: '250px' }}>
                                                                    <div className='d-flex text-nowrap'>
                                                                        <span>{t('UserName')}</span>
                                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => UserSortColumn('Username')}></span>
                                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => UserFilterColumn('username')}></span>
                                                                    </div>
                                                                </th>
                                                                <th id="email" style={{ backgroundColor: (userFilterValueDict.email ? '#6cae55' : ''), width: '250px' }}>
                                                                    <div className='d-flex text-nowrap'>
                                                                        <span>{t('Email')}</span>
                                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => UserSortColumn('Email')}></span>
                                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => UserFilterColumn('email')}></span>
                                                                    </div>
                                                                </th>

                                                            </tr>
                                                        </thead>
                                                        <thead>
                                                            {
                                                                UserData.length === 0 ?
                                                                    <tr>
                                                                        <td></td>
                                                                        <td>
                                                                            No Data Available
                                                                        </td>
                                                                        <td></td>
                                                                        <td></td>    </tr>
                                                                    :
                                                                    UserData.map((item, index) => (

                                                                        <tr key={index}>

                                                                            <td><input id={item.Id} data-index="1" name="chkBoxUser" type="checkbox"
                                                                                disabled={disableField}
                                                                                checked={item.Selected == null || item.Selected == false ? false : true}
                                                                                onChange={(event) => onChangeUser(event, item.Id, item.FirstName)}
                                                                            /></td>


                                                                            <td>{item.FirstName}</td>
                                                                            <td>{item.LastName}</td>
                                                                            <td>{item.Username}</td>
                                                                            <td>{item.Email} </td>
                                                                        </tr>
                                                                    ))
                                                            }
                                                        </thead>
                                                    </table>

                                                </div>
                                            </div>
                                            <div>
                                                <button style={{ float: "right", marginTop: "3px", marginRight: "50px", width: "30px" }} className='btn btn-outline-dark  btn-sm ml-1' onClick={oksettlFreq}>{t('Ok')}</button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            {showfilterpopup === true ?
                                <div className='pop-up' style={{ left: divleft, top: divtop }}>

                                    {filterCol === 'firstname' || filterCol === 'lastname' || filterCol === 'username' || filterCol === 'email' || filterCol === 'roles' ?

                                        <>
                                            <div className="container">
                                                <div className="row">
                                                    <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>
                                                </div>
                                            </div>
                                            <div className="container mt-2">
                                                <div className="row">
                                                    <input type='textbox' placeholder={t('Search')} className='filtertextboxbackground col-md-11 textbox-padding ml-2' style={{ outline: 'none' }} value={userFilterValueDict[filterCol]} onChange={(event) => filterInput(event)}></input>
                                                </div>
                                            </div>
                                        </>

                                        :
                                        <>
                                        </>

                                    }


                                    {filterCol === 'status' || filterCol === 'userstype' ?
                                        <>
                                            <div className="container">
                                                <div className="row">
                                                    <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>

                                                </div>

                                            </div>

                                            <div className="container mt-2">
                                                <div className="row">
                                                    <input type="text" placeholder={t('Search')} value={filterSearch} onChange={(event) => UserfilterHandler(event)} className='filtertextboxbackground col-md-11 textbox-padding ml-2' />
                                                </div>

                                                <div className="row contentoverflow mt-2">
                                                    <table className="filtertable">
                                                        <tbody>
                                                            {
                                                                dropdownData.map((item, index) => (

                                                                    <tr key={index}>

                                                                        <td><input id={item.Id} data-index="1" name="filter-option-all" checked={item.ischecked} type="checkbox" onChange={(event) => OndropdownChange(event, item.Id, item.Name)} /></td>

                                                                        <td>{item.Name}</td>

                                                                    </tr>


                                                                ))
                                                            }

                                                        </tbody>
                                                    </table>

                                                </div>
                                            </div>
                                        </>
                                        :
                                        <></>
                                    }

                                    <div style={{ paddingTop: '10px', paddingLeft: '47px', paddingBottom: '7px' }}>
                                        <button className="btn btn-sm btn-update" onClick={() => filterHandler('Apply')} style={{ width: "45px" }}>{t('Apply')}</button>
                                        <button className="btn btn-sm btn-update" style={{ marginLeft: '10px', width: "45px" }} onClick={() => filterHandler('Reset')}>{t('Reset')}</button>
                                    </div>
                                    <div>
                                    </div>
                                </div>
                                :
                                <></>
                            }

                        </div>
                        :
                        ""
                }

                {showLineItemfilterpopup === true ?
                    <div className='pop-up' style={{ left: divleft1, top: divtop1 }}>
                        {filterCol === 'OperationStatus' || filterCol === 'ErrorMessage' || filterCol === 'RebateProgramLineId' || filterCol === 'rebateid' ?
                            <>
                                <div className="container">
                                    <div className="row">
                                        <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>
                                    </div>
                                </div>
                                <div className="container mt-2">
                                    <div className="row">

                                        <input type='textbox' placeholder={t('Search')} className='filtertextboxbackground col-md-11 textbox-padding ml-2' value={lineItemFilterValue[filterCol]} onChange={(event) => filterInput(event)}></input>
                                    </div>
                                </div>
                            </>
                            :
                            <>
                            </>
                        }
                        {filterCol === 'Validity_From' || filterCol === 'Validity_To' ?
                            <>
                                <div className="container">
                                    <div className="row">
                                        <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>
                                    </div>
                                </div>
                                <div className="container mt-2">
                                    <div className="row">
                                        <input type='date' placeholder='Search...' className='filtertextboxbackground col-md-11 textbox-padding ml-2' value={lineItemFilterValue[filterCol]} onChange={(event) => filterInput(event)}></input>
                                    </div>
                                </div>
                            </>
                            :
                            <></>
                        }
                        <div style={{ paddingTop: '10px', paddingLeft: '47px', paddingBottom: '7px' }}>
                            <button className="btn btn-sm btn-update" onClick={() => filterHandlerLineItem('Apply')} style={{ width: "45px" }}>{t('Apply')}</button>
                            <button className="btn btn-sm btn-update" style={{ marginLeft: '10px', width: "45px" }} onClick={() => filterHandlerLineItem('Reset')}>{t('Reset')}</button>
                        </div>
                        <div>
                        </div>
                    </div>
                    :
                    <></>
                }

                {
                    showSelectedCreatedBy === true ?
                        <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, marginRight: "10px", padding: "0" }}>
                            <div className="container" style={{ padding: "0" }}>
                                <div className="row">
                                    <div className="col-lg-12 pr-lg-2">
                                        <div className="card text-center card_border">
                                            <div className="card-header chart-grid__header">
                                                <div className="row">
                                                    <div className="col-md-10 text-left">
                                                        {t('CreatedBy')}
                                                    </div>
                                                    <div className="col-md-2">

                                                        <button className="btn btn-outline-dark  btn-sm ml-1" onClick={clickCloseAccuralWorkflow} type="submit" style={{ width: "45px" }}>{t('Close')}</button>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className="row mt-1">
                                    <div className="col-lg-12 pr-lg-2">
                                        <div className="card text-center card_border">
                                            <div className="card-header chart-grid__header">

                                            </div>
                                            <div className='scrollbar-class'>

                                                <div className="container">
                                                    <div>

                                                    </div>
                                                    <table className="table table-bordered text-left mt-2 " id="tableregion">
                                                        <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                                                            <tr>
                                                                <th>

                                                                </th>
                                                                <th>{t('FirstName')}</th>
                                                                <th>{t('LastName')}</th>
                                                                <th>{t('UserName')}</th>
                                                                <th>{t('Email')}</th>
                                                            </tr>
                                                        </thead>

                                                        <tbody>

                                                            {
                                                                UserDataSelected.map((cslist, index) => (

                                                                    <tr>

                                                                        <td style={{ width: "30px" }}>
                                                                            {disableField == true ? <>
                                                                                <input type="checkbox"
                                                                                    disabled={true}
                                                                                    className={disabledClass}
                                                                                    checked={cslist.Selected} name="customer-check" value={cslist.Id} id={cslist.Id} onChange={(event) => handleUserSelectedChanges(event, cslist.label, cslist.Id, cslist.State)} />

                                                                            </> : <>
                                                                                <input type="checkbox"
                                                                                    className={disabledClass}
                                                                                    checked={cslist.Selected} name="customer-check" value={cslist.Id} id={cslist.Id} onChange={(event) => handleUserSelectedChanges(event, cslist.label, cslist.Id, cslist.State)} />

                                                                            </>

                                                                            } </td>

                                                                        <td style={{ width: "90px" }}>
                                                                            <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                                                                title={cslist.FirstName}>{cslist.FirstName}</div>


                                                                        </td>
                                                                        <td style={{ width: "260px" }}>
                                                                            <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                                                                title={cslist.LastName}>{cslist.LastName}</div>


                                                                        </td>
                                                                        <td style={{ width: "100px" }}>
                                                                            <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                                                                title={cslist.Username}>{cslist.Username}</div>

                                                                        </td>
                                                                        <td style={{ width: "100px" }}>
                                                                            <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                                                                title={cslist.Email}>{cslist.Email}</div>

                                                                        </td>
                                                                    </tr>


                                                                ))


                                                            }

                                                        </tbody> </table>


                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                        :
                        ""
                }
                {
                    showSelectedSettlFreq === true ?
                        <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, marginRight: "10px", padding: "0" }}>
                            <div className="container" style={{ padding: "0" }}>
                                <div className="row">
                                    <div className="col-lg-12 pr-lg-2">
                                        <div className="card text-center card_border">
                                            <div className="card-header chart-grid__header">
                                                <div className="row">
                                                    <div className="col-md-10 text-left">
                                                        {t('Rebates.CustomerListing')}
                                                    </div>
                                                    <div className="col-md-2">

                                                        <button className="btn btn-outline-dark  btn-sm ml-1" onClick={clickCloseAccuralWorkflow} type="submit" style={{ width: "45px" }}>{t('Close')}</button>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className="row mt-1">
                                    <div className="col-lg-12 pr-lg-2">
                                        <div className="card text-center card_border">
                                            <div className="card-header chart-grid__header">

                                            </div>
                                            <div className='scrollbar-class'>

                                                <div className="container">
                                                    <div>

                                                    </div>
                                                    <table className="table table-bordered text-left mt-2 " id="tableregion">
                                                        <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                                                            <tr>
                                                                <th >
                                                                </th>
                                                                <th id="Product">
                                                                    {t('MassAction.SettlementsFreq')}
                                                                </th>
                                                            </tr>
                                                        </thead>

                                                        <tbody>

                                                            {
                                                                SettlFreqDataSelected.map((cslist, index) => (

                                                                    <tr>

                                                                        <td style={{ width: "30px" }}>
                                                                            {disableField == true ? <>
                                                                                <input type="checkbox"
                                                                                    disabled={true}
                                                                                    className={disabledClass}
                                                                                    checked={cslist.Selected} name="customer-check" value={cslist.Id} id={cslist.Id} onChange={(event) => handleSetlFreqSelectedChanges(event, cslist.label, cslist.Id, cslist.State)} />

                                                                            </> : <>
                                                                                <input type="checkbox"
                                                                                    className={disabledClass}
                                                                                    checked={cslist.Selected} name="customer-check" value={cslist.Id} id={cslist.Id} onChange={(event) => handleSetlFreqSelectedChanges(event, cslist.label, cslist.Id, cslist.State)} />

                                                                            </>

                                                                            } </td>

                                                                        <td style={{ width: "90px" }}>
                                                                            <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                                                                title={cslist.Name}>{cslist.Name}</div>


                                                                        </td>
                                                                    </tr>


                                                                ))


                                                            }

                                                        </tbody> </table>


                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                        :
                        ""
                }

                <CustomErrorPopup ErrorMessage={errorMessage} show={showErrorModal} ErrorStatus={errorStatus}
                    onHide={() => setShowErrorModal(false)}></CustomErrorPopup>
                <Modal show={showSubmitModal} size="bg"
                    centered>
                    <Modal.Header>
                        <Modal.Title>{t('Rebates.SubmitComments')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ "textAlign": 'center' }} >
                        <Form.Control className={classes['Textarea-width']} as="textarea" rows={2} value={commentsForSubmit} name="commentsForSubmit"
                            onChange={(e) => { setCommentsForSubmit(e.target.value) }} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button size='sm' style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "50px" }} onClick={() => showSubmitDialog('showSubmitDialog')}>
                            {t('Cancel')}
                        </Button>
                        <Button size='sm' style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "50px" }} onClick={() => showSubmitDialog('proceed')}>
                            {t('Submit')}
                        </Button>
                    </Modal.Footer>
                </Modal>
                <ToastContainer autoClose={1000} />
            </div>
        </>
    )
}

export default CreateNewMassActionSubmitClaimForRebate