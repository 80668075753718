
import React, { useEffect, useState, useRef, forwardRef } from 'react';
import { CSVLink } from "react-csv";

import { Form, FormGroup, Col, Row, Button, InputGroup, Collapse, Modal } from 'react-bootstrap';
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import DatePicker from "react-datepicker";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { Link, useLocation } from "react-router-dom"
import Service from '../../Services/Service';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import CustomErrorPopup from '../../UI/CustomErrorPopup';
import Multiselect from 'multiselect-react-dropdown';
import SessionManage from '../../SessionManagement/SessionManage';
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function PaymentHistoryComponent(props) {
    const csvInstance = useRef();
    const [csvList, setCsvList] = useState([]);
    const [headers, setHeaders] = useState([

        { label: "Dist. Code", key: "DistributorCode" },
        { label: "Distributor Name", key: "DistributorName" },
        { label: "Doc Number", key: "DocNumber" },
        { label: "Bill Number", key: "BillNumber" },
        { label: "Bill Date", key: "BillDate" },
        { label: "Bill Amount", key: "BillAmount" },
        { label: "CD Amt.", key: "CashDiscountAmount" },
        { label: "Amount Paid", key: "AmountPaid" },
        { label: "Payment Recieved Date", key: "PaymentreceivedDate" },
        { label: "Transaction ID", key: "TransactionId" },
    ]);
    const [t, i18n] = useTranslation('translation');
    const [sortColName, setSortColName] = useState("");
    const [dropdownChange, setDropdownChange] = useState('');
    const [sticky, setSticky] = useState("");

    const location = useLocation();
    const [userPerms, setuserPermission] = useState([]);
    const navigate = useNavigate();
    let rowData = [];
    const [showspinner, setshowspinner] = useState('');
    const [listViewData, setListViewData] = useState(rowData)
    const [filteredArray, setFilteredArray] = useState(rowData)
    const [searchValue, setSearchValue] = useState(null)
    const [checkedValue, setCheckedValue] = useState([]);
    const [checkedValueid, setCheckedValueid] = useState([]);
    const [checkedValueStatus, setCheckedValueStatus] = useState([]);
    const [errorStatus, setErrorStatus] = useState("Error");
    const [errorMessage, setErrorMessage] = useState("");
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [showbutton, setshowbutton] = useState('');
    const [isValid, setIsValid] = useState(true);
    const [showfilterpopup, setshowfilterpopup] = useState(false);
    const [columnAscending, setColumnAscending] = useState(true);
    const [filterCol, setFilterCol] = useState('');
    const [dropdownData, setDropdownData] = useState([]);
    const [totalselectedshow, settotalselectedshow] = useState('');
    const [totalselectedcount, settotalselectedcount] = useState(0);
    const [totalCountlst, settotalCountlst] = useState('');
    const [ToggleStatusModal, setToggleStatusModal] = useState(false);
    const [toggleChange, setToggleChange] = useState("");
    const [toggleId, setToggleId] = useState();
    const [filtertext, setfiltertext] = useState('');
    const [filterSearch, setFilterSearch] = useState("");
    const [filterdropdownData, setFilterDropdownData] = useState([]);
    const [filterValue, setFilterValue] = useState([]);
    const [filterValueDict, setFilterValueDict] = useState({
        DistributorCode: '',
        DistributorName: '',
        BillNumber: '',
        BillAmount: '',
        CashDiscountAmount: '',
        AmountPaid: '',
        DocNumber: '',
        TransactionId: '',
        BillDate: null,
        PaymentreceivedDate: null
    });
    const [multiFilterValue, setMultiFilterValue] = useState({
        CreatedBy: '',
        LastModifiedBy: '',
        status: ''
    });
    const [paging, setpaging] = useState({
        pageSize: 20,
        pageNumber: 1
    })
    let showSuccessMsg = false;
    let showErrorMsg = false;
    let showDetails = '';
    let loggedInUser = sessionStorage.getItem("id");
    {
        useEffect(() => {
            getUserPermission(location.pathname);
            setshowspinner(true);
            setColumnAscending(false);
            setSortColName("Id");
            getPaymentHistoryViewData("PaymentHistory", paging.pageSize, paging.pageNumber, "Descending", "Id", [], null);
            getColumnVisibilityList()
            getColVisibilityListData()
            window.addEventListener("scroll", isSticky);
            return () => {
                window.removeEventListener("scroll", isSticky);
            };
        }, [props.id])
    }
    const isSticky = () => {
        const scrollTop = window.scrollY;
        const stickyClass = scrollTop >= 60 ? "is-sticky-thead" : "";
        setSticky(stickyClass);
        const scrollTopActual = scrollTop >= 60 ? scrollTop - 85 : 0;
        var divtop1 = document.getElementById("tableA");
        setdivtop(divtop1.offsetTop + scrollTopActual)
    };
    const activeOption = [
        { Name: "Active", Id: "1" },
        { Name: "In-Active", Id: "0" },

    ]

    const class_thead = `text-white rt-thead  ${sticky}`;

    const filterLst = () => {
        let list = [];
        for (var key in multiFilterValue) {
            if (multiFilterValue[key].length > 0) {
                let values = ""
                multiFilterValue[key].map((i) => {
                    values += i.Name + ";";
                })
                if (values !== null && values !== '') {
                    list.push({
                        filterKey: key,
                        FilterValue: values
                    })
                }
            }
        }

        for (var key in filterValueDict) {
            if (filterValueDict[key] !== null && filterValueDict[key] !== '') {
                list.push({
                    filterKey: key,
                    FilterValue: filterValueDict[key]
                })
            }
        }
        return list;
    }

    const ExporttoCSV = () => {
        let checkedBoxes = document.querySelectorAll('input[name=chkBox]:checked');
        let values = "";
        if (checkedBoxes.length >= 1) {
            for (let checkbox of checkedBoxes) {
                values = values + ';' + checkbox.id;

            }
        }
        let objFilter = [];
        let objFilterValue = [{
            filterKey: "Id",
            FilterValue: values,
        }]

        objFilter = objFilterValue;
        Service.getAllPolicydataSearch("PaymentReceived", 20000, 1, "Ascending", "Id", objFilter, null).then((res) => {
          
            if (typeof res !== 'undefined') {
                if (res.data !== 'null' && res.data !== 'undefined') {

                   
                    let csvLst = res.data.DataList;

                    let filteredCsvList = csvLst.map(item => {
                        let newItem = {};
                        Object.keys(item).forEach(key => {
                            if (!hideColumn.includes(key)) {
                                newItem[key] = item[key];
                            }
                        });
                        return newItem;
                    });

                    let filteredHeaders = headers.filter(header => !hideColumn.includes(header.key));

                    setCsvList(filteredCsvList);
                    setHeaders(filteredHeaders);

                    setTimeout(() => {
                        csvInstance.current.link.click();
                    });

                }
            }
        });
    }




    const [operatorValOption, setOperatorValueOption] = useState([
        { value: "=", label: "equals" },
        { value: "<", label: "less then" },
        { value: ">", label: "greater then" },
        { value: "<=", label: "less or equal" },
        { value: ">=", label: "greater or equal" }
    ])

    const [operatorVal, setOperatorValue] = useState({ value: "=", label: "equals" })

    const customStyles = {
        control: base => ({
            ...base,
            minHeight: 28
        }),
        dropdownIndicator: base => ({
            ...base,
            padding: 2
        }),
        clearIndicator: base => ({
            ...base,
            padding: 2
        }),
        multiValue: base => ({
            ...base,

        }),
        valueContainer: base => ({
            ...base,
            padding: '0px 2px'
        }),
        input: base => ({
            ...base,
            margin: 0,
            padding: 0
        })
    };
    const OnChangeOperatorValue = (data) => {
        setOperatorValue({ value: data.value, label: data.label });
    }

    const checkAll = (mycheckbox) => {
        var checkboxes = document.querySelectorAll("input[type = 'checkbox']");
        if (mycheckbox.target.checked == true) {
            settotalselectedshow(true);
            checkboxes.forEach(function (checkbox) {
                checkbox.checked = true;
            });
            settotalselectedcount(checkboxes.length - 1)
        }
        else {
            settotalselectedshow(false);
            checkboxes.forEach(function (checkbox) {
                checkbox.checked = false;
            });
            settotalselectedcount(0)
        }

    }
    const getUserPermission = (pathname) => {
        Service.GetUserPermission(pathname).then((res) => {

            if (res !== undefined) {
          
                if (res.data !== 'null' && res.data !== 'undefined' && res.data !== '') {
                    if (res.data.DataList !== undefined) {
                        setuserPermission(res.data.DataList)
                    }
                }
            }
        });
    }
    const searchHandler = (event) => {
        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
        let pagenumber = 1;
        paging.pageNumber = pagenumber;
        setpaging(paging);
        if (event.target.value != '') {
 
            setSearchValue(String(event.target.value).toLowerCase())
            getPaymentHistoryViewData("PaymentHistory", paging.pageSize, pagenumber, sortType, sortColName, filterLst(), event.target.value);
        }
        else {
            setSearchValue(null);
            getPaymentHistoryViewData("PaymentHistory", paging.pageSize, pagenumber, sortType, sortColName, filterLst(), null);
        }
    }

    const handleLoadMore = () => {
 
        let pagenumber = paging.pageNumber + 1;
        var sortType = columnAscending === true ? 'Ascending' : 'Descending';

        Service.getAllPolicydataSearch("PaymentHistory", paging.pageSize, pagenumber, sortType, sortColName, filterLst(), searchValue).then((res) => {
            if (typeof res !== 'undefined') {
                if (res.data !== 'null' && typeof res.data !== 'undefined') {
                    const currentlength = data.length;
                    setdata(data => [...data, ...res.data.DataList])
                    setFilteredArray(filteredArray => [...filteredArray, ...res.data.DataList])
                    LoadMore(currentlength + res.data.DataList.length, res.data.Totalcount)
                }
            }
        });
 
        paging.pageNumber = pagenumber
        setpaging(paging);
    };
    const [data, setdata] = useState([]);

    const [showLoadMore, setshowLoadMore] = useState('');
    const LoadMore = (lst, totalcount) => {

        if (lst < totalcount) {
            setshowLoadMore(true);
        }
        else if (lst >= totalcount) {
            setshowLoadMore(false);
        } else {
            setshowLoadMore(false);
        }

    }
    const handleChange = (e, id, name, status) => {
        let isChecked = e.target.checked;   // true if it is checked false if unchecked
     
        let checkedBoxes = document.querySelectorAll('input[name=chkBox]:checked');
        if (isChecked === true) {

            setCheckedValue(id)  // check box value - id
            setCheckedValueStatus(status) // check box value - Active status
            setCheckedValueid(name) //check box value - RA-0000
            settotalselectedshow(true);
            settotalselectedcount(checkedBoxes.length);
        }
        else {

            setCheckedValue([])  // check box value - id
            setCheckedValueStatus([]) // check box value - Active status
            setCheckedValueid([]) //check box value - RA-0000
            if (checkedBoxes.length === 0) {
                settotalselectedshow(false);
            }
            settotalselectedcount(checkedBoxes.length);
        }

     
        if (checkedBoxes.length === 1) {
            setshowbutton(true);
        }
        else {
            setshowbutton(false);
        }

    }

    const OpenHandler = (id) => {
        if (id == null || id == 0) {
            alert('Please select the Workflow Rule to view')
        }
        else {
            navigate("/policies/PaymentHistory/edit/" + id);
        }
    }




    const getPaymentHistoryViewData = (method, pageSize, pageNumber, sortType, SortProperty, filterValue, search) => {
      
        Service.getAllPolicydataSearch(method, pageSize, pageNumber, sortType, SortProperty, filterValue, search).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                            rowData = res.data.DataList;
                            setListViewData(rowData)
                            settotalCountlst(res.data.Totalcount)
                            setFilteredArray(rowData)
                            setshowspinner(false)
                            setdata(rowData)
                            LoadMore(rowData.length, res.data.Totalcount)
                        }
                    }
                }
            }

        })
    }





    const DropdownHandler = (key) => {
        Service.getRebatePolicyfilterDropdown('PaymentHistory/GetPaymentHistoryFilterValue', key).then((res) => {
            if (res && res.status === 200) {
                if (typeof res !== 'undefined') {
                    if (typeof res.data !== 'undefined' && res.data !== 'null') {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                          
                            addnewfield(res.data, key)
                        }
                    }
                }

            }
        });
    }

    const [divleft, setdivleft] = useState('');
    const [divtop, setdivtop] = useState('');
    const FilterColumn = (name) => {

        const scrollTop = window.scrollY;
        const scrollTopActual = scrollTop >= 60 ? scrollTop - 85 : 0;
        var divtop1 = document.getElementById("tableA");
        setdivtop((divtop1.offsetTop + 30) + scrollTopActual)


        setshowfilterpopup(true)
        setFilterCol(name)

        if (name === 'DistributorCode') {
            setfiltertext(t('Policies.DistributorCode'))
            var testDiv = document.getElementById("DistributorCode");
            setdivleft((testDiv.offsetLeft + 30) + "px");
        }
        if (name === 'DistributorName') {
            setfiltertext(t('DistributorName'))
            var testDiv = document.getElementById("DistributorName");
            setdivleft((testDiv.offsetLeft + 30) + "px");
        }
        if (name === 'DocNumber') {
            setfiltertext(t('Policies.DocNumber'))
            var testDiv = document.getElementById("DocNumber");
            setdivleft((testDiv.offsetLeft + 30) + "px");
        }
        if (name === 'BillNumber') {
            setfiltertext(t('BillNumber'))
            var testDiv = document.getElementById("BillNumber");
            setdivleft((testDiv.offsetLeft + 30) + "px");
        }
        if (name === 'BillDate') {
            setfiltertext(t('BillDate'))
            var testDiv = document.getElementById("BillDate");
            setdivleft((testDiv.offsetLeft + 30) + "px");
           
        }
        if (name === 'BillAmount') {
            setfiltertext(t('BillAmount'))
            var testDiv = document.getElementById("BillAmount");
            setdivleft((testDiv.offsetLeft + 30) + "px");
      
        }
        if (name === 'AmountPaid') {
            setfiltertext(t('AmountPaid'))
            var testDiv = document.getElementById("AmountPaid");
            setdivleft((testDiv.offsetLeft + 30) + "px");
        
        }
        if (name === 'CashDiscountAmount') {
            setfiltertext(t('CashDiscountAmount'))
            var testDiv = document.getElementById("CashDiscountAmount");
            setdivleft((testDiv.offsetLeft + 30) + "px");
        }
        if (name === 'TransactionId') {
            setfiltertext(t('TransactionId'))
            var testDiv = document.getElementById("TransactionId");
            setdivleft(((testDiv.offsetLeft + 30) - 100) + "px");
        }
        if (name === 'PaymentreceivedDate') {
            setfiltertext(t('PaymentreceivedDate'))
            var testDiv = document.getElementById("PaymentreceivedDate");
            setdivleft((testDiv.offsetLeft + 30) + "px");
        }
    }

    const OpenPolicy = (event, id) => {
        if (event.detail === 2) {
        
            navigate("/policies/PaymentHistory/edit/" + id);
        }
    }
    const SortColumn = (colName) => {


        var sortType = '';
        if (columnAscending === true) {
            setColumnAscending(false);
            sortType = "Descending";
        } else {
            setColumnAscending(true);
            sortType = "Ascending";
        }
        setSortColName(colName);
       
        let pagenumber = 1;
        
        paging.pageNumber = pagenumber;
        setpaging(paging);
        getPaymentHistoryViewData("PaymentHistory", paging.pageSize, paging.pageNumber, sortType, colName, filterLst(), searchValue);
    }


    const filterInput = (e) => {
        
        let inputValue = e.target.value
        setFilterValueDict(() => ({
            ...filterValueDict, [filterCol]: e.target.value,
        }));
    }

    const OndropdownChange = (e, id, name) => {
        let isChecked = e.target.checked;
        var lastfilter = [...dropdownData]
        if (isChecked === true) {
            let currentdata = lastfilter.map((i) => {
                if (i.Id == id) {
                    i["ischecked"] = true;
                }
                return i;
            });
          
            setDropdownData(currentdata);
            let data1 = []
            const getallData = {
                Name: name,
            }
            data1.push(getallData)
            setFilterValue(filterValue => [...filterValue, ...data1]);
            let updateddata = ([...filterValue, ...data1]);
            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: updateddata,
            }));
        }
        else {
            let currentdata = lastfilter.map((i) => {
                if (i.Id === id) {
                    i["ischecked"] = false;
                }
                return i;
            });
        
            setDropdownData(currentdata);
            setFilterValue(filterValue => filterValue.filter(data => data.Name !== name))
            let data1 = multiFilterValue.filter(data => data.Name !== name);
            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: data1,
            }));
        }
    }
    const addnewfield = (data, key) => {
        let selectedKeys = multiFilterValue[key];
        let currentdata = data.map((i) => {
            i["ischecked"] = false;
            return i;
        });
        if (key != 'status') {
            if (selectedKeys != '') {
                currentdata.forEach((each) => {
                    selectedKeys.forEach((eachSelected) => {
                        if (each.Name == eachSelected.Name) {
                            each.ischecked = true
                        }
                    })
                })
            }
         
            setDropdownData(currentdata);
            setFilterDropdownData(currentdata)
        }
        else {
            if (selectedKeys != '') {
                currentdata.forEach((each) => {
                    selectedKeys.forEach((eachSelected) => {
                        if (each.Name == eachSelected.Name) {
                            each.ischecked = true
                        }
                    })
                })
                setDropdownData(currentdata)
                setFilterDropdownData(currentdata)
            }
            else {
                setDropdownData(currentdata)
                setFilterDropdownData(currentdata)
            }
        }
    }

    const checkAllFilter = (mycheckbox) => {
        var lastfilter = [...dropdownData]
        let data1 = []
        var checkboxes = document.querySelectorAll("input[name = 'filter-option-all']");
        if (mycheckbox.target.checked == true) {
            
            let currentdata = lastfilter.map((i) => {
                i["ischecked"] = true;
                return i;
            });
            setDropdownData(currentdata)
           
            dropdownData.map((each) => {
             
                const getallData = {
                    Name: each.Name,
                }
                data1.push(getallData)
            })
            setFilterValue(filterValue => [...filterValue, ...data1]);
            let updateddata = ([...filterValue, ...data1]);
            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: updateddata,
            }));
        }
        else {
           
            let currentdata = lastfilter.map((i) => {
                i["ischecked"] = false;
                return i;
            });
            setFilterValue([])
            setMultiFilterValue([])
        }

    }
    const UserfilterHandler = (e) => {
        
        setFilterSearch(e.target.value)
        if (e.target.value !== "") {
            let searchResults = dropdownData.filter((each) => {
                return each.Name.toLowerCase().includes(String(e.target.value).toLowerCase())
            });
            setDropdownData(searchResults);
        }
        else {
         setFilterSearch("")
            setDropdownData(filterdropdownData)
        }
    }

    const filterHandler = (flag) => {

        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
        let pagenumber = 1;
        paging.pageNumber = pagenumber;

        if (flag === 'Apply') {
            setshowfilterpopup(false)
            let list = [];

            for (var key in multiFilterValue) {
                if (multiFilterValue[key].length > 0) {
                    let values = ""
                    multiFilterValue[key].map((i) => {
                        values += i.Name + ";";
                    })
                    if (values !== null && values !== '') {
                        list.push({
                            filterKey: key,
                            FilterValue: values
                        })
                    }
                }
            }
            for (var key in filterValueDict) {
                if (filterValueDict[key] !== null && filterValueDict[key] !== '') {
                    if (key === 'CashDiscountAmount' || key === 'AmountPaid' || key === 'BillAmount') {
                        list.push({
                            filterKey: key,
                            FilterValue: filterValueDict[key],
                            operatorValue: operatorVal.value
                        })
                    }
                    else {
                        list.push({
                            filterKey: key,
                            FilterValue: filterValueDict[key]
                        })
                    }
                }
            }
            if (list.length > 0) {
                let pagesize = 20;
                let pagenumber = 1;
                paging.pageSize = pagesize;
                paging.pageNumber = pagenumber;
                setpaging(paging);
                if (list.length > 0) {
                    setpaging(paging);
                   
                    getPaymentHistoryViewData("PaymentHistory", paging.pageSize, pagenumber, sortType, sortColName, list, searchValue);
                }
            }
        }
        else {
            setshowfilterpopup(false)
            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: '',
            }));
            setFilterValueDict(() => ({
                ...filterValueDict, [filterCol]: '',
            }));

            let list = [];
            for (var key in multiFilterValue) {
                if (key !== filterCol && multiFilterValue[key].length > 0) {
                    let values = ""
                    multiFilterValue[key].map((i) => {
                        values += i.Name + ";";
                    })
                    if (values !== null && values !== '') {
                        list.push({
                            filterKey: key,
                            FilterValue: values
                        })
                    }
                }
            }

            for (var key in filterValueDict) {
                if (filterValueDict[key] !== null && filterValueDict[key] !== '' && key !== filterCol) {
                    list.push({
                        filterKey: key,
                        FilterValue: filterValueDict[key]
                    })
                }
            }

            setpaging(paging);
            getPaymentHistoryViewData("PaymentHistory", paging.pageSize, pagenumber, sortType, sortColName, list, searchValue);
            setFilterCol('')
            setFilterValue('')
            setDropdownData([])
            setDropdownChange("")
            setFilterSearch("")
            setFilterDropdownData([])
            if (filterCol === 'accruals_aggregate') {
                setOperatorValue({ value: "=", label: "equals" });
            }
        }
    }
    const customMultiStyles = {
        multiselectContainer: {
            maxWidth: '188px',
            marginLeft: '3px'
        },
        chips: {
            borderRadius: '5px'
        }
    };


    // -----------------------------------------------------------------
    const columnList = [
        { "label": "Policies.SNo", "key": "SNo", "value": true },
        { "label": "Policies.DistributorCode", "key": "DistributorCode", "value": true },
        { "label": "Policies.DistributorName", "key": "DistributorName", "value": true },
        { "label": "Policies.DocNumber", "key": "DocNumber", "value": true },
        { "label": "Policies.BillNumber", "key": "BillNumber", "value": true },
        { "label": "Policies.BillDate", "key": "BillDate", "value": true },
        { "label": "Policies.BillAmount", "key": "BillAmount", "value": true },
        { "label": "Policies.CashDiscountAmount", "key": "CashDiscountAmount", "value": true },
        { "label": "Policies.AmountPaid", "key": "AmountPaid", "value": true },
        { "label": "payment.PaymentreceivedDate", "key": "PaymentreceivedDate", "value": true },
        { "label": "Policies.TransactionId", "key": "TransactionId", "value": true },
    ]
    const [addRemoveCol, setAddRemoveCol] = useState(columnList);
    const [createColumnListOption, setCreateColumnListOption] = useState(columnList)
    const [showAddColumnPopup, setShowAddColumnPopup] = useState(false)
    const [showCreateColumnListPopup, setShowCreateColumnListPopup] = useState(false)
    const [hideColumn, setHideColumn] = useState([]);
    const [resetColumnKey, setResetColumnKey] = useState([]);
    const [colVisibilityOption, setColVisibilityOption] = useState([]);
    const [colvisiOptionVal, setColvisiOptionVal] = useState({ value: null, label: 'Default' })
    const [divleft1, setdivleft1] = useState('');
    const [divtop1, setdivtop1] = useState('');
    const [existingViewVal, setExistingViewVal] = useState(false);
    const [defaultView, setDefaultView] = useState(false);
    const [colViewName, setColViewName] = useState('');
    const [recorddId, setRecordId] = useState(0);


    const OnChangedefaultView = (event,) => {
        setDefaultView(event.target.checked)
    }
    const OnChangeexistingViewVal = (event,) => {
        setExistingViewVal(event.target.checked)
    }

    const OnChangeSetViewName = (event,) => {
        setColViewName(event.target.value)
    }

    const OnChangeColVisibilityList = (data) => {
        setColvisiOptionVal({ value: data.value, label: data.label });
        getColVisibilityListData(data.value)
    }
    const CreateColumnListPopup = () => {
        setShowCreateColumnListPopup(true)
        var divtop1 = document.getElementById("addRemoveCol");
        setdivtop1(divtop1.offsetTop + 30)
        setdivleft1((divtop1.offsetLeft) + 100 + "px");
    }

    const CloseCreateColumnListPopup = () => {
        setShowCreateColumnListPopup(false)
        setColViewName('')
        setDefaultView(false)
    }

    const AddRemoveColumn = () => {
        setShowAddColumnPopup(true)
        var divtop1 = document.getElementById("addRemoveCol");
        setdivtop(divtop1.offsetTop + 45)
        setdivleft((divtop1.offsetLeft) + "px");
        getColumnVisibilityList()
    }
    const OnchangeAddRemoveCol = (event, column) => {
        let isChecked = event.target.checked;
        let lst = [...addRemoveCol];
        for (let d in lst) {
            if (lst[d].key == column.key && isChecked) {
                lst[d]['value'] = true
            }
            else if (lst[d].key == column.key && !isChecked) {
                lst[d]['value'] = false
            }
        }
        setResetColumnKey([...resetColumnKey, column.key])
        setAddRemoveCol(lst)
    }
    const OnchangeCreateColumnListOption = (event, column) => {
        let isChecked = event.target.checked;
        let lst = [...createColumnListOption];
        for (let d in lst) {
            if (lst[d].key == column.key && isChecked) {
                lst[d]['value'] = true
            }
            else if (lst[d].key == column.key && !isChecked) {
                lst[d]['value'] = false
            }
        }
        setCreateColumnListOption(lst)
    }
    const updateColumnVisibilityList = (tag) => {
        let lst = [...addRemoveCol];
        if (tag == 'reset') {
            for (let d in lst) {
                lst[d]['value'] = true
            }
            setHideColumn([])
            setAddRemoveCol(lst)
        }
        else {
            let data = [];
            for (let d in lst) {
                if (!lst[d].value) {
                    data.push(lst[d].key)
                }
            }
            setHideColumn(data)
        }
        let viewName = colvisiOptionVal.value;
        Service.setColumnVisibilityListData('ColumnVisibility/CreateOrUpdateColumnList', recorddId, loggedInUser, 'paymentHistoryList', viewName, existingViewVal, lst).then((res) => { })
        setShowAddColumnPopup(false)
        setResetColumnKey([])
    }


    const CreateColumnVisibilityList = (tag) => {
        let veiwName = colViewName;
        let idDefault = defaultView;
        if (veiwName.trim() == 'undefined' || veiwName.trim() == '' || veiwName.trim() == null) {
            setErrorStatus("Error")
            setErrorMessage("Please set View Name")
            setShowErrorModal(true)
        }
        else {
            let lst = [...createColumnListOption];
            if (tag == 'reset') {
                for (let d in lst) {
                    lst[d]['value'] = true
                }
                setHideColumn([])
                setAddRemoveCol(lst)
            }
            else {
                let data = [];
                for (let d in lst) {
                    if (!lst[d].value) {
                        data.push(lst[d].key)
                    }
                }
                setHideColumn(data)
            }
            Service.setColumnVisibilityListData('ColumnVisibility/CreateOrUpdateColumnList', 0, loggedInUser, 'paymentHistoryList', colViewName, defaultView, lst).then((res) => {
                getColumnVisibilityList()
                setColvisiOptionVal({ value: colViewName, label: colViewName });
                getColVisibilityListData(colViewName)
                CloseCreateColumnListPopup()
                setShowAddColumnPopup(false)
            })
        }
    }

    const closeAddRemoveColumnPopup = () => {
        let lst = [...addRemoveCol];
        let resetKey = [...resetColumnKey];
        for (let rk in resetKey) {
            for (let d in lst) {
                if (lst[d].key == resetKey[rk]) {
                    lst[d]['value'] = !lst[d].value
                }
            }
        }
        setAddRemoveCol(lst)
        setShowAddColumnPopup(false)
        setResetColumnKey([])

    }

    const getColumnVisibilityList = () => {
        Service.getColumnVisibilityListData('ColumnVisibility/GetColumnList', loggedInUser, 'paymentHistoryList', null).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                     
                        if (typeof res.data !== 'undefined' && res.data !== 'null') {
                            let data = []
                            for (let d in res.data) {
                                data.push({ value: res.data[d].label, label: res.data[d].label })

                            }
                         
                            setColVisibilityOption(data)
                        }
                    }
                }
            }
        })
    }


    const getColVisibilityListData = (viewName = null) => {
        if (!viewName) {
            viewName = colvisiOptionVal.value
        }
        if (viewName == 'Deleted') {
            viewName = null
        }
        Service.getColumnVisibilityListData('ColumnVisibility/GetColumnListJsonData', loggedInUser, 'paymentHistoryList', viewName).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (typeof res.data.jsonData !== 'undefined' && res.data.jsonData !== 'null') {
                          
                            let jsonData = JSON.parse(res.data.jsonData)
                            let data = [];
                            for (let d in jsonData) {
                                if (!jsonData[d].value) {
                                    data.push(jsonData[d].key)
                                }
                            }
                            setColvisiOptionVal({ value: res.data.label, label: res.data.label });
                            setHideColumn(data)
                            setAddRemoveCol(jsonData)
                            setExistingViewVal(res.data.defaultView)
                            setResetColumnKey([])
                            setRecordId(res.data.id)
                        }
                    }
                }
            }
        })
    }

    const deleteColumnVisibilityView = () => {
        Service.deleteDataByPolicyID("ColumnVisibility/Delete", recorddId, loggedInUser).then(res => {
            if (res.status === 200) {
                getColumnVisibilityList()
                getColVisibilityListData('Deleted')
                setShowAddColumnPopup(false)
                toast.error('Deleted Successfully !', {
                    position: toast.POSITION.TOP_RIGHT,
                });
             
            }
        })
    }
    // ------------------------------------------------------------

    return (
        <div className="container-fluid content-top-gap">

            <nav aria-label="breadcrumb">
                <ol className="breadcrumb my-breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">{t('Home.title')}</Link></li>

                    <li className="breadcrumb-item active" aria-current="page"><Link to="/policies">{t('Policies.Policy')}</Link></li>

                    <li className="breadcrumb-item active" aria-current="page">{`${t('payment.PaymentHistory')} ${t('payment.List')}`}</li>
                </ol>
            </nav>

            <div style={{ display: "none" }}>
                <CSVLink data={csvList} headers={headers} ref={csvInstance} filename="PaymentHistory.csv" > </CSVLink>
            </div>
            <div className="">
                <div className="row">
                    <div className="col-lg-12 pr-lg-2">
                        <div className="card text-center card_border overflow-auto">

                            <div className="card-body" style={{ minHeight: "70vh" }}>
                                <div className="row ml-1" style={{ position: "sticky" }} >

                                    <div className="col-md-7">
                                        <div className='row'>
                                            <input type="text" className="form-control form-control-user col-md-10" id="firstName" name="firstName" autoComplete='off' placeholder={`${t('SearchFor')}${t('Records')}`} onChange={(event) => searchHandler(event)} />
                                            <button id="addRemoveCol" icon className="btn btn-update ml-2 mt-1 pa-0" type="submit"
                                                style={{ float: "left", cursor: "pointer", padding: '0px', height: '29px', width: '26px' }}
                                                onClick={AddRemoveColumn} title='Manage View'><i class="fa fa-columns" aria-hidden="true"></i></button>
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                        {totalselectedcount <= 0 ? "" : Service.exportPerms(userPerms) == false ? "" : <button className="btn btn-update  btn-sm ml-2" type="submit" style={{ float: "right", width: "50px" }} onClick={(event) => ExporttoCSV()}>{t('Export')}</button>}
                                     

                                        {showbutton === true ?
                                            <>
                                                



                                            </>
                                            :
                                            ''
                                        } </div>
                                </div>

                                <div className="scrollbar-class">
                                    <table className="table table-bordered text-left mt-2" id="tableA">
                                        <thead className="text-white is-sticky-thead-product" style={{ backgroundColor: "var(--primary)" }}>
                                            <tr style={{ position: "sticky", top: "0", backgroundColor: "var(--primary)" }}>
                                                <th style={{ width: '30px' }}><input type="checkbox" id="option-all" onChange={checkAll} /></th>
                                                <th id="SNo" style={{ backgroundColor: (filterValueDict.SNo ? '#6cae55' : '') }} hidden={hideColumn.includes('SNo') ? true : false}>
                                                    <div className='d-flex text-nowrap'>
                                                        <span>{t('Policies.SNo')}</span>
                                                       
                                                    </div>
                                                </th>
                                                <th id="DistributorCode" style={{ backgroundColor: (filterValueDict.DistributorCode ? '#6cae55' : '') }} hidden={hideColumn.includes('DistributorCode') ? true : false}>
                                                    <div className='d-flex text-nowrap'>
                                                        <span>{t('Policies.DistributorCode')}</span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('DistributorCode')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('DistributorCode')}></span>
                                                    </div>
                                                </th>
                                                <th id="DistributorName" style={{ backgroundColor: (filterValueDict.DistributorName ? '#6cae55' : '') }} hidden={hideColumn.includes('DistributorName') ? true : false}>
                                                    <div className='d-flex text-nowrap'>
                                                        <span>{t('Policies.DistributorName')}</span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('DistributorName')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('DistributorName')}></span>
                                                    </div>
                                                </th>
                                                <th id="DocNumber" style={{ backgroundColor: (filterValueDict.DocNumber ? '#6cae55' : '') }} hidden={hideColumn.includes('DocNumber') ? true : false}>
                                                    <div className='d-flex text-nowrap'>
                                                        <span>{t('Policies.DocNumber')}</span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('DocNumber')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('DocNumber')}></span>
                                                    </div>
                                                </th>
                                                <th id="BillNumber" style={{ backgroundColor: (filterValueDict.BillNumber ? '#6cae55' : '') }} hidden={hideColumn.includes('BillNumber') ? true : false}>
                                                    <div className='d-flex text-nowrap'>
                                                        <span>{t('Policies.BillNumber')}</span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('BillNumber')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('BillNumber')}></span>
                                                    </div>
                                                </th>
                                                <th id="BillDate" style={{ backgroundColor: (filterValueDict.BillDate ? '#6cae55' : '') }} hidden={hideColumn.includes('BillDate') ? true : false}>
                                                    <div className='d-flex text-nowrap'>
                                                        <span>{t('Policies.BillDate')}</span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('BillDate')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('BillDate')}></span>
                                                    </div>
                                                </th>
                                                <th id="BillAmount" style={{ backgroundColor: (filterValueDict.BillAmount ? '#6cae55' : '') }} hidden={hideColumn.includes('BillAmount') ? true : false}>
                                                    <div className='d-flex text-nowrap'>
                                                        <span>{t('Policies.BillAmount')}</span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('BillAmount')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('BillAmount')}></span>
                                                    </div>
                                                </th>
                                              
                                                <th id="CashDiscountAmount" style={{ backgroundColor: (filterValueDict.CashDiscountAmount ? '#6cae55' : '') }} hidden={hideColumn.includes('CashDiscountAmount') ? true : false}>
                                                    <div className='d-flex text-nowrap'>
                                                        <span>{t('Policies.CashDiscountAmount')}</span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('CashDiscountAmount')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('CashDiscountAmount')}></span>
                                                    </div>
                                                </th>
                                                <th id="AmountPaid" style={{ backgroundColor: (filterValueDict.AmountPaid ? '#6cae55' : '') }} hidden={hideColumn.includes('AmountPaid') ? true : false}>
                                                    <div className='d-flex text-nowrap'>
                                                        <span>{t('Policies.AmountPaid')}</span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('AmountPaid')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('AmountPaid')}></span>
                                                    </div>
                                                </th>
                                                <th id="PaymentreceivedDate" style={{ backgroundColor: (filterValueDict.PaymentreceivedDate ? '#6cae55' : '') }} hidden={hideColumn.includes('PaymentreceivedDate') ? true : false}>
                                                    <div className='d-flex text-nowrap'>
                                                        <span>{t('payment.PaymentreceivedDate')}</span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('PaymentreceivedDate')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('PaymentreceivedDate')}></span>
                                                    </div>
                                                </th>
                                                <th id="TransactionId" style={{ backgroundColor: (filterValueDict.TransactionId ? '#6cae55' : '') }} hidden={hideColumn.includes('TransactionId') ? true : false}>
                                                    <div className='d-flex text-nowrap'>
                                                        <span>{t('Policies.TransactionId')}</span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('TransactionId')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('TransactionId')}></span>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                showspinner === true ?
                                                    <tr>
                                                        <>
                                                            <Spinner animation="grow" size="sm" variant="success" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                            <Spinner animation="grow" size="sm" variant="danger" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                            <Spinner animation="grow" size="sm" variant="warning" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                        </>
                                                    </tr>

                                                    :
                                                    filteredArray.length === 0 ?
                                                        <tr>
                                                            <td></td>
                                                            <td colSpan="11" style={{ textAlign: 'center' }}>
                                                                {t('NoDataAvailable')}
                                                            </td>
                                                        </tr>
                                                        :
                                                        filteredArray.map((listVal, index) =>
                                                            <tr onDoubleClick={(event) => OpenPolicy(event, listVal.Id)}>
                                                                <td ><input id={listVal.Id} type="checkbox" value={""} onChange={(event) => handleChange(event, listVal.Id, listVal.Name, listVal.ISActive)} name="chkBox" /></td>
                                                                <td hidden={hideColumn.includes('SNo') ? true : false}>{index + 1}</td>
                                                                <td hidden={hideColumn.includes('DistributorCode') ? true : false}>{listVal.DistributorCode}</td>
                                                                <td hidden={hideColumn.includes('DistributorName') ? true : false}>{listVal.DistributorName}</td>
                                                                <td hidden={hideColumn.includes('DocNumber') ? true : false}>{listVal.DocNumber}</td>
                                                                <td hidden={hideColumn.includes('BillNumber') ? true : false}>{listVal.BillNumber}</td>
                                                                <td hidden={hideColumn.includes('BillDate') ? true : false}>{moment(listVal.BillDate).format(SessionManage.getcurrentdateformat())}</td>
                                                                <td hidden={hideColumn.includes('BillAmount') ? true : false}>{listVal.Currency + " " + Service.currencyFormat(parseFloat(listVal.BillAmount).toFixed(SessionManage.getroundingPrecision()))}</td>
                                                                <td hidden={hideColumn.includes('CashDiscountAmount') ? true : false}>{listVal.Currency + " " + Service.currencyFormat(parseFloat(listVal.CashDiscountAmount).toFixed(SessionManage.getroundingPrecision()))}</td>
                                                                <td hidden={hideColumn.includes('AmountPaid') ? true : false}>{listVal.Currency + " " + Service.currencyFormat(parseFloat(listVal.AmountPaid).toFixed(SessionManage.getroundingPrecision()))}</td>
                                                                <td hidden={hideColumn.includes('PaymentreceivedDate') ? true : false}>{moment(listVal.PaymentreceivedDate).format(SessionManage.getcurrentdateformat())}</td>
                                                                <td hidden={hideColumn.includes('TransactionId') ? true : false}>{listVal.TransactionId}</td>

                                                            </tr>
                                                        )

                                            }


                                        </tbody>
                                    </table>
                                </div>
                                <div className='row'>
                                    {
                                        totalselectedshow === true ?

                                            <div className='col-lg-2 forgettext col-sm-12 border form-control mt-1' style={{ textAlign: "center", marginLeft: "0.938rem" }}>
                                                {t('TotalRecordsSelected')} - <span id="totalselected">{totalselectedcount}</span>
                                            </div>

                                            :
                                            <div className='col-lg-2  col-sm-12' style={{ textAlign: "center" }}>

                                            </div>
                                    }



                                    <div className='col-lg-7 col-sm-12'></div>


                                    <div className='col-lg-2 forgettext  col-sm-12 border form-control mt-1' style={{ textAlign: "center" }}>
                                        {t('TotalRecords')} - {totalCountlst}
                                    </div>
                                </div>
                                {
                                    showLoadMore === true ?
                                        <div className='text-center container row'>
                                            <div className='col-md-5'></div>
                                            <div className='col-md-2'>
                                                <button className='btn btn-sm btn-update ml-1' onClick={handleLoadMore} style={{ width: "70px" }}>{t('LoadMore')}</button>
                                            </div>
                                            <div className='col-md-5'></div>

                                        </div> : ""}
                                {showfilterpopup === true ?
                                    <div className='pop-up' style={{ left: divleft, top: divtop }}>
                                       
                                        {filterCol === 'DistributorCode' || filterCol === 'SNo' || filterCol === 'DocNumber' || filterCol === 'BillNumber'
                                            || filterCol === 'TransactionId' || filterCol === 'DistributorName' ?
                                            <>
                                                <div className="container">
                                                    <div className="row">
                                                        <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>
                                                    </div>
                                                </div>
                                                <div className="container mt-2">
                                                    <div className="row">
                                                        <input type='textbox' placeholder={t('Search')} className='filtertextboxbackground col-md-11 textbox-padding ml-2' style={{ outline: 'none' }} value={filterValueDict[filterCol]} onChange={(event) => filterInput(event)}></input>
                                                    </div>
                                                </div>
                                            </>

                                            :
                                            <>
                                            </>

                                        }

                                        {filterCol === 'BillDate' || filterCol === 'PaymentreceivedDate' ?

                                            <>
                                                <div className="container">
                                                    <div className="row">
                                                        <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>
                                                    </div>
                                                </div>
                                                <div className="container mt-2">
                                                    <div className="row">
                                                        <input type='date' className='filtertextboxbackground col-md-11 textbox-padding ml-2' value={filterValueDict[filterCol]} onChange={(event) => filterInput(event)}></input>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <></>
                                        }

                                        {filterCol === 'LastModifiedBy' || filterCol === 'status' || filterCol === 'CreatedBy' ?
                                            
                                            <>
                                                <div className="container">
                                                    <div className="row">
                                                        <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>

                                                    </div>

                                                </div>

                                                <div className="container mt-2">
                                                    <div className="row">
                                                        <input type="text" placeholder={t('Search')} value={filterSearch} onChange={(event) => UserfilterHandler(event)} className='filtertextboxbackground col-md-11 textbox-padding ml-2' />
                                                    </div>

                                                    <div className="row contentoverflow mt-2">
                                                        <table className="filtertable">
                                                            <tbody>
                                                                <tr>
                                                                    <td><input type="checkbox" name="filter-option-all" id="option-all" onChange={checkAllFilter} /></td>
                                                                    <td>{t('SelectAll')}</td>
                                                                </tr>
                                                                {
                                                                    dropdownData.map((item, index) => (

                                                                        <tr key={index}>

                                                                            <td><input id={item.Id} data-index="1" name="filter-option-all" checked={item.ischecked} type="checkbox" onChange={(event) => OndropdownChange(event, item.Id, item.Name)} /></td>

                                                                            <td>{item.Name}</td>

                                                                        </tr>


                                                                    ))
                                                                }

                                                            </tbody>
                                                        </table>

                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <></>
                                        }
                                      
                                        {filterCol === 'BillAmount' || filterCol === 'CashDiscountAmount' || filterCol === 'AmountPaid' ?
                                            <div>
                                                <div className="row">
                                                    <div className="row" style={{ marginLeft: "40px", paddingBottom: "5px" }}>
                                                        <b>{filtertext}</b>
                                                    </div>
                                                    <Select
                                                        className='col-md-9 textbox-padding ml-4 fontsizedropdown'
                                                        options={operatorValOption}
                                                        placeholder="Select"
                                                        value={operatorVal}
                                                        onChange={OnChangeOperatorValue}
                                                        styles={customStyles}
                                                    />
                                                </div>
                                                <div className="mt-2">
                                                    <input type='text' className='filtertextboxbackground col-md-11 textbox-padding ml-2' onKeyPress={(e) => !/^(\d)*(\.)?([0-9]{1})?$/.test(e.key) && e.preventDefault()}
                                                        value={filterValueDict[filterCol]} onChange={(event) => filterInput(event)} />
                                                </div>
                                            </div>
                                            :
                                            <></>
                                        }
                                        <div style={{ paddingTop: '10px', paddingLeft: '47px', paddingBottom: '7px' }}>
                                            <button className="btn btn-sm btn-update" onClick={() => filterHandler('Apply')} style={{ width: "45px" }}>{t('Apply')}</button>
                                            <button className="btn btn-sm btn-update" style={{ marginLeft: '10px', width: "45px" }} onClick={() => filterHandler('Reset')}>{t('Reset')}</button>
                                        </div>
                                        <div>
                                        </div>
                                    </div>
                                    :
                                    <></>
                                }

                                {showAddColumnPopup === true ?
                                    <div className='pop-up' style={{ left: divleft, top: divtop, width: "250px" }}>
                                        <div className="container">
                                            <div className="row">
                                                <Select
                                                    className='col-md-10 textbox-padding fontsizedropdown'
                                                    options={colVisibilityOption}
                                                    placeholder="Select"
                                                    value={colvisiOptionVal}
                                                    onChange={OnChangeColVisibilityList}
                                                    styles={customStyles}
                                                />
                                                <button id="createColumnListPopup" icon className="btn btn-update ml-1 pa-0" type="submit"
                                                    style={{ float: "left", cursor: "pointer", padding: '0px', height: '28px', width: '26px' }}
                                                    onClick={CreateColumnListPopup} title='Add New View'><i class="fa fa-plus" aria-hidden="true"></i></button>
                                            </div>
                                            <div className="row mb-2" style={{ textAlign: 'right' }}>
                                                <div className="col-md-10 mt-1 mr-1" style={{ padding: '0px', fontSize: '12px' }}>
                                                    <input id="existingViewVal" data-index="1" name="filter-option-all" checked={existingViewVal} type="checkbox"
                                                        onChange={(event) => OnChangeexistingViewVal(event)} /> <b>Is Default</b>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <table className='ml-2' style={{ fontSize: '0.80rem' }}>
                                                    {
                                                        addRemoveCol.map((item, index) => (
                                                            <tr>
                                                                <td key={index}><input id={item.label} data-index="1" name="filter-option-all" checked={item.value} type="checkbox"
                                                                    onChange={(event) => OnchangeAddRemoveCol(event, item)}
                                                                /> {t(item.label)}</td>
                                                            </tr>
                                                        ))
                                                    }
                                                </table>
                                            </div>
                                            <div className="row mr-2" style={{ float: "right" }}>
                                                <div style={{ paddingTop: '10px', paddingLeft: '15px', paddingBottom: '7px' }}>
                                                    <button className="btn btn-sm btn-update" style={{ width: "45px" }} onClick={() => updateColumnVisibilityList('reset')}>{t('Reset')}</button>
                                                    <button className="btn btn-sm btn-update ml-1" style={{ width: "45px" }} onClick={closeAddRemoveColumnPopup}>{t('Close')}</button>
                                                    <button className="btn btn-sm btn-update ml-1" style={{ width: "50px", background: '#4755ab', color: 'white' }} onClick={() => updateColumnVisibilityList('apply')} title='Update View'>{t('Update')}</button>
                                                    <button className="btn btn-sm btn-update ml-1" style={{ width: "50px", background: 'rgb(233 43 0)', color: 'white' }} onClick={deleteColumnVisibilityView} title='Delete View'>{t('Delete')}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : <></>
                                }


                                {showCreateColumnListPopup === true ?
                                    <div className='pop-up' style={{ left: divleft1, top: divtop1, width: "190px" }}>
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-12 ml-2" style={{ padding: '0px' }}>
                                                    <input type='textbox' placeholder='View Name' style={{ height: '30px' }}
                                                        value={colViewName} name={colViewName} onChange={(event) => OnChangeSetViewName(event)}></input>
                                                </div>
                                            </div>
                                            <div className="row mb-2" style={{ textAlign: 'right' }}>
                                                <div className="col-10 mt-2" style={{ padding: '0px', fontSize: '12px' }}>
                                                    <input id="defaultView" data-index="1" name="filter-option-all" checked={defaultView} type="checkbox"
                                                        onChange={(event) => OnChangedefaultView(event)} /> <b>Set Default</b>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <table className='ml-2' style={{ fontSize: '0.80rem' }}>
                                                    {
                                                        createColumnListOption.map((item, index) => (
                                                            <tr>
                                                                <td key={index}><input id={item.label} data-index="1" name="filter-option-all" checked={item.value} type="checkbox"
                                                                    onChange={(event) => OnchangeCreateColumnListOption(event, item)}
                                                                /> {t(item.label)}</td>
                                                            </tr>
                                                        ))
                                                    }
                                                </table>
                                            </div>
                                            <div className="row mr-2" style={{ float: "right" }}>
                                                <div style={{ paddingTop: '10px', paddingLeft: '35px', paddingBottom: '7px' }}>
                                                    <button className="btn btn-sm btn-update ml-1" style={{ width: "50px" }} onClick={CloseCreateColumnListPopup}>{t('Cancel')}</button>
                                                    <button className="btn btn-sm btn-update ml-1" style={{ width: "40px", background: '#4755ab', color: 'white' }} onClick={() => CreateColumnVisibilityList('apply')}>{t('Save')}</button>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                    : <></>
                                }

                            </div>

                        </div>
                    </div>

                </div>
            </div >

            <CustomErrorPopup ErrorMessage={errorMessage} show={showErrorModal} ErrorStatus={errorStatus}
                onHide={() => setShowErrorModal(false)}></CustomErrorPopup>
            <ToastContainer autoClose={1000} />
        </div >

    )

}
export default PaymentHistoryComponent