
import React, { Component } from 'react' 
import { useNavigate ,useParams } from 'react-router-dom';
import PendingActionComponent from '../../../Components/PoliciesComponent/DistributorBudgetedAmountPolicy/ApprovalsComponent/PendingActionComponent';
function DistributorBudgetedAmountPolicyPendingAction(props){
    let navigate = useNavigate();
    const params = useParams();

    return (  
        <div> 
            <PendingActionComponent {...props}  navigate={navigate} id={params.id} name={params.name} value={params.value}/>
        </div>
    );  
}
export default DistributorBudgetedAmountPolicyPendingAction