import { Link, useLocation } from "react-router-dom"
import React, { useState, useEffect } from 'react'
import classes from '../../RebateProgramComponent/CreateRebateComponent/Form.module.css'
import { Form, FormGroup, Col, Row, Button, InputGroup, Collapse, Modal } from 'react-bootstrap';
import Service from '../../../Services/Service';
import 'react-multiple-select-dropdown-lite/dist/index.css'
import CustomErrorPopup from '../../../UI/CustomErrorPopup';
import Multiselect from 'multiselect-react-dropdown';
import Select from "react-select";
import $ from 'jquery';
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function CreateNewSubDivideTargetPolicy(props) {

    const location = useLocation();
    const [userPerms, setuserPermission] = useState([]);

    let rowData = [];
    const [t, i18n] = useTranslation('translation');
    const [listViewData, setListViewData] = useState(rowData);
    const [errorMessage, setErrorMessage] = useState("");
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorStatus, setErrorStatus] = useState("Error");
    const [isValid, setIsValid] = useState(true);
    const [policyStatus, setPolicyStatus] = useState(false);
    const [rebateTypeData, setRebateTypeData] = useState([]);
    const [closeModal, setCloseModal] = useState(false);
    const [handleChange, setHandleChange] = useState(false);
    const [selectedOptionsRebateType, setSelectedOptionsRebateType] = useState();
    let loggedInUser = sessionStorage.getItem("id");
    const [isLocked, setIsLocked] = useState(false);
    const [lockedBy, setLockedBy] = useState('');
    let disable = false;
    const [inputField, setInputField] = useState({
        name: '',
        label: '',
        NoOfDays: '',
        CreatedUserId: loggedInUser,
        LastModifiedUserId: loggedInUser,
    })
    useEffect(() => {
        getUserPermission(location.pathname);
        if (props.id !== 0) {
            getdata("SubDivideTarget/GetById", props.id);

        }
        
    }, [])
    const getUserPermission = (pathname) => {
        Service.GetUserPermission(pathname).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res !== undefined) {
                        if (res.data !== 'null' && res.data !== undefined && res.data !== '') {
                            if (res.data.DataList !== undefined) {
                                setuserPermission(res.data.DataList)
                            }
                        }
                    }
                }
            }

        });
    }
    const getdata = (method, id) => {
        
        Service.getdatabyId(method, id).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                            setInputField(res.data);
                            setPolicyStatus(true)
                            setLockedBy(res.data.LockedBy);
                            setIsLocked(res.data.Locked);
                            
                        }
                    }
                }
            }
         
        });
        
    }


    const inputsHandler = (e) => {
        setHandleChange(true)
     
        if (policyStatus === false) {
            const { name, value } = e.target;
            setInputField(() => ({
                ...inputField, [name]: value,
            }));
        }
        else {
            const { name, value } = e.target;
            setInputField(() => ({
                ...inputField, [name]: value,
            }));
        }
    }

    const checkValidation = () => {
       
        if (inputField.name == null || inputField.name == '') {
            setErrorMessage(`${t('Please Enter')}${t('Policies.Name')}`)
            setIsValid(false)
            setShowErrorModal(true)
        }
        else if (inputField.label == '' || inputField.label == null) {
            setErrorMessage(`${t('Please Enter')}${t('Policies.Label')}`)
            setIsValid(false)
            setShowErrorModal(true)
        }
        else {
            saveHandler(true)
        }
    }


    const saveHandler = (isValid) => {
        let data = inputField
        data['LastModifiedUserId'] = loggedInUser
        if (isValid === true) {
            Service.createorupdate("SubDivideTarget/CreateOrUpdate", data).then(res => {
                if (typeof res !== 'undefined') {
                    if (typeof res.data !== 'undefined' && res.data !== 'null') {
                       
                        toast.success('Saved successfully !', {
                            position: toast.POSITION.TOP_RIGHT,
                            onClose: () => props.navigate('/SubDivideTargetPolicy')
                        });
                    }
                    else {
                        setErrorMessage(t('Policies.SamePolicyAlreadyExists'))
                        setShowErrorModal(true)
                        
                    }
                }
            })

        };
    }

    const getRebateTypeData = (method) => {
        Service.getAlldata(method).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                            setRebateTypeData(res.data)
                        }
                    }
                }
            }

        });
    }
    const customStyles = {
        control: base => ({
            ...base,
            minHeight: 28
        }),
        dropdownIndicator: base => ({
            ...base,
            padding: 2,
            fontSize: "0.75rem",
        }),
        clearIndicator: base => ({
            ...base,
            padding: 2
        }),
        multiValue: base => ({
            ...base,
            fontSize: "0.75rem",
        }),
        valueContainer: base => ({
            ...base,
            padding: '0px 2px',
            fontSize: "0.75rem",
        }),
        input: base => ({
            ...base,
            margin: 0,
            padding: 0
        })
    };
    const OnChangeHandlerRebateType = (data) => {
        setSelectedOptionsRebateType(data);
        setInputField(() => ({
            ...inputField, ['RebateTypeId']: data.value, ['RebateType']: data.label,
        }));
    };


    const closeHandler = (flag) => {
        if (flag == "Open" && handleChange == true) {
            setCloseModal(true)
        }
        if (flag == "Open" && handleChange == false) {
           
            props.navigate("/SubDivideTargetPolicy")
        }
        if (flag == "Close") {
           
            props.navigate("/SubDivideTargetPolicy")
        }
        if (flag == "Cancel") {
            setCloseModal(false)
        }

    }
    if (isLocked === false || (isLocked === true && lockedBy.toString() === loggedInUser)) {
        disable = false;
    }
    else {
        disable = true;
    }
    window.onbeforeunload = function () {
        unlockEntity();
        
    };

    const unlockEntity = () => {
        Service.LockUnLockEntity('Configurationprop/UnLockEntity', "RebateMeasure", props.id, loggedInUser).then((res) => {

        });

    }
    const class_textbox = `col-md-2 mt-2 textbox-padding form-control ${Service.editPerms(userPerms) == false ? "disabledClass" : ""}`;
    const class_select = ` col-md-2 textbox-padding mt-2 fontsizedropdown  ${Service.editPerms(userPerms) == false ? "disabledClass" : ""}`;

    return (
        <>
            <div className="container-fluid content-top-gap">

                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb my-breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">{t('Home.title')}</Link></li>
                        <li className="breadcrumb-item active" aria-current="page"><Link to="/SubDivideTargetPolicy">{`${t('Policies.SubDivideTarget')}${t('Policies.PolicyList')}`}</Link></li>
                        <li className="breadcrumb-item active" id="libread" aria-current="page">{`${t('Policies.CreateNew')}${t('Policies.SubDivideTarget')}${t('Policies.Policy')}`}</li>
                    </ol>
                </nav>

                <div className="row mt-3" id="rebateProgramdiv">
                    <div className="col-lg-12 pr-lg-2">
                        <div className="card text-center card_border">
                            <div className="card-header chart-grid__header">
                                <div className="row">
                                    <div className="col-md-7 text-left">
                                        {`${t('Policies.CreateNew')}${t('Policies.SubDivideTarget')}${t('Policies.Policy')}`}
                                    </div>
                                    <div className="col-md-5">
                                        {Service.editPerms(userPerms) == false ? "" : <button className="btn  btn-update btn-sm ml-2" type="submit" style={{ float: "right" }} onClick={checkValidation}>Save</button>}
                                        <button className="btn  btn-update btn-sm ml-2" id="close" style={{ width: "80px", backgroundColor: "buttonface", float: "right" }} onClick={(event) => closeHandler("Open")} >Close   </button>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body" style={{ minHeight: "60vh" }}>
                                <div className="container">
                                    <div className="row">
                                        <label className="col-md-2 text-left">{t('Policies.Name')}<span className={classes.mandatory}>*</span></label>
                                        <input name="Name" type="text" autoComplete='off' onChange={inputsHandler} className="col-md-2 mt-2 textbox-padding form-control" value={inputField.name} disabled={policyStatus} />
                                        <div className="col-md-2">
                                        </div>
                                    </div>
                                    <div className="row">
                                        <label className="col-md-2 text-left">{t('Policies.Label')}<span className={classes.mandatory}>*</span></label>
                                        <input onKeyPress={(e) => { e.key === ' ' && e.preventDefault(); }} name="label" type="text" autoComplete='off' onChange={inputsHandler} className={class_textbox} value={inputField.label} />
                                        <div className="col-md-2">
                                        </div>
                                    </div>
                                  
                                </div>


                            </div>

                        </div>
                    </div>
                </div>
                <Modal show={closeModal}
                    size="sm"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <Modal.Body style={{ "textAlign": 'center', fontFamily: 'Segoe UI' }}>{t('Policies.YourChangesWillBeLost!AreYouSureYouWantToClose?')}</Modal.Body>
                    <Modal.Footer>
                        <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black' }} size='sm' onClick={() => closeHandler("Close")}>
                            {t('Close')}
                        </Button>
                        <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black' }} size='sm' onClick={() => closeHandler("Cancel")}>
                            {t('Cancel')}
                        </Button>
                    </Modal.Footer>
                </Modal>
                <CustomErrorPopup ErrorMessage={errorMessage} show={showErrorModal} ErrorStatus={errorStatus}
                    onHide={() => setShowErrorModal(false)}></CustomErrorPopup>
            </div>
            <ToastContainer autoClose={1000} />
        </>
    )
}
export default CreateNewSubDivideTargetPolicy