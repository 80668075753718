import React, { useEffect, useState } from "react";
import BarChart from "./Charts/Barchart";
import { ArcElement } from "chart.js";
import Chart from "chart.js/auto";
import ActivateRebateProgram from "./Charts/ActivateRebateProgram";
import AccuredvsProjected from "./Charts/AccuredvsProjected";
import AccuredvsForecast from "./Charts/AccuredvsForecast";
import TopBenficiaries from "./Charts/Topbenficiaries";
import TopProduct from "./Charts/TopProduct";
import { DashBoard } from "./DashBoard.css";
import Service from "../../Services/Service";
import { Bar, Line, Doughnut } from "react-chartjs-2";
import Select from "react-select";
import { Link, useLocation } from 'react-router-dom';

function ForecastDashboard() {
  const location = useLocation();
  const [currencyData, setCurrencyData] = useState([]);
  const [selectedOptionsCurrency, setSelectedOptionsCurrency] = useState();
  const [selectedOptionsMonths, setSelectedOptionsMonths] = useState();
  const [userPerms, setuserPermission] = useState([]);

  const [selectedOptionsDateRange, setselectedOptionsDateRange] = useState();
  const [selectedOptionsProduct, setselectedOptionsProduct] = useState();
  const [dateRange, setDateRange] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [lstMonth, setLstMonth] = useState([]);
  const [prefixCurrency, setPrefixCurrency] = useState("");
  const [defaultCurrency, setdefaultCurrency] = useState("");
  const [loading, setLoading] = useState(false);

  const [DataSet, setDataSet] = useState("");
  let name = sessionStorage.getItem("name");
  let chartReference = React.createRef();
  const plotOptions = {
    indexAxis: "y",
    maintainAspectRatio: false,
    plugins: {
      title: { display: true, text: "Active Rebate Program By Type" },
    },
    scales: { yAxes: { beginAtZero: true } },
  };
  const AccruedvsProjectedStatusOptions = {
    indexAxis: "y",
    plugins: {
      title: { display: true, text: "Accrued Vs Projected By Program Status" },
    },
    scales: {
      xAxes: {
        beginAtZero: true,
        ticks: {
          callback: function (value) {
            return prefixCurrency + "" + value;
          },
        },
      },
    },
  };

  const AccruedVsForecastDataOptions = {
    plugins: { title: { display: true, text: "Accrued Vs Forecast Data" } },
    scales: {
      yAxes: {
        beginAtZero: true,
        ticks: {
          callback: function (value) {
            return prefixCurrency + "" + value;
          },
        },
      },
    },
  };
  const PostedvsUnpostedOptions = {
    indexAxis: "y",
    plugins: { title: { display: true, text: "Top 10 Beneficiaries" } },
    scales: {
      xAxes: {
        beginAtZero: true,
        ticks: {
          callback: function (value) {
            return prefixCurrency + "" + value;
          },
        },
      },
    },
  };
  const revenueVsPayoutOptions = {
    indexAxis: "y",
    plugins: { title: { display: true, text: "Revenue Vs Payout" } },
    scales: {
      xAxes: {
        beginAtZero: true,
        ticks: {
          callback: function (value) {
            return prefixCurrency + "" + value;
          },
        },
      },
    },
  };

  const Top10ProductsOptions = {
    indexAxis: "y",
    plugins: { title: { display: true, text: "Top 10 Products by Rebate" } },
    scales: {
      xAxes: {
        beginAtZero: true,
        ticks: {
          callback: function (value) {
            return prefixCurrency + "" + value;
          },
        },
      },
    },
  };

  useEffect(() => {
    var year = new Date().getFullYear();
    getCurrencyData("currency");
    GetDateRangeAndProducts("DashBoard/GetDateRangeAndProductsForecast");
    setPrefixCurrency("$");
    getUserPermission('/');
  }, [0]);

  const disabledClass = `${Service.editPerms(userPerms) == false ? "disabledClass" : true}`;




  const getUserPermission = (pathname) => {
    Service.GetUserPermission(pathname).then((res) => {
      if (typeof res !== 'undefined') {
    
        if (res.data !== 'null' && typeof res.data !== 'undefined' && res.data !== '') {
          if (res.data.DataList !== undefined) {
            setuserPermission(res.data.DataList)
           
          }
        }
      }
    });
  }

  const getCurrencyData = (method) => {
    Service.getAlldata(method).then((res) => {
      if (typeof res !== "undefined") {
        if (res.data !== "null" && typeof res.data !== "undefined") {
          setCurrencyData(res.data);
          var defCurrency = res.data.find((x) => x.isoCode === "USD");
          if (defCurrency !== "undefined" && defCurrency !== null) {
            setSelectedOptionsCurrency({
              value: defCurrency.value,
              label: defCurrency.label,
            });
          }
        }
      }
    });
  };
  const getData = (method) => {
    Service.getdata(method).then((res) => {
      if (Service.checkUndefined(res) === true) {
        if (Service.checkDataUndefined(res.data) == true) {
          setDataSet(res.data);
          setLoading(false);
        }
      }
    });
  };
  const GetDateRangeAndProducts = (method) => {
    Service.getdata(method).then((res) => {
      if (Service.checkUndefined(res) === true) {
        if (Service.checkDataUndefined(res.data) == true) {
          setDateRange(res.data.lstDateRange);
          setAllProducts(res.data.lstProduct);
          setLstMonth(res.data.lstMonth);
          var defProd = res.data.lstProduct.find((x) => x.label === "All");
          if (defProd !== "undefined" && defProd !== null) {
            setselectedOptionsProduct({
              value: defProd.value,
              label: defProd.label,
            });
          }

          var months = res.data.lstMonth.find((x) => x.label === "All");
          if (months !== "undefined" && months !== null) {
            setSelectedOptionsMonths({
              value: months.value,
              label: months.label,
            });
          }

          //var year=new Date().getFullYear();

          var defDateRange = res.data.lstDateRange.find(
            (x) => x.defaultValue === true
          );
          if (typeof defDateRange !== "undefined" && defDateRange !== null) {
            setselectedOptionsDateRange({
              value: defDateRange.value,
              label: defDateRange.label,
            });
          }
          getData(
            "DashBoard/GetForecastDashBoard?DateRange=" +
            defDateRange.value +
            "&Currency=6&Products=0&Months=0"
          );
        }
      }
    });
  };

  const OnChangeDateRange = (e) => {
    setselectedOptionsDateRange({ value: e.value, label: e.label });
    getData(
      "DashBoard/GetForecastDashBoard?DateRange=" +
      e.value +
      "&Currency=" +
      selectedOptionsCurrency.value +
      "&Products=" +
      selectedOptionsProduct.value +
      "&Months=" +
      selectedOptionsMonths.value
    );
    setLoading(true);
  };
  const OnChangeCurrency = (e) => {
    setPrefixCurrency(e.Prefix);
    setSelectedOptionsCurrency({ value: e.value, label: e.label });
    getData(
      "DashBoard/GetForecastDashBoard?DateRange=" +
      selectedOptionsDateRange.value +
      "&Currency=" +
      e.value +
      "&Products=" +
      selectedOptionsProduct.value +
      "&Months=" +
      selectedOptionsMonths.value
    );
    setLoading(true);
  };
  const OnChangeMonths = (e) => {
    setSelectedOptionsMonths({ value: e.value, label: e.label });
    getData(
      "DashBoard/GetForecastDashBoard?DateRange=" +
      selectedOptionsDateRange.value +
      "&Currency=" +
      selectedOptionsCurrency.value +
      "&Products=" +
      selectedOptionsProduct.value +
      "&Months=" +
      e.value
    );
    setLoading(true);
  };

  const OnChangeProduct = (e) => {
    setselectedOptionsProduct({ value: e.value, label: e.label });
    getData(
      "DashBoard/GetForecastDashBoard?DateRange=" +
      selectedOptionsDateRange.value +
      "&Currency=" +
      selectedOptionsCurrency.value +
      "&Products=" +
      e.value +
      "&Months=" +
      selectedOptionsMonths.value
    );
    setLoading(true);
  };

  const targetHeight = 25;

  const styles = {
    control: (base) => ({
      ...base,
      minHeight: "initial",
    }),
    valueContainer: (base) => ({
      ...base,
      height: `${targetHeight - 1 - 1}px`,
      padding: "0 8px",
    }),
    clearIndicator: (base) => ({
      ...base,
      padding: `${(targetHeight - 20 - 1 - 1) / 2}px`,
    }),
    dropdownIndicator: (base) => ({
      ...base,
      padding: `${(targetHeight - 20 - 1 - 1) / 2}px`,
    }),
  };

  Chart.register(ArcElement);
  return (
    <>
      <div className="container-fluid content-top-gap">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb my-breadcrumb">
            <li className="breadcrumb-item">
              <a href="/">Home</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Forecast Dashboard
            </li>
          </ol>
        </nav>
        <div className="text_align_center">
          <h4>Forecast Dashboard</h4>
        </div>

        {loading ? (
          <>
            <div className="loader-container">
              <div className="spinner"></div>
            </div>
          </>
        ) : (
          ""
        )}

        {
          <>
            <div className="statistics">
              <div className="row">
                <div className="col-xl-6 pr-xl-2">
                  <div className="row">
                    <div className="col-sm-6 pr-sm-2 statistics-grid">
                      <div className="card card_border border-primary-top p4 ">
                        <div class="row">
                          <label class="col-md-5 text-left">Date Range</label>
                          <Select
                            isDisabled="true"
                            styles={styles}
                            className={`col-md-5 textbox-padding-dropdown fontsizedropdown  textbox-padding ${disabledClass}`}
                            options={dateRange}
                            placeholder="Select"
                            isSearchable={true}
                            value={selectedOptionsDateRange}
                            onChange={OnChangeDateRange}
                          />

                          <div class="col-md-1"></div>
                          <label class="col-md-5 mt-2 text-left">
                            Quarter/Month
                          </label>

                          <Select
                            styles={styles}
                            className={`col-md-5 textbox-padding-dropdown fontsizedropdown mt-2 textbox-padding ${disabledClass}`}
                            options={lstMonth}
                            placeholder="Select"
                            isSearchable={true}
                            onChange={OnChangeMonths}
                            value={selectedOptionsMonths}
                          />

                          <div class="col-md-1"></div>
                          <label class="col-md-5 mt-2 text-left">
                            Currency
                          </label>

                          <Select
                            styles={styles}
                            className={`col-md-5 textbox-padding-dropdown mt-2 fontsizedropdown  textbox-padding ${disabledClass}`}
                            options={currencyData}
                            placeholder="Select"
                            isSearchable={true}
                            onChange={OnChangeCurrency}
                            value={selectedOptionsCurrency}
                          />

                          <div class="col-md-1"></div>
                          <label class="col-md-5 mt-2 text-left">
                            Products
                          </label>
                          <Select
                            styles={styles}
                            className={`col-md-5 textbox-padding-dropdown mt-2 fontsizedropdown  textbox-padding ${disabledClass}`}
                            options={allProducts}
                            placeholder="Select"
                            isSearchable={true}
                            value={selectedOptionsProduct}
                            onChange={OnChangeProduct}
                          />
                          <div class="col-md-1"></div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 pl-sm-2 statistics-grid">
                      <div className="card card_border border-primary-top p-4">
                        <h3 className="text-danger number">
                          {DataSet.noOfActiveRebatePrgm}
                        </h3>
                        <p style={{ height: "66px" }} className="stat-text">
                          No. of Active Rebate Prgm.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 pl-xl-2">
                  <div className="row">
                    <div className="col-sm-6 pr-sm-2 statistics-grid">
                      <div className="card card_border border-primary-top p-4">
                        <h3 className="text-secondary number">
                          {prefixCurrency}
                          {DataSet.totalAccruedAmount}
                        </h3>
                        <p style={{ height: "66px" }} className="stat-text">
                          Total Accrued Amount
                        </p>
                      </div>
                    </div>
                    <div className="col-sm-6 pl-sm-2 statistics-grid">
                      <div className="card card_border border-primary-top p-4">
                        <h3 className="text-danger number">
                          {prefixCurrency}
                          {DataSet.totalPostedAmount}
                        </h3>
                        <p style={{ height: "66px" }} className="stat-text">
                          Total Posted Amount
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <section className="col-lg-6">
                <div className="card-dashboard1">
                  <div className="card-body">
                    {DataSet.length === 0 ? (
                      ""
                    ) : (
                      <Doughnut
                        data={DataSet.activeRebateProgramByType}
                        options={plotOptions}
                        height={330}
                        width={400}
                      ></Doughnut>
                    )}
                  </div>

                </div>
                <div className="card-dashboard">
                  <div className="card-body">
                    {DataSet.length === 0 ? (
                      ""
                    ) : (
                      <Bar
                        data={DataSet.accruedVsProjected}
                        options={AccruedvsProjectedStatusOptions}
                      ></Bar>
                    )}
                  </div>
                  {/* /.card-body */}
                </div>
                <div className="card-dashboard">
                  <div className="card-body">
                    {DataSet.length === 0 ? (
                      ""
                    ) : (
                      <Bar
                        data={DataSet.topTenBeneficiaries}
                        options={Top10ProductsOptions}
                      ></Bar>
                    )}
                  </div>
                  {/* /.card-body */}
                </div>

                <div className="">
                  <div className=""></div>
                  {/* /.card-body */}
                </div>
              </section>
              <section className="col-lg-6">
                <div className="card-dashboard1">
                  <div className="card-body">
                    {DataSet.length === 0 ? (
                      ""
                    ) : (
                      <Bar
                        data={DataSet.revenueVsPayout}
                        options={revenueVsPayoutOptions}

                      ></Bar>
                    )}
                  </div>
                  {/* /.card-body */}
                </div>
                <div className="card-dashboard">
                  <div className="card-body">
                    {DataSet.length === 0 ? (
                      ""
                    ) : (
                      <Line
                        data={DataSet.accruedVsForecast}
                        options={AccruedVsForecastDataOptions}
                      ></Line>
                    )}
                  </div>
                  {/* /.card-body */}
                </div>


                <div className="card-dashboard">
                  <div className="card-body">
                    {DataSet.length === 0 ? (
                      ""
                    ) : (
                      <Bar
                        data={DataSet.topTenProducts}
                        options={PostedvsUnpostedOptions}
                      ></Bar>
                    )}
                  </div>
                  {/* /.card-body */}
                </div>




                <div className="">
                  <div className=""></div>
                  {/* /.card-body */}
                </div>
              </section>
            </div>
          </>
        }
      </div>
    </>
  );
}
export default ForecastDashboard;
