import { Link, useLocation } from "react-router-dom"
import React, { useState, useEffect, useRef } from 'react'
import Service from '../../../Services/Service';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import Spinner from 'react-bootstrap/Spinner';
import SessionManage from "../../../SessionManagement/SessionManage";
import { CSVLink } from "react-csv";
import Multiselect from 'multiselect-react-dropdown';
import { useTranslation } from "react-i18next";

function EmailTemplateListComponent() {
    const location = useLocation();
    const csvInstance = useRef();
    const [csvList, setCsvList] = useState([]);
    const [sortColName, setSortColName] = useState("");
    const [sticky, setSticky] = useState("");
    const [t, i18n] = useTranslation('translation');
    const [userPerms, setuserPermission] = useState([]);
    const [data, setdata] = useState([]);
    const [showspinner, setshowspinner] = useState('');
    const [checkedValueid, setCheckedValueid] = useState([]);
    const [filteredArray, setFilteredArray] = useState(data)
    const [showLoadMore, setshowLoadMore] = useState('');
    const [showbutton, setshowbutton] = useState('');
    const [filterValue, setFilterValue] = useState('');
    const [filterCol, setFilterCol] = useState('');
    const [dropdownData, setDropdownData] = useState([]);
    const [showfilterpopup, setshowfilterpopup] = useState(false);
    const [totalselectedshow, settotalselectedshow] = useState('');
    const [totalselectedcount, settotalselectedcount] = useState(0);
    const [totalCountlst, settotalCountlst] = useState('');
    const [columnAscending, setColumnAscending] = useState(true);
    const [filtertext, setfiltertext] = useState('');
    const [filterSearch, setFilterSearch] = useState("");
    const [filterdropdownData, setFilterDropdownData] = useState([]);
    const [filterValueDict, setFilterValueDict] = useState({
        name: '',
        LastModifiedDate: null
    });
    const [multiFilterValue, setMultiFilterValue] = useState({
        LastModifiedUser: ''
    });

    const [paging, setpaging] = useState({
        pageSize: 20,
        pageNumber: 1
    })
    const getUserPermission = (pathname) => {
        Service.GetUserPermission(pathname).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined') {
                    if (res.data.DataList !== 'undefined') {
                        setuserPermission(res.data.DataList)
                    }
                }
            }
        });
    }
    const checkAll = (mycheckbox) => {
        var checkboxes = document.querySelectorAll("input[type = 'checkbox']");
        if (mycheckbox.target.checked == true) {
            settotalselectedshow(true);
            checkboxes.forEach(function (checkbox) {
                checkbox.checked = true;
            });
            settotalselectedcount(checkboxes.length - 1)
        }
        else {
            settotalselectedshow(false);
            checkboxes.forEach(function (checkbox) {
                checkbox.checked = false;
            });
            settotalselectedcount(0)
        }

    }

    const LoadMore = (lst, totalcount) => {

        if (lst < totalcount) {
            setshowLoadMore(true);
        }
        else if (lst >= totalcount) {
            setshowLoadMore(false);
        } else {
            setshowLoadMore(false);
        }

    }
    const navigate = useNavigate();

    useEffect(() => {
        setshowspinner(true);
        setColumnAscending(false);
        setSortColName("Id");
        getUserPermission(location.pathname);
        getData("ApiWorkflowEmailTemplate/GetAll", paging.pageSize, paging.pageNumber, "Descending", "Id", [], null);
        window.addEventListener("scroll", isSticky);
        return () => {
            window.removeEventListener("scroll", isSticky);
        };
    }, [0]);
    const isSticky = () => {
        const scrollTop = window.scrollY;
        const stickyClass = scrollTop >= 60 ? "is-sticky-thead" : "";
        setSticky(stickyClass);
        const scrollTopActual = scrollTop >= 60 ? scrollTop - 85 : 0;
        var divtop1 = document.getElementById("tableA");
        setdivtop(divtop1.offsetTop + scrollTopActual)
    };
    const class_thead = `text-white rt-thead  ${sticky}`;

    const handleLoadMore = () => {
        let pagenumber = paging.pageNumber + 1;
        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
        Service.getDatawithMultiFilterSearch("ApiWorkflowEmailTemplate/GetAll", paging.pageSize, pagenumber, sortType, sortColName, filterLst(), searchValue).then((res) => {
           
            if (typeof res !== 'undefined') {
                if (res.data !== 'null' && typeof res.data !== 'undefined') {
                    const currentlength = data.length;
                    setdata(data => [...data, ...res.data.DataList])
                    setFilteredArray(filteredArray => [...filteredArray, ...res.data.DataList])
                  
                    LoadMore(currentlength + res.data.DataList.length, res.data.Totalcount)
                }
            }
        });
        paging.pageNumber = pagenumber
        setpaging(paging);
    };


    let role = [];
    const filterLst = () => {
        let list = [];
        for (var key in multiFilterValue) {
            if (multiFilterValue[key].length > 0) {
                let values = ""
                multiFilterValue[key].map((i) => {
                    values += i.Name + ";";
                })
                if (values !== null && values !== '') {
                    list.push({
                        filterKey: key,
                        FilterValue: values
                    })
                }
            }
        }

        for (var key in filterValueDict) {
            if (filterValueDict[key] !== null && filterValueDict[key] !== '') {
                list.push({
                    filterKey: key,
                    FilterValue: filterValueDict[key]
                })
            }
        }
        return list;
    }
    const getData = (method, pageSize, pageNumber, sortType, SortProperty, filterValue, search) => {

        Service.getDatawithMultiFilterSearch(method, pageSize, pageNumber, sortType, SortProperty, filterValue, search).then((res) => {
            if (typeof res !== 'undefined') {
                if (res.data !== 'null' && res.data !== 'undefined') {
                    setdata(res.data.DataList);
                    setFilteredArray(res.data.DataList)
                    settotalCountlst(res.data.Totalcount)
                    LoadMore(res.data.DataList.length, res.data.Totalcount)
                    setshowspinner(false)
                }
            }
        });

    }



    const handleChange = (e, id) => {
        let isChecked = e.target.checked;   // true if it is checked false if unchecked
        
        let checkedBoxes = document.querySelectorAll('input[name=chkBox]:checked');
        if (isChecked === true) {

            settotalselectedshow(true);
            settotalselectedcount(checkedBoxes.length);
            setCheckedValueid(id)
        }
        else {
            if (checkedBoxes.length === 0) {
                settotalselectedshow(false);
            }
            settotalselectedcount(checkedBoxes.length);
          
            setCheckedValueid([])
          
        }


        if (checkedBoxes.length == 1) {
            setshowbutton(true);
        }
        else {
            setshowbutton(false);
        }
    }

    const [searchValue, setSearchValue] = useState(null)
    const OpenHandler = (id) => {
        if (id == null || id == 0) {
            alert('Please select the User to view')
        }
        else {
            navigate("/emailtemplates/edit/" + id);
        }
    }


    const SortColumn = (colName) => {
   
        var sortType = '';
        if (columnAscending === true) {
            setColumnAscending(false);
            sortType = "Descending";
        } else {
            setColumnAscending(true);
            sortType = "Ascending";
        }
        setSortColName(colName);
      
        let pagenumber = 1;
       
        paging.pageNumber = pagenumber;
        setpaging(paging);


        getData("ApiWorkflowEmailTemplate/GetAll", paging.pageSize, pagenumber, sortType, colName, filterLst(), searchValue);

    }



    const searchHandler = (event) => {
  
        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
        let pagenumber = 1;
        paging.pageNumber = pagenumber;
        setpaging(paging);
        if (event.target.value != '') {
          
            setSearchValue(String(event.target.value).toLowerCase())
            getData("ApiWorkflowEmailTemplate/GetAll", paging.pageSize, pagenumber, sortType, sortColName, filterLst(), event.target.value);
        }
        else {
            setSearchValue(null);
            getData("ApiWorkflowEmailTemplate/GetAll", paging.pageSize, pagenumber, sortType, sortColName, filterLst(), null);
        }
    }
    const ToggleUserActiveStatus = (id) => {
        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
        Service.TogglePolicyActiveStatus('ApiWorkflowEmailTemplate/ActiveStatus', id, Service.getUserId()).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.status !== 'undefined' && res.status === 200) {
                    getData("ApiWorkflowEmailTemplate/GetAll", paging.pageSize, paging.pageNumber, sortType, sortColName, filterLst(), searchValue)
                }
            }
        });
    }

    const OpenUser = (event, id) => {
        if (event.detail === 2) {
         
            navigate("/emailtemplates/edit/" + id);
        }
    }


    const filterInput = (e) => {
        let inputValue = e.target.value
        setFilterValue(inputValue)
        setFilterValueDict(() => ({
            ...filterValueDict, [filterCol]: e.target.value,
        }));
    
    }
    const addnewfield = (data, key) => {
        let selectedKeys = multiFilterValue[key];
        let currentdata = data.map((i) => {
            i["ischecked"] = false;
            return i;
        });
        if (key != 'status') {
            if (selectedKeys != '') {
                currentdata.forEach((each) => {
                    selectedKeys.forEach((eachSelected) => {
                        if (each.Name == eachSelected.Name) {
                            each.ischecked = true
                        }
                    })
                })
            }
         
            setDropdownData(currentdata);
            setFilterDropdownData(currentdata)
        }
        else {
            if (selectedKeys != '') {
                currentdata.forEach((each) => {
                    selectedKeys.forEach((eachSelected) => {
                        if (each.Name == eachSelected.Name) {
                            each.ischecked = true
                        }
                    })
                })
                setDropdownData(currentdata)
                setFilterDropdownData(currentdata)
            }
            else {
                setDropdownData(currentdata)
                setFilterDropdownData(currentdata)
            }
        }
    }


    const checkAllFilter = (mycheckbox) => {
        var lastfilter = [...dropdownData]
        let data1 = []
        var checkboxes = document.querySelectorAll("input[name = 'filter-option-all']");
        if (mycheckbox.target.checked == true) {
            
            let currentdata = lastfilter.map((i) => {
                i["ischecked"] = true;
                return i;
            });
            setDropdownData(currentdata)
           
            dropdownData.map((each) => {
        
                const getallData = {
                    Name: each.Name,
                }
                data1.push(getallData)
            })
            setFilterValue(filterValue => [...filterValue, ...data1]);
            let updateddata = ([...filterValue, ...data1]);
            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: updateddata,
            }));
        }
        else {
           
            let currentdata = lastfilter.map((i) => {
                i["ischecked"] = false;
                return i;
            });
            setFilterValue([])
            setMultiFilterValue([])
        }

    }


    const OndropdownChange = (e, id, name) => {
        let isChecked = e.target.checked;
        var lastfilter = [...dropdownData]
        if (isChecked === true) {
            let currentdata = lastfilter.map((i) => {
                if (i.Id == id) {
                    i["ischecked"] = true;
                }
                return i;
            });
           
            setDropdownData(currentdata);
            let data1 = []
            const getallData = {
                Name: name,
            }
            data1.push(getallData)
            setFilterValue(filterValue => [...filterValue, ...data1]);
            let updateddata = ([...filterValue, ...data1]);
            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: updateddata,
            }));
        }
        else {
            let currentdata = lastfilter.map((i) => {
                if (i.Id === id) {
                    i["ischecked"] = false;
                }
                return i;
            });
         
            setDropdownData(currentdata);
            setFilterValue(filterValue => filterValue.filter(data => data.Name !== name))
            let data1 = multiFilterValue.filter(data => data.Name !== name);
            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: data1,
            }));
        }
    }

    const UserfilterHandler = (e) => {

        setFilterSearch(e.target.value)
        if (e.target.value !== "") {
            let searchResults = dropdownData.filter((each) => {
                return each.Name.toLowerCase().includes(String(e.target.value).toLowerCase())
            });
            setDropdownData(searchResults);
        }
        else {
            
            setFilterSearch("")
            setDropdownData(filterdropdownData)
        }
    }
    const DropdownHandler = (key) => {
        Service.getRebatePolicyfilterDropdown('ApiWorkflowEmailTemplate/GetEmailtemplatesFilterValue', key).then((res) => {
            if (res && res.status === 200) {
                if (typeof res !== 'undefined') {
                    if (typeof res.data !== 'undefined' && res.data !== 'null') {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                           
                            addnewfield(res.data, key)
                        }
                    }
                }

            }
        });
    }
    const [divleft, setdivleft] = useState('');
    const [divtop, setdivtop] = useState('');


    const FilterColumn = (name) => {

        const scrollTop = window.scrollY;
        const scrollTopActual = scrollTop >= 60 ? scrollTop - 85 : 0;
        var divtop1 = document.getElementById("tableA");
        setdivtop(divtop1.offsetTop + scrollTopActual)

        setshowfilterpopup(true)
        setFilterCol(name)

        if (name === 'name') {
            setfiltertext(t('UserList.TemplateName'))
            var testDiv = document.getElementById("name");
            setdivleft(testDiv.offsetLeft + "px");
        }
        if (name === 'LastModifiedDate') {
            setfiltertext(t('LastModifiedDate'))
            var testDiv = document.getElementById("LastModifiedDate");
            setdivleft(testDiv.offsetLeft + "px");
        }

        if (name === 'LastModifiedUser') {
            setfiltertext(t('LastModifiedBy'))
            var testDiv = document.getElementById("LastModifiedUser");
            setdivleft(testDiv.offsetLeft + "px");
            DropdownHandler(name)
        }
    }

 

    const filterHandler = (flag) => {
        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
        let pagenumber = 1;
        paging.pageNumber = pagenumber;

        if (flag === 'Apply') {
            debugger;
            setshowfilterpopup(false)
            let list = [];

            for (var key in multiFilterValue) {
                if (multiFilterValue[key].length > 0) {
                    let values = ""
                    multiFilterValue[key].map((i) => {
                        values += i.Name + ";";
                    })
                    if (values !== null && values !== '') {
                        list.push({
                            filterKey: key,
                            FilterValue: values
                        })
                    }
                }
            }

            for (var key in filterValueDict) {
                if (filterValueDict[key] !== null && filterValueDict[key] !== '') {
                    list.push({
                        filterKey: key,
                        FilterValue: filterValueDict[key]
                    })
                }
            }
            if (list.length > 0) {
                setpaging(paging);
                getData("ApiWorkflowEmailTemplate/GetAll", paging.pageSize, pagenumber, sortType, sortColName, list, searchValue);
            }
            
        }
        else {
            setshowfilterpopup(false)
            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: '',
            }));
            setFilterValueDict(() => ({
                ...filterValueDict, [filterCol]: '',
            }));

            let list = [];
            for (var key in multiFilterValue) {
                if (key !== filterCol && multiFilterValue[key].length > 0) {
                    let values = ""
                    multiFilterValue[key].map((i) => {
                        values += i.Name + ";";
                    })
                    if (values !== null && values !== '') {
                        list.push({
                            filterKey: key,
                            FilterValue: values
                        })
                    }
                }
            }

            for (var key in filterValueDict) {
                if (filterValueDict[key] !== null && filterValueDict[key] !== '' && key !== filterCol) {
                    list.push({
                        filterKey: key,
                        FilterValue: filterValueDict[key]
                    })
                }
            }
            setpaging(paging);
            getData("ApiWorkflowEmailTemplate/GetAll", paging.pageSize, pagenumber, sortType, sortColName, list, searchValue);

            

            setFilterCol('')
            setFilterValue('')
            setDropdownData([])
            setFilterSearch("")
            setFilterDropdownData([])
        }
    }

    let headers = [
        { label: "Template Name", key: "Name" },
        { label: "Last Modified Date", key: "LastModifiedDate" },
        { label: "Last Modified By", key: "LastModifiedUser" },

    ];
    const ExporttoCSV = () => {
        let checkedBoxes = document.querySelectorAll('input[name=chkBox]:checked');


        let values = "";
        if (checkedBoxes.length >= 1) {
            for (let checkbox of checkedBoxes) {
                values = values + ';' + checkbox.id;

            }
        }
        let objFilter = [];
        let objFilterValue = [{
            filterKey: "id",
            FilterValue: values,
        }]

        objFilter = objFilterValue;
        Service.getDatawithMultiFilterSearch("ApiWorkflowEmailTemplate/GetAll", 20000, 1, "Ascending", "Id", objFilter, null).then((res) => {
     
            if (typeof res !== 'undefined') {
                if (res.data !== 'null' && res.data !== 'undefined') {
                    setCsvList(res.data.DataList);
                    setTimeout(() => {
                        csvInstance.current.link.click();
                    });

                }
            }
        });
    }
    const customMultiStyles = {
        multiselectContainer: {
            maxWidth: '188px',
            marginLeft: '3px'
        },
        chips: {
            borderRadius: '5px'
        }
    };
    return (<>
        <div className="container-fluid content-top-gap">

            <nav aria-label="breadcrumb">
                <ol className="breadcrumb my-breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">{t('Home.title')}</Link></li>
                    <li className="breadcrumb-item"><Link to="/admin">{t('Menu.Admin')}</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">{t('UserList.EmailTemplates')}</li>
                    
                </ol>
            </nav>
            <div style={{ display: "none" }}>
                <CSVLink data={csvList} headers={headers} ref={csvInstance} filename="emailtemplates.csv"  > </CSVLink>
            </div>
            <div className="">
                <div className="row">
                    <div className="col-lg-12 pr-lg-2">
                        <div className="card text-center card_border overflow-auto">

                            <div className="card-body" style={{ minHeight: "70vh" }}>
                                <div className="row" style={{position:"sticky"}}>

                                    <div className="col-md-7">
                                        <input type="text" className="form-control form-control-user" placeholder={`${t('SearchFor')}${t('UserList.TemplateName')}`} onChange={(event) => searchHandler(event)} />
                                    </div>
                                    <div className="col-md-5">
                                        {Service.editPerms(userPerms) == false ? "" : <a className="btn btn-update btn-sm ml-2" id="create" style={{ width: "75px", backgroundColor: "buttonface" }} href="/emailtemplates/add/0">Create New</a>}
                                        {totalselectedcount <= 0 ? "" : Service.exportPerms(userPerms) == false ? "" : <button className="btn btn-update  btn-sm ml-2" style={{ float: "right", width: "50px" }} type="submit" onClick={(event) => ExporttoCSV()}>Export</button>}


                                        {showbutton === true ?
                                            <>
                                                <button className="btn btn-update btn-sm ml-2" id="open" style={{ float: "right", width: "40px" }} type="submit" onClick={() => OpenHandler(checkedValueid)}>Open</button>
                                                
                                            </>
                                            :
                                            ''
                                        }

                                    </div>

                                </div>
                                <div className="scrollbar-class">
                                    <table className="table table-bordered text-left mt-2 emailtemplateclass" id="tableA">
                                    <thead className="text-white is-sticky-thead-product" style={{ backgroundColor: "var(--primary)" }}>
                                            <tr style={{ position: "sticky", top: "0", backgroundColor: "var(--primary)" }}>
                                                <th className="tableheader">
                                                    <input type="checkbox" id="option-all" onChange={checkAll} />
                                                </th>
                                                <th id="name" style={{ backgroundColor: (filterValueDict.name ? '#6cae55' : ''), minWidth: '200px' }}>
                                                    <div className='d-flex text-nowrap'>
                                                        <span>{t('UserList.TemplateName')}</span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('Name')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('name')}></span>
                                                    </div>
                                                </th>
                                                <th id="LastModifiedDate" style={{ backgroundColor: (filterValueDict.LastModifiedDate ? '#6cae55' : '') }}>
                                                    <div className='d-flex text-nowrap'>
                                                        <span>{t('LastModifiedDate')}</span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('LastModifiedDate')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('LastModifiedDate')}></span>
                                                    </div>
                                                </th>
                                                <th id="LastModifiedUser" style={{ backgroundColor: (multiFilterValue.LastModifiedUser ? '#6cae55' : '') }}>
                                                    <div className='d-flex text-nowrap'>
                                                        <span>{t('LastModifiedBy')}</span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('LastModifiedUser')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('LastModifiedUser')}></span>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody>

                                            {
                                                showspinner === true ?

                                                    <>
                                                        <Spinner animation="grow" size="sm" variant="success" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                        <Spinner animation="grow" size="sm" variant="danger" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                        <Spinner animation="grow" size="sm" variant="warning" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                    </>
                                                    :
                                                    filteredArray.length === 0 ?
                                                        <tr><td></td>

                                                            <td></td>
                                                            <td>
                                                                No Data Available
                                                            </td>
                                                            <td></td>
                                                        </tr>
                                                        :
                                                        filteredArray.map((item, index) => (

                                                            <tr key={index} onDoubleClick={(event) => OpenUser(event, item.Id)}>

                                                                <td><input data-index="1" name="chkBox" type="checkbox" id={item.Id} onChange={(event) => handleChange(event, item.Id)} /></td>


                                                                <td>{item.Name}</td>
                                                                <td>{item.LastModifiedDate === null ? "" : moment(item.LastModifiedDate).format(SessionManage.getcurrentdateformat())}</td>

                                                                <td>{item.LastModifiedUser}</td>


                                                             



                                                            </tr>


                                                        ))
                                            }

                                        </tbody>
                                    </table>
                                </div>

                                <div className='row'>

                                    {
                                        totalselectedshow === true ?

                                            <div className='col-lg-2 forgettext col-sm-12 border form-control' style={{ textAlign: "center", marginLeft:"0.938rem" }}>
                                                Total Records Selected - <span id="totalselected">{totalselectedcount}</span>
                                            </div>

                                            :
                                            <div className='col-lg-2  col-sm-12' style={{ textAlign: "center" }}>

                                            </div>
                                    }



                                    <div className='col-lg-3 col-sm-12'></div>


                                    <div className='col-lg-2 forgettext  col-sm-12 border form-control' style={{ textAlign: "center" }}>
                                        Total Records - {totalCountlst}
                                    </div>
                                    <div className='col-lg-4 col-sm-12'></div>
                                </div>
                                {
                                    showLoadMore === true ?
                                        <div className='text-center container row'>
                                            <div className='col-md-5'></div>
                                            <div className='col-md-2'>
                                                <button className='btn btn-sm btn-update ml-1' onClick={handleLoadMore} style={{ width: "70px" }}>Load More</button>
                                            </div>
                                            <div className='col-md-5'></div>

                                        </div>
                                        :
                                        ""
                                }
                                {showfilterpopup === true ?
                                    <div className='pop-up' style={{ left: divleft, top: divtop }}>
 
                                        {filterCol === 'name' ?
                                            <>
                                                <div className="container">
                                                    <div className="row">
                                                        <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>
                                                    </div>
                                                </div>
                                                <div className="container mt-2">
                                                    <div className="row">
                                                        <input type='textbox' placeholder={t('Search')} className='filtertextboxbackground col-md-11 textbox-padding ml-2' style={{ outline: 'none' }} value={filterValueDict[filterCol]} onChange={(event) => filterInput(event)}></input>
                                                    </div>
                                                </div>
                                            </>

                                            :
                                            <>
                                            </>


                                        }

                                        {filterCol === 'LastModifiedDate' ?

                                            <>
                                                <div className="container">
                                                    <div className="row">
                                                        <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>
                                                    </div>
                                                </div>
                                                <div className="container mt-2">
                                                    <div className="row">
                                                        <input type='date' className='filtertextboxbackground col-md-11 textbox-padding ml-2' value={filterValueDict[filterCol]} onChange={(event) => filterInput(event)}></input>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <></>
                                        }

                                        {filterCol === 'LastModifiedUser' ?
                                            
                                            <>
                                                <div className="container">
                                                    <div className="row">
                                                        <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>

                                                    </div>

                                                </div>

                                                <div className="container mt-2">
                                                    <div className="row">
                                                        <input type="text" placeholder={t('Search')} value={filterSearch} onChange={(event) => UserfilterHandler(event)} className='filtertextboxbackground col-md-11 textbox-padding ml-2' />
                                                    </div>

                                                    <div className="row contentoverflow mt-2">
                                                        <table className="filtertable">
                                                            <tbody>
                                                                <tr>
                                                                    <td><input type="checkbox" name="filter-option-all" id="option-all" onChange={checkAllFilter} /></td>
                                                                    <td>{t('SelectAll')}</td>
                                                                </tr>
                                                                {
                                                                    dropdownData.map((item, index) => (

                                                                        <tr key={index}>

                                                                            <td><input id={item.Id} data-index="1" name="filter-option-all" checked={item.ischecked} type="checkbox" onChange={(event) => OndropdownChange(event, item.Id, item.Name)} /></td>

                                                                            <td>{item.Name}</td>

                                                                        </tr>


                                                                    ))
                                                                }

                                                            </tbody>
                                                        </table>

                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <></>
                                        }
                                        <div style={{ paddingTop: '10px', paddingLeft: '47px', paddingBottom: '7px' }}>
                                            <button className="btn btn-sm btn-update" onClick={() => filterHandler('Apply')} style={{ width: "45px" }}>{t('Apply')}</button>
                                            <button className="btn btn-sm btn-update" style={{ marginLeft: '10px', width: "45px" }} onClick={() => filterHandler('Reset')}>{t('Reset')}</button>
                                        </div>
                                    </div>
                                    :
                                    <></>
                                }
                            </div>

                        </div>
                    </div>

                </div>
            </div >



        </div >
    </>)
}
export default EmailTemplateListComponent

