import { Link, Navigate, useLocation, useNavigate } from "react-router-dom"
import React, { useState, useEffect } from 'react'

import { Form, FormGroup, Col, Row, Button, InputGroup, Collapse, Modal } from 'react-bootstrap';

import 'react-multiple-select-dropdown-lite/dist/index.css'
import Multiselect from 'multiselect-react-dropdown';

import SessionManage from "../../SessionManagement/SessionManage";
import Service from "../../Services/Service";
import moment from "moment";


function AuditLogDetailsComponent(props) {
    const location = useLocation();
    const [userPerms, setuserPermission] = useState([]);
    const [Groups, setGroup] = useState([]);
    const [editPerms, setEditPerms] = useState([]);

  
    const [selected, setselected] = useState([]);
    const [useravailable, setuseravailable] = useState('');

    const [Roles, setRole] = useState([]);
    const [selectedRoles, setSelectedRoles] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState([]);

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [errorStatus, setErrorStatus] = useState("Error");
    const [isValid, setIsValid] = useState(true);
    const [closeModal, setCloseModal] = useState(false);
    const [handleChangeValue, setHandleChangeValue] = useState(false);
    let loggedInUser = sessionStorage.getItem('id');
    const [isLocked, setIsLocked] = useState(false);
    const [lockedBy, setLockedBy] = useState('');
    let disable = false;
    useEffect(() => {
        getUserPermission(location.pathname);
        getUserDatabyId("AuditLog/GetById/", props.id);


    }, [0])

    const [inputField, setInputField] = useState({
        Id: 0,
        Action: '',
        Section: '',
        ActionDetails: '',
        CreatedUserName: '',
        CreatedDate: '',        
        Request: '',
        Response: '',
       
    })
    const getUserPermission = (pathname) => {
        Service.GetUserPermission(pathname).then((res) => {
    
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null' && res.data !== '') {
                    if (typeof res.data.DataList !== 'undefined') {
                        var permission = res.data.DataList;
                        if (typeof permission !== 'undefined') {
                            if (permission.filter((item) => item.ActionName === 'Edit' || item.ActionName === 'Submit').length == 0) {
                                setEditPerms(false);
                            } else {
                                setEditPerms(true);
                            }
                        }
                        else {
                            setEditPerms(false);
                        }
                    }
                }
            }
        });
    }
    const getData = (method) => {
        Service.getdata(method).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && typeof res.data.DataList !== 'undefined') {

                    if (res.data.DataList.lstRoles !== 'undefined')
                        setRole(res.data.DataList.lstRoles);
                    if (res.data.DataList.lstGroups !== 'undefined')
                        setGroup(res.data.DataList.lstGroups);

                }
            }
        });
    }


    const getUserDatabyId = (method, id) => {
      
                Service.getdatabyId(method, id).then((res) => {
                    if (typeof res !== 'undefined') {
                        if (typeof res.data !== 'undefined' && res.data !== 'null') {
                            setInputField(res.data);
                           
                            setSelectedRoles(res.data.lstRole);
                            setSelectedGroups(res.data.lstGroup);
                            setLockedBy(res.data.LockedBy);
                            setIsLocked(res.data.Locked);
                        }
                    }
                });
            }
         
            const navigate = useNavigate();
            
            const closeHandler = () => {
                navigate('/auditlog')
            }
    const unlockEntity = () => {
        Service.LockUnLockEntity('Configurationprop/UnLockEntity', "User", props.id, loggedInUser).then((res) => {
        });
    }
    const class_textbox = `col-md-2 textbox-padding  ${editPerms == false ? "disabledClass" : ""}`;
    const class_multiselect = ` textbox-padding  ${editPerms == false ? "disabledClass" : ""}`;

    return (
        <div className="container-fluid content-top-gap">

            <nav aria-label="breadcrumb">
                <ol className="breadcrumb my-breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                    <li className="breadcrumb-item active" aria-current="page"><Link to="/auditlog">Auditlog List</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Details</li>

                </ol>
            </nav>

            <div className="row mt-3" id="rebateProgramdiv">
                <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                        <div className="card-header chart-grid__header">
                            <div className="row">
                                <div className="col-md-7 text-left">
                                    Auditlog Details
                                </div>
                                <div className="col-md-5">

                                    <button className="btn btn-sm btn-update ml-2" id="close" style={{ width: "45px", backgroundColor: "buttonface", float: "right" }} onClick={(event) => closeHandler("Open")}>Close   </button>
                                </div>
                            </div>
                        </div>
                        <div className="card-body" style={{ minHeight: "60vh" }}>
                            <div className="container">
                            <div className="row">
                                    <label className="col-md-2 text-left textbox-padding mt-2">Created By </label>
                                    {inputField.CreatedUserName}
                                  
                                </div>

                                <div className="row">
                                
                                    <label className="col-md-2 text-left textbox-padding mt-2">Created Date </label>                                 

                                    {inputField.CreatedDate === null ? "" : moment(inputField.CreatedDate)}
                                </div>
                                <div className="row">
                                    <label className="col-md-2 text-left textbox-padding mt-2">Method </label>
                                  
                                    <textarea className=" textbox-padding mt-2 form-control" style={{width:"900px",height:"70px"}} disabled={true} value={inputField.Section} ></textarea>
                              
                                </div>
                                <div className="row">
                                    <label className="col-md-2 text-left textbox-padding mt-2">Error Details </label>
                                  

                                    <textarea className=" textbox-padding mt-2 form-control" style={{width:"900px",height:"50px"}} disabled={true} value={inputField.ActionDetails} ></textarea>
                              
                                </div>
                                <div className="row">
                                    <label className="col-md-2 text-left textbox-padding mt-2">Request </label>
                                    <textarea className=" textbox-padding mt-2 form-control" style={{width:"900px",height:"400px"}}  disabled={true} value={inputField.Request} ></textarea>
                                </div>
                                <div className="row">
                                    <label className="col-md-2 text-left textbox-padding mt-2">Response </label>
                               
                                    <textarea className=" textbox-padding mt-2 form-control" style={{width:"900px",height:"500px"}}  disabled={true} value={inputField.Response} ></textarea>
                              
                                </div>
                            
                            </div>
                        </div>
                    </div>


                </div></div></div>
    );
}
export default AuditLogDetailsComponent