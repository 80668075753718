import { Link, useLocation } from "react-router-dom"
import React, { useState, useEffect } from 'react'

import classes from '../RebateProgramComponent/CreateRebateComponent/Form.module.css'
import { Form, FormGroup, Col, Row, Button, InputGroup, Collapse, Modal } from 'react-bootstrap';
import Service from '../../Services/Service';
import 'react-multiple-select-dropdown-lite/dist/index.css'
import CustomErrorPopup from '../../UI/CustomErrorPopup';
import Multiselect from 'multiselect-react-dropdown';
import $ from 'jquery';
import Select from "react-select";
import { useTranslation } from "react-i18next";
import qs from 'qs';

function PartnerProfileDetails(props) {
    let loggedInUser = sessionStorage.getItem('id');
    const location = useLocation();
    const [t, i18n] = useTranslation('translation');
    const [userPerms, setuserPermission] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selected, setselected] = useState([]);
    const [useravailable, setuseravailable] = useState('');
    const [invalidemail, setInvalidemail] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [errorStatus, setErrorStatus] = useState("Error");
    const [closeModal, setCloseModal] = useState(false);
    const [handleChangeValue, setHandleChangeValue] = useState(false);
    const [isValid, setIsValid] = useState(true);
    const [distributorList, setDistributorList] = useState([]);
    const [fileSelected, setFileSelected] = useState();
    const [currencyList, setCurrencyList] = useState([]);
    const [Roles, setRole] = useState([]);
    const [Groups, setGroup] = useState([]);
    const [isLocked, setIsLocked] = useState(false);
    const [lockedBy, setLockedBy] = useState('');
    const [id, setId] = useState(0);
    const [selectedRoles, setSelectedRoles] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [file, setFile] = useState([]);

    useEffect(() => {
        var id = sessionStorage.getItem("id");
        setId(id)
        getUserPermission(location.pathname);
        getdataByGroup("SDCommon/GetAllDistCustCurrencySettlementProduct");
        getData("Users/GetRoleAndGroup");
        if (typeof props.id != 'undefined')
            getUserDatabyId("Users/GetById/", props.id);
    }, [0])
    const getUserDatabyId = (method, id) => {
        Service.LockUnLockEntity('Configurationprop/LockEntity', "User", props.id, loggedInUser).then((res1) => {
            if (typeof res1 !== 'undefined') {
                Service.getdatabyId(method, id).then((res) => {
                    if (typeof res !== 'undefined') {
                        if (typeof res.data !== 'undefined' && res.data !== 'null') {
                            setInputField(res.data);
                            
                            setSelectedRoles(res.data.lstRole);
                            setSelectedGroups(res.data.lstGroup);
                            setLockedBy(res.data.LockedBy);
                            setIsLocked(res.data.Locked);
                            if ((res.data.Locked === true && res.data.LockedBy.toString() !== loggedInUser)) {
                                $("#libread").html("Edit User " + `Locked By ${res.data.LockedByUserName}`);
                            }
                        }
                    }
                });
            }
         
        });
    }
    const getdataByGroup = (method) => {
        Service.getdataByGroupAndType(method, '').then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined') {
                    ;
                    if (typeof res.data.distributorList !== 'undefined')
                        setDistributorList(res.data.distributorList);
                    if (typeof res.data.currencyList !== 'undefined')
                        setCurrencyList(res.data.currencyList);

                }
            }
        });
    }
    const getUserPermission = (pathname) => {
        Service.GetUserPermission(pathname).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== '') {
                    if (typeof res.data.DataList !== 'undefined') {
                        setuserPermission(res.data.DataList)
                    }
                }
            }
        });
    }

    const getData = (method) => {
        Service.getdata(method).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && typeof res.data.DataList !== 'undefined') {

                    if (typeof res.data.DataList.lstRoles !== 'undefined')
                        setRole(res.data.DataList.lstRoles);
                    if (typeof res.data.DataList.lstGroups !== 'undefined')
                        setGroup(res.data.DataList.lstGroups);
                }
            }
        });
    }

    const emailinputhandler = (e) => {
        setHandleChangeValue(true)
        const { name, value } = e.target;
        setInputField((prevState) => ({
            ...prevState,
            [name]: value, 'Username': value,
        }));
        if (value !== null && value != '') {
            Service.getdatabyId("Users/CheckUserExist", value).then((res) => {
                if (typeof res !== 'undefined') {
                    if (typeof res.data !== 'undefined' && res.data !== 'null') {
                        if (res.status === 200) {
                            setuseravailable(res.data);
                        }
                    }
                }
                

            });
        }
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
            setInvalidemail(true);
        }
        else {
            setInvalidemail(false);
        }
    }

    const [inputField, setInputField] = useState({
        FirstName: '',
        LastName: '',
        Username: '',
        Email: '',
        Password: '',
        ConfirmPassword: '',
        lstRole: [],
        lstGroup: [],
        Active: false,
        IsSuperuser: false,
        IsActive: false,
        LastModifiedUser: id,
        CreatedUser: id,
        dateformat: '',
        DistributorId: 0,
        CurrencyId: 0,
        UsersType: '',
        UploadPic: '',
        PartnerCompanyRole: '',
       // files: [],
        Locale: '',
        Languages: '',
        ProfileFile: null,

    })
    const OndropdownChange = (e, name) => {
        setInputField({ ...inputField, [name]: e.value });
    }
    const saveFileSelected = (e) => {
        //in case you wan to print the file selected
        setFile([...file, ...e.target.files]);
       // setFileSelected(e.target.files[0]);
    };

    const inputsHandler = (e) => {
       
        setHandleChangeValue(true)
        const { name, value } = e.target;
        setInputField((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    }

    const checkValidation = () => {
        var hasError = false;
        setIsValid(true)
     
        if (inputField.FirstName == null || inputField.FirstName == '') {
            setErrorMessage(t('Validation-Createuser.FirstName'))
            setIsValid(false)
            hasError = true;
            setShowErrorModal(true)
        }
        else if (inputField.LastName == '' || inputField.LastName == null) {
            setErrorMessage(t('Validation-Createuser.LastName'))
            setIsValid(false)
            setShowErrorModal(true)
            hasError = true;
        }
        else if (inputField.Email == '' || inputField.Email == null) {
            setErrorMessage(t('Validation-Createuser.Email'))
            setIsValid(false)
            setShowErrorModal(true)
            hasError = true;
        }
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(inputField.Email)) {
            setErrorMessage('Please Enter Valid Email')
            setIsValid(false)
            setShowErrorModal(true)
            hasError = true;
        }
        else if (inputField.Username == '' || inputField.Username == null) {
            setErrorMessage(t('Validation-Createuser.UserName'))
            setIsValid(false)
            setShowErrorModal(true)
            hasError = true;
        }
        else if (inputField.lstRole == '' || inputField.lstRole == null) {
            setErrorMessage(t('Validation-Createuser.Role'))
            setIsValid(false)
            setShowErrorModal(true)
            hasError = true;
        }
        else if (inputField.lstGroup == '' || inputField.lstGroup == null) {
            setErrorMessage(t('Validation-Createuser.Group'))
            setIsValid(false)
            setShowErrorModal(true)
            hasError = true;
        }
        else if (inputField.Password == '' || inputField.Password == null) {
            setErrorMessage(t('Validation-Createuser.Password'))
            setIsValid(false)
            setShowErrorModal(true)
            hasError = true;
        }
        else if (inputField.ConfirmPassword != inputField.Password) {
            setErrorMessage(t('Validation-Createuser.Vaidatepassword'))
            setIsValid(false)
            setShowErrorModal(true)
            hasError = true;
        }
        else if (inputField.Locale == '' || inputField.Locale == 'select' || inputField.Locale == null) {
            setErrorMessage('Please Select Locale')
            setIsValid(false)
            setShowErrorModal(true)
            hasError = true;
        }
       
        return hasError;
    }


    let selectedData = [];
    const submitButton = (e) => {
        e.preventDefault();
        setLoading(true);
        inputField.CreatedUser = id;
        inputField.LastModifiedUser = id;
        inputField.UsersType = 'Partner User'
        var hasError = checkValidation()
   
        let Users = inputField;
       // inputField.ProfileFile=file;
        let SendData = new FormData();
        debugger
        SendData.append("data", JSON.stringify(inputField));
              debugger
        SendData.append('ProfileFile', file[0]);
       
        if (hasError === false) {
            if (useravailable == "User available" || typeof props.id !== 'undefined') {
                Service.createorupdateImport("Users/CreateOrUpdate", SendData).then(res => {
                    if (typeof res !== 'undefined') {

                        setLoading(false);
                        if (typeof res.data !== 'undefined' && res.data !== 'null') {
                            try {
                                if (typeof fileSelected != 'undefined') {
                                    const formData = new FormData();
                                    formData.append("file", fileSelected);
                                    Service.createorupdate("Users/ImportUserFile?id=" + res.data.Status, formData).then(res => {
                                        props.navigate('/Users');
                                    });
                                } else {
                                    props.navigate('/Users');
                                }
                            } catch (ex) {
                              
                            }

                        } else {
                            setLoading(false);
                        }
                    } else {
                        setLoading(false);
                    }

                });
            } else {
                setLoading(false);
            }
        } else {
            setLoading(false);
        }
    }



    const handleChange = (selectedList, selectedItem) => {
        setHandleChangeValue(true)

        const selected = [];
        const selectedId = [];
        inputField.lstRole = [];
        let selectedOption = (selectedList);
   
        for (let i = 0; i < selectedOption.length; i++) {
            selected.push(selectedOption[i].Name);
            selectedId.push(selectedOption[i].Id);
            const getallData = {
                id: selectedOption[i].Id,
               
            }
            inputField.lstRole.push(getallData);
        }

       
    }
    const handleChangeGroup = (selectedList, selectedItem) => {
        setHandleChangeValue(true)
        const selected = [];
        const selectedId = [];
        inputField.lstGroup = [];
        let selectedOption = (selectedList);
      
        for (let i = 0; i < selectedOption.length; i++) {
            selected.push(selectedOption[i].Name);
            selectedId.push(selectedOption[i].Id);
            const getallData = {
                id: selectedOption[i].Id,
                
            }
            inputField.lstGroup.push(getallData);
        }

       
    }

    const closeHandler = (flag) => {
        if (flag == "Open" && handleChangeValue == true) {
            setCloseModal(true)
        }
        if (flag == "Open" && handleChangeValue == false) {
            props.navigate('/Users')
        }
        if (flag == "Close") {
            props.navigate('/Users')
        }
        if (flag == "Cancel") {
            setCloseModal(false)
        }

    }

    const regionStyles = {
        chips: {
            fontSize: "12px",
            lineHeight: "13px"
        },

        multiSelectContainer: {
            "&:hover": { background: "red" }
        }

    }


    return (<>
        <div className="container-fluid content-top-gap">

            <nav aria-label="breadcrumb">
                <ol className="breadcrumb my-breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">{t('Home.title')}</Link></li>
                    <li className="breadcrumb-item"><Link to="/admin">{t('Menu.Admin')}</Link></li>
                    <li className="breadcrumb-item active" aria-current="page"><Link to="/Users">{t('UserList.title')}</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">{t('UserList.CreatePartnerUser')}</li>
                </ol>
            </nav>
        
            <div className="row mt-3" id="rebateProgramdiv">
                <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                        <div className="card-header chart-grid__header">
                            <div className="row">
                                <div className="col-md-7 text-left">
                                    {t('UserList.CreatePartnerUser')}
                                </div>
                                <div className="col-md-5">
                                    {Service.editPerms(userPerms) == false ? "" : <button className="btn  btn-update btn-sm ml-2" style={{ float: "right",width: "50px" }} onClick={submitButton} type="submit">{t('Submit')}</button>}
                                    <button className="btn  btn-update btn-sm ml-2" id="close" style={{ width: "45px", backgroundColor: "buttonface", float: "right" }} onClick={(event) => closeHandler("Open")} >{t('Close')}</button>
                                </div>
                            </div>
                        </div>
                        <div className="card-body" style={{ minHeight: "60vh" }}>

                            {loading ? <>
                                <div className="loader-container">
                                    <div className="spinner"></div>
                                </div>
                            </> : ""}

                            <div className="container">
                                <div className="row">
                                    <label className="col-md-2 text-left">{t('FirstName')}<span className={classes.mandatory}>*</span></label>
                                    <input id="aid" onChange={inputsHandler} value={inputField.FirstName} autoComplete="no" name="FirstName" type="text" className="col-md-2 textbox-padding form-control" />
                                    <div className="col-md-2"></div>
                                    <label className="col-md-2 text-left">{t('LastName')}<span className={classes.mandatory}>*</span></label>
                                    <input id="aid" onChange={inputsHandler} value={inputField.LastName} autoComplete='no' name="LastName" type="text" className=" col-md-2 textbox-padding form-control" />
                                </div>

                                <div className="row ">
                                    <label className="col-md-2 text-left">{t('UserList.PartnerCompanyName')}<span className={classes.mandatory}>*</span></label>


                                    <Select
                                        id="DistributorId"
                                        className="col-md-2 mt-2 textbox-padding"
                                        options={distributorList}
                                        placeholder={t('Select')}
                                        onChange={(event) => OndropdownChange(event, 'DistributorId')}
                                        isSearchable={true}
                                        styles={Service.getSelectstyle()}
                                        value={distributorList.filter(function (option) {
                                            return option.value === inputField.DistributorId;
                                        })} />

                                    <div className="col-md-2"></div>
                                    <label className="col-md-2 text-left">{t('UserList.PartnerCompanyRole')}<span className={classes.mandatory}>*</span></label>
                                    <input name="PartnerCompanyRole" type="text" onChange={inputsHandler} value={inputField.PartnerCompanyRole} className=" col-md-2 mt-2 textbox-padding form-control" />

                                </div>

                                <div className="row">
                                    <label className="col-md-2 mt-2 text-left">{t('Email')}<span className={classes.mandatory}>*</span></label>
                                    <input id="aid" disabled={typeof props.id === 'undefined' ? false : true} onChange={emailinputhandler} value={inputField.Email} autoComplete='no' name="Email" type="email" className="col-md-2 mt-2 textbox-padding form-control" />

                                    <div className="col-md-2 mt-2 text-left">
                                        <span className='errormessage'>{useravailable === "User available" ? "" : useravailable} {invalidemail === true ? "Invalid Email" : ""}</span>
                                    </div>

                                    <label className="col-md-2 mt-2 text-left">{t('UserName')}<span className={classes.mandatory}>*</span></label>
                                    <input id="aid" onChange={inputsHandler} value={inputField.Username} autoComplete='no' name="Username" type="text" className="col-md-2 mt-2 textbox-padding form-control" disabled />

                                </div>
                                <div className="row">

                                    <label className="col-md-2 mt-2 text-left">{t('Password')}<span className={classes.mandatory}>*</span></label>
                                    <input id="aid" onChange={inputsHandler} value={inputField.Password} autoComplete='no' name="Password" type="password" className="col-md-2 mt-2 textbox-padding form-control" />

                                    <div className="col-md-2"></div>
                                    <label className="col-md-2 mt-2 text-left">{t('UserList.ConfirmPassword')}<span className={classes.mandatory}>*</span></label>
                                    <input id="aid" onChange={inputsHandler} value={inputField.ConfirmPassword} autoComplete='no' name="ConfirmPassword" type="password" className="col-md-2 mt-2 textbox-padding form-control" />
                                </div>
                                <div className="row">
                                    <label className="col-md-2 mt-2 text-left">{t('DateFormat')}</label>
                                    <select name="dateformat" id="dateformat" onChange={inputsHandler} style={{ fontSize: "0.75rem" }} value={inputField.dateformat} className="col-md-2 textbox-padding mt-2 form-control">
                                        <option >{t('Select')}</option>
                                        <option value="dd/MM/yyyy">DD/MM/YYYY</option>
                                        <option value="MM/dd/yyyy">MM/DD/YYYY</option>
                                    </select>
                                    <div className="col-md-2"></div>
                                    <label className="col-md-2 mt-2 text-left">{t('CompanyProfile.DefaultCurrency')}<span className={classes.mandatory}>*</span></label>
                                    <Select
                                        className="col-md-2 mt-2 textbox-padding"
                                        options={currencyList}
                                        placeholder={t('Select')}
                                        value={currencyList.filter(function (option) {
                                            return option.value === inputField.CurrencyId;
                                        })}
                                        onChange={(event) => OndropdownChange(event, 'CurrencyId')}
                                        isSearchable={true}
                                        styles={Service.getSelectstyle()}
                                 
                                    />
                                </div>
                                <div className="row mt-2">
                                    <label className="col-md-2 mt-2 text-left">{t('Locale')}<span className={classes.mandatory}>*</span></label>
                                    <select name="Locale" id="Locale" onChange={inputsHandler} style={{ fontSize: "0.75rem" }} value={inputField.Locale} className="col-md-2 textbox-padding mt-2 form-control">
                                        <option >Select</option>
                                        <option value="Indian">Indian</option>
                                        <option value="International">International</option>
                                    </select>
                                    <div className="col-md-2"></div>
                                    <label className="col-md-2 mt-2 text-left">{t('Language')}<span className={classes.mandatory}>*</span></label>
                                    <select name="Languages" id="Languages" onChange={inputsHandler} style={{ fontSize: "0.75rem" }} value={inputField.Languages} className="col-md-2 textbox-padding mt-2 form-control">
                                        <option >Select</option>
                                        <option value="en">English</option>
                                        <option value="it">Italian</option>
                                    </select>
                                    <div className="col-md-2"></div>
                                </div>

                                <div className="row mt-2">
                                    <label className="col-md-2 mt-2 text-left">{t('Roles')}<span className={classes.mandatory}>*</span></label>
                                    <Multiselect
                                        onSelect={handleChange}
                                        onRemove={handleChange}
                                        options={Roles}
                                        displayValue="Name"
                                        selectedValues={selectedRoles}
                                        style={regionStyles}
                                        className="custom-multiselect form-control"
                                    />
                                </div>

                                <div className="row mt-2">
                                    <label className="col-md-2 mt-2 text-left">{t('Groups')}<span className={classes.mandatory}>*</span></label>
                                    <Multiselect
                                        onSelect={handleChangeGroup}
                                        onRemove={handleChangeGroup}
                                        options={Groups}
                                        displayValue="Label"
                                        style={regionStyles}
                                        selectedValues={selectedGroups}
                                        className="custom-multiselect form-control"
                                    />
                                </div>
                                <div className="row">
                                    <label className="col-md-2 mt-2 text-left">{t('UserList.UploadPicture')}<span className={classes.mandatory}>*</span></label>
                                    < Form.Control type="file" onChange={saveFileSelected} name="ProfileFile" className="mt-2 col-md-2 mt-2 text-left"/>
                                    {(inputField.UploadPic != "" && inputField.UploadPic != null)
                                        ? <>      <img style={{ width: "140px" }} src={inputField.UploadPic}></img></> : ""

                                    }

                                </div>
                            </div>


                        </div>

                    </div>
                </div>
            </div>
          
            <Modal show={closeModal}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Body style={{ "textAlign": 'center', fontFamily: 'Segoe UI' }}>{t('DefaultValue.YourChangesWillBeLost!AreYouSureYouWantToClose?')}</Modal.Body>
                <Modal.Footer>
                    <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black',width: "45px" }} size='sm' onClick={() => closeHandler("Close")}>
                        {t('Close')}
                    </Button>
                    <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black',width: "50px" }} size='sm' onClick={() => closeHandler("Cancel")}>
                        {t('Cancel')}
                    </Button>
                </Modal.Footer>
            </Modal>
            <CustomErrorPopup ErrorMessage={errorMessage} show={showErrorModal} ErrorStatus={errorStatus}
                onHide={() => setShowErrorModal(false)}></CustomErrorPopup>
        </div>
    </>)
}
export default PartnerProfileDetails