import Service from '../../../Services/Service';
import React, { useState, useEffect } from 'react'
import { Link, useLocation, useNavigate } from "react-router-dom"
import { Form, FormGroup, Col, Row, Button, InputGroup, Collapse, Modal } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import SessionManage from '../../../SessionManagement/SessionManage';
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import Select from "react-select";
import Validation from '../../../Services/Validation';
import moment from 'moment'
import classes from '../../RebateProgramComponent/CreateRebateComponent/Form.module.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import $, { each } from 'jquery';
import CustomErrorPopup from '../../../UI/CustomErrorPopup';


function CreateNewDistributorBudgetedAmountPolicyView(props) {
  const navigate = useNavigate();
  const [userPerms, setuserPermission] = useState([]);
  const [t, i18n] = useTranslation('translation');
  const [distributorList, setDistributorList] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorStatus, setErrorStatus] = useState("Error");
  const [dateRange, setDateRange] = useState([]);
  const [Region, setRegion] = useState([]);
  const [selectedDistrbutorId, setselectedDistrbutorId] = useState([]);
  const [selectedBudgetedPeriod, setselectedBudgetedPeriod] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState([]);
  const [selectedCurrencyId, setselectedCurrencyId] = useState([]);
  const [StatusLabel, setStatusLabel] = useState();

  const [showSubmitModal, setShowSubmitModal] = useState(false);
  const [commentsForSubmit, setCommentsForSubmit] = useState("");
  const [showExceptionWorkflow, setshowExceptionWorkflow] = useState('');
  const [showWorkflow, setshowWorkflow] = useState('');
  const [sidebarwidth, setsidebarwidth] = useState('');
  const [workflowchart, setworkflowchart] = useState([]);
  const [workflowData, setWorkflowData] = useState([]);
  const [distributorbudgetId, setdistributorbudgetId] = useState([]);
  const [showspinnerworkflow, setshowspinnerworkflow] = useState('');
  const [workflowApprovers, setWorkflowApprovers] = useState([])
  const [workflowLevelCount, setWorkflowLevelCount] = useState('');
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [currencyList, setCurrencyList] = useState([]);
  const [loadedFrom, setLoadedFrom] = useState('');
  const [isLocked, setIsLocked] = useState(false);
  const [lockedBy, setLockedBy] = useState('');
  const [showApprovedModal, setShowApprovedModal] = useState(false)
  const [commentsForApproved, setCommentsForApproved] = useState("")
  const [showDeniedModal, setShowDeniedModal] = useState(false)
  const [commentsForDenied, setCommentsForDenied] = useState("")
  const [disableHeaderField, setdisableHeaderField] = useState(false);
  const [showWorkflowAlert, setShowWorkflowAlert] = useState(false)
  let wData = [0]
  let disabled = false;
  const [woData, setWoData] = useState('');
  const [paging, setpaging] = useState({
    pageSize: 20,
    pageNumber: 1
  })
  let loggedInUser = sessionStorage.getItem("id")
  const [inputField, setInputField] = useState({
    Id: 0, DistributorId: '', DistributorBudgetId: '', BudgetedPeriod: '', BudgetedAmount: '', CreatedUserId: '', RegionId: '', Status: '',
    LastModifiedUserId: '', CreatedDate: '', CreatedUserName: '', CurrencyId: '', StatusLabel: '', LastModifiedDate: ''

  })


  const [updateNav, setUpdateNav] = useState("/DistributorBudgetedAmountPolicylist")




  inputField.CreatedUserId = loggedInUser;
  const getDistributorDate = (method) => {
    Service.getdata(method).then((res) => {
      setDistributorList(res.data.DataList);
    });


  }




  const getUserPermission = (pathname) => {

    Service.GetUserPermission(pathname).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null' && res.data !== '') {
          if (res.data.DataList !== undefined) {
            setuserPermission(res.data.DataList)
          }
        }
      }
    });
  }
  const OnChangeDateRange = (data) => {
    setselectedBudgetedPeriod(data.label);
    inputField.BudgetedPeriod = data.label;
  }

  const OnChangeHandlerCategory = (data) => {
    setselectedCurrencyId(data.label);
    inputField.CurrencyId = data.Id;
  }
  const inputsHandler = (e) => {

    const { name, value } = e.target;
    setInputField((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }
  const OndropdownChange = (e, name) => {

    setInputField({ ...inputField, [name]: e.value });


    if (name === 'RegionId') {
      Service.getdatabyCountryId('SDCommon/GetDistributorByCountry', e.value).then((res) => {
        if (typeof res !== 'undefined') {
          if (typeof res.data !== 'undefined' && res.data !== 'null') {
            let data = res.data
           
            setDistributorList(data)
            setSelectedRegion(e.Name);
            inputField.RegionId = e.Id;
          }
        }
      });
    }
    if (name === 'DistributorId') {

      Service.getdatabyId('SDCommon/GetCountryByDistributor', e.value).then((res) => {
        if (typeof res !== 'undefined') {
          if (typeof res.data !== 'undefined' && res.data !== 'null') {
            let data = res.data
            setselectedDistrbutorId(e.label);
            inputField.DistributorId = e.value;
           
          }
        }
      });
    }
  };
  const SaveHandler = (e, Status, flag) => {

    e.preventDefault();
    let today = moment(new Date());
    inputField.CreatedDate = today;
    inputField.LastModifiedDate = today;
    let HeaderData = {
      'CreatedUserId': parseInt(loggedInUser),
      "LastModifiedUserId": parseInt(loggedInUser),
      "LastModifiedDate" : parseInt(loggedInUser),
    }
    HeaderData = inputField;
    
    if (flag == 'saveclose') {
   
      Service.createorupdate("DistributorBudget/CreateOrUpdate", HeaderData).then((res) => {

     
        if (typeof res !== 'undefined') {
          if (typeof res.data !== 'undefined' && res.data !== 'null') {
            let rData = res.data
    
            if (res.data.Status == 'Warning') {
              setErrorMessage(t('Rebates.Validations.RebateHeaderForTheSameCustomerAlreadyExistsWithSubmitted/ApprovedProgramlines'))
              setErrorStatus(res.data.Status)
              setShowErrorModal(true);
            } else {
              if (res.data.Status == 'success') {

                toast.success('Distributor Budget saved successfully !', {
                  position: toast.POSITION.TOP_RIGHT,
                  
                });
              }
         
              closeHandler()
            }
          }
        }
       
      });
    }
    if (flag == 'save') {
      Service.createorupdate("DistributorBudget/CreateOrUpdate", HeaderData).then((res) => {

        if (typeof res !== 'undefined') {
          if (typeof res.data !== 'undefined' && res.data !== 'null') {
            let rData = res.data
     
            if (res.data.Status == 'Warning') {
              setErrorMessage(t('Rebates.Validations.RebateHeaderForTheSameCustomerAlreadyExistsWithSubmitted/ApprovedProgramlines'))
              setErrorStatus(res.data.Status)
              setShowErrorModal(true);
            } else {
              if (res.data.Status == 'success') {

                toast.success('Distributor Budget saved successfully !', {
                  position: toast.POSITION.TOP_RIGHT,
         
                });
              }
              getdata("DistributorBudget/GetById", res.data.Id);
            }
          }
        }
    
      });
    }

  }

  const workflowAlert = (e, flag) => {
    if (flag == 'open') {
      if (workflowApprovers.length > 0) {
        setShowWorkflowAlert(true)
      }
      else {
        showSubmitDialog(e, 'showSubmitDialog')
      }
    }
    else if (flag == 'close') {
      setShowWorkflowAlert(false)
      showSubmitDialog(e, 'showSubmitDialog')
    }
  }


  const showSubmitDialog = (e, flag) => {

    if (flag === 'showSubmitDialog') {
      setShowSubmitModal(!showSubmitModal)

    }
    else if (flag === 'proceed') {
      setShowSubmitModal(false)
      SubmitHandler()

    }
  }
  const SubmitHandler = (e, Status) => {


    let SubmitData = {
      'CreatedUserId': parseInt(loggedInUser),
      "LastModifiedUserId": parseInt(loggedInUser),
    }
    SubmitData = inputField;
  
    Service.SubmitExceptionPolicy("DistributorBudget/SubmitDistributorBudget", inputField.Id, loggedInUser, commentsForSubmit, SubmitData).then(res => {

      if (typeof res !== 'undefined') {

        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          let rData = res.data;
   
          if (res.data.Code == 200) {
            if (res.data !== 'null' && res.data !== 'undefined') {
              if (res.data.Status != 'Failure') {

                let id = parseInt(res.data.Id)
                setCommentsForSubmit('')
                setShowSubmitModal(!showSubmitModal)
                toast.success('Submitted Successfully !', {
                  position: toast.POSITION.TOP_RIGHT,
                });
                closeHandler('Close');
               
              }
              else {
                
                setErrorMessage(res.data.ErrorMessage);
                setErrorStatus("Error")
                setShowErrorModal(true)
                setShowSubmitModal(!showSubmitModal)
               
              }

            }
          }
        }
      }
    });

  }
  const closeHandler = (flag) => {
    Service.LockUnLockEntity('Configurationprop/UnLockEntity', "DistributorBudget", inputField.Id, loggedInUser).then((res) => {

    });
    if (loadedFrom === 'fromApproval') {
      navigate("/DistributorBudgetedAmountPolicylist/approvals/pending");
    }
    else if (loadedFrom === 'fromapprovalcompleted') {
      navigate("/DistributorBudgetedAmountPolicylist/approvals/archive");
    }
    else {
      navigate("/DistributorBudgetedAmountPolicylist");
    }
  }
  const clickShowWorkflow = (val, id) => {
    setsidebarwidth("70vw");
    if (val === "Workflow")
      setshowExceptionWorkflow(true);
    setshowWorkflow(false);
  }
  const getExceptionWorkflowDetails = (id, pagesize, pagenumber) => {
    Service.getdatabyId('DistributorBudget/GetDistributorBudgetWorkflowById', id, pagesize, pagenumber).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {
            if (res.data.DataList !== 'null' && res.data.DataList !== 'undefined') {
              wData = res.data.DataList;
              setWorkflowData(wData);
              setshowspinnerworkflow(false)
              setWoData('get data');
            }
          }
        }
      }
    });

  }



  const _ = require('lodash');
  const getExceptionWorkflowApprovers = (method, id) => {

    Service.getdatabyId(method, id).then((res) => {

      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {

          setWorkflowApprovers(res.data)
          setWorkflowLevelCount(res.data.length)

          const sortdata = _.orderBy(res.data, ['Workflowlevel'], ['asc'])
          setworkflowchart(sortdata);

        }

      }

    });
  }
  const clickCloseShipWorkflow = () => {
    setshowWorkflow(false);
  }
  const GetDateRangeAndProducts = (method) => {
    Service.getdata(method).then((res) => {
      if (Service.checkUndefined(res) === true) {
        if (Service.checkDataUndefined(res.data) == true) {
          setDateRange(res.data.lstDateRange);

        }
      }
    });
  };
  const GetRegion = (method) => {

    Service.getdata(method).then((res) => {

      if (Service.checkUndefined(res) === true) {
        if (Service.checkDataUndefined(res.data) == true) {

          setRegion(res.data.DataList);

        }
      }
    });
  };
  const GetCurrency = (method) => {

    Service.getdata(method).then((res) => {

      if (Service.checkUndefined(res) === true) {
        if (Service.checkDataUndefined(res.data) == true) {
          setCurrencyList(res.data.DataList);

        }
      }
    });
  };


  const clickCloseAccuralWorkflow = () => {
    setshowExceptionWorkflow(false);
  }
  const getdata = (method, id) => {
    Service.getdatabyId(method, id).then((res) => {

      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {
            if (res.data !== 'null' && res.data !== 'undefined') {
       
              setInputField(res.data);
              setselectedDistrbutorId(res.data.DistributorName);
              setSelectedRegion(res.data.Region);
              setselectedBudgetedPeriod(res.data.BudgetedPeriod);
              setdistributorbudgetId(res.data.Id);
              setStatusLabel(res.data.StatusLabel);
              setselectedCurrencyId(res.data.CurrencyName);
           
              if ((res.data.Locked == true && res.data.LockedBy.toString() !== loggedInUser)) {
                $("#libread").html(t('Policies.CreateNewDistributorBudgetedAmountPolicy') + ` Locked By ${res.data.LockedByUserName}`);
                setdisableHeaderField(true)
              }
              else{
                $("#libread").html( res.data.DistributorBudgetId);
              }
              if (res.data.StatusLabel.toLowerCase() == "approved" || res.data.StatusLabel.toLowerCase() == "inreview" || res.data.StatusLabel.toLowerCase() == "denied") {
                setdisableHeaderField(true)
              }

              if (props.name != '' && props.name != undefined) {
                if (props.name.toLocaleLowerCase() == 'fromapproval') {
                  $("#headertxt").html('Distributor Budget Approval- Pending Action');
                  setUpdateNav("/DistributorBudgetedAmountPolicylist/approvals/pending")
                }
                else if (props.name.toLocaleLowerCase() == 'fromapprovalcompleted') {
                  setUpdateNav("/DistributorBudgetedAmountPolicylist/approvals/archive")
                  $("#headertxt").html('Distributor Budget Approval- Workflow Completed');
                }
              }
              else {
                $("#headertxt").html(t('Policies.DistributorBudgetedAmountPolicyList'));
                setUpdateNav("/DistributorBudgetedAmountPolicylist")
              }
              if (res.data.StatusLabel == 'New') {
                insertWorkflowDetails(res.data.Id, loggedInUser)
              }
              setTimeout(() => {
                getExceptionWorkflowApprovers('DistributorBudget/GetDistributorBudgetWorkflowApprovers', props.id);
                getExceptionWorkflowDetails(props.id, paging.pageSize, paging.pageNumber)
              },500);
            }
          }
        }
      }
      if (((props.name == 'fromApproval' || props.name == 'fromapprovalcompleted') && res.data.StatusLabel == 'InReview' && res.data.Locked == false) || (res.data.StatusLabel == 'New' && res.data.Locked == false)) {
        Service.LockUnLockEntity('Configurationprop/LockEntity', "DistributorBudget", id, loggedInUser).then((res1) => {
          if (typeof res1 !== 'undefined') {
            setLockedBy(loggedInUser);
            setIsLocked(true);
          }
        });
      }
      else {
        setLockedBy(res.data.LockedBy);
        setIsLocked(res.data.Locked);
      }
    });
  }




  useEffect(() => {

    setLoadedFrom(props.name);
    getUserPermission("/DistributorBudgetedAmountPolicylist/new/0")
    getDistributorDate("DistributorBudget/GetAllDistributorBudget")
    GetDateRangeAndProducts("DashBoard/GetDateRangeAndProducts");
    GetCurrency("DistributorBudget/GetAllCurrency");
    GetRegion("DistributorBudget/GetAllDistributorRegion");

    if (props.id != 0) {
      getdata("DistributorBudget/GetById", props.id);

    }
    else {
      setLockedBy(loggedInUser);
      setIsLocked(true);
    }
  }, []);
  if (isLocked === false || (isLocked === true && lockedBy == loggedInUser)) {
    disabled = false;
  }

  if (StatusLabel != "New" && StatusLabel != "undefined" && StatusLabel != null) {
    disabled = true;
  }
  const showApprovedDialog = (flag) => {

    if (flag === 'showApprovedDialog') {
      setCommentsForApproved("")
      setShowApprovedModal(!showApprovedModal)
    }
    else if (flag === 'proceed') {
      ApproveDistributorBudget()
      setShowApprovedModal(!showApprovedModal)

    }
  }

  const showDeniedDialog = (flag) => {
    if (flag === 'showDeniedDialog') {
      setCommentsForDenied("")
      setShowDeniedModal(!showDeniedModal)
    }
    else if (flag === 'proceed') {
      DenyDistributorBudget()
      setShowDeniedModal(!showDeniedModal)

    }
  }

  const insertWorkflowDetails = (id, userId,) => {
    Service.workflowDataDistributor('DistributorBudget/InsertWorkflow', id, userId).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
        
          if (res.status == 200) {
            if (res.data !== 'null' && res.data !== 'undefined') {
          
            }
          }
        }
      }
    })
  }






  const ApproveDistributorBudget = () => {

    let Id = inputField.Id
    let Comments = commentsForApproved
    let UserId = SessionManage.getuserid();//state.createdUserId
    let data = { Id, Comments, UserId }

    Service.createorupdate('DistributorBudget/ApproveDistributorBudget', data).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {
    
            if (res.data !== 'null' && res.data !== 'undefined') {
              toast.success('Budgeted Policy approved successfully', {
                position: toast.POSITION.TOP_RIGHT,
              });
              Service.LockUnLockEntity('Configurationprop/UnLockEntity', "DistributorBudget", Id, loggedInUser).then((res) => {
                if (typeof res !== 'undefined') {
                  if (typeof res.data !== 'undefined' && res.data !== 'null') {

                  }
                }

              });
              setTimeout(() => {
                navigate("/DistributorBudgetedAmountPolicylist/approvals/pending")
              }, 800)

            }

          }
          else if (res.status !== 200) {
            toast.success('Error in approval !', {
              position: toast.POSITION.TOP_RIGHT,
            });
            setErrorMessage(res.data.ErrorMessage);
            setErrorStatus("Error")
            setShowErrorModal(true)
          }
        }
      }

    });
  }

  const DenyDistributorBudget = () => {

    let Id = inputField.Id
    let Comments = commentsForDenied
    let UserId = SessionManage.getuserid();//state.createdUserId
    let data = { Id, Comments, UserId }
  
    Service.createorupdate('DistributorBudget/DenyDistributorBudget', data).then((res) => {
    
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {
        
            if (res.data !== 'null' && res.data !== 'undefined') {
              toast.success('Budgeted Policy denied successfully', {
                position: toast.POSITION.TOP_RIGHT,
              });
              Service.LockUnLockEntity('Configurationprop/UnLockEntity', "DistributorBudget", Id, loggedInUser).then((res) => {
                if (typeof res !== 'undefined') {
                  if (typeof res.data !== 'undefined' && res.data !== 'null') {

                  }
                }
              });
              setTimeout(() => {
                navigate("/DistributorBudgetedAmountPolicylist/approvals/pending")
              }, 800)
            }

          }
          else if (res.status !== 200) {
            toast.success('Error in deny !', {
              position: toast.POSITION.TOP_RIGHT,
            });
            setErrorMessage(res.data.ErrorMessage);
            setErrorStatus("Error")
            setShowErrorModal(true)
          }
        }
      }

    });
  }
  return (
    <>
      <div className="container-fluid content-top-gap">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb my-breadcrumb">
            <li className="breadcrumb-item"><Link to="/">{t('Home.title')}</Link></li>
            <li className="breadcrumb-item active" aria-current="page"><Link to="/policies">{t('Menu.Policies')}</Link></li>
            <li className="breadcrumb-item active" aria-current="page"><Link id="headertxt" to={updateNav}>{t('Policies.DistributorBudgetedAmountPolicylist')}</Link></li>
            <li className="breadcrumb-item active" id="libread" aria-current="page">{t('Policies.CreateNewDistributorBudgetedAmountPolicy')}</li>
          </ol>
        </nav>
        <ToastContainer autoClose={1000} />

        <div className="row">

          <div className="col-lg-12 pr-lg-2">
            <div className="card text-center card_border">
              <div className="card-header chart-grid__header">
                <div className="row">
                  <div className="col-md-5 text-left">
                    {t('Policies.DistributorBudgetedAmountPolicy')}
                  </div>
                  <div className="col-md-7">
                 
                    {Service.editPerms(userPerms) == false ? "" : disabled === false ?
                      <>
                        <button className="btn  btn-update btn-sm ml-1" onClick={(Event) => { SaveHandler(Event, "new", "save") }} style={{ width: "40px", float: "right"}} type="submit" >{t('Save')}</button>
                        <button className="btn  btn-update btn-sm ml-1" onClick={(e) => SaveHandler(e, "new", 'saveclose')} style={{ width: "80px", float: "right" }} type="submit" >{t('Save & Close')}</button>
                        <button className="btn  btn-update btn-sm ml-1" onClick={(event) => workflowAlert(event, 'open')} style={{ width: "50px", float: "right" }} type="submit" >{t('Submit')}</button>
                      </>
                      : ''}
                    <button className="btn  btn-update btn-sm ml-1" onClick={() => { closeHandler("Close") }} style={{ width: "50px", float: "right" }} type="submit" >{t('Cancel')}</button>
                    <button className="btn  btn-update btn-sm ml-1" style={{ width: "65px", float: "right" }} type="submit" onClick={() => { clickShowWorkflow("Workflow", distributorbudgetId) }} >{t('Workflow')}</button>

                    {Service.editPerms(userPerms) == false ? "" :
                      (loadedFrom == 'fromApproval' && StatusLabel == 'InReview') ?
                        (isLocked == true && lockedBy == loggedInUser) ?

                          <>
                            <button type="button" style={{ float: "right",width: "55px" }} className="btn btn-sm btn-update ml-1" onClick={() => showApprovedDialog('showApprovedDialog')}>{t('Approve')}</button>
                            <button type="button" style={{ float: "right",width: "40px" }} className="btn btn-sm btn-update ml-1" onClick={() => showDeniedDialog('showDeniedDialog')} >{t('Deny')}</button>
                          </>
                          : ""
                        : <></>
                    } </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div className="row mt-1">
          <div className="col-sm-5">
            <div className="row">
              <div className="col-lg-12 pr-lg-2">
                <div className="card text-center card_border">
                  <div className="card-body">
                    <div className="container">
                      <div className="row">
                        <label className="col-md-6 text-left">{t('ExceptionPolicy.PolicyId')}</label>
                        <input disabled={true} name="DistributorId" type="text" className="col-md-6 mb-2 textbox-padding" value={inputField.DistributorBudgetId} />
                        <label className="col-md-6 mt-2 text-left">{t('Policies.Fiscalperiod')}<span className={classes.mandatory}>*</span></label>
                        <Select
                    
                          className={"col-md-6 textbox-padding-dropdown mt-2 fontsizedropdown ${disabledClass}"}
                          options={dateRange}
                          placeholder={t('Select')}

                          value={dateRange.filter(function (option) {
                            return option.label === `${selectedBudgetedPeriod}`;
                          })}
                          name="Budgeted_Period"
                          onChange={OnChangeDateRange}

                          isSearchable={true}
                          styles={Service.getSelectstyle()}

                      
                          isDisabled={disableHeaderField}
                        />
                        <br></br>
                        <label className="col-md-6 mt-2 text-left">{t('Policies.Region')}<span className={classes.mandatory}>*</span></label>


                        <Select
        
                          className={"col-md-6 textbox-padding-dropdown mt-2 fontsizedropdown ${disabledClass}"}
                          options={Region}
                          placeholder={t('Select')}
                          value={Region.filter(function (option) {
                            return option.Name === `${selectedRegion}`;
                          })}
                          name="RegionId"
                    
                          onChange={(event) => OndropdownChange(event, 'RegionId')}
                          isSearchable={true}
                          styles={Service.getSelectstyle()}
                          isDisabled={disableHeaderField}
                
                        />


                        <br></br>
                        <label className="col-md-6 mt-2 text-left">{t('ship&debit.DistributorName')}<span className={classes.mandatory}>*</span></label>


                        <Select
                       
                          className={"col-md-6 textbox-padding-dropdown mt-2 fontsizedropdown ${disabledClass}"}
                          options={distributorList}
                          placeholder={t('Select')}
                          value={distributorList.filter(function (option) {
                            return option.label === `${selectedDistrbutorId}`;
                          })}
                          name="DistributorId"
                     
                          onChange={(event) => OndropdownChange(event, 'DistributorId')}
                          isSearchable={true}
                          styles={Service.getSelectstyle()}

                       
                          isDisabled={disableHeaderField}
                        />
                        <br></br>


                        <label className="col-md-6 text-left">{t('Policies.BudgetAmount')}<span className={classes.mandatory}>*</span></label>
                        <Select
                          className={"col-md-3 textbox-padding-dropdown mt-2 fontsizedropdown ${disabledClass}"}
                          options={currencyList}
                          placeholder={t('Select')}
                          value={currencyList.filter(function (option) {

                            return option.label === `${selectedCurrencyId}`; return option.label === `${selectedDistrbutorId}`;

                          })}
                          onChange={OnChangeHandlerCategory}
                          isSearchable={true}
                          styles={Service.getSelectstyle()}
                          isDisabled={disableHeaderField}

                        />
                        <input name="BudgetedAmount" onChange={inputsHandler} value={inputField.BudgetedAmount} type="number" className="col-md-2 mb-2 ml-2 textbox-padding mt-2 pb-1 form-control" disabled={disableHeaderField} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal show={showSubmitModal} size="bg"
          centered>
          <Modal.Header>
            <Modal.Title>{t('Rebates.SubmitComments')}</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ "textAlign": 'center' }} >
            <Form.Control className={classes['Textarea-width']} as="textarea" rows={2} value={commentsForSubmit} name="commentsForSubmit"
              onChange={(e) => { setCommentsForSubmit(e.target.value) }} />
          </Modal.Body>
          <Modal.Footer>
            <Button size='sm' style={{ background: 'buttonface', color: 'black', borderColor: 'black',width: "50px" }} onClick={(event) => showSubmitDialog(event, 'showSubmitDialog')}>
              {t('Cancel')}
            </Button>
            <Button size='sm' style={{ background: 'buttonface', color: 'black', borderColor: 'black',width: "50px" }} onClick={(event) => showSubmitDialog(event, 'proceed')}>
              {t('Submit')}
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={showApprovedModal} size="bg"
          centered>
          <Modal.Header>
            <Modal.Title>{t('Rebates.ApprovalComments')}</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ "textAlign": 'center', fontFamily: 'Segoe UI' }} >
            <Form.Control className={classes['Textarea-width']} as="textarea" rows={2} value={commentsForApproved} name="commentsForApproved"
              onChange={(e) => { setCommentsForApproved(e.target.value) }} />
          </Modal.Body>
          <Modal.Footer>
            <Button size='sm' style={{ background: 'buttonface', color: 'black', borderColor: 'black',width: "50px" }} onClick={() => showApprovedDialog('showApprovedDialog')}>
              {t('Cancel')}
            </Button>
            <Button size='sm' style={{ background: 'buttonface', color: 'black', borderColor: 'black',width: "50px" }} onClick={() => showApprovedDialog('proceed')}>
              {t('Submit')}
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showWorkflowAlert}
          size="sm"
          aria-labelledby="contained-modal-title-vcenter"
          centered>
          <Modal.Body style={{ "textAlign": 'center' }}>{t('Accruals.WorkflowAlert')}</Modal.Body>
          <Modal.Footer>
            <Button size='sm' style={{ background: '#eff1f9', color: 'black', borderColor: 'black',width: "30px" }} onClick={(e) => workflowAlert(e ,'close')}>
              {t('Ok')}
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showDeniedModal} size="bg"
          centered>
          <Modal.Header>
            <Modal.Title>{t('Rebates.DeniedComments')}</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ "textAlign": 'center', fontFamily: 'Segoe UI' }} >
            <Form.Control className={classes['Textarea-width']} as="textarea" rows={2} value={commentsForDenied} name="commentsForDenied"
              onChange={(e) => { setCommentsForDenied(e.target.value) }} />
          </Modal.Body>
          <Modal.Footer>
            <Button size='sm' style={{ background: 'buttonface', color: 'black', borderColor: 'black',width: "50px" }} onClick={() => showDeniedDialog('showDeniedDialog')}>
              {t('Cancel')}
            </Button>
            <Button size='sm' style={{ background: 'buttonface', color: 'black', borderColor: 'black',width: "50px" }} onClick={() => showDeniedDialog('proceed')}>
              {t('Submit')}
            </Button>
          </Modal.Footer>
        </Modal>
        {
          showExceptionWorkflow === true ?
            <div id="mySidepanel" className="sidepanel sidepanelborder" style={{ width: sidebarwidth }}>

              {showWorkflow === false ?
                <div className="container" id="workflow">
                  <div className="row mt-3">
                    <div className="col-lg-12 pr-lg-2">
                      <div className="card text-center card_border">
                        <div className="card-header chart-grid__header">
                          <div className="row">
                            <div className="col-md-10 text-left">
                              {t('Workflow')}
                            </div>
                            <div className="col-md-2">
                              <button className="btn btn-outline-dark  btn-sm" onClick={clickCloseAccuralWorkflow} type="submit" style={{width: "45px"}}>{t('Close')}</button>
                            </div>
                          </div>
                        </div>

                        <div className="card-body" style={{ minHeight: "55vh" }}>
                     

                          {workflowchart.length > 0 ?
                            <div className='col-md-12'>
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th className='workflowchart-levelwidth workflowchart-font'>Level</th>
                                    <th className='font-center workflowchart-font'>Users</th>
                                    <th className='font-center workflowchart-font'>Approval</th>
                                  </tr>
                                </thead>

                                <tbody>
                                  {
                                    workflowchart.map((i) =>
                                      <tr key={i.Id}>
                                        <td className='workflowchart-font square-levels'>{i.Levels}</td>
                                        <td className='font-center'>
                                          <ul >
                                            {
                                              i.lstworkflowapprover.map((j, index) => {
                                                return <>

                                                  {
                                                    index < 2 ?
                                                      j.IsApproved === true || j.IsDenied == true ?
                                                        <li className='workflowchartperson'>
                                                          <span className='workflowtpersoncolor'><i class="fa fa-user-circle-o" aria-hidden="true"></i>
                                                            <br></br> {j.FullName}</span>
                                                        </li>
                                                        :
                                                        <>
                                                          <li className='workflowchartperson'>
                                                            <i class="fa fa-user-circle-o" aria-hidden="true"></i>
                                                            <br></br> {j.FullName}
                                                          </li>
                                                        </>
                                                      :
                                                      ''
                                                  }
                                                </>

                                              })
                                            }

                                            {
                                              i.lstworkflowapprover.length > 2 ?
                                                <li className='workflowchartperson tooltip_workflow'>
                                                  <i class="fa fa-user-circle-o" aria-hidden="true"></i>
                                                  <br></br> {t('MoreUsers')}
                                                  <span class="tooltiptext">

                                                    {
                                                      i.lstworkflowapprover.map((k, index) => {
                                                        return <span>
                                                          {
                                                            index > 1 ? <><i class="fa fa-user-circle-o" aria-hidden="true"></i>
                                                              &nbsp; {k.FullName}
                                                              <hr></hr></> : null
                                                          }


                                                        </span>
                                                      })
                                                    }

                                                  </span>
                                                </li>
                                                :
                                                ''
                                            }


                                          </ul>

                                        </td>

                                        <td className='font-center'>
                                          {
                                            i.IsApproved === true ?
                                              <span className='workflowrightcolor'>
                                                <i class="fa fa-check" aria-hidden="true"></i>
                                              </span>

                                              : i.IsDenied == true ?
                                                <span className='workflowrightcolor'>
                                                  <i class="fa fa-times" aria-hidden="true" style={{ color: "red" }}></i>
                                                </span>
                                                : ''
                                          }
                                        </td>
                                      </tr>)

                                  }
                                </tbody>

                              </table>

                            </div>
                            :
                            <div className={classes.flow}>

                            </div>
                          }


                          <div className="row">
                            <div className="col-md-12">
                              <table className="table table-bordered text-left mt-2">
                                <thead className='text-white' style={{ backgroundColor: "var(--primary)" }}>
                                  <tr>

                                    <th>{t('Rebates.Event')}</th>
                                    <th>{t('Rebates.CreatedDate')}</th>
                                    <th>{t('Rebates.User')}</th>
                                    <th>{t('Rebates.Comments')}</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {
                                    showspinnerworkflow === true ?

                                      <>
                                        <Spinner animation="grow" size="sm" variant="success" style={{ width: "0.7rem", height: "0.7rem" }} />
                                        <Spinner animation="grow" size="sm" variant="danger" style={{ width: "0.7rem", height: "0.7rem" }} />
                                        <Spinner animation="grow" size="sm" variant="warning" style={{ width: "0.7rem", height: "0.7rem" }} />
                                      </>
                                      :
                                      workflowData.length === 0 ?
                                        <tr>
                                          <td></td>
                                          <td></td>
                                          <td>
                                            {t('NoDataAvailable')}
                                          </td> <td></td></tr>
                                        :
                                        woData === '' || woData === 'undefined' ?
                                          <tr></tr> :
                                          workflowData.map((i) =>
                                            <tr key={i.id}>
                                              <td>{i.EventName}</td>
                                              {i.CreatedDate === null ? <td></td> : <td>{moment(i.CreatedDate).format(SessionManage.getcurrentdateformat())}</td>}
                                              <td>{i.CreateUserName}</td>
                                              <td>{i.Comments}</td>
                                            </tr>)

                                  }

                                </tbody>
                              </table>

                            </div>

                          </div>


                        </div>

                      </div>
                    </div>

                  </div>
                </div>
                :
                ''
              }

            </div>
            :
            ""
        }
        <CustomErrorPopup ErrorMessage={errorMessage} show={showErrorModal} ErrorStatus={errorStatus}
          onHide={() => setShowErrorModal(false)}></CustomErrorPopup>
      </div>
    </>

  )
}
export default CreateNewDistributorBudgetedAmountPolicyView