import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import classes from './CustomMessagePopup.module.css';

const CustomMessagePopup = props => {

    return (
        <Modal className={classes['Modal-div']}
            {...props}
            size="md" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header className={classes.header} closeButton id="contained-modal-title-vcenter">
                <h4 className={classes['title']} >Success</h4>
            </Modal.Header>
            <Modal.Body className={classes['Modal-body']}>
                <p className={classes['Msg']}>{props.SuccessMessage}</p>
            </Modal.Body>
            <Modal.Footer className={classes['Modal-Footar']}>
                <Button className={classes['Footer-Buttons']} onClick={props.onHide}>Ok</Button>
            </Modal.Footer>
        </Modal>
    );
}
export default CustomMessagePopup;