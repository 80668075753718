import { Link, useLocation } from "react-router-dom"
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import { Button, Modal } from "react-bootstrap";
import Service from "../../Services/Service";
import Multiselect from 'multiselect-react-dropdown';
import moment from 'moment';
import SessionManage from "../../SessionManagement/SessionManage";
import { CSVLink } from "react-csv";
import { useTranslation } from "react-i18next";
function PermissionSetListComponent() {
    const [t, i18n] = useTranslation('translation');
    const [showbutton, setshowbutton] = useState('');
    const location = useLocation();
    const [userPerms, setuserPermission] = useState([]);
    const [columnAscending, setColumnAscending] = useState(true);
    const [totalselectedshow, settotalselectedshow] = useState('');
    const [totalselectedcount, settotalselectedcount] = useState(0);
    const [totalCountlst, settotalCountlst] = useState('');
    const [ToggleStatusModal, setToggleStatusModal] = useState(false);
    const [toggleChange, setToggleChange] = useState("");
    const [toggleId, setToggleId] = useState();
    const [colName, setColName] = useState("");
    const csvInstance = useRef();
    const [csvList, setCsvList] = useState([]);
    const [sortColName, setSortColName] = useState("");
    const [sticky, setSticky] = useState("");

    const [dropdownChange, setDropdownChange] = useState('');
    let loggedInUser = SessionManage.getuserid();
    const [showfilterpopup, setshowfilterpopup] = useState(false);
    const [divleft, setdivleft] = useState('');
    const [divtop, setdivtop] = useState('');
    const [dropdownData, setDropdownData] = useState([]);
    const [filterCol, setFilterCol] = useState('');
    const [filterValue, setFilterValue] = useState('');
    const [filtertext, setfiltertext] = useState('');
    const [filterSearch, setFilterSearch] = useState("");
    const [filterdropdownData, setFilterDropdownData] = useState([]);


    const [filterValueDict, setFilterValueDict] = useState({
        Name: '',
        Label: '',
        LastModifiedDate: null,
    });
    const [multiFilterValue, setMultiFilterValue] = useState({
        createdby: '',
        LastModifiedUser_Name: '',
        IsActive: '',
    });

    const checkAll = (mycheckbox) => {
        var checkboxes = document.querySelectorAll("input[type = 'checkbox']");
        if (mycheckbox.target.checked == true) {
            settotalselectedshow(true);
            checkboxes.forEach(function (checkbox) {
                checkbox.checked = true;
            });
            settotalselectedcount(checkboxes.length - 1)
        }
        else {
            settotalselectedshow(false);
            checkboxes.forEach(function (checkbox) {
                checkbox.checked = false;
            });
            settotalselectedcount(0)
        }

    }

    const [paging, setpaging] = useState({
        pageSize: 20,
        pageNumber: 1
    })


    const [showspinner, setshowspinner] = useState('');
    const [data, setdata] = useState([]);

    const [showLoadMore, setshowLoadMore] = useState('');

    const LoadMore = (lst, totalcount) => {

        if (lst < totalcount) {
            setshowLoadMore(true);
        }
        else if (lst >= totalcount) {
            setshowLoadMore(false);
        } else {
            setshowLoadMore(false);
        }

    }

    const [checkedValueid, setCheckedValueid] = useState([]);
    const [filteredArray, setFilteredArray] = useState(data);
    const navigate = useNavigate();

    useEffect(() => {
        setshowspinner(true);
        setColumnAscending(false);
        setSortColName("Id");
        getData("Permission/GetAllByPage", paging.pageSize, paging.pageNumber, "Descending", "Id", [], null);

        var roleid = SessionManage.getroleid();
        getUserPermission(location.pathname);
        window.addEventListener("scroll", isSticky);
        return () => {
            window.removeEventListener("scroll", isSticky);
        };

    }, [0])
    const addnewfield = (data, key) => {
       
        let selectedKeys = multiFilterValue[key];
        let currentdata = data.map((i) => {
            i["ischecked"] = false;
            return i;
        });
        if (key != 'status') {
            if (selectedKeys != '') {
                currentdata.forEach((each) => {
                    selectedKeys.forEach((eachSelected) => {
                        if (each.Name == eachSelected.Name) {
                            each.ischecked = true
                        }
                    })
                })
            }
           
            setDropdownData(currentdata);
            setFilterDropdownData(currentdata)
        }
        else {
            if (selectedKeys != '') {
                currentdata.forEach((each) => {
                    selectedKeys.forEach((eachSelected) => {
                        if (each.Name == eachSelected.Name) {
                            each.ischecked = true
                        }
                    })
                })
                setDropdownData(currentdata)
                setFilterDropdownData(currentdata)
            }
            else {
                setDropdownData(currentdata)
                setFilterDropdownData(currentdata)
            }
        }
    }


    const checkAllFilter = (mycheckbox) => {
        var lastfilter = [...dropdownData]
        let data1 = []
        var checkboxes = document.querySelectorAll("input[name = 'filter-option-all']");
        if (mycheckbox.target.checked == true) {
            
            let currentdata = lastfilter.map((i) => {
                i["ischecked"] = true;
                return i;
            });
            setDropdownData(currentdata)
     
            dropdownData.map((each) => {
                
                const getallData = {
                    Name: each.Name,
                }
                data1.push(getallData)
            })
            setFilterValue(filterValue => [...filterValue, ...data1]);
            let updateddata = ([...filterValue, ...data1]);
            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: updateddata,
            }));
        }
        else {
           
            let currentdata = lastfilter.map((i) => {
                i["ischecked"] = false;
                return i;
            });
            setFilterValue([])
            setMultiFilterValue([])
        }

    }

  

    const OndropdownChange = (e, id, name) => {
        let isChecked = e.target.checked;
        var lastfilter = [...dropdownData]
        if (isChecked === true) {
            let currentdata = lastfilter.map((i) => {
                if (i.Id == id) {
                    i["ischecked"] = true;
                }
                return i;
            });
          
            setDropdownData(currentdata);
            let data1 = []
            const getallData = {
                Name: name,
            }
            data1.push(getallData)
            setFilterValue(filterValue => [...filterValue, ...data1]);
            let updateddata = ([...filterValue, ...data1]);
            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: updateddata,
            }));
        }
        else {
            let currentdata = lastfilter.map((i) => {
                if (i.Id === id) {
                    i["ischecked"] = false;
                }
                return i;
            });
       
            setDropdownData(currentdata);
            setFilterValue(filterValue => filterValue.filter(data => data.Name !== name))
            let data1 = multiFilterValue.filter(data => data.Name !== name);
            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: data1,
            }));
        }
    }

    const customMultiStyles = {
        multiselectContainer: {
            maxWidth: '188px',
            marginLeft: '3px'
        },
        chips: {
            borderRadius: '5px'
        }
    };
    const isSticky = () => {
        const scrollTop = window.scrollY;
        const stickyClass = scrollTop >= 60 ? "is-sticky-thead" : "";
        setSticky(stickyClass);
       
    };
    const class_thead = `text-white rt-thead  ${sticky}`;


    const getUserPermission = (pathname) => {
        Service.GetUserPermission(pathname).then((res) => {
            if (typeof res !== undefined) {

                if (res.data !== 'null' && typeof res.data !== undefined && res.data !== '') {
                    if (typeof res.data.DataList !== undefined) {
                        setuserPermission(res.data.DataList)
                    }
                }
            }
        });
    }
    const getData = (method, pageSize, pageNumber, sortType, SortProperty, filterValue, search) => {
   
        Service.getDatawithMultiFilterSearch(method, pageSize, pageNumber, sortType, SortProperty, filterValue, search).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {

                        if (res.data !== 'null' && typeof res.data !== 'undefined') {
                            setdata(res.data.DataList);
                            setFilteredArray(res.data.DataList)
                            settotalCountlst(res.data.Totalcount)
                            LoadMore(res.data.DataList.length, res.data.Totalcount)
                            setshowspinner(false);
                        }
                    }
                }
            }

        });

    }
    const filterLst = () => {
        let list = [];
        for (var key in multiFilterValue) {
            if (multiFilterValue[key].length > 0) {
                let values = ""
                multiFilterValue[key].map((i) => {
                    values += i.Name + ";";
                })
                if (values !== null && values !== '') {
                    list.push({
                        filterKey: key,
                        FilterValue: values
                    })
                }
            }
        }

        for (var key in filterValueDict) {
            if (filterValueDict[key] !== null && filterValueDict[key] !== '') {
                list.push({
                    filterKey: key,
                    FilterValue: filterValueDict[key]
                })
            }
        }
        return list;
    }
    const handleLoadMore = () => {
        let pagenumber = paging.pageNumber + 1;
        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
        setpaging(paging);
        Service.getDatawithMultiFilterSearch("Permission/GetAllByPage", paging.pageSize, pagenumber, sortType, sortColName, filterLst(), searchValue).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && typeof res.data !== 'undefined') {
                    
                            const currentlength = data.length;
                            setdata(data => [...data, ...res.data.DataList])
                            setFilteredArray(filteredArray => [...filteredArray, ...res.data.DataList])
                            LoadMore(currentlength + res.data.DataList.length, res.data.Totalcount)
                        }
                    }
                }
            }

        });


       
    };

    const filterHandler = (flag) => {

        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
        let pagenumber = 1;
        paging.pageNumber = pagenumber;

        if (flag === 'Apply') {
            setshowfilterpopup(false)
            let list = [];

            for (var key in multiFilterValue) {
                if (multiFilterValue[key].length > 0) {
                    let values = ""
                    multiFilterValue[key].map((i) => {
                        values += i.Name + ";";
                    })
                    if (values !== null && values !== '') {
                        list.push({
                            filterKey: key,
                            FilterValue: values
                        })
                    }
                }
            }

            for (var key in filterValueDict) {
                if (filterValueDict[key] !== null && filterValueDict[key] !== '') {
                    list.push({
                        filterKey: key,
                        FilterValue: filterValueDict[key]
                    })
                }
            }
            if (list.length > 0) {
                setpaging(paging);
                getData("Permission/GetAllByPage", paging.pageSize, pagenumber, sortType, sortColName, list, searchValue);
            }
            
        }
        else {
            setshowfilterpopup(false)
            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: '',
            }));
            setFilterValueDict(() => ({
                ...filterValueDict, [filterCol]: '',
            }));

            let list = [];
            for (var key in multiFilterValue) {
                if (key !== filterCol && multiFilterValue[key].length > 0) {
                    let values = ""
                    multiFilterValue[key].map((i) => {
                        values += i.Name + ";";
                    })
                    if (values !== null && values !== '') {
                        list.push({
                            filterKey: key,
                            FilterValue: values
                        })
                    }
                }
            }

            for (var key in filterValueDict) {
                if (filterValueDict[key] !== null && filterValueDict[key] !== '' && key !== filterCol) {
                    list.push({
                        filterKey: key,
                        FilterValue: filterValueDict[key]
                    })
                }
            }
            setpaging(paging);
            getData("Permission/GetAllByPage", paging.pageSize, pagenumber, sortType, sortColName, list, searchValue);

           
            setFilterCol('')
            setFilterValue('')
            setDropdownData([])
            setDropdownChange("")
            setFilterSearch("")
            setFilterDropdownData([])
        }
    }
    const UserfilterHandler = (e) => {
     
        setFilterSearch(e.target.value)
        if (e.target.value !== "") {
            let searchResults = dropdownData.filter((each) => {
                return each.Name.toLowerCase().includes(String(e.target.value).toLowerCase())
            });
            setDropdownData(searchResults);
        }
        else {
            
            setFilterSearch("")
            setDropdownData(filterdropdownData)
        }
    }
    const handleChange = (e, id) => {
        let isChecked = e.target.checked;   // true if it is checked false if unchecked
        
        let checkedBoxes = document.querySelectorAll('input[name=btSelectItem]:checked');
        if (isChecked === true) {
            settotalselectedshow(true);
            settotalselectedcount(checkedBoxes.length);
            setCheckedValueid(id)
        }
        else {
            setCheckedValueid([])
            if (checkedBoxes.length === 0) {
                settotalselectedshow(false);
            }
            settotalselectedcount(checkedBoxes.length);
        }

        if (checkedBoxes.length == 1) {
            setshowbutton(true);
        }
        else {

            setshowbutton(false);
        }
    }

    const [searchValue, setSearchValue] = useState(null)
    const OpenHandler = (id) => {
        if (id == null || id == 0) {
            alert('Please select the Permission to view')
        }
        else {
            navigate("/permissionset/edit/" + id);
        }
    }

    const OpenPermission = (event, id) => {
        if (event.detail === 2) {
         
            navigate("/permissionset/edit/" + id);
        }
    }


    const [isShow, invokeModal] = React.useState(false)
    const initModal = () => {
        return invokeModal(!false)
    }

    const searchHandler = (event) => {

        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
        let pagenumber = 1;
        paging.pageNumber = pagenumber;
        setpaging(paging);
        if (event.target.value != '') {
          
            setSearchValue(String(event.target.value).toLowerCase())

            getData("Permission/GetAllByPage", paging.pageSize, pagenumber, sortType, sortColName, filterLst(), event.target.value);
        }
        else {
            setSearchValue(null);
            getData("Permission/GetAllByPage", paging.pageSize, pagenumber, sortType, sortColName, filterLst(), null);
        }
    }


    const TogglePermissionActiveStatus = (id, flag) => {
        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
        setToggleId(id)
        if (flag == "Open-A") {
            setToggleStatusModal(true)
            setToggleChange("DeActivate")
        }
        if (flag == "Open-D") {
            setToggleStatusModal(true)
            setToggleChange("Activate")
        }
        if (flag == "Ok") {
            let user_id = SessionManage.getuserid();
            Service.TogglePermissionActiveStatus('Permission/TogglePermissionActiveStatus', id, user_id).then((res) => {
                if (typeof res !== 'undefined') {
                    if (typeof res.data !== 'undefined' && res.data !== 'null') {
                        if (res.status === 200) {
                            getData("Permission/GetAllByPage", paging.pageSize, paging.pageNumber, sortType, sortColName, filterLst(), searchValue)

                        }
                        setToggleStatusModal(false)
                    }
                }
            });
        }
        if (flag == "Cancel") {
            setToggleStatusModal(false)
        }
    }
    const CopyHandler = (id) => {
        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
        let rowData = {}
        let pagesize = 20 * paging.pageNumber
        let pagenumber = 1
        Service.PostWithIdAndUser('Permission/CopyPermissionSet', id, loggedInUser).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                       
                        if (res.data !== 'null' && res.data !== undefined) {

                            getData("Permission/GetAllByPage", paging.pageSize, paging.pageNumber, sortType, sortColName, filterLst(), searchValue)

                        }
                    }
                }
            }

        });
    }


    const SortColumn = (colName) => {

        var sortType = '';
        if (columnAscending === true) {
            setColumnAscending(false);
            sortType = "Descending";
        } else {
            setColumnAscending(true);
            sortType = "Ascending";
        }
        setSortColName(colName);
   
        let pagenumber = 1;
       
        paging.pageNumber = pagenumber;
        setpaging(paging);


        getData("Permission/GetAllByPage", paging.pageSize, pagenumber, sortType, colName, filterLst(), searchValue);

    }

    let headers = [
        { label: "Name", key: "Name" },
        { label: "Label  ", key: "Label" },
        { label: "Last Modified By", key: "LastModifiedUser_Name" },
        { label: "Last Modified Date", key: "LastModifiedDate" },
        { label: "Is Active", key: "IsActive" },

    ];

    const ExporttoCSV = () => {
        let checkedBoxes = document.querySelectorAll('input[name=btSelectItem]:checked');
        let values = "";
        if (checkedBoxes.length >= 1) {
            for (let checkbox of checkedBoxes) {
                values = values + ';' + checkbox.id;

            }
        }
        let objFilter = [];
        let objFilterValue = [{
            filterKey: "id",
            FilterValue: values,
        }]

        objFilter = objFilterValue;

        Service.getDatawithMultiFilterSearch("Permission/GetAllByPage", 20000, 1, "Ascending", "Id", objFilter, null).then((res) => {


            if (typeof res !== 'undefined') {
                if (res.data !== 'null' && res.data !== 'undefined') {
                    setCsvList(res.data.DataList);
                    setTimeout(() => {
                        csvInstance.current.link.click();
                    });

                }
            }
        });
    }

    const activeOption = [
        { Name: "Active", Id: "1" },
        { Name: "In-Active", Id: "0" },

    ]
    const FilterColumn = (name) => {

        const scrollTop = window.scrollY;
        const scrollTopActual = scrollTop >= 60 ? scrollTop - 85 : 0;
        var divtop1 = document.getElementById("tableA");
        setdivtop(divtop1.offsetTop + scrollTopActual + 30)
        setshowfilterpopup(true)
        setFilterCol(name)

        if (name === 'LastModifiedUser_Name') {
            setfiltertext(t('LastModifiedBy'))
            var testDiv = document.getElementById("LastModifiedUser_Name");
            setdivleft(testDiv.offsetLeft + 20 + "px");
            DropdownHandler("permissions")
        }

        if (name === 'Name') {
            setfiltertext(t('Policies.Name'))
            var testDiv = document.getElementById("Name");
            setdivleft(testDiv.offsetLeft + 20 + "px");
        }
        if (name === 'Label') {
            setfiltertext(t('Policies.Label'))
            var testDiv = document.getElementById("Label");
            setdivleft(testDiv.offsetLeft + 20 + "px");
        }
        if (name === 'IsActive') {
            setfiltertext(t('IsActive'))
            var testDiv = document.getElementById("IsActive");
            setdivleft(testDiv.offsetLeft + 20 + "px");
            addnewfield(activeOption, name)
        }

        if (name === 'LastModifiedDate') {
            setfiltertext(t('LastModifiedDate'))
            var testDiv = document.getElementById("LastModifiedDate");
            setdivleft(testDiv.offsetLeft + 20 + "px");
        }
    }
    const DropdownHandler = (key) => {
        Service.getfilterDropdown('Permission/GetFilterValue', key).then((res) => {
            if (typeof res !== 'undefined') {
                if (key == 'permissions') {
                    addnewfield(res.data, "LastModifiedUser_Name")
                }
                else {
                    addnewfield(res.data, key)
                }
            }
        });
    }
    const filterInput = (e) => {
     
        let inputValue = e.target.value
        setFilterValue(inputValue)
      
        setFilterValueDict(() => ({
            ...filterValueDict, [filterCol]: e.target.value,
        }));
    }
    return (<>


        <div class="container-fluid content-top-gap">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb my-breadcrumb">
                    <li class="breadcrumb-item"><Link to="/">{t('Home.title')}</Link></li>
                    <li className="breadcrumb-item"><Link to="/admin">{t('Menu.Admin')}</Link></li>
                    <li class="breadcrumb-item active" aria-current="page">{t('UserList.PermissionSetList')}</li>
              
                </ol>
            </nav>
            <div style={{ display: "none" }}>
                <CSVLink data={csvList} headers={headers} ref={csvInstance}   > </CSVLink>
            </div>
            <div class="">
                <div class="row">
                    <div class="col-lg-12 pr-lg-2">
                        <div class="card text-center card_border overflow-auto">

                            <div class="card-body" style={{ minHeight: "70vh" }}>
                                <div class="row" style={{ position: "sticky" }}>

                                    <div class="col-md-8">
                                        <input type="text" autoComplete='no' class="form-control form-control-user" id="firstName" name="firstName" placeholder={`${t('SearchFor')}${t('Menu.Permission Set')}${t('Policies.Name')}${t('UserList.&')}${t('Menu.Permission Set')}${t('Policies.Label')}`} onChange={(event) => searchHandler(event)} />

                                    </div>
                                    <div class="col-md-4">

                                        {Service.editPerms(userPerms) == false ? "" : <Link class="btn btn-sm btn-update ml-2" id="create" style={{ width: "90px", backgroundColor: "buttonface", float: "right" }} to="/permissionset/add">{t('CreateNew')}</Link>}
                                        {showbutton === true ?
                                            <>
                                                <button className="btn btn-update btn-sm ml-2" id="open" type="submit" style={{ float: "right", width: "40px" }} onClick={() => OpenHandler(checkedValueid)}>{t('Open')}</button>
                                                {Service.editPerms(userPerms) == false ? "" : <button className="btn btn-sm btn-update ml-2" id="Copy" style={{ float: "right", width: "40px" }} type="submit" onClick={() => CopyHandler(checkedValueid)}>{t('Copy')}</button>}
                                            </>
                                            :
                                            ''
                                        }
                                        {totalselectedcount <= 0 ? "" : Service.exportPerms(userPerms) == false ? "" : <button className="btn btn-update  btn-sm ml-2" type="submit" style={{ float: "right", width: "50px" }} onClick={(event) => ExporttoCSV()}>{t('Export')}</button>}
                                    </div>

                                </div>
                                <div className="scrollbar-class">
                                    <table class="table table-bordered text-left mt-2 table-responsive-sm" id="tableA">
                                        <thead className="text-white is-sticky-thead-product" style={{ backgroundColor: "var(--primary)" }}>
                                            <tr style={{ position: "sticky", top: "0", backgroundColor: "var(--primary)" }}>
                                                <th class="tableheader" style={{ width: '30px' }}>
                                                    <input type="checkbox" id="option-all" onChange={checkAll} />
                                                </th>
                                               
                                                <th id='Name' style={{ backgroundColor: (filterValueDict.Name ? '#6cae55' : ''), width: '250px' }} >
                                                    <div className='d-flex text-nowrap'>
                                                        <span>{t('Policies.Name')}</span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('Name')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('Name')}></span>
                                                    </div>
                                                </th>
                                                <th id='Label' style={{ backgroundColor: (filterValueDict.Label ? '#6cae55' : ''), width: '500px' }} >
                                                    <div className='d-flex text-nowrap'>
                                                        <span>{t('Policies.Label')}</span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('Label')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('Label')}></span>
                                                    </div>
                                                </th>
                                                <th id='LastModifiedUser_Name' style={{ backgroundColor: (multiFilterValue.LastModifiedUser_Name ? '#6cae55' : ''), width: '250px' }} >
                                                    <div className='d-flex text-nowrap'>
                                                        <span>{t('LastModifiedBy')}</span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('LastModifiedUser_Name')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('LastModifiedUser_Name')}></span>

                                                    </div>
                                                </th>
                                                <th id='LastModifiedDate' style={{ backgroundColor: (filterValueDict.LastModifiedDate ? '#6cae55' : ''), width: '250px' }} >
                                                    <div className='d-flex text-nowrap'>
                                                        <span>{t('LastModifiedDate')}</span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('LastModifiedDate')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('LastModifiedDate')}></span>

                                                    </div>
                                                </th>
                                                <th id='IsActive' style={{ backgroundColor: (multiFilterValue.IsActive ? '#6cae55' : ''), maxWidth: '200px' }}  >
                                                    <div className='d-flex text-nowrap'>
                                                        <span>{t('IsActive')}</span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('IsActive')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('IsActive')}></span>
                                                    </div>
                                                </th>

                                            </tr>
                                        </thead>
                                        <tbody>

                                            {
                                                showspinner === true ?

                                                    <>
                                                        <Spinner animation="grow" size="sm" variant="success" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                        <Spinner animation="grow" size="sm" variant="danger" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                        <Spinner animation="grow" size="sm" variant="warning" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                    </>
                                                    :
                                                    filteredArray.length === 0 ?
                                                        <tr><td></td>
                                                            <td></td>

                                                            <td>
                                                                {t('NoDataAvailable')}
                                                            </td>
                                                            <td></td>
                                                        </tr>
                                                        :
                                                        filteredArray.map((item, index) => (
                                                            <tr key={item.Id} onDoubleClick={(event) => OpenPermission(event, item.Id)}>
                                                             
                                                                <td ><input data-index="1" name="btSelectItem" type="checkbox" id={item.Id} onChange={(event) => handleChange(event, item.Id)} /></td>
                                                               
                                                                <td><div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px", maxWidth: "100px" }} data-toggle="tooltip" data-placement="right" title={item.Name}>{item.Name}</div></td>
                                                                <td><div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px", maxWidth: "250px" }} data-toggle="tooltip" data-placement="right" title={item.Label}>{item.Label}</div></td>
                                                                <td>{item.LastModifiedUser_Name}</td>
                                                                <td>{item.LastModifiedDate === null ? "" : moment(item.LastModifiedDate).format(SessionManage.getcurrentdateformat())}</td>
                                                                <td >
                                                                    {Service.editPerms(userPerms) == false ? (item.IsActive === true ? "Active" : "In-Active") : item.IsActive === true ?
                                                                        <a href="#" style={{ cursor: "pointer" }} title="Active/In-Active" onClick={(event) => TogglePermissionActiveStatus(item.Id, "Open-A")}>Active</a>
                                                                        :
                                                                        <a href="#" style={{ cursor: "pointer" }} title="Active/In-Active" onClick={(event) => TogglePermissionActiveStatus(item.Id, "Open-D")}>In-Active</a>
                                                                    }
                                                                </td>
                                                            </tr>

                                                        ))


                                            }


                                        </tbody>
                                    </table>
                                </div>

                                <div className='row'>

                                    {
                                        totalselectedshow === true ?

                                            <div className='col-lg-2 forgettext col-sm-12 border  form-control' style={{ textAlign: "center", marginLeft: "0.938rem" }}>
                                                {t('TotalRecordsSelected')} - <span id="totalselected">{totalselectedcount}</span>
                                            </div>

                                            :
                                            <div className='col-lg-2  col-sm-12' style={{ textAlign: "center" }}>

                                            </div>
                                    }



                                    <div className='col-lg-7 col-sm-12'></div>


                                    <div className='col-lg-2 forgettext  col-sm-12 border form-control' style={{ textAlign: "center" }}>
                                        {t('TotalRecords')} - {totalCountlst}
                                    </div>
                                    {showfilterpopup === true ?
                                        <div className='pop-up' style={{ left: divleft, top: divtop }}>
                                           
                                            {filterCol === 'Name' || filterCol === 'Label' ?

                                                <>
                                                    <div className="container">
                                                        <div className="row">
                                                            <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>
                                                        </div>
                                                    </div>
                                                    <div className="container mt-2">
                                                        <div className="row">
                                                            <input type='textbox' placeholder={t('Search')} className='filtertextboxbackground col-md-11 textbox-padding ml-2' style={{ outline: 'none' }} value={filterValueDict[filterCol]} onChange={(event) => filterInput(event)}></input>
                                                        </div>
                                                    </div>
                                                </>

                                                :
                                                <>
                                                </>
                                            }

                                            {filterCol === 'LastModifiedDate' ?

                                                <>
                                                    <div className="container">
                                                        <div className="row">
                                                            <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>
                                                        </div>
                                                    </div>
                                                    <div className="container mt-2">
                                                        <div className="row">
                                                            <input type='date' className='filtertextboxbackground col-md-11 textbox-padding ml-2' value={filterValueDict[filterCol]} onChange={(event) => filterInput(event)}></input>
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                <></>
                                            }

                                            {filterCol === 'createdby' || filterCol === 'LastModifiedUser_Name' || filterCol === 'IsActive' ?
                                               
                                                <>
                                                    <div className="container">
                                                        <div className="row">
                                                            <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>

                                                        </div>

                                                    </div>

                                                    <div className="container mt-2">
                                                        <div className="row">
                                                            <input type="text" placeholder={t('Search')} value={filterSearch} onChange={(event) => UserfilterHandler(event)} className='filtertextboxbackground col-md-11 textbox-padding ml-2' />
                                                        </div>

                                                        <div className="row contentoverflow mt-2">
                                                            <table className="filtertable">
                                                                <tbody>
                                                                    <tr>
                                                                        <td><input type="checkbox" name="filter-option-all" id="option-all" onChange={checkAllFilter} /></td>
                                                                        <td>{t('SelectAll')}</td>
                                                                    </tr>
                                                                    {
                                                                        dropdownData.map((item, index) => (

                                                                            <tr key={index}>

                                                                                <td><input id={item.Id} data-index="1" name="filter-option-all" checked={item.ischecked} type="checkbox" onChange={(event) => OndropdownChange(event, item.Id, item.Name)} /></td>

                                                                                <td>{item.Name}</td>

                                                                            </tr>


                                                                        ))
                                                                    }

                                                                </tbody>
                                                            </table>

                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                <></>
                                            }
                                           
                                            <div style={{ paddingTop: '10px', paddingLeft: '47px', paddingBottom: '7px' }}>
                                                <button className="btn btn-sm btn-update" onClick={() => filterHandler('Apply')} style={{ width: "45px" }}>{t('Apply')}</button>
                                                <button className="btn btn-sm btn-update" style={{ marginLeft: '10px', width: "45px" }} onClick={() => filterHandler('Reset')}>{t('Reset')}</button>
                                            </div>
                                            <div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                    }

                                </div>
                                {
                                    showLoadMore === true ?
                                        <div className='text-center container row'>
                                            <div className='col-md-5'></div>
                                            <div className='col-md-2'>
                                                <button className='btn btn-sm btn-update ml-1' onClick={handleLoadMore} style={{ width: "70px" }}>{t('LoadMore')}</button>
                                            </div>
                                            <div className='col-md-5'></div>

                                        </div>
                                        :
                                        ""
                                }

                                <Modal show={ToggleStatusModal}
                                    size="sm"
                                    aria-labelledby="contained-modal-title-vcenter"
                                    centered>
                                    <Modal.Body style={{ "textAlign": 'center', fontFamily: 'Segoe UI' }}>{`${t('Policies.AreYouSureYouWantTo')} ${toggleChange}` + "?"}</Modal.Body>
                                    <Modal.Footer>
                                        <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "35px" }} size='sm' onClick={() => TogglePermissionActiveStatus(toggleId, "Ok")}>
                                            {t('Yes')}
                                        </Button>
                                        <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "50px" }} size='sm' onClick={() => TogglePermissionActiveStatus(toggleId, "Cancel")}>
                                            {t('Cancel')}
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                            </div>

                        </div>
                    </div>

                </div>
            </div>



        </div>



    </>)
}
export default PermissionSetListComponent