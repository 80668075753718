import Service from '../../Services/Service';
import React, { useState, useEffect } from 'react'
import { Link, useLocation } from "react-router-dom"
import Spinner from 'react-bootstrap/Spinner';
import SessionManage from '../../SessionManagement/SessionManage';

function LoginComponent(props) {
    const location = useLocation();
    const [showspinner, setshowspinner] = useState(false);
    const [errorMessage, seterrorMessage] = useState('');
    const [inputField, setInputField] = useState({
        Email: '',
        Password: ''
    })

    useEffect(() => {
        getconfigurationdata("Configurationprop")
    }, [])

    const getconfigurationdata = (method) => {

        Service.getAlldata(method).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                           
                            var currentdata = res.data;
                            currentdata.map(i => {
                                if (i.Name === "roundingprecision") {
                                    SessionManage.setroundingPrecision(i.value)
                                }
                                else if (i.Name === "AccrualCalc") {
                                    SessionManage.setAccrualCalc(i.value)
                                }
                                else if (i.Name === "ComparisonPeriod") {
                                    SessionManage.setComparisonPeriod(i.value)
                                }
                                else if (i.Name === "DefaultCurrency") {
                                    SessionManage.setdefaultCurrency(JSON.stringify(i.objselectedDropdown))
                                }
                                else if (i.Name === "FiscalStartMonth") {
                                    SessionManage.setfiscalstartmonth(i.value)
                                }
                                else if (i.Name === "FiscalEndMonth") {
                                    SessionManage.setfiscalendmonth(i.value)
                                }
                                else if (i.Name === "Session-Idle-Timeout") {
                                    SessionManage.setidletimeout(i.value)
                                }
                                else if (i.Name === "IncludeSubDivideTarget") {
                                    SessionManage.setIncludeSubDivideTarget(i.IncludeSubDivideTarget)
                                }
                                else if (i.Name === "IncludeOutStandingAmount") {
                                    SessionManage.setIncludeOutstandingAmount(i.IncludeOutStandingAmount)
                                }
                                else if(i.Name === "AccrualThresholdCriteria"){
                                    SessionManage.setAccrualThresholdCriteria(i.AccrualThresholdCriteria)
                                }
                                else if(i.Name === "AccrualThresholdCriteria"){
                                    SessionManage.setAccrualThresholdCriteria(i.AccrualThresholdCriteria)
                                }
                            });


                        }
                    }
                }
            }

        });
    }


    const inputsHandler = (e) => {
  
        const { name, value } = e.target;
        setInputField((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    }

    const submitButton = (e) => {
        setshowspinner(true);
        e.preventDefault();
        let Users = inputField;


        try {

            const res1 = Service.createorupdate("Login/Authentication", Users).
                then(res => {
                    if (res.data.Status == "Successfully") {
                 
                        SessionManage.setuserid(res.data.Id);
                        SessionManage.setusername(res.data.FullName);
                        SessionManage.settokenid(res.data.Token);
                        SessionManage.setroleid(res.data.RoleId);
                        SessionManage.setgroupid(res.data.GroupId);
                        

                        sessionStorage.setItem('navData', JSON.stringify(res.data.navItem));
                        SessionManage.setdateformat(res.data.dateformat);

                        SessionManage.setFiscalEndDate(res.data.FiscalEndDate);

                        SessionManage.setLocale(res.data.Locale);
                        SessionManage.setLanguages(res.data.Languages);
                        SessionManage.setUsersType(res.data.UsersType);
                       

                        setshowspinner(false);

                        window.location.href = location.pathname;
                    }
                    else if (res.data.Status == "Password not Matched") {
                        seterrorMessage("Invalid username or password.")
                      
                        setshowspinner(false);
                    }
                    else if (res.data.Status == "Yout Account Is In-Active") {

                        seterrorMessage("Invalid username or password.");
                        setshowspinner(false);
                    }
                    else if (res.data.Status == "Email Id not Exists") {
                        seterrorMessage("Invalid username or password.");
                        setshowspinner(false);
                    }
                    else {
                        seterrorMessage("Server Error");
                        setshowspinner(false);
                    }
                    return res;
                }).catch(error => {
                    seterrorMessage("Server not Working");
                    setshowspinner(false);
                    console.log(error);
                    return null;
                });
            return res1;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
        }

    }
    return (<>
        <div className="container" style={{ marginTop: "18vh" }}>
            <div className="row mb-4">

            </div>
            <div className="row">
                <div className="col-md-4 offset-md-4">
                    <div className="card">
                        <div className="card-body" style={{ borderTop: "3px solid #007bff" }}>
                            <div className="login-img text-center">
                                <img src="/NewAssets/images/logo.png" alt="logo-icon" />
                            </div>
                            <div className="login-title">
                           
                            </div>
                            {
                                errorMessage !== '' ?
                                    <div className="alert alert-danger" role="alert">
                                        {errorMessage}
                                    </div> : null
                            }

                            <div className="login-form mt-4">
                                <form>
                                    <div className="form-row">
                                        <div className="form-group col-md-12">
                                            <input type="email" className="form-control" placeholder="Email" onChange={inputsHandler} value={inputField.Email} name="Email"></input>
                                        </div>
                                        <div className="form-group col-md-12">
                                            <input type="password" className="form-control" placeholder="Password" onChange={inputsHandler} value={inputField.Password} name="Password" />
                                        </div>
                                    </div>


                                    <div className="row">
                                        <div className="col-sm-3">
                                            <button type="submit" className="btn btn-sm btn-primary" onClick={submitButton}>
                                                {
                                                    showspinner === true ? <>
                                                        <Spinner animation="grow" size="sm" variant="success" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                    
                                                    </>
                                                        : null
                                                }

                                                &nbsp; Log In
                                            </button>
                                        </div>
                                        <div className="col-sm-9">
                                            <div className="text-right">
                                                <Link className='forgettext' to="#">Forgot Password? </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-sm-3">

                                        </div>
                                        <div className="col-sm-6">
                                            <p className='forgettext'>Product Version : 2.04</p>
                                        </div>
                                        <div className="col-sm-3">

                                        </div>

                                    </div>
                                    <br />
                                    <br />
                                </form>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}

export default LoginComponent