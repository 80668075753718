import React, { Component } from 'react' 
import { useNavigate ,useParams } from 'react-router-dom';
// import '../index.css';
import SDClaimListView from '../../../Components/SDClaimComponent/SDClaimListView/SDClaimListViewComponent';



function ShipAndDebitListing(props){
    let navigate = useNavigate();
    const params = useParams();

    return (  
        <div> 
            <SDClaimListView {...props}  navigate={navigate} id={params.val} />
        </div>
    );  
}
export default ShipAndDebitListing