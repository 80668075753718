import React, { useState, useEffect } from 'react';
import classes from './ProductTreeDialog.module.css';
import CustomListbox from './CustomListbox';
import CustomDialog from './CustomDialog';
import ProductSelector from './ProductSelector'
import ProductSelectorAttribute from './ProductSelectorAttribute';
import Service from '../../../Services/Service';

const ProductTreeDialog = (props) => {
    const [SelectedProducts, SetSelectedProducts] = useState([]);
    const [ExcludedSelectedProducts, SetExcludedSelectedProducts] = useState([]);
    const [userPerms, setuserPermission] = useState([]); 

    console.log(props.selectedType)
    console.log("26_07 Product",props.SelectedData)
    {
        useEffect(() => {
        console.log("data selector",props.dataByHeirarchy)
        document.getElementById("currentlength").innerHTML = props.SelectedData.length;
        getUserPermission('/rebate-program/0');
          SetSelectedProducts(props.SelectedData)
        }, [0])
      }
    const OkClickHandler = (event) => {
       
        props.onHide();
        if (props.selectedType === 'Heirarchy') {
            props.onOkClick(SelectedProducts);
        }
        else {
            props.onOkClick(SelectedProducts);
        }
        SetSelectedProducts([]);
        SetExcludedSelectedProducts([]);
    }
	const getUserPermission = (pathname) => {
        Service.GetUserPermission(pathname).then((res) => {
          if (res !== undefined) {
            if (res.data !== 'null' && res.data !== undefined && res.data !== '') {
                if (res.data.DataList !== undefined) {
                setuserPermission(res.data.DataList)
                }
            }
          }
        });
      }
    const getSelectedList = (products) =>{
        const withoutDuplicates = [...new Set(products)];
        console.log("24 product selector", products);
        console.log("36 product selector", withoutDuplicates);
        document.getElementById("currentlength").innerHTML = withoutDuplicates.length;
        SetSelectedProducts(withoutDuplicates);
    }

    const AddProductsHandler = (products) => {
        console.log("26_07 seledted prodcts",products);
        if (props.selectedType === 'Heirarchy') {

            let itemExist = SelectedProducts.some(x => x.key === products.key);
            if (products.checked && !itemExist) {
                SetSelectedProducts([...SelectedProducts, products]);
            }
            if (!products.checked) {
                console.log("26_07 inside 36 in producttreedialog",products)
                SetSelectedProducts(SelectedProducts.filter(x => x.key !== products.key));
            }
            console.log("26_07 seledted prodcts",SelectedProducts);
        }
        else {
            let itemExist = ExcludedSelectedProducts.some(x => x.key === products.key);
            if (products.checked && !itemExist) {
                SetExcludedSelectedProducts([...ExcludedSelectedProducts, products]);
            }
            if (!products.checked) {
                SetExcludedSelectedProducts(ExcludedSelectedProducts.filter(x => x.key !== products.key));
            }
        }
    }
    return (
        <>        
            <div className="text-left">
                <CustomDialog onHide={OkClickHandler}  size="lg" title={props.title} show={props.show} userPerms={userPerms}  >
                    {props.children}
                    {/* <CustomListbox dataByHeirarchy={props.dataByHeirarchy}
                        dialogType={props.dialogType}
                        SelectedData={props.SelectedData}
                        
                        dataByAtrribute={props.dataByAtrribute}
                        selectedType={props.selectedType}
                        onCheckboxChecked={AddProductsHandler}
                        programLineStatus={props.programLineStatus}
                    ></CustomListbox> */}
                    { props.selectedType === "Heirarchy" ? 
                        <ProductSelector 
                        SelectedData={props.SelectedData}
                        dataByHeirarchy={props.dataByHeirarchy}
                        dataByAtrribute={props.dataByAtrribute}
                        rebatelinestatus = {props.rebatelinestatus}
                        onCheckboxChecked = {getSelectedList}
                        selectedType={props.selectedType}
                        userPerms={userPerms} 
                        />
                    :
                        <>
                        <ProductSelectorAttribute
                            onCheckboxChecked = {getSelectedList}
                            SelectedData={props.SelectedData}
                            rebatelinestatus = {props.rebatelinestatus}
                            userPerms={userPerms} 
                            dualListBoxOptionsByAttribute={props.dualListBoxOptionsByAttribute}
                         />
                        </>
                    }
                     
                </CustomDialog>
            </div>
        </>
    )
}
export default ProductTreeDialog;