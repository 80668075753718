import React, { useState, useEffect } from 'react';
import classes from './DataExport.module.css';
import CustomErrorPopup from '../../../UI/CustomErrorPopup';
import CustomMessagePopup from '../../../UI/CustomMessagePopup';
import moment from 'moment/moment';
import $ from 'jquery';
import axios from 'axios';
import { Form, Row, Col } from 'react-bootstrap';
import Service from '../../../Services/Service';
import ProgressBar from 'react-bootstrap/ProgressBar';
import SessionManage from "../../../SessionManagement/SessionManage";
import { CSVLink } from "react-csv";
import Select from "react-select";
import { Link, useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const bussiness = [{ "Name": "", "Label": "" }];
const productlinetemplate = [{ "Name": "", "Label": "", "BussinessUnit": "" }];
const productfamilytemplate = [{ "Name": "", "Label": "", "ProductLine": "" }];
const productsubfamilytemplate = [{ "Name": "", "Label": "", "ProductFamily": "" }];
const uomtemplate = [{ "Name": "", "Label": "", "IsoCode": "" }];
const producttemplate = [{
	"Name": "", "Label": "", "UOM": "", "ProductSubFamily": "", "ProductAttribute1": "",
	"ProductAttribute2": "", "ProductAttribute3": "", "ProductAttribute4": "", "ProductAttribute5": ""
}];
const salessubregiontemplate = [{ "Name": "", "Label": "", "SalesRegion": "" }];
const salesorgtemplate = [{ "Name": "", "Label": "", "SalesSubRegion": "" }];
const salesofficetemplate = [{ "Name": "", "Label": "", "SalesOrg": "" }];
const salesgrouptemplate = [{ "Name": "", "Label": "", "SalesOffice": "" }];
const customerlevel2template = [{ "Name": "", "Label": "", "CustomerLevel1": "" }];
const customertemplate = [{ "Name": "", "Label": "", "Phone": "", "City": "", "State": "", "Country": "", "CustomerLevel2": "", "SalesRegion": "" }];
const currencytemplate = [{ "IsoCode": "", "Description": "", "Phone": "", "Prefix": "", "Suffix": "", "MathPrecision": "", "DisplayPrecision": "", "DisplayPerunitPrecision": "", "ExportPrecision": "" }];
const transactiontemplate = [{
	"Id": "", "CreatedDate": "", "LastModifiedDate": "", "Deleted": "", "Product": "", "Customer": "", "Plant": "", "Exclude": "", "SalesRep": "",
	"Quantity": "", "Netprice": "", "PostedAccruals": "", "UnpostedAccruals": "", "TransactionDate": "", "QuoteId": "", "Uom": "", "Currency": "", "InvoiceNumber": "",
	"Discount": "", "LineItem": "", "CreatedUserId": "", "DefaultGroupId": "", "LastModifiedUserId": "", "Saleshierarchy": "", "TotalValue": ""
}];
const ExchnageRatetemplate = [{ "EffectiveDate": "", "FromCurrency": "", "ToCurrency": "", "Rate": "" }];
const PaymentsTerms = [{ "Name": "", "label": "", "NoOfDays": 0, "Isblocked": false, "BlockedReason1": "", "BlockedReason2": "", "BlockedReason3": "", "BlockedReason4": "", "Locked": false }];
const Invoicepaymentdetail = [{
	"Id": "", "Customer": "", "InvoiceNumber": "", "Paymentdate": "", "Amountreceived": "", "CreatedDate": "", "LastModifiedDate": "", "Deleted": "",
	"CreatedUserId": "", "DefaultGroupId": "", "LastModifiedUserId": "", "InvoiceDate": "", "DateDifference": ""
}];
const Leadger = [{ "Id": "", "CreatedDate": "", "Customer": "", "DocDate": "", "PostingDate": "", "PaymentReceived": "", "Particulars": "" }];
const Customerinvoiceoutstandingdetails = [{
	"Id": "", "Customer": "", "InvoiceNumber": "", "RebatePeriod": "", "DueDate": "", "OutstandingAmount": "", "CreatedDate": "", "LastModifiedDate": "",
	"Deleted": "", "CreatedUserId": "", "DefaultGroupId": "", "LastModifiedUserId": ""
}];
const DistributorTemplate = [{ "Name": "", "Label": "", "City": "", "Phone": "", "State": "", "Country": "" }];
const DistributorTransactionTemplate = [{ "Product": "", "Distributor": "", "Salesrep": "", "Plant": "", "Exclude": "", "Quantity": "", "InvoicePrice": "", "TransactionDate": "", "QuoteId": "", "UOM": "", "Currency": "", "InvoiceNumber": "", "Discount": "", "PaymentReceivedDate": "", "Saleshierarchy": "", "TotalValue": "", "Revenue": "" }];
const CountryTemplate = [{ "Name": "", "Label": "" }];
const DistributorCountryTemplate = [{ "DistributorName": "", "DistributorLabel": "" , "CountryName" : "" , "CountryLabel" : "" }];
const InvoicePending = [{ "Id": "", "CreatedDate": "", "LastModifiedDate": "", "Deleted": "","DistributorCode": "", "DistributorName": "" , "DocNumber" : "" , "BillNumber" : "", "BillAmount" :0.0 ,"CashdiscountAmount":0.0 ,"PendingAmount":0.0,"BillDate":"","LrDate":""}];

const CashDiscountDaysDue = [{ "Name": "", "Label": "" }];

const DataExport = () => {

	const [selectedFile, setSelectedFile] = useState();
	const [t, i18n] = useTranslation('translation');
	const [template, settemplate] = useState([]);
	const [isFilePicked, setIsFilePicked] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [successMessage, setSuccessMessage] = useState("");
	const [showErrorModal, setShowErrorModal] = useState(false);
	const [showMessageModal, setShowMessageModal] = useState(false);
	const [selectedModule, setselectedModule] = React.useState("");
	const [array, setArray] = useState([]);
	const [data, setdata] = useState('');
	const [failedlist, setfailedlist] = useState([]);
	const [importhistory, setimporthistory] = useState([]);
	const [progressvalue, setprogressvalue] = useState('');
	const fileReader = new FileReader();
	const [showLoadMore, setshowLoadMore] = useState('');
	const [totalCountlst, settotalCountlst] = useState('');
	const [filteredArray, setFilteredArray] = useState(data);
	const [filterCol, setFilterCol] = useState('');
	const [filterValue, setFilterValue] = useState('');
	const [loading, setLoading] = useState(false);
	const [showfilterpopup, setshowfilterpopup] = useState(false);
	const [divleft, setdivleft] = useState('');
	const [divtop, setdivtop] = useState('');
	const [columnAscending, setColumnAscending] = useState(true);
	const [sortValue, setSortValue] = useState('');
	const [columnValue, setColumnValue] = useState('');
	const [selectedOptions, setSelectedOptions] = useState();
	const [editPerms, setEditPerms] = useState([]);
	const location = useLocation();
	const [filterValueDict, setFilterValueDict] = useState({
		Modulename: '',

	});

	const customStyles = {
		control: base => ({
			...base,
			minHeight: 28,
			maxHeight: 100,

		}),
		dropdownIndicator: base => ({
			...base,
			padding: 2
		}),
		clearIndicator: base => ({
			...base,
			padding: 2
		}),
		multiValue: base => ({
			...base,

		}),
		valueContainer: base => ({
			...base,
			padding: '0px 2px'
		}),
		input: base => ({
			...base,
			margin: 0,
			padding: 0,

		})
	};

	const [paging, setpaging] = useState({
		pageSize: 20,
		pageNumber: 1
	})
	const [modules, setmodules] = useState([]);
	let id = SessionManage.getuserid();
	useEffect(() => {
		getUserPermission(location.pathname);
		setSortValue("Descending");
		setColumnValue("Id");
		getModules("Transaction/GetAllModules");
		setLoading(false);
	}, [0])

	const getUserPermission = (pathname) => {
		Service.GetUserPermission(pathname).then((res) => {
		
			if (typeof res !== 'undefined') {
				if (typeof res.data !== 'undefined' && res.data !== 'null' && res.data !== '') {
					if (typeof res.data.DataList !== 'undefined') {
						var permission = res.data.DataList;
						if (typeof permission !== 'undefined') {
							if (permission.filter((item) => item.ActionName === 'Edit' || item.ActionName === 'Submit').length == 0) {
								setEditPerms(false);
							} else {
								setEditPerms(true);
							}
						}
						else {
							setEditPerms(false);
						}
					}
				}
			}
		});
	}

	const getModules = (method) => {

		Service.getdata(method).then((res) => {
			if (typeof res !== 'undefined' && typeof res.data !== 'undefined') {
				setmodules(res.data);

			}
		});
	}
	const LoadMore = (lst, totalcount) => {
		if (lst < totalcount) {
			setshowLoadMore(true);
		}
		else if (lst >= totalcount) {
			setshowLoadMore(false);
		} else {
			setshowLoadMore(false);
		}
	}

	const getData = (method, pageSize, pageNumber, sortType, SortProperty, filterValue) => {
		Service.getDataExport(method, pageSize, pageNumber, sortType, SortProperty, filterValue).then((res) => {
			if (typeof res !== 'undefined') {
				if (typeof res.data !== 'undefined' && res.data !== 'null') {
				
					setdata(res.data.DataList);
					setFilteredArray(res.data.DataList)
					settotalCountlst(res.data.Totalcount)
					LoadMore(res.data.DataList.length, res.data.Totalcount)

				}
			}
		});
	}

	const changeSelectOptionHandler = (data) => {

		paging.pageSize = 20;
		paging.pageNumber = 1
		setpaging(paging);
		setSortValue("Descending");
		setColumnValue("Id");

		setselectedModule(data.ModelName);
		setFilterValue(data.ModelName)
		setFilterCol("Modulename")
		setSelectedOptions(data)
		// alert(inputValue)
		setFilterValueDict(() => ({
			...filterValueDict, [filterCol]: data.ModelName,
		}));
		getData("ExportFile/GetAll?userId=" + Service.getUserId() + "&moduleName=" + data.ModelName, paging.pageSize, paging.pageNumber, "Descending", "Id", []);

		if (data.ModelName === "BusinessUnit") {
			settemplate(bussiness);
		}
		else if (data.ModelName === "ProductLine") {
			settemplate(productlinetemplate);
		}
		else if (data.ModelName === "ProductFamily") {
			settemplate(productfamilytemplate);
		}
		else if (data.ModelName === "ProductSubFamily") {
			settemplate(productsubfamilytemplate);
		}
		else if (data.ModelName === "CatI") {
			settemplate(bussiness);
		}
		else if (data.ModelName === "CatII") {
			settemplate(bussiness);
		}
		else if (data.ModelName === "CatIII") {
			settemplate(bussiness);
		}
		else if (data.ModelName === "CatIV") {
			settemplate(bussiness);
		}
		else if (data.ModelName === "ProductAttribute5") {
			settemplate(bussiness);
		}
		else if (data.ModelName === "UOM") {
			settemplate(uomtemplate);
		}
		else if (data.ModelName === "Product") {
			settemplate(producttemplate);
		}
		else if (data.ModelName == "SalesRegion") {
			settemplate(bussiness);
		}
		else if (data.ModelName == "SalesSubRegion") {
			settemplate(salessubregiontemplate);
		}
		else if (data.ModelName == "SalesOrg") {
			settemplate(salesorgtemplate);
		}
		else if (data.ModelName == "SalesOffice") {
			settemplate(salesofficetemplate);
		}
		else if (data.ModelName == "SalesGroup") {
			settemplate(salesgrouptemplate);
		}
		else if (data.ModelName == "CustomerLevel1") {
			settemplate(bussiness);
		}
		else if (data.ModelName == "CustomerLevel2") {
			settemplate(customerlevel2template);
		}
		else if (data.ModelName == "Customer") {
			settemplate(customertemplate);
		}
		else if (data.ModelName == "TransactionRecord") {
			settemplate(transactiontemplate);
		}
		else if (data.ModelName == "Currency") {
			settemplate(currencytemplate);
		}
		else if (data.ModelName == "ExchangeRate") {
			settemplate(ExchnageRatetemplate);
		}
		else if (data.ModelName == "ExchangeRate") {
			settemplate(ExchnageRatetemplate);
		}
		else if (data.ModelName == "PaymentsTerms") {
			settemplate(PaymentsTerms);
		}
		else if (data.ModelName == "Leadger") {
			settemplate(Leadger);
		}
		else if (data.ModelName == "Invoicepaymentdetail") {
			settemplate(Invoicepaymentdetail);
		}
		else if (data.ModelName == "Customerinvoiceoutstandingdetails") {
			settemplate(Customerinvoiceoutstandingdetails);
		}
		else if (data.ModelName == "Distributor") {
			settemplate(DistributorTemplate);
		}
		else if (data.ModelName == "Country") {
			settemplate(CountryTemplate);
		}
		else if (data.ModelName == "DistributorCountry") {
			settemplate(DistributorCountryTemplate);
		}
		else if (data.ModelName == "DistributorTransaction") {
			settemplate(DistributorTransactionTemplate);
		}
		else if (data.ModelName == "InvoicePending") {
			settemplate(InvoicePending);
		}
		else if (data.ModelName == "CashDiscountDaysDue") {
			settemplate(CashDiscountDaysDue);
		}
		else {
			settemplate('');
			toast.warning('No Export Available !!', {
				position: toast.POSITION.TOP_RIGHT,
			  });
			
		}

	};

	const csvReport1 = {
		data: template,

		filename: selectedModule + '_Template.csv'
	};

	const csvReport = {
		data: failedlist,

		filename: 'Failed_Record.csv'
	};
	const submitButton = (e) => {
	
		if (selectedModule != "") {
			e.preventDefault();
			setLoading(true);
			Service.createorupdate("ExportFile/Create?Createdby=" + Service.getUserId() + "&Modulename=" + selectedModule, data).then(res => {

				if (typeof res != 'undefined') {
					var path = res.data.Path
					setLoading(false);

					window.location =  path;
				}
				paging.pageSize = 20;
				paging.pageNumber = 1
				setpaging(paging);
				setSortValue("Descending");
				setColumnValue("Id");

				getData("ExportFile/GetAll?userId=" + Service.getUserId() + "&moduleName=" + selectedModule, paging.pageSize, paging.pageNumber, "Descending", "Id", []);
			});
		}
	}
	const SortColumn = (colName) => {
		paging.pageSize = 20;
		paging.pageNumber = 1
		setpaging(paging);

		setColumnAscending(!columnAscending);
		var sortType = columnAscending === true ? 'Ascending' : 'Descending';
		setSortValue(sortType);
		setColumnValue(colName);

		getData("ExportFile/GetAll?userId=" + Service.getUserId() + "&moduleName=" + selectedModule, 20, 1, sortType, colName, []);

	}
	const handleLoadMore = () => {
		let pagesize = 20;
		let pagenumber = paging.pageNumber + 1;
		
		Service.getDataExport("ExportFile/GetAll?userId=" + Service.getUserId() + "&moduleName=" + selectedModule, pagesize, pagenumber, sortValue, columnValue, []).then((res) => {

			if (typeof res != 'undefined') {
				if (typeof res.data !== 'undefined' && res.data !== 'null') {
					const currentlength = data.length;
					setdata(data => [...data, ...res.data.DataList])
				
					setFilteredArray(filteredArray => [...filteredArray, ...res.data.DataList])
					LoadMore(currentlength + res.data.DataList.length, res.data.Totalcount)
				}
			}
		});


		paging.pageSize = pagesize;
		paging.pageNumber = pagenumber
		setpaging(paging);
	};


	const headerKeys = Object.keys(Object.assign({}, ...array));
	return (
		<div class="container-fluid content-top-gap">
			<nav aria-label="breadcrumb">
				<ol class="breadcrumb my-breadcrumb">
					<li class="breadcrumb-item"><Link to="/">{t('Home.title')}</Link></li>
					<li class="breadcrumb-item active" aria-current="page">{t('DataImport.DataExport')}</li>
					
				</ol>
			</nav>

			<div class="">
				<div class="row">
					<div class="col-lg-12 pr-lg-2">
						<div class="card text-center card_border">

							<div class="card-body" style={{ minHeight: "70vh" }}>
								{loading ? <>
									<div className="loader-container">
										<div className="spinner"></div>
									</div>
								</> : ""}
								<div className='col-md-12  mb-4'>
									<div className='row'>
										<div className='col-md-3'>
											<p className={classes['Footer-Buttons']}>{t('DataImport.DataTableName')}</p>
										</div>
										<div className='col-md-3'>
											
											<Select
												className='col-md-12 textbox-padding-dropdown mt-2 fontsizedropdown'
												options={modules}
												placeholder="Choose"
												value={selectedOptions}
												onChange={changeSelectOptionHandler}
												isSearchable={true}
												styles={customStyles}
											/>


										</div>
										<div className='col-md-4'>	{editPerms == false ? "" : <button style={{ margin: "10px", float: "right", width: "50px" }} class="btn btn-sm btn-update" onClick={submitButton} type="submit">{t('Export')}</button>}</div>
										{
											template.length === 1000 ?
												<div className='col-md-5'><p className={classes['Footer-Buttons']}>{t('DataImport.Template')} : <CSVLink {...csvReport1}>{`${t('DataImport.Download')}${selectedModule} ${t('DataImport.Template')}`}</CSVLink></p> </div>
												:
												''
										}


									</div>
									<div className='row'>
									</div>


									<CustomErrorPopup ErrorMessage={errorMessage} show={showErrorModal}
										onHide={() => setShowErrorModal(false)}></CustomErrorPopup>
									<CustomMessagePopup SuccessMessage={successMessage} show={showMessageModal}
										onHide={() => setShowMessageModal(false)}></CustomMessagePopup>
								</div>
								<br></br>
								<br></br>

								{selectedModule === "" || selectedModule === "Choose..." ? "" :
									<div className="scrollbar-class">
										<h5>{t('DataImport.ExportHistory')}</h5>
										<br></br>

										<table className="table table-bordered text-left mt-2 table-responsive-sm" id="tableA">
											<thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
												<tr style={{ position: "sticky", top: "0", backgroundColor: "var(--primary)" }}>

													<th id="rolename" style={{ backgroundColor: (filterValueDict.rolename ? '#6cae55' : '') ,minWidth:"130px" }}>
														{t('DataImport.ModuleName')} <span className='fa fa-sort' onClick={() => SortColumn('Modulename')}></span>

													</th>
													<th id="rolelabel" style={{ backgroundColor: (filterValueDict.rolelabel ? '#6cae55' : '') ,minWidth:"130px" }}>
														{t('DataImport.Status')} <span className='fa fa-sort' onClick={() => SortColumn('Status')}></span>
													</th>
												
													<th style={{minWidth:"130px" }}>{t('DataImport.CreatedBy')} <span className='fa fa-sort' onClick={() => SortColumn('Startdate')}></span></th>
													<th style={{minWidth:"130px" }}>{t('DataImport.CreatedDate')} <span className='fa fa-sort' onClick={() => SortColumn('CreatedbyUser')}></span></th>
												





													<th></th>
												</tr>
											</thead>
											{
												filteredArray.length === 0 ?
													<>
														<tr><td colspan="5" >{t('NoDataAvailable')}</td>	</tr>
													</>
													:
													<tbody>
														{filteredArray.map((item, index) => (
															<tr key={index} >

																<td>{item.Modulename}</td>
																<td>{item.Status}</td>
																<td>{moment(item.Startdate).format(SessionManage.getcurrentdateformat() + " hh:mm:a")}</td>

																<td>{item.CreatedbyUser}</td>
																<td >
																	{item.Filepath == "" || item.Filepath == null ? "" : <a target={'_blank'} className={editPerms == false ? "disabledClass" : ""} href={item.Filepath} download > Download File </a>}

																</td>
															</tr>
														))}

													</tbody>
											}
										</table>
										{
											showLoadMore === true && filteredArray.length > 0 ?
												<div className='text-center container row'>
													<div className='col-md-5'></div>
													<div className='col-md-2'>
														<button className='btn btn-sm btn-update ml-1' onClick={handleLoadMore}style={{width: "70px"}}>{t('LoadMore')}</button>
													</div>
													<div className='col-md-5'></div>

												</div>
												:
												""
										}
									</div>
								}

							</div>

						</div>


					</div>

				</div>
			</div>


		</div>
	)
}
export default DataExport;