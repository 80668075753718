import Service from '../../../Services/Service';
import React, { useState, useEffect } from 'react'
import { Link, useLocation, useNavigate } from "react-router-dom"
import Spinner from 'react-bootstrap/Spinner';
import SessionManage from '../../../SessionManagement/SessionManage';
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { ToastContainer, toast } from 'react-toastify';
import { Form, Dropdown, Button, InputGroup, FormControl, Modal } from 'react-bootstrap';
import classes from '../../RebateProgramComponent/CreateRebateComponent/Form.module.css'
import 'react-toastify/dist/ReactToastify.css';
import CustomErrorPopup from '../../../UI/CustomErrorPopup';
import moment from 'moment';
import $, { each } from 'jquery';


function CreateNewCashDiscountPolicyView(props) {
    const [t, i18n] = useTranslation('translation');
    const navigate = useNavigate();
    let loggedInUser = sessionStorage.getItem("id")
    const [showSubmitModal, setShowSubmitModal] = useState(false);
    const [showWarningModal, setShowWarningModal] = useState(false);
    const [commentsForSubmit, setCommentsForSubmit] = useState("");
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [errorStatus, setErrorStatus] = useState("Error");
    const [disableHeaderfield, setDisabledHeaderField] = useState(false);
    const [daysdueData, setDaysDueData] = useState([]);
    const [showEndDateModal, setShowEndDateModal] = useState(false);
    const [endate, setEndDate] = useState(new Date());

    const [data, setData] = useState(
        {
            Id: 0,
            CreatedUserId: parseInt(loggedInUser),
            LastModifiedUserId: parseInt(loggedInUser),
            ValidityFrom: new Date(),
            ValidityTo: (new Date(SessionManage.getFiscalEndDate())),
            DaysLessthan: '',
            CashPercent: '',
            Cashdiscount: "",
            CashdiscountId: "",
            CreatedUser: "",
            Status: "New",
            CreatedDate: moment(new Date()).format(SessionManage.getcurrentdateformat()),
            maxValue: 0,
            minValue: 0,
        }
    )
    useEffect(() => {
        if (props.id != 0) {
            getdata("CashDiscount/GetById", props.id);
        }
        // else {
        //     setLockedBy(loggedInUser);
        //     setIsLocked(true);
        // }
        getDaysdueData("CashDiscount")
    }, []);
    const OnChangeHandlerDate = (e, name) => {
        // setChangeHandle(true)
        if (e) {
            if (name === 'ValidityFrom')
                setData({ ...data, ValidityFrom: moment(e).format("YYYY-MM-DD") })
            if (name === 'ValidityTo')
                setData({ ...data, ValidityTo: moment(e).format("YYYY-MM-DD") })
        }
    };

    const addDays = (date, days) => {
        const newDate = new Date(date);
        newDate.setDate(date.getDate() + days);
        return newDate;
    };

    const OnChangeHandlerEndDate = (e) => {
       
        let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        let today = new Date().toLocaleString('en-US', { timeZone: timeZone });
        let enddate = new Date(e).toLocaleString('en-US', { timeZone: timeZone });
       
        if (moment(enddate).isSameOrBefore(today)) {
            setErrorMessage("End Date should be greater than today")
            setShowErrorModal(true)
        }
        else {
            setEndDate(e)
        }
    };

    const handlePolicyChange = (e, name) => {
        if (name == 'DaysLessthan') {
            let minvalue = 0
            let maxvalue = 0
            daysdueData.map((each) => {
         
                if (each.value == e.value) {
                    if (each.Name == '10') {
                        minvalue = 0;
                        maxvalue = parseInt(each.Name);
                    }
                    if (each.Name == '20') {
                        minvalue = 10;
                        maxvalue = parseInt(each.Name);
                    }
                    if (each.Name == '30') {
                        minvalue = 20;
                        maxvalue = parseInt(each.Name);
                    }
                }
            })
            setData({ ...data, [name]: e.Id, ['minValue']: minvalue, ['maxValue']: maxvalue })
        }
        else {
            setData({ ...data, [name]: e.target.value })
        }
    }


    const getDaysdueData = (method) => {
        Service.getAllDaysduedata(method).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                            
                            setDaysDueData(res.data)
                        }
                    }
                }
            }
        });
    }

    const validateSave = () => {
        let checkpolicy = false
        if ((moment(data.ValidityTo).isBefore(moment(data.ValidityFrom)))) {
            setErrorMessage('Validity To should be greater than Validity From')
            setShowErrorModal(true);
            checkpolicy = true;
        }
        if ((moment(data.ValidityFrom).isAfter(moment(data.ValidityTo)))) {
            setErrorMessage('Validity From should be lesser than Validity To')
            setShowErrorModal(true);
            checkpolicy = true;
        }
        if (data.DaysLessthan == '') {
            setErrorMessage('Please select No of Days')
            setShowErrorModal(true);
            checkpolicy = true;
        }
        if (data.CashPercent == '') {
            setErrorMessage('Please enter cash percent')
            setShowErrorModal(true);
            checkpolicy = true;
        }
        return checkpolicy;
    }
    const SaveHandler = (e, flag) => {
        let check = validateSave()
        if (check == false) {
            data.CreatedDate = new Date()
            if (flag == 'save') {
                Service.createorupdateCashDiscount("CashDiscount/CreateOrUpdate", "save", data).then((res) => {
                    if (typeof res !== 'undefined') {
                        if (typeof res.data !== 'undefined' && res.data !== 'null') {
                            let id = res.data.Id
                    
                            if (res.status == 200) {
                                toast.success('Policy saved successfully !', {
                                    position: toast.POSITION.TOP_RIGHT,
                                });
                            }
                            getdata("CashDiscount/GetById", res.data.Id);
                        }
                    }
                })
            }
            if (flag == 'save&close') {
                Service.createorupdateCashDiscount("CashDiscount/CreateOrUpdate", "save", data).then((res) => {
                    if (typeof res !== 'undefined') {
                        if (typeof res.data !== 'undefined' && res.data !== 'null') {
                            let id = res.data.Id
                      
                            if (res.status == 200) {
                                toast.success('Policy saved successfully !', {
                                    position: toast.POSITION.TOP_RIGHT,
                                });
                            }
                            navigate("/CashDiscountPolicylist");
                        }
                    }
                })
            }
        }
    }
    const getdata = (method, id) => {
       
        Service.getdatabyId(method, id).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                          
                            let apidata = res.data
                            apidata.CreatedDate = moment(apidata.CreatedDate).format(SessionManage.getcurrentdateformat())
                            setData(apidata)
                            if (res.data.Status == 'Approved' || res.data.Status == 'Overridden' || res.data.Status == 'Expired' || res.data.Status == 'Submitted') {
                                setDisabledHeaderField(true)
                            }
                           if(id!=null){
                                $("#libread").html( res.data.CashdiscountId);
                           }
                        
                            
                        }
                    }
                }
            }
        });
       
    }
    const showSubmitDialog = (e, flag) => {
        //submit comments
        if (flag == 'showSubmitDialog') {
            setShowSubmitModal(!showSubmitModal)
        }
        else if (flag == 'proceed') {
            setShowSubmitModal(false)
            SubmitHandler(e, 'proceed')
        }
    }
    const SubmitHandler = (e, flag) => {
        let check = false
    
        if (flag == 'submit') {
            let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            let today = new Date().toLocaleString('en-US', { timeZone: timeZone });
            let validityfrom = new Date(data.ValidityFrom).toLocaleString('en-US', { timeZone: timeZone });
         
            if (moment(moment(validityfrom).format('MM/DD/YYYY')).isBefore(moment(today).format('MM/DD/YYYY'))) {
                showWarningDialog(e, 'open')
            }
            else {
                check = true
            }
        }
        if (flag == 'proceed' || check == true) {
            // alert('calling api')
            let id = data.Id;
            let submitcomments = commentsForSubmit;
            let userid = parseInt(loggedInUser)
            data.LastModifiedUserId = parseInt(loggedInUser)
            Service.submitCashDiscount('CashDiscount/Submit', submitcomments, data).then((res) => {
                if (typeof res !== 'undefined') {
                    if (typeof res.data !== 'undefined' && res.data !== 'null') {
                      
                        if (res.status == 200 & res.data.ErrorMessage == '') {
                            toast.success('Submitted Successfully !', {
                                position: toast.POSITION.TOP_RIGHT,
                            });
                            getdata("CashDiscount/GetById", res.data.Id);
                            setTimeout(() => {
                                navigate('/CashDiscountPolicylist')
                            }, 500)
                        }
                        else {
                            setShowSubmitModal(!showSubmitModal)
                            setErrorStatus("Error")
                            setErrorMessage(res.data.ErrorMessage)
                            setShowErrorModal(true)
                            setCommentsForSubmit('')
                        }
                    }
                }
            })
        }
    }



    const showWarningDialog = (e, flag) => {
        if (flag == 'open') {
            setShowWarningModal(!showWarningModal)
        }
        else if (flag === 'proceed') {
            setShowWarningModal(false)
            showSubmitDialog(e, 'showSubmitDialog')
        }
        else if (flag === 'close') {
            setShowWarningModal(false)
            setCommentsForSubmit('')
        }
    }
    const CloseHandler = (e, flag) => {
        navigate("/CashDiscountPolicylist");
    }

    const expireHandler = (e) => {
        Service.deleteDataBySDId("CashDiscount/expirePolicy", props.id, loggedInUser).then(res => {
            if (res.status === 200) {
                if (res.data !== 'null' && res.data !== 'undefined' && res.data.message !== 'Failed') {
                    toast.success('Expired Successfully', {
                        position: toast.POSITION.TOP_RIGHT,
                    });

                    //  alert('Expired Successfully')

                }
            }
        })
        navigate("/CashDiscountPolicylist");
    }


    const openPopup = (e, flag) => {
        if (flag == "Open") {
            setShowEndDateModal(true)
            let date = addDays(endate, 1)
            setEndDate(date)
        }
        else {
            setShowEndDateModal(false)
        }
        if (flag == "Proceed") {
            let date = moment(endate).format("YYYY-MM-DD")
          
            Service.EndDatebyId("CashDiscount/updateEndDate", props.id, loggedInUser, date).then(res => {
                if (res.status === 200) {
                    if (res.data !== 'null' && res.data !== 'undefined' && res.data.message !== 'Failed') {
                        toast.success('Endate Updated Successfully', {
                            position: toast.POSITION.TOP_RIGHT,

                        });
                     
                        getdata("CashDiscount/GetById", props.id);
                    }
                }
            })
        }
    }

    const customStyles = {
        control: base => ({
            ...base,
            minHeight: 26
        }),
        dropdownIndicator: base => ({
            ...base,
            padding: 2
        }),
        clearIndicator: base => ({
            ...base,
            padding: 2
        }),
        multiValue: base => ({
            ...base,

        }),
        valueContainer: base => ({
            ...base,
            padding: '0px 2px'
        }),
        input: base => ({
            ...base,
            margin: 0,
            padding: 0
        })
    };





    return (
        <>
            <div className="container-fluid content-top-gap">
                <ToastContainer autoClose={1000} />
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb my-breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">{t('Home.title')}</Link></li>
                        <li className="breadcrumb-item active" aria-current="page"><Link to="/policies">{t('Menu.Policies')}</Link></li>
                        <li className="breadcrumb-item active" aria-current="page"><Link to="/CashDiscountPolicylist">{t('Policies.CashDiscountPolicylist')}</Link></li>
                        <li className="breadcrumb-item active" id="libread" aria-current="page">{t('Policies.CreateNewCashDiscountPolicy')}</li>
                    </ol>
                </nav>
                <div className="row">
                    <div className="col-lg-12 pr-lg-2">
                        <div className="card text-center card_border">
                            <div className="card-header chart-grid__header" style={{ minHeight: "25vh" }}>
                                < div className="row">
                                    <div className="col-md-5 text-left">
                                        {t('Policies.CashDiscountPolicy')}
                                    </div>
                                    <div className="col-md-7">
                                        <button className="btn  btn-update btn-sm ml-1" style={{ width: "50px", float: "right" }} type="submit" onClick={(Event) => { CloseHandler(Event, "close") }} >{t('Cancel')}</button>
                                        {disableHeaderfield == true ?
                                            <>
                                                <button className="btn  btn-update btn-sm ml-1" style={{ width: "50px", float: "right" }} type="submit" onClick={(Event) => { expireHandler(Event) }} >{t('Expire')}</button>
                                                <button className="btn  btn-update btn-sm ml-1" style={{ width: "60px", float: "right" }} type="submit" onClick={(Event) => { openPopup(Event, "Open") }} >{t('EndDate')}</button>
                                            </>
                                            :
                                            <> <button className="btn  btn-update btn-sm ml-1" style={{ width: "40px", float: "right" }} type="submit" onClick={(Event) => { SaveHandler(Event, "save") }} >{t('Save')}</button>
                                                <button className="btn  btn-update btn-sm ml-1" style={{ width: "90px", float: "right" }} type="submit" onClick={(Event) => { SaveHandler(Event, "save&close") }} >{t('Save & Close')}</button>
                                                <button className="btn  btn-update btn-sm ml-1" style={{ width: "50px", float: "right" }} type="submit" onClick={(Event) => { SubmitHandler(Event, "submit") }} >{t('Submit')}</button>

                                            </>
                                        }
                                    </div>
                                </div>
                                <div className="row mt-1">
                                    <div className="col-sm-12 col-md-6 col-lg-4">
                                        <div className="row">
                                            <div className="col-lg-12 pr-lg-2">
                                                <div className="card text-center card_border">
                                                    <div className="card-body">
                                                        <div className="container">
                                                            <div className="row">
                                                                <label className="col-md-6 mt-2 text-left">{t('Policies.CashDiscountId')}</label>
                                                                <input type="text" name="NumberOfDays" disabled="true" value={data.CashdiscountId} className={`col-md-6 textbox-padding mt-2 form-control`} />

                                                                <label className="col-md-6 mt-2 text-left">{t('Policies.DaysLessThan')}<span className={classes.mandatory}>*</span></label>

                                                                <Select
                                                                    className={"col-md-6 mb-2 textbox-padding-dropdown mt-2 fontsizedropdown"}
                                                                    options={daysdueData}
                                                                    placeholder={t('Select')}
                                                                    value={daysdueData.filter(function (option) {
                                                                        return option.value == data.DaysLessthan;
                                                                    })}
                                                                    onChange={(event) => handlePolicyChange(event, 'DaysLessthan')}
                                                                    isSearchable={true}
                                                                    //styles={Service.getSelectstyle()}
                                                                    isDisabled={disableHeaderfield}
                                                                    styles={customStyles}
                                                                />
                                                                <label className="col-md-6 mt-2 text-left">{t('Policies.CashPercent')}<span className={classes.mandatory}>*</span></label>
                                                                <input type="number" name="NumberOfDays" disabled={disableHeaderfield} value={data.CashPercent} className={`col-md-6 textbox-padding mt-2 form-control`} onChange={(event) => handlePolicyChange(event, 'CashPercent')} />


                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-4">
                                        <div className="row">
                                            <div className="col-lg-12 pr-lg-2">
                                                <div className="card text-center card_border">
                                                    <div className="card-body">
                                                        <div className="container">
                                                            <div className="row">


                                                                <label className="col-md-6 mt-2 text-left">{t('Policies.ValidityFrom')}<span className={classes.mandatory}>*</span></label>
                                                                <div className='col-md-6 mt-2 textbox-padding'>
                                                                    <DatePicker name="Validity From" className={"drodownwidth form-control form-class"}
                                                                        selected={new Date(data.ValidityFrom)}
                                                                        onChange={(event) => OnChangeHandlerDate(event, 'ValidityFrom')}
                                                                        dateFormat={SessionManage.getdateformat()} disabled={disableHeaderfield} />
                                                                </div>

                                                                <label className="col-md-6 mt-2 text-left">{t('Policies.ValidityTo')}<span className={classes.mandatory}>*</span></label>
                                                                <div className='col-md-6 mt-2 textbox-padding'>
                                                                    <DatePicker name="Validity To" className={"drodownwidth form-control"}
                                                                        selected={new Date(data.ValidityTo)}
                                                                        onChange={(event) => OnChangeHandlerDate(event, 'ValidityTo')}
                                                                        dateFormat={SessionManage.getdateformat()} disabled={disableHeaderfield} />
                                                                </div>


                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-4">
                                        <div className="row">
                                            <div className="col-lg-12 pr-lg-2">
                                                <div className="card text-center card_border">
                                                    <div className="card-body">
                                                        <div className="container">
                                                            <div className="row">
                                                                <label className="col-md-6 mt-2 text-left">{t('Policies.Status')}</label>
                                                                <input type="text" name="Status" disabled="true" value={data.Status} className={`col-md-6 textbox-padding mt-2 form-control`} />

                                                                <label className="col-md-6 mt-2 text-left">{t('CreatedBy')}</label>
                                                                <input type="text" name="CreatedUser" disabled="true" value={data.CreatedUser} className={`col-md-6 textbox-padding mt-2 form-control`} />

                                                                <label className="col-md-6 mt-2 text-left">{t('Rebates.CreatedDate')}</label>
                                                                <input type="text" name="NumberOfDays" disabled="true" value={data.CreatedDate} className={`col-md-6 textbox-padding mt-2 form-control`} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div >
                                </div >
                            </div >
                        </div >
                    </div >
                    <CustomErrorPopup ErrorMessage={errorMessage} show={showErrorModal} ErrorStatus={errorStatus}
                        onHide={() => setShowErrorModal(false)}></CustomErrorPopup>
                    <Modal show={showSubmitModal} size="bg"
                        centered>
                        <Modal.Header>
                            <Modal.Title>{t('Rebates.SubmitComments')}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{ "textAlign": 'center' }} >
                            <Form.Control className={classes['Textarea-width']} as="textarea" rows={2} value={commentsForSubmit} name="commentsForSubmit"
                                onChange={(e) => { setCommentsForSubmit(e.target.value) }} />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button size='sm' style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "50px" }} onClick={(event) => showSubmitDialog(event, 'showSubmitDialog')}>
                                {t('Cancel')}
                            </Button>
                            <Button size='sm' style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "50px" }} onClick={(event) => { showSubmitDialog(event, 'proceed') }}>
                                {t('Submit')}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <Modal show={showWarningModal}
                        size="sm"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered>
                        <Modal.Body style={{ "textAlign": 'center', fontFamily: 'Segoe UI' }}>{'Validity From is in the Past, Do you want to proceed?'}</Modal.Body>
                        <Modal.Footer>
                            <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "45px" }} size='sm' onClick={(event) => showWarningDialog(event, "close")}>
                                {t('Close')}
                            </Button>
                            <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "55px" }} size='sm' onClick={(event) => showWarningDialog(event, "proceed")}>
                                {t('Proceed')}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <Modal show={showEndDateModal}
                        size="sm"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered>
                        <Modal.Body style={{ "textAlign": 'center', fontFamily: 'Segoe UI' }}>
                            <div>
                                <label className="col-md-6 mt-2 text-left">{t('Rebates.EndDate')}</label>
                                <div className='col-md-10 textbox-padding'>

                                    <DatePicker name="EndDate" className={"drodownwidth"}
                                        selected={endate}
                                        onChange={(e) => OnChangeHandlerEndDate(e)}
                                        dateFormat={SessionManage.getdateformat()} />
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "45px" }} size='sm' onClick={(event) => openPopup(event, "Close")}>
                                {t('Close')}
                            </Button>
                            <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "55px" }} size='sm' onClick={(event) => openPopup(event, "Proceed")}>
                                {t('Proceed')}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div >
            </div >
        </>
    )
}
export default CreateNewCashDiscountPolicyView