import { Link, Navigate, useLocation } from "react-router-dom"
import { Form, FormGroup, Col, Row, Button, InputGroup, Collapse, Modal } from 'react-bootstrap';
import React, { useState, useEffect } from 'react'
import classes from '../../RebateProgramComponent/CreateRebateComponent/Form.module.css'
import Service from '../../../Services/Service';
import 'react-multiple-select-dropdown-lite/dist/index.css'
import CustomErrorPopup from '../../../UI/CustomErrorPopup';
import $ from 'jquery';
import Multiselect from 'multiselect-react-dropdown';
import { useTranslation } from "react-i18next";
import Select from "react-select";

function CreateNewCategoryExceptionPolicy(props) {
    const location = useLocation();
    const [userPerms, setuserPermission] = useState([]);
    const [t, i18n] = useTranslation('translation');
    let rowData = [];
   
    const [rebateTypeData, setRebateTypeData] = useState([]);
    const [listViewData, setListViewData] = useState(rowData);
    const [errorMessage, setErrorMessage] = useState("");
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorStatus, setErrorStatus] = useState("Error");
    const [isValid, setIsValid] = useState(true);
    const [policyStatus, setPolicyStatus] = useState(false);
    let loggedInUser = sessionStorage.getItem("id");
    const [closeModal, setCloseModal] = useState(false);
    const [handleChange, setHandleChange] = useState(false);
    const [isLocked, setIsLocked] = useState(false);
    const [lockedBy, setLockedBy] = useState('');
    const [customerData, setCustomerData] = useState([]);
    const [groupCustomerData, setGroupCustomerData] = useState([]);
    const [SalesLevelData, setSalesLevelData] = useState([])
    const [data, setData] = useState([]);
    const [groupdata, setGroupData] = useState([]);
    let disabled = false;
    const [sidebarwidth, setsidebarwidth] = useState('');
    const [selectedOptionsRebateType, setSelectedOptionsRebateType] = useState('');
    const [selectedCustomer, setSelectedCustomer] = useState([]);
    const [selectedRegion, setSelectedRegion] = useState([]);
    const [selectedAttribute, setSelectedAttribute] = useState('');
    const [showProductCountpopup, setShowProductCountpopup] = useState(false);
    const [showProductCountData, setShowProductCountData] = useState(false);
    const [showSelectedProductpopup, setShowSelectedProductpopup] = useState(false);
    const [showSelectedCustomerpopup, setShowSelectedCustomerpopup] = useState(false);
    const [showCustomerCountpopup, setShowCustomerCountpopup] = useState("");
    const [showSelectedGroupCustomerpopup, setShowSelectedGroupCustomerpopup] = useState(false);
    const [showGroupCustomerCountpopup, setShowGroupCustomerCountpopup] = useState("");
    const [showSelectedRegionpopup, setShowSelectedRegionpopup] = useState(false);
    const [showRegionCountpopup, setShowRegionCountpopup] = useState(false);
    const [checkCustomerGroup, setcheckCustomerGroup] = useState(false);
    const [attribute1Data, SetAttribute1Data] = useState([]);
    const [attribute2Data, SetAttribute2Data] = useState([]);
    const [attribute3Data, SetAttribute3Data] = useState([]);
    const [attribute4Data, SetAttribute4Data] = useState([]);
    const [showDeleteModal, SetShowDeleteModal] = useState(false);
    const [keyValue, SetKeyValue] = useState("");
    const [countKeyValue, SetCountKeyValue] = useState("");
    const [attbSearch, SetAttbSearch] = useState({
        "cat1": [],
        "cat2": [],
        "cat3": [],
        "cat4": [],
    })

    const [categoryDetail, SetCategoryDetail] = useState({
        "Id": "0",
        "RebateTypeId": "",
        "lstCatCustExp": [],
        "region": [],
        "CustomerGroup": false,
        "CreatedUserId": parseInt(loggedInUser),
        "LastModifiedUserId": parseInt(loggedInUser),
        "lstcat1": [],
        "lstcat2": [],
        "lstcat3": [],
        "lstcat4": [],
    })
    const [inputField, setInputField] = useState([])
    const [loadedFrom, setLoadedFrom] = useState('')
    const [customercheckAll, setCustomercheckAll] = useState(false);
    const [customergroupcheckAll, setCustomerGroupcheckAll] = useState(false);
    const [attbCheckAll, setattbCheckAll] = useState(false);
    const [paging, setpaging] = useState({
        pageSize: 20,
        pageNumber: 1
    })
    useEffect(() => {

        getUserPermission(location.pathname);
        getRebateTypeData("rebatetype")
        getCustomerData("Customer/GetIndividualCustomersWithoutGroup")
        getGroupCustomerData("CustomerGroups/GetAll", paging.pageSize, paging.pageNumber, "Descending", "Id", [], null)
        getSalesOfficeDataNew("SalesOffice/GetBySalesOrgID")
        getProductAttribute1("CategoryException/GetAttributeValue")
        getProductAttribute2("CategoryException/GetAttributeValue")
        getProductAttribute3("CategoryException/GetAttributeValue")
        getProductAttribute4("CategoryException/GetAttributeValue")
        if (props.id !== 0) {
 
            getdata("CategoryException/GetById", props.id);
            getAttributeData("CategoryException/GetAttributeById", props.id, "Cat-I")
            getAttributeData("CategoryException/GetAttributeById", props.id, "Cat-II")
            getAttributeData("CategoryException/GetAttributeById", props.id, "Cat-III")
            getAttributeData("CategoryException/GetAttributeById", props.id, "Cat-IV")
        }
    }, [])
    const getUserPermission = (pathname) => {
        Service.GetUserPermission(pathname).then((res) => {
            if (res !== undefined) {
         
                if (res.data !== 'null' && res.data !== undefined && res.data !== '') {
                    if (res.data.DataList !== undefined) {
                        setuserPermission(res.data.DataList)
                    }
                }
            }
        });
    }


    const getdata = (method, id) => {
        Service.getdatabyId(method, id).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                            setTimeout(() => {
                                let tempCatData = categoryDetail
                                tempCatData.RebateTypeId = res.data.RebateTypeId
                                tempCatData.CustomerGroup = res.data.CustomerGroup
                                tempCatData.Id = res.data.Id
                                setSelectedOptionsRebateType(res.data.RebateTypeId)
                                let temp = res.data.lstCatCustExp.filter(e => e.Selected == true)
                                temp = temp.map((i) => {
                                    return { "Id": i.Id }
                                })
                                tempCatData.lstCatCustExp = temp
                              
                                if (res.data.CustomerGroup == true) {
                                    setcheckCustomerGroup(true)
                                    setGroupCustomerData(res.data.lstCatCustExp)
                                    setGroupData(res.data.lstCatCustExp)
                                }
                                else {
                                    setCustomerData(res.data.lstCatCustExp)
                                    setData(res.data.lstCatCustExp)
                                }
                                SetCategoryDetail(tempCatData)
                            }, 350);
                        }
                    }
                }
            }

        });
    }


    const getRebateTypeData = (method) => {
        Service.getAlldata(method).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                            setRebateTypeData(res.data)
                        
                            res.data.map((i, index) => {
                                if (i.Name === "Revenue") {
                                    setSelectedOptionsRebateType(i.Id)
                                    let temp = i.Id
                                   
                                }
                            })
                        }
                    }
                }

            }
        });
    }

    const getAttributeData = (method, id, key) => {
        Service.getAttributebyId(method, id, key).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                            setTimeout(() => {
                                let temp = []
                                res.data.selectedlstCat.forEach(e => {
                                    if (e.Selected == true) {
                                        temp.push({ "Id": e.Id })
                                    }
                                })
                           
                                let cat = categoryDetail
                                let searchdata = attbSearch
                                if (key == "Cat-I") {
                                    cat.lstcat1 = temp
                                    SetAttribute1Data(res.data.selectedlstCat)
                                    searchdata.cat1 = res.data.selectedlstCat
                                }
                                if (key == "Cat-II") {
                                    cat.lstcat2 = temp
                                    SetAttribute2Data(res.data.selectedlstCat)
                                    searchdata.cat2 = res.data.selectedlstCat
                                }
                                if (key == "Cat-III") {
                                    cat.lstcat3 = temp
                                    SetAttribute3Data(res.data.selectedlstCat)
                                    searchdata.cat3 = res.data.selectedlstCat
                                }
                                if (key == "Cat-IV") {
                                    cat.lstcat4 = temp
                                    SetAttribute4Data(res.data.selectedlstCat)
                                    searchdata.cat4 = res.data.selectedlstCat
                                }
                          
                                SetCategoryDetail(cat)
                                SetAttbSearch(searchdata)
                            }, 350);
                        }
                    }
                }

            }
        });
    }
  

    const getCustomerData = (method) => {
 
        Service.getdata(method).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                         
                            setCustomerData(res.data)
                            setData(res.data)
                        }
                    }
                }
            }

        });
    }
    const getGroupCustomerData = (method, pageSize, pageNumber, sortType, SortProperty, filterValue, search) => {
   
        Service.getdata(method).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                        
                            setGroupCustomerData(res.data.DataList)
                            setGroupData(res.data.DataList)
                        }
                    }
                }
            }

        });
    }

    const getSalesOfficeDataNew = (method) => {
        Service.getregionbyId('SalesOffice/GetBySalesOrgID', 0).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                            let rowData = res.data
                            if (rowData.length > 0) {
                                for (var i = 0; i < rowData.length; i++) {
                                    for (var j = 0; j < customerData.length; j++) {
                                        try {
                                            if (rowData[i].label.toLowerCase() == customerData[j].State.toLowerCase())
                                                if (customerData[j].Selected == true)
                                                    rowData[i].selected = true;
                                        } catch { }
                                    }
                                }
                                setSalesLevelData(rowData)
                            }
                            else {
                                setSalesLevelData([])
                            }
                        }
                    }
                }
            }
        });
    }


    const checkValidation = () => {
        let isValidPolicy = true;
        if (selectedOptionsRebateType == null || selectedOptionsRebateType == 0 || selectedOptionsRebateType == '') {
            setShowErrorModal(true);
            setErrorMessage("Please Select Rebate Type")
            isValidPolicy = false;
        }
        if (categoryDetail.CustomerGroup == "false") {
            if (categoryDetail.lstCatCustExp.length == 0 || categoryDetail.lstCatCustExp.length < 0) {
                setShowErrorModal(true);
                setErrorMessage("Please Select Customer")
                isValidPolicy = false;
            }
        }
        if (categoryDetail.CustomerGroup == "true") {
            if (categoryDetail.lstCatCustExp.length == 0 || categoryDetail.lstCatCustExp.length < 0) {
                setShowErrorModal(true);
                setErrorMessage("Please Select Group Customer")
                isValidPolicy = false;
            }
        }
        if (categoryDetail.lstcat1.length + categoryDetail.lstcat2.length + categoryDetail.lstcat3.length + categoryDetail.lstcat4.length <= 0) {
            setShowErrorModal(true);
            setErrorMessage("Please Select Any One Category")
            isValidPolicy = false;
        }

        return isValidPolicy
    }

    const saveHandler = (flag) => {

        let isValidPolicy = checkValidation();
        let value = selectedOptionsRebateType
        let data = categoryDetail
        data.RebateTypeId = value
        if (isValidPolicy === true) {
            Service.createorupdate("CategoryException/CreateOrUpdate", data).then(res => {
                if (typeof res !== 'undefined') {
                    if (typeof res.data !== 'undefined' && res.data !== 'null') {
                        if (res.data > 0 & flag == "Save&Close") {
                         
                            props.navigate("/CategoryExceptionPolicy");
                        }
                        if (res.data > 0 & flag == "Save") {
                           
                            alert("Policy Saved Successfully")
                        }
                        else {
                            setErrorMessage(t('Policies.SamePolicyAlreadyExists'))
                            setShowErrorModal(true)
                        }
                    }
                }

            });
        }
    }


    const customStyles = {
        control: base => ({
            ...base,
            minHeight: 28
        }),
        dropdownIndicator: base => ({
            ...base,
            padding: 2,
            fontSize: "0.75rem",
        }),
        clearIndicator: base => ({
            ...base,
            padding: 2
        }),
        multiValue: base => ({
            ...base,
            fontSize: "0.75rem",
        }),
        valueContainer: base => ({
            ...base,
            padding: '0px 2px',
            fontSize: "0.75rem",
        }),
        input: base => ({
            ...base,
            margin: 0,
            padding: 0
        })
    };

    const class_select = ` col-md-2 textbox-padding mt-2 fontsizedropdown  ${Service.editPerms(userPerms) == false ? "disabledClass" : ""}`;

    const DeleteHandler = () => {
        Service.DeleteCategoryException('CategoryException/Delete', props.id, loggedInUser).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        alert('Deleted Successfully !')
                        props.navigate("/CategoryExceptionPolicy");
                    }
                }
                else {
                    setErrorMessage("Cannot Delete Policy")
                    setShowErrorModal(true)
                }
            }
            else {
                setErrorMessage("Cannot Delete Policy")
                setShowErrorModal(true)
            }

        });
    }
    const checkDelete = (flag) => {
        if (flag == "Open") {
            SetShowDeleteModal(true)
        }
        if (flag == "Close") {
            SetShowDeleteModal(false)
        }
        if (flag == "Proceed") {
            SetShowDeleteModal(false)
            DeleteHandler()
        }
    }


    const closeHandler = (flag) => {
        if (flag == "Open" && handleChange == true) {
            setCloseModal(true)
        }
       
        props.navigate("/CategoryExceptionPolicy")
    
        if (flag == "Close") {
           
            props.navigate("/CategoryExceptionPolicy")
        }
        if (flag == "Cancel") {
            setCloseModal(false)
        }

    }
    if (isLocked === false || (isLocked === true && lockedBy.toString() === loggedInUser)) {
        disabled = false;
    }
    else {
        disabled = true;
    }
    window.onbeforeunload = function () {
        unlockEntity();
   
    };

    const unlockEntity = () => {

    }
    const class_textbox = `col-md-2 mt-2 textbox-padding  ${Service.editPerms(userPerms) == false ? "disabledClass" : ""}`;



    const OnChangeHandlerRebateType = (data) => {
        SetCategoryDetail({ ...categoryDetail, ['RebateTypeId']: data.Id })
        setSelectedOptionsRebateType(data.Id)
    }

    const checkAllCustomer = (mycheckbox) => {
  
        const getallData = [...categoryDetail.lstCatCustExp];
        var checkboxes = document.querySelectorAll("input[name = 'customer-check']");
        if (mycheckbox.target.checked == true) {
            setCustomercheckAll(true);
            checkboxes.forEach(function (checkbox) {
                checkbox.checked = true;
            });
            let olddata = [...customerData];
            olddata.map((i) => {
                i.Selected = true;
            
                getallData.push({ "Id": i.Id },);
            })
            setCustomerData(olddata);
            SetCategoryDetail({ ...categoryDetail, ['lstCatCustExp']: getallData })
        }
        else {
            setCustomercheckAll(false);
            checkboxes.forEach(function (checkbox) {
                checkbox.checked = false;
            });
            let olddata = [...customerData];
            olddata.map((i) => {
                i.Selected = false;
            })
            setCustomerData(olddata);
           
            SetCategoryDetail({ ...categoryDetail, ['lstCatCustExp']: [] })
        }
    }
    const checkAllGroupCustomer = (mycheckbox) => {

        const getallData = [...categoryDetail.lstCatCustExp];
        var checkboxes = document.querySelectorAll("input[name = 'customergroup-check']");
        if (mycheckbox.target.checked == true) {
            setCustomerGroupcheckAll(true);
            checkboxes.forEach(function (checkbox) {
                checkbox.checked = true;
            });
            let olddata = [...groupCustomerData];
            olddata.map((i) => {
                i.Selected = true;
              
                getallData.push({ "Id": i.Id },);
            })
            setGroupCustomerData(olddata);
            SetCategoryDetail({ ...categoryDetail, ['lstCatCustExp']: getallData })
        }
        else {
            setCustomerGroupcheckAll(false);
            checkboxes.forEach(function (checkbox) {
                checkbox.checked = false;
            });
            let olddata = [...groupCustomerData];
            olddata.map((i) => {
                i.Selected = false;
            })
            setGroupCustomerData(olddata);
     
            SetCategoryDetail({ ...categoryDetail, ['lstCatCustExp']: [] })
        }
    }

    const checkAllAttribute = (mycheckbox) => {
        let names = [
            { key: "Cat-I", data: attribute1Data, setter: SetAttribute1Data, cat: 'lstcat1' },
            { key: "Cat-II", data: attribute2Data, setter: SetAttribute2Data, cat: 'lstcat2' },
            { key: "Cat-III", data: attribute3Data, setter: SetAttribute3Data, cat: 'lstcat3' },
            { key: "Cat-IV", data: attribute4Data, setter: SetAttribute4Data, cat: 'lstcat4' }
        ]
        let list = []
        names.forEach((each) => {
            if (each.key == keyValue) {
                list = each.data
                const getallData = [...categoryDetail.lstCatCustExp];
                var checkboxes = document.querySelectorAll("input[name = 'attribute-check']");
                if (mycheckbox.target.checked == true) {
                    setattbCheckAll(true);
                    checkboxes.forEach(function (checkbox) {
                        checkbox.checked = true;
                    });
                    let olddata = [...each.data];
                    olddata.map((i) => {
                        i.Selected = true;
                        
                        getallData.push({ "Id": i.Id },);
                    })
                    each.setter(olddata);
                    SetCategoryDetail({ ...categoryDetail, [each.cat]: getallData })
                }
                else {
                    setattbCheckAll(false);
                    checkboxes.forEach(function (checkbox) {
                        checkbox.checked = false;
                    });
                    let olddata = [...each.data];
                    olddata.map((i) => {
                        i.Selected = false;
                    })
                    each.setter(olddata);
                
                    SetCategoryDetail({ ...categoryDetail, [each.cat]: [] })
                }
            }
        })
    }

    const OnChangeHandlerCustomer = (e, label, id, State) => {
        let update = [];
        const getallData = [...categoryDetail.lstCatCustExp];
        let olddata = [...customerData];
        if (e.target.checked === true) {
            olddata.map((i) => {
                if (i.Id === id) {
                    i.Selected = true;
                }
            })
            getallData.push({ "Id": id },);
            setCustomerData(olddata);
            SetCategoryDetail({ ...categoryDetail, ['lstCatCustExp']: getallData })
        }
        else {
            olddata.map((i) => {
                if (i.Id === id) {
                    i.Selected = false;
                }
                update.push(i);
            })

            SetCategoryDetail({ ...categoryDetail, ['lstCatCustExp']: getallData.filter(i => i.Id !== id) })
            setCustomerData(olddata);
        }
    }

    const OnChangeHandlerGroupCustomer = (e, label, id, name) => {
        let update = [];
        const getallData = [...categoryDetail.lstCatCustExp];
        let olddata = [...groupCustomerData];
        if (e.target.checked === true) {
            olddata.map((i) => {
                if (i.Id === id) {
                    i.Selected = true;
                }
            })
            getallData.push({ "Id": id },);
            setGroupCustomerData(olddata);
            SetCategoryDetail({ ...categoryDetail, ['lstCatCustExp']: getallData })
        }
        else {
            olddata.map((i) => {
                if (i.Id === id) {
                    i.Selected = false;
                }
                update.push(i);
            })

            SetCategoryDetail({ ...categoryDetail, ['lstCatCustExp']: getallData.filter(i => i.Id !== id) })
            setGroupCustomerData(olddata);
        }
    }

    const OnChangeHandlerRegion = (e, label, id) => {
        let update = [];
        const getallData = [...categoryDetail.region];
        let olddata = [...SalesLevelData];
        if (e.target.checked === true) {
            olddata.map((i) => {
                if (i.Id === id) {
                    i.Selected = true;
                }
            })
            getallData.push({ "Id": id },);
            setSalesLevelData(olddata);
            SetCategoryDetail({ ...categoryDetail, ['region']: getallData })
        }
        else {
            olddata.map((i) => {
                if (i.Id === id) {
                    i.Selected = false;
                }
                update.push(i);
            })
            SetCategoryDetail({ ...categoryDetail, ['region']: getallData.filter(i => i.Id !== id) })
            setSalesLevelData(olddata);
        }
    }

    const OnChangeHandlerAttribute = (e, label, id, flag) => {
        //
        let names = [
            { key: "Cat-I", label: "Category-I", data: attribute1Data },
            { key: "Cat-II", label: "Category-II", data: attribute2Data },
            { key: "Cat-III", label: "Category-III", data: attribute3Data },
            { key: "Cat-IV", label: "Category-IV", data: attribute4Data }
        ]
        let temp = []
        names.forEach((each) => {  //iterating names array 
            if (each.key == flag) {
                each.data.forEach((cat) => {  // iterating attribute data
                    if (cat.Id === id) {
                        e.target.checked ? cat.Selected = true : cat.Selected = false
                    }
                })
                temp = each //storing selected obj in var  
            }
        })
        switch (flag) {
            case "Cat-I":
                SetAttribute1Data(temp.data)
                break;

            case "Cat-II":
                SetAttribute2Data(temp.data)
                break;

            case "Cat-III":
                SetAttribute3Data(temp.data)
                break;

            case "Cat-IV":
                SetAttribute4Data(temp.data)
                break;

        }
        let attbData = ([{
            "lstcat1": formatAttributes(attribute1Data),
            "lstcat2": formatAttributes(attribute2Data),
            "lstcat3": formatAttributes(attribute3Data),
            "lstcat4": formatAttributes(attribute4Data)
        }])
        attbData.map((i) => {
            SetCategoryDetail({ ...categoryDetail, ['lstcat1']: i.lstcat1, ['lstcat2']: i.lstcat2, ['lstcat3']: i.lstcat3, ['lstcat4']: i.lstcat4 })
        })
    }

    const formatAttributes = (data) => {
        let temp = []
        data.forEach((i) => {
            if (i.Selected == true) {
                temp.push({ "Id": i.Id, "Name": i.Name, "Label": i.Label },)
            }
        })
        return temp
    }
   


    const handleGroupCust = (e) => {
        if (e.target.checked == true) {
            setcheckCustomerGroup(true)
            SetCategoryDetail({ ...categoryDetail, ['CustomerGroup']: true, ['lstCatCustExp']: [] })
        }
        else {
            setcheckCustomerGroup(false)
            SetCategoryDetail({ ...categoryDetail, ['CustomerGroup']: false, ['lstCatCustExp']: [] })
        }
    }

    const getProductAttribute1 = (method) => {
        let key = "Cat-I"
        Service.getAttributeValue(method, key).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                          
                            SetAttribute1Data(res.data)
                            SetAttbSearch({ ...attbSearch, ['cat1']: res.data })
                        }
                    }
                }
            }

        });
    }
    const getProductAttribute2 = (method) => {
        let key = "Cat-II"
        Service.getAttributeValue(method, key).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                          
                            SetAttribute2Data(res.data)
                            SetAttbSearch({ ...attbSearch, ['cat2']: res.data })
                        }
                    }
                }
            }

        });
    }
    const getProductAttribute3 = (method) => {
        let key = "Cat-III"
        Service.getAttributeValue(method, key).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                        
                            SetAttribute3Data(res.data)
                            SetAttbSearch({ ...attbSearch, ['cat3']: res.data })
                        }
                    }
                }
            }

        });
    }
    const getProductAttribute4 = (method) => {
        let key = "Cat-IV"
        Service.getAttributeValue(method, key).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                         
                            SetAttribute4Data(res.data)
                            SetAttbSearch({ ...attbSearch, ['cat4']: res.data })
                        }
                    }
                }
            }

        });
    }

    const clickRegionPopup = (flag) => {
        if (flag === "Open") {
            setShowSelectedRegionpopup(true)
            setShowSelectedCustomerpopup(false)
            setShowSelectedProductpopup(false)
            setShowSelectedGroupCustomerpopup(false)
            setsidebarwidth("80vw");
        }
        if (flag === "Close") {
            setShowSelectedRegionpopup(false)
        }
    }

    const clickProductCountPopup = (flag, key) => {
        if (key == "Cat-I") {
            if (flag === 'Open') {
                SetCountKeyValue(key)
                setShowProductCountpopup(true)
                setsidebarwidth("60vw");
                
            }

        }
        if (key == "Cat-II") {
            if (flag === 'Open') {
                SetCountKeyValue(key)
                setShowProductCountpopup(true)
                setsidebarwidth("60vw");
                
            }

        }
        if (key == "Cat-III") {
            if (flag === 'Open') {
                SetCountKeyValue(key)
                setShowProductCountpopup(true)
                setsidebarwidth("60vw");
                
            }

        }
        if (key == "Cat-IV") {
            if (flag === 'Open') {
                SetCountKeyValue(key)
                setShowProductCountpopup(true)
                setsidebarwidth("60vw");
                
            }

        }
        if (flag === 'Close') {
            setShowProductCountpopup(false)
            SetKeyValue("")
        }
    }

    const customerSearch = (event) => {
        let list = customerData
        if (event.target.value != '') {
            let searchResults = list.filter((each) => {
                
                return each.label.toLowerCase().includes(String(event.target.value).toLowerCase()) ||
                    each.Name.toLowerCase().includes(String(event.target.value).toLowerCase()) ||
                    each.Station.toLowerCase().includes(String(event.target.value.toLowerCase())) ||
                    each.State.toLowerCase().includes(String(event.target.value.toLowerCase())) ||
                    each.Zone.toLowerCase().includes(String(event.target.value.toLowerCase()))
            });
            setCustomerData(searchResults)
        }
        else {
            setCustomerData(data)
        }
    }
    const groupcustomerSearch = (event) => {
        let list = groupCustomerData
        if (event.target.value != '') {
            let searchResults = list.filter((each) => {
             
                return each.label.toLowerCase().includes(String(event.target.value).toLowerCase()) ||
                    each.Name.toLowerCase().includes(String(event.target.value).toLowerCase())
            });
            setGroupCustomerData(searchResults)
        }
        else {
            setGroupCustomerData(groupdata)
        }
    }
    const attributeSearch = (event) => {
        let names = [
            { key: "Cat-I", data: attbSearch.cat1, setter: SetAttribute1Data },
            { key: "Cat-II", data: attbSearch.cat2, setter: SetAttribute2Data },
            { key: "Cat-III", data: attbSearch.cat3, setter: SetAttribute3Data },
            { key: "Cat-IV", data: attbSearch.cat4, setter: SetAttribute4Data }
        ]
        let list = []
        names.forEach((each) => {
            if (each.key == keyValue) {
                list = each.data
                if (event.target.value != '') {
                    let searchResults = list.filter((each) => {
                    
                        return each.Name.toLowerCase().includes(String(event.target.value).toLowerCase())
                    });
                    each.setter(searchResults)
                }
                else {
                    each.setter(each.data)
                }
            }
        })
    }
    


    const clickCustomerPopup = (flag) => {
        if (flag === "Open") {
            setShowCustomerCountpopup("Data")
            setShowSelectedCustomerpopup(true)
            setShowSelectedProductpopup(false)
            setShowSelectedRegionpopup(false)
            setShowSelectedGroupCustomerpopup(false)
            setsidebarwidth("80vw");
        }
        if (flag === "Close") {
            setCustomerData(data)
            setShowSelectedCustomerpopup(false)
        }
    }

    const clickGroupCustomerPopup = (flag) => {
        if (flag === "Open") {
            setShowGroupCustomerCountpopup("Data")
            setShowSelectedGroupCustomerpopup(true)
            setShowSelectedCustomerpopup(false)
            setShowSelectedProductpopup(false)
            setShowSelectedRegionpopup(false)
            setsidebarwidth("60vw");
        }
        if (flag === "Close") {
            setGroupCustomerData(groupdata)
            setShowSelectedGroupCustomerpopup(false)
        }
    }
    const clickGroupCustomerCountPopup = (flag) => {
        if (flag === "Open") {
            setShowGroupCustomerCountpopup("Count")
            setShowSelectedGroupCustomerpopup(true)
            setShowSelectedCustomerpopup(false)
            setShowSelectedProductpopup(false)
            setShowSelectedRegionpopup(false)
            setsidebarwidth("60vw");
        }
        if (flag === "Close") {
            setShowSelectedGroupCustomerpopup(false)
        }
    }
    const clickCustomerCountPopup = (flag) => {
        if (flag === "Open") {
            setShowCustomerCountpopup("Count")
            setShowSelectedCustomerpopup(true)
            setShowSelectedProductpopup(false)
            setShowSelectedRegionpopup(false)
            setShowSelectedGroupCustomerpopup(false)
            setsidebarwidth("60vw");
        }
        if (flag === "Close") {
            setShowSelectedGroupCustomerpopup(false)
        }
    }

    const clickProductPopup = (flag, cat) => {
        if (flag === 'Open' && cat === "Cat-I") {
            setShowSelectedProductpopup(true)
            setShowSelectedCustomerpopup(false)
            setShowSelectedRegionpopup(false)
            setShowSelectedGroupCustomerpopup(false)
            setsidebarwidth("60vw");
            SetKeyValue(cat)
        }
        if (flag === 'Open' && cat === "Cat-II") {
            setShowSelectedProductpopup(true)
            setShowSelectedCustomerpopup(false)
            setShowSelectedRegionpopup(false)
            setShowSelectedGroupCustomerpopup(false)
            setsidebarwidth("60vw");
            SetKeyValue(cat)
        }
        if (flag === 'Open' && cat === "Cat-III") {
            setShowSelectedProductpopup(true)
            setShowSelectedCustomerpopup(false)
            setShowSelectedRegionpopup(false)
            setShowSelectedGroupCustomerpopup(false)
            setsidebarwidth("60vw");
            SetKeyValue(cat)
        }
        if (flag === 'Open' && cat === "Cat-IV") {
            setShowSelectedProductpopup(true)
            setShowSelectedCustomerpopup(false)
            setShowSelectedRegionpopup(false)
            setShowSelectedGroupCustomerpopup(false)
            setsidebarwidth("60vw");
            SetKeyValue(cat)
        }
        if (flag === 'Close' && cat == "Cat-I") {
            setShowSelectedProductpopup(false)
            SetKeyValue("")
            SetAttribute1Data(attbSearch.cat1)
            setattbCheckAll(false)
        }
        if (flag === 'Close' && cat == "Cat-II") {
            setShowSelectedProductpopup(false)
            SetKeyValue("")
            SetAttribute2Data(attbSearch.cat2)
            setattbCheckAll(false)
        }
        if (flag === 'Close' && cat == "Cat-III") {
            setShowSelectedProductpopup(false)
            SetKeyValue("")
            SetAttribute3Data(attbSearch.cat3)
            setattbCheckAll(false)
        }
        if (flag === 'Close' && cat == "Cat-IV") {
            setShowSelectedProductpopup(false)
            SetKeyValue("")
            SetAttribute4Data(attbSearch.cat4)
            setattbCheckAll(false)
        }
    }



    return (
        <>
            <div className="container-fluid content-top-gap">

                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb my-breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">{t('Home.title')}</Link></li>
                        <li className="breadcrumb-item active" aria-current="page"><Link to="/CategoryExceptionPolicy">{t('Policies.CategoryExceptionPolicy')}</Link></li>
                        <li className="breadcrumb-item active" id="libread" aria-current="page">{t('Policies.CreateNewExceptionForAccrualCalcualtions')}</li>
                    </ol>
                </nav>

                <div className="row mt-3" id="rebateProgramdiv">
                    <div className="col-lg-12 pr-lg-2">
                        <div className="card text-center card_border">
                            <div className="card-header chart-grid__header">
                                <div className="row">
                                    <div className="col-md-7 text-left">
                                        {t('Policies.CreateNewExceptionForAccrualCalcualtions')}
                                    </div>
                                    <div className="col-md-5">
                                        <button className="btn  btn-update btn-sm ml-2" type="submit" style={{ float: "right",width: "40px" }} onClick={(event) => saveHandler("Save")}>{t('Save')}</button>
                                        <button className="btn  btn-update btn-sm ml-2" id="close" style={{ width: "45px", backgroundColor: "buttonface", float: "right" }} onClick={(event) => closeHandler("Open")} >{t('Close')}</button>
                                        <button className="btn  btn-update btn-sm ml-2" id="close" style={{ width: "50px", backgroundColor: "buttonface", float: "right" }} onClick={(event) => checkDelete("Open")} >{t('Delete')}</button>
                                        <button className="btn  btn-update btn-sm ml-2" id="close" style={{ width: "80px", backgroundColor: "buttonface", float: "right" }} onClick={(event) => saveHandler("Save&Close")} >{t('Save&Close')}</button>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body" style={{ minHeight: "80vh" }}>
                                <div className="container">
                                    <div className="row">
                                        <label className="col-md-2 text-left">{t('Rebates.RebateType')}<span className={classes.mandatory}>*</span></label>
                                        <Select
                                            className={class_select}
                                            options={rebateTypeData}
                                            placeholder={t('Select')}
                                            value={
                                                rebateTypeData.filter(function (option) {
                                                    return option.Id === selectedOptionsRebateType;
                                                })
                                            }
                                            onChange={OnChangeHandlerRebateType}
                                            isSearchable={true}
                                            styles={customStyles}
                                       
                                        />
                                        <div className="col-md-2">
                                        </div>
                                    </div>
                                    <div className="row">
                                        <label className="col-md-2 text-left" style={{ marginTop: "12px", marginBottom: "12px" }}>{t('Rebates.CustomerGroup')}</label>
                                        <input type="checkbox" style={{ marginLeft: "5px" }} checked={checkCustomerGroup} onChange={(event) => handleGroupCust(event)}></input>
                                        <div className="col-md-2">
                                        </div>
                                    </div>
                                    {checkCustomerGroup === true ?
                                        <div className="row">
                                            <label className="col-md-2 text-left">{t('Rebates.GroupCustomer')}</label>
                                            <button className="btn  btn-update btn-sm ml-2" onClick={(event) => clickGroupCustomerPopup('Open')} style={{width: "50px"}}>{t('Select')}</button>
                                            <span style={{ marginLeft: "20px" }}><a href='javascript:void(0)' onClick={(event) => clickGroupCustomerCountPopup('Open')}>
                                                <label style={{ paddingTop: "5px" }}>({groupCustomerData.filter((e) => e.Selected === true).length}){t('Policies.SelectedGroupCustomers')}</label></a></span>
                                        </div>
                                        :
                                        ""
                                    }
                                    {checkCustomerGroup === false ?
                                        <div className="row">
                                            <label className="col-md-2 text-left">{t('Rebates.Customer')}</label>
                                            <button className="btn  btn-update btn-sm ml-2" onClick={(event) => clickCustomerPopup('Open')}>{t('Select')}</button>
                                            <span style={{ marginLeft: "20px" }}><a href='javascript:void(0)' onClick={(event) => clickCustomerCountPopup('Open')}>
                                                <label style={{ paddingTop: "5px" }}>({customerData.filter((e) => e.Selected === true).length}){t('Policies.SelectedCustomers')}</label></a></span>
                                        </div>
                                        :
                                        ""
                                    }
                                    <div style={{ marginTop: "10px" }} className="row">
                                        <label className="col-md-2 mt-2 text-left">{t('Rebates.Region')}</label>
                                        <button className="btn  btn-update btn-sm ml-2" onClick={(event) => clickRegionPopup('Open')} style={{width: "50px"}}>{t('Select')}</button>
                                        <span style={{ marginLeft: "20px" }}><a href='javascript:void(0)'>
                                            <label style={{ paddingTop: "5px" }}>{t('Policies.SelectedRegion')}</label></a></span>
                                    </div>
                                </div>
                                <div style={{ marginTop: "70px" }}>
                                </div>
                                <table className="table table-bordered text-left mt-2" id="tableA" style={{ width: "600px" }}>
                                    <thead className="text-white rt-thead" style={{ backgroundColor: "var(--primary)" }}>
                                        <tr>
                                            <th id="name">
                                                {t('Policies.Attributes')}
                                            </th>
                                            <th id="label">
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style={{ width: "200px" }}>
                                                <input type="textbox" disabled="true" value="Cat I" style={{ textAlign: "center" }}></input>
                                            </td>
                                            <td>
                                                <button className="btn  btn-update btn-sm ml-2" onClick={(event) => clickProductPopup('Open', 'Cat-I')} style={{width: "50px"}}>{t('Select')}</button>
                                                <span style={{ marginLeft: "20px" }}><a href='javascript:void(0)' onClick={(event) => clickProductCountPopup('Open', "Cat-I")}>
                                                    <label style={{ paddingTop: "5px" }}>({attribute1Data.filter((e) => e.Selected === true).length}){t('Policies.CategoriesAdded')}</label></a></span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: "200px" }}>
                                                <input type="textbox" disabled="true" value="Cat II" style={{ textAlign: "center" }}></input>
                                            </td>
                                            <td>
                                                <button className="btn  btn-update btn-sm ml-2" onClick={(event) => clickProductPopup('Open', 'Cat-II')} style={{width: "50px"}}>{t('Select')}</button>
                                                <span style={{ marginLeft: "20px" }}><a href='javascript:void(0)' onClick={(event) => clickProductCountPopup('Open', "Cat-II")}>
                                                    <label style={{ paddingTop: "5px" }}>({attribute2Data.filter((e) => e.Selected === true).length}){t('Policies.CategoriesAdded')}</label></a></span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: "200px" }}>
                                                <input type="textbox" disabled="true" value="Cat III" style={{ textAlign: "center" }}></input>
                                            </td>
                                            <td>
                                                <button className="btn  btn-update btn-sm ml-2" onClick={(event) => clickProductPopup('Open', 'Cat-III')} style={{width: "50px"}}>{t('Select')}</button>
                                                <span style={{ marginLeft: "20px" }}><a href='javascript:void(0)' onClick={(event) => clickProductCountPopup('Open', "Cat-III")}>
                                                    <label style={{ paddingTop: "5px" }}>({attribute3Data.filter((e) => e.Selected === true).length}){t('Policies.CategoriesAdded')}</label></a></span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: "200px" }}>
                                                <input type="textbox" disabled="true" value="Cat IV" style={{ textAlign: "center" }}></input>
                                            </td>
                                            <td>
                                                <button className="btn  btn-update btn-sm ml-2" onClick={(event) => clickProductPopup('Open', 'Cat-IV')} style={{width: "50px"}}>{t('Select')}</button>
                                                <span style={{ marginLeft: "20px" }}><a href='javascript:void(0)' onClick={(event) => clickProductCountPopup('Open', "Cat-IV")}>
                                                    <label style={{ paddingTop: "5px" }}>({attribute4Data.filter((e) => e.Selected === true).length}){t('Policies.CategoriesAdded')}</label></a></span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div >


                {
                    showSelectedCustomerpopup === true ?
                        <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, marginRight: "10px", padding: "0" }}>
                            <div className="container" style={{ padding: "0" }}>
                                <div className="row">
                                    <div className="col-lg-12 pr-lg-2">
                                        <div className="card text-center card_border">
                                            <div className="card-header chart-grid__header">
                                                <div className="row">
                                                    <div className="col-md-10 text-left">
                                                        {t('Rebates.CustomerListing')}
                                                    </div>
                                                    <div className="col-md-2">

                                                        <button className="btn btn-outline-dark  btn-sm ml-1" onClick={(event) => clickCustomerPopup('Close')} type="submit" style={{width: "45px"}}>{t('Close')}</button>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className="row mt-1" id="Customer">
                                    <div className="col-lg-12 pr-lg-2">
                                        <div className="card text-center card_border">
                                            <div className="card-header chart-grid__header">

                                            </div>

                                            <div className='scrollbar-class'>
                                                <div className="container">
                                                    {showCustomerCountpopup == "Data" ?
                                                        <div>
                                                            <input type="text" className='form-control form-control-user' placeholder={`${t('Search')} ${t('Rebates.Customer')}`} onChange={(event) => customerSearch(event)} />
                                                        </div>
                                                        :
                                                        ""
                                                    }
                                                    {showCustomerCountpopup == "Data" ?
                                                        <table className="table table-bordered text-left mt-2 " id="Customer">
                                                            <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                                                                <tr>
                                                                    <th>
                                                                        <input checked={customercheckAll} type="checkbox" name="customer-check" id="option-all-customer" onChange={checkAllCustomer} /><label></label>
                                                                    </th>
                                                                    <th id="Name" style={{ width: "110px" }}>{t('Rebates.CustomerCode')} <span className='fa fa-filter' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} ></span></th>
                                                                    <th id="Label" style={{ maxWidth: "110px" }}>{t('Rebates.CustomerName')} <span className='fa fa-filter' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} ></span></th>
                                                                    <th id="Station" style={{ maxWidth: "110px" }}>{t('Rebates.Station')}	 <span className='fa fa-filter' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} ></span></th>
                                                                    <th id="State" style={{ maxWidth: "110px" }}>{t('Rebates.State')}	 <span className='fa fa-filter' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} ></span></th>
                                                                    <th id="Zone" style={{ maxWidth: "110px" }}>{t('Rebates.Zone')}  <span className='fa fa-filter' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} ></span></th>
                                                                </tr>
                                                            </thead>
                                                            < tbody >
                                                                {
                                                                    customerData.map((cslist, index) => (

                                                                        <tr>
                                                                            <td style={{ width: "30px" }}>
                                                                                <input type="checkbox"
                                                                                   
                                                                                    checked={cslist.Selected == null || cslist.Selected == false ? false : true} name="customer-check" value={cslist.Id} id={cslist.Id} onChange={(event) => OnChangeHandlerCustomer(event, cslist.label, cslist.Id, cslist.State)} />
                                                                            </td>
                                                                            <td style={{ width: "90px" }}>
                                                                                <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                                                                    title={cslist.Name}>{cslist.Name}</div>
                                                                            </td>
                                                                            <td style={{ width: "260px" }}>
                                                                                <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                                                                    title={cslist.label}>{cslist.label}</div>
                                                                            </td>
                                                                            <td style={{ width: "100px" }}>
                                                                                <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                                                                    title={cslist.Station}>{cslist.Station}</div>

                                                                            </td>
                                                                            <td style={{ width: "100px" }}>
                                                                                <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                                                                    title={cslist.State}>{cslist.State}</div>

                                                                            </td>
                                                                            <td style={{ width: "100px" }}>
                                                                                <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                                                                    title={cslist.Zone}>{cslist.Zone}</div>

                                                                            </td>

                                                                        </tr>
                                                                    ))
                                                                }
                                                            </tbody>
                                                        </table>
                                                        :
                                                        ""
                                                    }

                                                    {showCustomerCountpopup == "Count" ?
                                                        <table className="table table-bordered text-left mt-2 " id="Customer">
                                                            <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                                                                <tr>
                                                                    <th>
                                                                        <input type="checkbox" id="option-all-customer" disabled="true" /><label></label>
                                                                    </th>
                                                                    <th id="Name" style={{ width: "110px" }}>{t('Rebates.CustomerCode')} </th>
                                                                    <th id="Label" style={{ maxWidth: "110px" }}>{t('Rebates.CustomerName')} </th>
                                                                    <th id="Station" style={{ maxWidth: "110px" }}>{t('Rebates.Station')}	 </th>
                                                                    <th id="State" style={{ maxWidth: "110px" }}>{t('Rebates.State')}	 </th>
                                                                    <th id="Zone" style={{ maxWidth: "110px" }}>{t('Rebates.Zone')}  </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>

                                                                {
                                                                    customerData.filter((e) => e.Selected === true).map((cslist) => (
                                                                        <tr>
                                                                            <td style={{ width: "30px" }}>
                                                                                <input type="checkbox"
                                                                                  
                                                                                    checked={cslist.Selected == null || cslist.Selected == false ? false : true} name="customer-check" value={cslist.Id} id={cslist.Id} disabled="true" />
                                                                            </td>
                                                                            <td style={{ width: "90px" }}>
                                                                                <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                                                                    title={cslist.Name}>{cslist.Name}</div>
                                                                            </td>
                                                                            <td style={{ width: "260px" }}>
                                                                                <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                                                                    title={cslist.label}>{cslist.label}</div>
                                                                            </td>
                                                                            <td style={{ width: "100px" }}>
                                                                                <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                                                                    title={cslist.Station}>{cslist.Station}</div>

                                                                            </td>
                                                                            <td style={{ width: "100px" }}>
                                                                                <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                                                                    title={cslist.State}>{cslist.State}</div>

                                                                            </td>
                                                                            <td style={{ width: "100px" }}>
                                                                                <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                                                                    title={cslist.Zone}>{cslist.Zone}</div>

                                                                            </td>
                                                                        </tr>
                                                                    ))
                                                                }
                                                            </tbody>
                                                        </table>
                                                        :
                                                        ""
                                                    }
                                                </div>
                                            </div>
                                            <div>
                                               
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                        :
                        ""
                }

                {
                    showSelectedGroupCustomerpopup === true ?
                        <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, marginRight: "10px", padding: "0" }}>
                            <div className="container" style={{ padding: "0" }}>
                                <div className="row">
                                    <div className="col-lg-12 pr-lg-2">
                                        <div className="card text-center card_border">
                                            <div className="card-header chart-grid__header">
                                                <div className="row">
                                                    <div className="col-md-10 text-left">
                                                        {t('Policies.GroupCustomerListing')}
                                                    </div>
                                                    <div className="col-md-2">
                                                        <button className="btn btn-outline-dark  btn-sm ml-1" onClick={(event) => clickGroupCustomerPopup('Close')} type="submit" style={{width: "45px"}}>{t('Close')}</button>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className="row mt-1" id="Customer">
                                    <div className="col-lg-12 pr-lg-2">
                                        <div className="card text-center card_border">
                                            <div className="card-header chart-grid__header">

                                            </div>

                                            <div className='scrollbar-class'>
                                                <div className="container">
                                                    {showGroupCustomerCountpopup == "Data" ?
                                                        <div>
                                                            <input type="text" className='form-control form-control-user' placeholder={`${t('Search')} ${"Group Customer"}`} onChange={(event) => groupcustomerSearch(event)} />
                                                        </div>
                                                        :
                                                        ""
                                                    }
                                                    {showGroupCustomerCountpopup == "Data" ?
                                                        <table className="table table-bordered text-left mt-2 " id="Customer">
                                                            <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                                                                <tr>
                                                                    <th style={{ width: "30px" }}>
                                                                        <input checked={customergroupcheckAll} type="checkbox" name="customergroup-check" id="option-all-customer" onChange={checkAllGroupCustomer} /><label></label>
                                                                    </th>
                                                                    <th id="Name" style={{ width: "110px" }}>{t('Name')}<span className='fa fa-filter' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} ></span></th>
                                                                    <th id="Label" style={{ maxWidth: "110px" }}>{t('Label')}<span className='fa fa-filter' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} ></span></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>

                                                                {
                                                                    groupCustomerData.map((cslist, index) => (

                                                                        <tr>
                                                                            <td style={{ width: "30px" }}>
                                                                                <input type="checkbox"
                                                                                 
                                                                                    checked={cslist.Selected == null || cslist.Selected == false ? false : true} name="customer-check" value={cslist.Id} id={cslist.Id} onChange={(event) => OnChangeHandlerGroupCustomer(event, cslist.label, cslist.Id, cslist.State)} />
                                                                            </td>
                                                                            <td style={{ width: "90px" }}>
                                                                                <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                                                                    title={cslist.Name}>{cslist.Name}</div>
                                                                            </td>
                                                                            <td style={{ width: "260px" }}>
                                                                                <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                                                                    title={cslist.label}>{cslist.label}</div>
                                                                            </td>
                                                                        </tr>
                                                                    ))
                                                                }
                                                            </tbody>
                                                        </table>
                                                        :
                                                        ""
                                                    }
                                                    {showGroupCustomerCountpopup == "Count" ?
                                                        <table className="table table-bordered text-left mt-2 " id="Customer">
                                                            <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                                                                <tr>
                                                                    <th style={{ width: "30px" }}>
                                                                        <input type="checkbox" id="option-all-customer" disabled="true" />
                                                                    </th>
                                                                    <th id="Name" style={{ width: "110px" }}>{t('Name')}</th>
                                                                    <th id="Label" style={{ maxWidth: "110px" }}>{t('Label')}</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>

                                                                {
                                                                    groupCustomerData.filter((e) => e.Selected === true).map((cslist, index) => (

                                                                        <tr>
                                                                            <td style={{ width: "30px" }}>
                                                                                <input type="checkbox"
                                                                                   
                                                                                    checked={cslist.Selected == null || cslist.Selected == false ? false : true} name="customer-check" value={cslist.Id} id={cslist.Id} disabled="true" />
                                                                            </td>
                                                                            <td style={{ width: "90px" }}>
                                                                                <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                                                                    title={cslist.Name}>{cslist.Name}</div>
                                                                            </td>
                                                                            <td style={{ width: "260px" }}>
                                                                                <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                                                                    title={cslist.label}>{cslist.label}</div>
                                                                            </td>
                                                                        </tr>
                                                                    ))
                                                                }
                                                            </tbody>
                                                        </table>
                                                        :
                                                        ""
                                                    }
                                                </div>
                                            </div>
                                            <div>
                                               
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                        :
                        ""
                }


                {
                    showSelectedRegionpopup === true ?
                        <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, padding: "0", height: "72vh" }}>
                            <div className="container" style={{ padding: "0" }}>
                                <div className="row">
                                    <div className="col-lg-12 pr-lg-2">
                                        <div className="card text-center card_border">
                                            <div className="card-header chart-grid__header">
                                                <div className="row">
                                                    <div className="col-md-10 text-left">
                                                        {t('Rebates.RegionListing')}
                                                    </div>
                                                    <div className="col-md-2">

                                                        <button className="btn btn-outline-dark  btn-sm ml-1" onClick={(event) => clickRegionPopup('Close')} type="submit" style={{width: "45px"}}>{t('Close')}</button>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className="row mt-1">
                                    <div className="col-lg-12 pr-lg-2">
                                        <div className="card text-center card_border">
                                            <div className="card-header chart-grid__header">

                                            </div>
                                            <div style={{ minHeight: "58vh", overflowY: "scroll" }}>
                                                <div className="container">
                                                    <table className="table table-bordered text-left mt-2 " id="tableregion">
                                                        <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                                                            <tr>
                                                                <th>
                                                                    <input disabled="true" type="checkbox" name="option-all-region" />
                                                                </th>

                                                                <th id="Product">
                                                                    {t('Rebates.Region')}
                                                                </th>

                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                SalesLevelData.map((i) =>
                                                                    <tr key={i.Id}>
                                                                        <td><input type="checkbox"
                                                                           
                                                                            checked={i.Selected == null || i.Selected == false ? false : true} value={i.Id} id={i.Id} onChange={(event) => OnChangeHandlerRegion(event, i.label, i.Id)} /></td>
                                                                        <td>{i.label}</td>
                                                                    </tr>
                                                                )
                                                            }
                                                        </tbody>

                                                    </table>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        ""
                }

                {
                    showSelectedProductpopup === true ?
                        <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, padding: "0" }}>
                            <div className="container" style={{ padding: "0" }}>
                                <div className="row">
                                    <div className="col-lg-12 pr-lg-2">
                                        <div className="card text-center card_border">
                                            <div className="card-header chart-grid__header">
                                                <div className="row">
                                                    <div className="col-md-10 text-left">
                                                        {t('Policies.AttributeListing')}
                                                    </div>
                                                    <div className="col-md-2">
                                                        <button className="btn btn-outline-dark  btn-sm ml-1" onClick={(event) => clickProductPopup('Close', keyValue)} type="submit" style={{width: "45px"}}>{t('Close')}</button>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-1">
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-lg-12 pr-lg-2">
                                                <div className="card text-center card_border">
                                                    <div className="card-header chart-grid__header">
                                                    </div>
                                                    <div className='scrollbar-class'>
                                                        <div className="container">
                                                            <div style={{ paddingTop: "5px" }}>
                                                                <input type="text" className='form-control form-control-user' placeholder={`${t('SearchFor')} ${"Categories"}`} onChange={(event) => attributeSearch(event)} />
                                                            </div>
                                                            <div className='col-md-12'>
                                                                <table className="table table-bordered text-left mt-2 " id="tableA">
                                                                    <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                                                                        <tr>
                                                                            <th className="tableheader"><input checked={attbCheckAll} type="checkbox" name="attribute-check" id="option-all-customer" onChange={checkAllAttribute} /><label></label></th>
                                                                            <th id="Attribute1" >
                                                                                {keyValue}
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                    {keyValue === "Cat-I" ?
                                                                        <tbody>
                                                                            {attribute1Data.map((i, index) => (
                                                                                <tr>
                                                                                    <td><input type="checkbox" checked={i.Selected == null || i.Selected == false ? false : true} value={i.Id} id={i.Id} onChange={(event) => OnChangeHandlerAttribute(event, i.label, i.Id, "Cat-I")} ></input></td>
                                                                                    <td>
                                                                                        {i.Name}
                                                                                    </td>
                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                        : ""}
                                                                    {keyValue === "Cat-II" ?
                                                                        <tbody>
                                                                            {attribute2Data.map((i, index) => (
                                                                                <tr>
                                                                                    <td><input type="checkbox" checked={i.Selected == null || i.Selected == false ? false : true} value={i.Id} id={i.Id} onChange={(event) => OnChangeHandlerAttribute(event, i.label, i.Id, "Cat-II")} ></input></td>
                                                                                    <td>
                                                                                        {i.Name}
                                                                                    </td>
                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                        : ""}
                                                                    {keyValue === "Cat-III" ?
                                                                        <tbody>
                                                                            {attribute3Data.map((i, index) => (
                                                                                <tr>
                                                                                    <td><input type="checkbox" checked={i.Selected == null || i.Selected == false ? false : true} value={i.Id} id={i.Id} onChange={(event) => OnChangeHandlerAttribute(event, i.label, i.Id, "Cat-III")} ></input></td>
                                                                                    <td>
                                                                                        {i.Name}
                                                                                    </td>
                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                        : ""}
                                                                    {keyValue === "Cat-IV" ?
                                                                        <tbody>
                                                                            {attribute4Data.map((i, index) => (
                                                                                <tr>
                                                                                    <td><input type="checkbox" checked={i.Selected == null || i.Selected == false ? false : true} value={i.Id} id={i.Id} onChange={(event) => OnChangeHandlerAttribute(event, i.label, i.Id, "Cat-IV")} ></input></td>
                                                                                    <td>
                                                                                        {i.Name}
                                                                                    </td>
                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                        : ""
                                                                    }
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        ""
                }
                {
                    showProductCountpopup === true ?
                        <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, padding: "0" }}>
                            <div className="container" style={{ padding: "0" }}>
                                <div className="row">
                                    <div className="col-lg-12 pr-lg-2">
                                        <div className="card text-center card_border">
                                            <div className="card-header chart-grid__header">
                                                <div className="row">
                                                    <div className="col-md-10 text-left">
                                                        {t('Policies.AttributeListing')}
                                                    </div>
                                                    <div className="col-md-2">
                                                        <button className="btn btn-outline-dark  btn-sm ml-1" onClick={(event) => clickProductCountPopup('Close', "")} type="submit" style={{width: "45px"}}>{t('Close')}</button>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className="row mt-1">
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-lg-12 pr-lg-2">
                                                <div className="card text-center card_border">
                                                    <div className="card-header chart-grid__header">
                                                    </div>
                                                    <div className='scrollbar-class'>
                                                        <div className="container">
                                                            <div className='col-md-12'>
                                                                <table className="table table-bordered text-left mt-2 " id="tableA">
                                                                    <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                                                                        <tr>
                                                                            <th className="tableheader"><input type="checkbox" disabled="true" ></input></th>
                                                                            <th id="Attribute1" >
                                                                                {countKeyValue}
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                    {countKeyValue === "Cat-I" ?
                                                                        <tbody>
                                                                            {attribute1Data.filter((e) => e.Selected === true).map((i, index) => (
                                                                                <tr>
                                                                                    <td>
                                                                                        <input type="checkbox"
                                                                                            checked={i.Selected == null || i.Selected == false ? false : true} name="customer-check" value={i.Id} id={i.Id} disabled="true" />
                                                                                    </td>
                                                                                    <td>
                                                                                        {i.Name}
                                                                                    </td>
                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                        : ""}
                                                                    {countKeyValue === "Cat-II" ?
                                                                        <tbody>
                                                                            {attribute2Data.filter((e) => e.Selected === true).map((i, index) => (
                                                                                <tr>
                                                                                    <td>
                                                                                        <input type="checkbox"
                                                                                            checked={i.Selected == null || i.Selected == false ? false : true} name="customer-check" value={i.Id} id={i.Id} disabled="true" />
                                                                                    </td>
                                                                                    <td>
                                                                                        {i.Name}
                                                                                    </td>
                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                        : ""}
                                                                    {countKeyValue === "Cat-III" ?
                                                                        <tbody>
                                                                            {attribute3Data.filter((e) => e.Selected === true).map((i, index) => (
                                                                                <tr>
                                                                                    <td>
                                                                                        <input type="checkbox"
                                                                                            checked={i.Selected == null || i.Selected == false ? false : true} name="customer-check" value={i.Id} id={i.Id} disabled="true" />
                                                                                    </td>
                                                                                    <td>
                                                                                        {i.Name}
                                                                                    </td>
                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                        : ""}
                                                                    {countKeyValue === "Cat-IV" ?
                                                                        <tbody>
                                                                            {attribute4Data.filter((e) => e.Selected === true).map((i, index) => (
                                                                                <tr>
                                                                                    <td>
                                                                                        <input type="checkbox"
                                                                                            checked={i.Selected == null || i.Selected == false ? false : true} name="customer-check" value={i.Id} id={i.Id} disabled="true" />
                                                                                    </td>
                                                                                    <td>
                                                                                        {i.Name}
                                                                                    </td>
                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                        : ""
                                                                    }
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        ""
                }

                <Modal show={closeModal}
                    size="sm"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <Modal.Body style={{ "textAlign": 'center', fontFamily: 'Segoe UI' }}>{t('Policies.YourChangesWillBeLost!AreYouSureYouWantToClose?')}</Modal.Body>
                    <Modal.Footer>
                        <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black',width: "45px" }} size='sm' onClick={() => closeHandler("Close")}>
                            {t('Close')}
                        </Button>
                        <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black',width: "50px" }} size='sm' onClick={() => closeHandler("Cancel")}>
                            {t('Cancel')}
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={showDeleteModal}
                    size="sm"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <Modal.Body style={{ "textAlign": 'center' }}>{t('Rebates.AreYouSureYouWantToDelete')}</Modal.Body>
                    <Modal.Footer>
                        <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black',width: "45px"}} size='sm' onClick={() => checkDelete('Close')}>
                            {t('Close')}
                        </Button>
                        <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black',width: "50px" }} size='sm' onClick={() => checkDelete('Proceed')}>
                            {t('Delete')}
                        </Button>
                    </Modal.Footer>
                </Modal>
                <CustomErrorPopup ErrorMessage={errorMessage} show={showErrorModal} ErrorStatus={errorStatus}
                    onHide={() => setShowErrorModal(false)}></CustomErrorPopup>
            </div >
        </>
    )
}
export default CreateNewCategoryExceptionPolicy