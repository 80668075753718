import React from 'react';
import { Button, Modal, ModalFooter, ModalHeader } from 'react-bootstrap';
import classes from './CustomErrorPopup.module.css';
import { useTranslation } from "react-i18next";

const CustomErrorPopup = props => {

    const [t, i18n] = useTranslation('translation');


    return (
        <Modal className={classes['Modal-div']}
            {...props}
            size="md" aria-labelledby="contained-modal-title-vcenter" centered>
            <ModalHeader className={classes.header}>
                <div className='container text-right'>
                <h6 className={classes['title']}><i class="fa fa-close" onClick={props.onHide}></i></h6>
                </div>
            </ModalHeader>
            <Modal.Body className={classes['Modal-body']}>
                <h6 className={classes['Error-Msg']}>{props.ErrorMessage}</h6>
            </Modal.Body>
            {/* <Modal.Footer>
                <Button className={classes['Footer-Buttons']} onClick={props.onHide}>Close</Button>
            </Modal.Footer> */}
            <ModalFooter className={classes['Modal-Footar']}>
                <Button className={classes['Footer-Buttons']} onClick={props.onHide}>{t('Ok')}</Button>
            </ModalFooter>
        </Modal>
    );
}
export default CustomErrorPopup;