import React, { Component } from 'react' 
import { useNavigate ,useParams } from 'react-router-dom';
import CreateNewSettlementMethodPolicy from '../../../Components/PoliciesComponent/SettlementMethodPolicyComponent/CreateNewSettlementMethodPolicy';

function NewSettlementMethodPolicy(props){
    let navigate = useNavigate();
    const params = useParams();
     
     return (  
         <div> 
             <CreateNewSettlementMethodPolicy {...props}  navigate={navigate} id={params.id} name={params.name} />
         </div>
     );  

}

export default NewSettlementMethodPolicy