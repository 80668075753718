import { Link, useLocation } from "react-router-dom"
import React, { useState, useEffect } from 'react'
import { Form, FormGroup, Col, Row, Button, InputGroup, Collapse, Modal } from 'react-bootstrap';
import classes from '../RebateProgramComponent/CreateRebateComponent/Form.module.css'
import Service from '../../Services/Service';
import 'react-multiple-select-dropdown-lite/dist/index.css'
import Spinner from 'react-bootstrap/Spinner';
import $ from 'jquery';
import SessionManage from "../../SessionManagement/SessionManage";
import { useTranslation } from "react-i18next";

function NewGroupComponent(props) {
    const [t, i18n] = useTranslation('translation');
    const location = useLocation();
    const [showspinner, setshowspinner] = useState(false);
    const [IsSubmit, setIsSubmit] = useState(false);
    const [status, setStatus] = useState(false);
    const [closeModal, setCloseModal] = useState(false);
    const [handleChange, setHandleChange] = useState(false);
    const [editPerms, setEditPerms] = useState([]);
    const [userPerms, setuserPermission] = useState([]);
    let loggedInUser = SessionManage.getuserid();
    const [isLocked, setIsLocked] = useState(false);
    const [lockedBy, setLockedBy] = useState('');
    let disable = false;
    const [inputField, setInputField] = useState({
        Id: props.id,
        Label: '',
        Name: '',
        CreatedUserId: loggedInUser,
        LastModifiedUserId: loggedInUser,
        TableName: '',
        CreatedDate: '',
        LastModifiedDate: '',
        LastModifiedUser_Name: '',

    })
    useEffect(() => {
        getUserPermission(location.pathname);
        getUserDatabyId("Groups/GetById/", props.id);
    }, [0])
    const getUserPermission = (pathname) => {
        Service.GetUserPermission(pathname).then((res) => {
    
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null' && res.data !== '') {
                    if (typeof res.data.DataList !== 'undefined') {
                        var permission = res.data.DataList;
                        if (typeof permission !== 'undefined') {
                            if (permission.filter((item) => item.ActionName === 'Edit' || item.ActionName === 'Submit').length == 0) {
                                setEditPerms(false);
                            } else {
                                setEditPerms(true);
                            }
                        }
                        else {
                            setEditPerms(false);
                        }
                    }
                }
            }
        });
    }
    const getUserDatabyId = (method, id) => {
        Service.LockUnLockEntity('Configurationprop/LockEntity', "Group", props.id, loggedInUser).then((res1) => {
            if (typeof res1 !== 'undefined') {
                Service.getdatabyId(method, id).then((res) => {
                    if (typeof res !== 'undefined') {
                        if (typeof res.data !== 'undefined' && res.data !== 'null') {
                            if (res.status === 200) {
                                if (res.data !== 'null' && res.data !== 'undefined') {
                                    setInputField(res.data);
                                    setStatus(true)
                                    setLockedBy(res.data.LockedBy);
                                    setIsLocked(res.data.Locked);
                                    if ((res.data.Locked === true && res.data.LockedBy.toString() !== loggedInUser)) {
                                        $("#libread").html("Edit " + `Locked By ${res.data.LockedByUserName}`);
                                    }
                                }
                            }
                        }
                    }
                });
            }
        });
    }

    const submitButton = (e) => {
        e.preventDefault();
        setIsSubmit(true);
        setshowspinner(true);
        let group = inputField;
        group.CreatedUserId = Service.getUserId();
        group.LastModifiedUserId = Service.getUserId();
        Service.createorupdate("Groups/Updates", group).then(res => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    setshowspinner(false);
                    setIsSubmit(false);
                    setHandleChange(false)
                    Service.LockUnLockEntity('Configurationprop/UnLockEntity', "Group", props.id, loggedInUser).then((res) => {
                    });
                    props.navigate('/dataentitlement');
                }
            }
        });
    }
    const inputsHandler = (e) => {
     
        setHandleChange(true)
        const { name, value } = e.target;
        setInputField((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    }
    const closeHandler = (flag) => {
        if (flag == "Open" && handleChange == true) {
            setCloseModal(true)
        }
        if (flag == "Open" && handleChange == false) {
            Service.LockUnLockEntity('Configurationprop/UnLockEntity', "Group", props.id, loggedInUser).then((res) => {
            });
            props.navigate("/dataentitlement")
        }
        if (flag == "Close") {
            Service.LockUnLockEntity('Configurationprop/UnLockEntity', "Group", props.id, loggedInUser).then((res) => {
            });
            props.navigate("/dataentitlement")
        }
        if (flag == "Cancel") {
            setCloseModal(false)
        }

    }
    if (isLocked === false || (isLocked === true && lockedBy.toString() === loggedInUser)) {
        disable = false;
    }
    else {
        disable = true;
    }
    window.onbeforeunload = function () {
        unlockEntity();
        
    };

    const unlockEntity = () => {
        Service.LockUnLockEntity('Configurationprop/UnLockEntity', "Group", props.id, loggedInUser).then((res) => {
        });

    }
    const class_textbox = `col-md-3 mt-3 textbox-padding  ${editPerms == false ? "disabledClass" : ""}`;
    return (
        <>
            <div className="container-fluid content-top-gap">

                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb my-breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">{t('Home.title')}</Link></li>
                        <li className="breadcrumb-item"><Link to="/admin">{t('Menu.Admin')}</Link></li>
                        <li className="breadcrumb-item active" aria-current="page"><Link to="/dataentitlement">{t('Menu.Data Entitlement')}</Link></li>
                        <li className="breadcrumb-item active" id="libread" aria-current="page">{t('Edit')}</li>
                    </ol>
                </nav>

                <div className="row mt-3" id="rebateProgramdiv">
                    <div className="col-lg-12 pr-lg-2">
                        <div className="card text-center card_border">
                            <div className="card-header chart-grid__header">
                                <div className="row">
                                    <div className="col-md-7 text-left">
                                        {`${t('Menu.Data Entitlement')} ${t('Edit')}`}
                                    </div>
                                    <div className="col-md-5">

                                        {editPerms == false ? "" : (isLocked === true && lockedBy.toString() === loggedInUser) ? <button disabled={IsSubmit} class="btn btn-sm btn-update ml-2" style={{ float: "right", width: "50px" }} onClick={submitButton} type="submit">{showspinner === true ? <Spinner animation="border" size="sm" /> : null} {t('Submit')}</button>
                                            : ''
                                        }
                                        <button className="btn btn-sm btn-update ml-2" id="close" style={{ width: "45px", backgroundColor: "buttonface", float: "right" }} onClick={(event) => closeHandler("Open")} >{t('Close')}</button>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body" style={{ minHeight: "60vh" }}>
                                <div className="container">
                                    <div className="row">
                                        <label className="col-md-2 text-left">{t('Policies.Name')}<span className={classes.mandatory}>*</span></label>
                                        <input name="Name" type="text" value={inputField.Name} style={{ fontStyle: "normal" }} className="col-md-2 mt-2 textbox-padding form-control" disabled={status} />
                                        <div className="col-md-2">
                                        </div>
                                    </div>
                                    <div className="row">
                                        <label className="col-md-2 text-left mt-2">{t('Policies.Label')}<span className={classes.mandatory}>*</span></label>
                                        <textarea name="Label" value={inputField.Label} onChange={inputsHandler} style={{ fontSize: "0.75rem", width:"15rem" }} className="form-control mt-2" disabled={disable} />
                                        <div className="col-md-2">
                                        </div>
                                    </div>

                                </div>


                            </div>

                        </div>
                    </div>
                </div>
                <Modal show={closeModal}
                    size="sm"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <Modal.Body style={{ "textAlign": 'center', fontFamily: 'Segoe UI' }}>{t('DefaultValue.YourChangesWillBeLost!AreYouSureYouWantToClose?')}</Modal.Body>
                    <Modal.Footer>
                        <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "45px" }} size='sm' onClick={() => closeHandler("Close")}>
                            {t('Close')}
                        </Button>
                        <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "50px" }} size='sm' onClick={() => closeHandler("Cancel")}>
                            {t('Cancel')}
                        </Button>
                    </Modal.Footer>
                </Modal>
              
            </div>
        </>
    )
}
export default NewGroupComponent