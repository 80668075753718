import $, { event } from 'jquery';
import React, { useRef, useState, forwardRef, useEffect } from 'react';
import { Form, Dropdown, Button, InputGroup, FormControl, Modal } from 'react-bootstrap';
import Service from '../../../Services/Service';
import classes from '../../RebateProgramComponent/CreateRebateComponent/Form.module.css'
import { Link, Navigate, useLocation } from 'react-router-dom';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import Multiselect from 'multiselect-react-dropdown';
import { CSVLink } from "react-csv";
import SessionManage from '../../../SessionManagement/SessionManage';
import Select from "react-select";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css'
import dateFormat from 'dateformat';
import _ from "lodash";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Validation from '../../../Services/Validation';
import CustomErrorPopup from '../../../UI/CustomErrorPopup';
import WorkflowApprovers from './WorkflowApprovers';
import ClaimWorkflowApprovers from './WorkflowApprovers';
import { useTranslation } from "react-i18next";
import qs from 'qs';

function CreateNewShipAndDebit(props) {


  const [totalAmount, setTotalAmount] = useState("");
  const [totalSum, setTotalSum] = useState("");
  const [rebateType, setRebateType] = useState("");
  const [commentsForApproved, setCommentsForApproved] = useState("")
  const [showDeniedModal, setShowDeniedModal] = useState(false)
  const [showApprovedModal, setShowApprovedModal] = useState(false)
  const [commentsForDenied, setCommentsForDenied] = useState("")
  const [closeModal, setCloseModal] = useState(false);
  const [changeHandle, setChangeHandle] = useState(false);
  const [showRebateRulesPopup, setShowRebateRulesPopup] = useState(false)
  const [rebateRulesData, setRebateRulesData] = useState([])
  const [sidebarHeight, setsidebarHeight] = useState('');
  const [BgColor, setBgColor] = useState("")
  const [showspinner, setshowspinner] = useState('');
  const [showWorkflowAlert, setShowWorkflowAlert] = useState(false)

  var currentDate = new Date();
  var year = currentDate.getFullYear();
  var month = currentDate.getMonth();
  var day = currentDate.getDate();
  const location = useLocation();

  let loggedInUser = sessionStorage.getItem("id")
  const [editPerms, setEditPerms] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showShipWorkflow, setshowShipWorkflow] = useState('');
  const [sidebarwidth, setsidebarwidth] = useState('');
  const [showWorkflow, setshowWorkflow] = useState('');
  const [defaultCurrency, setDefaultCurrency] = useState('');
  const [isLocked, setIsLocked] = useState(false);
  const [lockedBy, setLockedBy] = useState('');
  const [agreementIds, setAgreementIds] = useState([]);
  const [MaterialIds, setMaterialIds] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [selectedAgreement, setSelectedAgreement] = useState([]);
  const [selectedprogramLine, setselectedprogramLine] = useState([]);
  const [currentClaimAgreement, setCurrentClaimAgreement] = useState([]);
  const navigate = useNavigate();
  const [agreementType, setAgreementType] = useState('');
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorStatus, setErrorStatus] = useState("Error");
  const [errorPopup, setErrorPopup] = useState(false);
  const [loadedFrom, setLoadedFrom] = useState('')
  const [t, i18n] = useTranslation('translation');
  const [rebateTypeData, setRebateTypeData] = useState([]);
  const [disableHeaderField, setdisableHeaderField] = useState(false);
  const [showSubmitModal, setShowSubmitModal] = useState(false);
  const [commentsForSubmit, setCommentsForSubmit] = useState("");
  const [workflowchart, setWorkflowchart] = useState([]);
  const [showDuplicateRecModal, setShowDuplicateRecModal] = useState(false);
  const [workflowData, setWorkflowData] = useState([]);
  const [updateNav, setUpdateNav] = useState('/claims');
  const [programLine, setprogramLine] = useState(false);
  const [programLineIds, setprogramLineIds] = useState([]);
  const [showvomumetierdeails, setshowvomumetierdeails] = useState(false);
  const [volumerierdata, setvolumerierdata] = useState([]);
  const [file, setFile] = useState([]);
  let disableFields = false;
  let userType = SessionManage.getUsersType();


  const [shipList, setShipList] = useState([{
    Id: 0,
    ClaimItemId: '',
    Material: "",
    ProductId: 0,
    Product: '',
    ListPrice: 0,
    ContractTypeId: 0,
    ContractType: '',
    ContractListPrice: '',
    ClaimQty: '',
    ClaimRevenue: 0,
    TransactionDate: '',
    ClaimAmount: '',
    InvoiceNumber: '',
    ContractPrice: '',
    FilePath: '',
    rebateTypeId: '',
    selectedRebateType: '',
    sdAgreementItemId: 0,
    specialAgreementItemId: 0,
    MaterialName: ''
  },]);

  useEffect(() => {

    getRebateTypeData("rebatetype")

    setshowspinner(true)
    if (typeof props.id === 'undefined' || props.id === '0') {
      getUserPermission('Create Claim');
      setLockedBy(loggedInUser);
      setIsLocked(true);
    }
    else if (typeof props.id !== 'undefined' && typeof props.name !== 'undefined' && props.id != '0' && (props.name.toLowerCase() == 'fromapproval' || props.name.toLowerCase() == 'fromapprovalcompleted'))
      getUserPermission('Claim Workflow');
    else if (typeof props.id !== 'undefined' && props.id != '0')
      getUserPermission('Claim List');


    if (typeof props.name !== 'undefined')
      setLoadedFrom(props.name.toLowerCase())

    if (typeof props.id != 'undefined' && props.id != 0) {
      bindClaim(props.id);
    }
    else {
      setLockedBy(loggedInUser);
      setIsLocked(true);
    }
  }, [])





  const bindClaim = (id) => {

    Service.GetWithIdAndUser('SDClaim/GetByClaimID', id, 0).then((res) => {
      if (typeof res !== 'undefined') {
        if (res.data.DataList !== 'null' && typeof res.data !== 'undefined') {
          var data = res.data.DataList;

          setInputField(data);
          setAgreementType(data.AgreementType)
          setAgreementIds(data.lstAgreementView);
          setvolumerierdata(data.getProductList.volumetierDetailView)

          if (((props.name == 'fromApproval' || props.name == 'fromapprovalcompleted') && data.Status == 'InReview' && data.Locked == false) || (data.Status == 'New' && data.Locked == false)) {
            Service.LockUnLockEntity('Configurationprop/LockEntity', "Claim", id, loggedInUser).then((res1) => {
              if (typeof res1 !== 'undefined') {
                setLockedBy(loggedInUser)
                setIsLocked(true)
              }
            })
          }
          else {
            setLockedBy(data.LockedBy);
            setIsLocked(data.Locked);
          }

          if ((data.Locked == true && data.LockedBy?.toString() !== loggedInUser)) {
            $("#libread").html(data.ClaimId + " " + `Locked By ${data.LockedByUsername}`);

          }
          else {
            $("#libread").html(data.ClaimId);
          }
          setSelectedAgreement(data.AgreementId_Id);
          setselectedprogramLine(data.ProgramLineId);
          setCurrentClaimAgreement(data.AgreementId_Id);

          for (var j = 0; j <= data.lstClaimItemView.length - 1; j++) {
            data.lstClaimItemView[j]["TransactionDate"] = new Date(data.lstClaimItemView[j]["TransactionDate"]);
          }

          let totalValue = 0
          data.lstClaimItemView.forEach(element => {
            if (element.RebateType == "Revenue") {
              setRebateType(element.RebateType)
              totalValue = totalValue + element.ClaimRevenue
            }
            else {
              setRebateType(element.RebateType)
              totalValue = totalValue + element.ClaimQty
            }
          });
          setTotalSum(totalValue)

          setTotalAmount(data.TotalClaimAmount);
          setShipList(data.lstClaimItemView);
          setMaterialIds(data.getProductList.sdagreementItemsList);
          if (data.AgreementType == 'ClaimAgreement') {
            setprogramLine(true);
            if (data.lstAgreementView.length > 0) {
              for (var j = 0; j < data.lstAgreementView.length; j++) {
                if (data.AgreementId_Id == data.lstAgreementView[j].value) {
                  setprogramLineIds(data.lstAgreementView[j].programLineIds);
                  break;
                }
              }
            }

          }


          if (props.name != '' && props.name != undefined) {
            if (props.name.toLocaleLowerCase() == 'fromapproval') {
              $("#headertxt").html('Claim Approval- Pending Action');
              setUpdateNav('/claims/approvals/pending')
            }
            else if (props.name.toLocaleLowerCase() == 'fromapprovalcompleted') {
              $("#headertxt").html('Claim Approval- Workflow Completed');
              setUpdateNav('/claims/approvals/archive')
            }
          }
          else {
            $("#headertxt").html(t('Menu.Claim List'));
            setUpdateNav('/claims')
          }

          if (data.Status.toLowerCase() == 'new') {
            insertWorkflowDetails(data.Id, loggedInUser)
          }

          if (data.Status.toLowerCase() == "new") {

            if (data.Locked == false || (data.Locked == true && data.LockedBy?.toString() == loggedInUser)) {
              setdisableHeaderField(false)
            }
            else {
              setdisableHeaderField(true)
            }
          }
          else {
            setdisableHeaderField(true)
          }




        }
      }
    }

    );


  }


  const unLockEntity = () => {
    Service.LockUnLockEntity('Configurationprop/UnLockEntity', "Claim", props.id, loggedInUser).then((res) => {
    });
  }

  const [inputField, setInputField] = useState({
    Id: 0, AgreementId: '', Prefix: '', AgreementType: '', ClaimDesc: '', ClaimId: '', EndcustomerName: '', DistributorName: '', DistributorId: '', EndcustomerId: '', ValidityFrom: new Date(), ValidityTo: (new Date(year + 1, month, day)),
    SettlementMethodId: '', SettlementMethodName: '', CurrencyId: '', CurrencyName: '', Currency: '', Status: '', LastModifiedUserId: '',
    CreatedUserId: '', CreatedDate: null, CreatedUserName: '', LockedBy: 0, LastModifiedDate: '',
    Deleted: false, RevisionOf: '', CopyOf: '', Locked: true, AgreementId_Id: '',
    ConcilationId: 0, ConcilationStatus: '', lstClaimItemView: [], AttachPOS: null, ProgramLineId: ''

  })

  if (inputField.Status != null && inputField.Status != '' && inputField.Status != "New") {
    disableFields = true;
  }
  const inputsHandler = (e) => {
    setChangeHandle(true)
    const { name, value } = e.target;
    setInputField((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    var f = inputField;
  }
  const OnChangeHandlerDate = (e, name) => {
    setChangeHandle(true)
    if (e) {
      if (name === 'ValidityFrom')
        setInputField({ ...inputField, ValidityFrom: e })
      if (name === 'ValidityTo')
        setInputField({ ...inputField, ValidityTo: e })
    }
  };

  const clickShowShipWorkflow = (val) => {
    setshowShipWorkflow(true);
    setsidebarwidth("70vw");
    GetWorkflowDetails()
    if (val === "Ship") {
      setshowWorkflow(true);
    }
  }

  const insertWorkflowDetails = (id, userId,) => {
    Service.workflowDataClaim('SDClaim/InsertWorkflow', id, userId).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {

          if (res.status == 200) {
            if (res.data !== 'null' && res.data !== 'undefined') {

            }
          }
        }
      }
    })
  }

  const clickCloseShipWorkflow = () => {
    setshowShipWorkflow(false);
  }
  const closeClaim = (flag) => {
    unLockEntity()
    if (flag === "Open" && changeHandle == true) {
      setCloseModal(true)
    }
    if (flag === "Open" && changeHandle == false) {
      setCloseModal(false)
      navigate("/claims")
    }
    if (flag === "Close" && changeHandle == true) {
      setCloseModal(false)
      navigate("/claims")
    }
    if (flag === "Cancel" && changeHandle == true) {
      setCloseModal(false)
    }

  }
  const closeAproveDeny = () => {
    navigate("/claims")
    if (loadedFrom === "fromapproval") {
      unLockEntity()
      navigate("/claims/approvals/pending")
    } else {
      unLockEntity()
      navigate("/claims/approvals/archive")
    }
  }
  const AddVolume = () => {
    setChangeHandle(true)
    const _item = {
      Id: 0,
      ClaimItemId: '',
      Material: "",
      ProductId: 0,
      Product: '',
      Listprice: 0,
      ContractTypeId: 0,
      ContractType: '',
      ContractListPrice: '',
      ClaimQty: '',
      TransactionDate: '',
      ClaimAmount: '',
      InvoiceNumber: '',
      ContractPrice: '',

      sdAgreementItemId: 0,
      specialAgreementItemId: 0,
      RebateType: inputField.RebateType,
    };
    setShipList([...shipList, _item]);
  }




  const rebateRulesPopup = (data, flag) => {
    if (flag == 'Open') {
      setshowspinner(false)
      setShowRebateRulesPopup(true)

      setsidebarwidth("60vw")
      setsidebarHeight("20vh")
      setBgColor("white")
    }
    if (flag == 'Close') {
      setShowRebateRulesPopup(false)
      setRebateRulesData([])
    }
  }


  const getUserPermission = (pathname) => {
    Service.GetUserPermissionNew(pathname).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null' && res.data !== '') {
          if (typeof res.data.DataList !== 'undefined') {
            var permission = res.data.DataList;
            if (typeof permission !== 'undefined') {
              if (permission.filter((item) => item.ActionName === 'Edit' || item.ActionName === 'Submit').length == 0) {
                setEditPerms(false);
              } else {
                setEditPerms(true);
              }
            }
            else {
              setEditPerms(false);
            }
          }
        }
      }
    });
  }
  const clearlist = () => {
    setChangeHandle(true)
    let inputField = {
      Id: 0, AgreementId: '', AgreementType: '', ClaimDesc: '', ClaimId: '', EndcustomerName: '', DistributorName: '', DistributorId: '', EndcustomerId: '', ValidityFrom: new Date(), ValidityTo: (new Date(year + 1, month, day)), SettlementMethodId: '', SettlementMethodName: '', CurrencyId: '', CurrencyName: '', Currency: '', Status: '', LastModifiedUserId: '', CreatedUserId: '', CreatedDate: null, CreatedUserName: '', LockedBy: 0, LastModifiedDate: '', Deleted: false, RevisionOf: '',
      CopyOf: '', Locked: true, AgreementId_Id: '', lstClaimItemView: [], AttachPOS: null, Justification: '', RebateType: ''
    }
    setInputField(inputField);

    let shiplist = {
      Id: 0,
      ClaimItemId: '',
      ProductId: 0,
      Product: '',
      Listprice: 0,
      ContractTypeId: 0,
      ContractType: '',
      ContractListPrice: '',
      ClaimQty: '',
      ClaimRevenue: 0,
      TransactionDate: '',
      ClaimAmount: '',
      InvoiceNumber: '',
      ContractPrice: '',
      CreatedDate: new Date(),
      sdAgreementItemId: 0,
      specialAgreementItemId: 0,
      FilePath: '',

    }
    setShipList([shiplist]);
    setMaterialIds([]);
  }



  const workflowAlert = (e, flag) => {
    if (flag == 'open') {
      if (workflowchart == true) {
        setShowWorkflowAlert(true)
      }
      else {
        showSubmitDialog(e, 'showSubmitDialog')
      }
    }
    else if (flag == 'close') {
      setShowWorkflowAlert(false)
      showSubmitDialog(e, 'showSubmitDialog')
    }
  }

  const getAgreements = (type) => {
    clearlist();
    setprogramLine(false);

    if (type == "ClaimAgreement") {
      setprogramLine(true);
    }



    Service.getdatabyDistId('SDClaim/GetAgreementIDs', type).then((res) => {
      if (typeof res !== 'undefined') {

        if (res.data.DataList !== 'null' && res.data.DataList !== 'undefined') {
          var data = res.data.DataList;

          setAgreementIds(data)

        }
      }
    });

  }

  const RemoveVolume = (idx) => {
    setChangeHandle(true)
    setShipList(shipList.filter((item, index) => index !== idx));
  }

  const OnchangeProgramlineId = (e) => {

    setselectedprogramLine(e.value);
    inputField.ProgramLineId = e.value;
    const list = [...shipList];

    list[0]["RebateType"] = e.RebateType;
    list[0]["ContractTypeId"] = e.ContractTypeId;
    inputField.RebateType = e.RebateType;

    Service.getdatabyDistDetails('SDClaim/GetProgramLineDetatil', e.value, agreementType).then((res) => {
      if (typeof res !== 'undefined') {

        if (res.data !== 'null' && res.data !== 'undefined') {
          setMaterialIds(res.data.sdagreementItemsList)
          setvolumerierdata(res.data.volumetierDetailView)

        }

      }

    })


  }

  const OnchangeAgreement = (e, flag) => {

    clearlist();

    if (currentClaimAgreement === e.Id) {
      bindClaim(props.id);
    }


    setSelectedAgreement(e.value)
    if (agreementType != "ClaimAgreement") {
      Service.getdatabyDistDetails('SDClaim/GetAgreementDetails', e.Id, agreementType).then((res) => {

        if (typeof res !== 'undefined') {
          if (res.data !== 'null' && res.data !== 'undefined') {
           
            var data = res.data;
            data.Status = "New";


            setInputField(data);
            if (res.data.sdagreementItemsList != null) {
              setMaterialIds(res.data.sdagreementItemsList)

            }


            setprogramLineIds(data.programLineIds);
          }
        }
      });
    }
    else {

      e.Status = "New";

      setInputField(e);
      setprogramLineIds(e.programLineIds);
      inputField.AgreementId_Id = e.value;

    }
  }



  const handleProductChange = (e, index, names) => {

    const list = [...shipList];
    setChangeHandle(true)
    setSelectedProduct(e)
    list[index]["ProductId"] = e.ProductId;
    if (agreementType != "ClaimAgreement") {
      Service.getdatabyProduct('SDClaim/GetAgreementItemDetails', selectedAgreement, e.ProductId, agreementType).then((res) => {
        if (typeof res !== 'undefined') {
          if (res.data !== 'null' && res.data !== 'undefined') {
            var data = res.data;


            list[index]["Id"] = 0;
            list[index]["ClaimItemId"] = "";
            list[index]["ProductId"] = res.data.ProductId;
            list[index]["Product"] = res.data.ProductName;
            list[index]["MaterialName"] = res.data.MaterialName;
            list[index]["Listprice"] = parseFloat(res.data.Listprice).toFixed(2);
            list[index]["ContractTypeId"] = res.data.ContractTypeId;
            list[index]["ContractType"] = res.data.ContractTypeName;
            list[index]["ContractListPrice"] = res.data.ContractListPrice;
            list[index]["ContractPrice"] = res.data.ContractPrice;
            list[index]["ClaimQty"] = '';
            list[index]["ClaimAmount"] = '';
            if (agreementType == "Ship & Debit") {
              list[index]["sdAgreementItemId"] = res.data.Id;
            }
            else {
              list[index]["specialAgreementItemId"] = res.data.Id;
            }


            list[index]["ThresholdQty"] = res.data.ThresholdQty;
            list[index]["Thresholdcond"] = res.data.Thresholdcond;
            list[index]["ThresholdRevenue"] = res.data.ThresholdRevenue;
            list[index]["ThresholdAmount"] = res.data.ThresholdAmount;
            list[index]["RebateType"] = res.data.RebateType;
            list[index]["InvoiceNumber"] = '';
            list[index]["FilePath"] = null;
            list[index]["TransactionDate"] = '';

            setShipList(list)
          }
        }
      })
    }
    else {
      list[index]["Id"] = 0;
      list[index]["ClaimItemId"] = "";
      list[index]["ProductId"] = e.ProductId;
      list[index]["Product"] = e.ProductName;
      list[index]["MaterialName"] = e.MaterialName;

      list[index]["ClaimQty"] = '';
      list[index]["ClaimAmount"] = '';
      setShipList(list)
    }
  }



  const getRebateTypeData = (method) => {
    Service.getAlldata(method).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {
            if (res.data !== 'null' && res.data !== 'undefined') {

              let data = []
              res.data.map((each) => {
                if (each.Name.toLowerCase() == 'volume' || each.Name.toLowerCase() == 'revenue') {
                  data.push(each)
                }
              })
              setRebateTypeData(data)
            }
          }
        }
      }

    });
  }


  const OnChangeHandlerTransactionDate = (event, index, flag) => {


    setChangeHandle(true)
    const list = [...shipList];
    if (flag == "TransactionDate") {
      list[index]["TransactionDate"] = event;
      setShipList(list)
    }
  }

  function extractFileName(filePath) {
    if (filePath) {
      const pathArray = filePath.split('\\'); // Split on backslash for Windows
      const fileName = pathArray[pathArray.length - 1];
      return fileName;
    } else {
      return 'No file chosen';
    }
  }
  const [fileSizeExceeded, setFileSizeExceeded] = React.useState(false);
  const maxFileSize = 200000;

  const volumetier = async (e) => {

    if (e == 'open') {
      setshowspinner(false)
      setshowvomumetierdeails(true)

      setsidebarwidth("60vw")
      setsidebarHeight("20vh")
      setBgColor("white")
    }
    if (e == 'Close') {
      setshowvomumetierdeails(false)

    }


  }

  const OnFileChange = async (event, index, flag) => {
    const filesize = event.target.files[0];
    if (filesize.size > maxFileSize) {
      event.target.value = null;
      setFileSizeExceeded(true);
      return; //
    }
    else {
      setChangeHandle(true)
      setFileSizeExceeded(false);
      setFile([...file, ...event.target.files]);

      const list = [...shipList];
      if (event.target.value.length != 0) {
        list[index]["FilePath"] = event.target.value;
      }
    }
  }


  const OnClaimChange = (e, index, flag, ContractListPrice, ListPrice) => {

    setChangeHandle(true)
    const { name, value } = e.target;
    const list = [...shipList];
    let listName = flag;

    //  Claim Agreement calculation

    if (agreementType == "ClaimAgreement") {
      if (flag == "ClaimRevenue") {
        if (volumerierdata[0].RebateValue == "%") {
          list[index].ClaimRevenue = +e.target.value;
          let totalAmount = ((parseFloat(+e.target.value)) * volumerierdata[0].Rebate / 100);

          list[index].ClaimAmount = parseFloat(totalAmount).toFixed(2);
        }
        else if (volumerierdata[0].RebateValue == 'Ls') {
          list[index].ClaimRevenue = +e.target.value;
          let totalAmount = ((parseFloat(+e.target.value)) * volumerierdata[0].Rebate / volumerierdata[0].MeasureValue)
          list[index].ClaimAmount = parseFloat(totalAmount).toFixed(2);
        }
        else {
          list[index].ClaimRevenue = +e.target.value;
          let totalAmount = ((parseFloat(+e.target.value)) * volumerierdata[0].Rebate);
          list[index].ClaimAmount = parseFloat(totalAmount).toFixed(2);
        }
      }
      else if (flag == "ClaimQty") {
        if (volumerierdata[0].RebateValue == 'Ls') {
          list[index].ClaimQty = +e.target.value;
          let totalAmount = ((parseFloat(+e.target.value)) * volumerierdata[0].Rebate / volumerierdata[0].MeasureValue)
          list[index].ClaimAmount = parseFloat(totalAmount).toFixed(2);
        }
        else {
          list[index].ClaimQty = +e.target.value;
          let totalAmount = ((parseFloat(+e.target.value)) * volumerierdata[0].Rebate);
          list[index].ClaimAmount = parseFloat(totalAmount).toFixed(2);
        }
      }
    }

    // ship & debit Claim & Special Claim calculation

    else if (agreementType !== "ClaimAgreement") {
      if (flag == "ClaimQty") {
        list[index].ClaimQty = parseFloat(+e.target.value)
        let totalAmount = ((parseFloat(+e.target.value)) * list[index].ContractPrice);
        list[index].ClaimAmount = parseFloat(totalAmount).toFixed(2);
      }
      else if (flag == "ClaimRevenue") {
        list[index].ClaimRevenue = +e.target.value;
        let totalAmount = ((parseFloat(+e.target.value)) * list[index].ContractPrice / 100);
        list[index].ClaimAmount = parseFloat(totalAmount).toFixed(2);
      }
    }

    if (flag == "InvoiceNumber") {


      list[index]["InvoiceNumber"] = e.target.value;

    }

    setShipList(list)
  }



  const SaveHandler = (e, StatusType) => {
    e.preventDefault();
    inputField.AgreementType = agreementType
    let isValid = Validation.NewClaim(inputField, shipList);
    if (isValid != '') {
      setErrorMessage(isValid);
      setErrorStatus("Error")
      setShowErrorModal(true);
      return false;
    }
    else if (isValid === '') {
      setLoading(true);
      setShipList(shipList)


      let id = SessionManage.getuserid();

      inputField.CreatedUserId = loggedInUser;
      inputField.LastModifiedUserId = loggedInUser;

      let today = moment(new Date());
      inputField.CreatedDate = today;
      inputField.LastModifiedDate = today;
      inputField.Status = StatusType;
      inputField.AgreementType = agreementType;
      inputField.lstClaimItemView = shipList;

      if (typeof props.id === 'undefined')
      inputField.Id = 0;
      inputField.AgreementId_Id = selectedAgreement;
      let SendData = new FormData();
      inputField.comments = commentsForSubmit;

      
      SendData.append("data", JSON.stringify(inputField));
      for (let f of file) {
        SendData.append('AttachPOS', f);
      }

  
      Service.getdatabySdData('SDClaim/CreateOrUpdate', SendData).then((res) => {
        if (typeof res !== 'undefined') {
          setLoading(false);
          if (res.data !== 'null' && res.data !== 'undefined') {
            if (res.data.Status != 'Failure') {
              if (StatusType == "new") {
                if (res.data.Status == 'Warning') {
                  setErrorStatus("Warning")
                  setErrorMessage("Duplicate entries found")
                  setShowErrorModal(true);
                }
                else {
                  toast.success('Claim Saved successfully !', {
                    position: toast.POSITION.TOP_RIGHT,

                  });
                }
              }

              else {
                if (res.data.Status == 'Warning') {
                  if (StatusType == "inreview") {
                    setErrorStatus("Warning")
                    setErrorMessage("Duplicate entries found")
                    setShowErrorModal(true);
                  }
                }
                else {
                  unLockEntity()
                  toast.success('Claim Submitted successfully !', {
                    position: toast.POSITION.TOP_RIGHT,
                    onClose: () => navigate("/claims")
                  });
                }
              }
              navigate("/claims/new/" + res.data.Id);
              bindClaim(res.data.Id)
            }
            else {
              setErrorStatus("Error")
              setErrorMessage(res.data.Message)
              setShowErrorModal(true);
            }
          }

        }
      })
    }
  }
  let Type = [
    { label: "Ship & Debit", value: "Ship & Debit" },
    { label: "Special Price", value: "Special Price" },
    { label: "Claim Agreement", value: "ClaimAgreement" },

  ]

  const disabledClass = `  ${editPerms == false ? "disabledClass " : ""}`;
  const showApprovedDialog = (flag) => {
    if (flag === 'showApprovedDialog') {
      setCommentsForApproved("")
      setShowApprovedModal(!showApprovedModal)
    }
    else if (flag === 'proceed') {
      Approve()
      setShowApprovedModal(!showApprovedModal)

    }
  }
  const showSubmitDialog = (e, flag) => {
    if (flag === 'showSubmitDialog') {
      setShowDuplicateRecModal(false)
      setShowSubmitModal(!showSubmitModal)
    }
    else if (flag === 'proceed') {
      setShowSubmitModal(false)
      SaveHandler()

    }
  }
  const showDeniedDialog = (flag) => {
    if (flag === 'showDeniedDialog') {
      setCommentsForDenied("")
      setShowDeniedModal(!showDeniedModal)
    }
    else if (flag === 'proceed') {
      Deny()
      setShowDeniedModal(!showDeniedModal)

    }
  }
  const Deny = () => {
    let ClaimId = props.id
    let Comments = commentsForDenied
    let UserId = SessionManage.getuserid();
    let Status = 'Approved'
    let data = { ClaimId, Comments, UserId, Status }
    setLoading(true);
    Service.createorupdate('SDClaim/DenyAgreement', data).then((res) => {

      if (typeof res !== 'undefined' && typeof res.status !== 'undefined') {
        if (res.status === 200) {
          setLoading(false);
          toast.success('Claim Denied Successfully !', {
            position: toast.POSITION.TOP_RIGHT,
            onClose: () => navigate("/claims/approvals/pending")
          });

        }
      }
      else if (res.status !== 200) {
        setErrorPopup(true)
      }
    });
  }
  const Approve = () => {
    let ClaimId = props.id
    let Comments = commentsForApproved
    let UserId = SessionManage.getuserid();
    let Status = 'Approved'
    let data = { ClaimId, Comments, UserId, Status }
    setLoading(true);
    Service.createorupdate('SDClaim/UpdateSdClaimStatus', data).then((res) => {
      if (typeof res !== 'undefined' && typeof res.status !== 'undefined') {

        setLoading(false);
        unLockEntity();
        if (res.status === 200) {
          toast.success('Claim Approved Successfully !', {
            position: toast.POSITION.TOP_RIGHT,
            onClose: () => navigate("/claims/approvals/pending")
          });

        }
      }
      else if (res.status !== 200) {
        setErrorPopup(true)
      }
    });

  }
  const GenerateReconcilation = (e) => {
    setChangeHandle(true)
    Service.PostWithIdAndUser('ClaimReconcilation/CreateReconcilation', inputField.Id, loggedInUser).then((res) => {
      if (typeof res !== 'undefined') {
        setLoading(false);
        if (res.data !== 'null' && res.data !== 'undefined') {
          if (res.data.Status != 'Failure') {
            toast.success('Reconcilation created successfully !', {
              position: toast.POSITION.TOP_RIGHT,
            });

            navigate("/reconcilation/new/" + res.data.ReconcilationId + "/fromClaim")

          }
          else {
            setErrorMessage(res.data.Message);
            setErrorStatus("Error")
            setShowErrorModal(true);
          }
        }
      }
    })
  }

  const handleInputChange = (e, index, names, selectedName) => {
  }
  const ViewReconcilation = (e) => {
    let concilationId = inputField.ConcilationId;
    navigate("/reconcilation/new/" + concilationId + "/fromClaim")
  }

  const _ = require('lodash');


  const GetWorkflowDetails = () => {
    Service.getdatabyId('SDClaim/GetWorkflowAndApprovers', inputField.Id).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {

          const sortdata = _.orderBy(res.data.wlst, ['Workflowlevel'], ['asc'])
          setWorkflowchart(sortdata);
          setWorkflowData(res.data.workflowlst);

        }
      }
    });
  }
  const ValidateSubmit = (e) => {
    inputField.lstClaimItemView = shipList;
    let UpdatedData = new FormData();
    UpdatedData.append("data", JSON.stringify(inputField));

    Service.getdatabySdData('SDClaim/ValidateSubmit', UpdatedData).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {

          let isValid = res.data.isvalid;
          if (isValid == true) {
            if (res.data.product == null) {
              showSubmitDialog(e, 'showSubmitDialog')
            }
            else {
              setShowDuplicateRecModal(true);
            }
          }
          else {
            setErrorStatus("Error")
            setErrorMessage(res.data.Message)
            setShowErrorModal(true);
          }
        }
      }
    });
  }

  return (
    <div className="container-fluid content-top-gap">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb my-breadcrumb">

          <li className="breadcrumb-item"><Link to="/">{t('Home.title')}</Link></li>
          <li className="breadcrumb-item" aria-current="page"><Link to={updateNav} id='headertxt'>{t('Menu.Claim List')}</Link></li>
          <li className="breadcrumb-item active" id="libread" aria-current="page">{t('Menu.Create Claim')}</li>

        </ol>
      </nav>
      {loading ? <><div className="loader-container"><div className="spinner"></div> </div> </> : ""}
      <ToastContainer autoClose={1000} />
      {/* Workflow Pop-up */}


      {showShipWorkflow === true ?
        <div id="mySidepanel" className="sidepanel sidepanelborder" style={{ width: sidebarwidth }}>
          <div className="container" id="workflow">
            <div className="row mt-3">
              <div className="col-lg-12 pr-lg-2">
                <div className="card text-center card_border">
                  <div className="card-header chart-grid__header">
                    <div className="row">
                      <div className="col-md-10 text-left">
                        {t('Workflow')}
                      </div>
                      <div className="col-md-2">
                        <button className="btn btn-outline-dark  btn-sm" onClick={clickCloseShipWorkflow} type="submit" style={{ width: "45px" }}>{t('Close')}</button>
                      </div>
                    </div>
                  </div>
                  <div className="card-body" style={{ minHeight: "55vh" }}>


                    {workflowchart.length > 0 ?
                      <div className='col-md-12'>
                        <table className="table">
                          <thead>
                            <tr>
                              <th className='workflowchart-levelwidth workflowchart-font'>{t('Rebates.Level')}</th>
                              <th className='font-center workflowchart-font'>{t('Rebates.Users')}</th>
                              <th className='font-center workflowchart-font'>{t('Rebates.Approval')}</th>
                            </tr>
                          </thead>

                          <tbody>
                            {
                              workflowchart.map((i) =>
                                <tr key={i.Id}>
                                  <td className='workflowchart-font square-levels'>{i.Levels}</td>
                                  <td className='font-center'>
                                    <ul >
                                      {
                                        i.lstworkflowapprover.map((j, index) => {
                                          return <>

                                            {
                                              index < 2 ?
                                                j.IsApproved === true || j.IsDenied == true ?
                                                  <li className='workflowchartperson'>
                                                    <span className='workflowtpersoncolor'><i class="fa fa-user-circle-o" aria-hidden="true"></i>
                                                      <br></br> {j.FullName}</span>
                                                  </li>
                                                  :
                                                  <>
                                                    <li className='workflowchartperson'>
                                                      <i class="fa fa-user-circle-o" aria-hidden="true"></i>
                                                      <br></br> {j.FullName}
                                                    </li>
                                                  </>
                                                :
                                                ''

                                            }
                                          </>

                                        })
                                      }

                                      {
                                        i.lstworkflowapprover.length > 2 ?
                                          <li className='workflowchartperson tooltip_workflow'>
                                            <i class="fa fa-user-circle-o" aria-hidden="true"></i>
                                            <br></br> {t('ship&debit.MoreUsers')}
                                            <span class="tooltiptext">

                                              {
                                                i.lstworkflowapprover.map((k, index) => {
                                                  return <span>
                                                    {
                                                      index > 1 ? <><i class="fa fa-user-circle-o" aria-hidden="true"></i>
                                                        &nbsp; {k.FullName}
                                                        <hr></hr></> : null
                                                    }


                                                  </span>
                                                })
                                              }

                                            </span>
                                          </li>
                                          :
                                          ''
                                      }


                                    </ul>

                                  </td>

                                  <td className='font-center'>
                                    {
                                      i.IsApproved === true ?
                                        <span className='workflowrightcolor'>
                                          <i class="fa fa-check" aria-hidden="true"></i>
                                        </span>

                                        : i.IsDenied == true ?
                                          <span className='workflowrightcolor'>
                                            <i class="fa fa-times" aria-hidden="true" style={{ color: "red" }}></i>
                                          </span>
                                          : ''
                                    }
                                  </td>
                                </tr>)

                            }
                          </tbody>

                        </table>

                      </div>
                      :
                      <div className="'">

                      </div>
                    }

                    <div className="row">
                      <div className="col-md-12">
                        <table className="table table-bordered text-left mt-2">
                          <thead className='text-white' style={{ backgroundColor: "var(--primary)" }}>
                            <tr>

                              <th>{t('Rebates.Event')}</th>
                              <th>{t('Rebates.CreatedDate')}</th>
                              <th>{t('Rebates.User')}</th>
                              <th>{t('Rebates.Comments')}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {

                              workflowData.length === 0 ?
                                <tr>
                                  <td></td>
                                  <td></td>
                                  <td>
                                    {t('NoDataAvailable')}
                                  </td> <td></td></tr>
                                :
                                workflowData === '' || workflowData === 'undefined' ?
                                  <tr></tr> :
                                  workflowData.map((i) =>
                                    <tr key={i.id}>
                                      <td>{i.EventName}</td>
                                      {i.CreatedDate === null ? <td></td> : <td>{moment(i.CreatedDate).format(SessionManage.getcurrentdateformat())}</td>}
                                      <td>{i.CreateUserName}</td>
                                      <td>{i.Comments}</td>
                                    </tr>)

                            }

                          </tbody>
                        </table>
                      </div>

                    </div>


                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        :
        <div></div>
      }
      <div className="row">
        <div className="col-lg-12 pr-lg-2">
          <div className="card text-center card_border">
            <div className="card-header chart-grid__header">
              <div className="row">
                <div className="col-md-7 text-left">
                  {t('sdClaim.Claims')}
                </div>
                <div className="col-md-5">
                  {editPerms == false ? "" :
                    (loadedFrom == "fromapproval") ? (isLocked == true && lockedBy == loggedInUser) ?
                      <>
                        <button type="button" className="btn btn-sm btn-update ml-2" style={{ float: "right", width: "58px" }} onClick={() => showApprovedDialog('showApprovedDialog')} >{t('Approve')}</button>
                        <button type="button" className="btn btn-sm btn-update ml-2" style={{ float: "right", width: "40px" }} onClick={() => showDeniedDialog('showDeniedDialog')}>{t('Deny')}</button>
                      </>
                      :
                      ""
                      : <></>
                  }
                  {editPerms == false ? "" : disableFields == false ? <button className="btn  btn-update btn-sm ml-2" style={{ width: 40 + "px", float: "right" }} type="submit" onClick={(Event) => { SaveHandler(Event, "new") }} >{t('Save')}</button> : ''}
                  {editPerms == false ? "" : disableFields == false ? <button className="btn  btn-update btn-sm ml-2" style={{ width: 50 + "px", float: "right" }} type="submit" onClick={(event) => workflowAlert(event, 'open')}  >{t('Submit')}</button> : ''}
                  <button className="btn  btn-update btn-sm ml-2" style={{ width: 65 + "px", float: "right" }} type="submit" onClick={() => { clickShowShipWorkflow("ship") }}>{t('Workflow')}</button>
                  {loadedFrom === "" || loadedFrom == null ? <> <button className="btn  btn-update btn-sm ml-2" style={{ width: 45 + "px", float: "right" }} type="submit" onClick={() => { closeClaim('Open') }}>Close</button></> : ""}
                  {editPerms == false ? "" : inputField.Status == 'Pending Reconcilation' && inputField.ConcilationStatus != 'InReview' && userType != 'Partner User' ? <button className="btn  btn-update btn-sm ml-2" style={{ width: 70 + "px", float: "right" }} type="submit" onClick={(Event) => { GenerateReconcilation(Event) }} >{t('sdClaim.Reconcile')}</button> : ''}
                  {inputField.Status == 'Rejected' || (inputField.Status == 'Denied' && inputField.ConcilationStatus == 'Denied')
                    || inputField.Status == 'Approved' || inputField.ConcilationStatus == 'InReview' ? <button className="btn  btn-update btn-sm ml-2" style={{ width: "6.25rem", float: "right" }} type="submit" onClick={(Event) => { ViewReconcilation(Event) }} >{t('sdClaim.ViewReconcile')}</button> : ''}
                  {loadedFrom === "fromapprovalcompleted" || loadedFrom === "fromapproval" ? <> <button className="btn  btn-update btn-sm ml-2" style={{ width: 45 + "px", float: "right" }} type="submit" onClick={() => { closeAproveDeny() }}>{t('Close')}</button></> : ""}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-1">
        <div className="col-lg-4 col-md-6 col-sm-12">
          <div className="row">
            <div className="col-lg-12 pr-lg-2">
              <div className="card text-center card_border">
                <div className="card-body">
                  <div className="container">
                    <div className="row">

                      <label className="col-md-6 text-left" style={{ marginTop: "10px" }}>{t('sdClaim.AgreementType')}<span className={classes.mandatory}>*</span></label>
                      <Select
                        className={'col-md-6 textbox-padding-dropdown mt-2 fontsizedropdown' + disabledClass}
                        options={Type}
                        placeholder={t('Select')}
                        value={Type.filter(function (option) {
                          return option.value === `${agreementType}`;
                        })}
                        onChange={(event) => { setAgreementType(event.value); getAgreements(event.value) }}
                        isSearchable={true}
                        styles={Service.getSelectstyle()}
                        isDisabled={disableHeaderField}
                      >
                      </Select>

                      <label className="col-md-6 text-left" style={{ marginTop: "10px" }}>{t('ship&debit.AgreementId')}<span className={classes.mandatory}>*</span></label>
                      <Select
                        className={"col-md-6 textbox-padding-dropdown mt-2 fontsizedropdown" + (agreementType === "" || agreementType === null ? " disabledClass" : "") + disabledClass}
                        options={agreementIds}
                        placeholder={t('Select')}
                        value={(agreementIds.filter(function (option) {
                          return option.value == selectedAgreement
                        }))
                        }
                        onChange={(event) => OnchangeAgreement(event, 'AgreementId')}
                        isSearchable={true}
                        styles={Service.getSelectstyle()}
                        isDisabled={disableHeaderField}

                      />

                      {programLine ? (
                        <>

                          <label className="col-md-6 text-left" style={{ marginTop: "10px" }}>{t('sdClaim.ProgramLine')}<span className={classes.mandatory}>*</span></label>
                          <Select
                            className={"col-md-6 textbox-padding-dropdown mt-2 fontsizedropdown" + (agreementType === "" || agreementType === null ? " disabledClass" : "") + disabledClass}
                            options={programLineIds}
                            placeholder={t('Select')}
                            value={programLineIds.filter(function (option) {
                              return option.value === selectedprogramLine;
                            })}
                            onChange={(event) => OnchangeProgramlineId(event)}
                            isSearchable={true}
                            styles={Service.getSelectstyle()}
                            isDisabled={disableHeaderField}

                          />
                        </>

                      ) :
                        <>

                          <label className="col-md-6 text-left" style={{ marginTop: "10px" }} >{t('sdClaim.ClaimId')}</label>
                          <input name="ClaimId" type="text" value={inputField.ClaimId} disabled={true} className="col-md-6 textbox-padding mt-2 form-control" />
                        </>
                      }




                      <label className="col-md-6 mt-2 text-left">{t('sdClaim.ClaimDesc')}</label>
                      <input name="ClaimDesc" onChange={inputsHandler} type="text" value={inputField.ClaimDesc} disabled={disableHeaderField} className="col-md-6 textbox-padding mt-2 form-control" />

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12">
          <div className="row">
            <div className="col-lg-12 pr-lg-2">
              <div className="card text-center card_border">

                <div className="card-body" >
                  <div className="container">
                    <div className="row">

                      <label className="col-md-6 mt-2 text-left">{t('ClaimReconcilation.Distributor')}</label>
                      <input name="EndcustomerName" type="text" value={inputField.DistributorName} disabled="true" className="col-md-6 textbox-padding mt-2 form-control" />

                      <label className="col-md-6 text-left" style={{ marginTop: "5px" }}>{t('sdClaim.ValidityStartDate')}</label>

                      <div className='col-md-6 textbox-padding' style={{ marginTop: "5px" }}>
                        <DatePicker name="ValidityFrom" className="drodownwidth form-control"
                          dateFormat={SessionManage.getdateformat()}
                          selected={new Date(inputField.ValidityFrom)}

                          onChange={(event) => OnChangeHandlerDate(event, 'ValidityFrom')} disabled="true" />
                      </div>
                      <label className="col-md-6 mt-2 text-left">{t('sdClaim.ValidityEndDate')}</label>
                      <div className='col-md-6 textbox-padding' style={{ marginTop: "5px" }}>
                        <DatePicker name="ValidityTo" className="drodownwidth form-control"
                          dateFormat={SessionManage.getdateformat()}
                          selected={new Date(inputField.ValidityTo)}
                          onChange={(event) => OnChangeHandlerDate(event, 'ValidityTo')} disabled="true" />
                      </div>

                      <label className="col-md-6 mt-2 text-left">{t('Rebates.SettlementMethod')}</label>
                      <input name="SettlementMethodName" value={inputField.SettlementMethodName} type="text" style={{ marginTop: "5px" }} disabled="true" className="col-md-6 textbox-padding mt-2 form-control" />

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12">
          <div className="row">
            <div className="col-lg-12 pr-lg-2">
              <div className="card text-center card_border">
                <div className="card-body" >
                  <div className="container">
                    <div className="row">
                      <label className="col-md-6 text-left" style={{ marginTop: "10px" }}>{t('Rebates.Currency')}</label>
                      <input name="CurrencyName" type="text" value={inputField.CurrencyName} style={{ marginTop: "5px" }} disabled="true" className="col-md-6 textbox-padding mt-2 form-control" />

                      <label className="col-md-6  mt-2 text-left">{t('sdClaim.ClaimStatus')}</label>
                      <input type="text" disabled="true" className="col-md-6 textbox-padding mt-2 form-control" value={inputField.Status} />
                      <input type="text" className="col-md-6 textbox-padding mt-2 form-control" style={{ display: 'none' }} disabled={true} />
                      <label className="col-md-6 mt-2 text-left">{t('ship&debit.CreatedOn')}</label>
                      <input type="text" disabled="true" className="col-md-6 textbox-padding mt-2 form-control" value={inputField.CreatedDate === null || inputField.CreatedDate === "" || inputField.CreatedDate === "0001-01-01T00:00:00" ? "" : moment(inputField.CreatedDate).format(SessionManage.getcurrentdateformat())} />
                      <label className="col-md-6 mt-2 text-left">{t('CreatedBy')}</label>
                      <input type="text" className="col-md-6 textbox-padding mt-2 datetimepicker-input form-control" value={inputField.CreatedUserName} data-target="#reservationdate" disabled={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-lg-12 pr-lg-2">
          <div className="card text-center card_border">
            <div className="card-body" >
              <div className="row">
                <label className="col-sm-12 col-md-12 col-lg-2 pl-4 text-left">{t('Rebates.Justification')}<span className={classes.mandatory}>*</span></label>

                <textarea style={{ marginRight: "0.938rem", marginLeft: "0.938rem" }} className="col-md-11 col-lg-8 col-sm-12 form-control" onChange={inputsHandler} disabled={disableHeaderField} name="Justification" rows="4" value={inputField.Justification}></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
      {agreementType !== "ClaimAgreement" ? "" : <>
        <div className="row">
          <div className="col-md-10"> </div>
          <div className="col-md-2">
            <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "95px" }} size='sm' onClick={() => volumetier("open")}>
              {t('sdClaim.RebateRules')}
            </Button>
          </div>
        </div>
      </>
      }
      {

        showvomumetierdeails ?
          <>
            <div className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, padding: "0", height: sidebarHeight, overflow: "hidden" }}>
              <div className="container" style={{ padding: "0" }}>
                <div className="row">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border ">
                      <div className="card-header chart-grid__header">
                        <div className="row">
                          <div className="col-md-6 text-left">
                            {t('sdClaim.RebateRules')}
                          </div>
                          <>
                            <div className="col-md-6 text-right">
                              <button className="btn btn-outline-dark  btn-sm ml-1" onClick={() => volumetier("Close")} type="submit" style={{ width: "45px" }}>{t('Close')}</button>
                            </div>
                          </>
                          <></>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mt-1">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-lg-12 pr-lg-2">
                        <div className="card text-center card_border">
                          <div className="card-body">
                            <div className="container" style={{ minHeight: "60vh", backgroundColor: BgColor }}>
                              <div className='col-md-12'>

                                <table className="table table-bordered text-left mt-2" id="tableB">
                                  <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)", color: "white" }}>
                                    <tr>


                                      <th style={{ width: '150px' }}>
                                        <div className='d-flex text-nowrap'>
                                          <span>{t('Rebates.TargetCond')}</span>
                                        </div>
                                      </th>
                                      <th style={{ width: '250px' }}>
                                        <div className='d-flex text-nowrap'>
                                          <span> {t('Rebates.MeasureValue')} </span>
                                        </div>
                                      </th>
                                      <th style={{ width: '150px' }}>
                                        <div className='d-flex text-nowrap'>
                                          <span>{t('Rebates.Rebate')}</span>
                                        </div>
                                      </th>
                                      <th style={{ width: '150px' }}>
                                        <div className='d-flex text-nowrap'>
                                          <span>{t('Rebates.RebateValue')}</span>
                                        </div>
                                      </th>
                                      <th style={{ width: '150px' }}>
                                        <div className='d-flex text-nowrap'>
                                          <span>{t('Rebates.TargetMeasure')}</span>
                                        </div>
                                      </th>

                                    </tr>
                                  </thead>
                                  <tbody>

                                    {volumerierdata.map((i, index) => (
                                      <tr >
                                        <td>{i.Targetcondition}</td>
                                        <td>{i.MeasureValue}</td>
                                        <td>{i.Rebate}</td>
                                        <td>{i.RebateValue}</td>
                                        <td>{i.TargetMeasure}</td>

                                      </tr>
                                    ))}


                                  </tbody>
                                </table>
                                {/* </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </> : ""
      }
      <div className="row mt-3">
        <div className="col-lg-12 pr-lg-2 mt-3">
          <div className="card text-center card_border">
            <div className="card-header chart-grid__header">
              <div className="row">
                <div className="col-md-8 text-left">
                  {t('sdClaim.ClaimItems')}
                </div>
              </div>
            </div>
            <div className="card-body" >
              <div className="container-fluid">
                <div className="table-responsive" style={{ minHeight: "400px" }}>
                  <table className="table table-bordered table-head-fixed text-nowrap table-bordered table-striped" style={{ marginBottom: "178px" }}>
                    <thead className="thead-light">
                      <tr className='text-center'>
                        <th style={{ width: "30px" }}></th>
                        <th style={{ width: "60px" }}>{t('sdClaim.ClaimLineId')}</th>
                        <th style={{ width: "30px" }}>{t('SpecialPriceAgreement.MaterialName')}</th>
                        <th style={{ width: "800px" }}>{t('SpecialPriceAgreement.Material')}</th>


                        <th style={{ width: "60px" }}>{t('sdClaim.ClaimRevenue')}</th>
                        <th style={{ width: "60px" }}>{t('sdClaim.ClaimQty')}</th>
                        <th style={{ width: "60px" }}>{t('sdClaim.TransactionDate')}</th>

                        <th style={{ width: "60px" }}>{t('sdClaim.AmountClaimed')}</th>

                        <th style={{ width: "80px" }}>{t('sdClaim.InvoiceNumber')}</th>
                        <th style={{ width: "90px" }}>{t('sdClaim.AttachPOS')}</th>
                        {agreementType === "ClaimAgreement" ? "" : <> <th style={{ width: "80px" }}>{t('sdClaim.RebateRules')}</th></>}


                        {inputField.Status == 'New' || inputField.Status == '' ? <th style={{ width: "50px" }}>{t('Rebates.Actions')}</th> : ''}
                      </tr>
                    </thead>
                    <tbody>
                      {shipList.map((i, index) => (
                        <tr style={{ backgroundColor: "white" }}>
                          <td>
                            <div>
                              {(i.DuplicateAlert === true) ?
                                <i className="fa fa-exclamation" style={{ color: 'orange' }} title="Duplicate entries found"></i>
                                : <></>}
                            </div>
                          </td>
                          <td><Form.Control className={classes['Form-Input-control']} style={{ width: "50px", display: 'inline-block' }} disabled="true" value={index + 1} /></td>

                          <td style={{ minWidth: "100px" }}>
                            <Select className={classes[''] + disabledClass}
                              options={MaterialIds}
                              placeholder={t('Select')}
                              onChange={(event) => handleProductChange(event, index)}
                              isSearchable={true}
                              styles={Service.getSelectstyle()}
                              isDisabled={disableHeaderField}
                              value={MaterialIds.filter(function (option) {
                                return option.value === i.ProductId;
                              })}
                              menuPlacement="auto"
                              maxMenuHeight={100}
                            />
                          </td>
                          <td><Form.Control className={classes['Form-Input-control'] + disabledClass} style={{ width: "250px", display: "inline" }} disabled="true" value={i.MaterialName} /></td>

                          {i.RebateType == 'Revenue' ?
                            <td>{inputField.Prefix}<Form.Control onKeyPress={(e) => !/^(\d)*(\.)?([0-9]{1})?$/.test(e.key) && e.preventDefault()} className={classes['Form-Input-control'] + disabledClass} style={{ width: "80px", display: "inline" }} value={i.ClaimRevenue == 0 ? '' : i.ClaimRevenue} disabled={i.RebateType != 'Revenue' || disableHeaderField} name="ClaimRevenue" onChange={(event) => OnClaimChange(event, index, "ClaimRevenue")} /></td>
                            :
                            <td><Form.Control onKeyPress={(e) => !/^(\d)*(\.)?([0-9]{1})?$/.test(e.key) && e.preventDefault()} className={classes['Form-Input-control'] + disabledClass} style={{ width: "80px", display: "inline" }} value={i.ClaimRevenue == 0 ? '' : i.ClaimRevenue} disabled={i.RebateType != 'Revenue' || disableHeaderField} name="ClaimRevenue" onChange={(event) => OnClaimChange(event, index, "ClaimRevenue")} /></td>
                          }
                          <td><Form.Control onKeyPress={(e) => !/^(\d)*(\.)?([0-9]{1})?$/.test(e.key) && e.preventDefault()} className={classes['Form-Input-control'] + disabledClass} style={{ width: "80px" }} value={i.ClaimQty == 0 ? '' : i.ClaimQty} disabled={i.RebateType != 'Volume' || disableHeaderField} name="ClaimQty" onChange={(event) => OnClaimChange(event, index, "ClaimQty")} /></td>
                          <td>
                            <div className='col-md-12 mt-1 text-left textbox-padding'>
                              <DatePicker name="TransactionDate"
                                className="form-control"
                                onChange={(event) => OnChangeHandlerTransactionDate(event, index, 'TransactionDate')}
                                dateFormat={SessionManage.getdateformat()}
                                selected={i.TransactionDate}
                                minDate={new Date(inputField.ValidityFrom)}
                                maxDate={new Date(inputField.ValidityTo)}

                                disabled={disableHeaderField}

                              />
                            </div>
                          </td>

                          <td>{inputField.Prefix == '' ? '' : inputField.Prefix}<Form.Control className={classes['Form-Input-control'] + disabledClass} style={{ width: "60px", display: "inline" }} disabled="true" value={i.ClaimAmount == '' ? '' : i.ClaimAmount} /></td>


                          <td><Form.Control className={classes['Form-Input-control'] + disabledClass} style={{ width: "120px" }} disabled={disableHeaderField} name="InvoiceNumber" onChange={(event) => OnClaimChange(event, index, "InvoiceNumber", i.ContractListPrice, i.Listprice)} value={i.InvoiceNumber} /></td>


                          <td>
                            <Form.Control
                              className={classes['Form-Input-control'] + disabledClass}
                              style={{ width: "200px" }}
                              disabled={disableHeaderField}
                              type="file"
                              name="AttachPOS"
                              onChange={(event) => OnFileChange(event, index)}
                            />
                            <a href={i.FilePath == null || i.FilePath == '' ? 'Max file size: 2MB' : (i.FilePath)} target='_blank'>
                              {i.FilePath == null || i.FilePath == '' ? 'Max file size: 2MB' : extractFileName(i.FilePath)}
                              {fileSizeExceeded ? <a>File size should not be greater than 2MB</a> : ''}

                            </a>
                          </td>
                          {agreementType === "ClaimAgreement" ? '' : <>
                            <td><div style={{ textAlign: "center" }}><i class="fa fa-bookmark gridwidth" aria-hidden="true" style={{ fontSize: "15px" }} onClick={() => rebateRulesPopup(i, "Open")}></i></div></td>
                          </>}
                          {inputField.Status == 'New' || inputField.Status == '' ?
                            <td>
                              <div className={classes['gridwidth']} style={{ margin: "5px" }}>
                                {editPerms == false ? "" : shipList.length - 1 === index ? <a><i className="fa fa-plus " style={{ color: "#4755ab", fontSize: "18px" }} onClick={() => AddVolume()} title='Delete'></i></a> : null}

                                {editPerms == false ? "" : shipList.length !== 1 ? <a style={{ marginLeft: "15px" }} ><i className="fa fa-trash " onClick={() => RemoveVolume(index)} style={{ color: "red", fontSize: "18px" }} title='Delete'></i></a> : null}
                              </div>
                            </td>
                            :
                            ''}
                        </tr>
                      ))}

                      <tr>
                        <td colspan="4"></td>
                        {inputField.AgreementType == 'ClaimAgreement' ?
                          rebateType == 'Revenue' ?
                            <>
                              <td colspan="1" style={{ fontWeight: "600", color: "blue" }}>{inputField.Prefix == '' ? '' : inputField.Prefix} {totalSum == '' ? '' : Service.currencyFormat(parseFloat(totalSum).toFixed(SessionManage.getroundingPrecision()))}</td>
                              <td colspan="1"></td>
                            </>
                            :
                            <>
                              <td colspan="1"></td>
                              <td colspan="1" style={{ fontWeight: "600", color: "blue" }}>{inputField.Prefix == '' ? '' : inputField.Prefix} {totalSum == '' ? '' : Service.currencyFormat(parseFloat(totalSum).toFixed(SessionManage.getroundingPrecision()))}</td>
                            </>

                          :
                          <td colspan="2"></td>
                        }
                        <td colspan="1" style={{ fontWeight: "600", color: "blue" }}>Total Claim Amount</td>
                        <td colspan="3" style={{ fontWeight: "600", color: "blue" }}>
                          {inputField.Prefix == '' ? '' : inputField.Prefix} {totalAmount == '' ? '' : Service.currencyFormat(parseFloat(totalAmount).toFixed(SessionManage.getroundingPrecision()))}
                        </td>
                      </tr>
                    </tbody>
                  </table>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomErrorPopup ErrorMessage={errorMessage} show={showErrorModal} ErrorStatus={errorStatus}
        onHide={() => setShowErrorModal(false)}></CustomErrorPopup>
      <Modal show={showSubmitModal} size="bg"
        centered>
        <Modal.Header>
          <Modal.Title>{t('Rebates.SubmitComments')}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ "textAlign": 'center' }} >
          <Form.Control className={classes['Textarea-width']} as="textarea" rows={2} value={commentsForSubmit} name="commentsForSubmit"
            onChange={(e) => { setCommentsForSubmit(e.target.value) }} />
        </Modal.Body>
        <Modal.Footer>
          <Button size='sm' style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "50px" }} onClick={(event) => showSubmitDialog(event, 'showSubmitDialog')}>
            {t('Cancel')}
          </Button>
          <Button size='sm' style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "50px" }} onClick={(Event) => { SaveHandler(Event, "inreview") }}>
            {t('Submit')}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showApprovedModal} size="bg"
        centered>
        <Modal.Header>
          <Modal.Title>{t('Rebates.ApprovalComments')}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ "textAlign": 'center' }} >
          <Form.Control className={classes['Textarea-width']} as="textarea" rows={2} value={commentsForApproved} name="commentsForApproved"
            onChange={(e) => { setCommentsForApproved(e.target.value) }} />
        </Modal.Body>
        <Modal.Footer>
          <Button size='sm' style={{ background: '#eff1f9', color: 'black', borderColor: 'black', width: "50px" }} onClick={() => showApprovedDialog('showApprovedDialog')}>
            {t('Cancel')}
          </Button>
          <Button size='sm' style={{ background: '#eff1f9', color: 'black', borderColor: 'black', width: "55px" }} onClick={() => showApprovedDialog('proceed')}>
            {t('Approve')}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showDeniedModal} size="bg"
        centered>
        <Modal.Header>
          <Modal.Title>{t('Rebates.DeniedComments')}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ "textAlign": 'center' }} >
          <Form.Control className={classes['Textarea-width']} as="textarea" rows={2} value={commentsForDenied} name="commentsForDenied"
            onChange={(e) => { setCommentsForDenied(e.target.value) }} />
        </Modal.Body>
        <Modal.Footer>
          <Button size='sm' style={{ background: '#eff1f9', color: 'black', borderColor: 'black', width: "55px" }} onClick={() => showDeniedDialog('showDeniedDialog')}>
            {t('Cancel')}
          </Button>
          <Button size='sm' style={{ background: '#eff1f9', color: 'black', borderColor: 'black', width: "40px" }} onClick={() => showDeniedDialog('proceed')}>
            {t('Deny')}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={closeModal}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Body style={{ "textAlign": 'center', fontFamily: 'Segoe UI' }}>{t('Rebates.YourChangesWillBeLost!AreYouSureYouWantToClose?')}</Modal.Body>
        <Modal.Footer>
          <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "45px" }} size='sm' onClick={() => closeClaim("Close")}>
            {t('Close')}
          </Button>
          <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "50px" }} size='sm' onClick={() => closeClaim("Cancel")}>
            {t('Cancel')}
          </Button>
        </Modal.Footer>
      </Modal>

      {
        showRebateRulesPopup === true ?
          <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, padding: "0", height: sidebarHeight, overflow: "hidden" }}>
            <div className="container" style={{ padding: "0" }}>
              <div className="row">
                <div className="col-lg-12 pr-lg-2">
                  <div className="card text-center card_border ">
                    <div className="card-header chart-grid__header">
                      <div className="row">
                        <div className="col-md-6 text-left">
                          {t('sdClaim.RebateRules')}
                        </div>
                        <>
                          <div className="col-md-6 text-right">
                            <button className="btn btn-outline-dark  btn-sm ml-1" onClick={() => rebateRulesPopup("", "Close")} type="submit" style={{ width: "45px" }}>{t('Close')}</button>
                          </div>
                        </>
                        <></>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-1">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-lg-12 pr-lg-2">
                      <div className="card text-center card_border">
                        <div className="card-body">
                          <div className="container" style={{ minHeight: "60vh", backgroundColor: BgColor }}>
                            <div className='col-md-12'>

                              <table className="table table-bordered text-left mt-2" id="tableA">
                                <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)", color: "white" }}>
                                  <tr>
                                    <th style={{ width: '30px' }}>
                                      <div className='d-flex text-nowrap'>
                                        <span>No</span>
                                      </div>
                                    </th>
                                    <th style={{ width: '150px' }}>
                                      <div className='d-flex text-nowrap'>
                                        <span>{t('Rebates.RebateType')}</span>
                                      </div>
                                    </th>
                                    <th style={{ width: '250px' }}>
                                      <div className='d-flex text-nowrap'>
                                        <span> {t('SpecialPriceAgreement.ContractType')} </span>
                                      </div>
                                    </th>
                                    <th style={{ width: '150px' }}>
                                      <div className='d-flex text-nowrap'>
                                        <span>{t('sdClaim.ContractPrice')}</span>
                                      </div>
                                    </th>
                                    <th style={{ width: '150px' }}>
                                      <div className='d-flex text-nowrap'>
                                        <span>{t('ship&debit.ThresholdCondition')}</span>
                                      </div>
                                    </th>
                                    <th style={{ width: '150px' }}>
                                      <div className='d-flex text-nowrap'>
                                        <span>{t('ship&debit.ThresholdAmount')}</span>
                                      </div>
                                    </th>
                                    <th style={{ width: '150px' }}>
                                      <div className='d-flex text-nowrap'>
                                        <span>{t('ship&debit.ThresholdQty')}</span>
                                      </div>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {
                                    showspinner === true ?
                                      <tr>
                                        <>
                                          <Spinner animation="grow" size="sm" variant="success" style={{ width: "0.7rem", height: "0.7rem" }} />
                                          <Spinner animation="grow" size="sm" variant="danger" style={{ width: "0.7rem", height: "0.7rem" }} />
                                          <Spinner animation="grow" size="sm" variant="warning" style={{ width: "0.7rem", height: "0.7rem" }} />
                                        </>
                                      </tr>

                                      :
                                      rebateRulesData.length === 0 ?
                                        <tr>
                                          <td></td>
                                          <td></td>
                                          <td>{t('NoDataAvailable')}</td>
                                          <td></td>
                                        </tr>
                                        :
                                        rebateRulesData.map((listVal, index) =>
                                          <tr >
                                            <td>{listVal.ClaimAmount != '' ? (index + 1) : <></>}</td>
                                            <td>{listVal.Targetcondition}</td>
                                            <td>{listVal.MeasureValue}</td>
                                            <td>{listVal.Rebate != '' ? listVal.Rebate : <>{t('NoDataAvailable')}</>}</td>
                                            <td>{listVal.RebateValue}</td>
                                            <td>{listVal.ThresholdAmount}</td>
                                            <td>{listVal.TargetMeasure}</td>
                                          </tr>
                                        )
                                  }
                                </tbody>
                              </table>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          :
          <></>
      }

      <Modal show={showWorkflowAlert}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Body style={{ "textAlign": 'center' }}>{t('Accruals.WorkflowAlert')}</Modal.Body>
        <Modal.Footer>
          <Button size='sm' style={{ background: '#eff1f9', color: 'black', borderColor: 'black', width: "30px" }} onClick={(e) => workflowAlert(e, 'close')}>
            {t('Ok')}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showDuplicateRecModal} size="bg"
        centered>
        <Modal.Header>
          <Modal.Title>{t('sdClaim.Validation')}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ "textAlign": 'center', fontFamily: 'Segoe UI' }}>{t('sdClaim.DuplicateEntry')}</Modal.Body>
        <Modal.Footer>
          <Button size='sm' style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "50px" }} onClick={() => setShowDuplicateRecModal(!showDuplicateRecModal)}>
            {t('Cancel')}
          </Button>
          <Button size='sm' style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "55px" }} onClick={(Event) => { showSubmitDialog(event, 'showSubmitDialog') }}>
            {t('Proceed')}
          </Button>
        </Modal.Footer>
      </Modal>

    </div>
  )
}
export default CreateNewShipAndDebit