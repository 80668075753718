import $, { each } from 'jquery';
import React, { useRef, useState, forwardRef, useEffect } from 'react';
import { Form, Dropdown, Button, InputGroup, FormControl, Modal } from 'react-bootstrap';
import Service from '../../../Services/Service';
import Validation from '../../../Services/Validation';
import classes from '../../RebateProgramComponent/CreateRebateComponent/Form.module.css'
import { Link, Navigate, useLocation } from 'react-router-dom';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import Multiselect from 'multiselect-react-dropdown';
import { CSVLink } from "react-csv";
import SessionManage from '../../../SessionManagement/SessionManage';
import Select from "react-select";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css'
import dateFormat from 'dateformat';
import CustomErrorPopup from '../../../UI/CustomErrorPopup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import WorkflowApprovers from './WorkflowApprovers';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ClaimWorkflowApprovers from '../../SDClaimComponent/CreateSDClaim/WorkflowApprovers';
import SDWorkflowApprovers from './WorkflowApprovers';
import { useTranslation } from "react-i18next";
import ProductTreeDialog from '../../RebateProgramComponent/CreateRebateComponent/ProductTreeDialog';




function CreateNewShipAndDebit(props) {

    const [disableHeaderField, setdisableHeaderField] = useState(false);
    const location = useLocation();
    const [editPerms, setEditPerms] = useState('');
    const [userPerms, setuserPermission] = useState([]);
    var currentDate = new Date();
    var year = currentDate.getFullYear();
    var month = currentDate.getMonth();
    var day = currentDate.getDate();
    const [errorPopup, setErrorPopup] = useState(false)
    const [showspinner, setshowspinner] = useState('');
    const [sticky, setSticky] = useState("");
    const [divtop, setdivtop] = useState('');
    const [closeModal, setCloseModal] = useState(false);
    const [changeHandle, setChangeHandle] = useState(false);
    const [settlementMethodsList, setSettlementMethodsList] = useState([]);
    const [distributorList, setDistributorList] = useState([]);
    const [endCustomersList, setEndCustomersList] = useState([]);
    const [currencyList, setCurrencyList] = useState([]);
    const [productList, setProductList] = useState([]);
    const [contractTypeList, setContractTypeList] = useState([]);
    const [defaultCurrency, setDefaultCurrency] = useState('');
    const [defaultStatus, setDefaultStatus] = useState('');
    const [contractQtyConditionList, setContractQtyConditionList] = useState([]);
    const [statusList, setStatusList] = useState([]);
    const [isLocked, setIsLocked] = useState(false);
    const [lockedBy, setLockedBy] = useState('');
    const [loadedFrom, setLoadedFrom] = useState('')
    const [showShipWorkflow, setshowShipWorkflow] = useState('');
    const [showClaimWorkflow, setshowClaimWorkflow] = useState('');
    const [sidebarwidth, setsidebarwidth] = useState('');
    const [showWorkflow, setshowWorkflow] = useState('');
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [errorStatus, setErrorStatus] = useState("Error");
    const [showSubmitModal, setShowSubmitModal] = useState(false)
    const [commentsForApproved, setCommentsForApproved] = useState("")
    const [showDeniedModal, setShowDeniedModal] = useState(false)
    const [showApprovedModal, setShowApprovedModal] = useState(false)
    const [commentsForDenied, setCommentsForDenied] = useState("")
    const [contractTypeValue, setContractTypeValue] = useState("")
    const [loading, setLoading] = useState(false);
    const [errorProduct, setErrorProduct] = useState('');
    const [errorDetail, setErrorDetail] = useState('');
    const [defaultPrefix, setDefaultPrefix] = useState('');
    const [totalselectedshow, settotalselectedshow] = useState('');
    const [totalselectedcount, settotalselectedcount] = useState(0);
    const [claimData, setClaimData] = useState([]);
    const [showLoadMore, setshowLoadMore] = useState('');
    const [totalCountlst, settotalCountlst] = useState(0);
    const [columnAscending, setColumnAscending] = useState(true);
    const [filteringvalue, setfilteringvalue] = useState('');
    const [checkedValue, setCheckedValue] = useState([]);
    const [checkedValueStatus, setCheckedValueStatus] = useState([]);
    const [checkedValueid, setCheckedValueid] = useState([]);
    const [divleft, setdivleft] = useState('');
    const [sortColName, setSortColName] = useState("");
    const [searchValue, setSearchValue] = useState("")
    const [filterCol, setFilterCol] = useState('');
    const [dropdownData, setDropdownData] = useState([]);
    const [showfilterpopup, setshowfilterpopup] = useState(false);
    const [dropdownChange, setDropdownChange] = useState([]);
    const [filterValue, setFilterValue] = useState([]);
    const [t, i18n] = useTranslation('translation');
    const [filterSearch, setFilterSearch] = useState("");
    const [filterdropdownData, setFilterDropdownData] = useState([]);
    const [filtertext, setfiltertext] = useState('');
    const [showbuttons, setshowbuttons] = useState(false);
    const [commentsForSubmit, setCommentsForSubmit] = useState("");
    const [updateNav, setUpdateNav] = useState('/shipanddebit')
    let loggedInUser = sessionStorage.getItem("id")
    const [showProductPopup, setShowProductPopup] = useState(false)
    const [dualListBoxOptionsByHeirarchy, setdualListBoxOptionsByHeirarchy] = useState([])
    const [dualListBoxOptionsByAttribute, setdualListBoxOptionsByAttribute] = useState([])
    const [FilteredProducts, SetFilteredProducts] = useState([]);
    const [rebatelinestatus, setrebatelinestatus] = useState('New');
    const [selectedType, setSelectedType] = useState('Attribute');
    const [PrevSelectedTypeState, setPrevSelectedTypeState] = useState('Heirarchy');
    const [clickProduct, setclickProduct] = useState('');
    const [uniquePIds, setUniquePIds] = useState(new Set());
    const [showWorkflowAlert, setShowWorkflowAlert] = useState(false)
    const [workflowchart, setWorkflowchart] = useState([]);
    const [selectedOptionsautoPostReconciliations, setselectedOptionsautoPostReconciliations] = useState('No');
    const [changeHandleHeader, setChangeHandleHeader] = useState(false);
    const [state, setState] = useState({ agreementName: "", currencyID: 0, setlMethodID: 0, startDate: new Date(), endDate: new Date(SessionManage.getFiscalEndDate()), autoPostReconciliations: false, agreementId: '0', created_by: '', createdDate: moment(new Date()).format(SessionManage.getcurrentdateformat()), CurrencySymbol: '$', })
    const [filterValueDict, setFilterValueDict] = useState({
        claim_id: '',
        desc: '',
        status: '',
        amount: '',
        reconcilation_id: '',
        last_modified_date: '',
        reconcilation_status: '',

    });
    const [multiFilterValue, setMultiFilterValue] = useState({
        last_modified_by: '',
    });


    const [edit, setEdit] = useState(false);
    const [SalesLevelData, setSalesLevelData] = useState([])
    const [rebateTypeData, setRebateTypeData] = useState([]);
    const [countryData, setCountryData] = useState([]);
    const [paging, setpaging] = useState({
        pageSize: 20,
        pageNumber: 1
    })
    const OpenClaim = (event, id) => {
        if (event.detail === 2) {
            navigate("/claims/new/" + id);
        }
    }

    const OpenHandler = (id) => {
        
        if (id == null || id == 0) {
            alert('Please select a Policy to view')
        }
        else {
            navigate("/claims/new/" + id);
        }
    }

    const navigate = useNavigate();
    const [shipList, setShipList] = useState([]);

    const [inputField, setInputField] = useState({
        Id: 0, AgreementId: '', AgreementDesc: '', DistributorId: '', RegionId: '', ValidityFrom: new Date(), ValidityTo: (new Date(SessionManage.getFiscalEndDate())), SettlementMethodId: '', CurrencyId: '', StatusId: 0, Status: '', AutoPostAccruals: false, LastModifiedUserId: '', CreatedUserId: '', CreatedDate: '', CreatedUserName: '', LockedById: 0, LastModifiedDate: '', Deleted: false, RevisionOf: '', CopyOf: '', Locked: true, sdagreementItemsList: [], Justification: ''
    })

    const inputsHandler = (e) => {
        setChangeHandle(true)
        const { name, value } = e.target;
        setInputField((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        var f = inputField;
    }
    const OnChangeHandlerDate = (e, name) => {
        setChangeHandle(true)
        if (e) {
            if (name === 'ValidityFrom')
                setInputField({ ...inputField, ValidityFrom: e })
            if (name === 'ValidityTo')
                setInputField({ ...inputField, ValidityTo: e })
        }
    };

    const OndropdownChange = (e, name) => {
        setChangeHandle(true)
        setInputField({ ...inputField, [name]: e.value });

        if (name === 'CurrencyId') {
            setDefaultCurrency(e.label);
            setDefaultPrefix(e.Prefix);
            const list = [...shipList];
            for (var i = 0; i < list.length; i++) {
  
                if (list[i].ContractTypeSymbol !== '%' && list[i].ContractTypeId !== "") {
                    list[i].ContractTypeSymbol = e.Prefix;
                }
            }
        }
        if (name === 'RegionId') {
            Service.getdatabyCountryId('SDCommon/GetDistributorByCountry', e.value).then((res) => {
                if (typeof res !== 'undefined') {
                    if (typeof res.data !== 'undefined' && res.data !== 'null') {
                        let data = res.data
                      
                        setInputField({ ...inputField, ['RegionId']: e.value })
                        setDistributorList(data)
                    }
                }
            });
        }
        if (name === 'DistributorId') {
           
            Service.getdatabyId('SDCommon/GetCountryByDistributor', e.value).then((res) => {
                if (typeof res !== 'undefined') {
                    if (typeof res.data !== 'undefined' && res.data !== 'null') {
                        let data = res.data
                     
                        setInputField({ ...inputField, ['RegionId']: data.value, ['DistributorId']: e.value })
                    }
                }
            });
        }
    };

  
    useEffect(() => {

        getRebateTypeData("rebatetype")
        getCountryData("Country")

        if (typeof props.id === 'undefined' || props.id === '0')
            getUserPermission('Create Ship & Debit');
        else if (typeof props.id !== 'undefined' && typeof props.name !== 'undefined' && props.id != '0' && (props.name.toLowerCase() == 'fromapproval' || props.name.toLowerCase() == 'fromapprovalcompleted'))
            getUserPermission('Ship & Debit Workflow');
        else if (typeof props.id !== 'undefined' && props.id != '0') {
            getUserPermission('Create Ship & Debit');
            getClaims('SDAgreement/GetClaimBySDId', props.id, paging.pageSize, paging.pageNumber, "Descending", "Id", [], null);
        }

        if (typeof props.name !== 'undefined')
            setLoadedFrom(props.name.toLowerCase())
        getdataByGroup("SDCommon/GetAllDistCustCurrencySettlementProduct");
        getdataProductSelectorByHierarchy();
        getdataProductSelectorByAttribute();
        if (typeof props.id !== 'undefined' && props.id > 0) {
            setEdit(true);
            setSortColName("Id");
        }
        else {
            setLockedBy(loggedInUser);
            setIsLocked(true);
        }
    }, [0])



    const customStyles = {
        control: base => ({
            ...base,
            minHeight: 28,
            maxHeight: 100,
        }),
        dropdownIndicator: base => ({
            ...base,
            padding: 2,
            fontSize: "0.75rem",
        }),
        clearIndicator: base => ({
            ...base,
            padding: 2
        }),
        multiValue: base => ({
            ...base,
            fontSize: "0.75rem",
        }),
        valueContainer: base => ({
            ...base,
            padding: '0px 2px',
            fontSize: "0.75rem",
        }),
        input: base => ({
            ...base,
            margin: 0,
            padding: 0,
        })
    };

    const customMultiStyles = {
        multiselectContainer: {
            maxWidth: '170px',
            marginLeft: '3px'
        },
        chips: {
            borderRadius: '5px'
        }
    };




    const getdataByGroup = (method) => {
        Service.getdataByGroupAndType(method, '').then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined') {
                    if (typeof res.data.settlementMethodsList !== 'undefined')
                        setSettlementMethodsList(res.data.settlementMethodsList);
                    if (typeof res.data.distributorList !== 'undefined')
                        setDistributorList(res.data.distributorList);
                  
                    if (typeof res.data.currencyList !== 'undefined')
                        setCurrencyList(res.data.currencyList);
                    if (typeof res.data.productList !== 'undefined')
                        setProductList(res.data.productList);
                    if (typeof res.data.contractTypeList !== 'undefined')
                        setContractTypeList(res.data.contractTypeList);
                    if (typeof res.data.contractQtyConditionList !== 'undefined')
                        setContractQtyConditionList(res.data.contractQtyConditionList);
                    if (typeof res.data.statusList !== 'undefined')
                        setStatusList(res.data.statusList);
                    if (typeof props.id != 'undefined' && props.id != 0)
                        getUserDatabyId("SDAgreement/GetById", props.id);
                    else {

                        var NewItem = res.data.statusList.filter((item) => item.label === 'New');
                        if (NewItem.length > 0) {
                            setInputField((prevState) => ({
                                ...prevState,
                                ["StatusId"]: NewItem[0].value,
                            }));
                            setInputField((prevState) => ({
                                ...prevState,
                                ["Status"]: NewItem[0].label,
                            }));

                        }
                    }
                }
            }
        });
    }



    const unLockEntity = () => {
        Service.LockUnLockEntity('Configurationprop/UnLockEntity', "ShipAndDebit", props.id, loggedInUser).then((res) => {
        });
    }



    const getUserDatabyId = (method, id) => {
        let apidata = []
        Service.getdatabyIdUser(method, id).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    var data = res.data;
                    apidata = res.data
                  
                    if ((data.Locked == true && data.LockedById.toString() !== loggedInUser)) {
                        $("#libread").html(data.AgreementId + " " + `Locked By ${data.LockedByUsername}`);
                    }
                    else {
                        $("#libread").html(data.AgreementId);
                    }
                    setInputField(data);
                    setDefaultCurrency(data.CurrencyName);
                 
                    let pdtId = new Set();
                    res.data.sdagreementItemsList.map((each) => {
                        each['ContractTypeSymbol'] = data.Prefix
                        each['PercentageSymbol'] = '%'
                        pdtId.add(each.ProductId)
                    })
                    setUniquePIds(pdtId)
                    setShipList(res.data.sdagreementItemsList);
                    setEndCustomersList(res.data.endCustomerList);
                    setDefaultPrefix(data.Prefix);
                    if (data.IsApproveCurrentUser > 0)
                        setEditPerms(false);

                    setLockedBy(data.LockedById);
                    setIsLocked(data.Locked);
                   
                    if (props.name != '' && props.name != undefined) {
                        if (props.name.toLocaleLowerCase() == 'fromapproval') {
                            $("#headertxt").html('Ship & Debit Approval- Pending Action');
                            setUpdateNav('/shipanddebit/approvals/pending')
                        }
                        else if (props.name.toLocaleLowerCase() == 'fromapprovalcompleted') {
                            $("#headertxt").html('Ship & Debit Approval- Workflow Completed');
                            setUpdateNav('/shipanddebit/approvals/archive')
                        }
                    }
                    else {
                        $("#headertxt").html(t('Menu.Ship & Debit'));
                        setUpdateNav('/shipanddebit')
                    }
                }
            }
            if (data.Status == 'New') {
                insertWorkflowDetails(res.data.Id, loggedInUser)
            }

            if (((props.name == 'fromApproval' || props.name == 'fromapprovalcompleted') && data.Status == 'InReview' && data.Locked == false) || (data.Status == 'New' && data.Locked == false)) {
                Service.LockUnLockEntity('Configurationprop/LockEntity', "ShipAndDebit", id, loggedInUser).then((res1) => {
                  
                    if (typeof res1 !== 'undefined') {
                        setLockedBy(loggedInUser)
                        setIsLocked(true)
                    }
                })
            }


            if (data.Locked === false || (data.Locked === true && data.LockedById == loggedInUser)) {
                if (data.Status.toLowerCase() == "approved" || data.Status.toLowerCase() == "inreview" || data.Status.toLowerCase() == "denied" || data.Status.toLowerCase() == "rejected") {
                    setdisableHeaderField(true)
                }
                else {
                    setdisableHeaderField(false)
                }

            }
            else {
                setdisableHeaderField(true)
            }


        });




    }

    const insertWorkflowDetails = (id, userId,) => {
        Service.workflowDataSd('SDAgreement/Insertworkflow', id, userId).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                   
                    if (res.status == 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                            
                            setWorkflowchart(res.data)
                        }
                    }
                }
            }
        })
    }




  
    const getUserPermission = (pathname) => {

        Service.GetUserPermissionNew(pathname).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null' && res.data !== '') {
                    if (typeof res.data.DataList !== 'undefined') {
                        var permission = res.data.DataList;
                        setuserPermission(permission);
                        if (typeof permission !== 'undefined') {
                            if (permission.filter((item) => item.ActionName === 'Edit' || item.ActionName === 'Submit').length == 0) {
                                setEditPerms(false);
                            } else {
                                setEditPerms(true);
                            }
                        }
                        else {
                            setEditPerms(false);
                        }
                    }
                }
            }
        });
    }

    const LoadMore = (lst, totalcount) => {

        if (lst < totalcount) {
            setshowLoadMore(true);
        }
        else if (lst >= totalcount) {
            setshowLoadMore(false);
        } else {
            setshowLoadMore(false);
        }

    }

    const getSalesOfficeDataNew = (method) => {
        Service.getregionbyId('SalesOffice/GetBySalesOrgID', 0).then((res) => {
        
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                            let rowData = res.data
                            if (rowData.length > 0) {
                                for (var i = 0; i < rowData.length; i++) {
                                    for (var j = 0; j < endCustomersList.length; j++) {
                                        try {
                                            if (rowData[i].label.toLowerCase() == endCustomersList[j].State.toLowerCase())
                                                if (endCustomersList[j].Selected == true)
                                                    rowData[i].selected = true;
                                        } catch { }
                                    }
                                }
                                setSalesLevelData(rowData)
                            }
                            else {
                                setSalesLevelData([])
                            }
                        }
                    }
                }
            }
        });
    }


    const getRebateTypeData = (method) => {
        Service.getAlldata(method).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                            
                            let data = []
                            res.data.map((each) => {
                                if (each.Name.toLowerCase() == 'volume' || each.Name.toLowerCase() == 'revenue') {
                                    data.push(each)
                                }
                            })
                            setRebateTypeData(data)
                        }
                    }
                }
            }

        });
    }
    const getCountryData = (method) => {
        Service.getAlldata(method).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                           
                            setCountryData(res.data)
                        }
                    }
                }
            }

        });
    }

    const getClaims = (method, id, pagesize, pagenumber, sortType, SortProperty, filterlist, search) => {
       
        Service.getPostdatabyClaimIdSearch(method, id, pagesize, pagenumber, sortType, SortProperty, filterlist, search).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    let rData = res.data.DataList;
                 
                    if (res.status === 200) {
                        if (res.data.DataList !== 'null' && res.data.DataList !== 'undefined') {
                            setClaimData(res.data.DataList)
                            setshowspinner(false);
                            LoadMore(res.data.DataList.length, res.data.Totalcount)
                            settotalCountlst(res.data.Totalcount)
                        }
                    }
                }
            }
        });
    }


    const clickShowClaimWorkflow = (val) => {
        if (checkedValue.length === 0) {
            alert("Please Select a claim to view wokflow")
        }
        else {
            setshowClaimWorkflow(true);
            setsidebarwidth("70vw");
          
        }
    }

    const clickCloseClaimWorkflow = () => {
        setshowClaimWorkflow(false);
    }


    const clickShowShipWorkflow = () => {
        setshowShipWorkflow(true);
        setsidebarwidth("70vw");
        setshowWorkflow(true);

    }
    const clickCloseShipWorkflow = () => {
        setshowShipWorkflow(false);
    }

    const closeShipAgreement = (flag) => {
        if (flag === "Open" && changeHandle == true) {
            setCloseModal(true)
        }
        if (flag === "Open" && changeHandle == false) {
            setCloseModal(false)
            navigate("/shipanddebit")
        }
        if (flag === "Close" && changeHandle == true) {
            setCloseModal(false)
            navigate("/shipanddebit")
        }
        if (flag === "Cancel" && changeHandle == true) {
            setCloseModal(false)
        }
        unLockEntity()
    }
    const closeApproveAgreement = () => {
        navigate("/shipanddebit/approvals/pending")
        unLockEntity()
    }
    const closeApproveCompleteAgreement = () => {
        navigate("/shipanddebit/approvals/archive")
        unLockEntity()
    }
    const AddVolume = () => {
        setChangeHandle(true)
        const _item = {
            lineId: 0, ProductId: "", Currency: "", ContractTypeId: "", ContractTypeSymbol: "", PercentageSymbol: "", ContractPrice: "", ContractListPrice: 0, ContractQtyCondId: "", ContractQty: '', AgreementItemId: "", Id: 0, IsActive: true, ContractAmount: '', MaterialName: ''
        };
        setShipList([...shipList, _item]);
    }


    const SaveHandler = (e, Status) => {
        setShowSubmitModal(false);
        e.preventDefault();
        let isValid = Validation.NewShipAndDebit(inputField, shipList);
        if (isValid != '') {
            setErrorMessage(isValid);
            setErrorStatus("Error")
            setShowErrorModal(true);

            return false;
        }
        else if (isValid === '') {

            let temp = [...shipList]

            temp.forEach((each) => {
                if (each.selectedRebateType.toLowerCase() == 'volume') {
                    each.ContractAmount = each.ContractAmount || 0
                }
                if (each.selectedRebateType.toLowerCase() == 'revenue') {
                    each.ContractQty = each.ContractQty || 0
                }
            })

            setShipList(shipList)

            let validFrom = moment(inputField.ValidityFrom).toDate()
            let validTo = moment(inputField.ValidityTo).toDate()

            setLoading(true);
            setErrorProduct('');
            let id = SessionManage.getuserid();
            inputField.CreatedUserId = id;
            inputField.LastModifiedUserId = id;
            inputField.LockedById = id;
            inputField.ValidityFrom = moment(validFrom).format("YYYY-MM-DD");
            inputField.ValidityTo = moment(validTo).format("YYYY-MM-DD");
            let today = moment(new Date());
            inputField.CreatedDate = today;
            inputField.LastModifiedDate = today;
            inputField.Status = Status;
            inputField.Comments = commentsForSubmit;
            inputField.sdagreementItemsList = temp;
            let HeaderData = inputField;

            Service.createorupdate("SDAgreement/CreateOrUpdate", HeaderData).then(res => {
                setLoading(false);
               
                let id = res.data.Id
                if (typeof res !== 'undefined') {
                   
                    if (res.status == 200 && Status == 'new') {
                        toast.success('Agreement Saved Successfully !', {
                            position: toast.POSITION.TOP_RIGHT,
                           
                        });
                 
                        getUserDatabyId("SDAgreement/GetById", id);
                        navigate("/shipanddebit/new/" + id);
                    }
                    if (Status == 'inreview' && res.data.errorMsg == '') {
                        unLockEntity()
                        toast.success('Agreement Submitted Successfully !', {
                            position: toast.POSITION.TOP_RIGHT,
                            onClose: () => navigate("/shipanddebit")
                        });
                        getUserDatabyId("SDAgreement/GetById", id);
                        navigate("/shipanddebit/new/" + id);
                    }
                    if (res.data.errorMsg !== '' && res.data.errorDetails !== '') {
                        setErrorProduct(res.data.errorMsg);
                        setErrorDetail(res.data.errorDetails);
                        setErrorPopup(true);
                        getUserDatabyId("SDAgreement/GetById", id);
                    }

                }
            });
        }
        else {
            setLoading(false);
            setErrorStatus("Error")
            setShowErrorModal(true);
            return false;
        }
       
    }

    const handleInputChange = (e, index, names, selectedName) => {
        setChangeHandle(true)
        const name = names;
        const value = e.value;
        const list = [...shipList];
        list[index][name] = value;

      
      
        if (names === 'ProductId') {
     
            Service.getdatabyId('SDCommon/GetListPriceProduct', value).then((res) => {
                if (typeof res !== 'undefined') {
                    if (typeof res.data !== 'undefined' && res.data !== 'null') {
                        if (res.data.length > 0) {
                           
                            list[index]["Listprice"] = res.data[0].label;
                        }
                    }
                }
            });
          
            productList.map((each) => {
                if (each.value == e.value) {
                    list[index]["MaterialName"] = each.Name
                }
            })
        }
        if (names === 'ContractTypeId') {
            if (e.label == 'Rebate Amount')
                list[index]["ContractTypeSymbol"] = defaultPrefix;
            if (e.label == 'Rebate Percent')
                list[index]["ContractTypeSymbol"] = '%';
        }
        if (names === 'rebateTypeId') {
            list[index]['selectedRebateType'] = e.Name
          
            contractTypeList.forEach((eachcont) => {
                rebateTypeData.forEach((eachrebate) => {
                    if (eachrebate.Id == value) {

                        if (eachrebate.Name.toLowerCase() == 'revenue' && eachcont.label.toLowerCase() == 'rebate percent') {
                            list[index]['ContractTypeId'] = eachcont.value
                            list[index]['ContractQty'] = ''
                        }
                        if (eachrebate.Name.toLowerCase() == 'volume' && eachcont.label.toLowerCase() == 'rebate amount') {
                            list[index]['ContractTypeId'] = eachcont.value
                            list[index]['ContractAmount'] = ''
                        }
                        if (e.Name == 'Revenue') {
                            list[index]["ContractTypeSymbol"] = defaultPrefix;
                            list[index]["PercentageSymbol"] = '%'
                        }
                        if (e.Name == 'Volume') {
                            list[index]["ContractTypeSymbol"] = defaultPrefix;
                            list[index]["PercentageSymbol"] = ''
                        }
                    }
                })
            })
        }
        setShipList([...list]);
    }

 

    const handleInputTextChange = (e, index, names) => {
        setChangeHandle(true)
        const {
            name,
            value
        } = e.target;
        const list = [...shipList];
        list[index][names] = value;
        setShipList(list);
    }
    const RemoveItem = (idx) => {
        setChangeHandle(true)
        setShipList(shipList.filter((item, index) => index !== idx));
    }
    const ToggleActiveStatus = (id, Active) => {

    }
    const showApprovedDialog = (flag) => {
        if (flag === 'showApprovedDialog') {
            setCommentsForApproved("")
            setShowApprovedModal(!showApprovedModal)
        }
        else if (flag === 'proceed') {
            Approve()
            setShowApprovedModal(!showApprovedModal)

        }
    }

    const showDeniedDialog = (flag) => {
        if (flag === 'showDeniedDialog') {
            setCommentsForDenied("")
            setShowDeniedModal(!showDeniedModal)
        }
        else if (flag === 'proceed') {
            Deny()
            setShowDeniedModal(!showDeniedModal)

        }
    }
    const Deny = () => {
        let AgreementId = props.id
        let Comments = commentsForDenied
        let UserId = SessionManage.getuserid();
        let Status = 'Approved'
        let data = { AgreementId, Comments, UserId, Status }
        setLoading(true);
        Service.createorupdate('SDAgreement/DenyAgreement', data).then((res) => {
            if (typeof res !== 'undefined' && typeof res.status !== 'undefined') {
                if (res.status === 200) {
                    setLoading(false);
                    toast.success('Agreement denied successfully !', {
                        position: toast.POSITION.TOP_RIGHT,
                        onClose: () => navigate("/shipanddebit/approvals/pending")
                    });
                }
            }
            else if (res.status !== 200) {
                setErrorPopup(true)
            }
        });
    }
    const Approve = () => {
        let AgreementId = props.id
        let Comments = commentsForApproved
        let UserId = SessionManage.getuserid();
        let Status = 'Approved'
        let data = { AgreementId, Comments, UserId, Status }
        setLoading(true);
        Service.createorupdate('SDAgreement/UpdateSDAgreementStatus', data).then((res) => {
            if (typeof res !== 'undefined' && typeof res.status !== 'undefined') {
                if (res.status === 200) {
                    setLoading(false);
                    toast.success('Agreement approved successfully !', {
                        position: toast.POSITION.TOP_RIGHT,
                        onClose: () => navigate("/shipanddebit/approvals/pending")
                    });

                }
            }
            else if (res.status !== 200) {
                setErrorPopup(true)
            }
        });
    }
    const DeleteItem = (indexs, id) => {
        setChangeHandle(true)
        setLoading(true);
        setShipList(shipList.filter((item, index) => index !== indexs));
        Service.getdatabyIdUser('SDAgreement/DeleteSdagreementItem', id).then((res) => {
            if (typeof res !== 'undefined' && typeof res.status !== 'undefined') {
                if (res.status === 200) {
                    setLoading(false);
                   
                    toast.success('Agreement item deleted successfully !', {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            }

        });
    }

    const checkAll = (mycheckbox) => {
        var checkboxes = document.querySelectorAll("input[name = 'chkBox']");
        settotalselectedshow(true);
        if (mycheckbox.target.checked == true) {
            checkboxes.forEach(function (checkbox) {
                checkbox.checked = true;
            });
            settotalselectedcount(checkboxes.length)
        }
        else {
            checkboxes.forEach(function (checkbox) {
                checkbox.checked = false;
            });
            settotalselectedcount(0)
        }

    }

   


    const handleLoadMore = () => {

        let pagenumber = paging.pageNumber + 1;
        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
        Service.getdatabyId("SDAgreement/GetClaimBySDId", paging.pageSize, pagenumber, sortType, sortColName, filterLst(), searchValue).then((res) => {

            if (typeof res !== 'undefined') {
                if (res.data !== 'null' && res.data !== 'undefined') {
                    let rowData = res.data.DataList;
                    if (res.data.DataList !== 'null' && res.data.DataList !== 'undefined') {
                        const currentlength = claimData.length;
                        setClaimData(claimData => [...claimData, ...res.data.DataList])
                        LoadMore(currentlength + rowData.length, res.data.Totalcount)
                    }

                }
            }
        });


        paging.pageNumber = pagenumber
        setpaging(paging);
    };


    const class_thead = `text-white rt-thead  ${sticky}`;

    const disabledClass_dropdown = `  ${editPerms == false ? "col-md-6 textbox-padding-dropdown mt-2 fontsizedropdown disabledClass" : loadedFrom == "fromapproval" ? "col-md-6 textbox-padding-dropdown mt-2 fontsizedropdown disabledClass" : inputField.Status.toLowerCase() == 'new' ? "col-md-6 textbox-padding-dropdown mt-2 fontsizedropdown" : "col-md-6 textbox-padding-dropdown mt-2 fontsizedropdown disabledClass"}`;
    const disabledClass = `  ${editPerms == false ? "disabledClass " : loadedFrom == "fromapproval" ? "disabledClass " : inputField.Status.toLowerCase() == 'new' ? "" : "disabledClass"}`;

    const addnewfield = (data, key) => {
        let selectedKeys = multiFilterValue[key];
        let currentdata = data.map((i) => {
            i["ischecked"] = false;
            return i;
        });
        if (key != 'status') {
            if (selectedKeys != '') {
                currentdata.forEach((each) => {
                    selectedKeys.forEach((eachSelected) => {
                        if (each.Name == eachSelected.Name) {
                            each.ischecked = true
                        }
                    })
                })
            }
           
            setDropdownData(currentdata);
            setFilterDropdownData(currentdata)
        }
        else {
            if (selectedKeys != '') {
                currentdata.forEach((each) => {
                    selectedKeys.forEach((eachSelected) => {
                        if (each.Name == eachSelected.Name) {
                            each.ischecked = true
                        }
                    })
                })
                setDropdownData(currentdata)
                setFilterDropdownData(currentdata)
            }
            else {
                setDropdownData(currentdata)
                setFilterDropdownData(currentdata)
            }
        }
    }


    const checkAllFilter = (mycheckbox) => {
        var lastfilter = [...dropdownData]
        let data1 = []
        var checkboxes = document.querySelectorAll("input[name = 'filter-option-all']");
        if (mycheckbox.target.checked == true) {
          
            let currentdata = lastfilter.map((i) => {
                i["ischecked"] = true;
                return i;
            });
            setDropdownData(currentdata)
            
            dropdownData.map((each) => {
                
                const getallData = {
                    Name: each.Name,
                }
                data1.push(getallData)
            })
            setFilterValue(filterValue => [...filterValue, ...data1]);
            let updateddata = ([...filterValue, ...data1]);
            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: updateddata,
            }));
        }
        else {
            
            let currentdata = lastfilter.map((i) => {
                i["ischecked"] = false;
                return i;
            });
            setFilterValue([])
            setMultiFilterValue([])
        }

    }

    const OndropdownPopup = (e, id, name) => {
        let isChecked = e.target.checked;
        var lastfilter = [...dropdownData]
        if (isChecked === true) {
            let currentdata = lastfilter.map((i) => {
                if (i.Id == id) {
                    i["ischecked"] = true;
                }
                return i;
            });
         
            setDropdownData(currentdata);
            let data1 = []
            const getallData = {
                Name: name,
            }
            data1.push(getallData)
            setFilterValue(filterValue => [...filterValue, ...data1]);
            let updateddata = ([...filterValue, ...data1]);
            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: updateddata,
            }));
        }
        else {
            let currentdata = lastfilter.map((i) => {
                if (i.Id === id) {
                    i["ischecked"] = false;
                }
                return i;
            });
        
            setDropdownData(currentdata);
            setFilterValue(filterValue => filterValue.filter(data => data.Name !== name))
            let data1 = multiFilterValue.filter(data => data.Name !== name);
            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: data1,
            }));
        }
    }
    const UserfilterHandler = (e) => {
   
        setFilterSearch(e.target.value)
        if (e.target.value !== "") {
            let searchResults = dropdownData.filter((each) => {
                return each.Name.toLowerCase().includes(String(e.target.value).toLowerCase())
            });
            setDropdownData(searchResults);
        }
        else {
            
            setFilterSearch("")
            setDropdownData(filterdropdownData)
        }
    }

    const filterInput = (e) => {
        let inputValue = []
        setfilteringvalue(e.target.value)
        setFilterValueDict(() => ({
            ...filterValueDict, [filterCol]: e.target.value,
        }));
    }


    const FilterColumn = (name) => {

        var divtop1 = document.getElementById("table-RPL");
        var cardtop1 = document.getElementById("card-RPL");
        setdivtop(divtop1.offsetTop + cardtop1.offsetTop + 310)
        setFilterCol(name)
        setshowfilterpopup(true)

        if (name === 'claim_id') {
            setfiltertext(t('sdClaim.ClaimId'))
            var testDiv = document.getElementById("Claim_id");
            setdivleft((testDiv.offsetLeft + cardtop1.offsetLeft + 100) + "px");
        }
        if (name === 'desc') {
            setfiltertext(t('sdClaim.ClaimDesc'))
            var testDiv = document.getElementById("Claim_desc");
            setdivleft((testDiv.offsetLeft + cardtop1.offsetLeft + 100) + "px");
        }

        if (name === 'status') {
            setfiltertext(t('sdClaim.ClaimStatus'))
            var testDiv = document.getElementById("Claim_status");
            setdivleft((testDiv.offsetLeft + cardtop1.offsetLeft + 100) + "px");

        }
        if (name === 'last_modified_by') {
            setfiltertext(t('Accruals.SubmittedBy'))
            var testDiv = document.getElementById("Submitted_by");
            setdivleft((testDiv.offsetLeft + cardtop1.offsetLeft + 100) + "px");
            DropdownHandler(name)

        }
        if (name === 'last_modified_date') {
            setfiltertext(t('Accruals.SubmittedOn'))
            var testDiv = document.getElementById("Submitted_on");
            setdivleft((testDiv.offsetLeft + cardtop1.offsetLeft + 100) + "px");
        }
        if (name === 'amount') {
            setfiltertext(t('SpecialPriceAgreement.ClaimAmount'))
            var testDiv = document.getElementById("Approved_claim_amount");
            setdivleft((testDiv.offsetLeft + cardtop1.offsetLeft + 100) + "px");
        }
        if (name === 'reconcilation_id') {
            var testDiv = document.getElementById("reconcilation_id");
            setdivleft((testDiv.offsetLeft + 30) + "px");
            setfiltertext(t('ClaimReconcilation.ReconciliationId'))
        }
        if (name === 'reconcilation_status') {
            var testDiv = document.getElementById("reconcilation_status");
            setdivleft((testDiv.offsetLeft + 30) + "px");
            setfiltertext(t('ClaimReconcilation.ReconciliationStatus'))
        }
        setshowfilterpopup(true)
    }



    const DropdownHandler = (key) => {
        Service.getfilterDropdown('SDAgreement/GetSDClaimFilterValue', key).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                           
                            addnewfield(res.data, key)
                        }
                    }
                }
            }

        });
    }

    

    const filterLst = () => {
        let list = [];
        for (var key in multiFilterValue) {
            if (multiFilterValue[key].length > 0) {
                let values = ""
                multiFilterValue[key].map((i) => {
                    values += i.Name + ";";
                })
                if (values !== null && values !== '') {
                    list.push({
                        filterKey: key,
                        FilterValue: values
                    })
                }
            }
        }

        for (var key in filterValueDict) {
            if (filterValueDict[key] !== null && filterValueDict[key] !== '') {
                list.push({
                    filterKey: key,
                    FilterValue: filterValueDict[key]
                })
            }
        }
        return list;
    }


    const workflowAlert = (e, flag) => {
      
        if (flag == 'open') {
            if (workflowchart == true) {
                setShowWorkflowAlert(true)
            }
            else {
                showSubmitDialog(e, 'showSubmitDialog')
            }
        }
        if (flag == 'close') {
            setShowWorkflowAlert(false)
            showSubmitDialog(e, 'showSubmitDialog')
        }
    }

    const showSubmitDialog = (e, flag) => {
        if (flag === 'showSubmitDialog') {
            setShowSubmitModal(!showSubmitModal)

        }
        else if (flag === 'proceed') {
            setShowSubmitModal(false)
            SaveHandler()
        }
    }

    const filterHandler = (flag) => {
        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
        let pagenumber = 1;
        paging.pageNumber = pagenumber;

        if (flag === 'Apply') {
            setshowfilterpopup(false)
            let list = [];

            for (var key in multiFilterValue) {
                if (multiFilterValue[key].length > 0) {
                    let values = ""
                    multiFilterValue[key].map((i) => {
                        values += i.Name + ";";
                    })
                    if (values !== null && values !== '') {
                        list.push({
                            filterKey: key,
                            FilterValue: values
                        })
                    }
                }
            }

            for (var key in filterValueDict) {
                if (filterValueDict[key] !== null && filterValueDict[key] !== '') {
                    list.push({
                        filterKey: key,
                        FilterValue: filterValueDict[key]
                    })
                }
            }
           
            if (list.length > 0) {
                setpaging(paging);
                getClaims("SDAgreement/GetClaimBySDId", props.id, paging.pageSize, pagenumber, sortType, sortColName, list, searchValue);
            }

        }
        else {
            setshowfilterpopup(false)

            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: '',
            }));
            setFilterValueDict(() => ({
                ...filterValueDict, [filterCol]: '',
            }));

            let list = [];
            for (var key in multiFilterValue) {
                if (key !== filterCol && multiFilterValue[key].length > 0) {
                    let values = ""
                    multiFilterValue[key].map((i) => {
                        values += i.Name + ";";
                    })
                    if (values !== null && values !== '') {
                        list.push({
                            filterKey: key,
                            FilterValue: values
                        })
                    }
                }
            }

            for (var key in filterValueDict) {
                if (filterValueDict[key] !== null && filterValueDict[key] !== '' && key !== filterCol) {
                    list.push({
                        filterKey: key,
                        FilterValue: filterValueDict[key]
                    })
                }
            }
            setpaging(paging);
            getClaims("SDAgreement/GetClaimBySDId", props.id, paging.pageSize, pagenumber, sortType, sortColName, list, searchValue);
            setFilterCol('')
            setfilteringvalue([])
            setDropdownData([])
            setDropdownChange([])
            setFilterValue('')
            setFilterSearch("")
            setFilterDropdownData([])
        }
    }

    const SortColumn = (colName) => {

        var sortType = '';
        if (columnAscending === true) {
            setColumnAscending(false);
            sortType = "Descending";
        } else {
            setColumnAscending(true);
            sortType = "Ascending";
        }
        setSortColName(colName);
        let pagenumber = 1;
        paging.pageNumber = pagenumber;
        setpaging(paging);
        getClaims("SDAgreement/GetClaimBySDId", props.id, paging.pageSize, pagenumber, sortType, colName, filterLst(), searchValue);

    }

    const searchHandler = (event) => {
        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
        let pagenumber = 1;
        paging.pageNumber = pagenumber;
        setpaging(paging);
      
        if (event.target.value != '') {
            setSearchValue(String(event.target.value).toLowerCase())
            getClaims("SDAgreement/GetClaimBySDId", props.id, paging.pageSize, pagenumber, sortType, sortColName, filterLst(), event.target.value);
        }
        else {

            setSearchValue(null);
            getClaims("SDAgreement/GetClaimBySDId", props.id, paging.pageSize, pagenumber, sortType, sortColName, filterLst(), null);
        }
    }

    const handleChange = (e, id, status, name) => {
        let isChecked = e.target.checked;   // true if it is checked false if unchecked

        let checkedBoxes = document.querySelectorAll('input[name=chkBox]:checked');
        if (isChecked === true) {
            setshowbuttons(true);
            settotalselectedshow(true);
            settotalselectedcount(checkedBoxes.length);
            setCheckedValue(id)  // check box value - id
            setCheckedValueStatus(status) // check box value - status
            setCheckedValueid(name) //check box value - RA-0000
        }
        else {
            if (checkedBoxes.length === 0) {
                setshowbuttons(false);
                settotalselectedshow(false);
            }

            settotalselectedcount(checkedBoxes.length);
            setCheckedValue([])  // check box value - id
            setCheckedValueStatus([]) // check box value - status
            setCheckedValueid([]) //check box value - RA-0000
        }
    }

    const UpdateProducts = (_SelectedProducts, rplid) => {
      

        if (_SelectedProducts != null) {
            let lst = [];
            _SelectedProducts.map(i => {
             
                var pdtSplit = i.split("_")
                var pdtId = parseInt(pdtSplit[1]);
                let pdtName = productList.find((item) => item.value == pdtId)?.Name;
                const _item = {
                    lineId: 0, ProductId: pdtId, Currency: "", ContractTypeId: "", ContractTypeSymbol: "", PercentageSymbol: "", ContractPrice: "", ContractListPrice: 0, ContractQtyCondId: "", ContractQty: '', AgreementItemId: "", Id: 0, IsActive: true, ContractAmount: '', Material: pdtSplit[0], MaterialName: pdtName
                };
            
                if (!uniquePIds.has(pdtId)) {
                    lst.push(_item);
                    setUniquePIds((prevUniquePIds) => {
                        const updatedUniquePIds = new Set(prevUniquePIds);
                        updatedUniquePIds.add(pdtId);
                        return updatedUniquePIds;
                    });
                    setShipList((shipList) => [...shipList, _item]);
                }


            });

        }
        setShowProductPopup(false)
    }

    const CloseProductPicker = () => {

        setShowProductPopup(false)
    }
    const SelectedProductTypeHandler = (event) => {
        setSelectedType(event.target.value);

        if (event.target.value === 'Heirarchy') {
            setPrevSelectedTypeState("Attribute");
        }
        else {
            setPrevSelectedTypeState("Heirarchy");
        }
    }


    const showproducts = (e) => {
        setclickProduct(true);

        setsidebarwidth("55vw");
        setShowProductPopup(true);

    }
    const getdataProductSelectorByHierarchy = () => {

        if (dualListBoxOptionsByHeirarchy.length == 0) {
            Service.postdatawithId("Rebate/GetProductSelectorUpdatedByGroup").then((res) => {
                if (typeof res !== 'undefined') {
                    if (typeof res.data !== 'undefined' && res.data !== 'null') {
                        if (res.status === 200) {
                            if (res.data !== 'null' && res.data !== 'undefined') {
                              
                                setdualListBoxOptionsByHeirarchy(res.data)

                            }
                        }
                    }
                }

            });
        }
    }

    const getdataProductSelectorByAttribute = (programId) => {
        Service.getdataWithPagingandattributeSearchingByGroup('rebate/ProductSelectorByAttributeNew', '', 200000, 1, 0, [], 0).then((res) => {
            if (typeof res !== 'undefined' && typeof res.data !== 'undefined') {
                setdualListBoxOptionsByAttribute(res.data.DataList)
            }
        });
    };

    const AutoPostReconciliationData = [
        { key: '0', value: true, label: 'Yes' },
        { key: '1', value: false, label: 'No' },
    ];

    const OnChangeHandlerautoPostReconciliations = (data) => {
      
        setselectedOptionsautoPostReconciliations(data.label)
        setState({ ...state, ['autoPostReconciliations']: data.value })
        setChangeHandleHeader(true)
    }


    return (
        <div className="container-fluid content-top-gap">
            <ToastContainer autoClose={1000} />
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb my-breadcrumb">

                    <li className="breadcrumb-item"><Link to="/">{t('Home.title')}</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">
                        <Link to={updateNav} id='headertxt'>{t('Menu.Ship & Debit')}</Link></li>
                    <li className="breadcrumb-item active" id="libread" aria-current="page">{t('ship&debit.Create')}</li>


                </ol>
            </nav>
            {loading ? <><div className="loader-container"><div className="spinner"></div> </div> </> : ""}
          

            {showShipWorkflow === true ?
                <div id="mySidepanel" className="sidepanel sidepanelborder" style={{ width: sidebarwidth }}>
                    <div className="container" id="workflow">
                        <div className="row mt-3">
                            <div className="col-lg-12 pr-lg-2">
                                <div className="card text-center card_border">
                                    <div className="card-header chart-grid__header">
                                        <div className="row">
                                            <div className="col-md-10 text-left">
                                                {t('Workflow')}
                                            </div>
                                            <div className="col-md-2">
                                                <button className="btn btn-outline-dark  btn-sm" onClick={clickCloseShipWorkflow} type="submit" style={{ width: "40px" }}>{t('Close')}</button>
                                            </div>
                                        </div>
                                    </div>
                                    <SDWorkflowApprovers id={props.id} ></SDWorkflowApprovers>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                :
                <div></div>
            }

            <div className="row">
                <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                        <div className="card-header chart-grid__header">
                            <div className="row">
                                <div className="col-md-7 text-left">
                                    {t('Menu.Ship & Debit')}

                                </div>
                                <div className="col-md-5">
                                    {Service.submitPerms(userPerms) == false ? "" : editPerms == false ? "" : inputField.Status.toLocaleLowerCase() !== "new" ? "" : <button onClick={(Event) => { SaveHandler(Event, "new") }} className="btn  btn-update btn-sm ml-2" style={{ width: 40 + "px", float: "right" }} type="submit">{t('Save')}</button>}
                                    {Service.submitPerms(userPerms) == false ? "" : editPerms == false ? "" : inputField.Status.toLocaleLowerCase() !== "new" ? "" : <button onClick={(event) => workflowAlert(event, 'open')} className="btn  btn-update btn-sm ml-2" style={{ width: 50 + "px", float: "right" }} type="submit">{t('Submit')}</button>}

                                    <button className="btn  btn-update btn-sm ml-2" style={{ width: 65 + "px", float: "right" }} type="submit" onClick={() => { clickShowShipWorkflow() }}>{t('Workflow')}</button>
                                    {loadedFrom == "fromapproval" || loadedFrom == "fromapprovalcompleted" ? "" : <button className="btn  btn-update btn-sm ml-2" style={{ width: 45 + "px", float: "right" }} type="submit" onClick={(Event) => { closeShipAgreement('Open') }}>{t('Close')}</button>}

                                    {editPerms == false ? "" : loadedFrom == "fromapproval" && inputField.Status.toLocaleLowerCase() == 'inreview' ? <button type="button" className="btn btn-sm btn-update ml-2" style={{ float: "right", width: "55px" }} onClick={() => showApprovedDialog('showApprovedDialog')} >{t('Approve')}</button> : <></>}
                                    {editPerms == false ? "" : loadedFrom == "fromapproval" && inputField.Status.toLocaleLowerCase() == 'inreview' ? <button type="button" className="btn btn-sm btn-update ml-2" style={{ float: "right", width: "40px" }} onClick={() => showDeniedDialog('showDeniedDialog')}>{t('Deny')}</button> : <></>}
                                    {loadedFrom == "fromapproval" ? <button className="btn  btn-update btn-sm ml-2" style={{ width: 45 + "px", float: "right" }} type="submit" onClick={(Event) => { closeApproveAgreement() }}>{t('Close')}</button> : <></>}
                                    {loadedFrom == "fromapprovalcompleted" ? <button className="btn  btn-update btn-sm ml-2" style={{ width: 45 + "px", float: "right" }} type="submit" onClick={(Event) => { closeApproveCompleteAgreement() }}>{t('Close')}</button> : <></>}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="row mt-1">
                <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="row">
                        <div className="col-lg-12 pr-lg-2">
                            <div className="card text-center card_border">
                                <div className="card-body">
                                    <div className="container">
                                        <div className="row">
                                            <label className="col-md-6 mt-2 text-left">{t('ship&debit.AgreementId')}</label>
                                            <input name="ShipId" type="text" disabled={true} value={inputField.AgreementId} className="col-md-6 textbox-padding form-control mt-2" />
                                            <label className="col-md-6 mt-2 text-left">{t('SpecialPriceAgreement.AgreementName')}</label>
                                            <input onChange={inputsHandler} type="text" autoComplete='off' value={inputField.AgreementDesc} disabled={disableHeaderField} name="AgreementDesc" className={"col-md-6 textbox-padding mt-2 form-control" + disabledClass} />

                                            <label className="col-md-6 mt-2 text-left">{t('Rebates.Region')}<span className={classes.mandatory}>*</span></label>
                                          
                                            <Select
                                                className={disabledClass_dropdown}
                                                options={countryData}
                                                placeholder={t('Select')}
                                                value={countryData.filter(function (option) {
                                                    return option.value == inputField.RegionId;
                                                })}
                                                onChange={(event) => OndropdownChange(event, 'RegionId')}
                                                isSearchable={true}
                                                styles={Service.getSelectstyle()}
                                                isDisabled={disableHeaderField}
                                            />

                                            <label className="col-md-6 mt-2 text-left">{t('ship&debit.DistributorName')}<span className={classes.mandatory}>*</span></label>
                                            <Select
                                                id="DistributorId"
                                                className={disabledClass_dropdown}
                                                options={distributorList}
                                                placeholder={t('Select')}
                                                value={distributorList.filter(function (option) {
                                                    return option.value === inputField.DistributorId;
                                                })}
                                                onChange={(event) => OndropdownChange(event, 'DistributorId')}
                                                isSearchable={true}
                                                styles={Service.getSelectstyle()}
                                                isDisabled={disableHeaderField}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="row">
                        <div className="col-lg-12 pr-lg-2">
                            <div className="card text-center card_border">

                                <div className="card-body" >
                                    <div className="container">
                                        <div className="row">
                                            <label className="col-md-6 mt-2 text-left">{t('Rebates.StartDate')}</label>
                                            <div className='col-md-6 textbox-padding'>
                                                <DatePicker name="ValidityFrom" selected={new Date(inputField.ValidityFrom)} className={"drodownwidth  form-control form-class mt-2" + disabledClass}
                                                    onChange={(event) => OnChangeHandlerDate(event, 'ValidityFrom')}

                                                    dateFormat={SessionManage.getdateformat()} disabled={disableHeaderField} />
                                            </div>
                                            <label className="col-md-6 mt-2 text-left">{t('Rebates.EndDate')}</label>
                                            <div className='col-md-6 textbox-padding'>
                                                <DatePicker name="ValidityTo" className={"drodownwidth form-control form-class mt-2" + disabledClass}
                                                    selected={new Date(inputField.ValidityTo)}
                                                    onChange={(event) => OnChangeHandlerDate(event, 'ValidityTo')}

                                                    dateFormat={SessionManage.getdateformat()} disabled={disableHeaderField} />
                                            </div>

                                            <label className="col-md-6 mt-2 text-left">{t('Rebates.SettlementMethod')}<span className={classes.mandatory}>*</span></label>
                                            <Select
                                                className={disabledClass_dropdown}
                                                options={settlementMethodsList}
                                                placeholder={t('Select')}
                                                value={settlementMethodsList.filter(function (option) {
                                                    return option.value === inputField.SettlementMethodId;
                                                })}
                                                onChange={(event) => OndropdownChange(event, 'SettlementMethodId')}
                                                isSearchable={true}
                                                styles={Service.getSelectstyle()}
                                                isDisabled={disableHeaderField}
                                            />

                                            <label className="col-md-6 text-left" style={{ marginTop: "5px" }}>{t('Rebates.Currency')}<span className={classes.mandatory}>*</span></label>
                                            <Select
                                                className={disabledClass_dropdown}
                                                options={currencyList}
                                                placeholder={t('Select')}
                                                value={currencyList.filter(function (option) {
                                                    return option.value === inputField.CurrencyId;
                                                })}
                                                onChange={(event) => OndropdownChange(event, 'CurrencyId')}
                                                isSearchable={true}
                                                styles={Service.getSelectstyle()}
                                                isDisabled={disableHeaderField}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="row">
                        <div className="col-lg-12 pr-lg-2">
                            <div className="card text-center card_border">
                                <div className="card-body" >
                                    <div className="container">
                                        <div className="row">
                                            <label className="col-md-6  mt-2 text-left ">{t('SpecialPriceAgreement.AgreementStatus')}</label>
                                            <input onChange={inputsHandler} type="text" autoComplete='off' value={inputField.Status} name="Status" className={"col-md-6 textbox-padding mt-2 disabledClass form-control"} />

                                            <label className="col-md-6 mt-2 text-left">{t('Rebates.AutoPostReconciliation')}</label>
                                           
                                            <Select
                                                className={`col-md-6 textbox-padding-dropdown  mt-2 fontsizedropdown ${disabledClass}`}
                                                options={AutoPostReconciliationData}
                                                placeholder="Select"
                                                name="autoPostAccruals"
                                                value={AutoPostReconciliationData.filter(function (option) {
                                                    return option.label === `${selectedOptionsautoPostReconciliations}`;;
                                                })}
                                                onChange={OnChangeHandlerautoPostReconciliations}
                                                isSearchable={true}
                                                styles={customStyles}
                                                isDisabled={disableHeaderField}
                                            
                                            />
                                            <input type="text" className="col-md-6 textbox-padding mt-2 form-control" style={{ display: 'none' }} disabled={true} />
                                            <label className="col-md-6 mt-2 text-left">{t('CreatedBy')}</label>
                                            <input type="text" disabled="true" className="col-md-6 textbox-padding mt-2 form-control" value={inputField.CreatedUserName} />
                                            <label className="col-md-6 mt-2 text-left">{t('CreatedDate')}</label>
                                            <input type="text" className="col-md-6 textbox-padding mt-2 datetimepicker-input form-control" value={inputField.CreatedDate === null || inputField.CreatedDate === "" ? "" : moment(inputField.CreatedDate).format(SessionManage.getcurrentdateformat())} data-target="#reservationdate" disabled={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>  
                    </div>
                </div>
            </div>
            <div className="row mt-3">
                <div className='col-sm-12'>
                    <div className='row'>
                        <div className="col-lg-12 pr-lg-2">
                            <div className="card text-center card_border">
                                <div className="card-body" >
                                    <div className="row">
                                        <label className="col-sm-12 col-md-12 col-lg-2 pl-4 text-left">{t('Rebates.Justification')}<span className={classes.mandatory}>*</span></label>

                                        <textarea style={{ marginRight: "0.938rem", marginLeft: "0.938rem" }} className="col-md-11 col-lg-8 col-sm-12 form-control" onChange={inputsHandler} name="Justification" rows="4" disabled={disableHeaderField} value={inputField.Justification}></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                        <div className="card-header chart-grid__header">
                            <div className="row">
                                <div className="col-md-8 text-left">
                                    {t('ship&debit.Ship&DebitItems')}
                                </div>
                                {inputField.Status.toLocaleLowerCase() !== "new" ? "" :
                                    <div className="col-md-4">
                                        <button className="btn btn-outline-dark  btn-sm ml-6" onClick={showproducts} style={{ width: 95 + "px", float: "right" }} type="submit">{t('Select Products')}</button>
                                        <br />
                                    </div>
                                }
                            </div>
                        </div>

                        <div className="card-body">
                            <div className="container-fluid">
                                <div className="table-responsive" style={{ minHeight: "218px" }}>

                                    {shipList.length > 0 ?
                                        <table className="table table-bordered table-head-fixed text-nowrap table-bordered table-striped" style={{ marginBottom: "178px" }}>
                                            <thead className="thead-light">
                                                <tr className='text-left'>
                                                    <th>{t('SpecialPriceAgreement.LineId')}</th>
                                                    <th>{t('SpecialPriceAgreement.MaterialName')}</th>
                                                    <th>{t('SpecialPriceAgreement.Material')}</th>
                                                    <th>{t('Rebates.RebateType')}</th>
                                                   
                                                    <th>{t('SpecialPriceAgreement.ContractType')}</th>
                                                    <th>{t('SpecialPriceAgreement.ContractValue')}</th>
                                                  
                                                    <th>{t('ship&debit.ThresholdCondition')}</th>
                                                    <th>{t('ship&debit.ThresholdQty')}</th>
                                                    <th>{t('ship&debit.ThresholdAmount')}</th>
                                                    {inputField.Status.toLocaleLowerCase() == 'new' ? <th>{t('Rebates.Actions')}</th> : ''}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {shipList.map((i, index) => (
                                                    <tr style={{ backgroundColor: "white" }}>
                                                        <td><Form.Control className={classes['Form-Input-control'] + disabledClass} style={{ width: "60px", display: 'inline-block' }} disabled="true" value={(index + 1)} /></td>
                                                   
                                                        <td >
                                                           
                                                            <Form.Control value={i.MaterialName} className={classes['Form-Input-control'] + disabledClass} disabled={true} style={{ width: "80px" }} />
                                                        </td>
                                                        <td ><Form.Control value={i.Material} className={classes['Form-Input-control'] + disabledClass} disabled={true} /></td>
                                                        <td>
                                                            <Select onChange={(Event) => handleInputChange(Event, index, 'rebateTypeId', 'SelectedrebateTypeId')}
                                                                className={classes['Form-Input-control'] + disabledClass}
                                                                options={rebateTypeData}
                                                                placeholder={t('Select')}
                                                                value={rebateTypeData.filter(function (option) {
                                                                    return option.value == i.rebateTypeId;
                                                                })}
                                                                isSearchable={true}
                                                                styles={Service.getSelectstyle()}
                                                                isDisabled={disableHeaderField}
                                                            />

                                                        </td>

                                                      
                                                        <td> <Select onChange={(Event) => handleInputChange(Event, index, 'ContractTypeId', 'SelectedContractType')}
                                                            className={classes['Form-Input-control'] + disabledClass}
                                                            options={contractTypeList}
                                                            placeholder={t('Select')}
                                                            value={contractTypeList.filter(function (option) {
                                                                return option.value === i.ContractTypeId;
                                                            })}
                                                            isSearchable={true}
                                                            styles={Service.getSelectstyle()}
                                                            isDisabled={true}
                                                        />
                                                        </td>
                                                        {i.selectedRebateType == 'Volume' ?
                                                            <td>{i.ContractTypeSymbol}
                                                                < Form.Control onKeyPress={(e) => !/^(\d)*(\.)?([0-9]{1})?$/.test(e.key) && e.preventDefault()} value={i.ContractPrice} disabled={disableHeaderField} onChange={(Event) => handleInputTextChange(Event, index, 'ContractPrice')} className={classes['Form-Input-control'] + disabledClass} style={{ width: "80px", display: "inline-block" }} /></td>
                                                            :
                                                            <td>
                                                                <Form.Control onKeyPress={(e) => !/^(\d)*(\.)?([0-9]{1})?$/.test(e.key) && e.preventDefault()} value={i.ContractPrice} disabled={disableHeaderField} onChange={(Event) => handleInputTextChange(Event, index, 'ContractPrice')} className={classes['Form-Input-control'] + disabledClass} style={{ width: "80px", display: "inline-block" }} />{i.PercentageSymbol}</td>
                                                        }
                                                        <td>
                                                            <Select
                                                                onChange={(Event) => handleInputChange(Event, index, 'ContractQtyCondId', 'SelectedContractQtyCond')}
                                                                className={classes['Form-Input-control'] + disabledClass}
                                                                options={contractQtyConditionList}
                                                                placeholder={t('Select')}
                                                                isSearchable={true}
                                                                styles={Service.getSelectstyle()}
                                                                value={contractQtyConditionList.filter(function (option) {
                                                                    return option.value === i.ContractQtyCondId;
                                                                })}
                                                                isDisabled={disableHeaderField}
                                                            />

                                                        </td>
                                                        <td><Form.Control onKeyPress={(e) => !/^(\d)*(\.)?([0-9]{1})?$/.test(e.key) && e.preventDefault()} value={i.ContractQty == 0 ? '' : i.ContractQty} onChange={(Event) => handleInputTextChange(Event, index, 'ContractQty')} className={classes['Form-Input-control'] + disabledClass} disabled={i.selectedRebateType != 'Volume' || disableHeaderField} style={{ width: "80px" }} /></td>

                                                        {i.selectedRebateType == 'Volume' ?
                                                            <td>
                                                                <Form.Control onKeyPress={(e) => !/^(\d)*(\.)?([0-9]{1})?$/.test(e.key) && e.preventDefault()} value={i.ContractAmount == 0 ? '' : i.ContractAmount} onChange={(Event) => handleInputTextChange(Event, index, 'ContractAmount')} className={classes['Form-Input-control'] + disabledClass} disabled={i.selectedRebateType != 'Revenue'} style={{ width: "80px", display: "inline-block" }} /></td>
                                                            :
                                                            <td> {i.ContractTypeSymbol}<Form.Control onKeyPress={(e) => !/^(\d)*(\.)?([0-9]{1})?$/.test(e.key) && e.preventDefault()} value={i.ContractAmount == 0 ? '' : i.ContractAmount} onChange={(Event) => handleInputTextChange(Event, index, 'ContractAmount')} className={classes['Form-Input-control'] + disabledClass} disabled={i.selectedRebateType != 'Revenue' || disableHeaderField} style={{ width: "80px", display: "inline-block" }} /></td>
                                                        }

                                                        {inputField.Status.toLowerCase() == "new" || inputField.Status == '' ?
                                                            <td>
                                                                <div className={classes['gridwidth']} style={{ margin: "5px" }}>

                                                                    <>
                                                                       
                                                                      
                                                                        {(i.Id === 0) ?
                                                                            <>
                                                                                {shipList.length !== 0 ? <a style={{ marginLeft: "15px" }} onClick={() => RemoveItem(index)} className="">
                                                                                    <i className="fa fa-trash" style={{ color: "red", fontSize: "18px" }} title='Delete'></i>
                                                                                </a> : null
                                                                                }
                                                                            </> : ""
                                                                        }
                                                                        {(i.Id > 0) && shipList.length != 1 && loadedFrom !== "fromapproval" ? <>
                                                                            <a style={{ marginLeft: "15px" }} onClick={() => DeleteItem(index, i.Id)} className="">
                                                                                <i className="fa fa-trash" style={{ color: "red", fontSize: "18px" }} title='Delete'></i>
                                                                            </a>
                                                                        </> : ""}

                                                                    </>
                                                                </div>
                                                            </td>
                                                            :
                                                            <></>
                                                        }
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        : ''}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-3" id="card-RPL">
                <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                        <div className="card-header chart-grid__header">
                            <div className="row">
                                <div className="col-md-8 text-left">
                                    {t('ship&debit.Ship&DebitClaim')}
                                </div>

                                <div class="col-md-4">
                                    {showbuttons === true ? <>
                                        <button className="btn btn-sm btn-update ml-1" type="submit" style={{ float: "right", width: "40px" }} onClick={() => OpenHandler(checkedValue)}>{t('Open')}</button>
                                        <button className="btn btn-sm btn-update ml-1" style={{ float: "right", width: "65px" }} type="submit" onClick={() => { clickShowClaimWorkflow("Claim") }}>{t('Workflow')}</button> </>
                                        : <></>}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6" style={{ marginTop: '10px' }}>
                            <input type="text" className="form-control form-control-user" id="firstName" name="firstName" autoComplete='off' placeholder={t('SpecialPriceAgreement.insideHeader')} onChange={(event) => searchHandler(event)} />
                        </div>
                        <div className="card-body" style={{ minHeight: "35vh" }}>
                            <div className="table-responsive-lg">
                                <table className="table table-bordered text-left mt-2 table-responsive-sm" id="table-RPL">
                                    <thead className={class_thead} style={{ backgroundColor: "var(--primary)" }}>
                                        <tr>
                                            <th className="tableheader"><input type="checkbox" id="option-all" onChange={checkAll} /></th>
                                            <th id='Claim_id' style={{ backgroundColor: (filterValueDict.claim_id ? '#6cae55' : ''), maxWidth: "100px" }}>
                                                <div className='d-flex text-nowrap'>
                                                    <span>{t('sdClaim.ClaimId')}</span>
                                                    <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('ClaimId')}></span>
                                                    <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('claim_id')}></span>
                                                </div>
                                            </th>

                                            <th id='Claim_desc' style={{ backgroundColor: (filterValueDict.desc ? '#6cae55' : ''), maxWidth: "100px" }}>
                                                <div className='d-flex text-nowrap'>
                                                    <span>{t('sdClaim.ClaimDesc')}</span>
                                                    <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('ClaimDesc')}></span>
                                                    <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('desc')} ></span>
                                                </div>
                                            </th>
                                            <th id='Claim_status' style={{ backgroundColor: (filterValueDict.status ? '#6cae55' : ''), maxWidth: "100px" }}>
                                                <div className='d-flex text-nowrap'>
                                                    <span>{t('sdClaim.ClaimStatus')}</span>
                                                    <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('Status')}></span>
                                                    <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('status')} ></span>
                                                </div>
                                            </th>
                                            <th id='Approved_claim_amount' style={{ backgroundColor: (filterValueDict.amount ? '#6cae55' : ''), maxWidth: "110px" }}>
                                                <div className='d-flex text-nowrap'>
                                                    <span>{t('SpecialPriceAgreement.ClaimAmount')}</span>
                                                    <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('ClaimAmount')}></span>
                                                    <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('amount')}></span>
                                                </div>
                                            </th>
                                            <th id='Submitted_by' style={{ backgroundColor: (multiFilterValue.last_modified_by ? '#6cae55' : ''), maxWidth: "110px" }}>
                                                <div className='d-flex text-nowrap'>
                                                    <span>{t('Accruals.SubmittedBy')}</span>
                                                    <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('LastModifiedUserName')}></span>
                                                    <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('last_modified_by')} ></span>
                                                </div>
                                            </th>
                                            <th id='Submitted_on' style={{ backgroundColor: (filterValueDict.last_modified_date ? '#6cae55' : ''), maxWidth: "110px" }} >
                                                <div className='d-flex text-nowrap'>
                                                    <span>{t('Accruals.SubmittedOn')}</span>
                                                    <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('LastModifiedDate')}></span>
                                                    <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('last_modified_date')} ></span>
                                                </div>
                                            </th>

                                           
                                            <th id='reconcilation_id' style={{ backgroundColor: (filterValueDict.reconcilation_id ? '#6cae55' : ''), maxWidth: "130px" }}>
                                                <div className='d-flex text-nowrap'>
                                                    <span>{t('ClaimReconcilation.ReconcilationId')}</span>
                                                    <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('reconcilation_id')}></span>
                                                    <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('reconcilation_id')}></span>
                                                </div>
                                            </th>
                                            <th id='reconcilation_status' style={{ backgroundColor: (filterValueDict.reconcilation_status ? '#6cae55' : ''), maxWidth: "150px" }}>
                                                <div className='d-flex text-nowrap'>
                                                    <span>{t('ClaimReconcilation.ReconcilationStatus')}</span>
                                                    <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('reconcilation_status')}></span>
                                                    <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('reconcilation_status')}></span>
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            showspinner === true ?
                                                <tr>
                                                    <Spinner animation="border" size="sm" />
                                                </tr>

                                                :
                                                claimData.length === 0 ?
                                                    <tr>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td>{t('NoDataAvailable')}</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                    :
                                                    claimData.map((listVal, index) =>

                                                        <tr onDoubleClick={(event) => OpenClaim(event, listVal.Id)}>
                                                    
                                                            <td><input id={listVal.ClaimId} type="checkbox" name="chkBox" value={[listVal.ClaimId, listVal.Status]} onChange={(event) => handleChange(event, listVal.Id, listVal.Status, listVal.ClaimId)} /></td>
                                                            
                                                            <td style={{ minWidth: "110px" }}>{listVal.ClaimId}</td>
                                                            <td style={{ maxWidth: "150px" }}><div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right" title={listVal.ClaimDesc}>{listVal.ClaimDesc}</div></td>
                                                            <td style={{ minWidth: "110px" }}>{listVal.Status}</td>
                                                            <td style={{ minWidth: "110px" }}>{listVal.CurrencyPrefix}{listVal.ClaimAmount}</td>
                                                            <td style={{ minWidth: "110px" }}>{listVal.SubmittedBy}</td>
                                                            <td style={{ minWidth: "110px" }}>{listVal.SubmittedDate != null ? moment(listVal.SubmittedDate).format(SessionManage.getcurrentdateformat()) : ''}</td>
                                                            <td style={{ minWidth: "110px" }}>{listVal.ReconcilationId}</td>
                                                            <td style={{ minWidth: "110px" }}>{listVal.ReconcilationStatus}</td>
                                                        </tr>
                                                    )

                                        }

                                    </tbody>


                                </table>
                                {
                                    showLoadMore === true ?
                                        <div className='text-center container row'>
                                            <div className='col-md-5'></div>
                                            <div className='col-md-2'>
                                                <button className='btn btn-sm btn-update ml-1' onClick={handleLoadMore} style={{ width: "70px" }}>{t('LoadMore')}</button>
                                            </div>
                                            <div className='col-md-5'></div>

                                        </div>
                                        :
                                        ""
                                }

                                <div className='row'>
                                    {
                                        totalselectedshow === true ?

                                            <div className='col-lg-2 forgettext  col-sm-12 border' style={{ textAlign: "center" }}>
                                                {t('TotalRecordsSelected')} - <span id="totalselected">{totalselectedcount}</span>
                                            </div>

                                            :
                                            <div className='col-lg-3  col-sm-12' style={{ textAlign: "center" }}>

                                            </div>
                                    }
                                    <div className='col-lg-8 col-sm-12'></div>


                                    <div className='col-lg-2 forgettext col-sm-12 border form-control' style={{ textAlign: "center", marginLeft: "0.625rem" }}>
                                        {t('TotalRecords')} - {totalCountlst}
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>

            </div>
            {
                showClaimWorkflow === true ?
                    <div id="mySidepanel" className="sidepanel sidepanelborder" style={{ width: sidebarwidth }}>
                        <div className="container" id="workflow">
                            <div className="row mt-3">
                                <div className="col-lg-12 pr-lg-2">
                                    <div className="card text-center card_border">
                                        <div className="card-header chart-grid__header">
                                            <div className="row">
                                                <div className="col-md-10 text-left">
                                                    {t('Workflow')}
                                                </div>
                                                <div className="col-md-2">
                                                    <button className="btn btn-outline-dark  btn-sm" onClick={clickCloseClaimWorkflow} type="submit" style={{ width: "45px" }}>{t('Close')}</button>
                                                </div>
                                            </div>
                                        </div>
                                        <ClaimWorkflowApprovers id={checkedValue}></ClaimWorkflowApprovers>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    :
                    <div></div>
            }
            <div style={{ height: "50px" }}>&nbsp;
                <Modal show={showSubmitModal} size="bg"
                    centered>
                    <Modal.Header>
                        <Modal.Title>{t('Rebates.SubmitComments')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ "textAlign": 'center' }} >
                        <Form.Control className={classes['Textarea-width']} as="textarea" rows={2} value={commentsForSubmit} name="commentsForSubmit"
                            onChange={(e) => { setCommentsForSubmit(e.target.value) }} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button size='sm' style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "50px" }} onClick={(event) => showSubmitDialog(event, 'showSubmitDialog')}>
                            {t('Cancel')}
                        </Button>
                        <Button size='sm' style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "50px" }} onClick={(Event) => { SaveHandler(Event, "inreview") }}>
                            {t('Submit')}
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={showApprovedModal} size="bg"
                    centered>
                    <Modal.Header>
                        <Modal.Title>{t('Accruals.ApprovalComments')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ "textAlign": 'center' }} >
                        <Form.Control className={classes['Textarea-width']} as="textarea" rows={2} value={commentsForApproved} name="commentsForApproved"
                            onChange={(e) => { setCommentsForApproved(e.target.value) }} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button size='sm' style={{ background: '#eff1f9', color: 'black', borderColor: 'black', width: "50px" }} onClick={() => showApprovedDialog('showApprovedDialog')}>
                            {t('Cancel')}
                        </Button>
                        <Button size='sm' style={{ background: '#eff1f9', color: 'black', borderColor: 'black', width: "55px" }} onClick={() => showApprovedDialog('proceed')}>
                            {t('Approve')}
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showWorkflowAlert}
                    size="sm"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <Modal.Body style={{ "textAlign": 'center' }}>{t('Accruals.WorkflowAlert')}</Modal.Body>
                    <Modal.Footer>
                        <Button size='sm' style={{ background: '#eff1f9', color: 'black', borderColor: 'black', width: "30px" }} onClick={(e) => workflowAlert(e, 'close')}>
                            {t('Ok')}
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showDeniedModal} size="bg"
                    centered>
                    <Modal.Header>
                        <Modal.Title>{t('Accruals.DeniedComments')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ "textAlign": 'center' }} >
                        <Form.Control className={classes['Textarea-width']} as="textarea" rows={2} value={commentsForDenied} name="commentsForDenied"
                            onChange={(e) => { setCommentsForDenied(e.target.value) }} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button size='sm' style={{ background: '#eff1f9', color: 'black', borderColor: 'black', width: "50px" }} onClick={() => showDeniedDialog('showDeniedDialog')}>
                            {t('Cancel')}
                        </Button>
                        <Button size='sm' style={{ background: '#eff1f9', color: 'black', borderColor: 'black', width: "40px" }} onClick={() => showDeniedDialog('proceed')}>
                            {t('Deny')}
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={errorPopup} size="bg"
                    centered>
                    <Modal.Header>
                        <Modal.Title></Modal.Title>
                    </Modal.Header>
                    <Modal.Body  >
                        <label style={{ fontWeight: 'bold' }}>{errorDetail}</label>
                        <br></br>
                        <label>
                            {errorProduct.split("~").map(function (item) {
                                return (
                                    <>   {item}<br />
                                    </>
                                )
                            })}
                        </label>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button size='sm' style={{ background: '#eff1f9', color: 'black', borderColor: 'black', width: "45px" }} onClick={() => setErrorPopup(false)}>
                            {t('Close')}
                        </Button>
                    </Modal.Footer>
                </Modal>
                <CustomErrorPopup ErrorMessage={errorMessage} show={showErrorModal} ErrorStatus={errorStatus}
                    onHide={() => setShowErrorModal(false)}></CustomErrorPopup>

                {
                    showfilterpopup === true ?
                        <div className='pop-up' style={{ left: divleft, top: divtop, width: "200px" }}>
                            {filterCol === 'claim_id' || filterCol === 'desc' || filterCol === 'status' || filterCol === 'amount' || filterCol === 'reconcilation_id' || filterCol === 'reconcilation_status' ?

                                <>
                                    <div className="container">
                                        <div className="row">
                                            <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>
                                        </div>
                                    </div>
                                    <div className="container mt-2">
                                        <div className="row">
                                            <input type='textbox' placeholder={t('Search')} className='filtertextboxbackground col-md-11 textbox-padding ml-2' style={{ outline: 'none' }} value={filterValueDict[filterCol]} onChange={(event) => filterInput(event)}></input>
                                        </div>
                                    </div>
                                </>

                                :
                                <>
                                </>

                            }

                            {filterCol === 'last_modified_date' ?

                                <>
                                    <div className="container">
                                        <div className="row">
                                            <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>
                                        </div>
                                    </div>
                                    <div className="container mt-2">
                                        <div className="row">
                                            <input type='date' className='filtertextboxbackground col-md-11 textbox-padding ml-2' value={filterValueDict[filterCol]} onChange={(event) => filterInput(event)}></input>
                                        </div>
                                    </div>
                                </>
                                :
                                <></>
                            }

                            {filterCol === 'last_modified_by' ?

                                <>
                                    <div className="container">
                                        <div className="row">
                                            <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>

                                        </div>

                                    </div>

                                    <div className="container mt-2">
                                        <div className="row">
                                            <input type="text" placeholder={t('Search')} value={filterSearch} onChange={(event) => UserfilterHandler(event)} className='filtertextboxbackground col-md-11 textbox-padding ml-2' />
                                        </div>

                                        <div className="row contentoverflow mt-2">
                                            <table className="filtertable">
                                                <tbody>
                                                    <tr>
                                                        <td><input type="checkbox" name="filter-option-all" id="option-all" onChange={checkAllFilter} /></td>
                                                        <td>{t('SelectAll')}</td>
                                                    </tr>
                                                    {
                                                        dropdownData.map((item, index) => (

                                                            <tr key={index}>

                                                                <td><input id={item.Id} data-index="1" name="filter-option-all" checked={item.ischecked} type="checkbox" onChange={(event) => OndropdownPopup(event, item.Id, item.Name)} /></td>

                                                                <td>{item.Name}</td>

                                                            </tr>


                                                        ))
                                                    }

                                                </tbody>
                                            </table>

                                        </div>
                                    </div>
                                </>
                                :
                                <></>
                            }

                         

                            <div style={{ paddingTop: '10px', paddingLeft: '33px', paddingBottom: '7px' }}>

                                <button className="btn btn-sm btn-update" style={{ width: "45px" }} onClick={() => filterHandler('Apply')}>{t('Apply')}</button>
                                <button className="btn btn-sm btn-update" style={{ marginLeft: '10px', width: "45px" }} onClick={() => filterHandler('Reset')}>{t('Reset')}</button>
                            </div>
                            <div>
                            </div>
                        </div>
                        :
                        <></>
                }

                <Modal show={closeModal}
                    size="sm"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <Modal.Body style={{ "textAlign": 'center', fontFamily: 'Segoe UI' }}>{t('Rebates.YourChangesWillBeLost!AreYouSureYouWantToClose?')}</Modal.Body>
                    <Modal.Footer>
                        <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "45px" }} size='sm' onClick={() => closeShipAgreement("Close")}>
                            {t('Close')}
                        </Button>
                        <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "50px" }} size='sm' onClick={() => closeShipAgreement("Cancel")}>
                            {t('Cancel')}
                        </Button>
                    </Modal.Footer>
                </Modal>
                {
                    showProductPopup === true ?
                        <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, padding: "0" }}>
                            <div className="container" style={{ padding: "0" }}>
                                <div className="row">
                                    <div className="col-lg-12 pr-lg-2">
                                        <div className="card text-center card_border">
                                            <div className="card-header chart-grid__header">
                                                <div className="row">
                                                    <div className="col-md-10 text-left">
                                                        {t('Rebates.ProductListing')}
                                                    </div>
                                                    <div className="col-md-2">

                                                        <button className="btn btn-outline-dark  btn-sm ml-1" onClick={CloseProductPicker} type="submit" style={{ width: "45px" }}>{t('Close')}</button>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className="row mt-1">
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-lg-12 pr-lg-2">
                                                <div className="card text-center card_border">
                                                    <div className="card-header chart-grid__header">

                                                    </div>
                                                    <div style={{ minHeight: "66vh" }}>
                                                        <div className="container">
                                                            <div className='col-md-12'>
                                                           
                                                                <ProductTreeDialog
                                                                    show={showProductPopup}
                                                                    onOkClick={UpdateProducts}
                                                                    onHide={() => CloseProductPicker}
                                                                    dataByHeirarchy={dualListBoxOptionsByHeirarchy}
                                                                    rebatelinestatus={rebatelinestatus}
                                                                    dialogType="Product"
                                                                    SelectedData={FilteredProducts}
                                                                    dataByAtrribute={dualListBoxOptionsByHeirarchy}
                                                                    selectedType={selectedType}
                                                                    programLineStatus={rebatelinestatus}
                                                                    dualListBoxOptionsByAttribute={dualListBoxOptionsByAttribute}
                                                                >
                                                                    <div className={classes['Radiobutton-header']} style={{ marginBottom: "5px" }}>
                                                                        <div className={classes['Radiobutton-internal-div']} >
                                                                            <input type="radio" checked={selectedType === 'Attribute'}
                                                                                value="Attribute" onChange={SelectedProductTypeHandler}
                                                                                name="product" className={classes['radio-button-Right']} /> {t('Rebates.Attribute')}
                                                                            <input type="radio" checked={selectedType === 'Heirarchy'}
                                                                                onChange={SelectedProductTypeHandler}
                                                                                className={classes['radio-button-Left']} value="Heirarchy" name="product" /> {t('Rebates.Hierarchy')}

                                                                        </div>
                                                                    </div>
                                                                </ProductTreeDialog>


                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                   

                                </div>


                            </div>
                        </div>
                        :
                        ""
                }
            </div>
        </div >
    )
}
export default CreateNewShipAndDebit