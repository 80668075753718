import React, { Component } from 'react' 
import { useNavigate ,useParams } from 'react-router-dom';

// import NewMassActionSubmitClaim from '../../Components/MassAction/CreateNewMassAction';
import NewMassActionSubmitClaim from '../../../../Components/MassActionSubmit/MassSubmitForClaims/CreateNewMassActionSubmitClaim';

function CreateNewMassActionSubmitClaim(props){
    let navigate = useNavigate();
    const params = useParams();
     
     return (  
         <div> 

             <NewMassActionSubmitClaim{...props}  navigate={navigate} id={params.id} name={params.name} />
         </div>
     );  

}
export default CreateNewMassActionSubmitClaim
