import { Color } from 'ag-grid-community';
import { Link, useLocation } from "react-router-dom"
import React, { Component } from 'react' 
import { useNavigate ,useParams } from 'react-router-dom';


function NotfoundComponent(props){
    let navigate = useNavigate();
    const params = useParams();
     
     return (  
        <div class="container-fluid content-top-gap">



        <nav aria-label="breadcrumb">
            <ol class="breadcrumb my-breadcrumb">
                <li class="breadcrumb-item"><Link to="/">Home</Link></li>
                <li class="breadcrumb-item active" aria-current="page">Access Denied</li>
                {/* <li class="breadcrumb-item active" aria-current="page">Add Role</li> */}
            </ol>
        </nav>
        
        <div class="">
                <div class="row">
                    <div class="col-lg-12 pr-lg-2">
                        <div class="card text-center card_border">

                            <div class="card-body" style={{ minHeight: "170vh" }}>
                                <div class="row">
                      <div class="col-md-3"></div>
                      <div>
                      <h5 style={{ color: "red" }}>  You do not have permission to access this page, please refer to your system administrator</h5>
                      </div>
                      <div class="col-md-3"></div>
                                </div>
                                </div>
                                </div>
                                </div>
                                </div>


        </div>
       
        </div>
     );  

}
export default NotfoundComponent
