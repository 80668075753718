import React, { useEffect, useState, useRef } from 'react';
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import { Dropdown, Button, InputGroup, FormControl, Modal } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import Service from '../../../Services/Service';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import Select from "react-select";
import SessionManage from '../../../SessionManagement/SessionManage';
import { CSVLink } from "react-csv";
import { useTranslation } from "react-i18next";
import CustomErrorPopup from '../../../UI/CustomErrorPopup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function MassActionSubmitShipAndDebitList(props) {

    // Mass Action Submit Ship & Debit List Page

    let loggedInUser = sessionStorage.getItem("id")
    const csvInstance = useRef();
    const [t, i18n] = useTranslation('translation');
    const [csvList, setCsvList] = useState([]);

    const [headers, setHeaders] = useState([
        { label: "Mass Action Id", key: "MassActionId" },
        { label: "Description", key: "Desc" },
        { label: "Created By", key: "CreatedUser" },
        { label: "Created Date", key: "CreatedDate" },
        { label: "Last Modified By", key: "LastModifiedUser" },
        { label: "Last Modified Date", key: "LastModifiedDate" },
        { label: "Status", key: "Status" }
    ]);

    const [sortColName, setSortColName] = useState("");
    const [sticky, setSticky] = useState("");
    const [userPerms, setuserPermission] = useState([]);
    const location = useLocation();
    const navigate = useNavigate();
    const [showbutton, setshowbutton] = useState(false);
    const [showspinner, setshowspinner] = useState(false);
    const [filterValue, setFilterValue] = useState('');
    const [filterCol, setFilterCol] = useState('');
    const [showfilterpopup, setshowfilterpopup] = useState(false);
    const [dropdownData, setDropdownData] = useState([]);
    const [searchValue, setSearchValue] = useState(null)
    const [filterdropdownData, setFilterDropdownData] = useState([]);
    const [filterSearch, setFilterSearch] = useState("");
    const [filtertext, setfiltertext] = useState('');
    const [filterValueDict, setFilterValueDict] = useState({
        MassActionId: '',
        Desc: '',
        CreatedDate: '',
        LastModifiedDate: '',
        Status: ''
    });
    const [multiFilterValue, setMultiFilterValue] = useState({
        CreatedUser: '',
        LastModifiedUser: ''
    });

    const [totalselectedshow, settotalselectedshow] = useState('');
    const [totalselectedcount, settotalselectedcount] = useState(0);
    const [totalCountlst, settotalCountlst] = useState('');
    const checkAll = (mycheckbox) => {
        var checkboxes = document.querySelectorAll("input[type = 'checkbox']");
        if (mycheckbox.target.checked == true) {
            settotalselectedshow(true);
            checkboxes.forEach(function (checkbox) {
                checkbox.checked = true;
            });
            settotalselectedcount(totalCountlst)
        }
        else {
            settotalselectedshow(false);
            checkboxes.forEach(function (checkbox) {
                checkbox.checked = false;
            });
            settotalselectedcount(0)
        }

    }
    const customStyles = {
        control: base => ({
            ...base,
            minHeight: 28
        }),
        dropdownIndicator: base => ({
            ...base,
            padding: 2
        }),
        clearIndicator: base => ({
            ...base,
            padding: 2
        }),
        multiValue: base => ({
            ...base,

        }),
        valueContainer: base => ({
            ...base,
            padding: '0px 2px'
        }),
        input: base => ({
            ...base,
            margin: 0,
            padding: 0
        })
    };
    let rowData = [];

    const [listViewData, setListViewData] = useState(rowData)
    const [filteredArray, setFilteredArray] = useState(rowData)
    const [state, setState] = useState({ status: '2' });
    const [checkedValueid, setCheckedValueid] = useState([]);
    const [columnAscending, setColumnAscending] = useState(true);
    const [checkedMultiValueid, setCheckedMultiValueid] = useState([]);
    const [multiValueids, setMultiValueIds] = useState([]);
    const [errorDeleteModal, setErrorDeleteModal] = useState(false);
    const [showDeleteModal, toggleModal] = useState(false)
    const [deleteshowbutton, setDeleteshowbutton] = useState('');
    const [checkedValue, setCheckedValue] = useState([]);
    const [checkedValueStatus, setCheckedValueStatus] = useState([]);
    const [paging, setpaging] = useState({
        pageSize: 20,
        pageNumber: 1
    })
    const [operatorVal, setOperatorValue] = useState({ value: "=", label: "equals" })
    const [operatorValOption, setOperatorValueOption] = useState([
        { value: "=", label: "equals" },
        { value: "<", label: "less then" },
        { value: ">", label: "greater then" },
        { value: "<=", label: "less or equal" },
        { value: ">=", label: "greater or equal" }
    ])

    const [showLoadMore, setshowLoadMore] = useState('');
    let showErrorMsg = false;

    useEffect(() => {
        getUserPermission(location.pathname);
        setColumnAscending(false);
        setSortColName("Id");
        settotalselectedshow(false);
        setshowspinner(true);
        getMassActionViewData("MassAction/GetALLSubmit", paging.pageSize, paging.pageNumber, "Descending", "Id", [], searchValue, "ShipAndDebit");
        getColumnVisibilityList()
        getColVisibilityListData()
        window.addEventListener("scroll", isSticky);
        return () => {
            window.removeEventListener("scroll", isSticky);
        };
    }, [])
    const getUserPermission = (pathname) => {
        Service.GetUserPermission(pathname).then((res) => {
            if (typeof res !== 'undefined') {

                if (res.data !== 'null' && typeof res.data !== 'undefined' && res.data !== '') {
                    if (typeof res.data.DataList !== 'undefined') {
                        setuserPermission(res.data.DataList)
                    }
                }
            }
        });
    }
    const isSticky = () => {
        const scrollTop = window.scrollY;
        const stickyClass = scrollTop >= 60 ? "is-sticky-thead" : "";
        setSticky(stickyClass);
        const scrollTopActual = scrollTop >= 60 ? scrollTop - 85 : 0;
        var divtop1 = document.getElementById("tableA");
        setdivtop(divtop1.offsetTop + scrollTopActual)
    };
    const class_thead = `text-white rt-thead  ${sticky}`;


    const getMassActionViewData = (method, pageSize, pageNumber, sortType, SortProperty, filterValue, search, ActionFor) => {

        Service.getdataWithPagingByMassGroupSearch(method, pageSize, pageNumber, sortType, SortProperty, filterValue, search, ActionFor).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {

                            rowData = res.data.DataList

                            setListViewData(rowData)
                            setFilteredArray(rowData)

                            settotalCountlst(res.data.Totalcount)
                            setshowspinner(false);
                            LoadMore(rowData.length, res.data.Totalcount)
                        }
                    }
                }
            }

        });
    }








    const searchHandler = (event) => {
        var sortType = columnAscending === true ? 'Ascending' : 'Descending';

        let pagenumber = 1;

        paging.pageNumber = pagenumber;
        setpaging(paging);
        if (event.target.value != '') {
            setSearchValue(String(event.target.value).toLowerCase())
            getMassActionViewData("MassAction/GetALLSubmit", paging.pageSize, pagenumber, sortType, sortColName, filterLst(), event.target.value, "ShipAndDebit");
        }
        else {
            setSearchValue(null);
            getMassActionViewData("MassAction/GetALLSubmit", paging.pageSize, pagenumber, sortType, sortColName, filterLst(), null, "ShipAndDebit");
        }
    }


    const handleLoadMore = () => {

        let pagenumber = paging.pageNumber + 1;
        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
        paging.pageNumber = pagenumber
        setpaging(paging);
        Service.getdataWithPagingByMassGroupSearch("MassAction/GetALLSubmit", paging.pageSize, pagenumber, "Descending", "Id", [], searchValue, "ShipAndDebit").then((res) => {

            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                            rowData = res.data.DataList;

                            if (res.data.DataList !== 'null' && res.data.DataList !== 'undefined') {
                                const currentlength = listViewData.length;
                                setListViewData(listViewData => [...listViewData, ...res.data.DataList])
                                setFilteredArray(filteredArray => [...filteredArray, ...res.data.DataList])

                                LoadMore(currentlength + rowData.length, res.data.Totalcount)
                            }

                        }
                    }
                }
            }

        });

    };


    const handleChange = (e, id, status, name) => {
        let isChecked = e.target.checked;   // true if it is checked false if unchecked

        let checkedBoxes = document.querySelectorAll('input[name=chkBox]:checked');
        if (isChecked === true) {
            setCheckedValue(e.target.value)  // check box value
            setCheckedValueStatus(status)
            settotalselectedshow(true);
            settotalselectedcount(checkedBoxes.length);
            setCheckedValueid(id)

        }
        else {
            setCheckedValue([])  // check box value
            setCheckedValueStatus([])
            setCheckedValueid([])
            settotalselectedshow(false);

            settotalselectedcount(checkedBoxes.length);
        }

        if (checkedBoxes.length == 1) {
            setshowbutton(true);
            setDeleteshowbutton(false)
        }
        else if (checkedBoxes.length > 1) {
            setshowbutton(false);
            setDeleteshowbutton(true)
        }
        else {
            setshowbutton(false);
            setDeleteshowbutton(false)
        }
    }
    const OpenHandler = (id) => {
        if (id == null || id == 0) {
            alert('Please select the Mass Action to view')
        }
        else {
            navigate("/CreateNewMassActionSubmitShipAndDebit/edit/" + id);
        }
    }


    const filterInput = (e) => {
        let inputValue = e.target.value
        setFilterValue(inputValue)
        setFilterValueDict(() => ({
            ...filterValueDict, [filterCol]: e.target.value,
        }));
    }

    const OndropdownChange = (e, id, name) => {
        let isChecked = e.target.checked;
        var lastfilter = [...dropdownData]
        if (isChecked === true) {
            let currentdata = lastfilter.map((i) => {
                if (i.Id == id) {
                    i["ischecked"] = true;
                }
                return i;
            });

            setDropdownData(currentdata);
            let data1 = []
            const getallData = {
                Name: name,
            }
            data1.push(getallData)
            setFilterValue(filterValue => [...filterValue, ...data1]);

            let updateddata = ([...filterValue, ...data1]);
            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: updateddata,
            }));
        }
        else {
            let currentdata = lastfilter.map((i) => {
                if (i.Id === id) {
                    i["ischecked"] = false;
                }
                return i;
            });

            setDropdownData(currentdata);
            setFilterValue(filterValue => filterValue.filter(data => data.Name !== name))
            let data1 = filterValue.filter(data => data.Name !== name);
            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: data1,
            }));
        }
    }

    const [divleft, setdivleft] = useState('');
    const [divtop, setdivtop] = useState('');

    const FilterColumn = (name) => {
        const scrollTop = window.scrollY;
        const scrollTopActual = scrollTop >= 60 ? scrollTop - 85 : 0;
        var divtop1 = document.getElementById("tableA");
        setdivtop(divtop1.offsetTop + scrollTopActual + 30)


        setFilterCol(name)
        setshowfilterpopup(true)

        if (name === 'MassActionId') {
            setfiltertext(t('MassAction.massActionId'))
            var testDiv = document.getElementById("MassActionId");
            setdivleft((testDiv.offsetLeft + 30) + "px");
        }
        if (name === 'Desc') {
            setfiltertext(t('MassAction.Description'))
            var testDiv = document.getElementById("Desc");
            setdivleft((testDiv.offsetLeft + 30) + "px");
        }
        if (name === 'CreatedUser') {
            setfiltertext(t('CreatedBy'))
            var testDiv = document.getElementById("CreatedUser");
            setdivleft((testDiv.offsetLeft + 30) + "px");
            DropdownHandler(name);
        }
        if (name === 'CreatedDate') {
            setfiltertext(t('CreatedDate'))
            var testDiv = document.getElementById("CreatedDate");
            setdivleft((testDiv.offsetLeft + 30) + "px");

        }
        if (name === 'LastModifiedUser') {
            setfiltertext(t('ModifiedBy'))
            var testDiv = document.getElementById("LastModifiedUser");
            setdivleft((testDiv.offsetLeft + 30) + "px");
            DropdownHandler(name);
        }

        if (name === 'LastModifiedDate') {
            setfiltertext(t('ModifiedDate'))
            var testDiv = document.getElementById("LastModifiedDate");
            setdivleft(((testDiv.offsetLeft + 30) - 100) + "px");
        }
        if (name === 'Status') {
            setfiltertext(t('Status'))
            var testDiv = document.getElementById("Status");
            setdivleft(((testDiv.offsetLeft + 30) - 100) + "px");
        }

        setshowfilterpopup(true)
    }


    const DropdownHandler = (key) => {
        Service.getfilterDropdownByGroup('MassAction/GetMassActionFilterValue', key).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                            // setDropdownData(res.data)
                            addnewfield(res.data, key);
                        }
                    }
                }
            }

        });
    }

    const UserfilterHandler = (e) => {
        setFilterSearch(e.target.value)
        if (e.target.value !== "") {
            let searchResults = dropdownData.filter((each) => {
                return each.Name.toLowerCase().includes(String(e.target.value).toLowerCase())
            });
            setDropdownData(searchResults);
        }
        else {

            setFilterSearch("")
            setDropdownData(filterdropdownData)
        }
    }

    const addnewfield = (data, key) => {

        let currentdata = data.map((i) => {
            i["ischecked"] = false;
            return i;
        });
        let selectedKeys = multiFilterValue[key];
        if (key != 'active') {
            if (selectedKeys != '') {
                currentdata.forEach((each) => {
                    selectedKeys.forEach((eachSelected) => {
                        if (each.Name == eachSelected.Name) {
                            each.ischecked = true
                        }
                    })
                })
            }

            setDropdownData(currentdata);
            setFilterDropdownData(currentdata)
        }
        else {
            if (selectedKeys != '') {
                currentdata.forEach((each) => {
                    selectedKeys.forEach((eachSelected) => {
                        if (each.Name == eachSelected.Name) {
                            each.ischecked = true
                        }
                    })
                })
                setDropdownData(currentdata)
                setFilterDropdownData(currentdata)
            }
            else {
                setDropdownData(currentdata)
                setFilterDropdownData(currentdata)
            }
        }
    }

    const checkAllFilter = (mycheckbox) => {
        var lastfilter = [...dropdownData]
        let data1 = []
        var checkboxes = document.querySelectorAll("input[name = 'filter-option-all']");
        if (mycheckbox.target.checked == true) {

            let currentdata = lastfilter.map((i) => {
                i["ischecked"] = true;
                return i;
            });
            setDropdownData(currentdata)
            lastfilter.map((each) => {

                const getallData = {
                    Name: each.Name,
                }
                data1.push(getallData)
            })
            setFilterValue(filterValue => [...filterValue, ...data1]);
            let updateddata = ([...filterValue, ...data1]);
            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: updateddata,
            }));
        }
        else {

            let currentdata = lastfilter.map((i) => {
                i["ischecked"] = false;
                return i;
            });
            setFilterValue([])
            setMultiFilterValue([])
        }
    }



    const filterHandler = (flag) => {

        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
        let pagenumber = 1;
        paging.pageNumber = pagenumber;

        if (flag === 'Apply') {
            setshowfilterpopup(false)
            let list = [];

            for (var key in multiFilterValue) {
                if (multiFilterValue[key].length > 0) {
                    let values = ""
                    multiFilterValue[key].map((i) => {
                        values += i.Name + ";";
                    })
                    if (values !== null && values !== '') {
                        list.push({
                            filterKey: key,
                            FilterValue: values
                        })
                    }
                }
            }

            console.log(filterValueDict, 'fv');
            for (var key in filterValueDict) {

                if (filterValueDict[key] !== null && filterValueDict[key] !== '') {
                    list.push({
                        filterKey: key,
                        FilterValue: filterValueDict[key]
                    })
                }
            }


            if (list.length > 0) {
                let pagesize = 20;
                let pagenumber = 1;
                paging.pageSize = pagesize;
                paging.pageNumber = pagenumber;
                setpaging(paging);
                if (list.length > 0) {
                    setpaging(paging);
                    getMassActionViewData("MassAction/GetALLSubmit", paging.pageSize, pagenumber, sortType, sortColName, list, searchValue, "ShipAndDebit");
                }
            }
        }
        else {
            setshowfilterpopup(false)
            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: '',
            }));
            setFilterValueDict(() => ({
                ...filterValueDict, [filterCol]: '',
            }));

            let list = [];
            for (var key in multiFilterValue) {
                if (key !== filterCol && multiFilterValue[key].length > 0) {
                    let values = ""
                    multiFilterValue[key].map((i) => {
                        values += i.Name + ";";
                    })
                    if (values !== null && values !== '') {
                        list.push({
                            filterKey: key,
                            FilterValue: values
                        })
                    }
                }
            }

            for (var key in filterValueDict) {
                if (filterValueDict[key] !== null && filterValueDict[key] !== '' && key !== filterCol) {
                    list.push({
                        filterKey: key,
                        FilterValue: filterValueDict[key]
                    })
                }
            }

            setpaging(paging);
            getMassActionViewData("MassAction/GetALLSubmit", paging.pageSize, pagenumber, sortType, sortColName, list, searchValue, "ShipAndDebit");
            setFilterCol('')
            setFilterValue('')
            setDropdownData([])
            setFilterDropdownData([])
            setFilterSearch("")

            if (filterCol === 'accruals_aggregate') {
                setOperatorValue({ value: "=", label: "equals" });
            }
        }
    }


    const OpenAccrual = (event, id) => {
        if (event.detail === 2) {

            navigate("/CreateNewMassActionSubmitShipAndDebit/edit/" + id)
        }
    }


    const SortColumn = (colName) => {
        var sortType = '';
        if (columnAscending === true) {
            setColumnAscending(false);
            sortType = "Descending";
        } else {
            setColumnAscending(true);
            sortType = "Ascending";
        }
        setSortColName(colName);

        let pagenumber = 1;

        paging.pageNumber = pagenumber;
        setpaging(paging);
        getMassActionViewData("MassAction/GetALLSubmit", paging.pageSize, paging.pageNumber, sortType, colName, filterLst(), searchValue, "ShipAndDebit");
    }

    const filterLst = () => {
        let list = [];

        for (var key in multiFilterValue) {
            if (multiFilterValue[key].length > 0) {
                let values = ""
                multiFilterValue[key].map((i) => {
                    values += i.Name + ";";
                })
                if (values !== null && values !== '') {
                    list.push({
                        filterKey: key,
                        FilterValue: values
                    })
                }
            }
        }
        return list;
    }

    const LoadMore = (lst, totalcount) => {

        if (lst < totalcount) {
            setshowLoadMore(true);
        }
        else if (lst >= totalcount) {
            setshowLoadMore(false);
        }
        else {
            setshowLoadMore(false);
        }

    }



    const ExporttoCSV = () => {
        let checkedBoxes = document.querySelectorAll('input[name=chkBox]:checked');
        let values = "";
        if (checkedBoxes.length >= 1) {
            for (let checkbox of checkedBoxes) {
                values = values + ';' + checkbox.id;

            }
        }
        let objFilter = [];
        let objFilterValue = [{
            filterKey: "MassActionIdNo",
            FilterValue: values,
        }]

        objFilter = objFilterValue;


        Service.getDatawithMultiFilterSearchRebate("MassAction/GetALLSubmit", 20000, 1, "Ascending", "Id", objFilter, null, "ShipAndDebit").then((res) => {

            if (typeof res !== 'undefined') {
                if (res.data !== 'null' && res.data !== 'undefined') {

                    console.log(res.data.DataList, 'data-list');
                    console.log(hideColumn, 'hide');
                    console.log(typeof hideColumn);

                    console.log(res.data.DataList, 'data-list');

                    let csvLst = res.data.DataList;

                    let filteredCsvList = csvLst.map(item => {
                        let newItem = {};
                        Object.keys(item).forEach(key => {
                            if (!hideColumn.includes(key)) {
                                newItem[key] = item[key];
                            }
                        });
                        return newItem;
                    });

                    let filteredHeaders = headers.filter(header => !hideColumn.includes(header.key));

                    setCsvList(filteredCsvList);
                    setHeaders(filteredHeaders);

                    console.log(csvLst, 'final-list');
                    console.log(headers, 'headers');

                    setTimeout(() => {
                        toast.success('Exported Successfully !', {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                        csvInstance.current.link.click();
                    });

                }
            }
        });
    }

    const showDeleteDialog = (flag) => {
        if (flag === 'open') {

            toggleModal(true)
        }
        if (flag === 'close') {

            toggleModal(false)
            let checkedBoxes = document.querySelectorAll('input[name=chkBox]:checked');
            for (let checkbox of checkedBoxes) {
                checkbox.checked = false;
            }
            setMultiValueIds([])
            setDeleteshowbutton(false)
        }
        if (flag === 'proceed') {

            handleDelete(multiValueids)
            let checkedBoxes = document.querySelectorAll('input[name=chkBox]:checked');
            for (let checkbox of checkedBoxes) {
                checkbox.checked = false;
            }
            setDeleteshowbutton(false)
        }
    }




    const handleDelete = (id) => {
        toggleModal(false)
        let pagenumber = 1;
        paging.pageNumber = pagenumber;


        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
        Service.deleteDataBySDId('MassAction/Delete', id, Service.getUserId()).then((res) => {
            if (typeof res !== 'undefined') {

                if (res.data !== 'null' && res.data !== 'undefined') {

                    if (res.data.Status == "success") {

                        getMassActionViewData("MassAction/GetALLSubmit", paging.pageSize, paging.pageNumber, "Descending", "Id", [], searchValue, "Rebate");

                    }
                    else {
                        setErrorMessage(res.data);
                        setShowErrorModal(true);
                        showErrorMsg = true;
                        setErrorStatus('Error');
                    }

                    setMultiValueIds([])
                }
            }

        });
    }

    const checkDelete = () => {
        let checkedBoxes = document.querySelectorAll('input[name=chkBox]:checked');
        if (checkedBoxes.length == 1) {
            if (checkedValueStatus.includes('Approved') || checkedValueStatus.includes('InReview') || checkedValueStatus.includes('Denied')) {
                setErrorDeleteModal(true)
            }
            else {

                setMultiValueIds(checkedValue)
                showDeleteDialog('open')
            }
        }
        if (checkedBoxes.length > 1) {
            setCheckedMultiValueid([])
            for (let checkbox of checkedBoxes) {
                checkedMultiValueid.push(checkbox.defaultValue)

            }
            checkedMultiValueid.map(i => {
                if (i.includes('Approved') || i.includes('InReview') || i.includes('Denied')) {
                    setErrorDeleteModal(true)
                }
                else {
                    var z = i.split(",");
                    multiValueids.push(z[0])
                    showDeleteDialog('open')
                }
            })
        }
    }


    const CreateNew = () => {
        navigate("/CreateNewMassActionSubmitShipAndDebit/new/0")
    }


    // -----------------------------------------------------------------
    const columnList = [
        { "label": "MassAction.MassActionId", "key": "MassActionId", "value": true },
        { "label": "MassAction.Description", "key": "Desc", "value": true },
        { "label": "CreatedUser", "key": "CreatedUser", "value": true },
        { "label": "CreatedDate", "key": "CreatedDate", "value": true },
        { "label": "ModifiedBy", "key": "LastModifiedUser", "value": true },
        { "label": "ModifiedDate", "key": "LastModifiedDate", "value": true },
        { "label": "Status", "key": "Status", "value": true }
    ]
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [errorStatus, setErrorStatus] = useState("Error");
    const [addRemoveCol, setAddRemoveCol] = useState(columnList);
    const [createColumnListOption, setCreateColumnListOption] = useState(columnList)
    const [showAddColumnPopup, setShowAddColumnPopup] = useState(false)
    const [showCreateColumnListPopup, setShowCreateColumnListPopup] = useState(false)
    const [hideColumn, setHideColumn] = useState([]);
    const [resetColumnKey, setResetColumnKey] = useState([]);
    const [colVisibilityOption, setColVisibilityOption] = useState([]);
    const [colvisiOptionVal, setColvisiOptionVal] = useState({ value: null, label: 'Default' })
    const [divleft1, setdivleft1] = useState('');
    const [divtop1, setdivtop1] = useState('');
    const [existingViewVal, setExistingViewVal] = useState(false);
    const [defaultView, setDefaultView] = useState(false);
    const [colViewName, setColViewName] = useState('');
    const [recorddId, setRecordId] = useState(0);


    const OnChangedefaultView = (event,) => {
        setDefaultView(event.target.checked)
    }
    const OnChangeexistingViewVal = (event,) => {
        setExistingViewVal(event.target.checked)
    }

    const OnChangeSetViewName = (event,) => {
        setColViewName(event.target.value)
    }

    const OnChangeColVisibilityList = (data) => {
        setColvisiOptionVal({ value: data.value, label: data.label });
        getColVisibilityListData(data.value)
    }
    const CreateColumnListPopup = () => {
        setShowCreateColumnListPopup(true)
        var divtop1 = document.getElementById("addRemoveCol");
        setdivtop1(divtop1.offsetTop + 30)
        setdivleft1((divtop1.offsetLeft) + 100 + "px");
    }

    const CloseCreateColumnListPopup = () => {
        setShowCreateColumnListPopup(false)
        setColViewName('')
        setDefaultView(false)
    }

    const AddRemoveColumn = () => {
        setShowAddColumnPopup(true)
        var divtop1 = document.getElementById("addRemoveCol");
        setdivtop(divtop1.offsetTop + 45)
        setdivleft((divtop1.offsetLeft) + "px");
        getColumnVisibilityList()
    }
    const OnchangeAddRemoveCol = (event, column) => {
        let isChecked = event.target.checked;
        let lst = [...addRemoveCol];
        for (let d in lst) {
            if (lst[d].key == column.key && isChecked) {
                lst[d]['value'] = true
            }
            else if (lst[d].key == column.key && !isChecked) {
                lst[d]['value'] = false
            }
        }
        setResetColumnKey([...resetColumnKey, column.key])
        setAddRemoveCol(lst)
    }
    const OnchangeCreateColumnListOption = (event, column) => {
        let isChecked = event.target.checked;
        let lst = [...createColumnListOption];
        for (let d in lst) {
            if (lst[d].key == column.key && isChecked) {
                lst[d]['value'] = true
            }
            else if (lst[d].key == column.key && !isChecked) {
                lst[d]['value'] = false
            }
        }
        setCreateColumnListOption(lst)
    }
    const updateColumnVisibilityList = (tag) => {

        let lst = [...addRemoveCol];
        if (tag == 'reset') {
            for (let d in lst) {
                lst[d]['value'] = true
            }
            setHideColumn([])
            setAddRemoveCol(lst)
        }
        else {
            let data = [];
            for (let d in lst) {
                if (!lst[d].value) {
                    data.push(lst[d].key)
                }
            }
            setHideColumn(data)
        }
        let viewName = colvisiOptionVal.value;
        Service.setColumnVisibilityListData('ColumnVisibility/CreateOrUpdateColumnList', recorddId, loggedInUser, 'massActionList', viewName, existingViewVal, lst).then((res) => { })
        setShowAddColumnPopup(false)
        setResetColumnKey([])
    }


    const CreateColumnVisibilityList = (tag) => {
        let veiwName = colViewName;
        let idDefault = defaultView;
        if (veiwName.trim() == 'undefined' || veiwName.trim() == '' || veiwName.trim() == null) {
            setErrorStatus("Error")
            setErrorMessage("Please set View Name")
            setShowErrorModal(true)
        }
        else {
            let lst = [...createColumnListOption];
            if (tag == 'reset') {
                for (let d in lst) {
                    lst[d]['value'] = true
                }
                setHideColumn([])
                setAddRemoveCol(lst)
            }
            else {
                let data = [];
                for (let d in lst) {
                    if (!lst[d].value) {
                        data.push(lst[d].key)
                    }
                }
                setHideColumn(data)
            }
            Service.setColumnVisibilityListData('ColumnVisibility/CreateOrUpdateColumnList', 0, loggedInUser, 'massActionList', colViewName, defaultView, lst).then((res) => {
                getColumnVisibilityList()
                setColvisiOptionVal({ value: colViewName, label: colViewName });
                getColVisibilityListData(colViewName)
                CloseCreateColumnListPopup()
                setShowAddColumnPopup(false)
            })
        }
    }

    const closeAddRemoveColumnPopup = () => {
        let lst = [...addRemoveCol];
        let resetKey = [...resetColumnKey];
        for (let rk in resetKey) {
            for (let d in lst) {
                if (lst[d].key == resetKey[rk]) {
                    lst[d]['value'] = !lst[d].value
                }
            }
        }
        setAddRemoveCol(lst)
        setShowAddColumnPopup(false)
        setResetColumnKey([])

    }

    const getColumnVisibilityList = () => {
        Service.getColumnVisibilityListData('ColumnVisibility/GetColumnList', loggedInUser, 'massActionList', null).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {

                        if (typeof res.data !== 'undefined' && res.data !== 'null') {
                            let data = []
                            for (let d in res.data) {
                                data.push({ value: res.data[d].label, label: res.data[d].label })

                            }

                            setColVisibilityOption(data)
                        }
                    }
                }
            }
        })
    }


    const getColVisibilityListData = (viewName = null) => {
        if (!viewName) {
            viewName = colvisiOptionVal.value
        }
        if (viewName == 'Deleted') {
            viewName = null
        }

        Service.getColumnVisibilityListData('ColumnVisibility/GetColumnListJsonData', loggedInUser, 'massActionList', viewName).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (typeof res.data.jsonData !== 'undefined' && res.data.jsonData !== 'null') {

                            let jsonData = JSON.parse(res.data.jsonData)
                            let data = [];
                            for (let d in jsonData) {
                                if (!jsonData[d].value) {
                                    data.push(jsonData[d].key)
                                }
                            }
                            setColvisiOptionVal({ value: res.data.label, label: res.data.label });
                            setHideColumn(data)
                            setAddRemoveCol(jsonData)
                            setExistingViewVal(res.data.defaultView)
                            setResetColumnKey([])
                            setRecordId(res.data.id)
                        }
                    }
                }
            }
        })
    }

    const deleteColumnVisibilityView = () => {
        Service.deleteDataByPolicyID("ColumnVisibility/Delete", recorddId, loggedInUser).then(res => {
            if (res.status === 200) {
                getColumnVisibilityList()
                getColVisibilityListData('Deleted')
                setShowAddColumnPopup(false)
                toast.error('Deleted Successfully !', {
                    position: toast.POSITION.TOP_RIGHT,
                });

            }
        })
    }


    // ------------------------------------------------------------

    return (
        <div className="container-fluid content-top-gap">


            <nav aria-label="breadcrumb">
                <ol className="breadcrumb my-breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">{t('Home.title')}</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">{t('MassAction.MassActionSubmitShipAndDebitList')}</li>
                </ol>
            </nav>

            <div className="">
                <div className="row">
                    <div className="col-lg-12 pr-lg-2">
                        <div className="card text-center card_border overflow-auto ">

                            <div className="card-body" style={{ minHeight: "70vh", width: "100%" }}>
                                <div className="row ml-1">

                                    <div className="col-md-8">
                                        <div className='row'>
                                            <input type="text" className="form-control form-control-user col-md-10" onChange={(event) => searchHandler(event)} id="firstName" autoComplete='off' name="firstName" placeholder={`${t('SearchFor')} ${t('MassAction.massActionId')}, ${t('CreatedBy')}, ${t('LastModifiedBy')} `} />

                                            <button id="addRemoveCol" icon className="btn btn-update ml-2 mt-1 pa-0" type="submit"
                                                style={{ float: "left", cursor: "pointer", padding: '0px', height: '29px', width: '26px' }}
                                                onClick={AddRemoveColumn} title='Manage View'><i class="fa fa-columns" aria-hidden="true"></i></button>
                                        </div>
                                    </div>
                                    <div className="col-md-4">

                                        {showbutton === false ?
                                            <>
                                               {Service.editPerms(userPerms) == false ? "" :   <button className="btn btn-sm btn-update ml-2" id="create" style={{ width: "4.688rem", backgroundColor: "buttonface", float: "right" }} onClick={() => CreateNew()}>{t('CreateNew')}</button>}
                                            </>
                                            :
                                            <></>
                                        }
                                        {showbutton === true ?
                                            <>
                                                <a className="btn btn-update btn-sm ml-2" style={{ backgroundColor: "buttonface", float: "right", width: "40px" }} onClick={() => OpenHandler(checkedValueid)}>{t('Open')}</a>
                                                <button className="btn btn-update  btn-sm ml-2" type="submit" style={{ float: "right", width: "50px" }} onClick={(event) => checkDelete()}>{t('Delete')}</button>
                                            </>
                                            :
                                            deleteshowbutton === true ?
                                                <>
                                                    <button className="btn btn-update  btn-sm ml-2" style={{ float: "right", width: "50px" }} type="submit" onClick={(event) => checkDelete()}>{t('Delete')}</button>
                                                </> :
                                                ''
                                        }
                                        {totalselectedcount <= 0 ? "" : Service.exportPerms(userPerms) == false ? "" : <button className="btn btn-update  btn-sm ml-2" type="submit" style={{ float: "right", width: "50px" }} onClick={(event) => ExporttoCSV()}>{t('Export')}</button>}

                                    </div>

                                </div>
                                <div style={{ display: "none" }}>
                                    <CSVLink data={csvList} headers={headers} ref={csvInstance} filename="MassSubmit.csv" > </CSVLink>
                                </div>
                                <div className='d-flex text-nowrap'>
                                    <table className="table table-bordered text-left mt-2" id="tableA">
                                        <thead className={class_thead} style={{ backgroundColor: "var(--primary)" }}>
                                            <tr>
                                                <th className="tableheader" style={{ width: '30px' }}><input type="checkbox" id="option-all" onChange={checkAll} /> </th>
                                                <th id="MassActionId" style={{ backgroundColor: (filterValueDict.MassActionId ? '#6cae55' : ''), maxWidth: "145px", verticalAlign: "middle" }}
                                                    hidden={hideColumn.includes('MassActionId') ? true : false}>
                                                    <div className='d-flex text-nowrap'>
                                                        <span>{t('MassAction.massActionId')}</span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('MassActionId')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('MassActionId')}></span>
                                                    </div>
                                                </th>

                                                <th id="Desc" style={{ backgroundColor: (filterValueDict.Desc ? '#6cae55' : ''), maxWidth: "150px", verticalAlign: "middle" }}
                                                    hidden={hideColumn.includes('Desc') ? true : false}>
                                                    <div className='d-flex text-nowrap'>
                                                        <span>{t('MassAction.Description')} </span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('Desc')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('Desc')}></span>
                                                    </div>
                                                </th>


                                                <th id="CreatedUser" style={{ backgroundColor: (multiFilterValue.CreatedUser ? '#6cae55' : ''), maxWidth: "145px", verticalAlign: "middle" }}
                                                    hidden={hideColumn.includes('CreatedUser') ? true : false}>
                                                    <div className='d-flex text-nowrap'>
                                                        <span> {t('CreatedBy')} </span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('CreatedUser')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('CreatedUser')}></span>
                                                    </div>
                                                </th>
                                                <th id="CreatedDate" style={{ backgroundColor: (filterValueDict.CreatedDate ? '#6cae55' : ''), maxWidth: "145px", verticalAlign: "middle" }}
                                                    hidden={hideColumn.includes('CreatedDate') ? true : false}>
                                                    <div className='d-flex text-nowrap'>
                                                        <span>{t('CreatedDate')} </span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('CreatedDate')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('CreatedDate')}></span>
                                                    </div>
                                                </th>

                                                <th id="LastModifiedUser" style={{ backgroundColor: (multiFilterValue.LastModifiedUser ? '#6cae55' : ''), maxWidth: "145px", verticalAlign: "middle" }}
                                                    hidden={hideColumn.includes('LastModifiedUser') ? true : false}>
                                                    <div className='d-flex text-nowrap'>
                                                        <span> {t('ModifiedBy')} </span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('LastModifiedUser')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('LastModifiedUser')}></span>
                                                    </div>
                                                </th>
                                                <th id="LastModifiedDate" style={{ backgroundColor: (filterValueDict.LastModifiedDate ? '#6cae55' : ''), maxWidth: "145px", verticalAlign: "middle" }}
                                                    hidden={hideColumn.includes("LastModifiedDate") ? true : false}>
                                                    <div className='d-flex text-nowrap'>
                                                        <span>{t('ModifiedDate')} </span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('LastModifiedDate')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('LastModifiedDate')}></span>
                                                    </div>
                                                </th>
                                                <th id="Status" style={{ backgroundColor: (filterValueDict.LastModifiedDate ? '#6cae55' : ''), maxWidth: "145px", verticalAlign: "middle" }}
                                                    hidden={hideColumn.includes('Status') ? true : false}>
                                                    <div className='d-flex text-nowrap'>
                                                        <span>{t('Status')} </span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('Status')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('Status')}></span>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                showspinner === true ?

                                                    <>
                                                        <Spinner animation="grow" size="sm" variant="success" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                        <Spinner animation="grow" size="sm" variant="danger" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                        <Spinner animation="grow" size="sm" variant="warning" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                    </>
                                                    :
                                                    filteredArray.length === 0 ?
                                                        <tr>
                                                            <td></td>
                                                            <td colSpan="6" style={{ textAlign: 'center' }}>
                                                                {t('NoDataAvailable')}
                                                            </td>
                                                        </tr>
                                                        :
                                                        filteredArray.map((listVal, index) =>
                                                            <tr key={listVal.MassActionId} onDoubleClick={(event) => OpenAccrual(event, listVal.Id)}>
                                                                <td><input id={listVal.Id} type="checkbox" name="chkBox" value={listVal.Id} onChange={(event) => handleChange(event, listVal.Id, listVal.MassActionId)} /></td>
                                                                <td style={{ maxWidth: "145px" }} hidden={hideColumn.includes('MassActionId') ? true : false}>{listVal.MassActionId}</td>
                                                                <td style={{ width: "130px" }} hidden={hideColumn.includes('Desc') ? true : false}><div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right" title={listVal.Desc}>{listVal.Desc}</div></td>
                                                                <td style={{ maxWidth: "145px" }} hidden={hideColumn.includes('CreatedUser') ? true : false}>{listVal.CreatedUser}</td>
                                                                <td hidden={hideColumn.includes('CreatedDate') ? true : false}>{moment(listVal.CreatedDate).format(SessionManage.getcurrentdateformat())}</td>
                                                                <td style={{ maxWidth: "145px" }} hidden={hideColumn.includes('LastModifiedUser') ? true : false}>{listVal.LastModifiedUser}</td>
                                                                <td hidden={hideColumn.includes("LastModifiedDate") ? true : false}>{moment(listVal.LastModifiedDate).format(SessionManage.getcurrentdateformat())}</td>
                                                                <td hidden={hideColumn.includes('Status') ? true : false}>{listVal.Status}</td>

                                                            </tr>
                                                        )
                                            }

                                        </tbody>
                                    </table>
                                </div>
                                {
                                    showLoadMore === true ?
                                        <div className='text-center container row'>
                                            <div className='col-md-5'></div>
                                            <div className='col-md-2'>
                                                <button className='btn btn-sm btn-update ml-1' onClick={handleLoadMore} style={{ width: "70px" }}>{t('LoadMore')}</button>
                                            </div>
                                            <div className='col-md-5'></div>

                                        </div>
                                        :
                                        ""
                                }
                                <div className='row'>

                                    {
                                        totalselectedshow === true ?

                                            <div className='col-lg-2 forgettext col-sm-12 border form-control' style={{ textAlign: "center", marginLeft: "0.938rem" }}>
                                                {t('TotalRecordsSelected')} - <span id="totalselected">{totalselectedcount}</span>
                                            </div>

                                            :
                                            <div className='col-lg-2  col-sm-12' style={{ textAlign: "center" }}>

                                            </div>
                                    }



                                    <div className='col-lg-7 col-sm-12'></div>


                                    <div className='col-lg-2 forgettext  col-sm-12 border form-control' style={{ textAlign: "center" }}>
                                        {t('TotalRecords')} - {totalCountlst}
                                    </div>
                                </div>
                            </div>

                            <Modal show={showDeleteModal}
                                size="sm"
                                aria-labelledby="contained-modal-title-vcenter"
                                centered>
                                <Modal.Body style={{ "textAlign": 'center' }}>Are you sure you want to delete ?</Modal.Body>
                                <Modal.Footer>
                                    <Button style={{ background: '#eff1f9', color: 'black', borderColor: 'black', width: "50px" }} size='sm' onClick={() => showDeleteDialog('close')}>
                                        Cancel
                                    </Button>
                                    <Button style={{ background: '#eff1f9', color: 'black', borderColor: 'black', width: "50px" }} size='sm' onClick={() => showDeleteDialog('proceed')}>
                                        Delete
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                            <Modal show={errorDeleteModal}
                                size="sm"
                                aria-labelledby="contained-modal-title-vcenter"
                                centered>
                                <Modal.Body style={{ "textAlign": 'center', fontFamily: 'Segoe UI' }}>
                                    {'Records in New status only can be deleted'}
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button style={{ background: '#eff1f9', color: 'black', borderColor: 'black', width: "45px" }} size='sm' onClick={() => setErrorDeleteModal(false)}>
                                        Close
                                    </Button>
                                </Modal.Footer>
                            </Modal>


                            {showfilterpopup === true ?
                                <div className='pop-up' style={{ left: divleft, top: divtop, width: "220px" }}>
                                    {filterCol === 'MassActionId' || filterCol === 'Desc' || filterCol === 'Status' ?

                                        <>
                                            <div className="container">
                                                <div className="row">
                                                    <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>
                                                </div>
                                            </div>
                                            <div className="container mt-2">
                                                <div className="row">
                                                    <input type='textbox' placeholder='Search...' className='filtertextboxbackground col-md-11 textbox-padding ml-2' value={filterValueDict[filterCol]} name={filterCol} onChange={(event) => filterInput(event)}></input>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <></>
                                    }

                                    {filterCol === 'CreatedDate' || filterCol === 'LastModifiedDate' ?
                                        <>
                                            <div className="container">
                                                <div className="row">
                                                    <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>
                                                </div>
                                            </div>
                                            <div className="container mt-2">
                                                <div className="row">
                                                    <input type='date' placeholder='Search...' className='filtertextboxbackground col-md-11 textbox-padding ml-2' value={filterValueDict[filterCol]} onChange={(event) => filterInput(event)}></input>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <></>
                                    }

                                    {filterCol === 'CreatedUser' || filterCol === 'LastModifiedUser' ?
                                        <>
                                            <div className="container">
                                                <div className="row">
                                                    <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>
                                                </div>

                                            </div>

                                            <div className="container mt-2">
                                                <div className="row">
                                                    <input type="text" placeholder="Search" value={filterSearch} onChange={(event) => UserfilterHandler(event)} className='filtertextboxbackground col-md-11 textbox-padding ml-2' />
                                                </div>

                                                <div className="row contentoverflow mt-2">
                                                    <table className="filtertable">
                                                        <tbody>
                                                            <tr>
                                                                <td><input type="checkbox" name="filter-option-all" id="option-all" onChange={checkAllFilter} /></td>
                                                                <td>Select All</td>
                                                            </tr>
                                                            {
                                                                dropdownData.map((item, index) => (

                                                                    <tr key={index}>

                                                                        <td><input id={item.Id} data-index="1" name="filter-option-all" checked={item.ischecked} type="checkbox" onChange={(event) => OndropdownChange(event, item.Id, item.Name)} /></td>

                                                                        <td className="hovr" style={{ overflow: "hidden", display: "block", maxWidth: "183px" }}>{item.Name}</td>

                                                                    </tr>

                                                                ))
                                                            }
                                                        </tbody>
                                                    </table>

                                                </div>
                                            </div>
                                        </>
                                        :
                                        <></>
                                    }
                                    <div style={{ paddingTop: '10px', paddingLeft: '35px', paddingBottom: '7px' }}>
                                        <button className="btn btn-sm btn-update" style={{ width: "45px" }} onClick={() => filterHandler('Apply')}>{t('Apply')}</button>
                                        <button className="btn btn-sm btn-update" style={{ marginLeft: '10px', width: "45px" }} onClick={() => filterHandler('Reset')}>{t('Reset')}</button>
                                    </div>
                                    <div>
                                    </div>
                                </div>
                                :
                                <></>
                            }


                            {showAddColumnPopup === true ?
                                <div className='pop-up' style={{ left: divleft, top: divtop, width: "250px" }}>
                                    <div className="container">
                                        <div className="row">
                                            <Select
                                                className='col-md-10 textbox-padding fontsizedropdown'
                                                options={colVisibilityOption}
                                                placeholder="Select"
                                                value={colvisiOptionVal}
                                                onChange={OnChangeColVisibilityList}
                                                styles={customStyles}
                                            />
                                            <button id="createColumnListPopup" icon className="btn btn-update ml-1 pa-0" type="submit"
                                                style={{ float: "left", cursor: "pointer", padding: '0px', height: '28px', width: '26px' }}
                                                onClick={CreateColumnListPopup} title='Add New View'><i class="fa fa-plus" aria-hidden="true"></i></button>
                                        </div>
                                        <div className="row mb-2" style={{ textAlign: 'right' }}>
                                            <div className="col-md-10 mt-1 mr-1" style={{ padding: '0px', fontSize: '12px' }}>
                                                <input id="existingViewVal" data-index="1" name="filter-option-all" checked={existingViewVal} type="checkbox"
                                                    onChange={(event) => OnChangeexistingViewVal(event)} /> <b>Is Default</b>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <table className='ml-2' style={{ fontSize: '0.80rem' }}>
                                                {
                                                    addRemoveCol.map((item, index) => (
                                                        <tr>
                                                            <td key={index}><input id={item.label} data-index="1" name="filter-option-all" checked={item.value} type="checkbox"
                                                                onChange={(event) => OnchangeAddRemoveCol(event, item)}
                                                            /> {t(item.label)}</td>
                                                        </tr>
                                                    ))
                                                }
                                            </table>
                                        </div>
                                        <div className="row mr-2" style={{ float: "right" }}>
                                            <div style={{ paddingTop: '10px', paddingLeft: '15px', paddingBottom: '7px' }}>
                                                <button className="btn btn-sm btn-update" style={{ width: "45px" }} onClick={() => updateColumnVisibilityList('reset')}>{t('Reset')}</button>
                                                <button className="btn btn-sm btn-update ml-1" style={{ width: "45px" }} onClick={closeAddRemoveColumnPopup}>{t('Close')}</button>
                                                <button className="btn btn-sm btn-update ml-1" style={{ width: "50px", background: '#4755ab', color: 'white' }} onClick={() => updateColumnVisibilityList('apply')} title='Update View'>{t('Update')}</button>
                                                <button className="btn btn-sm btn-update ml-1" style={{ width: "50px", background: 'rgb(233 43 0)', color: 'white' }} onClick={deleteColumnVisibilityView} title='Delete View'>{t('Delete')}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : <></>
                            }


                            {showCreateColumnListPopup === true ?
                                <div className='pop-up' style={{ left: divleft1, top: divtop1, width: "190px" }}>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-12 ml-2" style={{ padding: '0px' }}>
                                                <input type='textbox' placeholder='View Name' style={{ height: '30px' }}
                                                    value={colViewName} name={colViewName} onChange={(event) => OnChangeSetViewName(event)}></input>
                                            </div>
                                        </div>
                                        <div className="row mb-2" style={{ textAlign: 'right' }}>
                                            <div className="col-10 mt-2" style={{ padding: '0px', fontSize: '12px' }}>
                                                <input id="defaultView" data-index="1" name="filter-option-all" checked={defaultView} type="checkbox"
                                                    onChange={(event) => OnChangedefaultView(event)} /> <b>Set Default</b>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <table className='ml-2' style={{ fontSize: '0.80rem' }}>
                                                {
                                                    createColumnListOption.map((item, index) => (
                                                        <tr>
                                                            <td key={index}><input id={item.label} data-index="1" name="filter-option-all" checked={item.value} type="checkbox"
                                                                onChange={(event) => OnchangeCreateColumnListOption(event, item)}
                                                            /> {t(item.label)}</td>
                                                        </tr>
                                                    ))
                                                }
                                            </table>
                                        </div>
                                        <div className="row mr-2" style={{ float: "right" }}>
                                            <div style={{ paddingTop: '10px', paddingLeft: '35px', paddingBottom: '7px' }}>
                                                <button className="btn btn-sm btn-update ml-1" style={{ width: "50px" }} onClick={CloseCreateColumnListPopup}>{t('Cancel')}</button>
                                                <button className="btn btn-sm btn-update ml-1" style={{ width: "40px", background: '#4755ab', color: 'white' }} onClick={() => CreateColumnVisibilityList('apply')}>{t('Save')}</button>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                                : <></>
                            }

                        </div>
                        <CustomErrorPopup ErrorMessage={errorMessage} show={showErrorModal} ErrorStatus={errorStatus}
                            onHide={() => setShowErrorModal(false)}></CustomErrorPopup>
                    </div>
                    <ToastContainer autoClose={1000} />
                </div>
            </div>


        </div>
    )
}

export default MassActionSubmitShipAndDebitList