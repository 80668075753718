import { Link, useLocation } from "react-router-dom"
import React, { useState, useEffect, useRef } from 'react'
import Service from '../../../Services/Service';
import { useNavigate } from 'react-router-dom';
import { Form, FormGroup, Col, Row, Button, InputGroup, Collapse, Modal } from 'react-bootstrap';
import moment from 'moment';
import Spinner from 'react-bootstrap/Spinner';
import Multiselect from 'multiselect-react-dropdown';
import SessionManage from "../../../SessionManagement/SessionManage";
import { useTranslation } from "react-i18next";
import { CSVLink } from "react-csv";
import Select from "react-select";
import CustomErrorPopup from '../../../UI/CustomErrorPopup';

function UserListComponent() {
    const [t, i18n] = useTranslation('translation');
    const [searchValue, setSearchValue] = useState(null);
    const location = useLocation();
    const csvInstance = useRef();
    const [csvList, setCsvList] = useState([]);
    const [sticky, setSticky] = useState("");
    const [sortColName, setSortColName] = useState("");
    const [userPerms, setuserPermission] = useState([]);
    const [data, setdata] = useState([]);
    const [showspinner, setshowspinner] = useState('');
    const [checkedValueid, setCheckedValueid] = useState([]);
    const [filteredArray, setFilteredArray] = useState(data)
    const [showLoadMore, setshowLoadMore] = useState('');
    const [showbutton, setshowbutton] = useState('');
    const [filterValue, setFilterValue] = useState('');
    const [filterCol, setFilterCol] = useState('');
    const [dropdownData, setDropdownData] = useState([]);
    const [showfilterpopup, setshowfilterpopup] = useState(false);
    const [columnAscending, setColumnAscending] = useState(true);
    const [totalselectedshow, settotalselectedshow] = useState('');
    const [totalselectedcount, settotalselectedcount] = useState(0);
    const [totalCountlst, settotalCountlst] = useState('');
    const [ToggleStatusModal, setToggleStatusModal] = useState(false);
    const [toggleChange, setToggleChange] = useState("");
    const [toggleId, setToggleId] = useState();
    const [toggleUserId, setToggleUserId] = useState();
    const [filtertext, setfiltertext] = useState('');
    const [filterSearch, setFilterSearch] = useState("");
    const [filterdropdownData, setFilterDropdownData] = useState([]);
    let loggedInUser = sessionStorage.getItem("id")

    const customStyles = {
        control: base => ({
            ...base,
            minHeight: 28
        }),
        dropdownIndicator: base => ({
            ...base,
            padding: 2
        }),
        clearIndicator: base => ({
            ...base,
            padding: 2
        }),
        multiValue: base => ({
            ...base,

        }),
        valueContainer: base => ({
            ...base,
            padding: '0px 2px'
        }),
        input: base => ({
            ...base,
            margin: 0,
            padding: 0
        })
    };

    const [dropdownChange, setDropdownChange] = useState('');
    const [filterValueDict, setFilterValueDict] = useState({
        firstname: '',
        lastname: '',
        username: '',
        email: '',
        roles: '',

        created_date: null,
        last_modified_date: null,
    });
    const [paging, setpaging] = useState({
        pageSize: 20,
        pageNumber: 1
    });
    const [multiFilterValue, setMultiFilterValue] = useState({
        createdby: '',
        last_modified_by: '',
        status: '',
        userstype: '',
    });
    const getUserPermission = (pathname) => {
        
        Service.GetUserPermission(pathname).then((res) => {
            if (typeof res !== 'undefined') {
                if (res.data !== 'null' && typeof res.data !== 'undefined') {
                    if (res.data.DataList !== 'undefined') {
                        setuserPermission(res.data.DataList)
                    }
                }
            }
        });
    }
    const activeOption = [
        { Name: "Active", Id: "1" },
        { Name: "In-Active", Id: "0" },

    ]
    const userstype = [
        { Name: "Internal User", Id: "Internal User" },
        { Name: "Partner User", Id: "Partner User" },

    ]

    const checkAll = (mycheckbox) => {
        var checkboxes = document.querySelectorAll("input[type = 'checkbox']");
        if (mycheckbox.target.checked == true) {
            settotalselectedshow(true);
            checkboxes.forEach(function (checkbox) {
                checkbox.checked = true;
            });
            settotalselectedcount(checkboxes.length - 1)
        }
        else {
            settotalselectedshow(false);
            checkboxes.forEach(function (checkbox) {
                checkbox.checked = false;
            });
            settotalselectedcount(0)
        }

    }

    const LoadMore = (lst, totalcount) => {

        if (lst < totalcount) {
            setshowLoadMore(true);
        }
        else if (lst >= totalcount) {
            setshowLoadMore(false);
        } else {
            setshowLoadMore(false);
        }

    }
    const navigate = useNavigate();

    useEffect(() => {
        setColumnAscending(false);
        setSortColName("Id");
        setshowspinner(true);
        getUserPermission(location.pathname);
        getData("Users/GetAll", paging.pageSize, paging.pageNumber, "Descending", "Id", [], null);
        getColumnVisibilityList()
        getColVisibilityListData()
        window.addEventListener("scroll", isSticky);
        return () => {
            window.removeEventListener("scroll", isSticky);
        };

    }, [0]);
    const isSticky = () => {
        const scrollTop = window.scrollY;
        const stickyClass = scrollTop >= 60 ? "is-sticky-thead" : "";
        setSticky(stickyClass);
        const scrollTopActual = scrollTop >= 60 ? scrollTop - 85 : 0;
        var divtop1 = document.getElementById("tableA");
        setdivtop(divtop1.offsetTop + scrollTopActual)
    };
    const class_thead = `text-white rt-thead  ${sticky}`;

    const handleLoadMore = () => {
        let pagenumber = paging.pageNumber + 1;
        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
        Service.getDatawithMultiFilterSearch("Users/GetAll", paging.pageSize, pagenumber, sortType, sortColName, filterLst(), searchValue).then((res) => {
            if (typeof res !== 'undefined') {
               
                if (res.data !== 'null' && typeof res.data !== 'undefined') {
                    const currentlength = data.length;
                    setdata(data => [...data, ...res.data.DataList])
                    setFilteredArray(filteredArray => [...filteredArray, ...res.data.DataList])
                    LoadMore(currentlength + res.data.DataList.length, res.data.Totalcount)
                }
            }
        });
   
        paging.pageNumber = pagenumber
        setpaging(paging);
    };

    let role = [];

    const getData = (method, pageSize, pageNumber, sortType, SortProperty, filterValue, search) => {
        Service.getDatawithMultiFilterSearch(method, pageSize, pageNumber, sortType, SortProperty, filterValue, search).then((res) => {
            if (typeof res !== 'undefined') {
                if (res.data !== 'null' && res.data !== 'undefined') {
                    setdata(res.data.DataList);
                    setFilteredArray(res.data.DataList)
                    settotalCountlst(res.data.Totalcount)
                    LoadMore(res.data.DataList.length, res.data.Totalcount)
                    setshowspinner(false)
                }
            }
        });

    }

   

    const handleChange = (e, id) => {
        let isChecked = e.target.checked;   // true if it is checked false if unchecked
       
        let checkedBoxes = document.querySelectorAll('input[name=chkBox]:checked');
        if (isChecked === true) {
            settotalselectedshow(true);
            settotalselectedcount(checkedBoxes.length);
            
            setCheckedValueid(id)
           
        }
        else {
           
            setCheckedValueid([])
            if (checkedBoxes.length === 0) {
                settotalselectedshow(false);
            }
            settotalselectedcount(checkedBoxes.length);
           
        }


        if (checkedBoxes.length == 1) {
            setshowbutton(true);
        }
        else {
            setshowbutton(false);
        }
    }
    const addnewfield = (data, key) => {
      
        let selectedKeys = multiFilterValue[key];
        let currentdata = data.map((i) => {
            i["ischecked"] = false;
            return i;
        });
        if (key != 'status') {
            if (selectedKeys != '') {
                currentdata.forEach((each) => {
                    selectedKeys.forEach((eachSelected) => {
                        if (each.Name == eachSelected.Name) {
                            each.ischecked = true
                        }
                    })
                })
            }
           
            setDropdownData(currentdata);
            setFilterDropdownData(currentdata)
        }
        else {
            if (selectedKeys != '') {
                currentdata.forEach((each) => {
                    selectedKeys.forEach((eachSelected) => {
                        if (each.Name == eachSelected.Name) {
                            each.ischecked = true
                        }
                    })
                })
                setDropdownData(currentdata)
                setFilterDropdownData(currentdata)
            }
            else {
                setDropdownData(currentdata)
                setFilterDropdownData(currentdata)
            }
        }
    }
  


    const checkAllFilter = (mycheckbox) => {
        var lastfilter = [...dropdownData]
        let data1 = []
        var checkboxes = document.querySelectorAll("input[name = 'filter-option-all']");
        if (mycheckbox.target.checked == true) {
           
            let currentdata = lastfilter.map((i) => {
                i["ischecked"] = true;
                return i;
            });
            setDropdownData(currentdata)
          
            dropdownData.map((each) => {
              
                const getallData = {
                    Name: each.Name,
                }
                data1.push(getallData)
            })
            setFilterValue(filterValue => [...filterValue, ...data1]);
            let updateddata = ([...filterValue, ...data1]);
            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: updateddata,
            }));
        }
        else {
            
            let currentdata = lastfilter.map((i) => {
                i["ischecked"] = false;
                return i;
            });
            setFilterValue([])
            setMultiFilterValue([])
        }

    }

  

    const OndropdownChange = (e, id, name) => {
        let isChecked = e.target.checked;
        var lastfilter = [...dropdownData]
        if (isChecked === true) {
            let currentdata = lastfilter.map((i) => {
                if (i.Id == id) {
                    i["ischecked"] = true;
                }
                return i;
            });
           
            setDropdownData(currentdata);
            let data1 = []
            const getallData = {
                Name: name,
            }
            data1.push(getallData)
            setFilterValue(filterValue => [...filterValue, ...data1]);
            let updateddata = ([...filterValue, ...data1]);
            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: updateddata,
            }));
        }
        else {
            let currentdata = lastfilter.map((i) => {
                if (i.Id === id) {
                    i["ischecked"] = false;
                }
                return i;
            });
          
            setDropdownData(currentdata);
            setFilterValue(filterValue => filterValue.filter(data => data.Name !== name))
            let data1 = multiFilterValue.filter(data => data.Name !== name);
            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: data1,
            }));
        }
    }
    const UserfilterHandler = (e) => {
  
        setFilterSearch(e.target.value)
        if (e.target.value !== "") {
            let searchResults = dropdownData.filter((each) => {
                return each.Name.toLowerCase().includes(String(e.target.value).toLowerCase())
            });
            setDropdownData(searchResults);
            
        }
        else {
           
            setFilterSearch("")
            setDropdownData(filterdropdownData)
            
        }
    }
    const customMultiStyles = {
        multiselectContainer: {
            maxWidth: '188px',
            marginLeft: '3px'
        },
        chips: {
            borderRadius: '5px'
        }
    };
    const OpenHandler = (id) => {
        if (id == null || id == 0) {
            alert('Please select the User to view')
        }
        else {
            navigate("/users/edit/" + id);
        }
    }

    const searchHandler = (event) => {
        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
   
        let pagenumber = 1;
     
        paging.pageNumber = pagenumber;
        setpaging(paging);
        if (event.target.value != '') {
          
            setSearchValue(String(event.target.value).toLowerCase())
            getData("Users/GetAll", paging.pageSize, pagenumber, sortType, sortColName, filterLst(), event.target.value);
        }
        else {

            setSearchValue(null);
            getData("Users/GetAll", paging.pageSize, pagenumber, sortType, sortColName, filterLst(), null);
        }
    }

    const ToggleUserActiveStatus = (id, user_id, flag) => {
   
        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
        setToggleId(id)
        setToggleUserId(user_id)
     
        if (flag == "Open-A") {
            setToggleStatusModal(true)
            setToggleChange("DeActivate")
        }
        if (flag == "Open-D") {
            setToggleStatusModal(true)
            setToggleChange("Activate")
        }
        if (flag == "Ok") {
            Service.ToggleUserActiveStatus('ToggleUserActiveStatus', id, loggedInUser).then((res) => {
                if (typeof res !== 'undefined') {
                    if (typeof res.data !== 'undefined' && res.data !== 'null') {
                        if (res.status === 200) {
                            getData("Users/GetAll", paging.pageSize, paging.pageNumber, sortType, sortColName, filterLst(), searchValue)
                        }
                        setToggleStatusModal(false)
                    }
                }

            });
        }
        if (flag == "Cancel") {
            setToggleStatusModal(false)
        }
    }

    const OpenUser = (event, id, UsersType) => {
        if (event.detail === 2) {
            if (UsersType === 'Internal User')
                navigate("/users/edit/" + id);
            else
                navigate("/partnerprofile/" + id);
        }
    }


    const filterInput = (e) => {
      
        let inputValue = e.target.value
        setFilterValue(inputValue)
        
        setFilterValueDict(() => ({
            ...filterValueDict, [filterCol]: e.target.value,
        }));
    }

    const [divleft, setdivleft] = useState('');
    const [divtop, setdivtop] = useState('');


    const FilterColumn = (name) => {
    
        const scrollTop = window.scrollY;
        const scrollTopActual = scrollTop >= 60 ? scrollTop - 85 : 0;
        var divtop1 = document.getElementById("tableA");
        setdivtop(divtop1.offsetTop + scrollTopActual + 30)
        setshowfilterpopup(true)
        setFilterCol(name)

        if (name === 'firstname') {
            setfiltertext(t('FirstName'))
            var testDiv = document.getElementById("firstname");
            setdivleft(testDiv.offsetLeft + 20 + "px");
        }
        if (name === 'lastname') {
            setfiltertext(t('LastName'))
            var testDiv = document.getElementById("lastname");
            setdivleft(testDiv.offsetLeft + 20 + "px");
        }

        if (name === 'username') {
            setfiltertext(t('UserName'))
            var testDiv = document.getElementById("username");
            setdivleft(testDiv.offsetLeft + 20 + "px");
        }
        if (name === 'email') {
            setfiltertext('Email')
            var testDiv = document.getElementById("email");
            setdivleft(testDiv.offsetLeft + 20 + "px");
        }
        if (name === 'roles') {
            setfiltertext('Roles')
            var testDiv = document.getElementById("roles");
            setdivleft(testDiv.offsetLeft + 20 + "px");
        }
        if (name === 'created_date') {
            setfiltertext('Created Date')
            var testDiv = document.getElementById("created_date");
            setdivleft(testDiv.offsetLeft + 20 + "px");
        }
        if (name === 'last_modified_date') {
            setfiltertext(t('LastModifiedDate'))
            var testDiv = document.getElementById("last_modified_date");
            setdivleft(testDiv.offsetLeft + 20 + "px");
        }
        if (name === 'last_modified_by') {
            setfiltertext(t('LastModifiedBy'))
            var testDiv = document.getElementById("last_modified_by");
            setdivleft(testDiv.offsetLeft + 20 + "px");
            DropdownHandler("users")
        }
        if (name === 'status') {
            setfiltertext(t('IsActive'))
            var testDiv = document.getElementById("status");
            setdivleft(testDiv.offsetLeft + 20 + "px");
            addnewfield(activeOption, name)
        }
        if (name === 'userstype') {
            setfiltertext(t('UserType'))
            var testDiv = document.getElementById("userstype");
            setdivleft(testDiv.offsetLeft + 20 + "px");
            addnewfield(userstype, name)
        }
    }
    const DropdownHandler = (key) => {
        Service.getfilterDropdown('Permission/GetFilterValue', key).then((res) => {
            if (typeof res !== 'undefined') {

                if (res.data !== 'null' && typeof res.data !== 'undefined') {
                   
                   
                    if (key == 'users') {
                        addnewfield(res.data, "last_modified_by")
                    }
                    else {
                        addnewfield(res.data, key)
                    }
                }
            }
        });
    }
    const filterHandler = (flag) => {

        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
        let pagenumber = 1;
        paging.pageNumber = pagenumber;

        if (flag === 'Apply') {
            setshowfilterpopup(false)
            let list = [];

            for (var key in multiFilterValue) {
                if (multiFilterValue[key].length > 0) {
                    let values = ""
                    multiFilterValue[key].map((i) => {
                        values += i.Name + ";";
                    })
                    if (values !== null && values !== '') {
                        list.push({
                            filterKey: key,
                            FilterValue: values
                        })
                    }
                }
            }

            for (var key in filterValueDict) {
                if (filterValueDict[key] !== null && filterValueDict[key] !== '') {
                    list.push({
                        filterKey: key,
                        FilterValue: filterValueDict[key]
                    })
                }
            }
            if (list.length > 0) {
                setpaging(paging);
                getData("Users/GetAll", paging.pageSize, pagenumber, sortType, sortColName, list, searchValue);
            }
            
        }
        else {
            setshowfilterpopup(false)
            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: '',
            }));
            setFilterValueDict(() => ({
                ...filterValueDict, [filterCol]: '',
            }));

            let list = [];
            for (var key in multiFilterValue) {
                if (key !== filterCol && multiFilterValue[key].length > 0) {
                    let values = ""
                    multiFilterValue[key].map((i) => {
                        values += i.Name + ";";
                    })
                    if (values !== null && values !== '') {
                        list.push({
                            filterKey: key,
                            FilterValue: values
                        })
                    }
                }
            }

            for (var key in filterValueDict) {
                if (filterValueDict[key] !== null && filterValueDict[key] !== '' && key !== filterCol) {
                    list.push({
                        filterKey: key,
                        FilterValue: filterValueDict[key]
                    })
                }
            }
            setpaging(paging);
            getData("Users/GetAll", paging.pageSize, pagenumber, sortType, sortColName, list, searchValue);

            
            setFilterCol('')
            setFilterValue('')
            setDropdownData([])
            setDropdownChange("")
         
            setFilterSearch("")
            setFilterDropdownData([])
        }
    }



    const SortColumn = (colName) => {
        var sortType = '';
        if (columnAscending === true) {
            setColumnAscending(false);
            sortType = "Descending";
        } else {
            setColumnAscending(true);
            sortType = "Ascending";
        }
        setSortColName(colName);
     
        let pagenumber = 1;
     
        paging.pageNumber = pagenumber;
        setpaging(paging);
        getData("Users/GetAll", paging.pageSize, paging.pageNumber, sortType, colName, filterLst(), searchValue);
    }
    const filterLst = () => {
        let list = [];
        for (var key in multiFilterValue) {
            if (multiFilterValue[key].length > 0) {
                let values = ""
                multiFilterValue[key].map((i) => {
                    values += i.Name + ";";
                })
                if (values !== null && values !== '') {
                    list.push({
                        filterKey: key,
                        FilterValue: values
                    })
                }
            }
        }

        for (var key in filterValueDict) {
            if (filterValueDict[key] !== null && filterValueDict[key] !== '') {
                list.push({
                    filterKey: key,
                    FilterValue: filterValueDict[key]
                })
            }
        }
        return list;
    }
    let headers = [
        { label: "First Name", key: "FirstName" },
        { label: "Last Name ", key: "LastName" },
        { label: "UserName ", key: "Username" },
        { label: "Email ", key: "Email" },

        { label: "Last Modified By", key: "LastModifiedUser_Name" },
        { label: "Last Modified Date", key: "LastModifiedDate" },
        { label: "Is Active ", key: "IsActive" },
    ];
    const ExporttoCSV = () => {
        let checkedBoxes = document.querySelectorAll("input[name=chkBox]:checked");
        let values = "";
        if (checkedBoxes.length >= 1) {
            for (let checkbox of checkedBoxes) {
                if (values === "") {
                    values = checkbox.id;
                } else {
                    values = values + ";" + checkbox.id;
                }
            }
        }
        let objFilter = [];
        let objFilterValue = [
            {
                filterKey: "user_id",
                FilterValue: values,
            },
        ];
        if (totalCountlst != checkedBoxes.length) {
            objFilter = objFilterValue;
        }
        Service.getDatawithMultiFilterSearch("Users/GetAll", 20000, 1, "Ascending", "Id", objFilter, null).then((res) => {
       
            if (typeof res !== "undefined") {
                if (res.data !== "null" && res.data !== "undefined") {
                    setCsvList(res.data.DataList);
                    setTimeout(() => {
                        csvInstance.current.link.click();
                    });
                }
            }
        });
    };

    // -----------------------------------------------------------------
    const columnList = [
        { "label": "FirstName", "key": "firstname", "value": true },
        { "label": "LastName", "key": "lastname", "value": true },
        { "label": "UserName", "key": "username", "value": true },
        { "label": "Email", "key": "email", "value": true },
        { "label": "UserType", "key": "userstype", "value": true },
        { "label": "LastModifiedBy", "key": "last_modified_by", "value": true },
        { "label": "LastModifiedDate", "key": "last_modified_date", "value": true },
        { "label": "IsActive", "key": "status", "value": true }
    ]

    const [errorMessage, setErrorMessage] = useState("");
    const [errorStatus, setErrorStatus] = useState("Error");
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [addRemoveCol, setAddRemoveCol] = useState(columnList);
    const [createColumnListOption, setCreateColumnListOption] = useState(columnList)
    const [showAddColumnPopup, setShowAddColumnPopup] = useState(false)
    const [showCreateColumnListPopup, setShowCreateColumnListPopup] = useState(false)
    const [hideColumn, setHideColumn] = useState([]);
    const [resetColumnKey, setResetColumnKey] = useState([]);
    const [colVisibilityOption, setColVisibilityOption] = useState([]);
    const [colvisiOptionVal, setColvisiOptionVal] = useState({ value: null, label: 'Default' })
    const [divleft1, setdivleft1] = useState('');
    const [divtop1, setdivtop1] = useState('');
    const [existingViewVal, setExistingViewVal] = useState(false);
    const [defaultView, setDefaultView] = useState(false);
    const [colViewName, setColViewName] = useState('');
    const [recorddId, setRecordId] = useState(0);


    const OnChangedefaultView = (event,) => {
        setDefaultView(event.target.checked)
    }
    const OnChangeexistingViewVal = (event,) => {
        setExistingViewVal(event.target.checked)
    }

    const OnChangeSetViewName = (event,) => {
        setColViewName(event.target.value)
    }

    const OnChangeColVisibilityList = (data) => {
        setColvisiOptionVal({ value: data.value, label: data.label });
        getColVisibilityListData(data.value)
    }
    const CreateColumnListPopup = () => {
        setShowCreateColumnListPopup(true)
        var divtop1 = document.getElementById("addRemoveCol");
        setdivtop1(divtop1.offsetTop + 30)
        setdivleft1((divtop1.offsetLeft) + 100 + "px");
    }

    const CloseCreateColumnListPopup = () => {
        setShowCreateColumnListPopup(false)
        setColViewName('')
        setDefaultView(false)
    }

    const AddRemoveColumn = () => {
        setShowAddColumnPopup(true)
        var divtop1 = document.getElementById("addRemoveCol");
        setdivtop(divtop1.offsetTop + 45)
        setdivleft((divtop1.offsetLeft) + "px");
        getColumnVisibilityList()
    }
    const OnchangeAddRemoveCol = (event, column) => {
        let isChecked = event.target.checked;
        let lst = [...addRemoveCol];
        for (let d in lst) {
            if (lst[d].key == column.key && isChecked) {
                lst[d]['value'] = true
            }
            else if (lst[d].key == column.key && !isChecked) {
                lst[d]['value'] = false
            }
        }
        setResetColumnKey([...resetColumnKey, column.key])
        setAddRemoveCol(lst)
    }
    const OnchangeCreateColumnListOption = (event, column) => {
        let isChecked = event.target.checked;
        let lst = [...createColumnListOption];
        for (let d in lst) {
            if (lst[d].key == column.key && isChecked) {
                lst[d]['value'] = true
            }
            else if (lst[d].key == column.key && !isChecked) {
                lst[d]['value'] = false
            }
        }
        setCreateColumnListOption(lst)
    }
    const updateColumnVisibilityList = (tag) => {
        let lst = [...addRemoveCol];
        if (tag == 'reset') {
            for (let d in lst) {
                lst[d]['value'] = true
            }
            setHideColumn([])
            setAddRemoveCol(lst)
        }
        else {
            let data = [];
            for (let d in lst) {
                if (!lst[d].value) {
                    data.push(lst[d].key)
                }
            }
            setHideColumn(data)
        }
        let viewName = colvisiOptionVal.value;
        Service.setColumnVisibilityListData('ColumnVisibility/CreateOrUpdateColumnList', recorddId, loggedInUser, 'userViewList', viewName, existingViewVal, lst).then((res) => { })
        setShowAddColumnPopup(false)
        setResetColumnKey([])
    }


    const CreateColumnVisibilityList = (tag) => {
        let veiwName = colViewName;
        let idDefault = defaultView;
        if (veiwName.trim() == 'undefined' || veiwName.trim() == '' || veiwName.trim() == null) {
            setErrorStatus("Error")
            setErrorMessage("Please set View Name")
            setShowErrorModal(true)
        }
        else {
            let lst = [...createColumnListOption];
            if (tag == 'reset') {
                for (let d in lst) {
                    lst[d]['value'] = true
                }
                setHideColumn([])
                setAddRemoveCol(lst)
            }
            else {
                let data = [];
                for (let d in lst) {
                    if (!lst[d].value) {
                        data.push(lst[d].key)
                    }
                }
                setHideColumn(data)
            }
            Service.setColumnVisibilityListData('ColumnVisibility/CreateOrUpdateColumnList', 0, loggedInUser, 'userViewList', colViewName, defaultView, lst).then((res) => {
                getColumnVisibilityList()
                setColvisiOptionVal({ value: colViewName, label: colViewName });
                getColVisibilityListData(colViewName)
                CloseCreateColumnListPopup()
                setShowAddColumnPopup(false)
            })
        }
    }

    const closeAddRemoveColumnPopup = () => {
        let lst = [...addRemoveCol];
        let resetKey = [...resetColumnKey];
        for (let rk in resetKey) {
            for (let d in lst) {
                if (lst[d].key == resetKey[rk]) {
                    lst[d]['value'] = !lst[d].value
                }
            }
        }
        setAddRemoveCol(lst)
        setShowAddColumnPopup(false)
        setResetColumnKey([])

    }

    const getColumnVisibilityList = () => {
        Service.getColumnVisibilityListData('ColumnVisibility/GetColumnList', loggedInUser, 'userViewList', null).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                      
                        if (typeof res.data !== 'undefined' && res.data !== 'null') {
                            let data = []
                            for (let d in res.data) {
                                data.push({ value: res.data[d].label, label: res.data[d].label })

                            }
                          
                            setColVisibilityOption(data)
                        }
                    }
                }
            }
        })
    }


    const getColVisibilityListData = (viewName = null) => {
        if (!viewName) {
            viewName = colvisiOptionVal.value
        }
        if (viewName == 'Deleted') {
            viewName = null
        }
        Service.getColumnVisibilityListData('ColumnVisibility/GetColumnListJsonData', loggedInUser, 'userViewList', viewName).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (typeof res.data.jsonData !== 'undefined' && res.data.jsonData !== 'null') {
                          
                            let jsonData = JSON.parse(res.data.jsonData)
                            let data = [];
                            for (let d in jsonData) {
                                if (!jsonData[d].value) {
                                    data.push(jsonData[d].key)
                                }
                            }
                            setColvisiOptionVal({ value: res.data.label, label: res.data.label });
                            setHideColumn(data)
                            setAddRemoveCol(jsonData)
                            setExistingViewVal(res.data.defaultView)
                            setResetColumnKey([])
                            setRecordId(res.data.id)
                        }
                    }
                }
            }
        })
    }

    const deleteColumnVisibilityView = () => {
        Service.deleteDataByPolicyID("ColumnVisibility/Delete", recorddId, loggedInUser).then(res => {
            if (res.status === 200) {
                getColumnVisibilityList()
                getColVisibilityListData('Deleted')
                setShowAddColumnPopup(false)
                alert('Deleted Successfully')
            }
        })
    }
    // ------------------------------------------------------------

    return (<>
        <div className="container-fluid content-top-gap">

            <nav aria-label="breadcrumb">
                <ol className="breadcrumb my-breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">{t('Home.title')} </Link></li>
                    <li className="breadcrumb-item"><Link to="/admin">{t('Menu.Admin')}</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">  {t('UserList.title')} </li>
                   
                </ol>
            </nav>
            <div style={{ display: "none" }}>
                <CSVLink data={csvList} headers={headers} ref={csvInstance} filename="users.csv" > </CSVLink>
            </div>
            <div className="">
                <div className="row">
                    <div className="col-lg-12 pr-lg-2">
                        <div className="card text-center card_border overflow-auto">

                            <div className="card-body" style={{ minHeight: "70vh" }}>
                                <div className="row ml-1" style={{ position: "sticky" }}>

                                    <div className="col-md-8">
                                        <div className="row">
                                            <input type="text" className="form-control form-control-user col-md-10" autoComplete="No" id="firstName" name="firstName" placeholder={`${t('SearchFor')}${t('Policies.FirstName,LastName,UserName&Email')}`} onChange={(event) => searchHandler(event)} />
                                            <button id="addRemoveCol" icon className="btn btn-update ml-2 mt-1 pa-0" type="submit"
                                                style={{ float: "left", cursor: "pointer", padding: '0px', height: '29px', width: '26px' }}
                                                onClick={AddRemoveColumn} title='Manage View'><i class="fa fa-columns" aria-hidden="true"></i></button>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        {Service.editPerms(userPerms) == false ? "" : <Link className="btn btn-update btn-sm ml-2" id="create" style={{ width: "90px", backgroundColor: "buttonface", float: "right" }} to="/users/add">{t('CreateNew')}</Link>}
                                        {Service.editPerms(userPerms) == false ? "" : <Link className="btn btn-update btn-sm ml-2" id="create" style={{ width: "120px", backgroundColor: "buttonface", float: "right" }} to="/partnerprofile">{t('UserList.CreatePartnerUser')}</Link>}

                                        {totalselectedcount <= 0 ? "" : Service.exportPerms(userPerms) == false ? "" : <button className="btn btn-update  btn-sm ml-2" type="submit" style={{ float: "right", width: "50px" }} onClick={(event) => ExporttoCSV()}>{t('Export')}</button>}


                                        {showbutton === true ?
                                            <>
                                                <button className="btn btn-update btn-sm ml-2" id="open" type="submit" style={{ float: "right", width: "40px" }} onClick={() => OpenHandler(checkedValueid)}>{t('Open')}</button>
                                               
                                            </>
                                            :
                                            ''
                                        }
                                    </div>

                                </div>
                                <div className="scrollbar-class">
                                    <table className="table table-bordered text-left mt-2 table-responsive-sm" id="tableA">
                                        <thead className="text-white is-sticky-thead-product" style={{ backgroundColor: "var(--primary)" }}>
                                            <tr style={{ position: "sticky", top: "0", backgroundColor: "var(--primary)" }}>
                                                <th className="tableheader" style={{ width: '30px' }}>
                                                    <input type="checkbox" id="option-all" onChange={checkAll} />
                                                </th>
                                           
                                                <th id="firstname" style={{ backgroundColor: (filterValueDict.firstname ? '#6cae55' : ''), minWidth: '200px' }} hidden={hideColumn.includes('firstname') ? true : false}>
                                                    <div className='d-flex text-nowrap'>
                                                        <span>{t('FirstName')}</span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('FirstName')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('firstname')}></span>
                                                    </div>
                                                </th>
                                                <th id="lastname" style={{ backgroundColor: (filterValueDict.lastname ? '#6cae55' : ''), minWidth: '200px' }} hidden={hideColumn.includes('lastname') ? true : false}>
                                                    <div className='d-flex text-nowrap'>
                                                        <span>{t('LastName')}</span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('LastName')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('lastname')}></span>
                                                    </div>
                                                </th>
                                                <th id="username" style={{ backgroundColor: (filterValueDict.username ? '#6cae55' : ''), width: '250px' }} hidden={hideColumn.includes('username') ? true : false}>
                                                    <div className='d-flex text-nowrap'>
                                                        <span>{t('UserName')}</span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('Username')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('username')}></span>
                                                    </div>
                                                </th>
                                                <th id="email" style={{ backgroundColor: (filterValueDict.email ? '#6cae55' : ''), width: '250px' }} hidden={hideColumn.includes('email') ? true : false}>
                                                    <div className='d-flex text-nowrap'>
                                                        <span>{t('Email')}</span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('Email')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('email')}></span>
                                                    </div>
                                                </th>
                                                <th id="userstype" style={{ backgroundColor: (multiFilterValue.userstype ? '#6cae55' : ''), width: '250px' }} hidden={hideColumn.includes('userstype') ? true : false}>
                                                    <div className='d-flex text-nowrap'>
                                                        <span>{t('UserType')}</span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('UsersType')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('userstype')}></span>
                                                    </div>
                                                </th>
                                                <th id="last_modified_by" style={{ backgroundColor: (multiFilterValue.last_modified_by ? '#6cae55' : ''), width: '250px' }} hidden={hideColumn.includes('last_modified_by') ? true : false}>
                                                    <div className='d-flex text-nowrap'>
                                                        <span>{t('LastModifiedBy')}</span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('LastModifiedUser_Name')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('last_modified_by')}></span>
                                                    </div>
                                                </th>
                                                <th id="last_modified_date" style={{ backgroundColor: (filterValueDict.last_modified_date ? '#6cae55' : ''), width: '280px' }} hidden={hideColumn.includes('last_modified_date') ? true : false}>
                                                    <div className='d-flex text-nowrap'>
                                                        <span>{t('LastModifiedDate')}</span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('LastModifiedDate')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('last_modified_date')}></span>
                                                    </div>
                                                </th>
                                             
                                                <th id="status" style={{ backgroundColor: (multiFilterValue.status ? '#6cae55' : ''), maxWidth: '200px' }} hidden={hideColumn.includes('status') ? true : false}>
                                                    <div className='d-flex text-nowrap'>
                                                        <span>{t('IsActive')}  &nbsp;</span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('IsActive')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('status')}></span>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody>

                                            {
                                                showspinner === true ?

                                                    <>
                                                        <Spinner animation="grow" size="sm" variant="success" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                        <Spinner animation="grow" size="sm" variant="danger" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                        <Spinner animation="grow" size="sm" variant="warning" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                    </>
                                                    :
                                                    filteredArray.length === 0 ?
                                                        <tr>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td>
                                                                No Data Available
                                                            </td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                        :
                                                        filteredArray.map((item, index) => (

                                                            <tr key={index} onDoubleClick={(event) => OpenUser(event, item.Id, item.UsersType)}>
                                                                <td><input id={item.Id} data-index="1" name="chkBox" type="checkbox" onChange={(event) => handleChange(event, item.Id)} /></td>
                                                           
                                                                <td hidden={hideColumn.includes('firstname') ? true : false}>{item.FirstName}</td>
                                                                <td hidden={hideColumn.includes('lastname') ? true : false}>{item.LastName}</td>
                                                                <td hidden={hideColumn.includes('username') ? true : false}>{item.Username}</td>
                                                                <td hidden={hideColumn.includes('email') ? true : false}>{item.Email} </td>
                                                                <td hidden={hideColumn.includes('userstype') ? true : false}>{item.UsersType} </td>
                                                                <td hidden={hideColumn.includes('last_modified_by') ? true : false}>{item.LastModifiedUser_Name}</td>
                                                                <td hidden={hideColumn.includes('last_modified_date') ? true : false}>

                                                                    {item.LastModifiedDate === null ? "" : moment(item.LastModifiedDate).format(SessionManage.getcurrentdateformat())}
                                                                </td>
                                                             
                                                                <td hidden={hideColumn.includes('status') ? true : false}>
                                                                    {Service.editPerms(userPerms) == false ? (item.IsActive === true ? "Active" : "In-Active") : item.IsActive === true ?
                                                                        <a href="#" style={{ cursor: "pointer" }} title="Active/In-Active" onClick={(event) => ToggleUserActiveStatus(item.Id, item.UserId, "Open-A")}>Active</a>
                                                                        :
                                                                        <a href="#" style={{ cursor: "pointer" }} title="Active/In-Active" onClick={(event) => ToggleUserActiveStatus(item.Id, item.UserId, "Open-D")}>In-Active</a>
                                                                    }
                                                                </td>
                                                                
                                                            </tr>


                                                        ))
                                            }

                                        </tbody>
                                    </table>
                                </div>
                                {
                                    showLoadMore === true ?
                                        <div className='text-center container row'>
                                            <div className='col-md-5'></div>
                                            <div className='col-md-2'>
                                                <button className='btn btn-sm btn-update ml-1' onClick={handleLoadMore} style={{ width: "70px" }}>Load More</button>
                                            </div>
                                            <div className='col-md-5'></div>

                                        </div>
                                        :
                                        ""
                                }
                                <div className='row'>

                                    {
                                        totalselectedshow === true ?

                                            <div className='col-lg-2 forgettext col-sm-12 border  form-control' style={{ textAlign: "center", marginLeft: "0.938rem" }}>
                                                Total Records Selected - <span id="totalselected">{totalselectedcount}</span>
                                            </div>

                                            :
                                            <div className='col-lg-2  col-sm-12' style={{ textAlign: "center" }}>

                                            </div>
                                    }



                                    <div className='col-lg-7 col-sm-12'></div>


                                    <div className='col-lg-2 forgettext  col-sm-12 border form-control' style={{ textAlign: "center" }}>
                                        Total Records - {totalCountlst}
                                    </div>
                                </div>

                                {showfilterpopup === true ?
                                    <div className='pop-up' style={{ left: divleft, top: divtop }}>
                                      
                                        {filterCol === 'firstname' || filterCol === 'lastname' || filterCol === 'username' || filterCol === 'email' || filterCol === 'roles' ?

                                            <>
                                                <div className="container">
                                                    <div className="row">
                                                        <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>
                                                    </div>
                                                </div>
                                                <div className="container mt-2">
                                                    <div className="row">
                                                        <input type='textbox' placeholder={t('Search')} className='filtertextboxbackground col-md-11 textbox-padding ml-2' style={{ outline: 'none' }} value={filterValueDict[filterCol]} onChange={(event) => filterInput(event)}></input>
                                                    </div>
                                                </div>
                                            </>

                                            :
                                            <>
                                            </>

                                        }



                                        {filterCol === 'last_modified_date' ?

                                            <>
                                                <div className="container">
                                                    <div className="row">
                                                        <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>
                                                    </div>
                                                </div>
                                                <div className="container mt-2">
                                                    <div className="row">
                                                        <input type='date' className='filtertextboxbackground col-md-11 textbox-padding ml-2' value={filterValueDict[filterCol]} onChange={(event) => filterInput(event)}></input>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <></>
                                        }

                                        {filterCol === 'createdby' || filterCol === 'last_modified_by' || filterCol === 'status' || filterCol === 'userstype' ?
                                           
                                            <>
                                                <div className="container">
                                                    <div className="row">
                                                        <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>

                                                    </div>

                                                </div>

                                                <div className="container mt-2">
                                                    <div className="row">
                                                        <input type="text" placeholder={t('Search')} value={filterSearch} onChange={(event) => UserfilterHandler(event)} className='filtertextboxbackground col-md-11 textbox-padding ml-2' />
                                                    </div>

                                                    <div className="row contentoverflow mt-2">
                                                        <table className="filtertable">
                                                            <tbody>
                                                                <tr>
                                                                    <td><input type="checkbox" name="filter-option-all" id="option-all" onChange={checkAllFilter} /></td>
                                                                    <td>{t('SelectAll')}</td>
                                                                </tr>
                                                                {
                                                                    dropdownData.map((item, index) => (

                                                                        <tr key={index}>

                                                                            <td><input id={item.Id} data-index="1" name="filter-option-all" checked={item.ischecked} type="checkbox" onChange={(event) => OndropdownChange(event, item.Id, item.Name)} /></td>

                                                                            <td>{item.Name}</td>

                                                                        </tr>


                                                                    ))
                                                                }

                                                            </tbody>
                                                        </table>

                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <></>
                                        }
                                       
                                       
                                        <div style={{ paddingTop: '10px', paddingLeft: '47px', paddingBottom: '7px' }}>
                                            <button className="btn btn-sm btn-update" onClick={() => filterHandler('Apply')} style={{ width: "45px" }}>{t('Apply')}</button>
                                            <button className="btn btn-sm btn-update" style={{ marginLeft: '10px', width: "45px" }} onClick={() => filterHandler('Reset')}>{t('Reset')}</button>
                                        </div>
                                        <div>
                                        </div>
                                    </div>
                                    :
                                    <></>
                                }

                                {showAddColumnPopup === true ?
                                    <div className='pop-up' style={{ left: divleft, top: divtop, width: "250px" }}>
                                        <div className="container">
                                            <div className="row">
                                                <Select
                                                    className='col-md-10 textbox-padding fontsizedropdown'
                                                    options={colVisibilityOption}
                                                    placeholder="Select"
                                                    value={colvisiOptionVal}
                                                    onChange={OnChangeColVisibilityList}
                                                    styles={customStyles}
                                                />
                                                <button id="createColumnListPopup" icon className="btn btn-update ml-1 pa-0" type="submit"
                                                    style={{ float: "left", cursor: "pointer", padding: '0px', height: '28px', width: '26px' }}
                                                    onClick={CreateColumnListPopup} title='Add New View'><i class="fa fa-plus" aria-hidden="true"></i></button>
                                            </div>
                                            <div className="row mb-2" style={{ textAlign: 'right' }}>
                                                <div className="col-md-10 mt-1 mr-1" style={{ padding: '0px', fontSize: '12px' }}>
                                                    <input id="existingViewVal" data-index="1" name="filter-option-all" checked={existingViewVal} type="checkbox"
                                                        onChange={(event) => OnChangeexistingViewVal(event)} /> <b>Is Default</b>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <table className='ml-2' style={{ fontSize: '0.80rem' }}>
                                                    {
                                                        addRemoveCol.map((item, index) => (
                                                            <tr>
                                                                <td key={index}><input id={item.label} data-index="1" name="filter-option-all" checked={item.value} type="checkbox"
                                                                    onChange={(event) => OnchangeAddRemoveCol(event, item)}
                                                                /> {t(item.label)}</td>
                                                            </tr>
                                                        ))
                                                    }
                                                </table>
                                            </div>
                                            <div className="row mr-2" style={{ float: "right" }}>
                                                <div style={{ paddingTop: '10px', paddingLeft: '15px', paddingBottom: '7px' }}>
                                                    <button className="btn btn-sm btn-update" style={{ width: "45px" }} onClick={() => updateColumnVisibilityList('reset')}>{t('Reset')}</button>
                                                    <button className="btn btn-sm btn-update ml-1" style={{ width: "45px" }} onClick={closeAddRemoveColumnPopup}>{t('Close')}</button>
                                                    <button className="btn btn-sm btn-update ml-1" style={{ width: "50px", background: '#4755ab', color: 'white' }} onClick={() => updateColumnVisibilityList('apply')} title='Update View'>{t('Update')}</button>
                                                    <button className="btn btn-sm btn-update ml-1" style={{ width: "50px", background: 'rgb(233 43 0)', color: 'white' }} onClick={deleteColumnVisibilityView} title='Delete View'>{t('Delete')}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : <></>
                                }


                                {showCreateColumnListPopup === true ?
                                    <div className='pop-up' style={{ left: divleft1, top: divtop1, width: "190px" }}>
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-12 ml-2" style={{ padding: '0px' }}>
                                                    <input type='textbox' placeholder='View Name' style={{ height: '30px' }}
                                                        value={colViewName} name={colViewName} onChange={(event) => OnChangeSetViewName(event)}></input>
                                                </div>
                                            </div>
                                            <div className="row mb-2" style={{ textAlign: 'right' }}>
                                                <div className="col-10 mt-2" style={{ padding: '0px', fontSize: '12px' }}>
                                                    <input id="defaultView" data-index="1" name="filter-option-all" checked={defaultView} type="checkbox"
                                                        onChange={(event) => OnChangedefaultView(event)} /> <b>Set Default</b>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <table className='ml-2' style={{ fontSize: '0.80rem' }}>
                                                    {
                                                        createColumnListOption.map((item, index) => (
                                                            <tr>
                                                                <td key={index}><input id={item.label} data-index="1" name="filter-option-all" checked={item.value} type="checkbox"
                                                                    onChange={(event) => OnchangeCreateColumnListOption(event, item)}
                                                                /> {t(item.label)}</td>
                                                            </tr>
                                                        ))
                                                    }
                                                </table>
                                            </div>
                                            <div className="row mr-2" style={{ float: "right" }}>
                                                <div style={{ paddingTop: '10px', paddingLeft: '35px', paddingBottom: '7px' }}>
                                                    <button className="btn btn-sm btn-update ml-1" style={{ width: "50px" }} onClick={CloseCreateColumnListPopup}>{t('Cancel')}</button>
                                                    <button className="btn btn-sm btn-update ml-1" style={{ width: "40px", background: '#4755ab', color: 'white' }} onClick={() => CreateColumnVisibilityList('apply')}>{t('Save')}</button>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                    : <></>
                                }

                                <CustomErrorPopup ErrorMessage={errorMessage} show={showErrorModal} ErrorStatus={errorStatus}
                                    onHide={() => setShowErrorModal(false)}></CustomErrorPopup>

                                <Modal show={ToggleStatusModal}
                                    size="sm"
                                    aria-labelledby="contained-modal-title-vcenter"
                                    centered>
                                    <Modal.Body style={{ "textAlign": 'center', fontFamily: 'Segoe UI' }}>Are you sure you want to {toggleChange}?</Modal.Body>
                                    <Modal.Footer>
                                        <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "35px" }} size='sm' onClick={() => ToggleUserActiveStatus(toggleId, toggleUserId, "Ok")}>
                                            Yes
                                        </Button>
                                        <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "50px" }} size='sm' onClick={() => ToggleUserActiveStatus(toggleId, toggleUserId, "Cancel")}>
                                            Cancel
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                            </div>

                        </div>
                    </div>

                </div>
            </div>



        </div>
    </>)
}
export default UserListComponent