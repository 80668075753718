import React, { useEffect, useState, forwardRef, useRef } from 'react';
import { Form, FormGroup, Col, Row, Button, InputGroup, Collapse, Modal } from 'react-bootstrap';
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import DatePicker from "react-datepicker";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { Link, useLocation } from "react-router-dom"
import Service from '../../../Services/Service';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import Multiselect from 'multiselect-react-dropdown';
import SessionManage from '../../../SessionManagement/SessionManage';
import { CSVLink } from "react-csv";
import { useTranslation } from "react-i18next";
import CustomErrorPopup from '../../../UI/CustomErrorPopup';
import { ToastContainer, toast } from 'react-toastify';
function WorkflowRules(props) {
    const csvInstance = useRef();
    const [csvList, setCsvList] = useState([]);
    const [sortColName, setSortColName] = useState("");
    const [sticky, setSticky] = useState("");
    const location = useLocation();
    const navigate = useNavigate();
    const [t, i18n] = useTranslation('translation');
    let rowData = [];
    const [userPerms, setuserPermission] = useState([]);
    const [showspinner, setshowspinner] = useState('');
    const [listViewData, setListViewData] = useState(rowData)
    const [filteredArray, setFilteredArray] = useState(rowData)
    const [searchValue, setSearchValue] = useState(null)
    const [checkedValueid, setCheckedValueid] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [showbutton, setshowbutton] = useState('');
    const [filterValue, setFilterValue] = useState('');
    const [filterCol, setFilterCol] = useState('');
    const [dropdownData, setDropdownData] = useState([]);
    const [dropdownChange, setDropdownChange] = useState('');
    const [showfilterpopup, setshowfilterpopup] = useState(false);
    const [columnAscending, setColumnAscending] = useState(true);
    const [totalselectedshow, settotalselectedshow] = useState('');
    const [totalselectedcount, settotalselectedcount] = useState(0);
    const [totalCountlst, settotalCountlst] = useState('');
    const [ToggleStatusModal, setToggleStatusModal] = useState(false);
    const [toggleChange, setToggleChange] = useState("");
    const [toggleId, setToggleId] = useState();
    const [filtertext, setfiltertext] = useState('');
    const [filterSearch, setFilterSearch] = useState("");
    const [filterdropdownData, setFilterDropdownData] = useState([]);
    const [multiValueids, setMultiValueIds] = useState([]);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [checkedMultiValueid, setCheckedMultiValueid] = useState([]);
    const [deleteshowbutton, setDeleteshowbutton] = useState('');
    const [errorStatus, setErrorStatus] = useState("Error");
    const [paging, setpaging] = useState({
        pageSize: 20,
        pageNumber: 1
    })
    const [filterValueDict, setFilterValueDict] = useState({
        workflow_name: '',
        rebatetype: '',
        saleslevel: '',
        businessunit: '',
        created_date: '',
        lastmodified_date: null,
    });
    const [multiFilterValue, setMultiFilterValue] = useState({
        createdby: '',
        lastmodifiedby: '',
        status: ''
    });
    let showSuccessMsg = false;
    let showErrorMsg = false;
    let showDetails = '';
    let loggedInUser = sessionStorage.getItem("id");
    {
        useEffect(() => {
            setshowspinner(true);
            setColumnAscending(false);
            setSortColName("Id");
            getWorkflowRuleListViewData("WorkflowRule/GetAll", paging.pageSize, paging.pageNumber, "Descending", "Id", [], null)
       
            getUserPermission(location.pathname);
            window.addEventListener("scroll", isSticky);
            return () => {
                window.removeEventListener("scroll", isSticky);
            };
           
        }, [props.id])
    }
    const activeOption = [
        { Name: "Active", Id: "1" },
        { Name: "In-Active", Id: "0" },

    ]
    const isSticky = () => {

        const scrollTop = window.scrollY;
        const stickyClass = scrollTop >= 60 ? "is-sticky-thead" : "";
        setSticky(stickyClass);
        const scrollTopActual = scrollTop >= 60 ? scrollTop - 85 : 0;
        var divtop1 = document.getElementById("tableA");
        setdivtop(divtop1.offsetTop + scrollTopActual)
    };
    const class_thead = `text-white rt-thead  ${sticky}`;

    const getUserPermission = (pathname) => {
        Service.GetUserPermission(pathname).then((res) => {
            if (res !== 'undefined') {
              
                if (res.data !== 'null' && res.data !== 'undefined' && res.data !== '') {
                    if (res.data.DataList !== 'undefined') {
                        setuserPermission(res.data.DataList)
                    }
                }
            }
        });
    }
    const ExporttoCSV = () => {
        let checkedBoxes = document.querySelectorAll('input[name=chkBox]:checked');
        let values = "";
        if (checkedBoxes.length >= 1) {
            for (let checkbox of checkedBoxes) {
                values = values + ';' + checkbox.id;

            }
        }
        let objFilter = [];
        let objFilterValue = [{
            filterKey: "id",
            FilterValue: values,
        }]

        objFilter = objFilterValue;

        Service.getDatawithMultiFilterSearch("WorkflowRule/GetAll", 20000, 1, "Ascending", "Id", objFilter, null).then((res) => {
            if (typeof res !== 'undefined') {
                if (res.data !== 'null' && res.data !== 'undefined') {
                    setCsvList(res.data.DataList);
                    setTimeout(() => {
                        csvInstance.current.link.click();
                    });

                }
            }
        });
    }
    const checkAll = (mycheckbox) => {
        var checkboxes = document.querySelectorAll("input[type = 'checkbox']");
        if (mycheckbox.target.checked == true) {
            settotalselectedshow(true);
            checkboxes.forEach(function (checkbox) {
                checkbox.checked = true;
            });
            settotalselectedcount(checkboxes.length - 1)
        }
        else {
            settotalselectedshow(false);
            checkboxes.forEach(function (checkbox) {
                checkbox.checked = false;
            });
            settotalselectedcount(0)
        }

    }

    const getWorkflowRuleListViewData = (method, pagesize, pagenumber, sortType, SortProperty, filterValue, search) => {
        Service.getDatawithMultiFilterSearch(method, pagesize, pagenumber, sortType, SortProperty, filterValue, search).then((res) => {
            if (typeof res !== 'undefined') {
                if (res.data !== 'null' && res.data !== 'undefined') {
                    rowData = res.data.DataList
                    setListViewData(rowData)
                    setFilteredArray(rowData)
                    settotalCountlst(res.data.Totalcount)
                    setshowspinner(false);
                }
            }
        });
    }
    const OnActivateOrDeactivate = (item, flag) => {
        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
        setToggleId(item)
        if (flag == "Open-A") {
            setToggleStatusModal(true)
            setToggleChange("DeActivate")
        }
        if (flag == "Open-D") {
            setToggleStatusModal(true)
            setToggleChange("Activate")
        }
        if (flag == "Ok") {
            Service.PostWithIdAndUser("WorkflowRule/ActivateOrDeActivate", item, loggedInUser).then((res) => {
                if (typeof res !== 'undefined') {

                    if (res.data !== 'null' && typeof res.data !== 'undefined') {
                        if (res.data.Status == "success") {

                            getWorkflowRuleListViewData("WorkflowRule/GetAll", paging.pageSize, paging.pageNumber, sortType, sortColName, filterLst(), searchValue)

                        }
                        else {
                            setErrorMessage(res.data.Status);
                            showErrorMsg = true;
                            setShowErrorModal(true);
                            setErrorStatus("Error");
                        }

                    }
                }
                setToggleStatusModal(false)
            });
        }
        if (flag == "Cancel") {
            setToggleStatusModal(false)
        }
    }
    const filterLst = () => {
        let list = [];
        for (var key in multiFilterValue) {
            if (multiFilterValue[key].length > 0) {
                let values = ""
                multiFilterValue[key].map((i) => {
                    values += i.Name + ";";
                })
                if (values !== null && values !== '') {
                    list.push({
                        filterKey: key,
                        FilterValue: values
                    })
                }
            }
        }

        for (var key in filterValueDict) {
            if (filterValueDict[key] !== null && filterValueDict[key] !== '') {
                list.push({
                    filterKey: key,
                    FilterValue: filterValueDict[key]
                })
            }
        }
        return list;
    }

    const searchHandler = (event) => {
        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
        let pagenumber = 1;
        paging.pageNumber = pagenumber;
        setpaging(paging);
        if (event.target.value != '') {

            setSearchValue(String(event.target.value).toLowerCase())
            getWorkflowRuleListViewData("WorkflowRule/GetAll", paging.pageSize, pagenumber, sortType, sortColName, filterLst(), event.target.value);
        }
        else {
            setSearchValue(null);
            getWorkflowRuleListViewData("WorkflowRule/GetAll", paging.pageSize, pagenumber, sortType, sortColName, filterLst(), null);
        }
    }


    const LoadMore = (lst, totalcount) => {

        if (lst < totalcount) {
            setshowLoadMore(true);
        }
        else if (lst >= totalcount) {
            setshowLoadMore(false);
        } else {
            setshowLoadMore(false);
        }

    }
    const [showLoadMore, setshowLoadMore] = useState('');
    const handleLoadMore = () => {
        let pagenumber = paging.pageNumber + 1;
        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
        Service.getDatawithMultiFilterSearch("WorkflowRule/GetAll", paging.pageSize, pagenumber, sortType, sortColName, filterLst(), searchValue).then((res) => {

            if (typeof res !== 'undefined') {
                if (res.data !== 'null' && typeof res.data !== 'undefined') {
                    const currentlength = listViewData.length;
                    setListViewData(data => [...data, ...res.data.DataList])
                    setFilteredArray(data => [...data, ...res.data.DataList])
                    settotalCountlst(res.data.Totalcount)

                    LoadMore(currentlength + res.data.DataList.length, res.data.Totalcount)
                }
            }
        });

        paging.pageNumber = pagenumber
        setpaging(paging);
    };

    const handleChange = (e, id) => {
        let isChecked = e.target.checked;   // true if it is checked false if unchecked

        let checkedBoxes = document.querySelectorAll('input[name=chkBox]:checked');
        if (isChecked === true) {
            setCheckedValueid(id)
            settotalselectedshow(true);
            settotalselectedcount(checkedBoxes.length);
        }
        else {
            setCheckedValueid([]);
            if (checkedBoxes.length === 0) {
                settotalselectedshow(false);
            }
            settotalselectedcount(checkedBoxes.length);
        }

        if (checkedBoxes.length === 1) {
            setshowbutton(true);
        }
        else if (checkedBoxes.length > 1) {
            setshowbutton(false);
            setDeleteshowbutton(true)
        }
        else {
            setshowbutton(false);
        }

    }
    const OpenHandler = (id) => {

        if (id == null || id == 0) {
            alert('Please select the Workflow Rule to view')
        }
        else {
            navigate("/workflow/edit/" + id);
        }
    }

    const DeleteHandler = (id) => {
        if (id == null || id == 0) {
            alert('Please select the Workflow Rule to view')
        }
        else {
            Service.delete('WorkflowRule/DeleteWorkflowRule', id).then((res) => {

                if (typeof res !== 'undefined') {
                    if (res.data !== 'null' && typeof res.data !== 'undefined') {
                        if (res.data.Status == "Success") {
                            setShowDeleteModal(false)
                            toast.success('Deleted Successfully !', {
                                position: toast.POSITION.TOP_RIGHT,
                                onClose: () => navigate("/Workflow/2")
                            });
                        }
                        else {
                            setShowDeleteModal(false)
                            setErrorMessage(res.data.Status);
                            showErrorMsg = true;
                            setShowErrorModal(true);
                            setErrorStatus("Error")
                        }
                    }
                }
            });

        }
    }
    const OpenWorkflow = (event, id) => {
        if (event.detail === 2) {

            navigate("/workflow/edit/" + id);
        }
    }

    const filterInput = (e) => {
        let inputValue = e.target.value
        setFilterValue(inputValue)
     
        setFilterValueDict(() => ({
            ...filterValueDict, [filterCol]: e.target.value,
        }));
    }

    const [divleft, setdivleft] = useState('');
    const [divtop, setdivtop] = useState('');

    const FilterColumn = (name) => {

        const scrollTop = window.scrollY;
        const scrollTopActual = scrollTop >= 60 ? scrollTop - 85 : 0;
        var divtop1 = document.getElementById("tableA");
        setdivtop(divtop1.offsetTop + scrollTopActual + 30)
        setshowfilterpopup(true)
        setFilterCol(name)

        if (name === 'lastmodifiedby') {
            setfiltertext(t('LastModifiedBy'))
            var testDiv = document.getElementById("lastmodifiedby");
            setdivleft(testDiv.offsetLeft + 20 + "px");
            DropdownHandler(name)
        }
        if (name === 'createdby') {
            setfiltertext('Created By')
            var testDiv = document.getElementById("createdby");
            setdivleft(testDiv.offsetLeft + 20 + "px");
            DropdownHandler(name)
        }
        if (name === 'workflow_name') {
            setfiltertext(t('WorkflowRules.WorkflowItem'))
            var testDiv = document.getElementById("workflow_name");
            setdivleft(testDiv.offsetLeft + 20 + "px");
        }
        if (name === 'rebatetype') {
            setfiltertext('Rebate Type')
            var testDiv = document.getElementById("rebatetype");
            setdivleft(testDiv.offsetLeft + 20 + "px");
        }
        if (name === 'saleslevel') {
            setfiltertext('Sales Level')
            var testDiv = document.getElementById("saleslevel");
            setdivleft(testDiv.offsetLeft + 20 + "px");
        }
        if (name === 'businessunit') {
            setfiltertext(t('WorkflowRules.BusinessUnit'))
            var testDiv = document.getElementById("businessunit");
            setdivleft(testDiv.offsetLeft + 20 + "px");
        }
        if (name === 'lastmodified_date') {
            setfiltertext(t('LastModifiedDate'))
            var testDiv = document.getElementById("lastmodified_date");
            setdivleft(testDiv.offsetLeft + 20 + "px");
        }

        if (name === 'status') {
            setfiltertext(t('IsActive'))
            var testDiv = document.getElementById("status");
            setdivleft(testDiv.offsetLeft + 20 + "px");
            addnewfield(activeOption, name)
        }
    }

    const addnewfield = (data, key) => {
        let selectedKeys = multiFilterValue[key];
        let currentdata = data.map((i) => {
            i["ischecked"] = false;
            return i;
        });

        if (key != 'status') {
            if (selectedKeys != '') {
                currentdata.forEach((each) => {
                    selectedKeys.forEach((eachSelected) => {
                        if (each.Name == eachSelected.Name) {
                            each.ischecked = true
                        }
                    })
                })
            }

            setDropdownData(currentdata);
            setFilterDropdownData(currentdata)
        }
        else {
            if (selectedKeys != '') {
                currentdata.forEach((each) => {
                    selectedKeys.forEach((eachSelected) => {
                        if (each.Name == eachSelected.Name) {
                            each.ischecked = true
                        }
                    })
                })
                setDropdownData(currentdata)
                setFilterDropdownData(currentdata)
            }
            else {
                setDropdownData(currentdata)
                setFilterDropdownData(currentdata)
            }
        }
    }


    const checkAllFilter = (mycheckbox) => {
        var lastfilter = [...dropdownData]
        let data1 = []
        var checkboxes = document.querySelectorAll("input[name = 'filter-option-all']");
        if (mycheckbox.target.checked == true) {

            let currentdata = lastfilter.map((i) => {
                i["ischecked"] = true;
                return i;
            });
            setDropdownData(currentdata)

            dropdownData.map((each) => {

                const getallData = {
                    Name: each.Name,
                }
                data1.push(getallData)
            })
            setFilterValue(filterValue => [...filterValue, ...data1]);
            let updateddata = ([...filterValue, ...data1]);
            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: updateddata,
            }));
        }
        else {

            let currentdata = lastfilter.map((i) => {
                i["ischecked"] = false;
                return i;
            });
            setFilterValue([])
            setMultiFilterValue([])
        }

    }



    const OndropdownChange = (e, id, name) => {
        let isChecked = e.target.checked;
        var lastfilter = [...dropdownData]
        if (isChecked === true) {
            let currentdata = lastfilter.map((i) => {
                if (i.Id == id) {
                    i["ischecked"] = true;
                }
                return i;
            });
            setDropdownData(currentdata);
            let data1 = []
            const getallData = {
                Name: name,
            }
            data1.push(getallData)
            setFilterValue(filterValue => [...filterValue, ...data1]);
            let updateddata = ([...filterValue, ...data1]);
            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: updateddata,
            }));
        }
        else {
            let currentdata = lastfilter.map((i) => {
                if (i.Id === id) {
                    i["ischecked"] = false;
                }
                return i;
            });
            setDropdownData(currentdata);
            setFilterValue(filterValue => filterValue.filter(data => data.Name !== name))
            let data1 = multiFilterValue.filter(data => data.Name !== name);
            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: data1,
            }));
        }
    }

    const UserfilterHandler = (e) => {

        setFilterSearch(e.target.value)
        if (e.target.value !== "") {
            let searchResults = dropdownData.filter((each) => {
                return each.Name.toLowerCase().includes(String(e.target.value).toLowerCase())
            });
            setDropdownData(searchResults);
        }
        else {

            setFilterSearch("")
            setDropdownData(filterdropdownData)
        }
    }



    const DropdownHandler = (key) => {
        Service.getfilterDropdown('WorkflowRule/GetWorkflowFilterValue', key).then((res) => {
            if (typeof res !== 'undefined') {

                if (res.data !== 'null' && typeof res.data !== 'undefined') {

                    addnewfield(res.data, key)
                }
            }
        });
    }



    const filterHandler = (flag) => {

        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
        let pagenumber = 1;
        paging.pageNumber = pagenumber;

        if (flag === 'Apply') {
            setshowfilterpopup(false)
            let list = [];

            for (var key in multiFilterValue) {
                if (multiFilterValue[key].length > 0) {
                    let values = ""
                    multiFilterValue[key].map((i) => {
                        values += i.Name + ";";
                    })
                    if (values !== null && values !== '') {
                        list.push({
                            filterKey: key,
                            FilterValue: values
                        })
                    }
                }
            }

            for (var key in filterValueDict) {
                if (filterValueDict[key] !== null && filterValueDict[key] !== '') {
                    list.push({
                        filterKey: key,
                        FilterValue: filterValueDict[key]
                    })
                }
            }
            if (list.length > 0) {
                setpaging(paging);
                getWorkflowRuleListViewData("WorkflowRule/GetAll", paging.pageSize, pagenumber, sortType, sortColName, list, searchValue);
            }

        }
        else {
            setshowfilterpopup(false)
            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: '',
            }));
            setFilterValueDict(() => ({
                ...filterValueDict, [filterCol]: '',
            }));

            let list = [];
            for (var key in multiFilterValue) {
                if (key !== filterCol && multiFilterValue[key].length > 0) {
                    let values = ""
                    multiFilterValue[key].map((i) => {
                        values += i.Name + ";";
                    })
                    if (values !== null && values !== '') {
                        list.push({
                            filterKey: key,
                            FilterValue: values
                        })
                    }
                }
            }

            for (var key in filterValueDict) {
                if (filterValueDict[key] !== null && filterValueDict[key] !== '' && key !== filterCol) {
                    list.push({
                        filterKey: key,
                        FilterValue: filterValueDict[key]
                    })
                }
            }
            setpaging(paging);
            getWorkflowRuleListViewData("WorkflowRule/GetAll", paging.pageSize, pagenumber, sortType, sortColName, list, searchValue);



            setDropdownChange("")
            setFilterCol('')
            setFilterValue('')
            setDropdownData([])
            setFilterSearch("")
            setFilterDropdownData([])
        }
    }

    const SortColumn = (colName) => {

        var sortType = '';
        if (columnAscending === true) {
            setColumnAscending(false);
            sortType = "Descending";
        } else {
            setColumnAscending(true);
            sortType = "Ascending";
        }
        setSortColName(colName);

        let pagenumber = 1;

        paging.pageNumber = pagenumber;
        setpaging(paging);


        getWorkflowRuleListViewData("WorkflowRule/GetAll", paging.pageSize, pagenumber, sortType, colName, filterLst(), searchValue);

    }
    const customMultiStyles = {
        multiselectContainer: {
            maxWidth: '188px',
            marginLeft: '3px'
        },
        chips: {
            borderRadius: '5px'
        }
    };
    let headers = [
        { label: "Workflow Item", key: "WorkflowItemTypeName" },
        { label: "Business Unit", key: "BusinessUnitName" },
        { label: "Last Modified By", key: "LastModifiedUserName" },
        { label: "Last Modified Date", key: "LastModifiedDate" },
        { label: "Is Active", key: "IsActive" },

    ];

    const checkDelete = () => {
        let checkedBoxes = document.querySelectorAll('input[name=chkBox]:checked');
        if (checkedBoxes.length == 1) {

            setMultiValueIds(checkedValueid)
            showDeleteDialog('open')

        }
        if (checkedBoxes.length > 1) {
            setCheckedMultiValueid([])
            for (let checkbox of checkedBoxes) {
                checkedMultiValueid.push(checkbox.defaultValue)
            }
            checkedMultiValueid.map(i => {

                var z = i.split(",");
                multiValueids.push(z[0])
                showDeleteDialog('open')

            })
        }
    }

    // delete modal handler
    const showDeleteDialog = (flag) => {
        if (flag === 'open') {
            setShowDeleteModal(true)
        }
        if (flag === 'close') {
            setShowDeleteModal(false)
            let checkedBoxes = document.querySelectorAll('input[name=chkBox]:checked');
            for (let checkbox of checkedBoxes) {
                checkbox.checked = false;
            }
            setMultiValueIds([])
            setDeleteshowbutton(false)
        }
        if (flag === 'proceed') {

            DeleteHandler(multiValueids)
            let checkedBoxes = document.querySelectorAll('input[name=chkBox]:checked');
            for (let checkbox of checkedBoxes) {
                checkbox.checked = false;
            }
            setDeleteshowbutton(false)
        }
    }

    return (
        <div className="container-fluid content-top-gap">

            <nav aria-label="breadcrumb">
                <ol className="breadcrumb my-breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">{t('Home.title')}</Link></li>
                    <li className="breadcrumb-item"><Link to="/admin">{t('Menu.Admin')}</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">{t('WorkflowRules.WorkflowRulesList')}</li>
                </ol>
            </nav>
            <ToastContainer autoClose={1000} />
            <div style={{ display: "none" }}>
                <CSVLink data={csvList} headers={headers} ref={csvInstance}   > </CSVLink>
            </div>

            <div className="">
                <div className="row">
                    <div className="col-lg-12 pr-lg-2">
                        <div className="card text-center card_border overflow-auto">

                            <div className="card-body" style={{ minHeight: "70vh" }}>
                                <div className="row" style={{ position: "sticky" }}>

                                    <div className="col-md-7">
                                        <input type="text" autoComplete='off' className="form-control form-control-user" id="firstName" name="firstName" placeholder={`${t('SearchFor')} ${t('WorkflowRules.WorkflowItem')} ,  ${t('WorkflowRules.BusinessUnit')} , ${t('LastModifiedBy')}`} onChange={(event) => searchHandler(event)} />
                                    </div>
                                    <div className="col-md-5">
                                        {Service.editPerms(userPerms) == false ? "" : <Link className="btn btn-sm btn-update ml-2" id="create" style={{ width: "90px", backgroundColor: "buttonface", float: "right" }} to="/workflow/add/0" >{t('CreateNew')}</Link>}
                                        {showbutton === true ?
                                            <>
                                                <button className="btn btn-update  btn-sm ml-2" type="submit" style={{ float: "right", width: "40px" }} onClick={() => OpenHandler(checkedValueid)}>{t('Open')}</button>

                                                {Service.editPerms(userPerms) == false ? "" : <button className="btn btn-update  btn-sm ml-2" type="submit" style={{ float: "right", width: "50px" }} onClick={() => checkDelete(checkedValueid)}>{t('Delete')}</button>}
                                            </>
                                            :
                                            deleteshowbutton === true ?
                                                <>

                                                    <button className="btn btn-update  btn-sm ml-2" style={{ float: "right", width: "50px" }} type="submit" onClick={(event) => checkDelete()}>{t('Delete')}</button>

                                                </> :
                                                ''
                                        }
                                        {totalselectedcount <= 0 ? "" : Service.exportPerms(userPerms) == false ? "" : <button className="btn btn-update  btn-sm ml-2" type="submit" style={{ float: "right", width: "50px" }} onClick={(event) => ExporttoCSV()}>{t('Export')}</button>}
                                    </div>
                                </div>
                                <div className='scrollbar-class'>
                                    <table className="table table-bordered text-left mt-2 table-responsive-sm" id="tableA">
                                        <thead className="text-white is-sticky-thead-product" style={{ backgroundColor: "var(--primary)" }}>
                                            <tr style={{ position: "sticky", top: "0", backgroundColor: "var(--primary)" }}>
                                                <th style={{ width: '30px' }}><input type="checkbox" id="option-all" onChange={checkAll} /></th>

                                                <th id='workflowrule_id' style={{ backgroundColor: (filterValueDict.workflow_name ? '#6cae55' : ''), width: '330px' }}>
                                                    <div className='d-flex text-nowrap'>
                                                        <span>{t('WorkflowRules.WorkflowRuleId')}</span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('WorkflowRuleId')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('workflowrule_id')}></span>
                                                    </div>
                                                </th>
                                                <th id='workflow_name' style={{ backgroundColor: (filterValueDict.workflow_name ? '#6cae55' : ''), width: '330px' }}>
                                                    <div className='d-flex text-nowrap'>
                                                        <span>{t('WorkflowRules.WorkflowItem')}</span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('WorkflowItemTypeName')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('workflow_name')}></span>
                                                    </div>
                                                </th>

                                                <th id='businessunit' style={{ backgroundColor: (filterValueDict.businessunit ? '#6cae55' : ''), width: '330px' }}>
                                                    <div className='d-flex text-nowrap'>
                                                        <span>{t('WorkflowRules.BusinessUnit')} </span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('BusinessUnitName')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('businessunit')}></span>
                                                    </div>
                                                </th>

                                                <th id='lastmodifiedby' style={{ backgroundColor: (multiFilterValue.lastmodifiedby ? '#6cae55' : ''), width: '330px' }}>
                                                    <div className='d-flex text-nowrap'>
                                                        <span>{t('LastModifiedBy')}</span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('LastModifiedUserName')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('lastmodifiedby')}></span>
                                                    </div>
                                                </th>
                                                <th id='lastmodified_date' style={{ backgroundColor: (filterValueDict.lastmodified_date ? '#6cae55' : ''), width: '300px' }}>
                                                    <div className='d-flex text-nowrap'>
                                                        <span>{t('LastModifiedDate')}</span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('LastModifiedDate')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('lastmodified_date')}></span>
                                                    </div>
                                                </th>

                                                <th id="status" style={{ backgroundColor: (multiFilterValue.status ? '#6cae55' : ''), maxWidth: '200px' }}  >
                                                    <div className='d-flex text-nowrap'>
                                                        <span>{t('IsActive')} &nbsp;</span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('IsActive')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('status')}></span>
                                                    </div>
                                                </th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                showspinner === true ?

                                                    <>
                                                        <Spinner animation="grow" size="sm" variant="success" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                        <Spinner animation="grow" size="sm" variant="danger" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                        <Spinner animation="grow" size="sm" variant="warning" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                    </>
                                                    :
                                                    filteredArray.length === 0 ?
                                                        <tr><td></td>
                                                            <td></td>
                                                            <td></td>

                                                            <td>
                                                                {t('NoDataAvailable')}
                                                            </td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                        :
                                                        filteredArray.map((listVal, index) =>
                                                            <tr onDoubleClick={(event) => OpenWorkflow(event, listVal.Id)}>
                                                                <td ><input id={listVal.Id} type="checkbox" value={listVal.Id} onChange={(event) => handleChange(event, listVal.Id)} name="chkBox" /></td>

                                                                <td>{listVal.WorkflowRuleId}</td>
                                                                <td>{listVal.WorkflowItemTypeName}</td>

                                                                <td>{listVal.BusinessUnitName}</td>

                                                                <td>{listVal.LastModifiedUserName}</td>
                                                                <td>{moment(listVal.LastModifiedDate).format(SessionManage.getcurrentdateformat())}</td>
                                                                <td ><div className="btn-group">
                                                                    <div className="btn-group" style={{ marginRight: '12px', cursor: "pointer" }}>
                                                                        {Service.editPerms(userPerms) == false ? (listVal.IsActive === true ? "Active" : "In-Active") : listVal.IsActive === true ?
                                                                            <a href="#" style={{ cursor: "pointer" }} title="Active/In-Active" onClick={(event) => OnActivateOrDeactivate(listVal.Id, "Open-A")}>Active</a>
                                                                            :
                                                                            <a href="#" style={{ cursor: "pointer" }} title="Active/In-Active" onClick={(event) => OnActivateOrDeactivate(listVal.Id, "Open-D")}>In-Active</a>
                                                                        }
                                                                    </div>

                                                                </div></td>
                                                            </tr>
                                                        )
                                            }


                                        </tbody>
                                    </table >
                                </div>

                                {
                                    showLoadMore === true ?
                                        <div className='text-center container row'>
                                            <div className='col-md-5'></div>
                                            <div className='col-md-2'>
                                                <button className='btn btn-sm btn-update ml-1' onClick={handleLoadMore} style={{ width: "70px" }}>{t('LoadMore')}</button>
                                            </div>
                                            <div className='col-md-5'></div>

                                        </div>
                                        :
                                        ""
                                }

                                <div className='row'>

                                    {
                                        totalselectedshow === true ?

                                            <div className='col-lg-2 forgettext col-sm-12 border form-control' style={{ textAlign: "center", marginLeft: "0.938rem" }}>
                                                {t('TotalRecordsSelected')} - <span id="totalselected">{totalselectedcount}</span>
                                            </div>

                                            :
                                            <div className='col-lg-2  col-sm-12' style={{ textAlign: "center" }}>

                                            </div>
                                    }



                                    <div className='col-lg-7 col-sm-12'></div>


                                    <div className='col-lg-2 forgettext  col-sm-12 border form-control' style={{ textAlign: "center" }}>
                                        {t('TotalRecords')} - {totalCountlst}
                                    </div>
                                </div>

                                {
                                    showfilterpopup === true ?
                                        <div className='pop-up' style={{ left: divleft, top: divtop }}>
                                            
                                            {filterCol === 'workflow_name' || filterCol === 'rebatetype' || filterCol === 'saleslevel' || filterCol === 'businessunit' ?

                                                <>
                                                    <div className="container">
                                                        <div className="row">
                                                            <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>
                                                        </div>
                                                    </div>
                                                    <div className="container mt-2">
                                                        <div className="row">
                                                            <input type='textbox' placeholder={t('Search')} className='filtertextboxbackground col-md-11 textbox-padding ml-2' style={{ outline: 'none' }} value={filterValueDict[filterCol]} onChange={(event) => filterInput(event)}></input>
                                                        </div>
                                                    </div>
                                                </>

                                                :
                                                <>
                                                </>

                                            }

                                            {filterCol === 'created_date' || filterCol === 'lastmodified_date' ?

                                                <>
                                                    <div className="container">
                                                        <div className="row">
                                                            <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>
                                                        </div>
                                                    </div>
                                                    <div className="container mt-2">
                                                        <div className="row">
                                                            <input type='date' className='filtertextboxbackground col-md-11 textbox-padding ml-2' value={filterValueDict[filterCol]} onChange={(event) => filterInput(event)}></input>
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                <></>
                                            }

                                            {filterCol === 'createdby' || filterCol === 'lastmodifiedby' || filterCol === 'status' ?

                                               
                                                <>
                                                    <div className="container">
                                                        <div className="row">
                                                            <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>

                                                        </div>

                                                    </div>

                                                    <div className="container mt-2">
                                                        <div className="row">
                                                            <input type="text" placeholder={t('Search')} value={filterSearch} onChange={(event) => UserfilterHandler(event)} className='filtertextboxbackground col-md-11 textbox-padding ml-2' />
                                                        </div>

                                                        <div className="row contentoverflow mt-2">
                                                            <table className="filtertable">
                                                                <tbody>
                                                                    <tr>
                                                                        <td><input type="checkbox" name="filter-option-all" id="option-all" onChange={checkAllFilter} /></td>
                                                                        <td>{t('SelectAll')}</td>
                                                                    </tr>
                                                                    {
                                                                        dropdownData.map((item, index) => (

                                                                            <tr key={index}>

                                                                                <td><input id={item.Id} data-index="1" name="filter-option-all" checked={item.ischecked} type="checkbox" onChange={(event) => OndropdownChange(event, item.Id, item.Name)} /></td>

                                                                                <td>{item.Name}</td>

                                                                            </tr>


                                                                        ))
                                                                    }

                                                                </tbody>
                                                            </table>

                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                <></>

                                            }
                                            
                                            <div style={{ paddingTop: '10px', paddingLeft: '47px', paddingBottom: '7px' }}>
                                                <button className="btn btn-sm btn-update" onClick={() => filterHandler('Apply')} style={{ width: "45px" }}>{t('Apply')}</button>
                                                <button className="btn btn-sm btn-update" style={{ marginLeft: '10px', width: "45px" }} onClick={() => filterHandler('Reset')}>{t('Reset')}</button>
                                            </div>
                                            <div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </div>

                        </div>
                    </div >

                </div >
            </div >
            <Modal show={ToggleStatusModal}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Body style={{ "textAlign": 'center', fontFamily: 'Segoe UI' }}>{`${t('WorkflowRules.AreYouSureYouWantTo')} ${toggleChange}` + "?"}</Modal.Body>
                <Modal.Footer>
                    <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "35px" }} size='sm' onClick={() => OnActivateOrDeactivate(toggleId, "Ok")}>
                        {t('Yes')}
                    </Button>
                    <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "50px" }} size='sm' onClick={() => OnActivateOrDeactivate(toggleId, "Cancel")}>
                        {t('Cancel')}
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showDeleteModal}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Body style={{ "textAlign": 'center' }}>Are you sure you want to delete ?</Modal.Body>
                <Modal.Footer>
                    <Button style={{ background: '#eff1f9', color: 'black', borderColor: 'black', width: "50px" }} size='sm' onClick={() => showDeleteDialog('close')}>
                        Cancel
                    </Button>
                    <Button style={{ background: '#eff1f9', color: 'black', borderColor: 'black', width: "50px" }} size='sm' onClick={() => showDeleteDialog('proceed')}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
            <CustomErrorPopup ErrorMessage={errorMessage} show={showErrorModal} ErrorStatus={errorStatus}
                onHide={() => setShowErrorModal(false)}></CustomErrorPopup>
        </div >

    )

}
export default WorkflowRules