import { Link, useLocation } from "react-router-dom"
import React, { useState, useEffect, useRef } from 'react'
import SessionManage from "../../SessionManagement/SessionManage";
import Service from "../../Services/Service";

import { useNavigate } from 'react-router-dom';
import { Form, FormGroup, Col, Row, Button, InputGroup, Collapse, Modal } from 'react-bootstrap';
import moment from 'moment';
import Spinner from 'react-bootstrap/Spinner';
import Multiselect from 'multiselect-react-dropdown';

import { CSVLink } from "react-csv";
import { useTranslation } from "react-i18next";

function AuditLogListComponent() {
    const [searchValue, setSearchValue] = useState(null);
    const location = useLocation();
    const csvInstance = useRef();
    const [csvList, setCsvList] = useState([]);
    const [sticky, setSticky] = useState("");
    const [t, i18n] = useTranslation('translation');
    const [sortColName, setSortColName] = useState("");
    const [userPerms, setuserPermission] = useState([]);
    const [data, setdata] = useState([]);
    const [showspinner, setshowspinner] = useState('');
    const [checkedValueid, setCheckedValueid] = useState([]);
    const [filteredArray, setFilteredArray] = useState(data)
    const [showLoadMore, setshowLoadMore] = useState('');
    const [showbutton, setshowbutton] = useState('');
    const [filterValue, setFilterValue] = useState('');
    const [filterCol, setFilterCol] = useState('');
    const [dropdownData, setDropdownData] = useState([]);
    const [showfilterpopup, setshowfilterpopup] = useState(false);
    const [columnAscending, setColumnAscending] = useState(true);
    const [totalselectedshow, settotalselectedshow] = useState('');
    const [totalselectedcount, settotalselectedcount] = useState(0);
    const [totalCountlst, settotalCountlst] = useState('');
    const [ToggleStatusModal, setToggleStatusModal] = useState(false);
    const [toggleChange, setToggleChange] = useState("");
    const [toggleId, setToggleId] = useState();
    const [toggleUserId, setToggleUserId] = useState();

    const [dropdownChange, setDropdownChange] = useState('');
    const [filterValueDict, setFilterValueDict] = useState({
        Action: '',
        Section: '',
        ActionDetails: '',
        email: '',
        roles: '',

        created_date: null,
        CreatedDate: null,
    });
    const [paging, setpaging] = useState({
        pageSize: 20,
        pageNumber: 1
    });
    const [multiFilterValue, setMultiFilterValue] = useState({
        createdby: '',
        CreatedUserName: '',
        status: '',
    });
    const getUserPermission = (pathname) => {
  
        Service.GetUserPermission(pathname).then((res) => {
            if (typeof res !== 'undefined') {
                if (res.data !== 'null' && typeof res.data !== 'undefined') {
                    if (res.data.DataList !== 'undefined') {
                        setuserPermission(res.data.DataList)
                    }
                }
            }
        });
    }
    const activeOption = [
        { Name: "Active", Id: "1" },
        { Name: "In-Active", Id: "0" },

    ]
    const checkAll = (mycheckbox) => {
        var checkboxes = document.querySelectorAll("input[type = 'checkbox']");
        if (mycheckbox.target.checked == true) {
            settotalselectedshow(true);
            checkboxes.forEach(function (checkbox) {
                checkbox.checked = true;
            });
            settotalselectedcount(checkboxes.length - 1)
        }
        else {
            settotalselectedshow(false);
            checkboxes.forEach(function (checkbox) {
                checkbox.checked = false;
            });
            settotalselectedcount(0)
        }

    }

    const LoadMore = (lst, totalcount) => {

        if (lst < totalcount) {
            setshowLoadMore(true);
        }
        else if (lst >= totalcount) {
            setshowLoadMore(false);
        } else {
            setshowLoadMore(false);
        }

    }
    const navigate = useNavigate();

    useEffect(() => {
        setColumnAscending(false);
        setSortColName("Id");
        setshowspinner(true);
        getUserPermission(location.pathname);
        getData("AuditLog/GetAll", paging.pageSize, paging.pageNumber, "Descending", "Id", [], null);
        window.addEventListener("scroll", isSticky);
        return () => {
            window.removeEventListener("scroll", isSticky);
        };

    }, [0]);
    const isSticky = () => {
        const scrollTop = window.scrollY;
        const stickyClass = scrollTop >= 60 ? "is-sticky-thead" : "";
        setSticky(stickyClass);
        const scrollTopActual = scrollTop >= 60 ? scrollTop - 85 : 0;
        var divtop1 = document.getElementById("tableA");
        setdivtop(divtop1.offsetTop + scrollTopActual)
    };
    const class_thead = `text-white rt-thead  ${sticky}`;

    const handleLoadMore = () => {
        let pagenumber = paging.pageNumber + 1;
        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
        Service.getDatawithMultiFilterSearch("AuditLog/GetAll", paging.pageSize, pagenumber, sortType, sortColName, filterLst(), searchValue).then((res) => {
            if (typeof res !== 'undefined') {
                if (res.data !== 'null' && typeof res.data !== 'undefined') {
                    const currentlength = data.length;
                    setdata(data => [...data, ...res.data.DataList])
                    setFilteredArray(filteredArray => [...filteredArray, ...res.data.DataList])
                    LoadMore(currentlength + res.data.DataList.length, res.data.Totalcount)
                }
            }
        });
 
        paging.pageNumber = pagenumber
        setpaging(paging);
    };

    let role = [];

    const getData = (method, pageSize, pageNumber, sortType, SortProperty, filterValue, search) => {
        Service.getDatawithMultiFilterSearch(method, pageSize, pageNumber, sortType, SortProperty, filterValue, search).then((res) => {
            if (typeof res !== 'undefined') {
                if (res.data !== 'null' && res.data !== 'undefined') {
                    setdata(res.data.DataList);
                    setFilteredArray(res.data.DataList)
                    settotalCountlst(res.data.Totalcount)
                    LoadMore(res.data.DataList.length, res.data.Totalcount)
                    setshowspinner(false)
                }
            }
        });

    }

   

    const handleChange = (e, id) => {
        let isChecked = e.target.checked;   // true if it is checked false if unchecked
      
        let checkedBoxes = document.querySelectorAll('input[name=chkBox]:checked');
        if (isChecked === true) {
            settotalselectedshow(true);
            settotalselectedcount(checkedBoxes.length);
          
            setCheckedValueid(id)
           
        }
        else {
          
            setCheckedValueid([])
            if (checkedBoxes.length === 0) {
                settotalselectedshow(false);
            }
            settotalselectedcount(checkedBoxes.length);
           
        }


        if (checkedBoxes.length == 1) {
            setshowbutton(true);
        }
        else {
            setshowbutton(false);
        }
    }
    const OndropdownChange = (e) => {
      
        let selectedOption = (e);
       
        setFilterValue([])
        let data = []
       
        for (let i = 0; i < selectedOption.length; i++) {
           
            const getallData = {
                Name: selectedOption[i].Name,
            }
            data.push(getallData)
        }
     
        setFilterValue(data);
        setMultiFilterValue(() => ({
            ...multiFilterValue, [filterCol]: data,
        }));
    }
    const customMultiStyles = {
        multiselectContainer: {
            maxWidth: '188px',
            marginLeft: '3px'
        },
        chips: {
            borderRadius: '5px'
        }
    };
    const OpenHandler = (id) => {
        if (id == null || id == 0) {
            alert('Please select the User to view')
        }
        else {
            navigate("/auditlog/details/" + id);
        }
    }

    const searchHandler = (event) => {
        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
      
        let pagenumber = 1;
       
        paging.pageNumber = pagenumber;
        setpaging(paging);
        if (event.target.value != '') {
           
            setSearchValue(String(event.target.value).toLowerCase())
            getData("AuditLog/GetAll", paging.pageSize, pagenumber, sortType, sortColName, filterLst(), event.target.value);
        }
        else {

            setSearchValue(null);
            getData("AuditLog/GetAll", paging.pageSize, pagenumber, sortType, sortColName, filterLst(), null);
        }
    }

    const ToggleUserActiveStatus = (id, user_id, flag) => {
        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
        setToggleId(id)
        setToggleUserId(user_id)
        if (flag == "Open-A") {
            setToggleStatusModal(true)
            setToggleChange("DeActivate")
        }
        if (flag == "Open-D") {
            setToggleStatusModal(true)
            setToggleChange("Activate")
        }
        if (flag == "Ok") {
            Service.ToggleUserActiveStatus('ToggleUserActiveStatus', id, user_id).then((res) => {
                if (typeof res !== 'undefined') {
                    if (typeof res.data !== 'undefined' && res.data !== 'null') {
                        if (res.status === 200) {
                            getData("AuditLog/GetAll", paging.pageSize, paging.pageNumber, sortType, sortColName, filterLst(), searchValue)
                        }
                        setToggleStatusModal(false)
                    }
                }

            });
        }
        if (flag == "Cancel") {
            setToggleStatusModal(false)
        }
    }

    const OpenUser = (event, id) => {
        if (event.detail === 2) {
          
            navigate("/auditlog/details/" + id);
        }
    }


    const filterInput = (e) => {
      
        let inputValue = e.target.value
        setFilterValue(inputValue)
    
        setFilterValueDict(() => ({
            ...filterValueDict, [filterCol]: e.target.value,
        }));
    }

    const [divleft, setdivleft] = useState('');
    const [divtop, setdivtop] = useState('');


    const FilterColumn = (name) => {

        const scrollTop = window.scrollY;
        const scrollTopActual = scrollTop >= 60 ? scrollTop - 85 : 0;
        var divtop1 = document.getElementById("tableA");
        setdivtop(divtop1.offsetTop + scrollTopActual + 30)
        setshowfilterpopup(true)
        setFilterCol(name)

        if (name === 'CreatedDate') {
            var testDiv = document.getElementById("CreatedDate");
            setdivleft(testDiv.offsetLeft - 100 + "px");
      
        }
        else if (name === 'CreatedUserName') {
            var testDiv = document.getElementById("CreatedUserName");
            setdivleft(testDiv.offsetLeft + 20 + "px");
            DropdownHandler("auditlog")
        } else {
            var testDiv = document.getElementById(name);
            setdivleft(testDiv.offsetLeft + 20 + "px");
        }
    }
    const DropdownHandler = (key) => {
        Service.getfilterDropdown('Permission/GetFilterValue', key).then((res) => {
            if (typeof res !== 'undefined') {

                if (res.data !== 'null' && typeof res.data !== 'undefined') {
                    setDropdownData(res.data)
                }
            }
        });
    }
    const filterHandler = (flag) => {

        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
        let pagenumber = 1;
        paging.pageNumber = pagenumber;

        if (flag === 'Apply') {
            setshowfilterpopup(false)
            let list = [];

            for (var key in multiFilterValue) {
                if (multiFilterValue[key].length > 0) {
                    let values = ""
                    multiFilterValue[key].map((i) => {
                        values += i.Name + ";";
                    })
                    if (values !== null && values !== '') {
                        list.push({
                            filterKey: key,
                            FilterValue: values
                        })
                    }
                }
            }

            for (var key in filterValueDict) {
                if (filterValueDict[key] !== null && filterValueDict[key] !== '') {
                    list.push({
                        filterKey: key,
                        FilterValue: filterValueDict[key]
                    })
                }
            }
            if (list.length > 0) {
                setpaging(paging);
                getData("AuditLog/GetAll", paging.pageSize, pagenumber, sortType, sortColName, list, searchValue);
            }
            
        }
        else {
            setshowfilterpopup(false)
            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: '',
            }));
            setFilterValueDict(() => ({
                ...filterValueDict, [filterCol]: '',
            }));

            let list = [];
            for (var key in multiFilterValue) {
                if (key !== filterCol && multiFilterValue[key].length > 0) {
                    let values = ""
                    multiFilterValue[key].map((i) => {
                        values += i.Name + ";";
                    })
                    if (values !== null && values !== '') {
                        list.push({
                            filterKey: key,
                            FilterValue: values
                        })
                    }
                }
            }

            for (var key in filterValueDict) {
                if (filterValueDict[key] !== null && filterValueDict[key] !== '' && key !== filterCol) {
                    list.push({
                        filterKey: key,
                        FilterValue: filterValueDict[key]
                    })
                }
            }
            setpaging(paging);
            getData("AuditLog/GetAll", paging.pageSize, pagenumber, sortType, sortColName, list, searchValue);

          
            setFilterCol('')
            setFilterValue('')
            setDropdownData([])
            setDropdownChange("")
        }
    }



    const SortColumn = (colName) => {
        var sortType = '';
        if (columnAscending === true) {
            setColumnAscending(false);
            sortType = "Descending";
        } else {
            setColumnAscending(true);
            sortType = "Ascending";
        }
        setSortColName(colName);
     
        let pagenumber = 1;

        paging.pageNumber = pagenumber;
        setpaging(paging);
        getData("AuditLog/GetAll", paging.pageSize, paging.pageNumber, sortType, colName, filterLst(), searchValue);
    }
    const filterLst = () => {
        let list = [];
        for (var key in multiFilterValue) {
            if (multiFilterValue[key].length > 0) {
                let values = ""
                multiFilterValue[key].map((i) => {
                    values += i.Name + ";";
                })
                if (values !== null && values !== '') {
                    list.push({
                        filterKey: key,
                        FilterValue: values
                    })
                }
            }
        }

        for (var key in filterValueDict) {
            if (filterValueDict[key] !== null && filterValueDict[key] !== '') {
                list.push({
                    filterKey: key,
                    FilterValue: filterValueDict[key]
                })
            }
        }
        return list;
    }
    let headers = [
        { label: "Action", key: "Action" },
        { label: "ActionDetails ", key: "ActionDetails" },
        { label: "Section", key: "Section" },
        { label: "DocumentId", key: "DocumentId" },
        { label: "Logtype", key: "Logtype" },
        { label: "Request", key: "Request" },
        { label: "Response", key: "Response" },
        { label: "CreatedUserName", key: "CreatedUserName" },
        { label: "CreatedDate", key: "CreatedDate" },

    ];
    const ExporttoCSV = () => {
        let checkedBoxes = document.querySelectorAll("input[name=chkBox]:checked");
        let values = "";
        if (checkedBoxes.length >= 1) {
            for (let checkbox of checkedBoxes) {
                if (values === "") {
                    values = checkbox.id;
                } else {
                    values = values + ";" + checkbox.id;
                }
            }
        }
        let objFilter = [];
        let objFilterValue = [
            {
                filterKey: "user_id",
                FilterValue: values,
            },
        ];
        if (totalCountlst != checkedBoxes.length) {
            objFilter = objFilterValue;
        }
        Service.getDatawithMultiFilterSearch("AuditLog/GetAll", 20000, 1, "Ascending", "Id", objFilter, null).then((res) => {
       
            if (typeof res !== "undefined") {
                if (res.data !== "null" && res.data !== "undefined") {
                    setCsvList(res.data.DataList);
                    setTimeout(() => {
                        csvInstance.current.link.click();
                    });
                }
            }
        });
    };

    return (<>
        <div className="container-fluid content-top-gap">

            <nav aria-label="breadcrumb">
                <ol className="breadcrumb my-breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">{t('Home.title')}</Link></li>
                    <li className="breadcrumb-item"><Link to="/admin">{t('Menu.Admin')}</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">{t('UserList.AuditLogList')}</li>
                    
                </ol>
            </nav>
            <div style={{ display: "none" }}>
                <CSVLink data={csvList} headers={headers} ref={csvInstance} filename="users.csv" > </CSVLink>
            </div>
            <div className="">
                <div className="row">
                    <div className="col-lg-12 pr-lg-2">
                        <div className="card text-center card_border">

                            <div className="card-body" style={{ minHeight: "70vh" }}>
                                <div className="row" style={{ position: "sticky" }}>

                                    <div className="col-md-8">
                                        <input type="text" className="form-control form-control-user" autoComplete='no' id="Action" name="Action" placeholder="Search for Action,Action Details, Error Details & Created By" onChange={(event) => searchHandler(event)} />
                                    </div>
                                    <div className="col-md-4">

                                        {totalselectedcount <= 0 ? "" : Service.exportPerms(userPerms) == false ? "" : <button className="btn btn-update  btn-sm ml-2" type="submit" style={{ float: "right", width: "50px" }} onClick={(event) => ExporttoCSV()}>Export</button>}

                                        {showbutton === true ?
                                            <>
                                                <button className="btn btn-update btn-sm ml-2" id="open" type="submit" style={{ float: "right", width: "40px" }} onClick={() => OpenHandler(checkedValueid)}>Open</button>
                                                
                                            </>
                                            :
                                            ''
                                        }
                                    </div>

                                </div>
                                <div className="scrollbar-class">
                                    <table className="table table-bordered text-left mt-2 table-responsive-sm" id="tableA">
                                        <thead className="text-white is-sticky-thead-product" style={{ backgroundColor: "var(--primary)" }}>
                                            <tr style={{ position: "sticky", top: "0", backgroundColor: "var(--primary)" }}>
                                                <th className="tableheader" style={{ width: '30px' }}>
                                                    <input type="checkbox" id="option-all" onChange={checkAll} />
                                                </th>

                                                <th id="Action" style={{ backgroundColor: (filterValueDict.Action ? '#6cae55' : '')}}>
                                                    <div className="d-flex text-nowrap">
                                                        <span>Action</span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('Action')}></span>
                                                        <span className='fa fa-filter ml-1 mt-2' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('Action')}></span>
                                                    </div>
                                                </th>
                                                <th id="ActionDetails" style={{ backgroundColor: (filterValueDict.ActionDetails ? '#6cae55' : ''), width: '250px' }}>
                                                    <div className="d-flex text-nowrap">
                                                        <span>Action Details</span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('ActionDetails')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('ActionDetails')}></span>
                                                    </div>
                                                </th>
                                                <th id="Section" style={{ backgroundColor: (filterValueDict.Section ? '#6cae55' : ''), width: '250px' }}>
                                                    <div className="d-flex text-nowrap">
                                                        <span>Section</span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('Section')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('Section')}></span>
                                                    </div>
                                                </th>
                                                <th id="DocumentId" style={{ backgroundColor: (filterValueDict.Section ? '#6cae55' : ''), width: '250px' }}>
                                                    <div className="d-flex text-nowrap">
                                                        <span>DocumentId</span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('DocumentId')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('DocumentId')}></span>
                                                    </div>
                                                </th>
                                                <th id="Logtype" style={{ backgroundColor: (filterValueDict.Section ? '#6cae55' : ''), width: '250px' }}>
                                                    <div className="d-flex text-nowrap">
                                                        <span>Logtype</span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('Logtype')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('Logtype')}></span>
                                                    </div>
                                                </th>
                                                <th id="Request" style={{ backgroundColor: (filterValueDict.Section ? '#6cae55' : ''), width: '250px' }}>
                                                    <div className="d-flex text-nowrap">
                                                        <span>Request</span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('Request')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('Request')}></span>
                                                    </div>
                                                </th>
                                                <th id="Response" style={{ backgroundColor: (filterValueDict.Section ? '#6cae55' : ''), width: '250px' }}>
                                                    <div className="d-flex text-nowrap">
                                                        <span>Response</span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('Response')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('Response')}></span>
                                                    </div>
                                                </th>
                                                <th id="CreatedUserName" style={{ backgroundColor: (multiFilterValue.CreatedUserName ? '#6cae55' : ''), width: '220px' }}>
                                                    <div className="d-flex text-nowrap">
                                                        <span>Created By</span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('CreatedUserName')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('CreatedUserName')}></span>
                                                    </div>
                                                </th>

                                                <th id="CreatedDate" style={{ backgroundColor: (filterValueDict.CreatedDate ? '#6cae55' : ''), maxWidth: '200px' }}>
                                                    <div className="d-flex text-nowrap">
                                                        <span>Created Date</span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('CreatedDate')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('CreatedDate')}></span>
                                                    </div>
                                                </th>


                                            </tr>
                                        </thead>

                                        <tbody>
                                            {
                                                showspinner === true ?

                                                    <>
                                                        <Spinner animation="grow" size="sm" variant="success" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                        <Spinner animation="grow" size="sm" variant="danger" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                        <Spinner animation="grow" size="sm" variant="warning" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                    </>
                                                    :
                                                    filteredArray.length === 0 ?
                                                        <tr><td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td>
                                                                No Data Available
                                                            </td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                        :

                                                        filteredArray.map((item, index) => (

                                                            <tr >

                                                                <td><input id={item.Id} data-index="1" name="chkBox" type="checkbox" onChange={(event) => handleChange(event, item.Id)} /></td>

                                                             
                                                                <td>{item.Action}</td>
                                                                <td>{item.ActionDetails}</td>
                                                                <td>{item.Section}</td>
                                                                <td>{item.DocumentId}</td>
                                                                <td>{item.Logtype}</td>
                                                                <td>{item.Request}</td>
                                                                <td>{item.Response}</td>
                                                                <td>{item.CreatedUserName}</td>
                                                                <td>
                                                                    {item.CreatedDate === null ? "" : item.CreatedDate}
                                                                </td>

                                                            </tr>


                                                        ))
                                            }

                                        </tbody>
                                    </table>
                                </div>

                                {
                                    showLoadMore === true ?
                                        <div className='text-center container row'>
                                            <div className='col-md-5'></div>
                                            <div className='col-md-2'>
                                                <button className='btn btn-sm btn-update ml-1' onClick={handleLoadMore} style={{ width: "70px" }}>Load More</button>
                                            </div>
                                            <div className='col-md-5'></div>

                                        </div>
                                        :
                                        ""
                                }
                                <div className='row'>

                                    {
                                        totalselectedshow === true ?

                                            <div className='col-lg-2 forgettext col-sm-12 border form-control' style={{ textAlign: "center", marginLeft: "0.938rem" }}>
                                                Total Records Selected - <span id="totalselected">{totalselectedcount}</span>
                                            </div>

                                            :
                                            <div className='col-lg-2  col-sm-12' style={{ textAlign: "center" }}>

                                            </div>
                                    }



                                    <div className='col-lg-7 col-sm-12'></div>


                                    <div className='col-lg-2 forgettext  col-sm-12 border form-control' style={{ textAlign: "center" }}>
                                        Total Records - {totalCountlst}
                                    </div>
                                </div>

                                {showfilterpopup === true ?
                                    <div className='pop-up' style={{ left: divleft, top: divtop }}>
                                    
                                        {filterCol === 'Action' || filterCol === 'Section' || filterCol === 'ActionDetails' || filterCol === 'DocumentId' || filterCol === 'LogType'|| filterCol == 'Request' || filterCol == 'Response' ?

                                            <div>
                                                <span className='fa fa-filter' /><input type='textbox' placeholder='Search...' className='col-md-10 ml-2' value={filterValueDict[filterCol]} onChange={(event) => filterInput(event)}></input>
                                            </div>
                                            :
                                            <></>

                                        }



                                        {filterCol === 'CreatedDate' ?

                                            <div>
                                                <span className='fa fa-filter' /><input type='date' className='col-md-10 ml-2' value={filterValueDict[filterCol]} onChange={(event) => filterInput(event)}></input>
                                            </div>

                                            :
                                            <></>
                                        }

                                        {filterCol === 'createdby' || filterCol === 'CreatedUserName' ?
                                            <>
                                                <div className="row">
                                                    <span className='col-md-1 fa fa-filter' />
                                                    <Multiselect
                                                        onSelect={(Event) => OndropdownChange(Event)}
                                                        onRemove={(Event) => OndropdownChange(Event)}
                                                        options={dropdownData}
                                                        displayValue="Name"
                                                       
                                                        showCheckbox={true}
                                                        selectedValues={multiFilterValue[filterCol]}
                                                        className='col-md-9 textbox-padding fontsizedropdown'
                                                        style={customMultiStyles}
                                                    />
                                                </div>
                                            </>
                                            :
                                            <></>
                                        }
                                        {filterCol === 'status' ?
                                            <>
                                                <div className="row">
                                                    <span className='col-md-1 fa fa-filter' />
                                                    <Multiselect
                                                        onSelect={(Event) => OndropdownChange(Event)}
                                                        onRemove={(Event) => OndropdownChange(Event)}
                                                        options={activeOption}
                                                        displayValue="Name"
                                                       
                                                        showCheckbox={true}
                                                        selectedValues={multiFilterValue[filterCol]}
                                                        className='col-md-9 textbox-padding fontsizedropdown'
                                                        style={customMultiStyles}
                                                    />
                                                </div>
                                            </>
                                            :
                                            <></>
                                        }

                                        <div style={{ paddingTop: '10px', paddingLeft: '47px', paddingBottom: '7px' }}>
                                            <button className="btn btn-sm btn-update" onClick={() => filterHandler('Apply')} style={{ width: "30px" }}>Ok</button>
                                            <button className="btn btn-sm btn-update" style={{ marginLeft: '10px', width: "50px" }} onClick={() => filterHandler('Reset')}>Remove</button>
                                        </div>
                                        <div>
                                        </div>
                                    </div>
                                    :
                                    <></>
                                }

                                <Modal show={ToggleStatusModal}
                                    size="sm"
                                    aria-labelledby="contained-modal-title-vcenter"
                                    centered>
                                    <Modal.Body style={{ "textAlign": 'center', fontFamily: 'Segoe UI' }}>Are you sure you want to {toggleChange}?</Modal.Body>
                                    <Modal.Footer>
                                        <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "35px" }} size='sm' onClick={() => ToggleUserActiveStatus(toggleId, toggleUserId, "Ok")}>
                                            Yes
                                        </Button>
                                        <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "50px" }} size='sm' onClick={() => ToggleUserActiveStatus(toggleId, toggleUserId, "Cancel")}>
                                            Cancel
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                            </div>

                        </div>
                    </div>

                </div>
            </div>



        </div>
    </>)
}
export default AuditLogListComponent