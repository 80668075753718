import React, { Component } from 'react' 
import { useNavigate ,useParams } from 'react-router-dom';
import ArchiveComponent from '../../../Components/PoliciesComponent/ExceptionPolicy/ApprovalsComponent/ArchiveComponent';

// import '../index.css';

function ExceptionPolicyArchive(props){
    let navigate = useNavigate();
    const params = useParams();

    return (  
        <div> 
            <ArchiveComponent {...props}  navigate={navigate} id={params.id} name={params.name} value={params.value}/>
        </div>
    );  
}
export default ExceptionPolicyArchive