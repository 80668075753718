import React, { useState, forwardRef, useEffect, useRef } from 'react';
import { Form, FormGroup, Col, Row, Button, InputGroup, Modal } from 'react-bootstrap';
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import DatePicker from "react-datepicker";
import classes from '../CreateClaimReconcilationComponent/Form.module.css';
import classes1 from '../CreateClaimReconcilationComponent/NewClaimReconcilation.css';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ProductCustomDialog from '../../../UI/ProductCustomDialog';
import CustomErrorPopup from '../../../UI/CustomErrorPopup';
import Popup from '../CreateClaimReconcilationComponent/Popup';
import { navigate, useLocation } from 'react-router-dom';
import { getCookie } from '../../../Helper/utils.js';
import $ from 'jquery';
import Service from '../../../Services/Service';
import { useNavigate } from 'react-router-dom';
import MultiSelect from 'react-multiple-select-dropdown-lite'
import 'react-multiple-select-dropdown-lite/dist/index.css'
import Multiselect from 'multiselect-react-dropdown';
import moment from 'moment';
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import Spinner from 'react-bootstrap/Spinner';

import Select from "react-select";
import SessionManage from '../../../SessionManagement/SessionManage';
import { CSVLink } from "react-csv";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function CreateClaimReconcilationComponent(props) {
  const [t, i18n] = useTranslation('translation');
  const csvInstance = useRef();
  const [csvList, setCsvList] = useState([]);
  const [isexport, setIsexport] = useState('')
  const location = useLocation();
  const [workflowchart, setworkflowchart] = useState([]);
  const [userPerms, setuserPermission] = useState([]);
  const [excludeheader, setexcludeheader] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [ClaimReconcilationId, setClaimReconcilationId] = useState('0')
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [commentsForSubmit, setCommentsForSubmit] = useState("")
  const [showSubmitModal, setShowSubmitModal] = useState(false)
  const [showApprovedModal, setShowApprovedModal] = useState(false)
  const [commentsForApproved, setCommentsForApproved] = useState("")
  const [showDeniedModal, setShowDeniedModal] = useState(false)
  const [commentsForDenied, setCommentsForDenied] = useState("")
  const [errorMessage, setErrorMessage] = useState("");
  const [errorStatus, setErrorStatus] = useState("Error");
  const [showErrorModal, setShowErrorModal] = useState(false);
  let rowPopupData = [];

  const [totalselectedshow, settotalselectedshow] = useState('');
  const [totalselectedcount, settotalselectedcount] = useState(0);
  const [totalCountlst, settotalCountlst] = useState('');
  const [transactionData, setTransactionData] = useState(rowPopupData)
  const [transactionStatus, setTransactionStatus] = useState("")
  let ClaimReconcilationItemsData = [];
  // let ClaimReconcilationData = [];
  const [ClaimReconcilationid, setClaimReconcilationid] = useState()
  const [itemsData, setItemsData] = useState([0])
  let existingTabs = [];
  var currentDate = new Date();
  var year = currentDate.getFullYear();
  var month = currentDate.getMonth();
  var day = currentDate.getDate();
  const [showExcludeModal, setShowExcludeModal] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date(year + 1, month, day));
  const navigate = useNavigate();
  const [rebateId, setrebateId] = useState();
  const [productTitle, setProductTitle] = useState("Product Picker");
  const [products, setProducts] = useState([]);
  const [productsList, setProductsList] = useState([]);
  const [show, setShow] = useState(false);
  const [selectedProductsName, setSelectedProductsName] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showRecalculated, setshowRecalculated] = useState(false);
  const [state, setState] = useState({ rebateType: 0, rebateMeasure: 0, product: "", startDate: new Date(), endDate: (new Date(year + 1, month, day)), createdDate: new Date().toISOString().slice(0, 10), createdBy: 0, rebateId: 0, ClaimReconcilationId: ClaimReconcilationId, rebatePayoutCond: 0, settlFreq: 0, rebateProgramLineId: 0, currency: '$', Customername: "", Rebate_Period: "", EnforcePaymentTerms: "", MinThresholdValue: "", MinThresholdPayout: "", CreatedUserId: 0, rebateHeaderId: 0, customerId: 0, customerID: "", rebateMesaureLabel: "", ClaimReconcilationType: "", comparisonPeriod: "", comparisonStartDate: new Date().toISOString().slice(0, 10), comparisonEndData: new Date().toISOString().slice(0, 10), deltaToReachTarget: 0, targetAbs: 0, rebateValue: "", CurrentTier: "", paymentTermsDays: "", TotalQuantity: 0, TotalRevenue: 0, TotalApprovedClaimReconcilation: 0, TotalUnpostedClaimReconcilation: 0, PaymentReceived: false, SubDivideTargetName: "", FiscalYearPeriod: "", CustomerCodeName: "", TotalClaimReconcilationRevenue: 0, TotalOutstandingAmount: 0, TotalExcludedAmount: 0, CustomerGroup: false, currencyPrefix: '' })
  let disable = false;
  let ClaimReconcilationTableData = [];
  const [addproductdata, setAddProductData] = useState(false)
  const [workflowData, setWorkflowData] = useState([0]);
  let wData = [0]
  const [loadedFrom, setLoadedFrom] = useState('')
  const [product_id, setProduct_Id] = useState([])
  const [accrlItems_id, setAccrlItems_Id] = useState([])
  const [Index, setIndex] = useState([])
  const [ClaimReconcilationStatus, setClaimReconcilationStatus] = useState('') //from ClaimReconcilation page 
  const [accStatus, setAccStatus] = useState('') // from approval page
  // const [, ] = useState('') // from ClaimReconcilation page
  const [errorPopup, setErrorPopup] = useState(false)
  const [submitErrorPopup, setSubmitErrorPopup] = useState(false)
  const [showspinner, setshowspinner] = useState('');
  const [rebateIcon, setRebateIcon] = useState('');
  const headerHeight = 50;
  const defaultColDef = {
    width: 130,
    editable: true
  };
  const [claimItemList, setclaimItemList] = useState([]);
  const [subdivTarget, setSubDivTarget] = useState([]);
  const [Validity, setValidity] = useState('');
  const [showClaimReconcilationWorkflow, setshowClaimReconcilationWorkflow] = useState('');
  const [sidebarwidth, setsidebarwidth] = useState('');
  const [iconClass, setIconClass] = useState('fas fa-angle-left')

  const [workflowApprovers, setWorkflowApprovers] = useState([])
  const [workflowLevelCount, setWorkflowLevelCount] = useState('');
  const [firstApprover, setFirstApprover] = useState('');
  const [secondApprover, setSecondApprover] = useState('');
  const [thirdApprover, setThirdApprover] = useState('');
  const [CurrentWorkflowLevel, setCurrentWorkflowLevel] = useState('');
  const [currentLevel1, setCurrentLevel1] = useState("{`${classes['square-levels']} `}");
  const [currentLevel2, setCurrentLevel2] = useState("{`${classes['square-levels']}  `}");
  const [currentLevel3, setCurrentLevel3] = useState("{`${classes['square-levels']} `}");
  const [salesLevelData, setSalesLevelData] = useState([]);
  const [excludetransactionid, setexcludetransactionid] = useState([]);
  const [initialexcludetransactionid, setinitialexcludetransactionid] = useState([]);
  const [rebateMeasureValue, setRebateMeasureValue] = useState('')
  const [columnAscending, setColumnAscending] = useState(true);
  const [headerdisable, setheaderdisable] = useState(false);
  const [rebateTypeValue, setRebateTypeValue] = useState('')
  const [filterCol, setFilterCol] = useState('');
  const [dropdownData, setDropdownData] = useState([]);
  const [showfilterpopup, setshowfilterpopup] = useState(false);
  const [isAnalyzeRebate, setIsAnalyzeRebate] = useState(false);
  const [checkTotal, setCheckTotal] = useState(false);
  const [dropdownChange, setDropdownChange] = useState([{ filterKey: "", FilterValue: "" }]);
  const [filterCol1, setFilterCol1] = useState('');
  const [dropdownData1, setDropdownData1] = useState([]);
  const [showfilterpopup1, setshowfilterpopup1] = useState(false);
  const [dropdownChange1, setDropdownChange1] = useState([{ filterKey: "", FilterValue: "" }]);
  const [isLocked, setIsLocked] = useState(false);
  const [lockedBy, setLockedBy] = useState('');
  const [measureValueLabel, setmeasureValueLabel] = useState();
  const [totalItemCountlst, settotalItemCountlst] = useState('');
  const [hfPayoutList, setHfPayoutList] = useState([])
  const [showRebateDefn, setRebateDefn] = useState(false)
  const [showRebateDefnIcon, setRebateDefnIcon] = useState("fa fa-plus")
  const [showTierIcon, setTierIcon] = useState("fa fa-plus")
  const [customerList, setCustomerList] = useState([])
  const [customerName, setCustomerName] = useState('')
  const [filterValue, setFilterValue] = useState([]);
  const [filterdropdownData, setFilterDropdownData] = useState([]);
  const [filterSearch, setFilterSearch] = useState("");
  const [filtertext, setfiltertext] = useState('');

  const [filterValuePopup, setFilterValuePopup] = useState([]);
  const [filterSearchPopup, setFilterSearchPopup] = useState("");
  const [filtertextPopup, setfiltertextPopup] = useState('');
  const [filterdropdownDataPopup, setFilterDropdownDataPopup] = useState([]);
  const [updateNav, setUpdateNav] = useState("/reconcilation")
  const [showWorkflowAlert, setShowWorkflowAlert] = useState(false)

  const [claimedQtySum, setClaimedQtySum] = useState(0);
  const [qtyInReviewSum, setQtyInReviewSum] = useState(0);
  const [revInReviewSum, setRevInReviewSum] = useState(0);
  const [claimedRevSum, setClaimedRevSum] = useState(0);
  const [totalReconciledSum, setTotalReconciledSum] = useState(0);
  const [reconciledInReviewSum, setReconciledInReviewSum] = useState(0);
  const [approvedAmountSum, setApprovedAmountSum] = useState(0);


  const [showvomumetierdeails, setshowvomumetierdeails] = useState(false);
  const [volumerierdata, setvolumerierdata] = useState([]);
  const [sidebarHeight, setsidebarHeight] = useState('');
  const [BgColor, setBgColor] = useState("")



  let loggedInUser = sessionStorage.getItem("id");
  const [loading, setLoading] = useState(false);
  const [operatorVal, setOperatorValue] = useState({
    Quantity: { value: "=", label: "equals" },
    ComparisonQuantity: { value: "=", label: "equals" },
    ComparisonNetprice: { value: "=", label: "equals" },
    Netprice: { value: "=", label: "equals" },
    ApprovedClaimReconcilation: { value: "=", label: "equals" },
    UnpostedClaimReconcilation: { value: "=", label: "equals" },
    WeightedAveragePerUnit: { value: "=", label: "equals" },
    TotalApprovedClaimReconcilation: { value: "=", label: "equals" },
    DeltaReachNextTier: { value: "=", label: "equals" },
    TotalRevenue: { value: "=", label: "equals" },
    ExcludedAmount: { value: "=", label: "equals" },
    ClaimReconcilationRevenue: { value: "=", label: "equals" },
  })
  const [filterValue1, setFilterValue1] = useState({
    Quantity: '',
    ComparisonQuantity: '',
    ComparisonNetprice: '',
    Netprice: '',
    ApprovedClaimReconcilation: '',
    UnpostedClaimReconcilation: '',
    WeightedAveragePerUnit: '',
    TotalApprovedClaimReconcilation: '',
    DeltaReachNextTier: '',
    CurrentTier: '',
    TotalRevenue: '',
    ExcludedAmount: '',
    ClaimReconcilationRevenue: '',
    Customer: '',
    ProductLabel: '',
    Category1: '',
  });
  const [operatorValOption, setOperatorValueOption] = useState([
    { value: "=", label: "equals" },
    { value: "<", label: "less then" },
    { value: ">", label: "greater then" },
    { value: "<=", label: "less or equal" },
    { value: ">=", label: "greater or equal" }
  ])


  const [popFilterValue, setPopFilterValue] = useState({
    Quantity: '',
    Netprice: '',
    WeightedAverage: '',
    LineItem: '',
    IsExclude: ''
  });
  const [popOperatorValue, setPopOperatorValue] = useState({
    Quantity: { value: "=", label: "equals" },
    Netprice: { value: "=", label: "equals" },
    WeightedAverage: { value: "=", label: "equals" },
    LineItem: { value: "=", label: "equals" },
  })

  const [filterValueDict, setFilterValueDict] = useState({
    InvoiceNumber: "",
    TransactionDate: "",
  });
  // const [filterValueDict1, setFilterValueDict1] = useState({
  //   ProductName: ''
  // });
  const [multiFilterValue, setMultiFilterValue] = useState({
    customer: '',
    modifiedby: '',
    createdby: '',
    IsExclude: ''
  });
  const [multiFilterValue1, setMultiFilterValue1] = useState({
    ProductName: '',
    Exclude: ''
  });

  const customStyles = {
    control: base => ({
      ...base,
      minHeight: 28
    }),
    dropdownIndicator: base => ({
      ...base,
      padding: 2
    }),
    clearIndicator: base => ({
      ...base,
      padding: 2
    }),
    multiValue: base => ({
      ...base,

    }),
    valueContainer: base => ({
      ...base,
      padding: '0px 2px'
    }),
    input: base => ({
      ...base,
      margin: 0,
      padding: 0
    })
  };



  useEffect(() => {
    if (window.location.href.includes('Analyze')) {
      setIsAnalyzeRebate(true);

    }

    getColumnVisibilityList()
    getColVisibilityListData()

    if (props.name.toLowerCase() == 'fromclaimreconcilation') {
      getUserPermission('/reconcilation');
    }
    else if (props.name.toLowerCase() == 'fromapproval' || props.name.toLowerCase() == 'fromapprovalcompleted') {
      getUserPermission('/reconcilation/pendingaction/0');
    }
    else if (props.name.toLowerCase() == 'fromclaim') {
      getUserPermission('/reconcilation');
    }
    else {
      getUserPermission(location.pathname);
    }
    setColumnAscending(false);
    setSortColName("Id");
    setshowspinner(true)


    sessionStorage.setItem('ids', props.id);

    if (props.id !== 0) {
      getClaimReconcilationDetails("ClaimReconcilation/GetByConcilationID", props.id);// we will pass ClaimReconcilation id

    }
    else {

      setWorkflowData([0])
      setLockedBy(loggedInUser);
      setIsLocked(true);
    }
    if (props.name !== '')
      setLoadedFrom(props.name)
  }, [props.id])

  const getUserPermission = (pathname) => {
    Service.GetUserPermission(pathname).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res !== undefined) {
            if (res.data !== 'null' && res.data !== undefined && res.data !== '') {
              if (res.data.DataList !== undefined) {
                setuserPermission(res.data.DataList)
              }
            }
          }
        }
      }

    });
  }

  const ExporttoCSV = () => {


    let checkedBoxes = document.querySelectorAll('input[name=chkBox]:checked');
    let values = "";
    if (checkedBoxes.length >= 1) {
      for (let checkbox of checkedBoxes) {
        values = values + ';' + checkbox.id;

      }
    }
    let objFilter = [];
    let objFilterValue = [{
      filterKey: "ids",
      FilterValue: values,
    }]

    objFilter = objFilterValue;


    Service.getClaimReconcilationItemsSortBy("ClaimReconcilation/ReconcilationItemsSortBy", props.id, "Ascending1", "Id", objFilter).then((res) => {

      if (typeof res !== 'undefined') {
        if (res.data !== 'null' && res.data !== 'undefined') {
          var data = res.data.DataList;

          data[data.length - 1].Exclude = "Total ";

          setCsvList(data);
          setTimeout(() => {
            csvInstance.current.link.click();
          });

        }
      }
    });
  }
  let headers = [];
  if (accStatus === 'New' || accStatus === 'InReview' || accStatus === 'Denied') {
    headers = [
      { label: "Customer", key: "Customer" },
      { label: "Material No", key: "ProductName" },
      { label: "Item Name ", key: "ProductLabel" },
      { label: "Category1", key: "Category1" },
      { label: "Exclude ", key: "Exclude" },
      { label: "Quantity ", key: "Quantity" },
      { label: "Revenue ", key: "TotalRevenue" },
      { label: "Excluded Revenue ", key: "ExcludedAmount" },
      { label: "ClaimReconcilation Revenue ", key: "ClaimReconcilationRevenue" },
      { label: "Total Accrued", key: "TotalApprovedClaimReconcilation" },
      { label: "ClaimReconcilation In Review", key: "UnpostedClaimReconcilations" },
    ];
  }
  else {
    headers = [
      { label: "Customer", key: "Customer" },
      { label: "Material No", key: "ProductName" },
      { label: "Item Name ", key: "ProductLabel" },
      { label: "Category1", key: "Category1" },
      { label: "Exclude ", key: "Exclude" },
      { label: "Quantity ", key: "Quantity" },
      { label: "Revenue ", key: "TotalRevenue" },
      { label: "Excluded Revenue ", key: "ExcludedAmount" },
      { label: "ClaimReconcilation Revenue ", key: "ClaimReconcilationRevenue" },
      { label: "Total Accrued", key: "TotalApprovedClaimReconcilation" },
      { label: "Current Accrued", key: "ApprovedClaimReconcilation" },
    ];
  }
  const getClaimReconcilationHeaderData = (method, id) => {
    Service.getdatabyId(method, id).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {

            if (res.data !== 'null' && res.data !== 'undefined') {

              setState({ rebateId: res.data.RebateProgramLineId, rebateTypeID: res.data.RebateTypeName, customerID: res.data.Customername, startDate: moment(res.data.ValidityFrom).format("YYYY-MM-DD"), endDate: moment(res.data.ValidityTo).format("YYYY-MM-DD"), createdDate: res.data.createdDate, createdBy: res.data.CreatedUserName, ClaimReconcilationId: res.data.ClaimReconcilationId, region: res.data.SalesLevel, rebateHeaderId: res.data.RebateId, createdUserId: res.data.CreatedUserId, rebateProgramLineId: res.data.Id })

            }
          }
        }
      }

    });
  }



  const getClaimReconcilationWorkflowDetails = () => {
    Service.getdatabyId('ClaimReconcilation/GetReconcilationWorkflowById', state.Id).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {

            if (res.data !== 'null' && res.data !== 'undefined') {
              wData = res.data.DataList;

              setWorkflowData(wData);
            }
          }
        }
      }

    });

  }


  const togglePopup = () => {
    setexcludetransactionid([])
    setIsOpen(true);
  }


  const fetchEligibleInvoice = (ClaimReconcilationId, rebateId) => {
    Service.getdatabyId('fetchEligibleInvoices', ClaimReconcilationId).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {
            if (res.data !== 'null' && res.data !== 'undefined') {
              ClaimReconcilationItemsData = res.data['data'];
              setItemsData(ClaimReconcilationItemsData)
              setClaimReconcilationId(res.data['ClaimReconcilation_id']);
            }
          }
        }
      }

    });

  }





  if (state.status === 'Draft') {
    disable = false;
  }
  else {
    disable = true;
  }

  const getProductsList = () => {

    setShow(true);


    let ProductTableData = [];
    Service.getdatabyId('ClaimReconcilation/GetClaimReconcilationProductsById', props.id).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {
            if (res.data !== 'null' && res.data !== 'undefined') {

              setProducts(res.data)
            }
          }
        }
      }

    });


  }

  let list = [];
  list = products.map((item) => {

    if (!productsList.includes(item)) {
      productsList.push(item);
    }
  });

  const [indexeligibleinvoice, setindexeligibleinvoice] = useState('');

  const GetTransactionData = (params, Id, Customer, Index, sortType, SortProperty, filterVal) => {

    setindexeligibleinvoice(Index);


    let customername = state.DistributorName
    let productname = params

    setAccrlItems_Id(Id)
    setProduct_Id(params)
    setCustomerName(customername)

    let param = { customername, productname }


    Service.getConcilationdatabyData('ClaimReconcilation/GetConcilationTransactionData', customername, productname, state.Id, 20, 1, sortType, SortProperty, filterVal).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {
            if (res.data !== 'null' && res.data !== 'undefined') {
              rowPopupData = res.data.DataList
              setTransactionData(res.data.DataList)

              settotalCountlst(res.data.DataList.length)

              var test = rowPopupData.find(x => x.IsExclude === false);
              if (test !== undefined) {

                setexcludeheader(false)

              } else {

                setexcludeheader(true)

              }

              var approv = rowPopupData.find(x => x.Status === "New")
              if (approv === undefined) {
                setheaderdisable(true)
              } else {
                setheaderdisable(false)
              }

              if (rowPopupData !== undefined) {
                var excludeid = [];
                rowPopupData.forEach((item) => {

                  if (item.IsExclude === true) {

                    setexcludetransactionid(excludetransactionid => [...excludetransactionid, item.TransactionId]);
                    var previousarray = [...initialexcludetransactionid];

                    var index1 = previousarray.indexOf(item.TransactionId)
                    if (index1 === -1) {
                      setinitialexcludetransactionid(initialexcludetransactionid => [...initialexcludetransactionid, item.TransactionId]);


                    }
                    excludeid.push(item.TransactionId)

                  }
                });
                if (excludeid.length !== 0) {
                  settotalselectedcount(excludeid.length);
                  settotalselectedshow(true);
                } else {
                  settotalselectedcount(0);
                  settotalselectedshow(false);
                }



              }


            }
          }
        }
      }

    });

    togglePopup();
  }




  let rdata = [{}]


  const GetEligibleInvoices = () => {

    setLoading(true);
    setshowRecalculated(false);

    let rebateProgramLineId = state.rebateProgramLineId
    let rebateId = state.rebateHeaderId
    let createdUserId = SessionManage.getuserid();//state.createdUserId
    let createdFromRebate = false;

    const excludedId = [...new Set(excludetransactionid)];
    let selectedCustomers = [{
      'CustomerId': state.customerId,
      'CustomerName': state.Customername
    }]
    let ClaimReconcilationPeriod = state.Rebate_Period;

    let data = { rebateId, rebateProgramLineId, selectedProducts, createdUserId, excludedId, createdFromRebate, selectedCustomers, ClaimReconcilationPeriod }

    Service.createorupdate('ClaimReconcilation/GenerateEligibleInvoice', data).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {

          if (res.status === 200) {

            if (res.data !== 'null' && res.data !== 'undefined') {
              if (res.data.status !== "Failure") {
                setLoadedFrom('fromClaimReconcilation')
                setLoading(false);

                let id_ClaimReconcilation = res.data.ClaimReconcilationId
                setClaimReconcilationStatus(res.data.ClaimReconcilationStatus)
                getClaimReconcilationDetails("ClaimReconcilation/GetByConcilationID", res.data.ClaimReconcilationId);

                setClaimReconcilationid(res.data.ClaimReconcilationId)



                settotalItemCountlst()
                navigate("/reconcilation/new/" + res.data.ClaimReconcilationId + "/fromClaimReconcilation");
              }
              else {
                setLoading(false);
                setErrorPopup(true)
                setErrorStatus("Error")
                setErrorMessage(res.data.Message)
              }
            }


          }
          else {
            setLoading(false);
            setErrorStatus("Error")
            setErrorMessage(t('ClaimReconcilation.FailedToCreateClaimReconcilations'))
            setShowErrorModal(true)
          }
        } else {
          setLoading(false);
          setErrorStatus("Error")
          setErrorMessage(t('ClaimReconcilation.FailedToCreateClaimReconcilations'))
          setShowErrorModal(true)
        }

      } else {
        setLoading(false);
        setErrorStatus("Error")
        setErrorMessage(t('ClaimReconcilation.FailedToCreateClaimReconcilations'))
        setShowErrorModal(true)
      }


    });

  }


  const CloseHandler = (flag) => {
    if (flag === 'openclose') {
      if (ClaimReconcilationStatus == 'New')
        setShowDeleteModal(!showDeleteModal)
    }
    if (flag === 'openclose') {
      if (ClaimReconcilationStatus == '')
        setShowDeleteModal(!showDeleteModal)
    }
    if (flag === 'proceed') {
      if (ClaimReconcilationid > '0') {

        deleteUnsavedRecords()
        setShowDeleteModal(!showDeleteModal)
      }
      else {

        setShowDeleteModal(!showDeleteModal)
        navigate("/reconcilation")
      }

    }
    Service.LockUnLockEntity('Configurationprop/UnLockEntity', "ClaimReconcilation", state.Id, loggedInUser).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {

        }
      }

    });
  }


  const navigateHandler = () => {
    Service.LockUnLockEntity('Configurationprop/UnLockEntity', "ClaimReconcilation", state.Id, loggedInUser).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {

        }
      }

    });
    if (loadedFrom == 'fromApproval') {
      navigate("/reconcilation/pendingaction/0")
      Service.LockUnLockEntity('Configurationprop/UnLockEntity', "ClaimReconcilation", state.Id, loggedInUser).then((res) => {
        if (typeof res !== 'undefined') {
          if (typeof res.data !== 'undefined' && res.data !== 'null') {

          }
        }

      });
    }
    else {
      navigate("/reconcilation/archive/0")
      Service.LockUnLockEntity('Configurationprop/UnLockEntity', "ClaimReconcilation", state.Id, loggedInUser).then((res) => {
        if (typeof res !== 'undefined') {
          if (typeof res.data !== 'undefined' && res.data !== 'null') {

          }
        }

      });
    }
  }

  const deleteUnsavedRecords = () => {

    Service.delete('ClaimReconcilation/DeleteReconcilation', ClaimReconcilationid).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {

            if (res.data !== 'null' && res.data !== 'undefined') {
              navigate("/reconcilation")
            }
          }
        }
      }

    });

  }
  const SubmitClaimReconcilations = () => {

    let Id = state.Id;
    let Comments = commentsForSubmit

    let UserId = SessionManage.getuserid();//state.createdUserId

    let data = { Id, Comments, UserId }

    Service.createorupdate('ClaimReconcilation/SubmitReconcilation', data).then((res) => {

      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {

          if (res.status === 200) {
            if (res.data.Status != 'Failure') {

              if (res.data !== 'null' && res.data !== 'undefined') {
                toast.success(t('ClaimReconcilation.ClaimReconcilationSubmittedsuccessfully'), {
                  position: toast.POSITION.TOP_RIGHT,
                });
                Service.LockUnLockEntity('Configurationprop/UnLockEntity', "ClaimReconcilation", state.Id, loggedInUser).then((res) => {
                  if (typeof res !== 'undefined') {
                    if (typeof res.data !== 'undefined' && res.data !== 'null') {

                    }
                  }

                });
                setTimeout(() => {
                  navigate('/reconcilation')
                }, 1000)
                setClaimReconcilationStatus('Submitted')
                getClaimReconcilationWorkflowDetails()

              }


            } else {
              setShowSubmitModal(!showSubmitModal)
              setErrorStatus("Error")
              setErrorMessage(res.data.ErrorMessage)
              setShowErrorModal(true)
              setCommentsForSubmit('')
            }

          }
        }
      }

      else if (res.status !== 200) {
        setErrorPopup(true)
      }
    });

  }



  const ApproveClaimReconcilations = () => {

    let Id = state.Id
    let Comments = commentsForApproved
    let UserId = SessionManage.getuserid();//state.createdUserId
    let data = { Id, Comments, UserId }

    Service.createorupdate('ClaimReconcilation/ApproveReconcilation', data).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {

            if (res.data !== 'null' && res.data !== 'undefined') {
              setLoading(false);
              toast.success(t('ClaimReconcilation.ClaimReconcilationapprovedsuccessfully'), {
                position: toast.POSITION.TOP_RIGHT,
              });
              Service.LockUnLockEntity('Configurationprop/UnLockEntity', "ClaimReconcilation", props.id, loggedInUser).then((res) => {
                if (typeof res !== 'undefined') {
                  if (typeof res.data !== 'undefined' && res.data !== 'null') {

                  }
                }

              });
              setTimeout(() => {
                navigate("/reconcilation/pendingaction/0")
              }, 1000)
            }

          }
          else if (res.status !== 200) {
            setErrorPopup(true)
          }
        }
      }

    });
  }

  const DenyClaimReconcilations = () => {

    let Id = state.Id
    let Comments = commentsForDenied
    let UserId = SessionManage.getuserid();
    let data = { Id, Comments, UserId }

    Service.createorupdate('ClaimReconcilation/DenyReconcilation', data).then((res) => {

      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {

            if (res.data !== 'null' && res.data !== 'undefined') {
              setLoading(false);
              toast.success(t('ClaimReconcilation.ClaimReconcilationdeniedsuccessfully'), {
                position: toast.POSITION.TOP_RIGHT,
              });

              Service.LockUnLockEntity('Configurationprop/UnLockEntity', "ClaimReconcilation", ClaimReconcilationId, loggedInUser).then((res) => {
                if (typeof res !== 'undefined') {
                  if (typeof res.data !== 'undefined' && res.data !== 'null') {

                  }
                }

              });
              setTimeout(() => {
                navigate("/reconcilation/pendingaction/0")
              }, 1000)
            }

          }
          else if (res.status !== 200) {
            setErrorPopup(true)
          }
        }
      }

    });

  }

  const columnList = [
    { "label": "ClaimReconcilation.MaterialNo", "key": "ProductName", "value": true },
    { "label": "ClaimReconcilation.MaterialDesc", "key": "ProductLabel", "value": true },
    { "label": "ClaimReconcilation.Quantity", "key": "Quantity", "value": true },
    { "label": "ClaimReconcilation.Revenue", "key": "Revenue", "value": true },
    { "label": "ClaimReconcilation.ClaimedQty", "key": "ClaimedQty", "value": true },
    { "label": "ClaimReconcilation.ClaimQty", "key": "ClaimQty", "value": true },
    { "label": "ClaimReconcilation.ClaimedRevenue", "key": "ClaimedRevenue", "value": true },
    { "label": "ClaimReconcilation.ClaimRevenue", "key": "ClaimRevenue", "value": true },
    { "label": "ClaimReconcilation.ReconcilationStatus", "key": "ReconcilationStatus", "value": true },
    { "label": "ClaimReconcilation.TotalReconciled", "key": "TotalReconciled", "value": true },
    { "label": "ClaimReconcilation.ApprovedAmount", "key": "ApprovedAmount", "value": true },
  ]
  const [addRemoveCol, setAddRemoveCol] = useState(columnList);
  const [createColumnListOption, setCreateColumnListOption] = useState(columnList)
  const [showAddColumnPopup, setShowAddColumnPopup] = useState(false)
  const [showCreateColumnListPopup, setShowCreateColumnListPopup] = useState(false)
  const [hideColumn, setHideColumn] = useState([]);
  const [resetColumnKey, setResetColumnKey] = useState([]);
  const [colVisibilityOption, setColVisibilityOption] = useState([]);
  const [colvisiOptionVal, setColvisiOptionVal] = useState({ value: null, label: 'Default' })
  const [existingViewVal, setExistingViewVal] = useState(false);
  const [defaultView, setDefaultView] = useState(false);
  const [colViewName, setColViewName] = useState('');
  const [recorddId, setRecordId] = useState(0);

  const OnChangedefaultView = (event,) => {
    setDefaultView(event.target.checked)
  }
  const OnChangeexistingViewVal = (event,) => {
    setExistingViewVal(event.target.checked)
  }

  const OnChangeSetViewName = (event,) => {
    setColViewName(event.target.value)
  }

  const OnChangeColVisibilityList = (data) => {
    setColvisiOptionVal({ value: data.value, label: data.label });
    getColVisibilityListData(data.value)
  }
  const CreateColumnListPopup = () => {
    setShowCreateColumnListPopup(true)
    var divtop1 = document.getElementById("addRemoveCol");
    setdivtop1(divtop1.offsetTop + 280)
    setdivleft1(divtop1.offsetLeft - 200 + "px");
  }

  const CloseCreateColumnListPopup = () => {
    setShowCreateColumnListPopup(false)
    setColViewName('')
    setDefaultView(false)
  }

  const AddRemoveColumn = () => {
    setShowAddColumnPopup(true)
    var divtop1 = document.getElementById("addRemoveCol");
    setdivtop(divtop1.offsetTop + 295)
    setdivleft(divtop1.offsetLeft - 200 + "px");
    getColumnVisibilityList()
  }
  const OnchangeAddRemoveCol = (event, column) => {
    let isChecked = event.target.checked;
    let lst = [...addRemoveCol];
    for (let d in lst) {
      if (lst[d].key == column.key && isChecked) {
        lst[d]['value'] = true
      }
      else if (lst[d].key == column.key && !isChecked) {
        lst[d]['value'] = false
      }
    }
    setResetColumnKey([...resetColumnKey, column.key])
    setAddRemoveCol(lst)
  }
  const OnchangeCreateColumnListOption = (event, column) => {
    let isChecked = event.target.checked;
    let lst = [...createColumnListOption];
    for (let d in lst) {
      if (lst[d].key == column.key && isChecked) {
        lst[d]['value'] = true
      }
      else if (lst[d].key == column.key && !isChecked) {
        lst[d]['value'] = false
      }
    }
    setCreateColumnListOption(lst)
  }

  const updateColumnVisibilityList = (tag) => {
    let lst = [...addRemoveCol];
    if (tag == 'reset') {
      for (let d in lst) {
        lst[d]['value'] = true
      }
      setHideColumn([])
      setAddRemoveCol(lst)
    }
    else {
      let data = [];
      for (let d in lst) {
        if (!lst[d].value) {
          data.push(lst[d].key)
        }
      }
      setHideColumn(data)
    }
    let viewName = colvisiOptionVal.value;
    Service.setColumnVisibilityListData('ColumnVisibility/CreateOrUpdateColumnList', recorddId, loggedInUser, 'ClaimReconcilation', viewName, existingViewVal, lst).then((res) => { })
    setShowAddColumnPopup(false)
    setResetColumnKey([])
  }

  const CreateColumnVisibilityList = (tag) => {
    let veiwName = colViewName;
    let idDefault = defaultView;
    if (veiwName.trim() == 'undefined' || veiwName.trim() == '' || veiwName.trim() == null) {
      setErrorStatus("Error")
      setErrorMessage("Please set View Name")
      setShowErrorModal(true)
    }
    else {
      let lst = [...createColumnListOption];
      if (tag == 'reset') {
        for (let d in lst) {
          lst[d]['value'] = true
        }
        setHideColumn([])
        setAddRemoveCol(lst)
      }
      else {
        let data = [];
        for (let d in lst) {
          if (!lst[d].value) {
            data.push(lst[d].key)
          }
        }
        setHideColumn(data)
      }
      Service.setColumnVisibilityListData('ColumnVisibility/CreateOrUpdateColumnList', 0, loggedInUser, 'ClaimReconcilation', colViewName, defaultView, lst).then((res) => {
        getColumnVisibilityList()
        setColvisiOptionVal({ value: colViewName, label: colViewName });
        getColVisibilityListData(colViewName)
        CloseCreateColumnListPopup()
        setShowAddColumnPopup(false)
      })
    }
  }

  const closeAddRemoveColumnPopup = () => {
    let lst = [...addRemoveCol];
    let resetKey = [...resetColumnKey];
    for (let rk in resetKey) {
      for (let d in lst) {
        if (lst[d].key == resetKey[rk]) {
          lst[d]['value'] = !lst[d].value
        }
      }
    }
    setAddRemoveCol(lst)
    setShowAddColumnPopup(false)
    setResetColumnKey([])

  }

  const getColumnVisibilityList = () => {
    Service.getColumnVisibilityListData('ColumnVisibility/GetColumnList', loggedInUser, 'ClaimReconcilation', null).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {

            if (typeof res.data !== 'undefined' && res.data !== 'null') {
              let data = []
              for (let d in res.data) {
                data.push({ value: res.data[d].label, label: res.data[d].label })

              }

              setColVisibilityOption(data)
            }
          }
        }
      }
    })
  }


  const getColVisibilityListData = (viewName = null) => {
    if (!viewName) {
      viewName = colvisiOptionVal.value
    }
    if (viewName == 'Deleted') {
      viewName = null
    }
    Service.getColumnVisibilityListData('ColumnVisibility/GetColumnListJsonData', loggedInUser, 'ClaimReconcilation', viewName).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {
            if (typeof res.data.jsonData !== 'undefined' && res.data.jsonData !== 'null') {

              let jsonData = JSON.parse(res.data.jsonData)
              let data = [];
              for (let d in jsonData) {
                if (!jsonData[d].value) {
                  data.push(jsonData[d].key)
                }
              }
              setColvisiOptionVal({ value: res.data.label, label: res.data.label });
              setHideColumn(data)
              setAddRemoveCol(jsonData)
              setExistingViewVal(res.data.defaultView)
              setResetColumnKey([])
              setRecordId(res.data.id)
            }
          }
        }
      }
    })
  }
  const deleteColumnVisibilityView = () => {
    Service.deleteDataByPolicyID("ColumnVisibility/Delete", recorddId, loggedInUser).then(res => {
      if (res.status === 200) {
        getColumnVisibilityList()
        getColVisibilityListData('Deleted')
        setShowAddColumnPopup(false)
        alert('Deleted Successfully')
      }
    })
  }
  const OndropdownChangeExcludeItems = (e) => {

    let selectedOption = (e);

    let data = []

    for (let i = 0; i < selectedOption.length; i++) {

      const getallData = {
        Name: selectedOption[i].Name,
      }
      data.push(getallData)
    }

    setMultiFilterValue1(() => ({
      ...multiFilterValue1, [filterCol1]: data,
    }));

  }
  const showSubmitDialog = (flag) => {

    if (flag === 'showSubmitDialog') {
      setCommentsForSubmit("")
      setShowSubmitModal(!showSubmitModal)
    }
    else if (flag === 'proceed') {
      if (showRecalculated === false) {
        setshowRecalculated(false)
        SubmitClaimReconcilations()
        setShowSubmitModal(!showSubmitModal)
      }
      else {
        alert(t('ClaimReconcilation.Validations.PleaseYouCanRecalculateAgainExcludeTransactionNotRecacluated'))
      }

    }

  }


  const OndropdownChangeIsExcludeItems = (e) => {

    let selectedOption = (e);

    let data = []

    for (let i = 0; i < selectedOption.length; i++) {

      const getallData = {
        Name: selectedOption[i].Name,
      }
      data.push(getallData)
    }

    setMultiFilterValue(() => ({
      ...multiFilterValue, [filterCol]: data,
    }));

  }

  const workflowAlert = (flag) => {
    if (flag == 'open') {
      if (workflowApprovers.length > 0) {
        setShowWorkflowAlert(true)
      }
      else {
        zeroClaimReconcilation()
      }
    }
    if (flag == 'close') {
      setShowWorkflowAlert(false)
      zeroClaimReconcilation()
    }
  }

  const zeroClaimReconcilation = () => {
    setSubmitErrorPopup(false)
    showSubmitDialog('showSubmitDialog')
  }

  const validateSubmit = () => {

    var count = false;

    if ((state.ClaimReconcilationAggregate) > 0 || state.ClaimReconcilationAggregate < 0) {
      if (submitErrorPopup == false) {
        if (itemsData.length > 0) {
          itemsData.map(i => {
            if (i.UnpostedClaimReconcilation === 0) {
              count = true;
            }
          })
          if (count == true) {
            setSubmitErrorPopup(true)
          }
          else {
            showSubmitDialog('showSubmitDialog')
          }
        }
      }
    }

    else {
      setErrorStatus("Error")
      setErrorMessage(t('ClaimReconcilation.Validations.ClaimReconcilationCannotBeSubmittedPaymentNotReceivedForOneOrMoreInvoice'))
      setShowErrorModal(true);
    }

  }


  const showApprovedDialog = (flag) => {
    if (flag === 'showApprovedDialog') {
      setCommentsForApproved("")
      setShowApprovedModal(!showApprovedModal)
    }
    else if (flag === 'proceed') {
      setLoading(true);
      ApproveClaimReconcilations()
      setShowApprovedModal(!showApprovedModal)

    }
  }


  const showDeniedDialog = (flag) => {
    if (flag === 'showDeniedDialog') {
      setCommentsForDenied("")
      setShowDeniedModal(!showDeniedModal)
    }
    else if (flag === 'proceed') {
      setLoading(true);
      DenyClaimReconcilations()
      setShowDeniedModal(!showDeniedModal)

    }
  }

  const [selectedProductsId, setSelectedProductsId] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const handleChange = (selectedList, selectedItem) => {

    const selected = [];
    const selectedId = [];
    const selectedData = [];
    let selectedOption = (selectedList);

    for (let i = 0; i < selectedOption.length; i++) {
      selected.push(selectedOption[i].ProductName);
      selectedId.push(selectedOption[i].ProductId);
      const getallData = {
        ProductId: selectedOption[i].ProductId,
        ProductName: selectedOption[i].ProductName
      }
      selectedData.push(getallData);
    }

    selectedProducts.length = 0;
    for (let i = 0; i < selectedData.length; i++) {
      const getall = {
        ProductId: selectedData[i].ProductId,
        ProductName: selectedData[i].ProductName
      }
      selectedProducts.push(getall)
    }



  }

  const getClaimReconcilationDetails = (method, id) => {

    Service.getdatabyId(method, id).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {
            if (res.data !== 'null' && res.data !== 'undefined') {
              if (res.data.status !== "Failure") {

                setTimeout(() => {

                  if (window.location.href.includes('fromClaimReconcilation') && res.data.DataList.Locked === true && res.data.DataList.LockedBy.toString() !== loggedInUser) {
                    $("#libread").html(res.data.DataList.ClaimReconcilationId + " " + `Locked By ${res.data.DataList.LockedByUsername}`);

                  }
                  if (window.location.href.includes('fromClaimReconcilation') && res.data.DataList.Locked === true && res.data.DataList.LockedBy.toString() == loggedInUser) {
                    $("#libread").html(res.data.DataList.ClaimReconcilationId);

                  }
                  if (window.location.href.includes('fromClaim') && res.data.DataList.Locked === true && res.data.DataList.LockedBy.toString() !== loggedInUser) {
                    $("#libread").html(res.data.DataList.ClaimReconcilationId + " " + `Locked By ${res.data.DataList.LockedByUsername}`);

                  }
                  if (window.location.href.includes('fromClaim') && res.data.DataList.Locked === true && res.data.DataList.LockedBy.toString() == loggedInUser) {
                    $("#libread").html(res.data.DataList.ClaimReconcilationId)

                  }
                  if (window.location.href.includes('fromApproval') && res.data.DataList.Locked === true && res.data.DataList.LockedBy.toString() !== loggedInUser) {
                    $("#libread").html(res.data.DataList.ClaimReconcilationId + " " + `Locked By ${res.data.DataList.LockedByUsername}`);

                  }
                  if (window.location.href.includes('fromApproval') && res.data.DataList.Locked === true && res.data.DataList.LockedBy.toString() == loggedInUser) {
                    $("#libread").html(res.data.DataList.ClaimReconcilationId)

                  }
                }, 100)
                if (props.name != '' && props.name != undefined) {
                  if (props.name.toLocaleLowerCase() == 'fromapproval') {
                    $("#headertxt").html('Claim Reconciliation Approval- Pending Action');
                    setUpdateNav("/reconcilation/pendingaction/0")
                  }
                  else if (props.name.toLocaleLowerCase() == 'fromapprovalcompleted') {
                    setUpdateNav("/reconcilation/archive/0")
                    $("#headertxt").html('Claim Reconciliation Approval- Workflow Completed');
                  }
                }
                else {
                  $("#headertxt").html(t('ClaimReconcilation.ClaimReconcilationList'));
                  setUpdateNav("/reconcilation")
                }


                var month_num = 0;


                if (window.location.href.includes('ClaimReconcilationsposting') && res.data.DataList.Locked === true && res.data.DataList.LockedBy.toString() !== loggedInUser) {
                  $("#libread").html(res.data.DataList.ClaimReconcilationId + " " + `Locked By ${res.data.DataList.LockedByUsername}`);
                }
                if (window.location.href.includes('ClaimReconcilationsposting') && res.data.DataList.Locked === true && res.data.DataList.LockedBy.toString() == loggedInUser) {
                  $("#libread").html(res.data.DataList.ClaimReconcilationId);
                }


                setshowspinner(false)
                setState({
                  Id: res.data.DataList.Id,
                  SDClaimId: res.data.DataList.SDClaimId,
                  SDAgreementId: res.data.DataList.AgreementId,
                  AgreementType: res.data.DataList.AgreementType,
                  ClaimApprovedDate: moment(res.data.DataList.ClaimApprovedDate).format(SessionManage.getcurrentdateformat()),
                  ClaimApprovedBy: res.data.DataList.ClaimApprovedBy,
                  ClaimSubmittedDate: moment(res.data.DataList.ClaimSubmittedDate).format(SessionManage.getcurrentdateformat()),
                  ClaimSubmittedBy: res.data.DataList.ClaimSubmittedBy,
                  createdUserId: res.data.DataList.CreatedUserId, ClaimReconcilationId: res.data.DataList.Id, region: res.data.DataList.SalesLevel,
                  DistributorName: res.data.DataList.DistributorName,
                  Region: res.data.DataList.Region,
                  TotalQuantity: res.data.DataList.TotalQuantity,
                  TotalRevenue: res.data.DataList.TotalRevenue,
                  TotalApprovedAmount: res.data.DataList.TotalApprovedAmount,
                  ConcilationAggregate: res.data.DataList.ConcilationAggregate,
                  ClaimReconcilationId: res.data.DataList.ClaimReconcilationId,
                  currencyPrefix: res.data.DataList.CurrencyPrefix,
                  OverallStatus: res.data.DataList.OverallStatus,
                  ErrorDetails: res.data.DataList.ErrorDetails,
                })
                setItemsData(res.data.DataList.lstConcilationItemVIew);
                setClaimedQtySum(res.data.DataList.lstConcilationItemVIew.reduce((acc, item) => acc + item.ClaimedQty, 0));
                setQtyInReviewSum(res.data.DataList.lstConcilationItemVIew.reduce((acc, item) => acc + item.ClaimQty, 0));
                setClaimedRevSum(res.data.DataList.lstConcilationItemVIew.reduce((acc, item) => acc + item.ClaimedRevenue, 0));
                setRevInReviewSum(res.data.DataList.lstConcilationItemVIew.reduce((acc, item) => acc + item.ClaimRevenue, 0));
                setTotalReconciledSum(res.data.DataList.lstConcilationItemVIew.reduce((acc, item) => acc + item.TotalApprovedAmount, 0));
                setReconciledInReviewSum(res.data.DataList.lstConcilationItemVIew.reduce((acc, item) => acc + item.UnpostedAmount, 0));
                setApprovedAmountSum(res.data.DataList.lstConcilationItemVIew.reduce((acc, item) => acc + item.ApprovedAmount, 0));
                setAccStatus(res.data.DataList.StatusName)
                setclaimItemList(res.data.DataList.claimItem)
                setvolumerierdata(res.data.DataList.VolumeTierDetails)
                let dt = moment(res.data.DataList.StartDate).add(month_num, 'month').format(SessionManage.getcurrentdateformat()) + " to " + moment(res.data.DataList.EndDate).add(month_num, 'month').format(SessionManage.getcurrentdateformat());

                setValidity(dt)

                if (res.data.DataList.StatusName == 'New') {
                  insertWorkflowDetails(res.data.DataList.Id, loggedInUser)
                }
              }
            } else {
              setErrorStatus("Error")
              setErrorMessage(res.data.Message)
              setShowErrorModal(true)
            }
            if (((props.name == 'fromApproval' || props.name == 'fromapprovalcompleted') && res.data.DataList.StatusName == 'InReview' && res.data.DataList.Locked == false) || (res.data.DataList.StatusName == 'New' && res.data.DataList.Locked == false)) {
              Service.LockUnLockEntity('Configurationprop/LockEntity', "ClaimReconcilation", id, loggedInUser).then((res) => {
                if (typeof res !== 'undefined') {
                  if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    setLockedBy(loggedInUser);
                    setIsLocked(true);
                  }
                }
              });
            }
            else {
              setLockedBy(res.data.DataList.LockedBy);
              setIsLocked(res.data.DataList.Locked);
            }
          }
        }
      }
    });
  }

  const [filteredArray, setFilteredArray] = useState()
  const [searchValue, setSearchValue] = useState('')


  const handleSearch = (event) => {
    if (event.target.value != '') {
      setSearchValue(String(event.target.value).toLowerCase())
    }
    else {
      setFilteredArray()
    }

  }

  const insertWorkflowDetails = (id, userId,) => {
    Service.workflowDataConcile('ClaimReconcilation/Insertworkflow', id, userId).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {

          if (res.status == 200) {
            if (res.data !== 'null' && res.data !== 'undefined') {

            }
          }
        }
      }
    })
  }

  const searchHandler = () => {

    let i = []
    let searchResults = i.filter((each) => {

      return each.rebate_id.toLowerCase().includes(searchValue) || each.customer.toLowerCase().includes(searchValue)
    })
    setFilteredArray(searchResults)

  }
  const OnChangeIcon = (e) => {
    if (iconClass == 'fas fa-angle-left') {
      setIconClass('fas fa-angle-down');
    }
    else if (iconClass == 'fas fa-angle-down') {
      setIconClass('fas fa-angle-left');
    }

  }
  const CancelHandler = () => {
    Service.LockUnLockEntity('Configurationprop/UnLockEntity', "ClaimReconcilation", state.Id, loggedInUser).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {

        }
      }

    });

    navigate("/reconcilation")
  }

  const clickShowClaimReconcilationWorkflow = (val) => {

    getClaimReconcilationWorkflowDetails();
    getClaimReconcilationWorkflowApprovers('ClaimReconcilation/GetReconcilationWorkflowApprovers', state.Id)
    setshowClaimReconcilationWorkflow(true);
    setsidebarwidth("60vw");

  }

  const clickCloseClaimReconcilationWorkflow = () => {

    setshowClaimReconcilationWorkflow(false);
    setsidebarwidth("0vw");
  }

  const getClaimReconcilationWorkflowApprovers = (method, id) => {

    Service.getdatabyId(method, id).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {

          if (res.status === 200) {
            if (res.data !== 'null' && res.data !== 'undefined') {

              setWorkflowApprovers(res.data)
              setWorkflowLevelCount(res.data.length)

              setworkflowchart(res.data);
            }

          }
        }
      }

    });
  }
  const activeOption = [
    { Name: "Yes", Id: "1" },
    { Name: "No", Id: "0" },

  ]
  const handlevalue = (e, id, index) => {

    let isChecked = e.target.checked;
    let tra = [...transactionData];
    tra[index].IsExclude = !tra[index].IsExclude
    setTransactionData(tra);
    var test = tra.find(x => x.IsExclude === false);

    if (test !== undefined) {

      setexcludeheader(false)
    } else {
      setexcludeheader(true)
    }
    let checkedBoxes = document.querySelectorAll('input[name=chkBox]:checked');

    if (isChecked === true) {
      settotalselectedshow(true);
      settotalselectedcount(checkedBoxes.length);
      document.getElementById(id).checked = true;
      var array = [...initialexcludetransactionid];
      var available = array.indexOf(id);
      if (available === -1) {
        setinitialexcludetransactionid(initialexcludetransactionid => [...initialexcludetransactionid, id])

      }
    }
    else {
      if (checkedBoxes.length === 0) {
        settotalselectedshow(false);
      }
      settotalselectedcount(checkedBoxes.length);
      document.getElementById(id).checked = false;
      var array = [...initialexcludetransactionid]; // make a separate copy of the array
      var index = array.indexOf(id)
      if (index !== -1) {
        array.splice(index, 1);
        setinitialexcludetransactionid(array);
      }
    }

  }

  const apply = () => {
    setexcludetransactionid(initialexcludetransactionid)


    setIsOpen(!isOpen);
    setshowRecalculated(true);
    setshowfilterpopup(false)
    setFilterValueDict(() => ({
      ...filterValueDict, [filterCol]: '',
    }));
  }
  const cancel = () => {

    setAccrlItems_Id([])
    setProduct_Id([])
    setIndex([])
    setIsOpen(!isOpen);
    setshowfilterpopup(false)
    setFilterValueDict(() => ({
      ...filterValueDict, [filterCol]: '',
    }));
  }

  const ClaimReconcilationItemsSortBy = (method, Id, sortType, SortProperty, Value) => {

    Service.getClaimReconcilationItemsSortBy(method, Id, sortType, SortProperty, Value).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status !== undefined) {

            if (res.data.DataList !== 'null' && res.data.DataList !== 'undefined') {
              setItemsData(res.data.DataList)

            }
          }
        }
      }

    });
  }



  const [sortColName, setSortColName] = useState("");
  const SortColumn = (colName) => {
    var sortType = '';
    if (columnAscending === true) {
      setColumnAscending(false);
      sortType = "Descending";
    } else {
      setColumnAscending(true);
      sortType = "Ascending";
    }
    setSortColName(colName);
    ClaimReconcilationItemsSortBy("ClaimReconcilation/ClaimReconcilationItemsSortBy", ClaimReconcilationid, sortType, colName, filterEligibleInvoiceLst());
  }


  const SortColumnItems = (colName) => {
    setColumnAscending(!columnAscending);
    var sortType = columnAscending === true ? 'Ascending' : 'Descending';

    GetTransactionData(product_id, accrlItems_id, customerName, Index, sortType, colName, []);
  }

  const filterHandler = (flag) => {

    if (flag === 'Apply') {
      setshowfilterpopup(false)
      let list = [];

      for (var key in multiFilterValue) {
        if (multiFilterValue[key].length > 0) {
          let values = ""
          multiFilterValue[key].map((i) => {
            values += i.Name + ";";
          })
          if (values !== null && values !== '') {
            list.push({
              filterKey: key,
              FilterValue: values
            })
          }
        }
      }

      for (var key in filterValueDict) {
        if (filterValueDict[key] !== null && filterValueDict[key] !== '') {
          list.push({
            filterKey: key,
            FilterValue: filterValueDict[key]
          })
        }
      }

      for (var key in popFilterValue) {
        if (popFilterValue[key] !== null && popFilterValue[key] !== '') {
          list.push({
            filterKey: key,
            FilterValue: popFilterValue[key],
            operatorValue: popOperatorValue[key].value
          })
        }
      }
      GetTransactionData(product_id, accrlItems_id, customerName, Index, "Ascending", "Id", list)

    }
    else {
      setshowfilterpopup(false)

      setMultiFilterValue(() => ({
        ...multiFilterValue, [filterCol]: '',
      }));
      setFilterValueDict(() => ({
        ...filterValueDict, [filterCol]: '',
      }));

      let list = [];
      for (var key in multiFilterValue) {
        if (key !== filterCol && multiFilterValue[key].length > 0) {
          let values = ""
          multiFilterValue[key].map((i) => {
            values += i.Name + ";";
          })
          if (values !== null && values !== '') {
            list.push({
              filterKey: key,
              FilterValue: values
            })
          }
        }
      }

      for (var key in filterValueDict) {
        if (filterValueDict[key] !== null && filterValueDict[key] !== '' && key !== filterCol) {
          list.push({
            filterKey: key,
            FilterValue: filterValueDict[key]
          })
        }
      }

      for (var key in popFilterValue) {
        if (popFilterValue[key] !== null && popFilterValue[key] !== '' && key !== filterCol) {
          list.push({
            filterKey: key,
            FilterValue: popFilterValue[key],
            operatorValue: popOperatorValue[key].value
          })
        }
      }

      GetTransactionData(product_id, accrlItems_id, customerName, Index, "Ascending", "Id", list)
      setFilterCol('')
      setDropdownData([])
      setDropdownChange("")
      setPopFilterValue(() => ({
        ...popFilterValue, [filterCol]: '',
      }));
      setPopOperatorValue(() => ({
        ...popOperatorValue, [filterCol]: { value: "=", label: "equals" },
      }));
    }
  }
  const filterEligibleInvoiceLst = () => {


    let list = [];

    for (var key in multiFilterValue1) {
      if (multiFilterValue1[key].length > 0) {
        let values = ""
        multiFilterValue1[key].map((i) => {
          values += key === 'Exclude' ? i.Name + ";" : i.ProductName + ";";
        })

        if (values !== null && values !== '') {
          list.push({
            filterKey: key,
            FilterValue: values
          })
        }
      }
    }

    for (var key in filterValue1) {
      if (filterValue1[key] !== null && filterValue1[key] !== '') {
        list.push({
          filterKey: key,
          FilterValue: filterValue1[key],
          operatorValue: key === 'CurrentTier' ? null : operatorVal[key].value
        })
      }
    }
    return list;
  }



  const filterHandlerItems = (flag) => {
    var sortType = columnAscending === true ? 'Ascending' : 'Descending';

    if (flag === 'Apply') {
      setshowfilterpopup1(false)
      let list = [];

      for (var key in multiFilterValue1) {
        if (multiFilterValue1[key].length > 0) {
          let values = ""
          multiFilterValue1[key].map((i) => {
            values += key === 'Exclude' ? i.Name + ";" : i.ProductName + ";";
          })

          if (values !== null && values !== '') {
            list.push({
              filterKey: key,
              FilterValue: values
            })
          }
        }
      }

      for (var key in filterValue1) {
        if (filterValue1[key] !== null && filterValue1[key] !== '') {
          list.push({
            filterKey: key,
            FilterValue: filterValue1[key],
            operatorValue: operatorVal[key].value
          })

        }
      }
      setFilterValue([])
      ClaimReconcilationItemsSortBy("ClaimReconcilation/ClaimReconcilationItemsSortBy", ClaimReconcilationid, sortType, sortColName, list)
    }
    else {
      setshowfilterpopup1(false)

      setMultiFilterValue1(() => ({
        ...multiFilterValue1, [filterCol1]: '',
      }));

      let list = [];
      for (var key in multiFilterValue1) {
        if (key !== filterCol1 && multiFilterValue1[key].length > 0) {
          let values = ""
          multiFilterValue1[key].map((i) => {
            values += key === 'Exclude' ? i.Name + ";" : i.ProductName + ";";
          })
          if (values !== null && values !== '') {
            list.push({
              filterKey: key,
              FilterValue: values
            })
          }
        }
      }
      for (var key in filterValue1) {
        if (filterValue1[key] !== null && filterValue1[key] !== '' && key !== filterCol1) {
          list.push({
            filterKey: key,
            FilterValue: filterValue1[key],
            operatorValue: key === 'CurrentTier' ? null : key === 'Customer' ? null : key === 'Customer' ? null : operatorVal[key].value
          })
        }
      }
      ClaimReconcilationItemsSortBy("ClaimReconcilation/ClaimReconcilationItemsSortBy", ClaimReconcilationid, sortType, sortColName, list)
      setFilterCol1('')
      setDropdownData1([])
      setDropdownChange1("")
      setFilterSearch("")
      setFilterValue([])
      setFilterDropdownData([])
      setFilterValue1(() => ({
        ...filterValue1, [filterCol1]: '',
      }));
      setOperatorValue(() => ({
        ...operatorVal, [filterCol1]: { value: "=", label: "equals" },
      }));
    }
  }



  const OnChangeOperatorValue = (data) => {
    setOperatorValue(() => ({
      ...operatorVal, [filterCol1]: { value: data.value, label: data.label },
    }));
  }


  const OnChangePopOperatorValue = (data) => {
    setPopOperatorValue(() => ({
      ...popOperatorValue, [filterCol]: { value: data.value, label: data.label },
    }));
  }

  const filterInput1 = (e) => {
    setFilterValue1(() => ({
      ...filterValue1, [filterCol1]: e.target.value,
    }));
  }

  const numericFilterInput = (e) => {
    setPopFilterValue(() => ({
      ...popFilterValue, [filterCol]: e.target.value,
    }));
  }


  const filterInput = (e) => {

    let inputValue = e.target.value
    setFilterValueDict(() => ({
      ...filterValueDict, [filterCol]: e.target.value,
    }));
  }



  const OndropdownChangeItems = (e, id, name, key) => {

    let isChecked = e.target.checked;
    var lastfilter = [...dropdownData1]
    let data1 = []
    if (isChecked === true) {
      let currentdata = lastfilter.map((i) => {
        if (i.Id == id) {
          i["ischecked"] = true;
        }
        return i;
      });

      setDropdownData1(currentdata);

      if (key == 'Product') {
        const getallData = { ProductName: name }
        data1.push(getallData)
      }
      if (key == 'Exclude') {
        const getallData = { Name: name }
        data1.push(getallData)
      }
      setFilterValue(filterValue => [...filterValue, ...data1]);
      setFilterValue(data1);
      let updateddata = ([...filterValue, ...data1]);
      setMultiFilterValue1(() => ({
        ...multiFilterValue1, [filterCol1]: updateddata,
      }));
    }
    else {
      let currentdata = lastfilter.map((i) => {
        if (i.Id === id) {
          i["ischecked"] = false;
        }
        return i;
      });

      setDropdownData1(currentdata);
      if (key == 'product') {
        setFilterValue(filterValue => filterValue.filter(data => data.ProductName !== name))
        let values = filterValue.filter(data => data.Name !== name);
        setMultiFilterValue1(() => ({
          ...multiFilterValue1, [filterCol1]: values,
        }));
      }
      else {
        setFilterValue(filterValue => filterValue.filter(data => data.Name !== name))
        let values = filterValue.filter(data => data.Name !== name);
        setMultiFilterValue1(() => ({
          ...multiFilterValue1, [filterCol1]: values,
        }));
      }
      data1 = []
    }
  }

  const OndropdownChange = (e, id, name, key) => {

    let isChecked = e.target.checked;
    var lastfilter = [...dropdownData]
    let data1 = []
    if (isChecked === true) {
      let currentdata = lastfilter.map((i) => {
        if (i.Id == id) {
          i["ischecked"] = true;
        }
        return i;
      });

      setDropdownData(currentdata);

      const getallData = { Name: name }
      data1.push(getallData)
      setFilterValuePopup(filterValuePopup => [...filterValuePopup, ...data1]);
      setFilterValuePopup(data1);
      let updateddata = ([...filterValuePopup, ...data1]);
      setMultiFilterValue(() => ({
        ...multiFilterValue, [filterCol]: updateddata,
      }));
    }
    else {
      let currentdata = lastfilter.map((i) => {
        if (i.Id === id) {
          i["ischecked"] = false;
        }
        return i;
      });

      setDropdownData(currentdata);
      setFilterValuePopup(filterValuePopup => filterValuePopup.filter(data => data.Name !== name))
      let values = filterValuePopup.filter(data => data.Name !== name);
      setMultiFilterValue(() => ({
        ...multiFilterValue, [filterCol]: values,
      }));
    }
  }




  const UserfilterHandler = (e) => {

    setFilterSearch(e.target.value)
    if (e.target.value !== "") {
      let searchResults = dropdownData1.filter((each) => {
        return each.Name.toLowerCase().includes(String(e.target.value).toLowerCase())
      });
      setDropdownData1(searchResults);
    }
    else {

      setFilterSearch("")
      setDropdownData1(filterdropdownData)
    }
  }
  const UserfilterHandlerPopup = (e) => {

    setFilterSearchPopup(e.target.value)
    if (e.target.value !== "") {
      let searchResults = dropdownData.filter((each) => {
        return each.Name.toLowerCase().includes(String(e.target.value).toLowerCase())
      });
      setDropdownData(searchResults);
    }
    else {

      setDropdownData(filterdropdownDataPopup)
      setFilterSearchPopup("")
    }
  }

  const checkAllFilter = (mycheckbox) => {
    var lastfilter = [...dropdownData1]
    let data1 = []
    var checkboxes = document.querySelectorAll("input[name = 'filter-option-all']");
    if (mycheckbox.target.checked == true) {

      let currentdata = lastfilter.map((i) => {
        i["ischecked"] = true;
        return i;
      });
      setDropdownData1(currentdata)

      dropdownData1.map((each) => {

        if (filtertext == 'Material No') {
          const getallData = {
            ProductName: each.ProductName,
          }
          data1.push(getallData)
        }
        else {
          const getallData = {
            Name: each.Name,
          }
          data1.push(getallData)
        }
      })
      setFilterValue(filterValue => [...filterValue, ...data1]);
      let updateddata = ([...filterValue, ...data1]);
      setMultiFilterValue1(() => ({
        ...multiFilterValue1, [filterCol1]: updateddata,
      }));
    }
    else {

      let currentdata = lastfilter.map((i) => {
        i["ischecked"] = false;
        return i;
      });
      setFilterValue([])
      setMultiFilterValue1([])
    }

  }

  const checkAllFilterPopup = (mycheckbox) => {
    var lastfilter = [...dropdownData]
    let data1 = []
    var checkboxes = document.querySelectorAll("input[name = 'filter-option-all']");
    if (mycheckbox.target.checked == true) {

      let currentdata = lastfilter.map((i) => {
        i["ischecked"] = true;
        return i;
      });
      setDropdownData(currentdata)

      dropdownData.map((each) => {

        const getallData = {
          Name: each.Name,
        }
        data1.push(getallData)
      })
      setFilterValuePopup(filterValuePopup => [...filterValuePopup, ...data1]);
      let updateddata = ([...filterValuePopup, ...data1]);
      setMultiFilterValue(() => ({
        ...multiFilterValue, [filterCol]: updateddata,
      }));
    }
    else {

      let currentdata = lastfilter.map((i) => {
        i["ischecked"] = false;
        return i;
      });
      setFilterValuePopup([])
      setMultiFilterValue([])
    }

  }


  const addnewfield = (data, key) => {


    let currentdata = data.map((i) => {
      i["ischecked"] = false;
      return i;
    });
    let selectedKeys = multiFilterValue1[key];
    if (key != 'Exclude') {
      if (selectedKeys != '') {
        currentdata.forEach((each) => {
          selectedKeys.forEach((eachSelected) => {
            if (each.ProductName == eachSelected.ProductName) {
              each.ischecked = true
            }
          })
        })
      }

      setDropdownData1(currentdata);
      setFilterDropdownData(currentdata)
    }
    else {
      if (selectedKeys != '') {
        currentdata.forEach((each) => {
          selectedKeys.forEach((eachSelected) => {
            if (each.Name == eachSelected.Name) {
              each.ischecked = true
            }
          })
        })
        setDropdownData1(currentdata)
        setFilterDropdownData(currentdata)
      }
      else {
        setDropdownData1(currentdata)
        setFilterDropdownData(currentdata)
      }
    }
  }
  const addnewfieldPopup = (data, key) => {

    let currentdata = data.map((i) => {
      i["ischecked"] = false;
      return i;
    });
    let selectedKeys = multiFilterValue[key];
    if (selectedKeys != '') {
      currentdata.forEach((each) => {
        selectedKeys.forEach((eachSelected) => {
          if (each.Name == eachSelected.Name) {
            each.ischecked = true
          }
        })
      })
      setDropdownData(currentdata)
      setFilterDropdownDataPopup(currentdata)
    }
    else {
      setDropdownData(currentdata)
      setFilterDropdownDataPopup(currentdata)
    }
  }



  const [divleft, setdivleft] = useState('');
  const [divtop, setdivtop] = useState('');

  const FilterColumn = (name) => {
    var divtop1 = document.getElementById("tableA");

    setdivtop(divtop1.offsetTop + 110)

    setshowfilterpopup(true)
    setFilterCol(name)
    if (name === 'InvoiceNumber') {
      setfiltertextPopup(t('ClaimReconcilation.Invoice'))
      var testDiv = document.getElementById("InvoiceNumber");
      setdivleft(testDiv.offsetLeft + 200 + "px");
    }
    if (name === 'TransactionDate') {
      setfiltertextPopup(t('ClaimReconcilation.InvoiceDate'))
      var testDiv = document.getElementById("TransactionDate");
      setdivleft(testDiv.offsetLeft + 180 + "px");
    }
    if (name === 'Quantity') {
      setfiltertextPopup(t('ClaimReconcilation.Qty'))
      var testDiv = document.getElementById("Quantity");
      setdivleft(testDiv.offsetLeft + 100 + "px");
    }
    if (name === 'Netprice') {
      setfiltertextPopup(t('ClaimReconcilation.InvoicePrice/Unit'))
      var testDiv = document.getElementById("Netprice");
      setdivleft(testDiv.offsetLeft + 120 + "px");
    }
    if (name === 'WeightedAverage') {
      setfiltertextPopup(t('ClaimReconcilation.WeightedAvg'))
      var testDiv = document.getElementById("WeightedAverage");
      setdivleft(testDiv.offsetLeft + 180 + "px");
    }
    if (name === 'LineItem') {
      setfiltertextPopup(t('ClaimReconcilation.Line'))
      var testDiv = document.getElementById("LineItem");
      setdivleft(testDiv.offsetLeft + 120 + "px");
    }
    if (name === 'Status') {
      setfiltertextPopup(t('ClaimReconcilation.Message'))
      var testDiv = document.getElementById("Status");
      setdivleft(testDiv.offsetLeft + 150 + "px");
    }
    if (name === 'IsExclude') {
      setfiltertextPopup(t('ClaimReconcilation.Exclude'))
      var testDiv = document.getElementById("IsExclude");
      setdivleft(testDiv.offsetLeft + 180 + "px");
      addnewfieldPopup(activeOption, name)
    }
    if (name === 'Revenue') {
      setfiltertextPopup(t('ClaimReconcilation.Revenue'))
      var testDiv = document.getElementById("Revenue");
      setdivleft(testDiv.offsetLeft + 100 + "px");
    }
    if (name === 'Region') {
      setfiltertextPopup(t('ClaimReconcilation.Region'))
      var testDiv = document.getElementById("Region");
      setdivleft(testDiv.offsetLeft + 180 + "px");
    }
    setshowfilterpopup(true)
  }

  const [divleft1, setdivleft1] = useState('');
  const [divtop1, setdivtop1] = useState('');

  const FilterColumnItems = (name) => {

    var divtop1 = document.getElementById("tableB");
    var cardtop1 = document.getElementById("card-RPL");

    setdivtop1(divtop1.offsetTop + cardtop1.offsetTop + 30)
    setshowfilterpopup1(true)

    setFilterCol1(name)


    if (name === 'ProductName') {
      var testDiv = document.getElementById("ProductName");
      setfiltertext(t('ClaimReconcilation.MaterialNo'))
      setdivleft1((testDiv.offsetLeft + cardtop1.offsetLeft + 30) + "px");
      DropdownHandler(props.id, name)
    }
    if (name === 'Quantity') {
      setfiltertext(t('ClaimReconcilation.Quantity'))
      var testDiv = document.getElementById("Quantity");
      setdivleft1((testDiv.offsetLeft + cardtop1.offsetLeft + 30) + "px");
    }
    if (name === 'ComparisonQuantity') {
      setfiltertext("Comparison Quantity")
      var testDiv = document.getElementById("ComparisonQuantity");
      setdivleft1((testDiv.offsetLeft + cardtop1.offsetLeft + 30) + "px");
    }
    if (name === 'ComparisonNetprice') {
      setfiltertext("Comparison Netprice")
      var testDiv = document.getElementById("ComparisonNetprice");
      setdivleft1((testDiv.offsetLeft + cardtop1.offsetLeft + 30) + "px");
    }
    if (name === 'ClaimReconcilationRevenue') {
      setfiltertext(t('ClaimReconcilation.ClaimReconcilationRevenue'))
      var testDiv = document.getElementById("ClaimReconcilationRevenue");
      setdivleft1((testDiv.offsetLeft + cardtop1.offsetLeft + 30) + "px");
    }
    if (name === 'TotalRevenue') {
      setfiltertext(t('ClaimReconcilation.Revenue'))
      var testDiv = document.getElementById("Revenue");
      setdivleft1((testDiv.offsetLeft + cardtop1.offsetLeft + 30) + "px");
    }
    if (name === 'ApprovedClaimReconcilation') {
      setfiltertext(t('ClaimReconcilation.CurrentAccrued'))
      var testDiv = document.getElementById("ApprovedClaimReconcilation");
      setdivleft1(((testDiv.offsetLeft + cardtop1.offsetLeft + 30) - 100) + "px");
    }
    if (name === 'UnpostedClaimReconcilations') {
      setfiltertext(t('ClaimReconcilation.ClaimReconcilationsInReview'))
      var testDiv = document.getElementById("UnpostedClaimReconcilations");
      setdivleft1(((testDiv.offsetLeft + cardtop1.offsetLeft + 30) - 100) + "px");
    }
    if (name === 'WeightedAveragePerUnit') {
      setfiltertext(t('ClaimReconcilation.Rebate/Unit'))
      var testDiv = document.getElementById("WeightedAveragePerUnit");
      setdivleft1((testDiv.offsetLeft + cardtop1.offsetLeft + 30) + "px");
    }
    if (name === 'TotalApprovedClaimReconcilation') {
      setfiltertext(t('ClaimReconcilation.TotalAccrued'))
      var testDiv = document.getElementById("TotalApprovedClaimReconcilation");
      setdivleft1((testDiv.offsetLeft + cardtop1.offsetLeft + 30) + "px");
    }
    if (name === 'DeltaReachNextTier') {
      setfiltertext("Delta To Reach Tier")
      var testDiv = document.getElementById("DeltaReachNextTier");
      setdivleft1((testDiv.offsetLeft + cardtop1.offsetLeft + 30) + "px");
    }
    if (name === 'CurrentTier') {
      setfiltertext("Current Tier")
      var testDiv = document.getElementById("CurrentTier");
      setdivleft1((testDiv.offsetLeft + cardtop1.offsetLeft + 30) + "px");
    }
    if (name === 'Exclude') {
      setfiltertext(t('ClaimReconcilation.Exclude'))
      var testDiv = document.getElementById("Exclude");
      setdivleft1((testDiv.offsetLeft + cardtop1.offsetLeft + 30) + "px");
      addnewfield(activeOption, name)
    }
    if (name === 'Category1') {
      setfiltertext(t('ClaimReconcilation.Category1'))
      var testDiv = document.getElementById("Category1");
      setdivleft1((testDiv.offsetLeft + cardtop1.offsetLeft + 30) + "px");
    }
    if (name === 'ProductLabel') {
      setfiltertext(t('ClaimReconcilation.ItemName'))
      var testDiv = document.getElementById("ProductLabel");
      setdivleft1((testDiv.offsetLeft + cardtop1.offsetLeft + 30) + "px");
    }
    if (name === 'Customer') {
      setfiltertext(t('ClaimReconcilation.Customer'))
      var testDiv = document.getElementById("Customer");
      setdivleft1((testDiv.offsetLeft + cardtop1.offsetLeft + 30) + "px");
    }
    if (name === 'ExcludedAmount') {
      setfiltertext(t('ClaimReconcilation.ExcludedRevenue'))
      var testDiv = document.getElementById("ExcludedRevenue");
      setdivleft1((testDiv.offsetLeft + cardtop1.offsetLeft + 30) + "px");
    }
    setshowfilterpopup1(true)
  }

  const DropdownHandler = (key, name) => {
    Service.getClaimReconcilationProductfilterDropdown('ClaimReconcilation/GetClaimReconcilationProdcutsFilterValue', key).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {

            if (res.data !== 'null' && res.data !== 'undefined') {

              addnewfield(res.data, name)

            }
          }
        }
      }

    });
  }
  const customMultiStyles = {
    multiselectContainer: {
      maxWidth: '170px',
      marginLeft: '3px'
    },
    chips: {
      borderRadius: '5px'
    }
  };

  const regionStyles = {
    chips: {
      fontSize: "12px",
      lineHeight: "13px"
    },

    multiSelectContainer: {
      "&:hover": { background: "red" }
    }

  }


  const unlockEntity = () => {
    Service.LockUnLockEntity('Configurationprop/UnLockEntity', "ClaimReconcilation", props.id, loggedInUser).then((res) => {

    });

  }

  const showDefn = () => {
    if (showRebateDefn === false) {
      setRebateDefn(true)
    }
    if (showRebateDefn === true) {
      setRebateDefn(false)
    }
    if (showRebateDefnIcon === "fa fa-plus") {
      setRebateDefnIcon("fa fa-minus")
    }
    if (showRebateDefnIcon === "fa fa-minus") {
      setRebateDefnIcon("fa fa-plus")
    }
  }

  const checkAll = (mycheckbox) => {
    var checkboxes = document.querySelectorAll("input[type = 'checkbox']");
    if (mycheckbox.target.checked == true) {
      settotalselectedshow(true);
      checkboxes.forEach(function (checkbox) {
        checkbox.checked = true;
      });
      settotalselectedcount(checkboxes.length - 1)
    }
    else {
      settotalselectedshow(false);
      checkboxes.forEach(function (checkbox) {
        checkbox.checked = false;
      });
      settotalselectedcount(0)
    }
    let checkedBoxes = document.querySelectorAll('input[name=chkBox]:checked');
    if (checkedBoxes.length >= 1) {
      setIsexport(true);
    } else {
      setIsexport(false);
    }
  }

  const handleItemChange = (e) => {
    let isChecked = e.target.checked;

    let checkedBoxes = document.querySelectorAll('input[name=chkBox]:checked');
    if (checkedBoxes.length >= 1) {
      setIsexport(true);
    } else {
      setIsexport(false);
    }
  }


  const volumetier = async (e) => {

    if (showvomumetierdeails == false) {
      setshowspinner(false)
      setshowvomumetierdeails(true)
    }
    if (showvomumetierdeails == true) {
      setshowvomumetierdeails(false)
    }
    if (showTierIcon === "fa fa-plus") {
      setTierIcon("fa fa-minus")
    }
    if (showTierIcon === "fa fa-minus") {
      setTierIcon("fa fa-plus")
    }
  }

  const rebateRulesPopup = (e) => {
    if (e == 'open') {
      setshowspinner(false)
      setshowvomumetierdeails(true)
      setsidebarwidth("60vw")
      setsidebarHeight("20vh")
      setBgColor("white")
    }
    if (e == 'Close') {
      setshowvomumetierdeails(false)
    }
  }


  return (

    <div className="container-fluid content-top-gap">
      <div style={{ display: "none" }}>
        <CSVLink data={csvList} headers={headers} ref={csvInstance} filename="ClaimReconcilationproducts.csv" > </CSVLink>
      </div>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb my-breadcrumb">
          <li className="breadcrumb-item"><Link to="/">{t('Home.title')}</Link></li>
          <li className="breadcrumb-item active" aria-current="page"><Link to={updateNav} id="headertxt">{t('ClaimReconcilation.ClaimReconcilationList')}</Link></li>
          <li className="breadcrumb-item active" id="libread" aria-current="page">{state.ClaimReconcilationId}</li>
        </ol>
      </nav>
      {loading ? <><div className="loader-container"><div className="spinner"></div> </div> </> : ""}
      {
        showClaimReconcilationWorkflow === true ?
          <div id="mySidepanel" className="sidepanel sidepanelborder" style={{ width: sidebarwidth }}>
            <div className="container" id="workflow">
              <div className="row mt-3">
                <div className="col-lg-12 pr-lg-2">
                  <div className="card text-center card_border">
                    <div className="card-header chart-grid__header">
                      <div className="row">
                        <div className="col-md-8 text-left">
                          {t('Workflow')}
                        </div>
                        <div className="col-md-4">
                          <button className="btn btn-outline-dark  btn-sm" style={{ float: "right", width: "40px" }} onClick={clickCloseClaimReconcilationWorkflow} type="submit">{t('Close')}</button>
                        </div>
                      </div>
                    </div>
                    <div className="card-body" style={{ minHeight: "55vh" }} >

                      {workflowchart.length > 0 ?


                        <div className='col-md-12'>
                          <table className="table">
                            <thead>
                              <tr>
                                <th className='workflowchart-levelwidth workflowchart-font'>{t('ClaimReconcilation.Level')}</th>
                                <th className='font-center workflowchart-font'>{t('ClaimReconcilation.Users')}</th>
                                <th className='font-center workflowchart-font'>{t('ClaimReconcilation.Approval')}</th>
                              </tr>
                            </thead>

                            <tbody>
                              {
                                workflowchart.map((i) =>
                                  <tr key={i.Id}>
                                    <td className='workflowchart-font square-levels'>{i.Levels}</td>
                                    <td className='font-center'>
                                      <ul >
                                        {
                                          i.lstworkflowapprover.map((j, index) => {
                                            return <>

                                              {
                                                index < 2 ?
                                                  j.IsApproved === true || j.IsDenied == true ?
                                                    <li className='workflowchartperson'>
                                                      <span className='workflowtpersoncolor'><i class="fa fa-user-circle-o" aria-hidden="true"></i>
                                                        <br></br> {j.FullName}</span>
                                                    </li>
                                                    :
                                                    <>
                                                      <li className='workflowchartperson'>
                                                        <i class="fa fa-user-circle-o" aria-hidden="true"></i>
                                                        <br></br> {j.FullName}
                                                      </li>
                                                    </>
                                                  :
                                                  ''
                                              }
                                            </>

                                          })
                                        }

                                        {
                                          i.lstworkflowapprover.length > 2 ?
                                            <li className='workflowchartperson tooltip_workflow'>
                                              <i class="fa fa-user-circle-o" aria-hidden="true"></i>
                                              <br></br> {t('ClaimReconcilation.MoreUsers')}
                                              <span class="tooltiptext">

                                                {
                                                  i.lstworkflowapprover.map((k, index) => {
                                                    return <span>
                                                      {
                                                        index > 1 ? <><i class="fa fa-user-circle-o" aria-hidden="true"></i>
                                                          &nbsp; {k.FullName}
                                                          <hr></hr></> : null
                                                      }


                                                    </span>
                                                  })
                                                }

                                              </span>
                                            </li>
                                            :
                                            ''
                                        }


                                      </ul>

                                    </td>

                                    <td className='font-center'>
                                      {
                                        i.IsApproved === true ?
                                          <span className='workflowrightcolor'>
                                            <i class="fa fa-check" aria-hidden="true"></i>
                                          </span>

                                          : i.IsDenied == true ?
                                            <span className='workflowrightcolor'>
                                              <i class="fa fa-times" aria-hidden="true" style={{ color: "red" }}></i>
                                            </span>
                                            : ''

                                      }
                                    </td>
                                  </tr>)

                              }
                            </tbody>

                          </table>

                        </div>

                        : workflowLevelCount > 0 ? (
                          <>
                            <div className={classes.flow}>
                              <div id="status1" className={`${classes['square']} `}>
                                <p className={classes['statusText']}>{t('ClaimReconcilation.InReview')}</p>
                              </div>
                              {CurrentWorkflowLevel === 0 && workflowData.length > 1 && workflowLevelCount > 0 ? (
                                <>
                                  <div className={classes.line}></div>
                                  <div className={`status3 ${classes['square']} ${classes['Active-Status']} `}>
                                    <p className={classes['statusText']}>{t('ClaimReconcilation.Approved')}</p>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className={classes.line}></div>
                                  <div className={`status3 ${classes['square']} `}>
                                    <p className={classes['statusText']}>{t('ClaimReconcilation.Approved')}</p>
                                  </div>
                                </>
                              )}
                            </div>
                          </>
                        ) : null}

                      <div className="col-md-12">
                        <table className="table text-left mt-2">
                          <thead className='text-white' style={{ backgroundColor: "var(--primary)" }}>
                            <tr>

                              <th>{t('ClaimReconcilation.Event')}</th>
                              <th>{t('ClaimReconcilation.CreatedDate')}</th>
                              <th>{t('ClaimReconcilation.User')}</th>
                              <th>{t('ClaimReconcilation.Comments')}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {workflowData.map((i) =>
                              <tr>
                                <td>{i.EventName}</td>
                                <td>{moment(i.CreatedDate).format(SessionManage.getcurrentdateformat())}</td>
                                <td>{i.CreateUserName}</td>
                                <td>{i.Comments}</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>




                    </div>

                  </div>
                </div>

              </div>
            </div>
          </div>
          :
          ''
      }






      <div className="row mt-3" id="rebateProgramdiv">
        <div className="col-lg-12 pr-lg-2">
          <div className="card text-center card_border">
            <div className="card-header chart-grid__header">
              <div className="row">
                <div className="col-md-7 text-left">
                  {t('ClaimReconcilation.Claim')}
                </div>
                <div className="col-md-5">

                  {isAnalyzeRebate == true ? <><button type="button" className="btn btn-sm btn-update ml-2" style={{ float: "right", width: "40px" }} onClick={() => CloseHandler('openclose')}>{t('Close')}</button></> :
                    <>


                      <button className="btn btn-sm
                       btn-update ml-2" style={{ float: "right", width: "65px" }} onClick={() => { clickShowClaimReconcilationWorkflow("Workflow") }} type="submit">{t('Workflow')}</button>
                      {Service.submitPerms(userPerms) == false ? "" : accStatus != 'Rejected' && accStatus != 'InReview' && accStatus != 'Approved' && accStatus != 'Denied' ? <button type="button" className="btn btn-sm btn-update ml-2" style={{ float: "right", width: "50px" }} onClick={() => workflowAlert('open')}>{t('Submit')}</button> : <></>}
                      {accStatus != 'InReview' && accStatus != 'Approved' && accStatus != 'Denied' ? <button type="button" className="btn btn-sm btn-update ml-2" style={{ float: "right", width: "40px" }} onClick={() => CloseHandler('openclose')}>{t('Close')}</button> : <></>}
                      {accStatus != 'Rejected' && accStatus != 'InReview' && accStatus != 'Approved' && accStatus != 'Denied' ? <button type="button" className="btn btn-sm btn-update ml-2" style={{ float: "right", width: "55px" }} >{t('Publish')}</button> : <></>}



                      {Service.editPerms(userPerms) == false ? "" :
                        (loadedFrom === 'fromApproval' && accStatus == 'InReview') ?
                          (isLocked === true && lockedBy.toString() === loggedInUser) ?
                            <>
                              <button type="button" className="btn btn-sm btn-update ml-2" style={{ float: "right", width: "55px" }} onClick={() => showApprovedDialog('showApprovedDialog')} >Approve</button>
                              <button type="button" className="btn btn-sm btn-update ml-2" style={{ float: "right", width: "40px" }} onClick={() => showDeniedDialog('showDeniedDialog')}> Deny </button>
                            </>
                            : ""
                          : <></>}
                      {Service.editPerms(userPerms) == false ? "" : (loadedFrom != "fromApproval" && loadedFrom != 'fromapprovalcompleted') && (accStatus == 'InReview' || accStatus == 'Approved' || accStatus == 'Denied') ? <button type="button" className="btn btn-sm btn-update ml-2" style={{ float: "right", width: "50px" }} onClick={() => CancelHandler()}>  Cancel</button> : <></>}
                      {Service.editPerms(userPerms) == false ? "" : loadedFrom == 'fromApproval' || loadedFrom == 'fromapprovalcompleted' ? <button type="button" className="btn btn-sm btn-update ml-2" style={{ float: "right", width: "50px" }} onClick={() => navigateHandler()}>  Cancel</button> : <></>}
                    </>}

                </div>
              </div>
            </div>
            <div className="card-body" >
              <div className="container">
                <div className="row">

                  <label className="col-md-2 text-left">{t('ClaimReconcilation.ClaimId')}</label>
                  <input id="aid" disabled={true} value={state.SDClaimId} type="text" className="col-md-2 form-control" />

                  <label className="col-md-2 text-left">{t('ClaimReconcilation.AgreementId')}</label>
                  <input id="aid" disabled={true} value={state.SDAgreementId} type="text" className=" col-md-2 form-control" />

                  <label className="col-md-2 text-left">{t('ClaimReconcilation.Validity')}</label>
                  <input id="aid" name="startdate" disabled={true} value={Validity} type="text" className="col-md-2 form-control" />

                  <label className="col-md-2 mt-2 text-left">{t('ClaimReconcilation.ClaimApprovedDate')}</label>
                  <input id="aid" name="startdate" disabled={true} value={state.ClaimApprovedDate} type="text" className="col-md-2 mt-2 form-control" />
                  <label className="col-md-2 mt-2 text-left">{t('ClaimReconcilation.Distributor')}</label>
                  <input id="aid" disabled={true} value={state.DistributorName} type="text" className="col-md-2 mt-2 form-control" />
                  <label className="col-md-2 mt-2 text-left">{t('ClaimReconcilation.ClaimSubmittedDate')}</label>
                  <input id="aid" disabled={true} value={state.ClaimSubmittedDate} type="text" className="col-md-2 mt-2 form-control" />
                  <div></div>
                  <label className="col-md-2 mt-2 text-left">{t('ClaimReconcilation.ClaimApprovedBy')}</label>
                  <input id="aid" disabled={true} value={state.ClaimApprovedBy} type="text" className="col-md-2 mt-2 form-control" title={state.CustomerCodeName} />
                  <label className="col-md-2 mt-2 text-left">{t('ClaimReconcilation.Region')}</label>
                  <input id="aid" disabled={true} value={state.Region} type="text" className="col-md-2 mt-2 form-control" />
                  <label className="col-md-2 mt-2 text-left">{t('ClaimReconcilation.ClaimSubmittedBy')}</label>
                  <input id="aid" name="ClaimSubmittedBy" disabled={true} value={state.ClaimSubmittedBy} type="text" className="col-md-2 mt-2 form-control" />
                  <div></div>
                  <label className="col-md-2 mt-2 text-left">{t('ClaimReconcilation.AgreementType')}</label>
                  <input id="aid" disabled={true} value={state.AgreementType} type="text" className="col-md-2 mt-2 form-control" title={state.AgreementType} />

                </div>
              </div>


              <div className="col-lg-12 pr-lg-2 mt-3">
                <div className="card text-center card_border">
                  <div className="card-header chart-grid__header">
                    <div className="row">
                      <div className="col-md-8 text-left">
                        <i className={showRebateDefnIcon} style={{ cursor: "pointer" }} onClick={() => showDefn()}></i> &nbsp;{t('ClaimReconcilation.Claim-Definition')}
                      </div>
                    </div>
                  </div>

                  {showRebateDefn === true ?
                    <div className="card-body" style={{ minHeight: "17vh" }} >
                      <div className="container-fluid">
                        <div className="table-responsive">
                          <table className="table table-head-fixed text-nowrap table-bordered table-striped table-responsive-sm" style={{ width: "760px" }}>
                            <thead className="thead-light">
                              <tr>
                                <th>{t('sdClaim.ClaimLineId')}</th>
                                <th>{t('SpecialPriceAgreement.Material')}</th>
                                <th>{t('SpecialPriceAgreement.MaterialName')}</th>
                                {state.AgreementType !== 'ClaimAgreement' ?
                                  <>
                                    <th>{t('Rebates.RebateType')}</th>
                                    <th>{t('SpecialPriceAgreement.ContractType')}</th>
                                    <th>{t('sdClaim.ContractPrice')}</th>
                                    <th>{t('ship&debit.ThresholdCondition')}</th>
                                    <th>{t('ship&debit.ThresholdAmount')}</th>
                                    <th>{t('ship&debit.ThresholdQty')}</th> </> :
                                  ''}
                                <th>{t('sdClaim.ClaimRevenue')}</th>
                                <th>{t('sdClaim.ClaimQty')}</th>
                                <th>{t('sdClaim.TransactionDate')}</th>
                                <th>{t('sdClaim.AmountClaimed')}</th>
                                <th>{t('sdClaim.InvoiceNumber')}</th>
                              </tr>
                            </thead>
                            <tbody>


                              {
                                claimItemList.map((item, index) => (
                                  <tr>
                                    <td>    {index + 1}</td>
                                    <td>    {item.Material}</td>
                                    <td>    {item.Product}</td>
                                    {state.AgreementType !== 'ClaimAgreement' ?
                                      <>
                                        <td>    {item.RebateType}</td>
                                        <td>    {item.ContractType}</td>

                                        <td>{item.RebateType == 'Volume' ? ` ${state.currencyPrefix} ${item.ContractPrice} ` : ` ${item.ContractPrice} ${'%'}`} </td>


                                        <td>   {item.Thresholdcond}</td>
                                        <td>{item.RebateType == 'Revenue' ? ` ${state.currencyPrefix} ${item.ThresholdAmount} ` : ` ${item.ThresholdAmount}`}</td>
                                        <td>   {item.ThresholdQty}</td>
                                      </> : ''}
                                    <td> {state.currencyPrefix}  {item.ClaimRevenue}</td>
                                    <td>   {item.ClaimQty}</td>
                                    <td>{moment(item.TransactionDate).format(SessionManage.getcurrentdateformat())}</td>
                                    <td> {state.currencyPrefix}  {item.ClaimAmount}</td>
                                    <td>   {item.InvoiceNumber}</td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>

                      </div>
                    </div>
                    :
                    ""
                  }
                </div>
              </div>

              {state.AgreementType == 'ClaimAgreement' ?

                <div className="col-lg-12 pr-lg-2 mt-3">
                  <div className="card text-center card_border">
                    <div className="card-header chart-grid__header">
                      <div className="row">
                        <div className="col-md-8 text-left">
                          <i className={showTierIcon} style={{ cursor: "pointer" }} onClick={() => volumetier()}></i> &nbsp;{t('sdClaim.RebateRules')}
                        </div>
                      </div>
                    </div>
                    {
                      showvomumetierdeails ?
                        <>

                          <div className="card-body" style={{ minHeight: "15vh" }} >
                            <div className="container-fluid">
                              <div className="table-responsive">
                                <div className="scrollbar-class">
                                  <table className="table-sm table-bordered text-left mt-2 table-responsive-sm" id="tableB" style={{ fontSize: "12px" }}>
                                    <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)", color: "white" }}>
                                      <tr>
                                        <th >
                                          {t('Rebates.TargetCond')}
                                        </th>
                                        <th >
                                          {t('Rebates.MeasureValue')}
                                        </th>
                                        <th >
                                          {t('Rebates.Rebate')}
                                        </th>
                                        <th>
                                          {t('Rebates.RebateValue')}
                                        </th>
                                        <th>
                                          {t('Rebates.TargetMeasure')}
                                        </th>

                                      </tr>
                                    </thead>
                                    <tbody>
                                      {volumerierdata.map((item, index) => (
                                        <tr >
                                          <td>{item.Targetcondition}</td>
                                          <td>{item.MeasureValue}</td>
                                          <td>{item.Rebate}</td>
                                          <td>{item.RebateValue}</td>
                                          <td>{item.TargetMeasure}</td>

                                        </tr>
                                      ))}


                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                        :
                        ""
                    }
                  </div>

                </div>
                :
                <></>}


            </div>

          </div>
        </div>
      </div>


      <div className="row mt-3" id="card-RPL">
        <div className="col-lg-12 pr-lg-2">
          <div className="card text-center card_border">
            <div className="card-header chart-grid__header">
              <div className="row">
                <div className="col-md-9 text-left">
                  {t('ClaimReconcilation.EligibleInvoice')}
                </div>

                <div className="col-md-3">


                  {isAnalyzeRebate == true ? "" : Service.editPerms(userPerms) == false ? "" : accStatus != 'InReview' && accStatus != 'Approved' && accStatus != 'Denied' ? (isLocked === true && lockedBy.toString() === loggedInUser) ? <button type="button" className="btn btn-sm btn-update ml-2" onClick={GetEligibleInvoices} style={{ width: '75px', float: "right" }} >{t('Recalculate')}</button> : <></> : <></>}
                  {isexport == false ? "" : Service.exportPerms(userPerms) == false ? "" : <button type="button" className="btn btn-sm btn-update ml-2" style={{ width: '50px', float: "right" }} onClick={(event) => ExporttoCSV()}>{t('Export')}</button>}

                </div>
              </div>
            </div>
            <div className="mt-3 ml-2">
              <table className="table-sm  table-bordered text-left mt-2 table-responsive-sm" style={{ fontSize: "12px" }}>
                <thead className='text-black ' >
                  <tr>
                    <th >{t('ClaimReconcilation.TotalRevenue')}</th>
                    <th >{t('ClaimReconcilation.TotalQuantity')}</th>

                    <th >{t('ClaimReconcilation.TotalAccrued')}</th>
                    {accStatus === 'New' || accStatus === 'InReview' || accStatus === 'Denied' || accStatus === 'Rejected' ?
                      <th >{t('ClaimReconcilation.TotalReconciledInReview')}</th>
                      :
                      <th >{t('ClaimReconcilation.ApprovedAmount')}</th>
                    }
                    {state.AgreementType == 'ClaimAgreement' ? <th>{t('ClaimReconcilation.OverallClaimStatus')}</th> : <></>}
                    {state.OverallStatus == "Failure" & state.AgreementType == 'ClaimAgreement' ?
                      <th >{t('ClaimReconcilation.ErrorMessage')}</th>
                      : ''
                    }

                  </tr>
                </thead>
                <tbody>
                  {
                    showspinner === true ?
                      <>
                        <Spinner animation="grow" size="sm" variant="success" style={{ width: "0.7rem", height: "0.7rem" }} />
                        <Spinner animation="grow" size="sm" variant="danger" style={{ width: "0.7rem", height: "0.7rem" }} />
                        <Spinner animation="grow" size="sm" variant="warning" style={{ width: "0.7rem", height: "0.7rem" }} />
                      </>
                      :
                      <>
                        <td >{state.currencyPrefix}{Service.currencyFormat(parseFloat(state.TotalRevenue).toFixed(SessionManage.getroundingPrecision()))}</td>
                        <td>{state.TotalQuantity}</td>


                        <td>{state.currencyPrefix}{Service.currencyFormat(parseFloat(state.TotalApprovedAmount).toFixed(SessionManage.getroundingPrecision()))}</td>
                        <td>{state.currencyPrefix}{state.ConcilationAggregate}</td>
                        {state.AgreementType == 'ClaimAgreement' ?
                          <td>{state.OverallStatus}</td>
                          :
                          <></>}
                        {state.OverallStatus == "Failure" & state.AgreementType == 'ClaimAgreement' ?
                          <td>{state.ErrorDetails}</td>
                          : ''
                        }

                      </>
                  }
                </tbody>
              </table>
            </div>
            <div className="card-body" style={{ minHeight: "35vh" }}>
              <div className='row mr-2' style={{ float: 'right' }}>
                <button id="addRemoveCol" icon className="btn btn-update ml-2 mt-1 pa-0" type="submit"
                  style={{ float: "left", cursor: "pointer", padding: '0px', height: '29px', width: '26px' }}
                  onClick={AddRemoveColumn} title='Manage View'><i class="fa fa-columns" aria-hidden="true"></i></button>
              </div>
              <br></br>

              <table className="table  table-bordered text-left mt-2 table-responsive-sm" id='tableB'>
                <thead className='text-white' style={{ backgroundColor: "var(--primary)" }}>
                  <tr>

                    <th style={{ width: '20px' }}></th>
                    <th id="ProductName" style={{ backgroundColor: (multiFilterValue1.ProductName ? '#6cae55' : ''), width: '40px' }}
                      title='Product ID' hidden={hideColumn.includes('ProductName') ? true : false}>
                      <div className='d-flex text-nowrap'>
                        <span>{t('ClaimReconcilation.MaterialNo')}</span>
                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('ProductName')}></span>
                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumnItems('ProductName')}></span>
                      </div>
                    </th>
                    <th id="ProductLabel" style={{ backgroundColor: (filterValue1.ProductLabel ? '#6cae55' : ''), maxWidth: '120px' }}
                      title='Product' hidden={hideColumn.includes('ProductLabel') ? true : false}>
                      <div className='d-flex text-nowrap'>
                        <span>{t('ClaimReconcilation.MaterialDesc')} </span>
                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('ProductLabel')}></span>
                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumnItems('ProductLabel')}></span>
                      </div>
                    </th>

                    <th id='Quantity' style={{ backgroundColor: (filterValue1.Quantity ? '#6cae55' : ''), maxWidth: "120px" }} title='Qty' hidden={hideColumn.includes('Quantity') ? true : false}>
                      <div className='d-flex text-nowrap'>
                        <span>{t('ClaimReconcilation.Quantity')}</span>
                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('Quantity')}></span>
                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumnItems('Quantity')}></span>
                      </div>
                    </th>

                    <th id='Revenue' style={{ backgroundColor: (filterValue1.TotalRevenue ? '#6cae55' : ''), maxWidth: "120px" }}
                      title='Sale' hidden={hideColumn.includes('Revenue') ? true : false}>
                      <div className='d-flex text-nowrap'>
                        <span>{t('ClaimReconcilation.Revenue')}</span>
                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('Revenue')}></span>
                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumnItems('Revenue')}></span>
                      </div>
                    </th>
                    <th id='ClaimedQuantity' style={{ backgroundColor: (filterValue1.Quantity ? '#6cae55' : ''), maxWidth: "120px" }}
                      title='Qty' hidden={hideColumn.includes('ClaimedQuantity') ? true : false}>
                      <div className='d-flex text-nowrap'>
                        <span>{t('ClaimReconcilation.ClaimedQty')}</span>
                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('ClaimedQuantity')}></span>
                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumnItems('ClaimedQuantity')}></span>
                      </div>
                    </th>
                    <th id='ClaimQuantity' style={{ backgroundColor: (filterValue1.Quantity ? '#6cae55' : ''), maxWidth: "120px" }}
                      title='Qty' hidden={hideColumn.includes('ClaimQuantity') ? true : false}>
                      <div className='d-flex text-nowrap'>
                        <span>{t('ClaimReconcilation.ClaimQty')}</span>
                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('ClaimQuantity')}></span>
                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumnItems('ClaimQuantity')}></span>
                      </div>
                    </th>
                    <th id='ClaimedRevenue' style={{ backgroundColor: (filterValue1.TotalRevenue ? '#6cae55' : ''), maxWidth: "130px" }}
                      title='Sale' hidden={hideColumn.includes('ClaimedRevenue') ? true : false}>
                      <div className='d-flex text-nowrap'>
                        <span> {t('ClaimReconcilation.ClaimedRevenue')} </span>
                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('ClaimedRevenue')}></span>
                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumnItems('ClaimedRevenue')}></span>
                      </div>
                    </th>
                    <th id='ClaimRevenue' style={{ backgroundColor: (filterValue1.TotalRevenue ? '#6cae55' : ''), maxWidth: "110px" }}
                      title='Sale' hidden={hideColumn.includes('ClaimRevenue') ? true : false}>
                      <div className='d-flex text-nowrap'>
                        <span>{t('ClaimReconcilation.ClaimRevenue')}</span>
                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('ClaimRevenue')}></span>
                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumnItems('ClaimRevenue')}></span>
                      </div>
                    </th>
                    <th id='ReconcilationStatus' style={{ backgroundColor: (filterValue1.TotalRevenue ? '#6cae55' : ''), maxWidth: "130px" }}
                      title='Sale' hidden={hideColumn.includes('ReconcilationStatus') ? true : false}>
                      <div className='d-flex text-nowrap'>
                        <span>{t('ClaimReconcilation.ReconcilationStatus')}</span>
                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('ReconcilationStatus')}></span>
                        <span className='fa fa-filter ml-1 mt-1' style={{
                          float:
                            "right", marginTop: "4px", cursor: "pointer"
                        }} onClick={() => FilterColumnItems('ReconcilationStatus')}></span>
                      </div>
                    </th>
                    <th id='TotalReconciled' style={{ backgroundColor: (filterValue1.TotalRevenue ? '#6cae55' : ''), maxWidth: "130px" }}
                      title='Sale' hidden={hideColumn.includes('TotalReconciled') ? true : false}>
                      <div className='d-flex text-nowrap'>
                        <span>{t('ClaimReconcilation.TotalReconciled')}</span>
                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('TotalReconciled')}></span>
                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumnItems('TotalReconciled')}></span>
                      </div>
                    </th>
                    {accStatus === 'New' || accStatus === 'InReview' || accStatus === 'Denied' || accStatus === 'Rejected' ?
                      <>
                        <th id='ReconciledInReview' style={{ backgroundColor: (filterValue1.ReconciledInReview ? '#6cae55' : ''), maxWidth: "140px" }}
                          title='Sale' hidden={hideColumn.includes('ReconciledInReview') ? true : false}>
                          <div className='d-flex text-nowrap'>
                            <span>{t('ClaimReconcilation.ReconciledInReview')}</span>
                            <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('ReconcilationInReview')}></span>
                            <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumnItems('ReconcilationInReview')}></span>
                          </div>
                        </th>
                      </>
                      :
                      <>
                        <th id='ApprovedAmount' style={{ backgroundColor: (filterValue1.ApprovedAmount ? '#6cae55' : ''), maxWidth: "160px" }}
                          title='Sale' hidden={hideColumn.includes('ApprovedAmount') ? true : false}>
                          <div className='d-flex text-nowrap'>
                            <span>{t('ClaimReconcilation.ApprovedAmount')}</span>
                            <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('ApprovedAmount')}></span>
                            <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumnItems('ApprovedAmount')}></span>
                          </div>
                        </th>
                      </>
                    }

                  </tr>
                </thead>
                {<tbody>
                  {
                    showspinner === true ?
                      <Spinner animation="grow" size="sm" variant="success" style={{ width: "0.7rem", height: "0.7rem" }} />
                      :
                      itemsData.length === 0 ?
                        <tr className='text-center'>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>{t('NoDataAvailable')}</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                        :
                        itemsData.map((i, index) =>
                          <tr onDoubleClick={() => GetTransactionData(i.ProductName, i.Id, i.Distributor, index, "Ascending", "Id", [])}>

                            <td>
                              <div>
                                {(i.ItemStatus === "Failure") ?
                                  <i className="fa fa-exclamation" style={{ color: 'orange' }} title="Validation failed"></i>
                                  : <></>}
                              </div>
                            </td>
                            <td title={i.ProductName} style={{ width: "120px" }} hidden={hideColumn.includes('ProductName') ? true : false}>
                              <div>
                                <a href="#" onClick={() => GetTransactionData(i.ProductName, i.Id, i.Customer, index, "Ascending", "Id", [])}> {i.ProductName}</a>
                              </div>
                            </td>
                            <td style={{ maxWidth: "120px" }} hidden={hideColumn.includes('ProductLabel') ? true : false}><div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right" title={i.ProductLabel}>{i.ProductLabel}</div></td>

                            <td title={i.Quantity} hidden={hideColumn.includes('Quantity') ? true : false}>{Service.numberFormat(i.Quantity)}</td>

                            {i.Revenue === null ? <td>{state.currencyPrefix} 0</td> :
                              <td title={i.Revenue} hidden={hideColumn.includes('Revenue') ? true : false}>{state.currencyPrefix}{state.currency} {Service.currencyFormat(parseFloat(i.Revenue).toFixed(SessionManage.getroundingPrecision()))}</td>}
                            {i.RebateType == 'Volume' ? <>
                              {i.ClaimedQty === null || i.ClaimedQty == 0 ? <td>0</td> :
                                <td title={i.ClaimedQty} hidden={hideColumn.includes('ClaimedQty') ? true : false}> {Service.numberFormat(i.ClaimedQty)}</td>}
                              {i.ClaimQty === null || i.ClaimQty == 0 ? <td>0</td> :
                                <td title={i.ClaimQty} hidden={hideColumn.includes('ClaimQty') ? true : false}>{Service.numberFormat(i.ClaimQty)}</td>}
                            </>
                              :
                              <>
                                <td></td>
                                <td></td>
                              </>}
                            {i.RebateType == 'Revenue' ? <>
                              {i.ClaimedRevenue === null ? <td>{state.currencyPrefix} 0</td> :
                                <td title={i.ClaimedRevenue} hidden={hideColumn.includes('ClaimedRevenue') ? true : false}>{state.currencyPrefix}{state.currency} {Service.currencyFormat(parseFloat(i.ClaimedRevenue).toFixed(SessionManage.getroundingPrecision()))}</td>}
                              {i.ClaimRevenue === null ? <td>{state.currencyPrefix} 0</td> :
                                <td title={i.ClaimRevenue} hidden={hideColumn.includes('ClaimRevenue') ? true : false}>{state.currencyPrefix}{state.currency} {Service.currencyFormat(parseFloat(i.ClaimRevenue).toFixed(SessionManage.getroundingPrecision()))}</td>}
                            </>
                              : <>
                                <td></td>
                                <td></td>
                              </>
                            }

                            <td>{i.ItemStatus}</td>
                            {i.TotalApprovedAmount === null ? <td>{state.currencyPrefix}0</td> :
                              <td title={i.TotalApprovedAmount} hidden={hideColumn.includes('TotalApprovedAmount') ? true : false}>{state.currencyPrefix}{state.currency} {Service.currencyFormat(parseFloat(i.TotalApprovedAmount).toFixed(SessionManage.getroundingPrecision()))}</td>}
                            {accStatus === 'New' || accStatus === 'InReview' || accStatus === 'Denied' || accStatus === 'Rejected' ? i.UnpostedAmount === null ? <td>0</td> :
                              <td title={i.UnpostedAmount} hidden={hideColumn.includes('UnpostedAmount') ? true : false}>{state.currencyPrefix}{state.currency} {Service.currencyFormat(parseFloat(i.UnpostedAmount).toFixed(SessionManage.getroundingPrecision()))}</td>
                              : <></>}
                            {accStatus === 'Approved' ? i.ApprovedAmount === null ? <td>0</td> :
                              <td title={i.ApprovedAmount} hidden={hideColumn.includes('ApprovedAmount') ? true : false}>{state.currencyPrefix}{state.currency} {Service.currencyFormat(parseFloat(i.ApprovedAmount).toFixed(SessionManage.getroundingPrecision()))}</td>
                              : <></>}


                          </tr>
                        )}
                  {state.AgreementType == 'ClaimAgreement' ?
                    <tr>
                      <td colSpan="3" style={{ textAlign: 'center' }}>Total</td>
                      <td hidden={hideColumn.includes('TotalQuantity') ? true : false}>{state.TotalQuantity}</td>
                      <td hidden={hideColumn.includes('TotalRevenue') ? true : false}>{state.currencyPrefix}{Service.currencyFormat(parseFloat(state.TotalRevenue).toFixed(SessionManage.getroundingPrecision()))}</td>
                      <td hidden={hideColumn.includes('claimedQtySum') ? true : false}>{claimedQtySum}</td>
                      <td hidden={hideColumn.includes('qtyInReviewSum') ? true : false}>{qtyInReviewSum}</td>
                      <td hidden={hideColumn.includes('claimedRevSum') ? true : false}>{state.currencyPrefix}{state.currency} {Service.currencyFormat(parseFloat(claimedRevSum).toFixed(SessionManage.getroundingPrecision()))}</td>
                      <td hidden={hideColumn.includes('revInReviewSum') ? true : false}>{state.currencyPrefix}{state.currency} {Service.currencyFormat(parseFloat(revInReviewSum).toFixed(SessionManage.getroundingPrecision()))}</td>
                      <td></td>
                      <td hidden={hideColumn.includes('totalReconciledSum') ? true : false}>{state.currencyPrefix}{state.currency} {Service.currencyFormat(parseFloat(totalReconciledSum).toFixed(SessionManage.getroundingPrecision()))}</td>
                      {accStatus === 'New' || accStatus === 'InReview' || accStatus === 'Denied' || accStatus === 'Rejected' ?
                        <td hidden={hideColumn.includes('reconciledInReviewSum') ? true : false}>{state.currencyPrefix}{state.currency} {Service.currencyFormat(parseFloat(reconciledInReviewSum).toFixed(SessionManage.getroundingPrecision()))}</td>
                        : <td hidden={hideColumn.includes('approvedAmountSum') ? true : false}>{state.currencyPrefix}{state.currency} {Service.currencyFormat(parseFloat(approvedAmountSum).toFixed(SessionManage.getroundingPrecision()))}</td>
                      } </tr>

                    :
                    <></>}
                </tbody>}
              </table>

              <br />
              <div className='col-lg-8 col-sm-12'></div>
              <div className='col-lg-2 forgettext col-sm-12 border form-control' style={{ textAlign: "center" }}>
                {t('TotalRecords')} - {itemsData.length}
              </div>

            </div>
          </div>
        </div>

      </div>



      {
        isOpen && <Popup
          content={<>
            <b></b>

            <div style={{ marginTop: '10px', height: '80px' }} className={`ag-theme-alpine ${classes1['aggrid-Container']}`} id="card-RPL2">

              <div className="scrollbar-class card-body table-responsive" style={{ height: "500px", overflowX: "hidden", overflowY: "scroll" }}>
                <span className="close-icon" onClick={cancel}>x</span>
                <table class="table table-head-fixed text-nowrap table-bordered table-striped" id="tableA">
                  <thead className='text-white' style={{ backgroundColor: "var(--primary)" }}>
                    <tr>

                      <th id="InvoiceNumber" style={{ backgroundColor: (filterValueDict.InvoiceNumber ? '#6cae55' : '') }}>
                        {t('ClaimReconcilation.Invoice')} <span className='fa fa-sort' onClick={() => SortColumnItems('InvoiceNumber')}></span>
                        <span className='fa fa-filter' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('InvoiceNumber')}></span>
                      </th>
                      <th id="TransactionDate" style={{ backgroundColor: (filterValueDict.TransactionDate ? '#6cae55' : '') }}>
                        {t('ClaimReconcilation.InvoiceDate')} <span className='fa fa-sort' onClick={() => SortColumnItems('TransactionDate')}></span>
                        <span className='fa fa-filter' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('TransactionDate')}></span>
                      </th>



                      <th id='LineItem' style={{ backgroundColor: (popFilterValue.LineItem ? '#6cae55' : '') }}>
                        {t('ClaimReconcilation.LineItem')} <span className='fa fa-sort' onClick={() => SortColumnItems('LineItem')}></span>
                        <span className='fa fa-filter' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('LineItem')}></span>
                      </th>
                      <th id='Quantity' style={{ backgroundColor: (popFilterValue.Quantity ? '#6cae55' : '') }}>
                        {t('ClaimReconcilation.Qty')} <span className='fa fa-sort' onClick={() => SortColumnItems('Quantity')}></span>
                        <span className='fa fa-filter' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('Quantity')}></span>
                      </th>
                      <th id='Revenue' style={{ backgroundColor: (popFilterValue.Revenue ? '#6cae55' : '') }}>
                        {t('ClaimReconcilation.Transacted Revenue')}<span className='fa fa-sort' onClick={() => SortColumnItems('Revenue')}></span>
                        <span className='fa fa-filter' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('Revenue')}></span>
                      </th>

                      <th id='Netprice' style={{ backgroundColor: (popFilterValue.Netprice ? '#6cae55' : '') }}>
                        {t('ClaimReconcilation.InvoicePrice')} <span className='fa fa-sort' onClick={() => SortColumnItems('Netprice')}></span>
                        <span className='fa fa-filter' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('Netprice')}></span>
                      </th>


                    </tr>
                  </thead>
                  {<tbody>
                    {transactionData.length === 0 ?
                      <tr>
                        <td></td>
                        <td></td>

                        <td>
                          {t('NoDataAvailable')}
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>

                      </tr>
                      :
                      transactionData.map((i, index) =>
                        <tr>

                          <td>{i.InvoiceNumber}</td>
                          <td>{moment(i.TransactionDate).format(SessionManage.getcurrentdateformat())}</td>

                          <td>{i.LineItem}</td>
                          <td>{Service.numberFormat(i.Quantity)}</td>

                          <td>{state.currencyPrefix} {Service.currencyFormat(parseFloat(i.TotalValue).toFixed(SessionManage.getroundingPrecision()))}</td>

                          <td>{state.currencyPrefix} {Service.currencyFormat(parseFloat(i.Revenue).toFixed(SessionManage.getroundingPrecision()))}</td>

                        </tr>
                      )}
                  </tbody>}
                </table>

                <div className='row'>

                  <div className='col-md-2 border' style={{ textAlign: "center", marginLeft: "15px", paddingTop: "5px" }}>
                    {t('TotalRecords')} -{totalCountlst}
                  </div>
                  {
                    totalselectedshow === true ?

                      <div className='col-md-3  col-sm-12 border' style={{ textAlign: "center", paddingTop: "5px", marginLeft: "10px" }}>
                        {t('ClaimReconcilation.TotalRecordsExcluded')} -<span id="totalselected">{totalselectedcount}</span>
                      </div>

                      :
                      <div className='col-md-3  col-sm-12' style={{ textAlign: "center" }}>

                      </div>
                  }
                  <div className='col-md-2'></div>
                  <div className="col-md-4">
                    <Button type="button" className="btn btn-primary btn-sm m-1" style={{ float: "right", width: "40px" }} onClick={cancel} >{t('Close')}</Button>
                  </div>
                </div>

              </div>
            </div>
          </>}
          handleClose={togglePopup}
        />
      }

      <CustomErrorPopup ErrorMessage={errorMessage} show={showErrorModal} ErrorStatus={errorStatus}
        onHide={() => setShowErrorModal(false)}></CustomErrorPopup>

      <Modal show={showDeleteModal}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Body style={{ "textAlign": 'center' }}>{t('ClaimReconcilation.Validations.ClaimReconcilationIsNotSubmittedDoYouWantToContinue')}</Modal.Body>
        <Modal.Footer>
          <Button size='sm' style={{ background: '#eff1f9', color: 'black', borderColor: 'black', width: "45px" }} onClick={() => CloseHandler('openclose')}>
            {t('Close')}
          </Button>
          <Button size='sm' style={{ background: '#eff1f9', color: 'black', borderColor: 'black', width: "55px" }} onClick={() => CloseHandler('proceed')}>
            {t('Proceed')}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showSubmitModal} size="bg"
        centered>
        <Modal.Header>
          <Modal.Title>{t('ClaimReconcilation.SubmitComments')}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ "textAlign": 'center' }} >
          <Form.Control className={classes['Textarea-width']} as="textarea" rows={2} value={commentsForSubmit} name="commentsForSubmit"
            onChange={(e) => { setCommentsForSubmit(e.target.value) }} />
        </Modal.Body>
        <Modal.Footer>
          <Button size='sm' style={{ background: '#eff1f9', color: 'black', borderColor: 'black', width: "45px" }} onClick={() => showSubmitDialog('showSubmitDialog')}>
            {t('Close')}
          </Button>
          <Button size='sm' style={{ background: '#eff1f9', color: 'black', borderColor: 'black', width: "50px" }} onClick={() => showSubmitDialog('proceed')}>
            {t('Submit')}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showApprovedModal} size="bg"
        centered>
        <Modal.Header>
          <Modal.Title>{t('ClaimReconcilation.ApprovalComments')}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ "textAlign": 'center' }} >
          <Form.Control className={classes['Textarea-width']} as="textarea" rows={2} value={commentsForApproved} name="commentsForApproved"
            onChange={(e) => { setCommentsForApproved(e.target.value) }} />
        </Modal.Body>
        <Modal.Footer>
          <Button size='sm' style={{ background: '#eff1f9', color: 'black', borderColor: 'black', width: "50px" }} onClick={() => showApprovedDialog('showApprovedDialog')}>
            {t('Cancel')}
          </Button>
          <Button size='sm' style={{ background: '#eff1f9', color: 'black', borderColor: 'black', width: "55px" }} onClick={() => showApprovedDialog('proceed')}>
            {t('Approve')}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showDeniedModal} size="bg"
        centered>
        <Modal.Header>
          <Modal.Title>{t('ClaimReconcilation.DeniedComments')}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ "textAlign": 'center' }} >
          <Form.Control className={classes['Textarea-width']} as="textarea" rows={2} value={commentsForDenied} name="commentsForDenied"
            onChange={(e) => { setCommentsForDenied(e.target.value) }} />
        </Modal.Body>
        <Modal.Footer>
          <Button size='sm' style={{ background: '#eff1f9', color: 'black', borderColor: 'black', width: "50px" }} onClick={() => showDeniedDialog('showDeniedDialog')}>
            {t('Cancel')}
          </Button>
          <Button size='sm' style={{ background: '#eff1f9', color: 'black', borderColor: 'black', width: "40px" }} onClick={() => showDeniedDialog('proceed')}>
            {t('Deny')}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={errorPopup} size="sm"
        centered>
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ "textAlign": 'center' }} >
          <label>{t('ClaimReconcilation.ClaimReconcilationFailed')}</label>
        </Modal.Body>
        <Modal.Footer>
          <Button size='sm' style={{ background: '#eff1f9', color: 'black', borderColor: 'black', width: "45px" }} onClick={() => setErrorPopup(false)}>
            {t('Close')}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={submitErrorPopup} size="sm"
        centered>
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ "textAlign": 'center' }} >
          <p>{t('ClaimReconcilation.Validations.TheProductsWithAccruedAmountEqualToZeroWillNotBeSubmittedForApproval')}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button size='sm' style={{ background: '#eff1f9', color: 'black', borderColor: 'black', width: "30px" }} onClick={() => zeroClaimReconcilation()}>
            {t('Ok')}
          </Button>
        </Modal.Footer>
      </Modal>

      {
        showfilterpopup === true ?
          <div className='pop-up' style={{ left: divleft, top: divtop, width: "200px" }}>
            {filterCol === 'InvoiceNumber' || filterCol === "Status" || filterCol === "Region" ?
              <>
                <div className="container">
                  <div className="row">
                    <b className="forgettext col-md-11 textbox-padding ml-2">{filtertextPopup}</b>
                  </div>
                </div>
                <div className="container mt-2">
                  <div className="row">
                    <input type='textbox' placeholder={t('Search')} className='filtertextboxbackground col-md-11 textbox-padding ml-2' value={filterValueDict[filterCol]} onChange={(event) => filterInput1(event)}></input>
                  </div>
                </div>
              </>
              :
              <></>

            }

            {filterCol === 'TransactionDate' ?
              <>
                <div className="container">
                  <div className="row">
                    <b className="forgettext col-md-11 textbox-padding ml-2">{filtertextPopup}</b>
                  </div>
                </div>
                <div className="container mt-2">
                  <div className="row">
                    <input type='date' placeholder='Search...' className='filtertextboxbackground col-md-11 textbox-padding ml-2' value={filterValueDict[filterCol]} onChange={(event) => filterInput(event)}></input>
                  </div>
                </div>
              </>
              :
              <></>
            }

            {filterCol === 'IsExclude' ?

              <>
                <div className="container">
                  <div className="row">
                    <b className="forgettext col-md-11 textbox-padding ml-2">{filtertextPopup}</b>

                  </div>

                </div>

                <div className="container mt-2">
                  <div className="row">
                    <input type="text" placeholder="Search" value={filterSearchPopup} onChange={(event) => UserfilterHandlerPopup(event)} className='filtertextboxbackground col-md-11 textbox-padding ml-2' />
                  </div>

                  <div className="row contentoverflow mt-2">
                    <table className="filtertable">
                      <tbody>
                        <tr>
                          <td><input type="checkbox" name="filter-option-all" id="option-all" onChange={checkAllFilterPopup} /></td>
                          <td>Select All</td>
                        </tr>
                        {
                          dropdownData.map((item, index) => (

                            <tr key={index}>
                              <>
                                <td><input id={item.Id} data-index="1" name="filter-option-all" checked={item.ischecked} type="checkbox" onChange={(event) => OndropdownChange(event, item.Id, item.Name)} /></td>
                                <td>{item.Name}</td>
                              </>
                            </tr>
                          ))
                        }
                      </tbody>
                    </table>

                  </div>
                </div>
              </>
              :
              <></>
            }


            {filterCol === 'Quantity' || filterCol === 'Netprice' || filterCol === 'WeightedAverage' || filterCol === 'LineItem' || filterCol === 'Revenue' ?
              <div>
                <div className="row">
                  <div className="row" style={{ marginLeft: "40px", paddingBottom: "2px" }}>
                    <b>{filtertextPopup}</b>
                  </div>
                  <Select
                    className='col-md-9 textbox-padding ml-4 fontsizedropdown'
                    options={operatorValOption}
                    placeholder="Select"
                    value={popOperatorValue[filterCol]}
                    onChange={OnChangePopOperatorValue}
                    styles={customStyles}
                  />
                </div>
                <div className="mt-2">
                  <input type='text' className='filtertextboxbackground col-md-11 textbox-padding ml-2' onKeyPress={(e) => !/^(\d)*(\.)?([0-9]{1})?$/.test(e.key) && e.preventDefault()}
                    value={popFilterValue[filterCol]} onChange={(event) => numericFilterInput(event)} />
                </div>
              </div>
              :
              <></>
            }


            <div style={{ paddingTop: '10px', paddingLeft: '35px', paddingBottom: '7px' }}>

              <button className="btn btn-sm btn-update" style={{ width: "45px" }} onClick={() => filterHandler('Apply')}>{t('Apply')}</button>
              <button className="btn btn-sm btn-update" style={{ marginLeft: '10px', width: "50px" }} onClick={() => filterHandler('Reset')}>{t('Remove')}</button>
            </div>
            <div>
            </div>
          </div>
          :
          <></>
      }


      <Modal show={showWorkflowAlert}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Body style={{ "textAlign": 'center' }}>{t('Accruals.WorkflowAlert')}</Modal.Body>
        <Modal.Footer>
          <Button size='sm' style={{ background: '#eff1f9', color: 'black', borderColor: 'black', width: "30px" }} onClick={() => workflowAlert('close')}>
            {t('Ok')}
          </Button>
        </Modal.Footer>
      </Modal>


      {
        showfilterpopup1 === true ?
          <div className='pop-up' style={{ left: divleft1, top: divtop1, width: "200px" }}>
            {filterCol1 === 'ProductName' || filterCol1 === 'Exclude' ?

              <>
                <div className="container">
                  <div className="row">
                    <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>

                  </div>

                </div>

                <div className="container mt-2">
                  <div className="row">
                    <input type="text" placeholder="Search" value={filterSearch} onChange={(event) => UserfilterHandler(event)} className='filtertextboxbackground col-md-11 textbox-padding ml-2' />
                  </div>

                  <div className="row contentoverflow mt-2">
                    <table className="filtertable">
                      <tbody>
                        <tr>
                          <td><input type="checkbox" name="filter-option-all" id="option-all" onChange={checkAllFilter} /></td>
                          <td>Select All</td>
                        </tr>
                        {
                          dropdownData1.map((item, index) => (

                            <tr key={index}>
                              {filtertext === 'Material No' ?
                                <>
                                  <td><input id={item.Id} data-index="1" name="filter-option-all" checked={item.ischecked} type="checkbox" onChange={(event) => OndropdownChangeItems(event, item.Id, item.ProductName, "Product")} /></td>
                                  <td> {item.ProductName}</td>
                                </>
                                :
                                <>
                                  <td><input id={item.Id} data-index="1" name="filter-option-all" checked={item.ischecked} type="checkbox" onChange={(event) => OndropdownChangeItems(event, item.Id, item.Name, "Exclude")} /></td>
                                  <td>{item.Name}</td>
                                </>
                              }
                            </tr>
                          ))
                        }

                      </tbody>
                    </table>

                  </div>
                </div>
              </>
              :
              <></>
            }
            {filterCol1 === 'Quantity' || filterCol1 === 'ComparisonQuantity' || filterCol1 === 'ComparisonNetprice' || filterCol1 === 'Netprice' || filterCol1 === 'ApprovedClaimReconcilation' || filterCol1 === 'UnpostedClaimReconcilations' || filterCol1 === 'WeightedAveragePerUnit' || filterCol1 === 'TotalApprovedClaimReconcilation' || filterCol1 === 'DeltaReachNextTier' || filterCol1 === 'ClaimReconcilationRevenue' || filterCol1 === 'ExcludedAmount' || filterCol1 === 'TotalRevenue' ?
              <div>
                <div className="row">
                  <div className="row" style={{ marginLeft: "40px", paddingBottom: "2px" }}>
                    <b>{filtertext}</b>
                  </div>
                  <Select
                    className='col-md-9 textbox-padding ml-4 fontsizedropdown'
                    options={operatorValOption}
                    placeholder="Select"
                    value={operatorVal[filterCol1]}
                    onChange={OnChangeOperatorValue}
                    styles={customStyles}
                  />
                </div>
                <div className="mt-2">
                  <input type='text' className='filtertextboxbackground col-md-11 textbox-padding ml-2' onKeyPress={(e) => !/^(\d)*(\.)?([0-9]{1})?$/.test(e.key) && e.preventDefault()}
                    value={filterValue1[filterCol1]} onChange={(event) => filterInput1(event)} />
                </div>
              </div>
              :
              <></>
            }
            {filterCol1 === 'CurrentTier' || filterCol1 === 'Category1' || filterCol1 === 'ProductLabel' || filterCol1 === 'Customer' ?
              <>
                <div className="container">
                  <div className="row">
                    <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>
                  </div>
                </div>
                <div className="container mt-2">
                  <div className="row">
                    <input type='textbox' placeholder={t('Search')} className='filtertextboxbackground col-md-11 textbox-padding ml-2' value={filterValue1[filterCol1]} onChange={(event) => filterInput1(event)}></input>
                  </div>
                </div>
              </>
              :
              <></>
            }

            <div style={{ paddingTop: '10px', paddingLeft: '35px', paddingBottom: '7px' }}>
              <button className="btn btn-sm btn-update" style={{ width: "30px" }} onClick={() => filterHandlerItems('Apply')}>{t('Ok')}</button>
              <button className="btn btn-sm btn-update" style={{ marginLeft: '10px', width: "50px" }} onClick={() => filterHandlerItems('Reset')}>{t('Remove')}</button>
            </div>
            <div>
            </div>
          </div>
          :
          <></>
      }
      {showAddColumnPopup === true ?
        <div className='pop-up' style={{ left: divleft, top: divtop, width: "250px" }}>
          <div className="container">
            <div className="row">
              <Select
                className='col-md-10 textbox-padding fontsizedropdown'
                options={colVisibilityOption}
                placeholder="Select"
                value={colvisiOptionVal}
                onChange={OnChangeColVisibilityList}
                styles={customStyles}
              />
              <button id="createColumnListPopup" icon className="btn btn-update ml-1 pa-0" type="submit"
                style={{ float: "left", cursor: "pointer", padding: '0px', height: '28px', width: '26px' }}
                onClick={CreateColumnListPopup} title='Add New View'><i class="fa fa-plus" aria-hidden="true"></i></button>
            </div>
            <div className="row mb-2" style={{ textAlign: 'right' }}>
              <div className="col-md-10 mt-1 mr-1" style={{ padding: '0px', fontSize: '12px' }}>
                <input id="existingViewVal" data-index="1" name="filter-option-all" checked={existingViewVal} type="checkbox"
                  onChange={(event) => OnChangeexistingViewVal(event)} /> <b>Is Default</b>
              </div>
            </div>
            <div className="row">
              <table className='ml-2' style={{ fontSize: '0.80rem' }}>
                {
                  addRemoveCol.map((item, index) => (
                    <tr>
                      <td key={index}><input id={item.label} data-index="1" name="filter-option-all" checked={item.value} type="checkbox"
                        onChange={(event) => OnchangeAddRemoveCol(event, item)}
                      /> {t(item.label)}</td>
                    </tr>
                  ))
                }
              </table>
            </div>
            <div className="row mr-2" style={{ float: "right" }}>
              <div style={{ paddingTop: '10px', paddingLeft: '15px', paddingBottom: '7px' }}>
                <button className="btn btn-sm btn-update" style={{ width: "45px" }} onClick={() => updateColumnVisibilityList('reset')}>{t('Reset')}</button>
                <button className="btn btn-sm btn-update ml-1" style={{ width: "45px" }} onClick={closeAddRemoveColumnPopup}>{t('Close')}</button>
                <button className="btn btn-sm btn-update ml-1" style={{ width: "50px", background: '#4755ab', color: 'white' }} onClick={() => updateColumnVisibilityList('apply')} title='Update View'>{t('Update')}</button>
                <button className="btn btn-sm btn-update ml-1" style={{ width: "50px", background: 'rgb(233 43 0)', color: 'white' }} onClick={deleteColumnVisibilityView} title='Delete View'>{t('Delete')}</button>
              </div>
            </div>
          </div>
        </div>
        : <></>
      }

      {showCreateColumnListPopup === true ?
        <div className='pop-up' style={{ left: divleft1, top: divtop1, width: "190px" }}>
          <div className="container">
            <div className="row">
              <div className="col-12 ml-2" style={{ padding: '0px' }}>
                <input type='textbox' placeholder='View Name' style={{ height: '30px' }}
                  value={colViewName} name={colViewName} onChange={(event) => OnChangeSetViewName(event)}></input>
              </div>
            </div>
            <div className="row mb-2" style={{ textAlign: 'right' }}>
              <div className="col-10 mt-2" style={{ padding: '0px', fontSize: '12px' }}>
                <input id="defaultView" data-index="1" name="filter-option-all" checked={defaultView} type="checkbox"
                  onChange={(event) => OnChangedefaultView(event)} /> <b>Set Default</b>
              </div>
            </div>
            <div className="row">
              <table className='ml-2' style={{ fontSize: '0.80rem' }}>
                {
                  createColumnListOption.map((item, index) => (
                    <tr>
                      <td key={index}><input id={item.label} data-index="1" name="filter-option-all" checked={item.value} type="checkbox"
                        onChange={(event) => OnchangeCreateColumnListOption(event, item)}
                      /> {t(item.label)}</td>
                    </tr>
                  ))
                }
              </table>
            </div>
            <div className="row mr-2" style={{ float: "right" }}>
              <div style={{ paddingTop: '10px', paddingLeft: '35px', paddingBottom: '7px' }}>
                <button className="btn btn-sm btn-update ml-1" style={{ width: "50px" }} onClick={CloseCreateColumnListPopup}>{t('Cancel')}</button>
                <button className="btn btn-sm btn-update ml-1" style={{ width: "40px", background: '#4755ab', color: 'white' }} onClick={() => CreateColumnVisibilityList('apply')}>{t('Save')}</button>
              </div>
            </div>
          </div>


        </div>
        : <></>
      }

      <ToastContainer autoClose={1000} />


    </div >

  )

}

export default CreateClaimReconcilationComponent
