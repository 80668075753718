import CreateClaimAgreementComponent from "../../../Components/RebateProgramComponent/CreateClaimAgreemnetComponent/CreateClaimAgreementComponent";
import React, { Component } from 'react' ;
import { useNavigate ,useParams } from 'react-router-dom';

function CreateClaimAgreement(props){
    let navigate = useNavigate();
    const params = useParams();

    return (  
        <div> 
            <CreateClaimAgreementComponent {...props}  navigate={navigate} id={params.id} name={params.name} value={params.value}/>
        </div>
    );  
}

export default CreateClaimAgreement