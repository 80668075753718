class SessionManage{
    // Userid set or get 
    async setuserid(value) {
        sessionStorage.setItem('id', value);    
    }
    getuserid() {
        const userid = sessionStorage.getItem("id");
        return userid;
    }
    // Username set or get 
    async setusername(value) {
        sessionStorage.setItem('name', value);
    }
    getusername() {
        const username = sessionStorage.getItem("name");
        return username;
    }

    //token id 
    async settokenid(value) {
        sessionStorage.setItem('token', value);
    }
    gettokenid() {
        const username = sessionStorage.getItem("token");
        return username;
    }

    // ROle id 
    async setroleid(value) {
        sessionStorage.setItem('roleid', value);
    }
    getroleid() {
        const username = sessionStorage.getItem("roleid");
        return username;
    }

    async setgroupid(value) {
        sessionStorage.setItem('groupids', value);
    }
    getgroupid() {
        const username = sessionStorage.getItem("groupids");
        return username;
    }

    // roundingPrecision set or get 
    async setroundingPrecision(value) {
        sessionStorage.setItem('roundingPrecision', value);
    }
    getroundingPrecision() {
        const username = sessionStorage.getItem("roundingPrecision");
        return username;
    }

    async setdateformat(value) {
        sessionStorage.setItem('dateformat', value);
    }
    getdateformat() {
        let username = sessionStorage.getItem("dateformat");
        if(username == null || username == 'Select'){
            username =  "DD/MM/YYYY";
        }
        return username;
    }

    async setFiscalEndDate(value) {
        sessionStorage.setItem('FiscalEndDate', value);
    }
    getFiscalEndDate() {
        let FiscalEndDate = sessionStorage.getItem("FiscalEndDate");
         
        return FiscalEndDate;
    }
    async setLocale(value) {
        sessionStorage.setItem('Locale', value);
    }
    getLocale() {
        let Locale = sessionStorage.getItem("Locale");
         
        return Locale;
    }

    async setLanguages(value) {
        sessionStorage.setItem('Languages', value);
    }
    getLanguages() {
        let Languages = sessionStorage.getItem("Languages");
         
        return Languages;
    }

    
    
    getcurrentdateformat() {
        let username = sessionStorage.getItem("dateformat").toUpperCase();
        if(username == null || username == 'Select'){
            username =  "DD/MM/YYYY";
        }
        return username;
    }
     extractDateFromTimestamp(timestamp) {
         const dateObj = new Date(timestamp);
         const year = dateObj.getUTCFullYear();
         const month = String(dateObj.getUTCMonth() + 1).padStart(2, '0');
         const day = String(dateObj.getUTCDate()).padStart(2, '0');
         return `${day}/${month}/${year}`;
        
       }

    async setAccrualCalc(value) {
        sessionStorage.setItem('AccrualCalc', value);
    }
    getAccrualCalc() {
        const username = sessionStorage.getItem("AccrualCalc");
        return username;
    }

    async setComparisonPeriod(value) {
        sessionStorage.setItem('ComparisonPeriod', value);
    }
    getComparisonPeriod() {
        const username = sessionStorage.getItem("ComparisonPeriod");
        return username;
    }

    async setdefaultCurrency(value) {
        sessionStorage.setItem('defaultCurrency', value);
    }
    getdefaultCurrency() {
        const username = sessionStorage.getItem("defaultCurrency");
        return username;
    }

    async setdefaultCurrency(value) {
        sessionStorage.setItem('defaultCurrency', value);
    }
    getdefaultCurrency() {
        const username = sessionStorage.getItem("defaultCurrency");
        return username;
    }

    async setfiscalstartmonth(value) {
        sessionStorage.setItem('fiscalstartmonth', value);
    }
    getfiscalstartmonth() {
        const username = sessionStorage.getItem("fiscalstartmonth");
        return username;
    }

    async setfiscalendmonth(value) {
        sessionStorage.setItem('fiscalendmonth', value);
    }
    getfiscalendmonth() {
        const username = sessionStorage.getItem("fiscalendmonth");
        return username;
    }

     async setidletimeout(value) {
        sessionStorage.setItem('idletimeout', value);
    }
    getidletimeout() {
        const username = sessionStorage.getItem("idletimeout");
        return username;
    }
 async setIncludeOutstandingAmount(value) {
        sessionStorage.setItem('includeOutstandingAmount', value);
    }
    getIncludeOutstandingAmount() {
        const includeOutstandingAmount = sessionStorage.getItem("includeOutstandingAmount");
        return includeOutstandingAmount;
    }
 async setIncludeSubDivideTarget(value) {
        sessionStorage.setItem('includeSubDivideTarget', value);

    }
    getIncludeSubDivideTarget() {
        const includeSubDivideTarget = sessionStorage.getItem("includeSubDivideTarget");
        return includeSubDivideTarget;
    }

async setAccrualThresholdCriteria(value) {
        sessionStorage.setItem('accrualThresholdCriteria', value);

    }
    getAccrualThresholdCriteria() {
        const accrualThresholdCriteria = sessionStorage.getItem("accrualThresholdCriteria");
        return accrualThresholdCriteria;
    }

     async setUsersType(value) {
            sessionStorage.setItem('UsersType', value);
        }
        getUsersType() {
            const usersType = sessionStorage.getItem("UsersType");
            return usersType;
        }
}
export default new SessionManage();