import { text } from "@fortawesome/fontawesome-svg-core";
import { React, useEffect, useState } from "react";
import CheckboxTree from "react-checkbox-tree";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import { CSVLink, CSVDownload } from "react-csv";
import Service from "../../../Services/Service";
import SessionManage from "../../../SessionManagement/SessionManage";
import moment from 'moment'
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
function SDWorkflowApprovers(props) {
    const [workflowchart, setWorkflowchart] = useState([]);
    const [workflowData, setWorkflowData] = useState([]);
    const [t, i18n] = useTranslation('translation');
    const _ = require('lodash');
    useEffect(() => {

        Service.getdatabyId('SDAgreement/GetWorkflowAndApprovers', props.id).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                   
                    const sortdata = _.orderBy(res.data.wlst, ['Workflowlevel'], ['asc'])
                   
                    setWorkflowchart(sortdata);
                    setWorkflowData(res.data.workflowlst);
                
                }
            }
        });
    });
    return (
        <div className="card-body" style={{ minHeight: "55vh" }}>
         
            {workflowchart.length > 0 ?
                <div className='col-md-12'>
                    <table className="table">
                        <thead>
                            <tr>
                                <th className='workflowchart-levelwidth workflowchart-font'>{t('Rebates.Level')}</th>
                                <th className='font-center workflowchart-font'>{t('Rebates.Users')}</th>
                                <th className='font-center workflowchart-font'>{t('Rebates.Approval')}</th>
                            </tr>
                        </thead>

                        <tbody>
                            {
                                workflowchart.map((i) =>
                                    <tr key={i.Id}>
                                        <td className='workflowchart-font square-levels'>{i.Levels}</td>
                                        <td className='font-center'>
                                            <ul >
                                                {
                                                    i.lstworkflowapprover.map((j, index) => {
                                                        return <>

                                                            {
                                                                index < 2 ?
                                                                    j.IsApproved === true || j.IsDenied == true ?
                                                                        <li className='workflowchartperson'>
                                                                            <span className='workflowtpersoncolor'><i class="fa fa-user-circle-o" aria-hidden="true"></i>
                                                                                <br></br> {j.FullName}</span>
                                                                        </li>
                                                                        :
                                                                        <>
                                                                            <li className='workflowchartperson'>
                                                                                <i class="fa fa-user-circle-o" aria-hidden="true"></i>
                                                                                <br></br> {j.FullName}
                                                                            </li>
                                                                        </>
                                                                    :
                                                                    ''
                                                            }
                                                        </>

                                                    })
                                                }

                                                {
                                                    i.lstworkflowapprover.length > 2 ?
                                                        <li className='workflowchartperson tooltip_workflow'>
                                                            <i class="fa fa-user-circle-o" aria-hidden="true"></i>
                                                            <br></br> {t('ship&debit.MoreUsers')}
                                                            <span class="tooltiptext">

                                                                {
                                                                    i.lstworkflowapprover.map((k, index) => {
                                                                        return <span>
                                                                            {
                                                                                index > 1 ? <><i class="fa fa-user-circle-o" aria-hidden="true"></i>
                                                                                    &nbsp; {k.FullName}
                                                                                    <hr></hr></> : null
                                                                            }


                                                                        </span>
                                                                    })
                                                                }

                                                            </span>
                                                        </li>
                                                        :
                                                        ''
                                                }


                                            </ul>

                                        </td>

                                        <td className='font-center'>
                                            {
                                                i.IsApproved === true ?
                                                    <span className='workflowrightcolor'>
                                                        <i class="fa fa-check" aria-hidden="true"></i>
                                                    </span>

                                                    : i.IsDenied == true ?
                                                        <span className='workflowrightcolor'>
                                                            <i class="fa fa-times" aria-hidden="true" style={{ color: "red" }}></i>
                                                        </span>
                                                        : ''
                                            }
                                        </td>
                                    </tr>)

                            }
                        </tbody>

                    </table>

                </div>
                :
                <div className="'">

                </div>
            }

            <div className="row">
                <div className="col-md-12">
                    <table className="table table-bordered text-left mt-2">
                        <thead className='text-white' style={{ backgroundColor: "var(--primary)" }}>
                            <tr>

                                <th>{t('Rebates.Event')}</th>
                                <th>{t('Rebates.CreatedDate')}</th>
                                <th>{t('Rebates.User')}</th>
                                <th>{t('Rebates.Comments')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {

                                workflowData.length === 0 ?
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td>
                                            {t('NoDataAvailable')}
                                        </td> <td></td></tr>
                                    :
                                    workflowData === '' || workflowData === 'undefined' ?
                                        <tr></tr> :
                                        workflowData.map((i) =>
                                            <tr key={i.id}>
                                                <td>{i.EventName}</td>
                                                {i.CreatedDate === null ? <td></td> : <td>{moment(i.CreatedDate).format(SessionManage.getcurrentdateformat())}</td>}
                                                <td>{i.CreateUserName}</td>
                                                <td>{i.Comments}</td>
                                            </tr>)

                            }

                        </tbody>
                    </table>
                </div>

            </div>


        </div>

    );
}

export default SDWorkflowApprovers;
