import $ from 'jquery';
import React, { useRef, useState, forwardRef, useEffect } from 'react';
import { Form, Dropdown, Button, InputGroup, FormControl, Modal } from 'react-bootstrap';
import Service from '../../../Services/Service';
import Validation from '../../../Services/Validation';
import classes from '../../RebateProgramComponent/CreateRebateComponent/Form.module.css'
import { Link, Navigate, useLocation } from 'react-router-dom';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import Multiselect from 'multiselect-react-dropdown';
import { CSVLink } from "react-csv";
import SessionManage from '../../../SessionManagement/SessionManage';
import Select from "react-select";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css'
import dateFormat from 'dateformat';
import CustomErrorPopup from '../../../UI/CustomErrorPopup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import WorkflowApprovers from './WorkflowApprovers';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ClaimWorkflowApprovers from '../../SDClaimComponent/CreateSDClaim/WorkflowApprovers';
import { useTranslation } from "react-i18next";


function NewSpecialPriceAgreementComponent(props) {
    const location = useLocation();
    const [userPerms, setuserPermission] = useState([]);
    const [editPerms, setEditPerms] = useState([]);
    var currentDate = new Date();
    var year = currentDate.getFullYear();
    var month = currentDate.getMonth();
    var day = currentDate.getDate();
    const [errorPopup, setErrorPopup] = useState(false)
    const [settlementMethodsList, setSettlementMethodsList] = useState([]);
    const [distributorList, setDistributorList] = useState([]);
    const [endCustomersList, setEndCustomersList] = useState([]);
    const [currencyList, setCurrencyList] = useState([]);
    const [showspinner, setshowspinner] = useState('');
    const [sticky, setSticky] = useState("");
    const [divtop, setdivtop] = useState('');
    const [productList, setProductList] = useState([]);
    const [contractTypeList, setContractTypeList] = useState([]);
    const [defaultCurrency, setDefaultCurrency] = useState('');
    const [defaultPrefix, setDefaultPrefix] = useState('')
    const [defaultStatus, setDefaultStatus] = useState('');
    const [contractQtyConditionList, setContractQtyConditionList] = useState([]);
    const [statusList, setStatusList] = useState([]);
    const [isLocked, setIsLocked] = useState(false);
    const [lockedBy, setLockedBy] = useState('');
    const [loadedFrom, setLoadedFrom] = useState('')
    const [showShipWorkflow, setshowShipWorkflow] = useState('');
    const [sidebarwidth, setsidebarwidth] = useState('');
    const [showWorkflow, setshowWorkflow] = useState('');
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [errorStatus, setErrorStatus] = useState("Error");
    const [showSubmitModal, setShowSubmitModal] = useState(false)
    const [commentsForApproved, setCommentsForApproved] = useState("")
    const [showDeniedModal, setShowDeniedModal] = useState(false)
    const [showApprovedModal, setShowApprovedModal] = useState(false)
    const [commentsForDenied, setCommentsForDenied] = useState("")
    const [contractTypeValue, setContractTypeValue] = useState("")
    const [loading, setLoading] = useState(false);
    const [errorProduct, setErrorProduct] = useState('');
    const [edit, setEdit] = useState(false);
    const [totalselectedshow, settotalselectedshow] = useState('');
    const [totalselectedcount, settotalselectedcount] = useState(0);
    const [claimData, setClaimData] = useState([]);
    const [showLoadMore, setshowLoadMore] = useState('');
    const [totalCountlst, settotalCountlst] = useState(0);
    const [columnAscending, setColumnAscending] = useState(true);
    const [filteringvalue, setfilteringvalue] = useState('');
    const [checkedValue, setCheckedValue] = useState([]);
    const [checkedValueStatus, setCheckedValueStatus] = useState([]);
    const [checkedValueid, setCheckedValueid] = useState([]);
    const [divleft, setdivleft] = useState('');
    const [sortColName, setSortColName] = useState("");
    const [searchValue, setSearchValue] = useState("")
    const [filterCol, setFilterCol] = useState('');
    const [dropdownData, setDropdownData] = useState([]);
    const [showfilterpopup, setshowfilterpopup] = useState(false);
    const [dropdownChange, setDropdownChange] = useState([]);
    const [filterValue, setFilterValue] = useState([]);
    const [showClaimWorkflow, setshowClaimWorkflow] = useState('');
    const [t, i18n] = useTranslation('translation');
    const [filterSearch, setFilterSearch] = useState("");
    const [filterdropdownData, setFilterDropdownData] = useState([]);
    const [filtertext, setfiltertext] = useState('');
    const [filterValueDict, setFilterValueDict] = useState({
        claim_id: '',
        desc: '',
        status: '',
        amount: '',
        last_modified_date: '',

    });
    const [multiFilterValue, setMultiFilterValue] = useState({
        modifiedby: '',
    });

    const [paging, setpaging] = useState({
        pageSize: 20,
        pageNumber: 1
    })


    const navigate = useNavigate();
    const [shipList, setShipList] = useState([{
        lineId: 0, ProductId: "", Currency: "", Listprice: "", ContractTypeId: "", ContractTypeSymbol: "", ContractPrice: "", ContractListPrice: "", ContractQtyCondId: "", ContractQty: "", AgreementItemId: "", Id: 0, IsActive: true, contractTypeValue: ""
    },]);

    const [inputField, setInputField] = useState({
        Id: 0, AgreementId: '', AgreementDesc: '', DistributorId: '', EndcustomerId: '', ValidityFrom: new Date(), ValidityTo: (new Date(year + 1, month, day)), SettlementMethodId: '', CurrencyId: '', StatusId: 0, Status: '', AutoPostAccruals: false, LastModifiedUserId: '', CreatedUserId: '', CreatedDate: '', CreatedUserName: '', LockedById: 0, LastModifiedDate: '', Deleted: false, RevisionOf: '', CopyOf: '', Locked: true, specialagreementItemsList: []
    })

    const inputsHandler = (e) => {
        const { name, value } = e.target;
        setInputField((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        var f = inputField;
    }
    const OnChangeHandlerDate = (e, name) => {
        if (e) {
            if (name === 'ValidityFrom')
                setInputField({ ...inputField, ValidityFrom: e })
            if (name === 'ValidityTo')
                setInputField({ ...inputField, ValidityTo: e })
        }
    };

    const OndropdownChange = (e, name) => {
       
        setInputField({ ...inputField, [name]: e.value });

        if (name === 'CurrencyId') {
            setDefaultCurrency(e.label);
            setDefaultPrefix(e.Prefix);

        }
        if (name === 'DistributorId') {
            Service.getdatabyId('SDCommon/GetAllEndCustomerByDistributor', e.value).then((res) => {
                if (typeof res !== 'undefined') {
                    if (typeof res.data !== 'undefined' && res.data !== 'null') {
                        if (res.data.length > 0) {
                            setEndCustomersList(res.data);
                        } else {
                            setEndCustomersList(res.data);
                        }
                    }
                }
            });
        }
    };
    useEffect(() => {

        if (typeof props.id === 'undefined' || props.id === '0')
            getUserPermission('Create Special Price Agreement');
        else if (typeof props.id !== 'undefined' && typeof props.name !== 'undefined' && props.id != '0' && (props.name.toLowerCase() == 'fromapproval' || props.name.toLowerCase() == 'fromapprovalcompleted'))
            getUserPermission('Special Price Agreement Workflow');
        else if (typeof props.id !== 'undefined' && props.id != '0') {
            getUserPermission('Create Special Price Agreement');
            getClaims('SpecialAgreement/GetClaimBySAId', props.id, paging.pageSize, paging.pageNumber, "Descending", "Id", [], null);
        }

        if (typeof props.name !== 'undefined')
            setLoadedFrom(props.name.toLowerCase())
        getdataByGroup("SDCommon/GetAllDistCustCurrencySettlementProduct");
        if (typeof props.id !== 'undefined' && props.id > 0)
            setEdit(true);
        setSortColName("Id");

    }, [])
    const getdataByGroup = (method) => {
        Service.getdataByGroupAndType(method, "specialprice").then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined') {
                    if (typeof res.data.settlementMethodsList !== 'undefined')
                        setSettlementMethodsList(res.data.settlementMethodsList);
                    if (typeof res.data.distributorList !== 'undefined')
                        setDistributorList(res.data.distributorList);
                   
                    if (typeof res.data.currencyList !== 'undefined')
                        setCurrencyList(res.data.currencyList);
                    if (typeof res.data.productList !== 'undefined')
                        setProductList(res.data.productList);
                    if (typeof res.data.contractTypeList !== 'undefined')
                        setContractTypeList(res.data.contractTypeList);
                    if (typeof res.data.contractQtyConditionList !== 'undefined')
                        setContractQtyConditionList(res.data.contractQtyConditionList);
                    if (typeof res.data.statusList !== 'undefined')
                        setStatusList(res.data.statusList);
                    if (typeof props.id != 'undefined' && props.id != 0)
                        getUserDatabyId("SpecialAgreement/GetById", props.id);
                    else {
                       
                        var NewItem = res.data.statusList.filter((item) => item.label === 'New');
                        if (NewItem.length > 0) {
                            setInputField((prevState) => ({
                                ...prevState,
                                ["StatusId"]: NewItem[0].value,
                            }));
                            setInputField((prevState) => ({
                                ...prevState,
                                ["Status"]: NewItem[0].label,
                            }));

                        }
                    }
                }
            }
        });
    }
    const getUserDatabyId = (method, id) => {
        Service.getdatabyIdUser(method, id).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    var data = res.data;
                    setInputField(data);
                    setDefaultCurrency(data.CurrencyName);
                    setDefaultPrefix(data.Prefix);
                    setIsLocked(res.data.Locked);
                    setShipList(res.data.specialagreementItemsList);
                    setEndCustomersList(res.data.endCustomerList);
                  
                    if (data.IsApproveCurrentUser > 0)

                        setEditPerms(false);

                }
            }
        });
    }
    const getUserPermission = (pathname) => {
        Service.GetUserPermissionNew(pathname).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null' && res.data !== '') {
                    if (typeof res.data.DataList !== 'undefined') {
                        var permission = res.data.DataList;
                        setuserPermission(permission);
                        if (typeof permission !== 'undefined') {
                            if (permission.filter((item) => item.ActionName === 'Edit' || item.ActionName === 'Submit').length == 0) {
                                setEditPerms(false);
                            } else {
                                setEditPerms(true);
                            }
                        }
                        else {
                            setEditPerms(false);
                        }
                    }
                }
            }
        });
    }
    const clickShowShipWorkflow = () => {
        setshowShipWorkflow(true);
        setsidebarwidth("70vw");
        setshowWorkflow(true);

    }
    const clickCloseShipWorkflow = () => {
        setshowShipWorkflow(false);
    }

    const closeShipAgreement = () => {
        navigate("/specialprice")
    }
    const closeApproveAgreement = () => {
        navigate("/specialprice/approvals/pending")
    }
    const closeApproveCompleteAgreement = () => {
        navigate("/specialprice/approvals/archive")
    }

    const AddVolume = () => {
        const _item = {
            lineId: 0, ProductId: "", Currency: "", Listprice: "", ContractTypeId: "", ContractTypeSymbol: "", ContractPrice: "", ContractListPrice: "", ContractQtyCondId: "", ContractQty: "", AgreementItemId: "", Id: 0, IsActive: true
        };
        setShipList([...shipList, _item]);
    }

    const SaveHandler = (e, Status) => {
        e.preventDefault();
        let isValid = Validation.NewSpecialPrice(inputField, shipList);
        if (isValid != '') {
            setErrorMessage(isValid);
            setErrorStatus("Error")
            setShowErrorModal(true);
            return false;
        }
        else if (isValid === '') {
            setErrorProduct('');
           
            setLoading(true);
            let id = SessionManage.getuserid();
            inputField.CreatedUserId = id;
            inputField.LastModifiedUserId = id;
            inputField.LockedById = id;
            let today = moment(new Date());
            inputField.CreatedDate = today;
            inputField.LastModifiedDate = today;
            inputField.Status = Status;
            inputField.specialagreementItemsList = shipList;
            var HeaderData = inputField;

            Service.createorupdate("SpecialAgreement/CreateOrUpdate", HeaderData).then(res => {
                setLoading(false);
                if (typeof res !== 'undefined') {

                    if (res.data === '') {
                     
                        toast.success('Agreement has created successfully !', {
                            position: toast.POSITION.TOP_RIGHT,
                            onClose: () => navigate("/specialprice")
                        });

                    }
                    else {
                        setErrorProduct(res.data);
                        setErrorPopup(true);
                    }
                }
            });
        } else {
            setLoading(false);
            setErrorStatus("Error")
            setShowErrorModal(true);
            return false;
        }
        inputField.Status = "new";
    }

    const handleInputChange = (e, index, names, selectedName) => {
        const name = names;
        const value = e.value;
        const list = [...shipList];
        list[index][name] = value;
      
        if (names === 'ProductId') {
            Service.getdatabyId('SDCommon/GetListPriceProduct', value).then((res) => {
                

                if (typeof res !== 'undefined') {
                    if (typeof res.data !== 'undefined' && res.data !== 'null') {
                       
                        var price = res.data.label;
                        list[index]["Listprice"] = price == "" ? "" : parseFloat(price).toFixed(2);
                        list[index]["ContractListPrice"] = price == "" ? "" : parseFloat(price).toFixed(2);

                       
                    }
                }
            });
        }
        if (names === 'ContractTypeId') {
            if (e.label == 'Negotiated Invoice')
                list[index]["ContractTypeSymbol"] = '$';


        }
        setShipList(list);
    }

    const handleInputTextChange = (e, index, names) => {
        const {
            name,
            value
        } = e.target;
        const list = [...shipList];
        list[index][names] = value;
        setShipList(list);
    }
    const RemoveItem = (idx) => {
        setShipList(shipList.filter((item, index) => index !== idx));
    }
    const ToggleActiveStatus = (id, Active) => {

    }
    const showApprovedDialog = (flag) => {
        if (flag === 'showApprovedDialog') {
            setCommentsForApproved("")
            setShowApprovedModal(!showApprovedModal)
        }
        else if (flag === 'proceed') {
            Approve()
            setShowApprovedModal(!showApprovedModal)

        }
    }

    const showDeniedDialog = (flag) => {
        if (flag === 'showDeniedDialog') {
            setCommentsForDenied("")
            setShowDeniedModal(!showDeniedModal)
        }
        else if (flag === 'proceed') {
            Deny()
            setShowDeniedModal(!showDeniedModal)

        }
    }
    const Deny = () => {
        let AgreementId = props.id
        let Comments = commentsForApproved
        let UserId = SessionManage.getuserid();
        let Status = 'Approved'
        let data = { AgreementId, Comments, UserId, Status }
        setLoading(true);
        Service.createorupdate('SpecialAgreement/DenyAgreement', data).then((res) => {

            if (typeof res !== 'undefined' && typeof res.status !== 'undefined') {
                if (res.status === 200) {
                    setLoading(false);
                    toast.success('Agreement deny successfully !', {
                        position: toast.POSITION.TOP_RIGHT,
                        onClose: () => navigate("/specialprice/approvals/pending")
                    });

                }
            }
            else if (res.status !== 200) {
                setErrorPopup(true)
            }
        });
    }
    const Approve = () => {
        let AgreementId = props.id
        let Comments = commentsForApproved
        let UserId = SessionManage.getuserid();
        let Status = 'Approved'
        let data = { AgreementId, Comments, UserId, Status }
        setLoading(true);
        Service.createorupdate('SpecialAgreement/UpdateSpecialAgreementStatus', data).then((res) => {
            if (typeof res !== 'undefined' && typeof res.status !== 'undefined') {

                setLoading(false);
                if (res.status === 200) {
                    toast.success('Agreement approved successfully !', {
                        position: toast.POSITION.TOP_RIGHT,
                        onClose: () => navigate("/specialprice/approvals/pending")
                    });

                }
            }
            else if (res.status !== 200) {
                setErrorPopup(true)
            }
        });
    }
    const DeleteItem = (indexs, id) => {
        setLoading(true);
        setShipList(shipList.filter((item, index) => index !== indexs));
        Service.getdatabyIdUser('SpecialAgreement/DeleteSpecialAgreementItem', id).then((res) => {
            if (typeof res !== 'undefined' && typeof res.status !== 'undefined') {
                if (res.status === 200) {
                    setLoading(false);
                    toast.err('Agreement item deleted successfully !', {
                        position: toast.POSITION.TOP_RIGHT,

                    });
                }
            }

        });
    }


    const checkAll = (mycheckbox) => {
        var checkboxes = document.querySelectorAll("input[name = 'chkBox']");
        settotalselectedshow(true);
        if (mycheckbox.target.checked == true) {
            checkboxes.forEach(function (checkbox) {
                checkbox.checked = true;
            });
            settotalselectedcount(checkboxes.length)
        }
        else {
            checkboxes.forEach(function (checkbox) {
                checkbox.checked = false;
            });
            settotalselectedcount(0)
        }

    }

    const isSticky = () => {
        try {
            const scrollTop = window.scrollY;
            const stickyClass = scrollTop >= 60 ? "is-sticky-thead" : "";
            setSticky(stickyClass);
            const scrollTopActual = scrollTop >= 60 ? scrollTop - 85 : 0;
            var divtop1 = document.getElementById("table-RPL");
            setdivtop(divtop1.offsetTop + scrollTopActual)
        } catch { }
    };

    const class_thead = `text-white rt-thead  ${sticky}`;
    const disabledClass_dropdown = `  ${editPerms == false ? "col-md-6 textbox-padding-dropdown mt-2 fontsizedropdown disabledClass" : loadedFrom == "fromapproval" ? "col-md-6 textbox-padding-dropdown mt-2 fontsizedropdown disabledClass" : inputField.Status.toLowerCase() == 'new' ? "col-md-6 textbox-padding-dropdown mt-2 fontsizedropdown" : "col-md-6 textbox-padding-dropdown mt-2 fontsizedropdown disabledClass"}`;
    const disabledClass = `  ${editPerms == false ? "disabledClass " : loadedFrom == "fromapproval" ? "disabledClass " : inputField.Status.toLowerCase() == 'new' ? "" : "disabledClass"}`;

    const handleChange = (e, id, status, name) => {
        let isChecked = e.target.checked;   // true if it is checked false if unchecked

        let checkedBoxes = document.querySelectorAll('input[name=chkBox]:checked');
        if (isChecked === true) {
            settotalselectedshow(true);
            settotalselectedcount(checkedBoxes.length);
            setCheckedValue(id)  // check box value - id
            setCheckedValueStatus(status) // check box value - status
            setCheckedValueid(name) //check box value - RA-0000
        }
        else {
            if (checkedBoxes.length === 0) {
                settotalselectedshow(false);
            }

            settotalselectedcount(checkedBoxes.length);
            setCheckedValue([])  // check box value - id
            setCheckedValueStatus([]) // check box value - status
            setCheckedValueid([]) //check box value - RA-0000
        }
    }

    const handleLoadMore = () => {

        let pagenumber = paging.pageNumber + 1;
        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
        Service.getdatabyId("SpecialAgreement/GetClaimBySAId", paging.pageSize, pagenumber, sortType, sortColName, filterLst(), searchValue).then((res) => {

            if (typeof res !== 'undefined') {
                if (res.data !== 'null' && res.data !== 'undefined') {
                    let rowData = res.data.DataList;
                    if (res.data.DataList !== 'null' && res.data.DataList !== 'undefined') {
                        const currentlength = claimData.length;
                        setClaimData(claimData => [...claimData, ...res.data.DataList])
                        LoadMore(currentlength + rowData.length, res.data.Totalcount)
                    }

                }
            }
        });


        paging.pageNumber = pagenumber
        setpaging(paging);
    };
    const customMultiStyles = {
        multiselectContainer: {
            maxWidth: '170px',
            marginLeft: '3px'
        },
        chips: {
            borderRadius: '5px'
        }
    };

    const addnewfield = (data, key) => {
        let selectedKeys = multiFilterValue[key];
        let currentdata = data.map((i) => {
            i["ischecked"] = false;
            return i;
        });
        if (key != 'status') {
            if (selectedKeys != '') {
                currentdata.forEach((each) => {
                    selectedKeys.forEach((eachSelected) => {
                        if (each.Name == eachSelected.Name) {
                            each.ischecked = true
                        }
                    })
                })
            }
         
            setDropdownData(currentdata);
            setFilterDropdownData(currentdata)
        }
        else {
            if (selectedKeys != '') {
                currentdata.forEach((each) => {
                    selectedKeys.forEach((eachSelected) => {
                        if (each.Name == eachSelected.Name) {
                            each.ischecked = true
                        }
                    })
                })
                setDropdownData(currentdata)
                setFilterDropdownData(currentdata)
            }
            else {
                setDropdownData(currentdata)
                setFilterDropdownData(currentdata)
            }
        }
    }


    const checkAllFilter = (mycheckbox) => {
        var lastfilter = [...dropdownData]
        let data1 = []
        var checkboxes = document.querySelectorAll("input[name = 'filter-option-all']");
        if (mycheckbox.target.checked == true) {
           
            let currentdata = lastfilter.map((i) => {
                i["ischecked"] = true;
                return i;
            });
            setDropdownData(currentdata)
   
            dropdownData.map((each) => {
             
                const getallData = {
                    Name: each.Name,
                }
                data1.push(getallData)
            })
            setFilterValue(filterValue => [...filterValue, ...data1]);
            let updateddata = ([...filterValue, ...data1]);
            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: updateddata,
            }));
        }
        else {
           
            let currentdata = lastfilter.map((i) => {
                i["ischecked"] = false;
                return i;
            });
            setFilterValue([])
            setMultiFilterValue([])
        }

    }

    const OndropdownPopup = (e, id, name) => {
        let isChecked = e.target.checked;
        var lastfilter = [...dropdownData]
        if (isChecked === true) {
            let currentdata = lastfilter.map((i) => {
                if (i.Id == id) {
                    i["ischecked"] = true;
                }
                return i;
            });
        
            setDropdownData(currentdata);
            let data1 = []
            const getallData = {
                Name: name,
            }
            data1.push(getallData)
            setFilterValue(filterValue => [...filterValue, ...data1]);
            let updateddata = ([...filterValue, ...data1]);
            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: updateddata,
            }));
        }
        else {
            let currentdata = lastfilter.map((i) => {
                if (i.Id === id) {
                    i["ischecked"] = false;
                }
                return i;
            });
       
            setDropdownData(currentdata);
            setFilterValue(filterValue => filterValue.filter(data => data.Name !== name))
            let data1 = multiFilterValue.filter(data => data.Name !== name);
            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: data1,
            }));
        }
    }
    const UserfilterHandler = (e) => {
      
        setFilterSearch(e.target.value)
        if (e.target.value !== "") {
            let searchResults = dropdownData.filter((each) => {
                return each.Name.toLowerCase().includes(String(e.target.value).toLowerCase())
            });
            setDropdownData(searchResults);
        }
        else {
    
            setFilterSearch("")
            setDropdownData(filterdropdownData)
        }
    }

    const filterInput = (e) => {
        let inputValue = []
        setfilteringvalue(e.target.value)
        setFilterValueDict(() => ({
            ...filterValueDict, [filterCol]: e.target.value,
        }));
    }

    const FilterColumn = (name) => {

        var divtop1 = document.getElementById("table-RPL");
        var cardtop1 = document.getElementById("card-RPL");
        setdivtop(divtop1.offsetTop + cardtop1.offsetTop + 310)
        setFilterCol(name)
        setshowfilterpopup(true)

        if (name === 'claim_id') {
            setfiltertext(t('sdClaim.ClaimId'))
            var testDiv = document.getElementById("Claim_id");
            setdivleft((testDiv.offsetLeft + cardtop1.offsetLeft + 100) + "px");
        }
        if (name === 'desc') {
            setfiltertext(t('sdClaim.ClaimDesc'))
            var testDiv = document.getElementById("Claim_desc");
            setdivleft((testDiv.offsetLeft + cardtop1.offsetLeft + 100) + "px");
        }

        if (name === 'status') {
            setfiltertext(t('sdClaim.ClaimStatus'))
            var testDiv = document.getElementById("Claim_status");
            setdivleft((testDiv.offsetLeft + cardtop1.offsetLeft + 100) + "px");

        }
        if (name === 'modifiedby') {
            setfiltertext(t('Accruals.SubmittedBy'))
            var testDiv = document.getElementById("Submitted_by");
            setdivleft((testDiv.offsetLeft + cardtop1.offsetLeft + 100) + "px");
            DropdownHandler("modifiedby")

        }
        if (name === 'last_modified_date') {
            setfiltertext(t('Accruals.SubmittedOn'))
            var testDiv = document.getElementById("Submitted_on");
            setdivleft((testDiv.offsetLeft + cardtop1.offsetLeft + 100) + "px");
        }
        if (name === 'amount') {
            setfiltertext(t('SpecialPriceAgreement.AmountClaimamount'))
            var testDiv = document.getElementById("Approved_claim_amount");
            setdivleft((testDiv.offsetLeft + cardtop1.offsetLeft + 100) + "px");
        }
        setshowfilterpopup(true)
    }



    const DropdownHandler = (key) => {
        Service.getfilterDropdown('SpecialAgreement/GetSAClaimFilterValue', key).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                            
                            addnewfield(res.data, key)
                        }
                    }
                }
            }

        });
    }

    const OndropdownFilterChange = (e) => {
        let selectedOption = (e);
        setFilterValue([])
        let data = []
        for (let i = 0; i < selectedOption.length; i++) {
            const getallData = {
                Name: selectedOption[i].Name,
            }
            data.push(getallData)
        }
        setFilterValue(data);
        setMultiFilterValue(() => ({
            ...multiFilterValue, [filterCol]: data,
        }));
    }

    const filterLst = () => {
        let list = [];
        for (var key in multiFilterValue) {
            if (multiFilterValue[key].length > 0) {
                let values = ""
                multiFilterValue[key].map((i) => {
                    values += i.Name + ";";
                })
                if (values !== null && values !== '') {
                    list.push({
                        filterKey: key,
                        FilterValue: values
                    })
                }
            }
        }

        for (var key in filterValueDict) {
            if (filterValueDict[key] !== null && filterValueDict[key] !== '') {
                list.push({
                    filterKey: key,
                    FilterValue: filterValueDict[key]
                })
            }
        }
        return list;
    }

    const filterHandler = (flag) => {
        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
        let pagenumber = 1;
        paging.pageNumber = pagenumber;

        if (flag === 'Apply') {
            setshowfilterpopup(false)
            let list = [];

            for (var key in multiFilterValue) {
                if (multiFilterValue[key].length > 0) {
                    let values = ""
                    multiFilterValue[key].map((i) => {
                        values += i.Name + ";";
                    })
                    if (values !== null && values !== '') {
                        list.push({
                            filterKey: key,
                            FilterValue: values
                        })
                    }
                }
            }

            for (var key in filterValueDict) {
                if (filterValueDict[key] !== null && filterValueDict[key] !== '') {
                    list.push({
                        filterKey: key,
                        FilterValue: filterValueDict[key]
                    })
                }
            }
        
            if (list.length > 0) {
                setpaging(paging);
                getClaims("SpecialAgreement/GetClaimBySAId", props.id, paging.pageSize, pagenumber, sortType, sortColName, list, searchValue);
            }

        }
        else {
            setshowfilterpopup(false)

            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: '',
            }));
            setFilterValueDict(() => ({
                ...filterValueDict, [filterCol]: '',
            }));

            let list = [];
            for (var key in multiFilterValue) {
                if (key !== filterCol && multiFilterValue[key].length > 0) {
                    let values = ""
                    multiFilterValue[key].map((i) => {
                        values += i.Name + ";";
                    })
                    if (values !== null && values !== '') {
                        list.push({
                            filterKey: key,
                            FilterValue: values
                        })
                    }
                }
            }

            for (var key in filterValueDict) {
                if (filterValueDict[key] !== null && filterValueDict[key] !== '' && key !== filterCol) {
                    list.push({
                        filterKey: key,
                        FilterValue: filterValueDict[key]
                    })
                }
            }
            setpaging(paging);
            getClaims("SpecialAgreement/GetClaimBySAId", props.id, paging.pageSize, pagenumber, sortType, sortColName, list, searchValue);
            setFilterCol('')
            setfilteringvalue([])
            setDropdownData([])
            setDropdownChange([])
            setFilterValue('')
            setFilterSearch("")
            setFilterDropdownData([])
        }
    }

    const SortColumn = (colName) => {

        var sortType = '';
        if (columnAscending === true) {
            setColumnAscending(false);
            sortType = "Descending";
        } else {
            setColumnAscending(true);
            sortType = "Ascending";
        }
        setSortColName(colName);
        let pagenumber = 1;
        paging.pageNumber = pagenumber;
        setpaging(paging);
        getClaims("SpecialAgreement/GetClaimBySAId", props.id, paging.pageSize, pagenumber, sortType, colName, filterLst(), searchValue);

    }

    const searchHandler = (event) => {
        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
        let pagenumber = 1;
        paging.pageNumber = pagenumber;
        setpaging(paging);
       
        if (event.target.value != '') {
            setSearchValue(String(event.target.value).toLowerCase())
            getClaims("SpecialAgreement/GetClaimBySAId", props.id, paging.pageSize, pagenumber, sortType, sortColName, filterLst(), event.target.value);
        }
        else {

            setSearchValue(null);
            getClaims("SpecialAgreement/GetClaimBySAId", props.id, paging.pageSize, pagenumber, sortType, sortColName, filterLst(), null);
        }
    }

    const getClaims = (method, id, pagesize, pagenumber, sortType, SortProperty, filterlist, search) => {
   
        Service.getPostdatabyClaimIdSearch(method, id, pagesize, pagenumber, sortType, SortProperty, filterlist, search).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    let rData = res.data.DataList;
                 
                    if (res.status === 200) {
                        if (res.data.DataList !== 'null' && res.data.DataList !== 'undefined') {
                            setClaimData(res.data.DataList)
                            setshowspinner(false);
                            LoadMore(res.data.DataList.length, res.data.Totalcount)
                            settotalCountlst(res.data.Totalcount)
                        }
                    }
                }
            }
        });
    }

    const LoadMore = (lst, totalcount) => {

        if (lst < totalcount) {
            setshowLoadMore(true);
        }
        else if (lst >= totalcount) {
            setshowLoadMore(false);
        } else {
            setshowLoadMore(false);
        }

    }

    const clickShowClaimWorkflow = (val) => {
        if (checkedValue.length === 0) {
            alert("Please Select a claim to view wokflow")
        }
        else {
            setshowClaimWorkflow(true);
            setsidebarwidth("70vw");
            
        }
    }

    const clickCloseClaimWorkflow = () => {
        setshowClaimWorkflow(false);
    }





    return (
        <div className="container-fluid content-top-gap">
            <ToastContainer autoClose={1000} />
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb my-breadcrumb">

                    <li className="breadcrumb-item"><Link to="/">{t('Home.title')}</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">
                        <Link to="/specialprice">{t('Menu.Special Price Agreement')}</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">{t('Create')}</li>


                </ol>
            </nav>
            {loading ? <><div className="loader-container"><div className="spinner"></div> </div> </> : ""}
            {/* Workflow Pop-up */}

            {showShipWorkflow === true ?
                <div id="mySidepanel" className="sidepanel sidepanelborder" style={{ width: sidebarwidth }}>
                    <div className="container" id="workflow">
                        <div className="row mt-3">
                            <div className="col-lg-12 pr-lg-2">
                                <div className="card text-center card_border">
                                    <div className="card-header chart-grid__header">
                                        <div className="row">
                                            <div className="col-md-10 text-left">
                                                {t('Workflow')}
                                            </div>
                                            <div className="col-md-2">
                                                <button className="btn btn-outline-dark  btn-sm" onClick={clickCloseShipWorkflow} type="submit" style={{width: "45px"}}>{t('Close')}</button>
                                            </div>
                                        </div>
                                    </div>
                                    <WorkflowApprovers id={props.id}></WorkflowApprovers>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                :
                <div></div>
            }

            {showClaimWorkflow === true ?
                <div id="mySidepanel" className="sidepanel sidepanelborder" style={{ width: sidebarwidth }}>
                    <div className="container" id="workflow">
                        <div className="row mt-3">
                            <div className="col-lg-12 pr-lg-2">
                                <div className="card text-center card_border">
                                    <div className="card-header chart-grid__header">
                                        <div className="row">
                                            <div className="col-md-10 text-left">
                                                {t('Workflow')}
                                            </div>
                                            <div className="col-md-2">
                                                <button className="btn btn-outline-dark  btn-sm" onClick={clickCloseClaimWorkflow} type="submit" style={{width: "45px"}}>{t('Close')}</button>
                                            </div>
                                        </div>
                                    </div>
                                    <ClaimWorkflowApprovers id={checkedValue}></ClaimWorkflowApprovers>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                :
                <div></div>
            }

            <div className="row">
                <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                        <div className="card-header chart-grid__header">
                            <div className="row">
                                <div className="col-md-7 text-left">
                                    {t('Menu.Special Price Agreement')}
                                </div>
                                <div className="col-md-5">
                                    {Service.submitPerms(userPerms) == false ? "" : editPerms == false ? "" : inputField.Status.toLocaleLowerCase() !== "new" ? "" : <button onClick={(Event) => { SaveHandler(Event, "new") }} className="btn  btn-update btn-sm ml-2" style={{ width: 40 + "px", float: "right" }} type="submit">{t('Save')}</button>}
                                    {Service.submitPerms(userPerms) == false ? "" : editPerms == false ? "" : inputField.Status.toLocaleLowerCase() !== "new" ? "" : <button onClick={(Event) => { SaveHandler(Event, "inreview") }} className="btn  btn-update btn-sm ml-2" style={{ width: 50 + "px", float: "right" }} type="submit">{t('Submit')}</button>}

                                    {edit === false ? "" : edit == false ? "" : <button className="btn  btn-update btn-sm ml-2" style={{ width: 65 + "px", float: "right" }} type="submit" onClick={() => { clickShowShipWorkflow() }}>{t('Workflow')}</button>}
                                    {loadedFrom == "fromapproval" || loadedFrom == "fromapprovalcompleted" ? "" : <button className="btn  btn-update btn-sm ml-2" style={{ width: 45 + "px", float: "right" }} type="submit" onClick={(Event) => { closeShipAgreement() }}>{t('Close')}</button>}

                                    {editPerms == false ? "" : loadedFrom == "fromapproval" && inputField.Status.toLocaleLowerCase() == 'inreview' ? <button type="button" className="btn btn-sm btn-update ml-2" style={{ float: "right",width: "55px" }} onClick={() => showApprovedDialog('showApprovedDialog')} >{t('Approve')}</button> : <></>}
                                    {editPerms == false ? "" : loadedFrom == "fromapproval" && inputField.Status.toLocaleLowerCase() == 'inreview' ? <button type="button" className="btn btn-sm btn-update ml-2" style={{ float: "right",width: "40px" }} onClick={() => showDeniedDialog('showDeniedDialog')}>{t('Deny')}</button> : <></>}

                                    {loadedFrom == "fromapproval" ? <button className="btn  btn-update btn-sm ml-2" style={{ width: 45 + "px", float: "right" }} type="submit" onClick={(Event) => { closeApproveAgreement() }}>Close</button> : <></>}
                                    {loadedFrom == "fromapprovalcompleted" ? <button className="btn  btn-update btn-sm ml-2" style={{ width: 45 + "px", float: "right" }} type="submit" onClick={(Event) => { closeApproveCompleteAgreement() }}>Close</button> : <></>}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="row mt-1">
                <div className="col-sm-4">
                    <div className="row">
                        <div className="col-lg-12 pr-lg-2">
                            <div className="card text-center card_border">
                                <div className="card-body">
                                    <div className="container">
                                        <div className="row">
                                            <label className="col-md-6 text-left">{t('ship&debit.AgreementId')}</label>
                                            <input name="ShipId" type="text" value={inputField.AgreementId} className="col-md-6 textbox-padding" />
                                            <label className="col-md-6 mt-2 text-left">{t('SpecialPriceAgreement.AgreementName')}</label>
                                            <input onChange={inputsHandler} type="text" autoComplete='off' value={inputField.AgreementDesc} name="AgreementDesc" className={"col-md-6 textbox-padding mt-2" + disabledClass} />

                                            <label className="col-md-6 mt-2 text-left">{t('ship&debit.DistributorName')}<span className={classes.mandatory}>*</span></label>
                                            <Select
                                                id="DistributorId"
                                                className={disabledClass_dropdown}
                                                options={distributorList}
                                                placeholder={t('Select')}
                                                onChange={(event) => OndropdownChange(event, 'DistributorId')}
                                                isSearchable={true}
                                                styles={Service.getSelectstyle()}
                                                value={distributorList.filter(function (option) {
                                                    return option.value === inputField.DistributorId;
                                                })}
                                         
                                            />
                                            <label className="col-md-6 mt-2 text-left">{t('ship&debit.EndCustomer')}<span className={classes.mandatory}>*</span></label>
                                            <Select
                                                className={disabledClass_dropdown}
                                                options={endCustomersList}
                                                placeholder={t('Select')}
                                                value={endCustomersList.filter(function (option) {
                                                    return option.value === inputField.EndcustomerId;
                                                })}
                                                onChange={(event) => OndropdownChange(event, 'EndcustomerId')}
                                                isSearchable={true}
                                                styles={Service.getSelectstyle()}
                                            
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-4">
                    <div className="row">
                        <div className="col-lg-12 pr-lg-2">
                            <div className="card text-center card_border">
                                <div className="card-body" >
                                    <div className="container">
                                        <div className="row">
                                            <label className="col-md-6 text-left">{t('Rebates.StartDate')}</label>
                                            <div className='col-md-6 textbox-padding'>
                                                <DatePicker name="ValidityFrom" selected={new Date(inputField.ValidityFrom)} className={"drodownwidth" + disabledClass}
                                                    onChange={(event) => OnChangeHandlerDate(event, 'ValidityFrom')}

                                                    dateFormat={SessionManage.getdateformat()} />
                                            </div>
                                            <label className="col-md-6 mt-2 text-left">{t('Rebates.EndDate')}</label>
                                            <div className='col-md-6 textbox-padding'>
                                                <DatePicker name="ValidityTo" className={"drodownwidth" + disabledClass}
                                                    selected={new Date(inputField.ValidityTo)}
                                                    onChange={(event) => OnChangeHandlerDate(event, 'ValidityTo')}

                                                    dateFormat={SessionManage.getdateformat()} />
                                            </div>

                                            <label className="col-md-6 mt-2 text-left">{t('Rebates.SettlementMethod')}<span className={classes.mandatory}>*</span></label>
                                            <Select
                                                className={disabledClass_dropdown}
                                                options={settlementMethodsList}
                                                placeholder={t('Select')}
                                                value={settlementMethodsList.filter(function (option) {
                                                    return option.value === inputField.SettlementMethodId;
                                                })}
                                                onChange={(event) => OndropdownChange(event, 'SettlementMethodId')}
                                                isSearchable={true}
                                                styles={Service.getSelectstyle()}
                                    


                                            />

                                            <label className="col-md-6 text-left" style={{ marginTop: "5px" }}>{t('Rebates.Currency')}<span className={classes.mandatory}>*</span></label>
                                            <Select
                                                className={disabledClass_dropdown}
                                                options={currencyList}
                                                placeholder={t('Select')}
                                                value={currencyList.filter(function (option) {
                                                    return option.value === inputField.CurrencyId;
                                                })}
                                                onChange={(event) => OndropdownChange(event, 'CurrencyId')}
                                                isSearchable={true}
                                                styles={Service.getSelectstyle()}
                                         
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-4">
                    <div className="row">
                        <div className="col-lg-12 pr-lg-2">
                            <div className="card text-center card_border">
                                <div className="card-body" >
                                    <div className="container">
                                        <div className="row">
                                            <label className="col-md-6  mt-2 text-left ">{t('SpecialPriceAgreement.AgreementStatus')}</label>

                                            <input onChange={inputsHandler} type="text" autoComplete='off' value={inputField.Status} name="Status" className={"col-md-6 textbox-padding mt-2 disabledClass"} />

                                            <label className="col-md-6 mt-2 text-left">{t('Rebates.AutoPostAccrual')}</label>
                                            <select value={inputField.AutoPostAccruals} onChange={(event) => OndropdownChange(event, 'AutoPostAccruals')} className={"col-md-6 textbox-padding mt-2" + disabledClass} style={{ fontSize: "0.7rem" }} name="autoPostAccruals">
                                                <option key='0' value="true">{t('Yes')}</option>
                                                <option key='1' value="false" >{t('No')}</option>
                                            </select>
                                            <input type="text" className="col-md-6 textbox-padding mt-2" style={{ display: 'none' }} disabled={true} />
                                            <label className="col-md-6 mt-2 text-left">{t('CreatedBy')}</label>
                                            <input type="text" disabled="true" className="col-md-6 textbox-padding mt-2" value={inputField.CreatedUserName} />
                                            <label className="col-md-6 mt-2 text-left">{t('CreatedDate')}</label>
                                            <input type="text" className="col-md-6 textbox-padding mt-2 datetimepicker-input" value={inputField.CreatedDate === null || inputField.CreatedDate === "" ? "" : moment(inputField.CreatedDate).format(SessionManage.getcurrentdateformat())} data-target="#reservationdate" disabled={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                        <div className="card-header chart-grid__header">
                            <div className="row">
                                <div className="col-md-8 text-left">
                                    {t('SpecialPriceAgreement.SpecialPriceAgreementItems')}
                                </div>
                            </div>
                        </div>

                        <div className="card-body" >
                            <div className="container-fluid">
                                <div className="table-responsive" style={{ minHeight: "218px" }}>
                                    <table className="table table-bordered table-head-fixed text-nowrap table-bordered table-striped" style={{ marginBottom: "178px" }}>
                                        <thead className="thead-light">
                                            <tr className='text-left'>
                                                <th>{t('SpecialPriceAgreement.LineId')}</th>
                                                <th>{t('SpecialPriceAgreement.Material')}</th>
                                                <th>{t('Rebates.Currency')}</th>
                                                <th>{t('SpecialPriceAgreement.InvoicePrice')}</th>
                                                <th>{t('SpecialPriceAgreement.ContractType')}</th>
                                                <th>{t('SpecialPriceAgreement.ContractValue')}</th>
                                                <th>{t('SpecialPriceAgreement.ContractedInvociePrice')}</th>
                                                <th>{t('SpecialPriceAgreement.ContractQtyCondition')}</th>
                                                <th>{t('SpecialPriceAgreement.ContractQty')}</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {shipList.map((i, index) => (
                                                <tr>
                                                    <td><Form.Control className={classes['Form-Input-control'] + disabledClass} style={{ width: "104px", display: 'inline-block' }} disabled="true" value={i.AgreementItemId === "" ? (index + 1) : i.AgreementItemId} /></td>
                                                    <td style={{ width: "300px" }}>
                                                        <Select onChange={(Event) => handleInputChange(Event, index, 'ProductId', 'SelectedProduct')}
                                                            className={classes[''] + disabledClass}
                                                            options={productList}
                                                            placeholder={t('Select')}
                                                            isSearchable={true}
                                                            styles={Service.getSelectstyle()}
                                                            value={productList.filter(function (option) {
                                                                return option.value === i.ProductId;
                                                            })}

                                                            menuPlacement="auto"
                                                            maxMenuHeight={130}

                                                        />

                                                    </td>
                                                    <td><Form.Control value={defaultCurrency} className={classes['Form-Input-control'] + disabledClass} style={{ width: "50px" }} disabled="true" />


                                                    </td>

                                                    <td><Form.Control disabled="true" onKeyPress={(e) => !/^(\d)*(\.)?([0-9]{1})?$/.test(e.key) && e.preventDefault()} value={i.Listprice} onChange={(Event) => handleInputTextChange(Event, index, 'Listprice')} className={classes['Form-Input-control'] + disabledClass} style={{ width: "80px" }} /></td>
                                                    <td> <Select onChange={(Event) => handleInputChange(Event, index, 'ContractTypeId', 'SelectedContractType')}
                                                        className={classes['Form-Input-control'] + disabledClass}
                                                        options={contractTypeList}
                                                        value={contractTypeList.filter(function (option) {
                                                            return option.value === i.ContractTypeId;
                                                        })}
                                                        isSearchable={true}
                                                        styles={Service.getSelectstyle()}
                                                    />
                                                    </td>
                                                    <td>
                                                        {defaultPrefix}
                                                        <Form.Control onKeyPress={(e) => !/^(\d)*(\.)?([0-9]{1})?$/.test(e.key) && e.preventDefault()} value={i.ContractPrice} onChange={(Event) => handleInputTextChange(Event, index, 'ContractPrice')} className={classes['Form-Input-control'] + disabledClass} style={{ width: "80px", display: "inline-block" }} /></td>
                                                    <td><Form.Control onKeyPress={(e) => !/^(\d)*(\.)?([0-9]{1})?$/.test(e.key) && e.preventDefault()} value={i.ContractListPrice} onChange={(Event) => handleInputTextChange(Event, index, 'ContractListPrice')} className={classes['Form-Input-control'] + disabledClass} style={{ width: "80px" }} /></td>
                                                    <td>
                                                        <Select
                                                            onChange={(Event) => handleInputChange(Event, index, 'ContractQtyCondId', 'SelectedContractQtyCond')}
                                                            className={classes['Form-Input-control'] + disabledClass}
                                                            options={contractQtyConditionList}
                                                            placeholder={t('Select')}
                                                            isSearchable={true}
                                                            styles={Service.getSelectstyle()}
                                                            value={contractQtyConditionList.filter(function (option) {
                                                                return option.value === i.ContractQtyCondId;
                                                            })}
                                                        />

                                                    </td>
                                                    <td><Form.Control onKeyPress={(e) => !/^(\d)*(\.)?([0-9]{1})?$/.test(e.key) && e.preventDefault()} value={i.ContractQty} onChange={(Event) => handleInputTextChange(Event, index, 'ContractQty')} className={classes['Form-Input-control'] + disabledClass} style={{ width: "80px" }} /></td>
                                                    <td>
                                                        <div className={classes['gridwidth']} style={{ margin: "5px" }}>
                                                            {inputField.Status.toLowerCase() !== "new" ? "" :
                                                                <>
                                                                    {shipList.length != (index + 1) ? <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</> :

                                                                        <a href='#' className="" onClick={() => AddVolume()} title="Add Row">
                                                                            <i className="fa fa-plus " style={{ color: "#4755ab", fontSize: "18px" }}></i><i className="fa-solid fa-pipe"></i> </a>}

                                                                    {(i.Id === 0) ?
                                                                        <>
                                                                            {shipList.length !== 1 ? <a style={{ marginLeft: "15px" }} onClick={() => RemoveItem(index)} className="">
                                                                                <i className="fa fa-trash" style={{ color: "red", fontSize: "18px" }} title='Delete'></i>
                                                                            </a> : null
                                                                            }
                                                                        </> : ""
                                                                    }
                                                                    {(i.Id > 0) && loadedFrom !== "fromapproval" ? <>
                                                                        <a style={{ marginLeft: "15px" }} onClick={() => DeleteItem(index, i.Id)} className="">
                                                                            <i className="fa fa-trash" style={{ color: "red", fontSize: "18px" }} title='Delete'></i>
                                                                        </a>
                                                                    </> : ""}

                                                                </>
                                                            }

                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-2" id="card-RPL">
                            <div className="col-lg-12 pr-lg-2">
                                <div className="card text-center card_border overflow-auto">
                                    <div className="card-header chart-grid__header">
                                        <div className="row">
                                            <div className="col-md-8 text-left">
                                                {t('SpecialPriceAgreement.SpecialPriceClaim')}
                                            </div>

                                            <div class="col-md-4">
                                                <button className="btn btn-sm btn-update ml-1" type="submit" style={{ float: "right",width: "40px" }} >{t('Open')}</button>
                                                <button className="btn btn-sm btn-update ml-1" style={{ float: "right",width: "65px" }} type="submit" onClick={() => { clickShowClaimWorkflow("Claim") }}>{t('Workflow')}</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6" style={{ marginTop: '10px' }}>
                                        <input type="text" className="form-control form-control-user" id="firstName" name="firstName" autoComplete='off' placeholder={t('SpecialPriceAgreement.insideHeader')} onChange={(event) => searchHandler(event)} />
                                    </div>
                                    <div className="card-body" style={{ minHeight: "35vh" }}>

                                        <table className="table table-bordered text-left mt-2" id="table-RPL">
                                            <thead className={class_thead} style={{ backgroundColor: "var(--primary)" }}>
                                                <tr>
                                                    <th className="tableheader"><input type="checkbox" id="option-all" onChange={checkAll} /></th>
                                                    <th id='Claim_id' style={{ backgroundColor: (filterValueDict.claim_id ? '#6cae55' : ''), maxWidth: "110px" }}>
                                                        <div className='d-flex text-nowrap'>
                                                            <span>{t('sdClaim.ClaimId')}</span>
                                                            <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('ClaimId')}></span>
                                                            <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('claim_id')}></span>
                                                        </div>
                                                    </th>
                                                    <th id='Claim_desc' style={{ backgroundColor: (filterValueDict.desc ? '#6cae55' : ''), maxWidth: "150px" }}>
                                                        <div className='d-flex text-nowrap'>
                                                            <span>{t('sdClaim.ClaimDesc')}</span>
                                                            <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('ClaimDesc')}></span>
                                                            <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('desc')} ></span>
                                                        </div>
                                                    </th>
                                                    <th id='Claim_status' style={{ backgroundColor: (filterValueDict.status ? '#6cae55' : ''), maxWidth: "110px" }}>
                                                        <div className='d-flex text-nowrap'>
                                                            <span>{t('sdClaim.ClaimStatus')}</span>
                                                            <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('Status')}></span>
                                                            <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('status')} ></span>
                                                        </div>
                                                    </th>
                                                    <th id='Submitted_by' style={{ backgroundColor: (multiFilterValue.modifiedby ? '#6cae55' : ''), maxWidth: "110px" }}>
                                                        <div className='d-flex text-nowrap'>
                                                            <span>{t('Accruals.SubmittedBy')}</span>
                                                            <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('LastModifiedUserName')}></span>
                                                            <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('modifiedby')} ></span>
                                                        </div>
                                                    </th>
                                                    <th id='Submitted_on' style={{ backgroundColor: (filterValueDict.last_modified_date ? '#6cae55' : ''), maxWidth: "110px" }} >
                                                        <div className='d-flex text-nowrap'>
                                                            <span>{t('Accruals.SubmittedOn')}</span>
                                                            <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('LastModifiedDate')}></span>
                                                            <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('last_modified_date')} ></span>
                                                        </div>
                                                    </th>
                                                    <th id='Approved_claim_amount' style={{ backgroundColor: (filterValueDict.amount ? '#6cae55' : ''), maxWidth: "110px" }}>
                                                        <div className='d-flex text-nowrap'>
                                                            <span>{t('SpecialPriceAgreement.AmountClaimamount')}</span>
                                                            <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('ClaimAmount')}></span>
                                                            <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('amount')}></span>
                                                        </div>
                                                    </th>
                                                    <th>{t('Rebates.Actions')}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    showspinner === true ?
                                                        <tr>
                                                            <Spinner animation="border" size="sm" />
                                                        </tr>

                                                        :
                                                        claimData.length === 0 ?
                                                            <tr>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td>
                                                                    {t('NoDataAvailable')}
                                                                </td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                            </tr>
                                                            :
                                                            claimData.map((listVal, index) =>
                                                                <tr>
                                                                    <td><input id={listVal.ClaimId} type="checkbox" name="chkBox" value={[listVal.ClaimId, listVal.Status]} onChange={(event) => handleChange(event, listVal.Id, listVal.Status, listVal.ClaimId)} /></td>
                                                               
                                                                    <td style={{ minWidth: "110px" }}>{listVal.ClaimId}</td>
                                                                    <td style={{ maxWidth: "150px" }}><div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right" title={listVal.ClaimDesc}>{listVal.ClaimDesc}</div></td>
                                                                    <td style={{ minWidth: "110px" }}>{listVal.Status}</td>
                                                                    <td style={{ minWidth: "110px" }}>{listVal.LastModifiedUserName}</td>
                                                                    <td style={{ minWidth: "110px" }}>{moment(listVal.LastModifiedDate).format(SessionManage.getcurrentdateformat())}</td>
                                                                    <td style={{ minWidth: "110px" }}>{listVal.ClaimAmount}</td>
                                                                    <td style={{ minWidth: "110px" }}>{t('ReconcileClaim')}</td>
                                                                </tr>
                                                            )

                                                }

                                            </tbody>

                                        </table>
                                        {
                                            showLoadMore === true ?
                                                <div className='text-center container row'>
                                                    <div className='col-md-5'></div>
                                                    <div className='col-md-2'>
                                                        <button className='btn btn-sm btn-update ml-1' onClick={handleLoadMore} style={{width: "70px"}}>{t('LoadMore')}</button>
                                                    </div>
                                                    <div className='col-md-5'></div>

                                                </div>
                                                :
                                                ""
                                        }

                                        <div className='row'>
                                            {
                                                totalselectedshow === true ?

                                                    <div className='col-lg-2 forgettext  col-sm-12 border' style={{ textAlign: "center" }}>
                                                        {t('TotalRecordsSelected')} - <span id="totalselected">{totalselectedcount}</span>
                                                    </div>

                                                    :
                                                    <div className='col-lg-3  col-sm-12' style={{ textAlign: "center" }}>

                                                    </div>
                                            }
                                            <div className='col-lg-8 col-sm-12'></div>


                                            <div className='col-lg-2 forgettext col-sm-12 border' style={{ textAlign: "center" }}>
                                                {t('TotalRecords')} - {totalCountlst}
                                            </div>
                                        </div>



                                    </div>

                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
            <div style={{ height: "50px" }}>&nbsp;

                <Modal show={showApprovedModal} size="bg"
                    centered>
                    <Modal.Header>
                        <Modal.Title>{t('Accruals.ApprovalComments')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ "textAlign": 'center' }} >
                        <Form.Control className={classes['Textarea-width']} as="textarea" rows={2} value={commentsForApproved} name="commentsForApproved"
                            onChange={(e) => { setCommentsForApproved(e.target.value) }} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button size='sm' style={{ background: '#eff1f9', color: 'black', borderColor: 'black',width: "50px" }} onClick={() => showApprovedDialog('showApprovedDialog')}>
                            {t('Cancel')}
                        </Button>
                        <Button size='sm' style={{ background: '#eff1f9', color: 'black', borderColor: 'black',width: "55px" }} onClick={() => showApprovedDialog('proceed')}>
                            {t('Approve')}
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showDeniedModal} size="bg"
                    centered>
                    <Modal.Header>
                        <Modal.Title>{t('Accruals.DeniedComments')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ "textAlign": 'center' }} >
                        <Form.Control className={classes['Textarea-width']} as="textarea" rows={2} value={commentsForDenied} name="commentsForDenied"
                            onChange={(e) => { setCommentsForDenied(e.target.value) }} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button size='sm' style={{ background: '#eff1f9', color: 'black', borderColor: 'black',width: "50px" }} onClick={() => showDeniedDialog('showDeniedDialog')}>
                            {t('Cancel')}
                        </Button>
                        <Button size='sm' style={{ background: '#eff1f9', color: 'black', borderColor: 'black',width: "40px" }} onClick={() => showDeniedDialog('proceed')}>
                            {t('Deny')}
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={errorPopup} size="bg"
                    centered>
                    <Modal.Header>
                        <Modal.Title></Modal.Title>
                    </Modal.Header>
                    <Modal.Body  >
                        <label style={{ fontWeight: 'bold' }}>{t('.SpecialPriceAgreement.ErrorMessage')}</label>
                        <br></br>
                        <label>
                            {errorProduct.split("~").map(function (item) {
                                return (
                                    <>   {item}<br />
                                    </>
                                )
                            })}
                        </label>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button size='sm' style={{ background: '#eff1f9', color: 'black', borderColor: 'black',width: "45px" }} onClick={() => setErrorPopup(false)}>
                            {t('Close')}
                        </Button>
                    </Modal.Footer>
                </Modal>
                <CustomErrorPopup ErrorMessage={errorMessage} show={showErrorModal} ErrorStatus={errorStatus}
                    onHide={() => setShowErrorModal(false)}></CustomErrorPopup>

                {
                    showfilterpopup === true ?
                        <div className='pop-up' style={{ left: divleft, top: divtop, width: "200px" }}>
                            {filterCol === 'claim_id' || filterCol === 'desc' || filterCol === 'status' || filterCol === 'amount' ?

                                <>
                                    <div className="container">
                                        <div className="row">
                                            <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>
                                        </div>
                                    </div>
                                    <div className="container mt-2">
                                        <div className="row">
                                            <input type='textbox' placeholder={t('Search')} className='filtertextboxbackground col-md-11 textbox-padding ml-2' style={{ outline: 'none' }} value={filterValueDict[filterCol]} onChange={(event) => filterInput(event)}></input>
                                        </div>
                                    </div>
                                </>

                                :
                                <>
                                </>

                            }

                            {filterCol === 'last_modified_date' ?

                                <>
                                    <div className="container">
                                        <div className="row">
                                            <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>
                                        </div>
                                    </div>
                                    <div className="container mt-2">
                                        <div className="row">
                                            <input type='date' className='filtertextboxbackground col-md-11 textbox-padding ml-2' value={filterValueDict[filterCol]} onChange={(event) => filterInput(event)}></input>
                                        </div>
                                    </div>
                                </>
                                :
                                <></>
                            }

                            {filterCol === 'modifiedby' ?
                               
                                <>
                                    <div className="container">
                                        <div className="row">
                                            <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>

                                        </div>

                                    </div>

                                    <div className="container mt-2">
                                        <div className="row">
                                            <input type="text" placeholder={t('Search')} value={filterSearch} onChange={(event) => UserfilterHandler(event)} className='filtertextboxbackground col-md-11 textbox-padding ml-2' />
                                        </div>

                                        <div className="row contentoverflow mt-2">
                                            <table className="filtertable">
                                                <tbody>
                                                    <tr>
                                                        <td><input type="checkbox" name="filter-option-all" id="option-all" onChange={checkAllFilter} /></td>
                                                        <td>{t('SelectAll')}</td>
                                                    </tr>
                                                    {
                                                        dropdownData.map((item, index) => (

                                                            <tr key={index}>

                                                                <td><input id={item.Id} data-index="1" name="filter-option-all" checked={item.ischecked} type="checkbox" onChange={(event) => OndropdownPopup(event, item.Id, item.Name)} /></td>

                                                                <td>{item.Name}</td>

                                                            </tr>


                                                        ))
                                                    }

                                                </tbody>
                                            </table>

                                        </div>
                                    </div>
                                </>
                                :
                                <></>
                            }


                            <div style={{ paddingTop: '10px', paddingLeft: '33px', paddingBottom: '7px' }}>

                                <button className="btn btn-sm btn-update" style={{ width: "45px" }} onClick={() => filterHandler('Apply')}>{t('Apply')}</button>
                                <button className="btn btn-sm btn-update" style={{ marginLeft: '10px', width: "45px" }} onClick={() => filterHandler('Reset')}>{t('Reset')}</button>
                            </div>
                            <div>
                            </div>
                        </div>
                        :
                        <></>
                }

            </div>
        </div>
    )
}
export default NewSpecialPriceAgreementComponent