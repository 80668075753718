import React, { Component } from 'react' 
import { useNavigate ,useParams } from 'react-router-dom';
import NotfoundComponent from '../../Components/NotfoundComponent/NotfoundComponent';

function NotFound(props){
    let navigate = useNavigate();
    const params = useParams();
     
     return (  
         <div> 
             <NotfoundComponent ></NotfoundComponent>
         </div>
     );  

}

export default NotFound