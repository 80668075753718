import Login from "../Pages/Login/Login"
// import RebateListing from "../RebatePrograms/RebateListing"
import RebateListing from "../Pages/RebateProgram/RebateListing/RebateListing"
import CreateRebate from "../Pages/RebateProgram/CreateRebate/CreateRebate"
import CreateClaim from "../Pages/RebateProgram/CreateClaimAgreement/CreateClaimAgreement"
import Dashboard from "../Pages/Dashboard/Dashboard"
import AccuralList from "../Pages/Accurals/AccuralList"
import CreateAccurals from "../Pages/Accurals/CreateAccurals"
import ExecutiveDashboard from "../Pages/Dashboard/ExecutiveDashboard"
import UserList from "../Pages/UserManagement/User/UserList"
import CreateUser from "../Pages/UserManagement/User/CreateUser"
import RoleList from "../Pages/UserManagement/Role/RoleList"
import CreateRole from "../Pages/UserManagement/Role/CreateRole"
import EditRole from "../Pages/UserManagement/Role/EditRole"
import PendingApprovalRebate from "../Pages/Approvals/Rebate/PendingApprovalRebate"
import ArchiveRebate from "../Pages/Approvals/Rebate/ArchiveRebate"
import PendingApprovalAccrual from "../Pages/Approvals/Accrual/PendingApprovalAccrual"
import ArchiveAccrual from "../Pages/Approvals/Accrual/ArchiveAccrual"
import Organization from "../Pages/Organization/Organization"
import Workflow from "../Pages/Workflow/Workflow"
import NewWorkflow from "../Pages/Workflow/NewWorkflow"

import DataImport from "../Pages/DataAdministration/DataImport"
import DataExport from "../Pages/DataAdministration/DataExport"
import Reports from "../Pages/DataAdministration/Reports"
import EditUser from "../Pages/UserManagement/User/EditUser"
import RebateTypePolicies from "../Pages/Policies/RebateTypePolicy/RebateTypePolicies"
import NewRebateTypePolicy from "../Pages/Policies/RebateTypePolicy/NewRebateTypePolicy"
import RebateMeasurePolicies from "../Pages/Policies/RebateMeasurePolicy/RebateMeasurePolicies"
import NewRebateMeasurePolicy from "../Pages/Policies/RebateMeasurePolicy/NewRebateMeasurePolicy"
import RebatePayoutCondPolicies from "../Pages/Policies/RebatePayoutCondPolicy/RebatePayoutCondPolicies"
import NewRebatePayoutCondPolicy from "../Pages/Policies/RebatePayoutCondPolicy/NewRebatePayoutCondPolicy"
import SettlementMethodPolicies from "../Pages/Policies/SettlementMethodPolicy/SettlementMethodPolicies"
import NewSettlementMethodPolicy from "../Pages/Policies/SettlementMethodPolicy/NewSettlementMethodPolicy"
import SettlementFreqPolicies from "../Pages/Policies/SettlementfreqPolicy/SettlementFreqPolicies"
import NewSettlementFreqPolicy from "../Pages/Policies/SettlementfreqPolicy/NewSettlementFreqPolicy"
import Groups from "../Pages/Groups/Groups"
import NewGroup from "../Pages/Groups/NewGroup"

import PermissionSetList from "../Pages/PermissionSet/PermissionSetList"
import CreatePermissionSet from "../Pages/PermissionSet/CreatePermissionSet"
import EditPermissionSet from "../Pages/PermissionSet/EditPermissionSet"
import NotFound from "../Pages/Notfound/Notfound"

import CongurationPropertyList from "../Pages/ConfigureProperty/ConfigurationProperty"
import NewConfigurationProperty from "../Pages/ConfigureProperty/NewConfigurationProperty"
import NewEmailTemplate from "../Pages/Workflow/NewEmailTemplate"
import EmailTemplateList from "../Pages/Workflow/EmailTemplateList"
import AccrualsPosting from "../Pages/Accurals/AccuralsPosting"
import RebateDefaultValues from "../Pages/RebateDefaultValues/RebateDefaultValues"

import CompanyProfile from "../Pages/CompanyProfile/CompanyProfile"
import PartnerProfile from "../Pages/PartnerProfile/PartnerProfile"
import LockedDocuments from "../Pages/LockedDocuments/LockedDocuments"

import ShipAndDebit from "../Pages/Ship&Debit/Ship&Debit/Ship&Debit"
import NewShipAndDebit from "../Pages/Ship&Debit/CreateShip&Debit/CreateShip&Debit"

import MassActionRenewShipAndDebitList from "../Pages/Ship&Debit/MassAction/Renew/MassActionRenewShipAndDebitList"
import MassActionSubmitShipAndDebitList from "../Pages/Ship&Debit/MassAction/Submit/MassActionSubmitShipAndDebitList"

import CreateNewMassActionRenewShipAndDebit from "../Pages/Ship&Debit/MassAction/Renew/CreateNewMassActionRenewShipAndDebit"
import CreateNewMassActionSubmitShipAndDebit from "../Pages/Ship&Debit/MassAction/Submit/CreateNewMassActionSubmitShipAndDebit"

import ShipAndDebitClaim from "../Pages/SDClaim/SDClaim/SDClaim"
import NewShipAndDebitClaim from "../Pages/SDClaim/CreateSDClaim/CreateSDClaim"

import MassActionSubmitListClaims from "../Pages/SDClaim/MassAction/Submit/MassActionSubmitListClaims"

import CreateNewMassActionSubmitClaimForRebate from "../Pages/MassActionSubmit/CreateNewMassActionSubmitClaimForRebate"

import CreateNewMassActionSubmitClaimShipAndDebit from "../Pages/SDClaim/MassAction/Submit/CreateNewMassActionSubmitClaimShipAndDebit"

import DistributorCustomerListing from "../Pages/DistributorCustomerData/DistributorCustomerData"
import DistributorCustomerListView from "../Components/DistributorCustomerComponent/DistributorCustomerListView"

import PendingAction from "../Pages/Ship&Debit/Approvals/PendingAction"
import Archive from "../Pages/Ship&Debit/Approvals/Archive"
import SpecialPriceAgreementList from "../Pages/SpecialPriceAgreement/List/SpecialPriceAgreementList"
import NewSpecialPriceAgreement from "../Pages/SpecialPriceAgreement/CreateNew/NewSpecialPriceAgreement"
import SpecialPriceAgreementPendingAction from "../Pages/SpecialPriceAgreement/Approvals/PendingAction"
import SpecialPriceAgreementArchive from "../Pages/SpecialPriceAgreement/Approvals/Archive"
import SDClaimPendingAction from "../Pages/SDClaim/Approvals/PendingAction"
import SdClaimArchive from "../Pages/SDClaim/Approvals/Archive"

import CreateScheduler from "../Pages/Scheduler/CreateScheduler"

import PaymentTermsPolicy from "../Pages/Policies/PaymentTermsPolicies/PaymentTermsPolicies"
import CreateNewPaymentTermPolicy from "../Pages/Policies/PaymentTermsPolicies/CreateNewPaymentTermPolicy"

import CategoryExceptionPolicy from "../Pages/Policies/CategoryExceptionPolicy/CategoryExceptionPolicies"
import CreateNewCategoryExceptionPolicy from "../Pages/Policies/CategoryExceptionPolicy/NewCategoryExceptionPolicy"


import SchedulerList from "../Pages/Scheduler/SchedulerList"

import SubDivideTargetPolicy from "../Pages/Policies/SubDivideTargetPolicies/SubDivideTargetPolicy"
import CreateNewSubDivideTargetPolicy from "../Pages/Policies/SubDivideTargetPolicies/CreateNewSubDivideTargetPolicy"

import RebateCategoryPolicy from "../Pages/Policies/RebateCategoryPolicies/RebateCategoryPolicy"
import CreateNewRebateCategoryPolicy from "../Pages/Policies/RebateCategoryPolicies/CreateNewRebateCategoryPolicy"

import SubDivideAccrualThresholdPolicy from "../Pages/Policies/SubDivideAccrualThresholdPolicy/SubDivideAccrualThresholdPolicy"
import CreateNewSubDivideAccrualThresholdPolicy from "../Pages/Policies/SubDivideAccrualThresholdPolicy/CreateNewSubDivideAccrualThresholdPolicy"


import ClaimReconcilationList from "../Pages/ClaimReconcilation/ClaimReconcilationList"
import CreateClaimReconcilation from "../Pages/ClaimReconcilation/CreateClaimReconcilation"
import ClaimReconcilationPosting from "../Pages/ClaimReconcilation/ClaimReconcilationPosting"
import AuditLogList from "../Pages/AuditLog/AuditLogList"
import AuditLogDetails from "../Pages/AuditLog/AuditLogDetails"
import Schedulerhistory from "../Pages/Scheduler/Schedulerhistory"

import MassActionSubmitListReconcilation from "../Pages/ClaimReconcilation/MassAction/Submit/MassActionSubmitListReconcilation"
import CreateNewMassActionSubmitReconcilationClaim from "../Pages/ClaimReconcilation/MassAction/Submit/CreateNewMassActionSubmitReconcilationClaim"
import CreateNewMassActionSubmitReconcilationShipAndDebit from "../Pages/ClaimReconcilation/MassAction/Submit/CreateNewMassActionSubmitReconcilationShipAndDebit"

import CustomerGroupsList from "../Pages/CustomerGroups/CustomerGroupsList"
import CreateCustomerGroups from "../Pages/CustomerGroups/CreateCustomerGroups"

import MassActionList from "../Pages/MassAction/MassActionList"
import CreateNewMassAction from "../Pages/MassAction/CreateNewMassAction"
import CreateNewMassActionRenewClaim from "../Pages/MassAction/CreateNewMassActionRenewClaim"

import MassActionSubmitListView from "../Pages/MassActionSubmit/MassActionSubmitList"
import CreateMassActionSubmitView from "../Pages/MassActionSubmit/CreateMassActionSubmit"
import CreateNewMassActionSubmitClaim from "../Pages/SDClaim/MassAction/Submit/CreateNewMassActionSubmitClaim"

import ExceptionConfigListView from "../Pages/ExceptionConfiguration/ExceptionConfigList"
import CreateExceptionConfigView from "../Pages/ExceptionConfiguration/CreateExceptionConfig"

import ExceptionPolicylist from "../Pages/ExceptionPolicy/ExceptionPolicylist"
import CreateNewExceptionPolicy from "../Pages/ExceptionPolicy/CreateNewExceptionPolicy"

import PoliciesList from "../Pages/Policy/Policies"
import Admin from "../Pages/Menu/Admin"

import ExceptionPolicyPendingAction from "../Pages/ExceptionPolicy/Approvals/PendingAction"
import ExceptionPolicyArchive from "../Pages/ExceptionPolicy/Approvals/Archive"

import MassActionSubmitAccrualListView from "../Pages/Accurals/MassActionSubmit/MassActionSubmitAccrualsListView"
import CreateMassActionSubmitAccrualView from "../Pages/Accurals/MassActionSubmit/CreateMassActionSubmitAccrualView"

import AccrualGenerateView from "../Pages/Accurals/MassActionGenerate/AccrualGenerateView"
import CreateMassActionGenerateAccrualView from "../Pages/Accurals/MassActionGenerate/CreateMassActionGenerateAccrualView"

import DistributorBudgetedAmountPolicylist from "../Pages/DistributorBudgetedAmountPolicy/DistributorBudgetedAmountPolicylist"
import CreateNewDistributorBudgetedAmountPolicy from "../Pages/DistributorBudgetedAmountPolicy/CreateNewDistributorBudgetedAmountPolicy"

import DistributorBudgetedAmountPolicyPendingAction from "../Pages/DistributorBudgetedAmountPolicy/Approvals/PendingAction"
import DistributorBudgetedAmountPolicyArchive from "../Pages/DistributorBudgetedAmountPolicy/Approvals/Archive"

import PendingApprovalClaimReconcilation from "../Pages/ClaimReconcilation/Approvals/PendingAction"
import ArchiveClaimReconcilation from "../Pages/ClaimReconcilation/Approvals/Archive"
import SDDashboard from "../Pages/Dashboard/SDDashboard"

import CashDiscountPolicylist from "../Pages/CashDiscountPolicy/CashDiscountPolicyList"
import CreateNewCashDiscountPolicy from "../Pages/CashDiscountPolicy/CreateNewCashDiscountPolicy"

import PaymentPending from "../Pages/InvoicePayment/PaymentPending"
import PaymentReceived from "../Pages/InvoicePayment/PaymentReceived"
import PaymentHistory from "../Pages/InvoicePayment/PaymentHistory"

import SpecialClaimlist from "../Pages/SpecialClaim/SpecialClaimlist"
import CreateSpecialClaim from "../Pages/SpecialClaim/CreateSpecialClaim"

import ChangeHistory from "../Pages/ChangeHistory/ChangeHistory"
import CreateClaimAgreement from "../Pages/RebateProgram/CreateClaimAgreement/CreateClaimAgreement"



const route = [

   // { path: '/', name: 'Rebate', component: Dashboard, element: Dashboard },    
   { path: '/', name: 'ExecutiveDashboard', component: ExecutiveDashboard, element: ExecutiveDashboard },

   { path: '/rebate-program/:val', name: 'Rebate', component: RebateListing, element: RebateListing },
   { path: '/new-rebate/:id/:name/:value', name: 'CreateRebate', component: CreateRebate, element: CreateRebate },
   { path: '/new-claimagreement/:id/:name/:value', name: 'CreateClaimAgreement', component: CreateClaimAgreement, element: CreateClaimAgreement },

   { path: '/accruals', name: 'AccuralList', component: AccuralList, element: AccuralList },
   { path: 'accruals/new/:id/:name/', name: 'CreateAccurals', component: CreateAccurals, element: CreateAccurals },
   { path: 'accruals/new/:id/:name/:option', name: 'CreateAccurals', component: CreateAccurals, element: CreateAccurals },


   { path: '/users', name: 'UserList', component: UserList, element: UserList },
   { path: '/users/add', name: 'CreateUser', component: CreateUser, element: CreateUser },
   { path: '/users/edit/:id', name: 'CreateUser', component: EditUser, element: EditUser },

   { path: '/roles', name: 'RoleList', component: RoleList, element: RoleList },
   { path: '/roles/edit/:id', name: 'EditRole', component: EditRole, element: EditRole },
   { path: '/roles/add', name: 'CreateRole', component: CreateRole, element: CreateRole },

   { path: '/rebateapprovals/pendingaction/:id', name: 'PendingApprovalRebate', component: PendingApprovalRebate, element: PendingApprovalRebate },
   { path: '/rebateapprovals/archive/:id', name: 'ArchiveRebate', component: ArchiveRebate, element: ArchiveRebate },

   { path: '/accrualapprovals/pendingaction/:id', name: 'PendingApprovalAccrual', component: PendingApprovalAccrual, element: PendingApprovalAccrual },
   { path: '/accrualapprovals/archive/:id', name: 'ArchiveAccrual', component: ArchiveAccrual, element: ArchiveAccrual },

   { path: '/Organization', name: 'Organization', component: Organization, element: Organization },

   { path: '/workflow/:id', name: 'Workflow', component: Workflow, element: Workflow },
   { path: '/workflow/add/:id', name: 'NewWorkflow', component: NewWorkflow, element: NewWorkflow },
   { path: '/workflow/edit/:id', name: 'WorkflowEdit', component: NewWorkflow, element: NewWorkflow },

   { path: '/emailtemplates/add/:id', name: 'NewEmailTemplate', component: NewEmailTemplate, element: NewEmailTemplate },
   { path: '/emailtemplates/edit/:id', name: 'NepwEmailTemplate', component: NewEmailTemplate, element: NewEmailTemplate },
   { path: '/emailtemplates', name: 'EmailTemplateList', component: EmailTemplateList, element: EmailTemplateList },

   { path: '/dataimport', name: 'DataImport', component: DataImport, element: DataImport },
   { path: '/dataexport', name: 'DataExport', component: DataExport, element: DataExport },
   { path: '/reports', name: 'Reports', component: Reports, element: Reports },

   { path: '/policies/rebatetype', name: 'RebateTypePolicies', component: RebateTypePolicies, element: RebateTypePolicies },
   { path: '/policies/rebatetype/add/:id', name: 'NewRebateTypePolicy', component: NewRebateTypePolicy, element: NewRebateTypePolicy },
   { path: '/policies/rebatetype/edit/:id', name: 'NewRebateTypePolicy', component: NewRebateTypePolicy, element: NewRebateTypePolicy },


   { path: '/policies/rebatemeasure', name: 'RebateMeasurePolicies', component: RebateMeasurePolicies, element: RebateMeasurePolicies },
   { path: '/policies/rebatemeasure/add/:id', name: 'NewRebateMeasurePolicy', component: NewRebateMeasurePolicy, element: NewRebateMeasurePolicy },
   { path: '/policies/rebatemeasure/edit/:id', name: 'NewRebateMeasurePolicy', component: NewRebateMeasurePolicy, element: NewRebateMeasurePolicy },


   { path: '/policies/rebatepayoutcond', name: 'RebatePayoutCondPolicies', component: RebatePayoutCondPolicies, element: RebatePayoutCondPolicies },
   { path: '/policies/rebatepayoutcond/add/:id', name: 'New RebatePayoutCondPolicy', component: NewRebatePayoutCondPolicy, element: NewRebatePayoutCondPolicy },
   { path: '/policies/rebatepayoutcond/edit/:id', name: 'Edit RebatePayoutCondPolicy', component: NewRebatePayoutCondPolicy, element: NewRebatePayoutCondPolicy },

   { path: '/policies/settlementmethod', name: 'SettlementMethodPolicies', component: SettlementMethodPolicies, element: SettlementMethodPolicies },
   { path: '/policies/settlementmethod/add/:id', name: 'NewSettlementMethodPolicy', component: NewSettlementMethodPolicy, element: NewSettlementMethodPolicy },
   { path: '/policies/settlementmethod/edit/:id', name: 'NewSettlementMethodPolicy', component: NewSettlementMethodPolicy, element: NewSettlementMethodPolicy },

   { path: '/policies/settlementfreq', name: 'SettlementFreqPolicies', component: SettlementFreqPolicies, element: SettlementFreqPolicies },
   { path: '/policies/settlementfreq/add/:id', name: 'NewSettlementFreqPolicy', component: NewSettlementFreqPolicy, element: NewSettlementFreqPolicy },
   { path: '/policies/settlementfreq/edit/:id', name: 'NewSettlementFreqPolicy', component: NewSettlementFreqPolicy, element: NewSettlementFreqPolicy },


   { path: '/dataentitlement', name: 'Groups', component: Groups, element: Groups },
   { path: '/dataentitlement/edit/:id', name: 'NewGroups', component: NewGroup, element: NewGroup },

   { path: '/permissionset', name: 'PermissionSetList', component: PermissionSetList, element: PermissionSetList, menuname: "Role List", action: "Edit Permission", menuname: "DashBoard", action: "View" },
   { path: '/permissionset/add', name: 'CreatePermissionSet', component: CreatePermissionSet, element: CreatePermissionSet, menuname: "Role List", action: "Edit Permission", menuname: "DashBoard", action: "View" },
   { path: '/permissionset/edit/:id', name: 'EditPermissionSet', component: EditPermissionSet, element: EditPermissionSet, menuname: "Role List", action: "Edit Permission", menuname: "DashBoard", action: "View" },
   { path: '/*', name: 'notfound', component: NotFound, element: NotFound },

   { path: '/congurationprop', name: 'CongurationPropertyList', component: CongurationPropertyList, element: CongurationPropertyList },
   { path: '/congurationprop/add/:id', name: 'NewConfigurationProperty', component: NewConfigurationProperty, element: NewConfigurationProperty },
   { path: '/congurationprop/edit/:id', name: 'NewConfigurationProperty', component: NewConfigurationProperty, element: NewConfigurationProperty },
   { path: '/accrualsposting', name: 'AccrualsPosting', component: AccrualsPosting, element: AccrualsPosting },

   { path: '/rebatedefaultvalues', name: 'RebateDefaultValues', component: RebateDefaultValues, element: RebateDefaultValues },

   { path: '/companyprofile', name: 'CompanyProfile', component: CompanyProfile, element: CompanyProfile },
   { path: '/partnerprofile', name: 'PartnerProfile', component: PartnerProfile, element: PartnerProfile },
   { path: '/partnerprofile/:id', name: 'PartnerProfile', component: PartnerProfile, element: PartnerProfile },
   { path: '/forecast-dashboard', name: 'forecastdashboard', component: Dashboard, element: Dashboard },
   { path: '/lockedDocuments', name: 'LockedDocuments', component: LockedDocuments, element: LockedDocuments },

   { path: '/shipanddebit', name: 'ShipAndDebit', component: ShipAndDebit, element: ShipAndDebit },
   { path: '/shipanddebit/new', name: 'NewShipAndDebit', component: NewShipAndDebit, element: NewShipAndDebit },
   { path: '/shipanddebit/new/:id', name: 'NewShipAndDebit', component: NewShipAndDebit, element: NewShipAndDebit },
   { path: '/shipanddebit/new/:id/:name', name: 'NewShipAndDebit', component: NewShipAndDebit, element: NewShipAndDebit },
   { path: '/shipanddebit/approvals/pending', name: 'pendingaction', component: PendingAction, element: PendingAction },
   { path: '/shipanddebit/approvals/archive', name: 'archive', component: Archive, element: Archive },


   { path: '/MassActionRenewShipAndDebitList', name: 'MassActionRenewShipAndDebit', component: MassActionRenewShipAndDebitList, element: MassActionRenewShipAndDebitList },
   { path: '/MassActionSubmitShipAndDebitList', name: 'MassActionSubmitShipAndDebit', component: MassActionSubmitShipAndDebitList, element: MassActionSubmitShipAndDebitList },

   { path: '/CreateNewMassActionRenewShipAndDebit/new/:id', name: 'CreateNewMassActionRenewShipAndDebit', component: CreateNewMassActionRenewShipAndDebit, element: CreateNewMassActionRenewShipAndDebit },
   { path: '/CreateNewMassActionRenewShipAndDebit/edit/:id', name: 'CreateNewMassActionRenewShipAndDebit', component: CreateNewMassActionRenewShipAndDebit, element: CreateNewMassActionRenewShipAndDebit },

   { path: '/CreateNewMassActionSubmitShipAndDebit/new/:id', name: 'CreateNewMassActionSubmitShipAndDebit', component: CreateNewMassActionSubmitShipAndDebit, element: CreateNewMassActionSubmitShipAndDebit },
   { path: '/CreateNewMassActionSubmitShipAndDebit/edit/:id', name: 'CreateNewMassActionSubmitShipAndDebit', component: CreateNewMassActionSubmitShipAndDebit, element: CreateNewMassActionSubmitShipAndDebit },


   { path: '/PaymentTermsPolicy', name: 'PaymentTermsPolicy', component: PaymentTermsPolicy, element: PaymentTermsPolicy },
   { path: '/PaymentTermsPolicy/new/:id', name: 'NewPaymentTermsPolicy', component: CreateNewPaymentTermPolicy, element: CreateNewPaymentTermPolicy },
   { path: '/PaymentTermsPolicy/edit/:id', name: 'NewPaymentTermsPolicy', component: CreateNewPaymentTermPolicy, element: CreateNewPaymentTermPolicy },


   { path: '/CategoryExceptionPolicy', name: 'CategoryExceptionPolicy', component: CategoryExceptionPolicy, element: CategoryExceptionPolicy },
   { path: '/CategoryExceptionPolicy/new/:id', name: 'NewCategoryExceptionPolicy', component: CreateNewCategoryExceptionPolicy, element: CreateNewCategoryExceptionPolicy },
   { path: '/CategoryExceptionPolicy/edit/:id', name: 'NewCategoryExceptionPolicy', component: CreateNewCategoryExceptionPolicy, element: CreateNewCategoryExceptionPolicy },

   { path: '/SubDivideTargetPolicy', name: 'SubDivideTargetPolicy', component: SubDivideTargetPolicy, element: SubDivideTargetPolicy },
   { path: '/SubDivideTargetPolicy/new/:id', name: 'NewSubDivideTargetPolicy', component: CreateNewSubDivideTargetPolicy, element: CreateNewSubDivideTargetPolicy },
   { path: '/SubDivideTargetPolicy/edit/:id', name: 'NewSubDivideTargetPolicy', component: CreateNewSubDivideTargetPolicy, element: CreateNewSubDivideTargetPolicy },

   { path: '/SubDivideAccrualThresholdPolicy', name: 'SubDivideAccrualThresholdPolicy', component: SubDivideAccrualThresholdPolicy, element: SubDivideAccrualThresholdPolicy },
   { path: '/SubDivideAccrualThresholdPolicy/new/:id', name: 'NewSubDivideAccrualThresholdPolicy', component: CreateNewSubDivideAccrualThresholdPolicy, element: CreateNewSubDivideAccrualThresholdPolicy },
   { path: '/SubDivideAccrualThresholdPolicy/edit/:id', name: 'NewSubDivideAccrualThresholdPolicy', component: CreateNewSubDivideAccrualThresholdPolicy, element: CreateNewSubDivideAccrualThresholdPolicy },

   { path: '/RebateCategoryPolicy', name: 'RebateCategoryPolicy', component: RebateCategoryPolicy, element: RebateCategoryPolicy },
   { path: '/RebateCategoryPolicy/new/:id', name: 'NewRebateCategoryPolicy', component: CreateNewRebateCategoryPolicy, element: CreateNewRebateCategoryPolicy },
   { path: '/RebateCategoryPolicy/edit/:id', name: 'NewRebateCategoryPolicy', component: CreateNewRebateCategoryPolicy, element: CreateNewRebateCategoryPolicy },

   { path: '/DistributorCustomerData', name: 'DistributorCustomerData', component: DistributorCustomerListView, element: DistributorCustomerListView },


   { path: '/specialprice', name: 'specialpriceagreementList', component: SpecialPriceAgreementList, element: SpecialPriceAgreementList },
   { path: '/specialprice/new/', name: 'specialpriceagreement', component: NewSpecialPriceAgreement, element: NewSpecialPriceAgreement },
   { path: '/specialprice/new/:id', name: 'specialpriceagreement', component: NewSpecialPriceAgreement, element: NewSpecialPriceAgreement },
   { path: '/specialprice/new/:id/:name', name: 'specialpriceagreement', component: NewSpecialPriceAgreement, element: NewSpecialPriceAgreement },
   { path: '/specialprice/approvals/pending', name: 'pendingaction', component: SpecialPriceAgreementPendingAction, element: SpecialPriceAgreementPendingAction },
   { path: '/specialprice/approvals/archive', name: 'archive', component: SpecialPriceAgreementArchive, element: SpecialPriceAgreementArchive },


   { path: '/specialprice', name: 'specialpriceagreementList', component: SpecialPriceAgreementList, element: SpecialPriceAgreementList },
   { path: '/specialprice/new/', name: 'specialpriceagreement', component: NewSpecialPriceAgreement, element: NewSpecialPriceAgreement },
   { path: '/specialprice/new/:id', name: 'specialpriceagreement', component: NewSpecialPriceAgreement, element: NewSpecialPriceAgreement },
   { path: '/specialprice/new/:id/:name', name: 'specialpriceagreement', component: NewSpecialPriceAgreement, element: NewSpecialPriceAgreement },
   { path: '/specialprice/approvals/pending', name: 'pendingaction', component: SpecialPriceAgreementPendingAction, element: SpecialPriceAgreementPendingAction },
   { path: '/specialprice/approvals/archive', name: 'archive', component: SpecialPriceAgreementArchive, element: SpecialPriceAgreementArchive },


   { path: '/claims', name: 'ShipAndDebitClaim', component: ShipAndDebitClaim, element: ShipAndDebitClaim },
   { path: '/claims/new/:id', name: 'NewShipAndDebitClaim', component: NewShipAndDebitClaim, element: NewShipAndDebitClaim },
   { path: '/claims/new/:id/:name', name: 'NewShipAndDebitClaim', component: NewShipAndDebitClaim, element: NewShipAndDebitClaim },

   { path: '/MassActionSubmitListClaims', name: 'MassActionSubmitClaims', component: MassActionSubmitListClaims, element: MassActionSubmitListClaims },

   //Create Mass Action Submit Claim For Rebate
   { path: '/CreateNewMassActionSubmitClaimForRebate/new/:id', name: 'CreateNewMassActionSubmitClaimForRebate', component: CreateNewMassActionSubmitClaimForRebate, element: CreateNewMassActionSubmitClaimForRebate },
   { path: '/CreateNewMassActionSubmitClaimForRebate/edit/:id', name: 'CreateNewMassActionSubmitClaimForRebate', component: CreateNewMassActionSubmitClaimForRebate, element: CreateNewMassActionSubmitClaimForRebate },

   //Create Mass Action Submit Claim Ship & Debit
   { path: '/CreateNewMassActionSubmitClaimShipAndDebit/new/:id', name: 'CreateNewMassActionSubmitClaimShipAndDebit', component: CreateNewMassActionSubmitClaimShipAndDebit, element: CreateNewMassActionSubmitClaimShipAndDebit },
   { path: '/CreateNewMassActionSubmitClaimShipAndDebit/edit/:id', name: 'CreateNewMassActionSubmitClaimShipAndDebit', component: CreateNewMassActionSubmitClaimShipAndDebit, element: CreateNewMassActionSubmitClaimShipAndDebit },


   { path: '/claims/approvals/pending', name: 'pendingaction', component: SDClaimPendingAction, element: SDClaimPendingAction },
   { path: '/claims/approvals/archive', name: 'archive', component: SdClaimArchive, element: SdClaimArchive },
   { path: '/CreateScheduler', name: 'Create Scheduler', component: CreateScheduler, element: CreateScheduler },
   { path: '/scheduler', name: 'SchedulerList', component: SchedulerList, element: SchedulerList },
   { path: '/reconcilationposting', name: 'ReconcilationPosting', component: ClaimReconcilationPosting, element: ClaimReconcilationPosting },
   { path: '/reconcilation', name: 'ClaimReconcilationList', component: ClaimReconcilationList, element: ClaimReconcilationList },
   { path: 'reconcilation/new/:id/:name/', name: 'CreateClaimReconcilation', component: CreateClaimReconcilation, element: CreateClaimReconcilation },
   { path: 'reconcilation/new/:id/:name/:option', name: 'CreateClaimReconcilation', component: CreateClaimReconcilation, element: CreateClaimReconcilation },


   { path: '/MassActionSubmitListReconcilation', name: 'MassActionSubmitListReconcilation', component: MassActionSubmitListReconcilation, element: MassActionSubmitListReconcilation },
   { path: '/CreateNewMassActionSubmitReconcilationClaim/new/:id', name: 'CreateNewMassActionSubmitReconcilationClaim', component: CreateNewMassActionSubmitReconcilationClaim, element: CreateNewMassActionSubmitReconcilationClaim },
   { path: '/CreateNewMassActionSubmitReconcilationClaim/edit/:id', name: 'CreateNewMassActionSubmitReconcilationClaim', component: CreateNewMassActionSubmitReconcilationClaim, element: CreateNewMassActionSubmitReconcilationClaim },
   { path: '/CreateNewMassActionSubmitReconcilationShipAndDebit/new/:id', name: 'CreateNewMassActionSubmitReconcilationShipAndDebit', component: CreateNewMassActionSubmitReconcilationShipAndDebit, element: CreateNewMassActionSubmitReconcilationShipAndDebit },
   { path: '/CreateNewMassActionSubmitReconcilationShipAndDebit/edit/:id', name: 'CreateNewMassActionSubmitReconcilationShipAndDebit', component: CreateNewMassActionSubmitReconcilationShipAndDebit, element: CreateNewMassActionSubmitReconcilationShipAndDebit },


   { path: '/auditlog', name: 'auditlog', component: AuditLogList, element: AuditLogList },
   { path: '/auditlog/details/:id', name: 'auditlogD', component: AuditLogDetails, element: AuditLogDetails },
   { path: '/Schedulerhistory/:id', name: 'Schedulerhistory', component: Schedulerhistory, element: Schedulerhistory },


   { path: '/policies/customergroups', name: 'customergroups', component: CustomerGroupsList, element: CustomerGroupsList },
   { path: '/policies/customergroups/add', name: 'customergroupsadd', component: CreateCustomerGroups, element: CreateCustomerGroups },
   { path: '/policies/customergroups/edit/:id', name: 'customergroupsadd', component: CreateCustomerGroups, element: CreateCustomerGroups },


   // Rebate Renew
   { path: '/MassActionList', name: 'MassAction', component: MassActionList, element: MassActionList },
   // {path: '/CreateNewMassAction', name: 'NewMassAction', component: CreateNewMassAction, element: CreateNewMassAction},
   { path: '/MassActionList/new/:id', name: 'NewMassActionList', component: CreateNewMassAction, element: CreateNewMassAction },
   { path: '/MassActionList/edit/:id', name: 'NewMassActionList', component: CreateNewMassAction, element: CreateNewMassAction },

   //Claim Renew
   { path: '/MassAction/CreateNewMassActionRenewClaim/new/:id', name: 'NewMassActionRenewClaim', component: CreateNewMassActionRenewClaim, element: CreateNewMassActionRenewClaim },
   { path: '/MassAction/CreateNewMassActionRenewClaim/edit/:id', name: 'NewMassActionRenewClaim', component: CreateNewMassActionRenewClaim, element: CreateNewMassActionRenewClaim },


   // Rebate Submit
   { path: '/rebate-MassActionSubmitList', name: 'MassActionSubmit', component: MassActionSubmitListView, element: MassActionSubmitListView },
   { path: '/MassActionSubmitList/new/:id', name: 'NewMassActionSubmitList', component: CreateMassActionSubmitView, element: CreateMassActionSubmitView },
   { path: '/MassActionSubmitList/edit/:id', name: 'NewMassActionSubmitList', component: CreateMassActionSubmitView, element: CreateMassActionSubmitView },

   //Claim Submit
   { path: '/CreateNewMassActionSubmitClaim/new/:id', name: 'NewMassActionSubmitClaim', component: CreateNewMassActionSubmitClaim, element: CreateNewMassActionSubmitClaim },
   { path: '/CreateNewMassActionSubmitClaim/edit/:id', name: 'NewMassActionSubmiClaim', component: CreateNewMassActionSubmitClaim, element: CreateNewMassActionSubmitClaim },

   { path: '/ExceptionConfigList', name: 'NewExceptionconfigurationList', component: CreateExceptionConfigView, element: CreateExceptionConfigView },
   // {path: '/ExceptionConfigList/new/:id', name: 'NewExceptionconfigurationList', component: CreateExceptionConfigView, element: CreateExceptionConfigView},
   // {path: '/ExceptionConfigList/edit/:id', name: 'NewExceptionconfigurationList', component: CreateExceptionConfigView, element: CreateExceptionConfigView},

   { path: '/ExceptionPolicylist', name: 'ExceptionPolicyList', component: ExceptionPolicylist, element: ExceptionPolicylist },
   { path: '/ExceptionPolicylist/new/:id/:name', name: 'NewExceptionPolicy', component: CreateNewExceptionPolicy, element: CreateNewExceptionPolicy },

   { path: '/policies', name: 'Policies', component: PoliciesList, element: PoliciesList },
   { path: '/admin', name: 'Admin', component: Admin, element: Admin },

   { path: '/ExceptionPolicylist/approvals/pending', name: 'pendingaction', component: ExceptionPolicyPendingAction, element: ExceptionPolicyPendingAction },
   { path: '/ExceptionPolicylist/approvals/archive', name: 'archive', component: ExceptionPolicyArchive, element: ExceptionPolicyArchive },

   { path: '/MassActionSubmitAccrualList', name: 'MassActionSubmitAccrual', component: MassActionSubmitAccrualListView, element: MassActionSubmitAccrualListView },
   { path: '/MassActionSubmitAccrualList/new/:id', name: 'NewMassActionSubmitAccrualList', component: CreateMassActionSubmitAccrualView, element: CreateMassActionSubmitAccrualView },
   { path: '/MassActionSubmitAccrualList/edit/:id', name: 'EditMassActionSubmitAccrualList', component: CreateMassActionSubmitAccrualView, element: CreateMassActionSubmitAccrualView },


   { path: '/generateaccruals', name: 'GenerateAccruals', component: AccrualGenerateView, element: AccrualGenerateView },
   { path: '/MassActionGenerateAccrualList/new/:id', name: 'NewMassActionGenerateAccrualList', component: CreateMassActionGenerateAccrualView, element: CreateMassActionGenerateAccrualView },

   { path: '/DistributorBudgetedAmountPolicylist', name: 'DistributorBudgetedAmountPolicylist', component: DistributorBudgetedAmountPolicylist, element: DistributorBudgetedAmountPolicylist },
   { path: '/DistributorBudgetedAmountPolicylist/new/:id', name: 'CreateNewDistributorBudgetedAmountPolicy', component: CreateNewDistributorBudgetedAmountPolicy, element: CreateNewDistributorBudgetedAmountPolicy },
   { path: '/DistributorBudgetedAmountPolicylist/edit/:id', name: 'CreateNewDistributorBudgetedAmountPolicy', component: CreateNewDistributorBudgetedAmountPolicy, element: CreateNewDistributorBudgetedAmountPolicy },

   { path: '/DistributorBudgetedAmountPolicylist/approvals/pending', name: 'pendingaction', component: DistributorBudgetedAmountPolicyPendingAction, element: DistributorBudgetedAmountPolicyPendingAction },
   { path: '/DistributorBudgetedAmountPolicylist/approvals/archive', name: 'archive', component: DistributorBudgetedAmountPolicyArchive, element: DistributorBudgetedAmountPolicyArchive },

   { path: '/DistributorBudgetedAmountPolicylist/new/:id/:name', name: 'CreateNewDistributorBudgetedAmountPolicy', component: CreateNewDistributorBudgetedAmountPolicy, element: CreateNewDistributorBudgetedAmountPolicy },

   { path: '/reconcilation/pendingaction/0', name: 'pendingaction', component: PendingApprovalClaimReconcilation, element: PendingApprovalClaimReconcilation },
   { path: '/reconcilation/archive/0', name: 'archive', component: ArchiveClaimReconcilation, element: ArchiveClaimReconcilation },
   { path: '/sd-dashboard', name: 'SDDashboard', component: SDDashboard, element: SDDashboard },

   { path: '/CashDiscountPolicylist', name: 'CashDiscountPolicylist', component: CashDiscountPolicylist, element: CashDiscountPolicylist },
   { path: '/CashDiscountPolicylist/new/:id', name: 'CreateNewCashDiscountPolicy', component: CreateNewCashDiscountPolicy, element: CreateNewCashDiscountPolicy },

   { path: '/paymentpending', name: 'paymentpendinglist', component: PaymentPending, element: PaymentPending },
   { path: '/paymentreceived', name: 'paymentreceivedlist', component: PaymentReceived, element: PaymentReceived },
   { path: '/paymenthistory', name: 'paymenthistorylist', component: PaymentHistory, element: PaymentHistory },

   { path: '/specialClaimAgreementlist', name: 'SpecialClaim', component: SpecialClaimlist, element: SpecialClaimlist },
   { path: '/specialClaimAgreement/new/:id', name: 'CreateSpecialClaim', component: CreateSpecialClaim, element: CreateSpecialClaim },
   { path: '/specialClaimAgreement/edit/:id', name: 'CreateSpecialClaim', component: CreateSpecialClaim, element: CreateSpecialClaim },


   { path: '/ChangeHistoryList', name: 'ChangeHistoryList', component: ChangeHistory, element: ChangeHistory },

]

export default route

