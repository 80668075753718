import { text } from "@fortawesome/fontawesome-svg-core";
import React from "react";
import CheckboxTree from "react-checkbox-tree";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import { CSVLink, CSVDownload } from "react-csv";
import Service from "../../../Services/Service";


class Widget extends React.Component {
  
  state = {
    checked: [],
    expanded: [],
    filterText: "",
    nodesFiltered: [],
    nodes: [],
    searchfilter: "",
    selectedfile: [],
    ErrorProduct:[],
    userPerms:[],
    rebatelinestatus:""
  };

  constructor(props) {
    super(props);
    this.state = {
      nodesFiltered: this.props.dataByHeirarchy,
      nodes: this.props.dataByHeirarchy,
      checked: this.props.SelectedData,
      userPerms:[],
      rebatelinestatus : this.props.rebatelinestatus
   //   checked: this.props.selecteddatabycsv
    }
   // console.log("25th csv", this.props.selecteddatabycsv);
    this.onCheck = this.onCheck.bind(this);
    this.onExpand = this.onExpand.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);
    this.filterTree = this.filterTree.bind(this);
    this.filterNodes = this.filterNodes.bind(this);
    this.getChildrenValue = this.getChildrenValue.bind(this);
    this.findNodeByValue = this.findNodeByValue.bind(this);
    this.getParentsValue = this.getParentsValue.bind(this);
    this.onSelectedFile = this.onSelectedFile.bind(this);
    this.csvFileToArray = this.csvFileToArray.bind(this);
    this.findcsvValue = this.findcsvValue.bind(this);
  }



  csvFileToArray = (string) => {
   // debugger;
    
    let csvh = [];
    const csvHeader = string.slice(0, string.indexOf("\n")).split(",");
    const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");
    // csvHeader = csvHeader
    csvHeader.forEach((c) => {
      console.log(c + " " + c.length);
      console.log(c.trim() + " " + c.trim().length)
      csvh.push(c.trim());
    })

       const array = csvRows.map(i => {
     
     // const values = i.split(",");
      const values = i;
      const obj = csvh.reduce((object, header, index) => {
      //  object[header] = values[index];
        object[header] = values
        return object;
      }, {});
      return obj;
    });
    var removed = array.splice(-1);
    console.log("csv array", array);
    let arrayreturn =[]
    let ErrorArray =[]
    
    array.forEach((a) => {           
      let value = a.Product
   
      var returnvalue = this.findcsvValue(this.state.nodesFiltered, a.Product.trim().replace(/['"]+/g, ''))
      debugger;
      if (returnvalue !== undefined) {
          arrayreturn.push(returnvalue);
      }
      else{     
        let val ={
          ProductName:value.trim().replace(/['"]+/g, ''),
          ErrorMessage:"Product Not available"          
        } 
        ErrorArray.push(val);
      }
    })


debugger;
    // props.SelectedData(arrayreturn);
     console.log("test csvproductlist", arrayreturn);
     this.setState({ checked:arrayreturn });
     this.props.onCheckboxChecked(arrayreturn);
    // const myObjStr = JSON.stringify(ErrorArray);
     console.log("Error Json",ErrorArray)
     this.setState({ ErrorProduct:ErrorArray})

  };
  componentDidMount() {  
    Service.GetUserPermission('/rebate-program/0').then((res) => {
      if (res !== undefined) {
        if (res.data !== 'null' && res.data !== undefined && res.data !== '') {
            if (res.data.DataList !== undefined) {
           this.setState({ userPerms:res.data.DataList });
         }
        }
      }
    });
}
  getChildrenValue(item) {
    if (typeof item.children === "undefined") {
      return [item.value];
    }
    let values = [item.value];
    item.children.forEach(c => {
      if (typeof c !== "undefined") {
        values = [...values, this.getChildrenValue(c)];
      }
    });
    return values.flat();
  }

  findNodeByValue(data, value) {
    for (var i = 0; i < data.length; i++) {
      if (data[i].value === value) {
        return data[i];
      } else if (typeof data[i].children !== "undefined") {
        const nodeInChild = this.findNodeByValue(data[i].children, value);
        if (nodeInChild) {
          return nodeInChild;
        }
      }
    }
    return;
  }

  findcsvValue(data, value) {
   // debugger;
  // alert(value);
   if (data !== null) {
    for (var i = 0; i < data.length; i++) {
      if (data[i].label === value) {
        return data[i].value;
      }else if(data[i].Name === value){
        return data[i].value;
      }
       else if (typeof data[i].children !== "undefined") {
        const nodeInChild = this.findcsvValue(data[i].children, value);
        if (nodeInChild) {
          return nodeInChild;
        }
      }
    }
  }
    return;
  }

  onSelectedFile(e) {
    // this.props.onSelectedfile(e.target.files[0])
    // console.log("Onselectedfile",e.target.files[0])
    let text ="";
    let file = e.target.files[0], reader = new FileReader(), self = this;
    reader.onload = function(){
     
      text = reader.result;    
     // alert(text);
      if(text !== ""){
        self.csvFileToArray(text);
      }
      
    }
   // 
     reader.readAsText(file);
    // reader.readAsDataURL(file);
    // alert(reader)
     
    // var fileReader = new FileReader();
    // if (e) {
    //   this.fileReader.onload = function (e) {
    //     const text = e.target.result;
    //     debugger;
        
    //     // setnewarr(val);
    //     // alert(newarr);
    //   };

      
    //}
  }

  getParentsValue(data, value) {
    for (var i = 0; i < data.length; i++) {
      if (data[i].value === value) {
        return [data[i].value];
      } else if (typeof data[i].children !== "undefined") {
        const nodeInChild = this.getParentsValue(data[i].children, value);
        if (nodeInChild && nodeInChild.length > 0) {
          return [...nodeInChild, data[i].value];
        }
      }
    }
    return [];
  }

  //   onCheck(checked, targetNode) {
  //     debugger;
  //     const checkedValues = { checked };
  //     const node = this.findNodeByValue(this.state.nodes, targetNode.value);
  //     const childerNodes = this.getChildrenValue(node);
  //     const parentNodes = this.getParentsValue(this.state.nodes, targetNode.value);
  //     if (!this.state.checked.includes(targetNode.value)) {
  //       checkedValues.checked = [
  //         ...new Set(
  //           [...checkedValues.checked, ...parentNodes, ...childerNodes].flat()
  //         )
  //       ];
  //     } else {
  //       checkedValues.checked = checkedValues.checked.filter(
  //         c => !childerNodes.includes(c) && !parentNodes.includes(c)
  //       );
  //     }
  //     this.setState({ checked: checkedValues.checked });
  //   }

  onCheck(checked, targetNode) {

    //   this.setState({ checked });
    if (this.state.checked.length === 0) {
      this.setState({ checked });
      this.props.onCheckboxChecked(checked);
    }
    else {
      if (this.state.searchfilter === "text") {
        if (targetNode.checked === true) {
          this.setState({
            checked: this.state.checked.concat(checked)
          })
          this.props.onCheckboxChecked(this.state.checked.concat(checked));
        } else {
          var index = this.state.checked.indexOf(targetNode.value);

          this.setState({
            checked: this.state.checked.filter((_, i) => i !== index)
          });
          this.props.onCheckboxChecked(this.state.checked.filter((_, i) => i !== index));
        }

      } else {
        this.setState({ checked });
        this.props.onCheckboxChecked(checked);
      }  
    }

    console.log("256",checked);
    console.log("256",checked.length);
  }
  onExpand(expanded) {
    this.setState({ expanded }, () => {
      console.log(this.state.expanded);
    });
  }

  onFilterChange(e) {
    if (e.target.value.length > 0) {
      this.setState({ searchfilter: "text" });
    } else {
      this.setState({ searchfilter: "" });
    }

    this.setState({
      filterText: e.target.value,

    }, this.filterTree);
  }

  filterTree() {
    // Reset nodes back to unfiltered state
    if (!this.state.filterText) {
      this.setState(prevState => ({
        nodesFiltered: prevState.nodes
      }));
      return;
    }
    const nodesFiltered = prevState => ({
      nodesFiltered: prevState.nodes.reduce(this.filterNodes, [])
    });
    this.setState(nodesFiltered);
  }

  filterNodes(filtered, node) {
    const { filterText } = this.state;
    let children;
    if (!!node.children) {
      children = node.children.reduce(this.filterNodes, []);
    }
    if (
      node.label.toLocaleLowerCase().indexOf(filterText.toLocaleLowerCase()) >
      -1 ||
      (children && children.length)
    ) {
      var expanded = [];
      expanded = [node.value, ...this.getAllValuesFromNodes(children)];
      this.setState({ expanded });
      if (!!node.children) {
        filtered.push({ ...node, children });
      } else {
        filtered.push(node);
      }
    }
    return filtered;
  }

  getAllValuesFromNodes = nodes => {
    const values = [];
    if (!!nodes)
      for (let n of nodes) {
        values.push(n.value);
        if (n.children) {
          values.push(...this.getAllValuesFromNodes(n.children, false));
        }
      }
    return values;
  };

  render() {
    const { checked, expanded, filterText, nodesFiltered } = this.state;
    return (
    
      <div className="filter-container productselecotordiv productselecotorpadding">
 
        {/* {Service.editPerms(this.state.userPerms)==false?"":
          this.state.rebatelinestatus !== undefined ? 
            this.state.rebatelinestatus === 'New' ?
             <input type='file' className="text-right" onChange={this.onSelectedFile.bind(this)}></input>
            :""
          :
          ""
           
        } */}
        { 
          this.state.ErrorProduct !== undefined ?
            this.state.ErrorProduct.length !== 0 ?
            <CSVLink
              data={this.state.ErrorProduct}
              filename="ErrorList.csv"
              className="viewfailedlink"
              target="_blank"
            >
              View Failed Records
            </CSVLink>
            : null
          :
          ''
       }
        {/* <a id='upload_link' href='#' className="uploadtext"> <span style={{fontSize:'13px'}}>Import </span>  </a>  */}
       
        <input
          className="filter-text col-md-12 mt-2 mb-2"
          placeholder="Search..."
          type="text"
          value={filterText}
          onChange={this.onFilterChange}
        />
       
       {
      Service.editPerms(this.state.userPerms)==false?<>
       <CheckboxTree
              checked={checked}
              expanded={expanded}
              nodes={nodesFiltered}
              onCheck={this.onCheck}
              onExpand={this.onExpand}
              
              disabled ={true}/>
              </>:
          this.state.rebatelinestatus !== undefined ? 
            this.state.rebatelinestatus === 'New'  ? 
            <CheckboxTree
              checked={checked}
              expanded={expanded}
              nodes={nodesFiltered}
              onCheck={this.onCheck}
              onExpand={this.onExpand}
              
              
            />
            :
            <CheckboxTree
              checked={checked}
              expanded={expanded}
              nodes={nodesFiltered}
              onCheck={this.onCheck}
              onExpand={this.onExpand}
              
              disabled ={true}
            />        
        : null
       
       }
        
        


      </div>
    );
  }
}

export default Widget;
