import React, { Component } from 'react' 
import { useNavigate ,useParams } from 'react-router-dom';
import NewMassActionSubmit from '../../../../Components/ClaimReconcilationComponent/MassAction/Submit/CreateNewMassActionSubmitReconcilationShipAndDebit';

function CreateNewMassActionSubmitReconcilationShipAndDebit(props){
    let navigate = useNavigate();
    const params = useParams();
     
     return (  
         <div> 
             <NewMassActionSubmit {...props}  navigate={navigate} id={params.id} name={params.name} />
         </div>
     );  

}
export default CreateNewMassActionSubmitReconcilationShipAndDebit
