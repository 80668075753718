import React, { Component } from 'react' 
import { useNavigate ,useParams } from 'react-router-dom';
import CreateNewRebateMeasurePolicy from '../../../Components/PoliciesComponent/RebateMeasurePolicyComponent/CreateNewRebateMeasurePolicy';

function NewRebateMeasurePolicy(props){
    let navigate = useNavigate();
    const params = useParams();
     
     return (  
         <div> 
             <CreateNewRebateMeasurePolicy {...props}  navigate={navigate} id={params.id} name={params.name} />
         </div>
     );  

}

export default NewRebateMeasurePolicy